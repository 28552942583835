<div class="detailsubcontent"  *ngIf="!showEdit">
   <div class="closebtn" (click)="hidedetail()">X</div>
   <div class="imgDetail">
       <div class="isimg">
           <img [src]="image_url+'?image_size=small'" *ngIf="image_url">
       </div>
       <div class="isremark" *ngIf="imgdetailData">
           <b>{{ i18n.t("js.photo-log.project") }}:</b>
           <span>{{imgdetailData.project.name}}</span>
           <b>{{ i18n.t("js.photo-log.photographer") }}:</b>
           <span>{{imgdetailData.user.name}}</span>
           <b>{{ i18n.t("js.photo-log.use") }}:</b>
           <span>{{imgdetailData.purpose.name}}</span>
           <b style="margin-bottom: 15px;">{{ i18n.t("js.photo-log.detail.location") }}</b>
              <div *ngIf="Object.keys(imgdetailData.location).length">
                <p>  <strong class="setmargin">{{ i18n.t("js.photo-log.detail.area") }}:</strong><span>{{imgdetailData.location.location1}}</span></p>
                <p>  <strong  class="setmargin">{{ i18n.t("js.photo-log.detail.floor") }}:</strong><span> {{imgdetailData.location.location2}}</span></p>
                <p>  <strong class="setmargin">{{ i18n.t("js.photo-log.detail.location") }}:</strong><span>{{imgdetailData.location.location3}}</span></p>
              </div>
              <div *ngIf="!Object.keys(imgdetailData.location).length">
                <p>  <strong class="setmargin">{{ i18n.t("js.photo-log.detail.area") }}:</strong><span>无</span></p>
                <p>  <strong  class="setmargin">{{ i18n.t("js.photo-log.detail.floor") }}:</strong><span> 无</span></p>
                <p>  <strong class="setmargin">{{ i18n.t("js.photo-log.detail.location") }}:</strong><span>无</span></p>
              </div>
           <b>GPS</b>
           <div style="margin:15px 0;">
          <span style="margin-right: 15px;">{{ i18n.t("js.photo-log.detail.latitude") }}:{{imgdetailData.latitude}}</span><span>{{ i18n.t("js.photo-log.detail.longitude") }}:{{imgdetailData.longitude}}</span>
        </div>
  
           <b>{{ i18n.t("js.photo-log.remark") }}</b>
           <span>{{imgdetailData.remarks}}</span>
           <button class="editbtn" (click)="toshowEdit()" *ngIf="hideEditbtn">{{ i18n.t("js.photo-log.detail.toedit") }}</button>
        </div>
    </div>
</div>

