<div class="createContent" id="liftingcontent">
  <div class="mantlediv" *ngIf="showMantle">
    <div class="materialDiv">
      <span class="closeIcon" (click)="closebtn()"></span>
      <h2>Add Comment On Section {{ saveIndex + 1 }}</h2>
      <div class="formDiv">
        <p>
          <span class="titlespan">Add Your Comment:</span>
          <textarea name="" id="" cols="30" rows="10" [(ngModel)]="comment"> </textarea>
        </p>
        <button (click)="confirmComment()">Confirm Add</button>
      </div>
    </div>
  </div>

<div class="topfixed" id="fixdiv">
  <div class="submitbtn">
    <button (click)="cancel()" style="background-color: #f56c6c">Cancel</button>
    <button *ngIf="saveBaseMessage && saveBaseMessage.state == 'pending_approve'&&approverFlag" (click)="agreeApplication()">
      APPROVE
    </button>
    <button *ngIf="saveBaseMessage && saveBaseMessage.state == 'approved'&&applicantFlag" (click)="requestTerminated()">
      Request for Termination
    </button>
    <button *ngIf="saveBaseMessage && saveBaseMessage.state == 'pending_terminate'&&approverFlag" (click)="terminated()">
      Termination
    </button>
    <button
      style="background: #f56c6c; "
      *ngIf="saveBaseMessage && saveBaseMessage.state == 'pending_approve'&&approverFlag"
      (click)="rejectApplication()"
    >
      Reject
    </button>
  </div>
    <p class="topp">
    <span class="topstaus">{{ saveBaseMessage.state=="init"?'draft':saveBaseMessage.state}}</span
    ><span class="toptitle">View e-lifting</span>
  </p>
  <ul class="toplist">
    <li><a href="#index1">1.Basic Information</a></li>
    <li><a href="#index2">2.Information of Tower Cranes & Lifting Gears</a></li>
    <li><a href="#index3">3.Information of Operator</a></li>
    <li><a href="#index4">4.Information of involved Materials</a></li>
    <li><a href="#index5">5.Consider Potential Danger Area</a></li>
    <li><a href="#index6">6.Checking Items</a></li>
    <li><a href="#index7">7.Confirmation and Issue of Lifting Permit</a></li>
    <li><a href="#index8">8.Cancellation of Lifting Permit</a></li>
  </ul>
  <hr class="hrstyle" />
</div>
  <!-- 主内容 -->
  <div class="bottomdiv">
    <div class="SectionItem__loading loadingDiv" *ngIf="showLoading">
      <div class="loading-indicator -compact">
        <div class="block-1"></div>
        <div class="block-2"></div>
        <div class="block-3"></div>
        <div class="block-4"></div>
        <div class="block-5"></div>
      </div>
    </div>
    <!-- 第一步 -->
    <div class="formcontent setmargin" id="index1">
      <p>1.Basic Information</p>
      <table class="firsttable">
        <tr>
          <td class="spantd">Contract No,</td>
          <td>{{ saveBaseMessage.contract_no }}</td>
        </tr>
        <tr>
          <td class="spantd">Permit Number</td>
          <td>{{ saveBaseMessage.permit_no }}</td>
        </tr>
        <tr>
          <td class="spantd">Permit Valid Date</td>
          <td *ngIf="saveBaseMessage.permit_valid_date">{{ saveBaseMessage.permit_valid_date.split(" ")[0] }}</td>
        </tr>
        <tr>
          <td class="spantd">Permit Valid Time*</td>
          <td *ngIf="saveBaseMessage.permit_valid_time_from">
            From {{ saveBaseMessage.permit_valid_time_from.split(" ")[1]
            }}<span *ngIf="saveBaseMessage.permit_valid_time_to"
              >To {{ saveBaseMessage.permit_valid_time_to.split(" ")[1] }}</span
            >
          </td>
        </tr>
        <tr>
          <td class="spantd">Work Location*</td>
          <td>
            <span *ngIf="saveBaseMessage.work_location">{{ saveBaseMessage.work_location.name }}</span>
          </td>
        </tr>
      </table>
      <button
        class="addComment"
        (click)="approveSection('0')"
        *ngIf="sectionArr[0] && sectionArr[0].state == 'initial'&&approverFlag&&saveBaseMessage.state=='pending_approve'"
      >
        Approve this section
      </button>
      <button class="addComment" (click)="addComment('0')" *ngIf="sectionArr[0] && sectionArr[0].state == 'initial'&&approverFlag&&saveBaseMessage.state=='pending_approve'">
        Reject this section
      </button>
    </div>
    <div class="commentDiv" *ngIf="sectionArr">
      <span *ngIf="sectionArr[0] && sectionArr[0].state == 'rejected'">
        {{ sectionArr[0].comment }}
      </span>
    </div>
    <!-- 第二步 -->
    <div id="#index2" class="setmargin" id="index2">
      <div class="secondContent">
        <p>2. Information of Tower Cranes & Lifting Gears</p>
        <div class="cranecontent">
          <!-- 起重机 -->
          <ng-container *ngIf="craneArr.length > 0">
            <div class="craneitem" *ngFor="let item of craneArr; let i = index">
              <p>
                Crane <span>{{ i+1 }}</span>
              </p>
              <table class="firsttable">
                <tr>
                  <td class="spantd">Plant Type</td>
                  <td>{{ item.plant_type }}</td>
                </tr>
                <tr>
                  <td class="spantd">Sub Type</td>
                  <td>{{ item.sub_type }}</td>
                </tr>
                <tr>
                  <td class="spantd">Plant No.</td>
                  <td>{{ item.plant_no }}</td>
                </tr>
                <tr>
                  <td class="spantd">Explire Date of Cert</td>
                  <td>{{ item.cert_valid_date_to }}</td>
                </tr>
                <tr>
                  <td class="spantd">Max Safe Working Load Lifting Radius</td>
                  <td>{{ item.working_radius }}</td>
                </tr>
                <tr>
                  <td class="spantd">Max Safe Working Load Lifting Capacity</td>
                  <td>{{ item.working_capacity }}</td>
                </tr>
                <tr>
                  <td class="spantd">Danger Zone Ground</td>
                  <td>{{ item.danger_zone_ground }}</td>
                </tr>
                <tr>
                  <td class="spantd">Danger Zone Roof</td>
                  <td>{{ item.danger_zone_roof }}</td>
                </tr>
                <tr>
                  <td class="spantd">Danger Zone Others</td>
                  <td>{{ item.danger_zone_others }}</td>
                </tr>
                <tr>
                  <td class="spantd">Photo</td>
                </tr>
              </table>
              <ng-container *ngIf="item.photo_doc_ids">
                <div *ngIf="item.photo_doc_ids.length > 0" class="showPic">
                  <img
                    [src]="host + '/api/v3/logical_docs/doc/' + subitem + '/stream'+'?image_size=thumbnail'"
                    *ngFor="let subitem of item.photo_doc_ids"
                  />
                </div>
              </ng-container>
            </div>
          </ng-container>

          <!-- 起重装置 -->
          <ng-container *ngIf="gearArr.length > 0">
            <div class="craneitem" *ngFor="let item of gearArr; let i = index">
              <p>
                Lifting Gear <span>{{ i+1 }}</span>
              </p>
              <table class="firsttable">
                <tr>
                  <td class="spantd">ID Mark</td>
                  <td>{{ item.plant_no }}</td>
                </tr>
                <tr>
                  <td class="spantd">Safe Working Load</td>
                  <td>{{ item.working_load }}</td>
                </tr>
                <tr>
                  <td class="spantd">Explire Date of Cert</td>
                  <td>{{ item.cert_valid_date_to }}</td>
                </tr>
                <tr>
                  <td class="spantd">Photo</td>
                  <td></td>
                </tr>
              </table>
              <ng-container *ngIf="item.photo_doc_ids">
                <div *ngIf="item.photo_doc_ids.length > 0" class="showPic">
                  <img
                    [src]="host + '/api/v3/logical_docs/doc/' + subitem + '/stream'+'?image_size=thumbnail'"
                    *ngFor="let subitem of item.photo_doc_ids"
                  />
                </div>
              </ng-container>
            </div>
          </ng-container>
          <button
            class="addComment"
            (click)="approveSection('1')"
            *ngIf="sectionArr[1] && sectionArr[1].state == 'initial'&&approverFlag&&saveBaseMessage.state=='pending_approve'"
          >
            Approve this section
          </button>
          <button
            class="addComment"
            (click)="addComment('1')"
            *ngIf="sectionArr[1] && sectionArr[1].state == 'initial'&&approverFlag&&saveBaseMessage.state=='pending_approve'"
          >
            Reject this section
          </button>
        </div>
      </div>
    </div>
    <div class="commentDiv" *ngIf="sectionArr">
      <span *ngIf="sectionArr[1] && sectionArr[1].state == 'rejected'">
        {{ sectionArr[1].comment }}
      </span>
    </div>
    <!-- 第三步 -->
    <div id="#index3" class="setmargin" id="index3">
      <div class="secondContent">
        <p>3.Information of Operator</p>
        <div class="cranecontent">
          <!-- 操作人员 -->
          <ng-container *ngIf="operatorArr.length > 0">
            <div class="craneitem" *ngFor="let item of operatorArr; let i = index">
              <p>
                Operator <span>{{ i + 1 }}</span>
              </p>
              <table class="firsttable">
                <tr>
                  <td class="spantd">Name of Operator</td>
                  <td>{{ item.name_en }}</td>
                </tr>
                <tr>
                  <td class="spantd">Site Pass No.</td>
                  <td>{{ item.site_pass_no }}</td>
                </tr>
                <tr>
                  <td class="spantd">Cert . No.</td>
                  <td>{{ item.cert_no }}</td>
                </tr>
                <tr>
                  <td class="spantd">Valid Date Cert.</td>
                  <td>{{ item.cert_valid_date_to }}</td>
                </tr>
                <tr>
                  <td class="spantd">Photo</td>
                </tr>
              </table>
              <ng-container *ngIf="item.photo_doc_ids">
                <div *ngIf="item.photo_doc_ids.length > 0" class="showPic">
                  <img
                    [src]="host + '/api/v3/logical_docs/doc/' + subitem + '/stream'+'?image_size=thumbnail'"
                    *ngFor="let subitem of item.photo_doc_ids"
                  />
                </div>
              </ng-container>
            </div>
          </ng-container>

          <!-- 监工 -->
          <ng-container *ngIf="banksmanArr.length > 0">
            <div class="craneitem" *ngFor="let item of banksmanArr; let i = index">
              <p>
                Banksman <span>{{ i + 1 }}</span>
              </p>
              <table class="firsttable">
                <tr>
                  <td class="spantd">Name of Banksman</td>
                  <td>{{ item.name_en }}</td>
                </tr>
                <tr>
                  <td class="spantd">Site Pass No.</td>
                  <td>{{ item.site_pass_no }}</td>
                </tr>
                <tr>
                  <td class="spantd">A12 Cert . No.</td>
                  <td>{{ item.cert_no }}</td>
                </tr>
                <tr>
                  <td class="spantd">Valid Date of A12 Cert. No.</td>
                  <td>{{ item.cert_valid_date_to }}</td>
                </tr>
                <tr>
                  <td class="spantd">Photo</td>
                </tr>
              </table>
              <ng-container *ngIf="item.photo_doc_ids">
                <div *ngIf="item.photo_doc_ids.length > 0" class="showPic">
                  <img
                    [src]="host + '/api/v3/logical_docs/doc/' + subitem + '/stream'+'?image_size=thumbnail'"
                    *ngFor="let subitem of item.photo_doc_ids"
                  />
                </div>
              </ng-container>
            </div>
          </ng-container>

          <!-- 起重工 -->
          <ng-container *ngIf="riggerArr.length > 0">
            <div class="craneitem" *ngFor="let item of riggerArr; let i = index">
              <p>
                Rigger <span>{{ i + 1 }}</span>
              </p>
              <table class="firsttable">
                <tr>
                  <td class="spantd">Name of Rigger</td>
                  <td>{{ item.name_en }}</td>
                </tr>
                <tr>
                  <td class="spantd">Site Pass No.</td>
                  <td>{{ item.site_pass_no }}</td>
                </tr>
                <tr>
                  <td class="spantd">A12 Cert . No.</td>
                  <td>{{ item.cert_no }}</td>
                </tr>
                <tr>
                  <td class="spantd">Valid Date of A12 Cert. No.</td>
                  <td>{{ item.cert_valid_date_to }}</td>
                </tr>
                <tr>
                  <td class="spantd">Photo</td>
                </tr>
              </table>
              <ng-container *ngIf="item.photo_doc_ids">
                <div *ngIf="item.photo_doc_ids.length > 0" class="showPic">
                  <img
                    [src]="host + '/api/v3/logical_docs/doc/' + subitem + '/stream'+'?image_size=thumbnail'"
                    *ngFor="let subitem of item.photo_doc_ids"
                  />
                </div>
              </ng-container>
            </div>
          </ng-container>

          <button
            class="addComment"
            (click)="approveSection('2')"
            *ngIf="sectionArr[2] && sectionArr[2].state == 'initial'&&approverFlag&&saveBaseMessage.state=='pending_approve'"
          >
            Approve this section
          </button>
          <button
            class="addComment"
            (click)="addComment('2')"
            *ngIf="sectionArr[2] && sectionArr[2].state == 'initial'&&approverFlag&&saveBaseMessage.state=='pending_approve'"
          >
            Reject this section
          </button>
        </div>
      </div>
    </div>
    <div class="commentDiv" *ngIf="sectionArr">
      <span *ngIf="sectionArr[2] && sectionArr[2].state == 'rejected'">
        {{ sectionArr[2].comment }}
      </span>
    </div>
    <!-- 第四步材料项 -->
    <div id="#index4" class="setmargin" id="index4">
      <div class="secondContent">
        <p>4.Information of involved Materials</p>
        <div class="cranecontent" style="background-color: #f2f2f2; padding: 15px">
          <div class="title"><span>Involved Materials</span> <span class="numspan">Max.Weight(Ton))</span></div>
          <ul class="checkboxul">
            <li *ngFor="let item of materialArr; let i = index">
              <div class="inputdiv">
                <label for="gogo"
                  ><input id="gogo" type="checkbox" [checked]="item.checked" disabled />{{ lang?item.name_cn: item.name}}</label
                >
                <input
                  type="number"
                  min="0"
                  style="width: 200px; padding-left: 100px"
                  disabled
                  [(ngModel)]="item.weight"
                />
              </div>
              <ng-container *ngIf="item.photo_doc_ids">
                <div *ngIf="item.photo_doc_ids.length > 0" class="showPic">
                  <img
                    [src]="host + '/api/v3/logical_docs/doc/' + subitem + '/stream'+'?image_size=thumbnail'"
                    *ngFor="let subitem of item.photo_doc_ids"
                  />
                </div>
              </ng-container>
            </li>
          </ul>
        </div>
        <button
          class="addComment"
          (click)="approveSection('3')"
          *ngIf="sectionArr[3] && sectionArr[3].state == 'initial'&&approverFlag&&saveBaseMessage.state=='pending_approve'"
        >
          Approve this section
        </button>
        <button class="addComment" (click)="addComment('3')" *ngIf="sectionArr[3] && sectionArr[3].state == 'initial'&&approverFlag&&saveBaseMessage.state=='pending_approve'">
          Reject this section
        </button>
      </div>
    </div>
    <div class="commentDiv" *ngIf="sectionArr">
      <span *ngIf="sectionArr[3] && sectionArr[3].state == 'rejected'">
        {{ sectionArr[3].comment }}
      </span>
    </div>
    <!-- 第五步 危险区备注 -->
    <div class="fiveForm setmargin" id="index5">
      <p>5.Consider Potential Danger Area</p>
      <div class="fiveContent">
        <span>Consider Potential Danger Area</span>
        <textarea name="" id="" cols="30" rows="10" style="pointer-events: none">{{
          saveBaseMessage.dangerous_zone_remarks
        }}</textarea>
      </div>
      <button
        class="addComment"
        (click)="approveSection('4')"
        *ngIf="sectionArr[4] && sectionArr[4].state == 'initial'&&approverFlag&&saveBaseMessage.state=='pending_approve'"
      >
        Approve this section
      </button>
      <button class="addComment" (click)="addComment('4')" *ngIf="sectionArr[4] && sectionArr[4].state == 'initial'&&approverFlag&&saveBaseMessage.state=='pending_approve'">
        Reject this section
      </button>
    </div>
    <div class="commentDiv" *ngIf="sectionArr">
      <span *ngIf="sectionArr[4] && sectionArr[4].state == 'rejected'">
        {{ sectionArr[4].comment }}
      </span>
    </div>
    <!-- 第六步了检查项 -->
    <div class="sixForm setmargin" id="index6">
      <p>6.Checking Item</p>
      <div class="sixContent">
        <span class="bigspan">Checking items</span>
        <div class="select">
          <span>Pass</span>
          <span>Fail</span>
          <span>N/A</span>
        </div>
      </div>
      <div class="sixContent" *ngFor="let item of checkedArr; let i = index">
        <span class="contentSpan">{{ i + 1 }}.{{ lang?item.content_cn:item.content }}</span>
        <div class="select">
          <input type="checkbox" [checked]="item.checked == 'pass'" disabled />
          <input type="checkbox" [checked]="item.checked == 'fail'" disabled />
          <input type="checkbox" [checked]="item.checked == 'not_applicable'" disabled />
        </div>
      </div>
      <button
        class="addComment"
        (click)="approveSection('5')"
        *ngIf="sectionArr[5] && sectionArr[5].state == 'initial'&&approverFlag&&saveBaseMessage.state=='pending_approve'"
      >
        Approve this section
      </button>
      <button class="addComment" (click)="addComment('5')" *ngIf="sectionArr[5] && sectionArr[5].state == 'initial'&&approverFlag&&saveBaseMessage.state=='pending_approve'">
        Reject this section
      </button>
    </div>
    <div class="commentDiv" *ngIf="sectionArr">
      <span *ngIf="sectionArr[5] && sectionArr[5].state == 'rejected'">
        {{ sectionArr[5].comment }}
      </span>
    </div>
    <!-- 第七步 -->
    <!-- 签名 -->
    <!-- <wp-signature *ngIf="decideToSign" (closeView)="closeView($event)"></wp-signature> -->
    <div class="sevenForm setmargin" id="index7">
      <p>7. Confirmation and Issue of Lifting Permit</p>
      <div class="sevenContent">
        <h2>i.Applicant</h2>
        <div class="inputbox">
          <label for="dasheng">
            <input type="checkbox" id="dasheng" checked /><span class="lisen"
              >I understand that as a lifting permit applicant shall ensure the above mentioned checking items have been
              properly implemented and adopted all necessary safety precautions control measures.</span
            ></label
          >
          <table class="firsttable">
            <!-- <tr>
              <td class="spantd">Signature</td>
              <td>
                <div class="singature">
                  <div class="singcontent" *ngIf="saveBaseMessage.confirmation_signature">
                    <img
                      [src]="
                        host + '/api/v3/logical_docs/doc/' + saveBaseMessage.confirmation_signature.doc_id + '/stream'+'?image_size=thumbnail'
                      "
                      *ngIf="saveBaseMessage.confirmation_signature.doc_id"
                    />
                  </div>
                  <label for="apply"><input type="checkbox" id="apply">apply my default signature</label>
                </div>
              </td>
            </tr> -->
            <tr>
              <td class="spantd">Applicant Name</td>
              <td *ngIf="saveBaseMessage.confirmation_signature && saveBaseMessage.confirmation_signature.username">
                {{ saveBaseMessage.confirmation_signature.username }}
              </td>
            </tr>
            <tr>
              <td class="spantd">Date & Time</td>
              <td *ngIf="saveBaseMessage.confirmation_signature && saveBaseMessage.confirmation_signature.created_at">
                {{ saveBaseMessage.confirmation_signature.created_at }}
              </td>
            </tr>
            <tr>
              <td class="spantd">Approver</td>
              <td>{{saveBaseMessage.approver_name}}</td>
            </tr>
          </table>
        </div>
      </div>

      <div
        class="sevenContent"
        *ngIf="
          saveBaseMessage.state == 'pending_approve' ||
          saveBaseMessage.state == 'rejected' ||
          saveBaseMessage.state == 'approved'
        "
      >
        <h3>ii.Approval of Lifting Operation</h3>
        <p>
          After assessing the potential hazards within this operation zone and the related works
           requirements, I think this used lifting appliance is:
        </p>
        <p></p>
        <div class="inputbox">
          <label for="aggree">
            <input type="radio" disabled [checked]="saveBaseMessage.state != 'rejected'" /><span class="lisen">
              Complied with the safety requirements and approved to use
            </span></label
          >
          <label for="reject">
            <input type="radio" disabled [checked]="saveBaseMessage.state == 'rejected'" /><span class="lisen">
              Non-complied with the safety requirements and reject to use,need to re-evaluate
            </span></label
          >
          <table class="firsttable">
            <tr *ngIf="saveBaseMessage.state == 'rejected'">
              <td class="spantd">remarks</td>
              <td *ngIf="saveBaseMessage.inspector_signature && saveBaseMessage.inspector_signature.remarks">
                {{ saveBaseMessage.inspector_signature.remarks }}
              </td>
              <td *ngIf="saveBaseMessage.inspector_signature && !saveBaseMessage.inspector_signature.remarks&&approverFlag">
                <textarea name="" id="" cols="30" rows="10" [(ngModel)]="rejectRemark"></textarea>
              </td>
            </tr>
            <!-- <tr>
              <td class="spantd">Signature</td>
              <td>
                <div class="singature">
                  <div
                    class="singcontent"
                    (click)="changType('inspector')"
                    *ngIf="saveBaseMessage.inspector_signature && !saveBaseMessage.inspector_signature.doc_id&&saveBaseMessage.state=='pending_approve'&&approverFlag&&!canSign"
                  >
                  </div>
                  <div
                    class="singcontent"
                    *ngIf="(saveBaseMessage.inspector_signature && saveBaseMessage.inspector_signature.doc_id)||saveBaseMessage.state!='pending_approve'"
                  >
                    <img
                      [src]="
                        host + '/api/v3/logical_docs/doc/' + saveBaseMessage.inspector_signature.doc_id + '/stream'+'?image_size=thumbnail'
                      "
                    />
                  </div>
                  <label for="apply" *ngIf="saveBaseMessage.state=='pending_approve'&&approverFlag"><input type="checkbox" 
                    id="apply" [checked]="canSign" (change)="useDefault($event.target.checked)"/>Apply my default signature</label>
                </div>
              </td>
            </tr> -->
            <tr>
              <td class="spantd">Approver Name</td>
              <td *ngIf="saveBaseMessage.applicant">
                {{ saveBaseMessage.applicant.firstname }} {{ saveBaseMessage.applicant.lastname }}
              </td>
            </tr>
            <tr>
              <td class="spantd">Date & Time</td>
              <td *ngIf="saveBaseMessage.created_at">{{ saveBaseMessage.created_at.split(" ")[0] }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <!-- 第八步 -->
    <div
      class="sevenForm setmargin"
      id="index8"
      *ngIf="
        saveBaseMessage.state == 'approved' ||
        saveBaseMessage.state == 'pending_terminate' ||
        saveBaseMessage.state == 'terminated'
      "
    >
      <div class="sevenContent">
      <p *ngIf="
        (saveBaseMessage.state == 'approved' ||
        saveBaseMessage.state == 'pending_terminate' ||
        saveBaseMessage.state == 'terminated')&&applicantFlag
      ">8. Cancellation of LIfting Perint</p>
        <div
          class="inputbox"
          *ngIf="
            (saveBaseMessage.state == 'approved' ||
            saveBaseMessage.state == 'pending_terminate' ||
            saveBaseMessage.state == 'terminated')&&applicantFlag
          "
        >
          <b>Applicant</b>
          <table class="firsttable">
            <tr>
              <td class="spantd">Remarks</td>
              <td *ngIf="saveBaseMessage.cancellation_signature && saveBaseMessage.cancellation_signature.remarks">
                {{ saveBaseMessage.cancellation_signature.remarks }}
              </td>
              <td *ngIf="saveBaseMessage.cancellation_signature && !saveBaseMessage.cancellation_signature.remarks&&applicantFlag">
                <textarea name="" id="" cols="30" rows="10" [(ngModel)]="cancelRemark"></textarea>
              </td>
            </tr>
            <tr>
              <td class="spantd">Signature</td>
              <td>
                <div class="singature">
                  <div
                    class="singcontent"
                    (click)="changType('cancellation')"
                    *ngIf="saveBaseMessage.cancellation_signature && !saveBaseMessage.cancellation_signature.doc_id&&saveBaseMessage.state=='approved'&&applicantFlag&&!canSign"
                  >
                  </div>
                  <div
                   class="singcontent"
                    *ngIf="(saveBaseMessage.cancellation_signature && saveBaseMessage.cancellation_signature.doc_id)||saveBaseMessage.state!='approved'"
                  >
                    <img
                      [src]="
                        host + '/api/v3/logical_docs/doc/' + saveBaseMessage.cancellation_signature.doc_id + '/stream'+'?image_size=thumbnail'
                      "
                    />
                  </div>
                  <label for="apply" *ngIf="saveBaseMessage.state=='approved'&&applicantFlag"><input type="checkbox" id="apply" [checked]="canSign" (change)="useDefault($event.target.checked)"/>Apply my default signature</label>
                </div>
              </td>
            </tr>
            <tr>
              <td class="spantd">Applicant Name</td>
              <td *ngIf="saveBaseMessage.cancellation_signature && saveBaseMessage.cancellation_signature.username">
                {{ saveBaseMessage.cancellation_signature.username }}
              </td>
            </tr>
            <tr>
              <td class="spantd">Date & Time</td>
              <td *ngIf="saveBaseMessage.cancellation_signature && saveBaseMessage.cancellation_signature.created_at">
                {{ saveBaseMessage.cancellation_signature.created_at }}
              </td>
            </tr>
          </table>
        </div>
        <div
          class="inputbox"
          *ngIf="(saveBaseMessage.state == 'pending_terminate' || saveBaseMessage.state == 'terminated')&&applicantFlag"
        >
          <b>Final Confirmation</b>
          <table class="firsttable">
            <tr>
              <td class="spantd">Remarks</td>
              <td *ngIf="saveBaseMessage.final_signature && saveBaseMessage.final_signature.remarks">
                {{ saveBaseMessage.final_signature.remarks }}
              </td>
              <td *ngIf="saveBaseMessage.final_signature && !saveBaseMessage.final_signature.remarks&&approverFlag">
                <textarea name="" id="" cols="30" rows="10" [(ngModel)]="finalRemark"></textarea>
              </td>
            </tr>
            <tr>
              <td class="spantd">Signature</td>
              <td>
                <div class="singature">
                  <div
                    class="singcontent"
                    (click)="changType('final')"
                    *ngIf="saveBaseMessage.final_signature && !saveBaseMessage.final_signature.doc_id&&saveBaseMessage.state=='pending_terminate'&&approverFlag&&!canSign"
                  >
                  </div>  
                  <div
                    class="singcontent"
                    *ngIf="(saveBaseMessage.final_signature && saveBaseMessage.final_signature.doc_id)||saveBaseMessage.state!='pending_terminate'"
                  >
                    <img
                      [src]="host + '/api/v3/logical_docs/doc/' + saveBaseMessage.final_signature.doc_id + '/stream'+'?image_size=thumbnail'"
                    />
                  </div>
                  <label for="apply" *ngIf="saveBaseMessage.state=='pending_terminate'&&approverFlag"><input type="checkbox" id="apply" [checked]="canSign"
                    (change)="useDefault($event.target.checked)"/>Apply my default signature</label>
                </div>
              </td>
            </tr>
            <tr>
              <td class="spantd">Approver Name</td>
              <td *ngIf="saveBaseMessage.final_signature && saveBaseMessage.final_signature.username">
                {{ saveBaseMessage.final_signature.username }}
              </td>
            </tr>
            <tr>
              <td class="spantd">Date & Time</td>
              <td *ngIf="saveBaseMessage.final_signature && saveBaseMessage.final_signature.created_at">
                {{ saveBaseMessage.final_signature.created_at }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>

</div>
