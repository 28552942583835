import { All_photoComponent } from './all_photo.component';
import { Photo_logEditComponent } from './component/photo_log-edit/photo_log-edit.component';
import { Photo_logDetailComponent } from './component/photo_log-detail/photo_log-detail.component';
import { NgModule } from '@angular/core';
import { OpenprojectCommonModule } from '../common/openproject-common.module';
import { Ng2StateDeclaration, UIRouterModule } from '@uirouter/angular';
import { Photo_logComponent } from './photo_log.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { from } from 'rxjs';
import { ProgessGroupAddComponent } from './component/progessGroupAdd/progessGroupAdd.component';
import { Photo_log_progressDetailComponent } from './component/photo_log_progress-detail/photo_log_progress-detail.component';
import { AddPhotoToProgressComponent } from './component/add-Photo-to-Progress/add-Photo-to-Progress.component';

export const DOCUMENTS_ROUTES: Ng2StateDeclaration[] = [
  {
    name: 'my_photo',
    url: '/my_photo',
    parent: 'root',
    component: Photo_logComponent
  },
  {
    name: 'all_photo',
    url: '/all_photo',
    parent: 'root',
    component: All_photoComponent
  }
];

@NgModule({
  imports: [
    UIRouterModule.forChild({ 
      states: DOCUMENTS_ROUTES
    }),
    OpenprojectCommonModule,
    HttpClientModule,
    FormsModule
  ],
  declarations: [
    AddPhotoToProgressComponent,
    Photo_log_progressDetailComponent,
    ProgessGroupAddComponent,
    All_photoComponent,
    Photo_logComponent,
    Photo_logDetailComponent,
    Photo_logEditComponent,

  ]
})

export class Photo_logModule { }