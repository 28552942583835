import { Component, OnInit } from '@angular/core';
import { Photo_logService } from './services/photo_log.service';
import { I18nService } from 'core-app/modules/common/i18n/i18n.service';
import { CurrentProjectService } from 'core-app/components/projects/current-project.service';
// import { StateService } from '@uirouter/core';
@Component({
  templateUrl: './photo_log.component.html',
  styleUrls: [ './photo_log.component.sass' ]
})
export class Photo_logComponent implements OnInit {

  public showloading:boolean=true
  public type_id:any
  public projectId:any
  public items:any
  public isselect:boolean=false
  public imgOrigin = `${location.origin}/images/isselect.png`
  public showRight:any=[]
  public showDetail:boolean=false
  public saveitemId:any
  public multitoshowEdit:boolean=false
  public comfirmDelete:boolean = false
  public saveimg:any=[]
  public getsingledata:any
  public pageCount:Number
  public pageSizenum:any=[]
  public savenowPage:any
  public projectName:any



  constructor(
    public service: Photo_logService,
    public currentProject: CurrentProjectService,
    public i18n: I18nService,
    // private $state:StateService
  ) { }
  ngOnInit() {
    this.projectId = jQuery('meta[name=current_project]').data('projectId')
    this.projectName =  jQuery('meta[name=current_project]').data('projectIdentifier')
    this.service.getAlbumbyday({whether_all:true,project_id:this.projectId,page:1}).subscribe((res:any)=>{
      this.pageCount = Math.ceil(res.count/3)
      for(let i=1;i<=this.pageCount;i++){
        this.pageSizenum.push(i)
      }
      this.savenowPage=1
      this.items = res.position
      setTimeout(() => {
        this.showloading=false
      }, 1000);
    })
    this.service.getType(this.projectId).subscribe(((res:any)=>{
      this.type_id = res.type_id
    }))

  }
  ngOnDestroy(): void {

  }
  //显示loading
  Loading(){
    this.showloading=true
    setTimeout(() => {
      this.showloading=false
    }, 1000);
  }
  //获取照片数据接口
  getPicture(e:any){
    this.service.getAlbumbyday({whether_all:true,project_id:this.projectId,page:e}).subscribe((res:any)=>{
      this.items = res.position
    })
  }
  //删除图片
  goDelete(){
    if(this.showRight.length>0){
      let r =window.confirm("是否确定删除")
      if (r==true){
        console.log(this.showRight)
        let photostring = this.showRight.toString() 
        this.service.deletePhtotLog(photostring).subscribe((res:any)=>{
          if(res.status=='ok'){
            this.getPicture(this.savenowPage)
            this.showRight=[]
          }
        })
      }
    }
  }
  //前往发布页面
  goWorkpackage(){
      if(this.isselect&&this.showRight.length>0){
        localStorage.setItem('imageid',this.showRight)
        const origin = window.location.origin
        window.location.href =`${origin}/projects/${this.projectName}/work_packages/new?type=${this.type_id}`
      }
  }
  //点击选择按钮
  confirmSelect(){
    this.isselect  =true
  }
  //点击取消按钮
  cancelSelect(){
    this.showRight = []
    this.isselect  =false
    this.saveimg=[]
  }
  //点击选择图片
  selectImg(e:any,url:any){
    this.showRight.push(e) 
    this.saveimg.push(url)
  }
  //取消选择图片
  cancelSelectimg(e:any,url:any){
    　let  index = this.showRight.indexOf(e);
    　let  urlindex = this.showRight.indexOf(url);
        this.showRight.splice(index, 1)
        this.saveimg.splice(urlindex, 1)

  }
  //进入详情页
  toDeatil(e:any,user:any,url:any){
    if(!this.isselect){
    this.Loading()
  this.showDetail = true
  this.saveitemId = e
}else if(this.isselect&&this.showRight.indexOf(e)<0){
  this.selectImg(e,user)
}else if(this.isselect&&this.showRight.indexOf(e)>=0){
  this.cancelSelectimg(e,url)
}
  }
  //隐藏详情页
  tohideDetail(){
    this.showDetail = false
    this.saveimg=[]
  }
  //多选详情页
  goEdit(){
   if(this.isselect&&this.showRight.length>0){
    this.Loading()  
     sessionStorage.setItem('multi','multi')
      this.multitoshowEdit=true
   }
  }
  //显示编辑页面
  toshowEditpage(data:any){
    this.Loading()
    //单选
    this.getsingledata = data
      this.multitoshowEdit=true

  }
  //隐藏编辑耶米阿
  tohideEditpage(){
    this.multitoshowEdit=false
    this.isselect=false
    this.showRight=[]
    this.saveimg=[]
  }

  //分页
  toMushpage(e:any){
      this.savenowPage = e
      this.getPicture(e)
  }
  //上一页
  prepage(){
    if(this.savenowPage==1){
      this.getPicture(1)
    }else{
      this.getPicture(  this.savenowPage-1)
      this.savenowPage--
    }
  }
//下一页
  nextpage(){
    this.getPicture(  this.savenowPage+1)
    this.savenowPage++
  }
  // 更新页面
  torefresh(){
    console.log('jinlailema ')
    this.getPicture(this.savenowPage)
    this.Loading()
    this.isselect = false
    let info = I18n.t('js.photo-log.savesuccess')
    alert(info)
  }


}
