import { Component, OnInit, Input } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "overview",
  templateUrl: "./overview.component.html",
  styleUrls: ["./overview.component.sass"]
})
export class OverviewComponent implements OnInit {
  // 项目数据
  @Input() projectList: any[];
  // 项目任务列表
  public overviewTasks: object;
  // 饼图 配置
  public itemOption: object;
  // 饼图 数据列表
  private itemList: any;
  // 饼图 更新
  public updateItemOptions: any;
  // 柱状图 配置
  public axisOption: object;
  // 柱状图 数据列表
  private axisList: object;
  // 柱状图 更新
  public updateAxisOptions: any;

  // 项目任务 选项
  public tasksSiteID: number;
  // 饼图 选项
  public itemSiteID: number;
  // 柱状图 选项
  public axisSiteID: number;

  constructor(readonly http: HttpClient) {}

  // 饼图自适应
  public axisResponse() {
    if (document.body.offsetWidth > 1366) {
      this.updateItemOptions = {
        series: [
          {
            radius: ["56%", "42%"],
            label: {
              rich: {
                fontSize1: {
                  fontSize: 14,
                  lineHeight: 20
                },
                fontSize2: {
                  fontSize: 12,
                  lineHeight: 16
                }
              }
            },
            labelLine: {
              length: 48,
              length2: 36
            }
          }
        ]
      };
    } else if (document.body.offsetWidth <= 1366 && document.body.offsetWidth > 1024) {
      this.updateItemOptions = {
        series: [
          {
            radius: ["44%", "32%"],
            label: {
              rich: {
                fontSize1: {
                  fontSize: 14,
                  lineHeight: 20
                },
                fontSize2: {
                  fontSize: 12,
                  lineHeight: 16
                }
              }
            },
            labelLine: {
                length: 36,
                length2: 32
            }
          }
        ]
      };
    } else if (document.body.offsetWidth <= 1024 && document.body.offsetWidth > 768) {
      this.updateItemOptions = {
        series: [
          {
            radius: ["44%", "32%"],
            label: {
              rich: {
                fontSize1: {
                  fontSize: 14,
                  lineHeight: 18
                },
                fontSize2: {
                  fontSize: 12,
                  lineHeight: 14
                }
              }
            },
            labelLine: {
              length: 28,
              length2: 24
            }
          }
        ]
      };
    } else if (document.body.offsetWidth <= 768 && document.body.offsetWidth > 700) {
      this.updateItemOptions = {
        series: [
          {
            radius: ["56%", "42%"],
            label: {
              rich: {
                fontSize1: {
                  fontSize: 14,
                  lineHeight: 18
                },
                fontSize2: {
                  fontSize: 12,
                  lineHeight: 14
                }
              }
            },
            labelLine: {
              length: 60,
              length2: 48
            }
          }
        ]
      };
    } else if (document.body.offsetWidth <= 700 && document.body.offsetWidth > 480) {
      this.updateItemOptions = {
        series: [
          {
            radius: ["54%", "40%"],
            label: {
              rich: {
                fontSize1: {
                  fontSize: 14,
                  lineHeight: 18
                },
                fontSize2: {
                  fontSize: 12,
                  lineHeight: 14
                }
              }
            },
            labelLine: {
              length: 50,
              length2: 40
            }
          }
        ]
      };
    } else if (document.body.offsetWidth <= 480) {
      this.updateItemOptions = {
        series: [
          {
            radius: ["54%", "40%"],
            label: {
              rich: {
                fontSize1: {
                  fontSize: 12,
                  lineHeight: 18
                },
                fontSize2: {
                  fontSize: 12,
                  lineHeight: 14
                }
              }
            },
            labelLine: {
              length: 36,
              length2: 20
            }
          }
        ]
      };
    }
  }
  // 图标初始化
  public chartInit() {
    // 柱状图
    this.axisOption = {
      color: {
        type: "linear",
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [
          {
            offset: 0,
            color: "#11b5b5" // 0% 处的颜色
          },
          {
            offset: 1,
            color: "#1bdfb8" // 100% 处的颜色
          }
        ],
        globalCoord: false
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow"
        }
      },
      grid: {
        left: "5%",
        right: "5%",
        bottom: "3%",
        containLabel: true,
        width: "auto"
      },
      xAxis: [
        {
          type: "category",
          axisTick: {
            alignWithLabel: true
          }
        }
      ],
      yAxis: [
        {
          type: "value"
        }
      ],
      series: [
        {
          name: "Tasks",
          type: "bar",
          barWidth: "60%"
        }
      ]
    };
    // 饼图
    this.itemOption = {
      grid: {
        left: "5%",
        right: "5%",
        bottom: "3%",
        containLabel: true,
        width: "auto"
      },
      series: [
        {
          type: "pie",
          radius: ["70%", "50%"],
          legendHoverLink: false,
          hoverAnimation: false,
          hoverOffset: 0,
          label: {
            formatter: "{fontSize1| {b}} \n {fontSize2| {d} %}",
            rich: {
              fontSize1: {
                color: "#393939",
                fontSize: 14,
                lineHeight: 20,
                align: "center"
              },
              fontSize2: {
                color: "#393939",
                fontSize: 12,
                lineHeight: 16,
                align: "center"
              }
            }
          },
          labelLine: {
            length: 36,
            length2: 42,
            lineStyle: {
              color: "#dedede"
            }
          },
          color: ["#15c594", "#f0bf43", "#f05343"],
          data: this.itemList
        }
      ]
    };
    this.axisResponse();
    const self = this;
    let overviewCon = document.querySelectorAll(".overview-con") as NodeListOf<HTMLElement>;
    for (let i = 0; i < overviewCon.length; i++) {
      overviewCon[i].style.height = overviewCon[0].offsetWidth + "px";
    }

    window.onresize = function() {
      let overviewCon = document.querySelectorAll(".overview-con") as NodeListOf<HTMLElement>;
      if (overviewCon[0].offsetHeight !== overviewCon[0].offsetWidth) {
        for (let i = 0; i < overviewCon.length; i++) {
          overviewCon[i].style.height = overviewCon[0].offsetWidth + "px";
        }
      }
      self.axisResponse();
    };
  }

  // 项目任务数据处理
  public tasksDataHandling() {
    this.http.get("/api/v3/projects/" + this.tasksSiteID + "/gantt_chart/overview").subscribe(res => {
      let obj: any = res;
      if (obj.result === "Success") {
        this.overviewTasks = obj.data;
      }
    });
  }

  // 饼图数据处理
  public itemDataHandling() {
    this.http.get("/api/v3/projects/" + this.itemSiteID + "/gantt_chart/overview").subscribe(res => {
      let obj: any = res;
      if (obj.result === "Success") {
        this.itemList = [
          { value: obj.data.working, name: "Working" },
          { value: obj.data.expiring, name: "Expiring" },
          { value: obj.data.outdate, name: "Outdate" }
        ];
        this.updateItemOptions = {
          series: [
            {
              data: this.itemList
            }
          ]
        };
      }
    });
  }
  // 柱状图数据处理
  public axisDataHandling() {
    this.http.get("/api/v3/projects/" + this.axisSiteID + "/gantt_chart/last_week_finished").subscribe(res => {
      let obj: any = res;

      if (obj.result === "Success") {
        // this.axisList = {
        //   date: Object.keys(res.data),
        //   tasks: Object.values(res.data)
        // };
        this.updateAxisOptions = {
          xAxis: [
            {
              data: Object.keys(obj.data)
            }
          ],
          series: [
            {
              data: Object.values(obj.data)
            }
          ]
        };
      }
    });
  }
  ngOnInit() {
    if(this.projectList.length > 0){
      this.tasksSiteID = this.projectList[0].id;
      this.itemSiteID = this.projectList[0].id;
      this.axisSiteID = this.projectList[0].id;
      this.tasksDataHandling();
      this.itemDataHandling();
      this.axisDataHandling();
      this.chartInit();
    }
    
  }
  ngOnDestroy() {}
}
