import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { I18nService } from 'core-app/modules/common/i18n/i18n.service';

declare var GC: any;

@Component({
  selector: 'cunstom-forms-item',
  templateUrl: './cunstom-forms-item.component.html',
  styleUrls: ['./cunstom-forms-item.component.sass']
})
export class CunstomFormsItemComponent implements OnInit {

  public excelcont = false
  public titlereturn = false
  public itemdata = []
  public itemdatavalue = 1
  public excelIo: any
  public projectId: number
  public excelmetadata: []
  public itemdateil: []
  public exceldatail: any
  public displeloading = false
  public widthbock:any

  public csrf_token: any
  public newHeaders: any
  constructor(
    readonly http: HttpClient,
    public i18n: I18nService

  ) {
  }


  ngOnInit() {
    let LicenseKey
    if(window.location.host == "103.82.213.202"){
      LicenseKey = '103.82.213.202,E331992477277567#B0AnyU3CdJ3SRTVjZzsiQjRWelNmYOFlMmxGVTNnUwUkcyFzLOFnZqdlRBhUWyYlWxMGZ7MjQllnerFnaEBVRGd4cy2SeRhnWHNVYjV5crNlWmp7YXtmdkpVeCJXRxYTYZFlb796aP3CbZxUZGV7bZpXUxd5ZDx6MG3UcthjYvM6UKlmVolDMyZjNzM5bJdzT7MzLStUc8JDWlRHdoVjUaJnVj56TahkY8czQWRXNpRlaRtWZl9WNWdTck3UNTZTYOJUTVRVY9wkSJhUbWdUVHlGSPdnUphWQ4E6LWBDOW3CcYV7cpZ6ZMdXS4EETEVUNuR6UhFkaGJiOiMlIsICNxgDOyQTMiojIIJCL9gTN4kTO8ADO0IicfJye&Qf35VfiU5TzYjI0IyQiwiIzEjL6ByUKBCZhVmcwNlI0IiTis7W0ICZyBlIsICOxAzMxADIyEjNwAjMwIjI0ICdyNkIsIiNxcDMxIDMyIiOiAHeFJCLiIDMy8yMxIjLygjLzATMiojIz5GRiwiIPdlTVh4QiojIh94QiwSZ5JHd0ICb6VkIsIyN6UzN7IzN7QjM9kTMzMjI0ICZJJCL3V6csFmZ0IiczRmI1pjIs9WQisnOiQkIsISP3EkSQRTZ9EldndUS624dPN5ZYFGd6lzVul5QmFVSypWOYdWWFlHMuF7MqhDSHdzNYR7N6x4L9kVS796Rrp4Y4pEcnRmYM36Sq9kNXlzSwY4S4kXboFWRX9WdjZDO0Alb5'
      console.log('103.82.213.202,');
    }else if(window.location.host == "mobility-uat.chunwo.com"){
      console.log('mobility-uat.chunwo.com');
      LicenseKey = 'mobility-uat.chunwo.com,E331992477277567#B0fVelDVjh5RpJUSxhnaxBDZxoWZj3mbXJzVC3Cap3mbVtya8M4QHdlNvpmZQFUaPJ5V4skS4IlbtdWO9ZTcppGbvYFcCFlaFtCS9lXVqZWTIx4N5EHVKNzNGhkVQtEbMJzbr2ya9ZTW6dTY5RTOitGZChmW6VlaCRnYvYDVpZ5R82ib6FFWHdjNzY6TmBjUzF6biVzTxZUZ0l5a6MTOpR4UURkUNVmM8MmWxsiVpZmd7NEOLNmTPhmQOx4Q7RWOEREbhhEbmV5MNNUQStWaxMmcaVjQlpmNCBTTp3UOwl5UxlGWkh7MGZHSxRHS7dHcHh5aplzTUJ6NYRlI0IyUiwiIyEDM4UjN5IjI0ICSiwCN5cjM6QDMycTM0IicfJye35XX3JSVPNjNiojIDJCLiMTMuYHITpEIkFWZyB7UiojIOJyebpjIkJHUiwiI8AzM4ETMgUTM7ADMyAjMiojI4J7QiwiI6EzNwEjMwIjI0ICc8VkIsISbvNmLvdnb5h6YuQXY55Se4lGbpJ6btJiOiMXbEJCLi24VOVFSDJiOiEmTDJCLlVnc4pjIsZXRiwiI7YTN7cjM7cDNykTOxMzMiojIklkIs4XZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34zdyVlTUN7MLV7QqxUM886L7RFVYdlbvEDWhJXW5UFTaFDdvdnWJhmbJ3CNld4Uydla4MXMw3GM4hjeE3CU5U6bkxEUvhXYwUENygUWGlld9d4ZqZ7Q8c4SMFwU55'
    }else{
      console.log('Chun Wo Construction & Engineering Co Ltd,mobility.chunwo.com,');
      LicenseKey = 'Chun Wo Construction & Engineering Co Ltd,mobility.chunwo.com,433369283719154#B0s08Q9ETcVhDbnhXQk9WR5hVOLB7cvM7SC3WZalUZ9QETQtCcVVmQ4xEZTZFO7MnYHRTUkh6dthHMM3CN6MlYm9GWyUldxtUUX3SV8oUZtdVZrd5KhljRyMVS8YUVmZTZ4JkbqVGZ8EUbxQ5KH5mYyMjU73UdzgDcCdnbEVzR6FXNmpVWGRnVKBVZvlmRtdVVUNDMWNXOwYESu5WRChXaHJzRPZlZ8oGMFx4ZIpHdplkUvUDbL9kdsFzKWJTN7JnavkWSll6blRUQrMEVk5GWyZ5MC3mN6VTQCNjZilzZxZXVjpUM5gEVY5mapdHevw4UENHdrFzZBRXRyBXZiojITJCLiEzQwUTN8EkI0ICSiwCMxIjMxUDO9QTM0IicfJye#4Xfd5nIV34M6IiOiMkIsIyMx8idgMlSgQWYlJHcTJiOi8kI1tlOiQmcQJCLiQjMxUjMwASMxYDMwIDMyIiOiQncDJCLi46bj9yb79WdoNmL9RXaslmYv5mI0IyctRkIsICZ4xEIvNEIn9WayVWZul6ZuVEImAibvlGdjVnc4NnbvNEIvdFIuVHaDJiOiEmTDJCLiQTNxkTM7MDOykjNzMzM4IiOiQWSiwSflNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPnhVM4Z6YDh7Uo3UVz8UTHpVYEFVYvsSWUFWONdGOz9kS7UUSz9mUtlEOiRVNKRUcotyYUxkc8wUeHF4TYBnRaRUMWtkUQhWetRnQZVTe5kmaPRFd8oVVuNqe'
    }
    GC.Spread.Sheets.LicenseKey = (GC.Spread.Excel.IO as any).LicenseKey = LicenseKey
    this.excelIo = new GC.Spread.Excel.IO();
    const currentProject: any = document.querySelector('meta[name=current_project]');
    this.projectId = currentProject.dataset.projectId
    this.csrf_token = jQuery("meta[name=csrf-token]").attr("content");
    let newHeaders: any = {};
    newHeaders["X-Authentication-Scheme"] = "Session";
    newHeaders["X-Requested-With"] = "XMLHttpRequest"
    if (this.csrf_token) {
      newHeaders["X-CSRF-TOKEN"] = this.csrf_token;
    }
    this.newHeaders = newHeaders
    this.titledatail()


  }

  titledatail() {
    const that = this
    console.log(that.projectId);

    that.http.get(
      `api/v3/projects/${that.projectId}/custom_forms/custom_form_types`,
      {
        withCredentials: true,
        headers: that.newHeaders
      }
    ).subscribe((res: any) => {
      console.log(res);
      that.itemdata = res.data
      that.itemdatavalue = res.data[0].id
      that.getitem(res.data[0].id)
    });
  }

  //去掉表格零值设置
  textprointpo() {
    var oldPaint = GC.Spread.Sheets.CellTypes.Text.prototype.paint;
    GC.Spread.Sheets.CellTypes.Text.prototype.paint = function (ctx: any, value: any, x: any, y: any, w: any, h: any, style: any, context: any) {
      if (value === 0) {
        oldPaint.call(this, ctx, "", x, y, w, h, style, context);
      }
      else {
        oldPaint.apply(this, arguments);
      }
    }
  }

  ngAfterViewInit() {
    console.log(jQuery('#ainout')[0].offsetWidth);
    this.widthbock = jQuery('#ainout')[0].offsetWidth  +"px"
    
    const file = document.getElementById("file") as any;
    const that = this
    file.addEventListener("change", function (fileaa: any) {
      console.log("change", fileaa);
      if(fileaa.target.files.length == 0) return
      // that.displeloading = true
      jQuery(".xianshiload").css('display','block')

      let filedata = fileaa.target.files[0]
      that.excelIo.open(filedata, (json: any) => {
        that.textprointpo()
        let datail: any = []
        for (let key in json.sheets) {
          if (json.sheets[key].index != 0 || json.sheets[key].index != 1) {
            datail.push({ index: json.sheets[key].index, name: json.sheets[key].name })
            console.log({ index: json.sheets[key].index, name: json.sheets[key].name });
          }
        }
        console.log(filedata);
        let filels = new FormData();
        filels.append('file', filedata);
        filels.append('metadata', JSON.stringify(datail));

        that.http.post(
          `api/v3/projects/${that.projectId}/custom_forms/types/${that.itemdatavalue}/excels`,
          filels,
          {
            withCredentials: true,
            headers: that.newHeaders
          }
        ).subscribe((res: any) => {
          that.getitem(that.itemdatavalue)
        });

      }, (err: any) => {
        console.log(err)
      });
    });
  }

  inputfile(){
    
  }

  clickreturn() {
    this.excelcont = false
    this.titlereturn = false
  }


  clickexcel(datail: any) {
    this.exceldatail = datail
    this.excelcont = true
    this.titlereturn = true
  }

  titleclick(value: any) {
    // this.displeloading = true
    jQuery(".xianshiload").css('display','block')
    this.itemdatavalue = value
    this.excelcont = false
    this.titlereturn = false
    this.getitem(value)

  }

  getitem(id: any) {
    const that = this
    that.http.get(
      `api/v3/projects/${that.projectId}/custom_forms/types/${id}/excels`,
      {
        withCredentials: true,
        headers: that.newHeaders
      }
    ).subscribe((res: any) => {
      console.log(res);
      that.itemdateil = res.data
      // that.displeloading = false
      jQuery(".xianshiload").css('display','none')
    });
  }

  delexcel(item: any) {
    var r = confirm(this.i18n.t("js.cunstom-forms.deletetemplate"));
    if (r == true) {
        let newHeaders = this.newHeaders
        // this.displeloading = true
        jQuery(".xianshiload").css('display','block')
        newHeaders['Content-Type'] = 'application/json'
        const that = this
        that.http.delete(
          `api/v3/projects/${that.projectId}/custom_forms/types/${that.itemdatavalue}/excels/${item.id}`,
          {
            withCredentials: true,
            headers: newHeaders
          }
        ).subscribe((res: any) => {
          console.log(res);
          that.getitem(that.itemdatavalue)
        });
    }
    else {
    }

  }

}
