import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { query } from "@angular/animations";

@Injectable({
  providedIn: "root",
})
export class E_liftingService {
  private currentId = {
    projectId: "",
    id:"",
    userId: "",
  };

  apiPrefix: any = "/api/v3";
  //请求头
  requestHeader: any = {
    withCredentials: true,
    headers:{
      "X-Authentication-Scheme": "Session",
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-Token":jQuery("meta[name=csrf-token]").attr("content")
    }

  };
  requestJsonHeader: any = {
    withCredentials: true,
    headers: {
      "X-Authentication-Scheme": "Session",
      "X-Requested-With": "XMLHttpRequest",
      "Content-Type": "application/json",
      "X-CSRF-Token":jQuery("meta[name=csrf-token]").attr("content")

    },
  };
  //API前缀
  constructor(private http: HttpClient) {
    this.getCurrentId();
  }
  //获取当前项目和当前用户ID
  getCurrentId() {
    this.currentId = {
      projectId: jQuery("meta[name=current_project]").data("project-identifier"),
      id: jQuery("meta[name=current_project]").data("id"),
      userId: jQuery("meta[name=current_user]").data("id"),
    };
  }
  //获取role的列表
  getRole(){
    return this.http.get(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/labours/role`)
  }
  //创建labour
  createLabour(data: any) {
    return this.http.post(
      `${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/labours`,
      data,
      this.requestJsonHeader
    );
  }
  //更新labour数据
  updateLabour(id: any, data: any) {
    return this.http.patch(
      `${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/labours/${id}`,
      data,
      this.requestJsonHeader
    );
  }
  // 更新cert数据
  updateCert(id: any, data: any) {
    return this.http.patch(
      `${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/certs/${id}`,
      data,
      this.requestJsonHeader
    );
  }
  //刪除證書記錄
  deleteCert(id:any){
    return this.http.delete(
      `${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/certs/${id}`,
      this.requestJsonHeader
    );
  }
  //刪除labour記錄
  deleteLabour(id: any) {
    return this.http.delete(
      `${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/labours/${id}`,
      this.requestJsonHeader
    );
  }
  //创建证书关联
  createCert(data: any) {
    return this.http.post(
      `${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/certs`,
      data,
      this.requestJsonHeader
    );
  }
  //獲取labour列表
  getLabourList(type: any,page:any) {
    return this.http.get(
      `${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/labours/index?labour_type=${type}`,{params:{page,per_page:'10'}}
    );
  }
  //获取单个labour
  getSignLabour(id:any,type: any) {
    return this.http.get(
      `${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/labours/${id}?with_driving_license=${type}`,this.requestJsonHeader
    );
  }

  //获取雇主id
  getEmployer() {
    return this.http.get(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/employer?page=1&per_page=999`);
  }

  //編輯時獲取用戶基本信息
  getUserDetail(id: any) {
    return this.http.get(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/labours/${id}`);
  }

  /************************************************************************* */
  // 創建palant信息
  createPlant(data: any) {
    return this.http.post(
      `${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/plants`,
      data,
      this.requestJsonHeader
    );
  }
  //获取plant列表
  getPlantList(data: any,page:any) {
    if (data == "all") {
      return this.http.get(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/plants`,{params:{page,per_page:'10'}});
    } else {
      return this.http.get(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/plants`, {
        params: { type: data,page,per_page:'10' },
      });
    }
  }

  //刪除plant記錄
  deletePlant(id: any) {
    return this.http.delete(
      `${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/plants/${id}`,
      this.requestJsonHeader
    );
  }
  //获取单个plant的详情
  plantDetail(id:any){
    return this.http.get(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/plants/${id}`)
  }
  //更新plant的信息
  updatePlant(id:any,data:any){
    console.log('updatePlant',id)
    return this.http.patch(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/plants/${id}`,data,this.requestJsonHeader)
  }

  /**************************************申请信息*************************************/
  //赋值application
  copyApplication(id:any){
    return this.http.post(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/applications/${id}/copy`,'',this.requestJsonHeader)
  }
  //上传图片
  update_img(data:any){
    return this.http.post(`/projects/${this.currentId.projectId}/lifting_photos/upload`,data,this.requestHeader)
  }
  //获取角色列表
  getApprover(){
    return this.http.get(`${this.apiPrefix}/projects/${this.currentId.projectId}/role_users?role_name[]=Lifting Approver&role_name[]=Lifting Manager`)
  }
  //获取location的列表
  getLocation(){
    return this.http.get(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/work_location?page=1&per_page=999`,this.requestJsonHeader)
  }
  //创建loaction信息
  createLocation(data:any){
    return this.http.post(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/work_location`,data,this.requestJsonHeader)
  }
  //创建e-lifting的申请信息
  createApplication(data:any){
    return this.http.post(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/applications`,data,this.requestJsonHeader)
  }
  //編輯e-lifting的申請信息
  editApplication(id:any,data:any){
    return this.http.patch(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/applications/${id}`,data,this.requestJsonHeader)
  }
  //獲取添加人員或者機器的列表
  getTableList(role:any,type:any){
     return this.http.get(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/${role}/index`,{params:{type,page:'1',per_page:'999'}})
  }
  //獲取申請列表
  getApplicationList(page:any,type:any,value:any){
    return this.http.get(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/applications?${type}=${value}`,{params:{page,per_page:'10'}})
  }
  //刪除申請列表
  deleteApplication(id:any){
    return this.http.delete(
      `${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/applications/${id}`,
      this.requestJsonHeader
    );
  }
  //获取材料选项
  getMaterialsList(id:any){
    return this.http.get(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/material_items?application_id=${id}`,this.requestJsonHeader)
  }
  //添加材料選項
  addMaterialItem(data:any){
    return this.http.post(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/material_items`,data,this.requestJsonHeader)
  }
  //刪除材料選項
  deleteMaterialItem(appId:any,id:any){
    return this.http.delete(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/material_items/${id}?application_id=${appId}`,this.requestJsonHeader)
  }
  //修改材料選項
  changeMaterialItem(id:any,data:any){
    return this.http.patch(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/material_items/${id}`,data,this.requestJsonHeader)
  }
  //批量更新材料選項
  changeMaterialGroup(data:any){
    return this.http.patch(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/material_items/batch_update_material_items`,data,this.requestJsonHeader)
  }
  //申请的时候添加设备
  applicationAddPlant(id:any,data:any){
    return this.http.post(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/applications/${id}/add_plant`,data,this.requestJsonHeader)
  }
  //申请的时候修改设备
  applicationEditPlant(id:any,data:any){
    return this.http.patch(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/applications/${id}/update_plant`,data,this.requestJsonHeader)

  }
  //申请的时候刪除设备
  applicationDeletePlant(id:any,plant_id:any){
    return this.http.delete(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/applications/${id}/remove_plant?plant_item_id=${plant_id}`,this.requestJsonHeader)
  }
  //申请的时候添加人员
  applicationAddLabour(id:any,data:any){
    return this.http.post(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/applications/${id}/add_labour`,data,this.requestJsonHeader)
  }
  //申请的时候编辑人员
  applicationEditLabour(id:any,data:any){
    return this.http.patch(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/applications/${id}/update_labour`,data,this.requestJsonHeader)
  }
  //申请的时候删除人员
  applicationDeleteLabour(id:any,labour_id:any,role:any){
    return this.http.delete(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/applications/${id}/remove_labour?assigned_labour_id=${labour_id}&&role=${role}`,this.requestJsonHeader)
  }
  //獲取檢查項列表
  getChecklistItem(id:any){
    return this.http.get(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/checklist_items?application_id=${id}`,this.requestJsonHeader)
  }
  //編輯檢查項
  editCheckItem(id:any,data:any){
    return this.http.patch(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/checklist_items/${id}`,data,this.requestJsonHeader)
  }
  //添加檢查項
  addCheckItem(data:any){
    return this.http.post(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/checklist_items`,data,this.requestJsonHeader)
  }
  //刪除檢查項
  deleteCheckItem(cid:any,aid:any){
    return this.http.delete(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/checklist_items/${cid}?application_id=${aid}`,this.requestJsonHeader)
  }

  /********************************************************编辑页面***************************************************************** */
  //获取application的基本信息
  viewApplicationBaseMessage(id:any){
    return this.http.get(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/applications/${id}`,this.requestJsonHeader)
  }
  //获取application的设备信息
  viewApplicationPlant(id:any,role:any){
    return this.http.get(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/applications/${id}/plants?type=${role}`,this.requestJsonHeader)
  }
  //获取application的人员信息
  viewApplicationLabour(id:any,role:any){
    return this.http.get(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/applications/${id}/labours?role=${role}`,this.requestJsonHeader)
  }
  //获取审核章节的列表
  viewSection(id:any){
    return this.http.get(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/applications/${id}/sections`)
  }
  //更新审核列表
  updateSection(id:any,data:any){
    return this.http.patch(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/applications/${id}/update_section`,data,this.requestJsonHeader)
  }
  //申请添加签名
  updateSign(id:any,data:any){
    return this.http.patch(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/applications/${id}/sign`,data,this.requestJsonHeader)
  }

  /**********************************************申请的审核接口******************************************************/
  //更新审核状态
  updateApplication(id:any,data:any){
    return this.http.patch(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/applications/${id}/check`,data,this.requestJsonHeader)
  }
  
  //提交申请
  sumbitApplication(id:any,data:any){
    return this.http.patch(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/applications/${id}/submit`,data,this.requestJsonHeader)
  }
  //批准申请（修改申请的状态为已通过）
  approveApplication(id:any,data:any){
    return this.http.patch(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/applications/${id}/approve`,data,this.requestJsonHeader)
  }
  //拒绝申请（修改申请的状态为已拒绝）
  rejectApplication(id:any,data:any){
    return this.http.patch(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/applications/${id}/reject`,data,this.requestJsonHeader)
  }
    //申请终止（修改申请的状态为待终止）
  requestTerminateApplication(id:any,data:any){
    return this.http.patch(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/applications/${id}/request_terminate`,data,this.requestJsonHeader)
  }
      //终止申请（修改申请的状态为已终止）
  terminateApplication(id:any,data:any){
    return this.http.patch(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/applications/${id}/terminate`,data,this.requestJsonHeader)
  }
  //获取sub_type /api/v3/projects/{project_id}/lifting/plants/sub_types
  getSubtype(){
    return this.http.get(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/plants/sub_types`)
  }

  //获取other sub_type
  getOtherSubType(){
    return this.http.get(`${this.apiPrefix}/projects/${this.currentId.projectId}/lifting/plants/other_sub_types`)
  }
  
  
}

