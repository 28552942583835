import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { I18nService } from "core-app/modules/common/i18n/i18n.service";
import { E_liftingService } from "../services/e_lifting.service";

declare let laydate: any;

@Component({
  selector: "e_lifting-plant",
  templateUrl: "./e_lifting-plant.component.html",
  styleUrls: ["./e_lifting-plant.component.sass"],
})
export class E_liftingPlantComponent implements OnInit {
  public createNew: boolean = true;
  public editNow: boolean = false;
  public data: any = {
    sub_type: "lifting gear",
  };
  public host: any = window.location.origin;
  public qrId: any;
  public type: any = "all";
  public showLoading: boolean = false;
  public saveId: any;
  public tableList: any = [];
  public saveNum: any = [];
  public saveIndex: any = 1;
  public count: any = 0;
  public saveSubType:any =[]
  public saveOtherSubType:any =[]
  

  constructor(public i18n: I18nService, public service: E_liftingService) {}

  ngOnInit() {
    this.getList(this.type, this.saveIndex);
    this.getSub()
  }
  //获取plant的列表
  getList(e: any, index: any) {
    this.service.getPlantList(e, index).subscribe((res: any) => {
      this.saveNum = [];
      this.tableList = res.list;
      this.count = Math.ceil(res.total_count / 10);
      for (let i: any = 0; i < this.count; i++) {
        this.saveNum.push(i);
      }
    });
  }
  //获取subtype的数据
  getSub(){
    this.service.getSubtype().subscribe((res:any)=>{
      this.saveSubType = res
    })
    this.service.getOtherSubType().subscribe((res:any)=>{
      this.saveOtherSubType = res
    })
  }
  //選擇不同的類型
  changeSelect(e: any, i: any) {
    if (!e.target.value) {
      return;
    }
    this.type = e.target.value;
    this.getList(this.type, this.saveIndex);
  }
  //动态绑定证书的日期
  dateChange(type: any) {
    laydate.render({
      elem: `#${type}`,
      show: true,
      lang: "en",
      value: this.data[type],
      done: (value: any) => {
        this.data[type] = value;
      },
    });
  }
  //前往创建页面
  toCreate() {
    this.createNew = false;
  }
  //前往编辑页面
  toEdit(e: any) {
    this.showLoading = true;
    this.createNew = false;
    this.saveId = e;
    console.log(e,this.saveId)
    this.editNow = true;
    this.service.plantDetail(e).subscribe((res: any) => {
      let savetype;
      const { qr_code_id, type, cert } = res;
      if (type) {
        savetype = this.toLine(type);
        this.data.type = savetype;
      }
      this.data = res;
      if (cert) {  
        this.data.cert_id = cert.id
        this.data.cert_no = cert.reference_no;
        this.data.cert_valid_date_from = cert.cert_valid_date_from;
        this.data.cert_valid_date_to = cert.cert_valid_date_to;
      }
      if (this.data.type != "LiftingGear") {
        if(this.data.working_radius){
          this.data.working_radius_from = this.data.working_radius.split("-")[0];
          this.data.working_radius_to = this.data.working_radius.split("-")[1];
        }
        if(this.data.working_capacity){
          this.data.working_capacity_from = this.data.working_capacity.split("-")[0];
          this.data.working_capacity_to = this.data.working_capacity.split("-")[1];
        }
      }

      this.qrId = `${this.host}/api/v3/logical_docs/doc/${qr_code_id}/stream`;
      this.showLoading = false;
    });
  }
  //驼峰转下划线
  toLine(name: any) {
    return (
      name
        .replace(/([A-Z])/g, "_$1")
        .toLowerCase()
        .slice(1) + "s"
    );
  }
  commonChange(e:any){
      if(e.type=='TowerCrane'){
        e.sub_type='tower'
      }
      if(e.working_radius_from){
        if(!e.working_radius_to){
          e.working_radius_to = e.working_radius_from
        }
        e.working_radius = `${e.working_radius_from}-${e.working_radius_to}`;
      }
      if(e.working_capacity_from){
        if(!e.working_capacity_to){
          e.working_capacity_to = e.working_capacity_from
        }
        e.working_capacity = `${e.working_capacity_from}-${e.working_capacity_to}`;
      }

      delete e.working_capacity_from;
      delete e.working_capacity_to;
      delete e.working_radius_from;
      delete e.working_radius_to;
      return e
    
  }
  //提交创建数据
  toSubmit() {
    if(!this.data.type){
      alert('請選擇plant的類型')
      return
    }
    //同时为空，或者同时不为空
    if (
      (this.data.cert_no &&
        this.data.cert_valid_date_to) ||
      (!this.data.cert_no &&
        !this.data.cert_valid_date_to)
    ) {
      if (this.data.type != "LiftingGear") {
      this.data = this.commonChange(this.data)
      }
      
      this.showLoading = true;
      this.service.createPlant(this.data).subscribe((res: any) => {
        // 判斷有沒有填寫證書
        if (this.data.cert_no) {
          const { cert_no, cert_valid_date_from, cert_valid_date_to } = this.data;
          let owner_id = res.id;
          this.service
            .createCert({
              reference_no: cert_no,
              cert_valid_date_from,
              cert_valid_date_to,
              kind: "plant",
              owner_type: "LiftingPlant",
              owner_id,
            })
            .subscribe((res: any) => {
              this.reset();
            });
        } else {
          this.reset();
        }
      });
    } else {
      alert("cert no、cert expire date to這两個字段，只要填寫了其中之一，則必須全部填寫");
      return
    }
  }
  //提交更新数据
  toUpdate() {
    console.log(this.saveId)
    this.showLoading = true;
    //同时为空，或者同时不为空
    if (
      (this.data.cert_no &&
        this.data.cert_valid_date_to) ||
      (!this.data.cert_no &&
        !this.data.cert_valid_date_to)
    ) {
      delete this.data.created_by
      delete this.data.created_by
      delete this.data.updated_at
      delete this.data.updated_by
      if (this.data.type != "LiftingGear") {
        this.data = this.commonChange(this.data)
        }
      this.service.updatePlant(this.saveId, this.data).subscribe((res: any) => {
        if (this.data.cert_no) {
          const { cert_id,cert_no, cert_valid_date_from, cert_valid_date_to } = this.data;
          console.log('this.data',this.data)
          let owner_id = res.id;
          this.service
            .updateCert(cert_id?cert_id:this.data.id,{
              reference_no: cert_no,
              cert_valid_date_from,
              cert_valid_date_to,
              kind: "plant",
              owner_type: "LiftingPlant",
              owner_id,
            })
            .subscribe((res: any) => {
              this.reset();
            });
        } else {
          this.reset();
        }
      });
    } else {
      alert("cert no、cert expire date to這两個字段，只要填寫了其中之一，則必須全部填寫");
      return
    }
  }
  //退出編輯或退出創建
  toCancel() {
    let msg = "確定退出創建或者編輯plant嗎？";
    if (confirm(msg) == true) {
      this.reset();
    }
  }
  //删除记录
  toDelete(e: any) {
    let msg = "確定刪除該記錄嗎？";
    if (confirm(msg) == true) {
      this.showLoading = true;
      this.service.deletePlant(e).subscribe((res: any) => {
        this.showLoading = false;
        this.getList(this.type, this.saveIndex);
      });
    }
  }
  //重置狀態
  reset() {
    this.createNew = true;
    this.data = {
      sub_type: "lifting gear",
    };
    this.saveId = undefined;
    this.showLoading = false;
    this.editNow = false;
    this.getList(this.type, this.saveIndex);
  }
  //分頁
  changePage(i: any) {
    if (i < 1 || i > this.saveNum.length) {
      return;
    }
    (this.saveIndex = i), this.getList(this.type, this.saveIndex);
  }
}
