<div [attr.id]="locals.contextMenuId"
     class="dropdown-relative dropdown -overflow-in-view"
     ng-class="{'dropdown-anchor-right': locals.showAnchorRight}">
  <ul class="dropdown-menu "
      role="menu"
      [ngClass]="{'-empty': items.length === 0 }">
    <ng-container *ngFor="let item of items">
        <li *ngIf="item.divider && !item.hidden" class="dropdown-divider"></li>
        <li *ngIf="!item.divider && !item.hidden">
          <a class="menu-item {{ item.class }}"
            tabindex="0"
            [ngClass]="{ 'inactive': item.disabled }"
            [attr.href]="item.href || ''"
            [attr.aria-disabled]="item.disabled"
            [attr.aria-label]="item.ariaLabel || item.linkText"
            (accessibleClick)="handleClick(item, $event)"
            [accessibleClickStopEvent]="false">
            <op-icon *ngIf="item.icon" icon-classes="icon-action-menu {{ item.icon }}"> </op-icon>
            <span [textContent]="item.linkText"></span>
            <op-icon *ngIf="item.postIcon"
                    [icon-title]="item.postIconTitle || ''"
                    icon-classes="icon-action-menu-post {{ item.postIcon }}"> </op-icon>
          </a>
        </li>
    </ng-container>
  </ul>
</div>
