<div class="content" id="siteDiary_details" *ngIf="summaryData">
  <div class="summary-key-value">
    <div class="summary-key">File Ref No:</div>
    <span class="address">{{fileData}}</span>
  </div>
  <div class="dayDate">
    <div class="title">Date：</div>
    <span class="text">{{summaryData.daily_date}}</span>
    <div class="title">Day：</div>
    <span class="text">{{day[summaryData.week]}}</span>
  </div>
  <div class="summary-key-value">
    <div class="summary-key">Day counted from the date of contract commencement：</div>
    <input type="text" class="summary-value" disabled [(ngModel)]="summaryData.project_effective_days"/>
  </div>
  <div class="summary-key-value">
    <div class="summary-key">Weather(A.M)：</div>
    <select class="summary-value" style="width: 450px;" [(ngModel)]="summaryData.morning_weather">
      <option *ngFor="let item of selectDate" [value]="item.value">{{item.name}}</option>
    </select>
  </div>
  <div class="summary-key-value">
    <div class="summary-key">Weather(P.M)：</div>
    <select class="summary-value" style="width: 450px;" [(ngModel)]="summaryData.afternoon_weather">
      <option *ngFor="let item of selectDate" [value]="item.value">{{item.name}}</option>
    </select>
  </div>
  <div class="summary-key-value">
    <div class="summary-key">Rainfall(mm)：</div>
    <input class="summary-value" id="rainfallInput"  [(ngModel)]="summaryData.rainfall" type="number"/>
  </div>
  <div class="summary-key-value">
    <div class="summary-key">Weather Warning：</div>
    <input type="text" class="summary-value" [(ngModel)]="summaryData.weather_warning"/>
  </div>
  <div class="summary-key-value">
    <div class="summary-key">Weather Warning（2）：</div>
    <input class="summary-value" [(ngModel)]="summaryData.rain_storm_warning"/>
  </div>
  <div class="summary-key-value">
    <div class="summary-key">Instructions：</div>
    <textarea class="summary-value" (keydown)='setHight($event)' [(ngModel)]="summaryData.instructions">
    </textarea>
  </div>
  <div class="summary-key-value">
    <div class="summary-key">Comments：</div>
    <textarea class="summary-value" (keydown)='setHight($event)' [(ngModel)]="summaryData.comments">
    </textarea>
  </div>
  <div class="summary-key-value">
    <div class="summary-key">Progress：</div>
    <textarea class="summary-value" (keydown)='setHight($event)' [(ngModel)]="summaryData.progress">\
    </textarea>
  </div>
  <div class="summary-key-value">
    <div class="summary-key">Visitor：</div>
    <textarea class="summary-value" (keydown)='setHight($event)' [(ngModel)]="summaryData.visitor">
    </textarea>
  </div>
  <div class="summary-key-value">
    <div class="summary-key">Site Staff：</div>
    <div class="summary-value" style="line-height: 35px;" (click)="toAddSfAndAc('site_staffs')">{{summaryData.site_staff}}.0</div>
  </div>
  <div class="summary-key-value">
    <div class="summary-key">Assistance：</div>
    <div class="summary-value" style="line-height: 35px;" (click)="toAddSfAndAc('assistances')">{{summaryData.assistance}}.0</div>
  </div>
  <div class="summary-key-value">
    <div class="summary-key">Remarks：</div>
    <textarea class="summary-value" (keydown)='setHight($event)' [(ngModel)]="summaryData.remarks">
    </textarea>
  </div>
  <div class="summary-key-value">
    <div class="summary-key">Site Diary SIOW：</div>
    <select class="summary-value" style="width: 450px;" [(ngModel)]="summaryData.pm_signed_id">
      <option *ngFor="let item of pmUserData" [value]="item.id">{{item.name}}</option>
    </select>
  </div>
  <div class="summary-key-value">
    <div class="summary-key">Site Diary Contractor：</div>
    <select class="summary-value" style="width: 450px;" [(ngModel)]="summaryData.contractor_signed_id">
      <option *ngFor="let item of contractorUserData" [value]="item.id">{{item.name}}</option>
    </select>
  </div>
  <div class="summary-key-value">
    <div class="summary-key">Site Diary Supervisor：</div>
    <select class="summary-value" style="width: 450px;" [(ngModel)]="summaryData.supervisor_signed_id">
      <option *ngFor="let item of supervisorUserData" [value]="item.id">{{item.name}}</option>
    </select>
  </div>
</div>


<SiteDiary-Details-Pupop *ngIf="displayPupop" (close)="Pupopclose()" [pupopType]="pupopType" [summaryData]='summaryData'></SiteDiary-Details-Pupop>