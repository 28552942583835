<div class="wage" (click)="closeDialog()">
  <div class="dialog" (click)="$event.stopPropagation()">
    <h1>
      Add Photo To Progress
    </h1>
    <p>
      <span>select Progress</span>
      <select [(ngModel)]="progressSelect">
        <option *ngFor="let item of progressList" [value]="item.id">{{item.title}}</option>
      </select>
    </p>
    <button class="Add" (click)="addToProgress()">Add</button>
  </div>
</div>
