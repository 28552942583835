import { Component, OnInit } from '@angular/core';
import { I18nService } from 'core-app/modules/common/i18n/i18n.service';
import { DocumentsService } from '../../services/check.lists.service';
@Component({
  selector: 'check_list-templateCopy',
  templateUrl: './check_list-templateCopy.component.html',
  styleUrls: ['./check_list-templateCopy.component.sass']
})
export class Check_listTemplateCopyComponent implements OnInit {
  public show =  false
  public projectSelect:any = null
  public projects:any = []
  public id:any = null
  public succussful:boolean = false
  constructor(
    public i18n: I18nService,
    public service: DocumentsService,
  ) { }

  ngOnInit() {
    this.service.templateCopy.subscribe( (res:any) => {
      if(res.type === 'copy') {
        this.show = true
        this.id = res.id
      }
    })
    this.service.getProjectlist().subscribe( (res:any) => {
      this.projects = res.projects
      console.log(res)
    })
  }
  copyModuleItem () {
    if(this.projectSelect) {
      console.log(this.projectSelect)
      this.service.copyCheckListTemplate(this.id, this.projectSelect).subscribe( (res) => {
          this.succussful = true
          setTimeout(() => {
            this.succussful = false
            this.show = false
          }, 1000)
      },
      (err:any) => {
        alert(err)
      })
    } else {
      alert('请选择项目')
    }

  }

}
