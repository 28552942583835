<div *ngIf="createNew">
  <div class="content">
    <!-- 左边的div -->
    <div class="leftcontent">
      
      <!-- 第一行 -->
      <!-- <h1>e-Lifting</h1>
      <ul class="toptab">
        <li (click)="changeDay('today')" [ngClass]="{ isselect: saveDay == 'today' ? true : false }">Today</li>
        <li (click)="changeDay('yesterday')" [ngClass]="{ isselect: saveDay == 'yesterday' ? true : false }">
          Yesterday
        </li>
        <li (click)="changeDay('before')" [ngClass]="{ isselect: saveDay == 'before' ? true : false }">
          1 day and before
        </li>
        <li (click)="changeDay('all')" [ngClass]="{ isselect: saveDay == 'all' ? true : false }">All</li>
      </ul> -->
      <!-- 第二行 -->
      <div class="tabletop">
        <!-- 表格上面的下拉框 -->
        <span class="bigSpan">E-Lifting</span>
        <button class="creatbtn icon-add" (click)="toCreate()" *ngIf="applicantFlag">Create</button>
      </div>
      <ul class="toptab subtab">
        <li style="background-color: #007bff">
          <p *ngIf="statusNum&&!statusNum.init">0</p>
          <p *ngIf="statusNum&&statusNum.init">{{statusNum.init}}</p>
          <p>Draft</p>
        </li>
        <li style="background-color: #798189">
          <p *ngIf="statusNum&&!statusNum.pending">0</p>
          <p *ngIf="statusNum&&(statusNum.pending)">{{statusNum.pending}}</p>
          <p>Pending</p>
        </li>
        <li style="background-color: #28a745">
          <p *ngIf="statusNum&&!statusNum.approved">0</p>
          <p *ngIf="statusNum&&(statusNum.approved)">{{statusNum.approved}}</p>
          <p>Approved</p>
        </li>
        <li style="background-color: #dc3545">
          <p *ngIf="statusNum&&!statusNum.rejected">0</p>
          <p *ngIf="statusNum&&statusNum.rejected">{{statusNum.rejected}}</p>
          <p>Rejected</p>
        </li>
        <li style="background-color: #ffc107">
          <p *ngIf="statusNum&&!statusNum.terminated">0</p>
          <p *ngIf="statusNum&&statusNum.terminated">{{statusNum.terminated}}</p>
          <p>Terminated</p>
        </li>
      </ul>
    </div>
    <!-- 右边的div -->
    <!-- <div class="rightContent"></div> -->
  </div>
  <!-- 下面的表格 -->
  <div class="table">
    <div class="tablecontent">
      <e_lifting-table (viewShow)="viewShow($event)" (editShow)="editShow($event)" (statusNum)="saveStatusNum($event)" (roleName)="saveRoleName($event)"></e_lifting-table>
    </div>
  </div>
</div>

<div *ngIf="!createNew && !showView">
  <e_lifting-create (cancelShow)="cancelCreate()" (saveDraft)="saveDraft()" [applicationConfig]="applicationConfig"></e_lifting-create>
</div>

<div *ngIf="!createNew && showView">
  <e_lifting-view (hideView)="hideView()" [viewApplicationId]="viewApplicationId" [roleName]="roleName"></e_lifting-view>
</div>
