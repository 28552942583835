import { Component, OnInit, ChangeDetectorRef, Output } from "@angular/core";
import { E_liftingService } from "./services/e_lifting.service";
import { I18nService } from "core-app/modules/common/i18n/i18n.service";
import { formatDate } from "@angular/common";
import { HttpClient } from '@angular/common/http';


@Component({
  templateUrl: "./e_lifting.component.html",
  styleUrls: ["./e_lifting.component.sass"],
})
export class E_liftingComponent implements OnInit {
  public showView: boolean = false;
  public datenow: any;
  public createNew: boolean = true;
  public saveDay: any = "today";
  public applicationConfig: any;
  public viewApplicationId:any
  public statusNum:any
  public applicantFlag:boolean = false
  public approverFlag:boolean = false
  public roleName:any
  constructor(public service: E_liftingService, public i18n: I18nService,
    private http: HttpClient
    ) {}

  ngOnInit() {
    this.formatTime();
  }
  //保存角色
  saveRoleName(e:any){
    if(e=='isApplicant'){
      this.applicantFlag = true
    }else if(e=='isApprover'){
      this.approverFlag = true
    }else{
      this.applicantFlag = true
      this.approverFlag = true
    }
  }
  //时间格式化
  formatTime() {
    let time = new Date();
    this.datenow = `${time.getFullYear()}-${time.getMonth() + 1}-${time.getDate()}`;
  }
  //修改筛选条件
  changeDay(e: any) {
    this.saveDay = e;
  }
  toCreate() {
    this.service.createApplication({}).subscribe((res: any) => {
      //新建的时候保存全局的application
      this.applicationConfig = res;
      this.createNew = false;
    });
  }
  cancelCreate() {
    this.createNew = true;
  }
  saveDraft() {
    this.createNew = true;
  }

  viewShow(e: any) {
    this.viewApplicationId = e.id
    this.roleName = e.role
    this.createNew = false;
    this.showView = true;
  }
  editShow(e:any){
    this.applicationConfig = e;
    this.createNew = false;
  }
  hideView() {
    this.createNew = true;
    this.showView = false;
  }
  saveStatusNum(e:any){
    this.statusNum = e
    if(this.statusNum.pending_approve||this.statusNum.pending_terminated){  
      if(this.statusNum.pending_approve){
        this.statusNum.pending = this.statusNum.pending_approve
      }
      if(this.statusNum.pending_terminated){
        this.statusNum.pending = this.statusNum.pending_terminated
      }
      if(this.statusNum.pending_terminated&&this.statusNum.pending_terminated){
        this.statusNum.pending = this.statusNum.pending_approve+this.statusNum.pending_terminated
      }
    }
  }
}
