import { Component, OnInit, ChangeDetectorRef ,Output} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
declare let layui: any
@Component({
  selector:'PermitDigComponent',
  templateUrl: './Permit-Dig.component.html',
  styleUrls: ['./Permit-Dig.component.sass']
})
export class PermitDigComponent implements OnInit {
  public list:any = []
  public first:boolean = true
  public page:1
  public limit:10
  public total_num:0
  public layer:''
  public loading = false

  constructor(
    readonly http: HttpClient,
  ) {

  }
  ngOnInit() {
      this.loading = true
      this.login()
  }
  showPeople(arr:any){
    let strArr = arr.map((item:any)=>{
      return item.displayName
    })
    return strArr.join(';')
  }
  openNewWindos(item:any){
    window.open(`https://staging.som.mattex.com.hk/en/projects/38/permit/ef/${item}`)
  }

  initPage(){
    let self = this
    layui.use(['jquery','laypage', 'layer'], function(){
      var laypage = layui.laypage
        ,layer = layui.layer,$ = layui.$;
      laypage.render({
        elem: 'pagging'
        ,lang: 'en'
        ,count: self.total_num,
        curr:self.page
        ,layout: ['count', 'prev', 'page', 'next', 'limit',]
        ,jump: function(obj:any, first:any){
          $('.layui-laypage-limits').css({'width':'90px'})
          $('.layui-laypage-count').text('Total: '+obj.count)
          let arr = $('.layui-laypage-limits').children('select').children()
          for (let index = 0; index < arr.length; index++) {
            $(arr[index]).text('Per Page:'+ obj.limits[index])
          }
          if(!self.first){
            self.limit = obj.limit
            self.page = obj.curr
            self.loading = true
            self.getDataList()
          }
          if(first){
            self.first = false
          }
        }
      });
    })
  }
  showCheck(arr:any){
      let falg = ''
      for(var i = 0; i < arr.length; i++) {
        if(arr[i].isCurrent) {
          falg = arr[i].submittedByActionType
          break;
        }
      }

    return falg
  }
  getDataList(){
    let parmse = {
      contractId:38,
      limit:this.limit,
      page:this.page,
      isPaginate:true,
    }
    window.fetch("https://staging.api.fours.app/machine/api/permits/ef/all", {
      "headers": {
        "authorization": 'Bearer ' + sessionStorage.getItem('access_token'),
        "content-type": "application/json",
      },
      "body":JSON.stringify(parmse),
      "method": "POST",
      "mode": "cors",
      "credentials": "include"
    }).then(res => res.json()).then(res => {
      if(res.statusCode == 401){
        if(sessionStorage.getItem('access_token')){
          sessionStorage.removeItem('access_token')
        }
        this.login()
      }else{
        console.log(res,'res')
        this.list = res.payload.permits
        this.total_num = res.payload.pager.totalRows
        let that = this
        that.loading = false
        if(this.first){
          this.initPage()
        }
      }

    });
  }
  login(){
    let data = 'username=dbc@fours.app&password=ZGJj'
    let isToken = sessionStorage.getItem('access_token')
    if(!isToken){
      window.fetch("https://staging.api.fours.app/auth/token?grant_type=password",{
        "headers": {
          "authorization": "Basic YzBhZjVlMDZiNTdlYmJlYTlhYTQ6ZGI4MDBjNzQ3ZjQ2MzgzOGM2NTQwMDQwYmM4ODM3MmNlZjVkNGVkMTlhNDU=",
          'Content-Type':'application/x-www-form-urlencoded'
        },
        "method": "POST",
        "mode": "cors",
        "credentials": "include",
        "body":data
      }).then((res) => res.json()).then((res) => {
        console.log(res,'res')
        sessionStorage.setItem('access_token',res.access_token)
        this.getDataList()
      });
    }else{
      this.getDataList()
    }
  }
}
