<div class="work-package--attachments--files" *ngIf="resource">
  <ul class="form--selected-value--list"
      *ngFor="let attachment of attachments; trackBy:trackByHref; let i = index;">
    <attachment-list-item [attachment]="attachment"
                          [resource]="resource"
                          [index]="i"
                          [destroyImmediately]="destroyImmediately"
                          (removeAttachment)="removeAttachment(attachment)"
                          (url)="onclick($event)"
                          (openpdfs)="getpdf($event)">
    </attachment-list-item>
  </ul>
  <div class="documents-preview" *ngIf="previewShow">
      <iframe
          class="iframe-preview"
          [src]="url">
      </iframe>
  </div>
  <div class="documents-video" *ngIf="videoShow">
    <video id="videoPlay" [src]="url" width="100%" height="100%" controls autoplay>
    </video>
</div>
<div class="documents-audio" *ngIf="audioShow">
  <audio controls="controls" height="100%" width="100%">
    <source [src]="url" type="audio/mp3" />
    <source [src]="url" type="audio/ogg" />
    <embed height="100" width="100" [src]="url" />
  </audio>
</div>
<div class="p-pdf" *ngIf="pdfShow">
  <div id="app" class="app"  ></div>
</div>

<div class="close" *ngIf="previewShow || videoShow || audioShow || pdfShow">
  <span>x</span>
</div>
<!-- loading -->
<div class="documents--loading" *ngIf="showLoading">
  <div >
      <div class="documents-loading icon rotate"></div>
      <span class="loading-text">Loading</span>
  </div>
</div>
  <!-- 提示 -->
  <div class="documents-toasts"  *ngIf="toastShow">
    <div class="documents-toast">
      <div class="toast-container">
          <div class="documents-success icon"></div>
          <div class="toast-text">{{ i18n.t("js.documents.save_successed")}}</div>
      </div>
  </div>
</div>
  <!-- 遮罩层 -->
  <div id="documents-mask"
    (click)="closeMask()"
    *ngIf="previewShow || videoShow || audioShow || pdfShow || showLoading">
  </div>
</div>
