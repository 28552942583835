import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Photo_logService } from '../../services/photo_log.service'
declare let laydate:any
@Component({
  selector: 'progessGroupAdd',
  templateUrl: './progessGroupAdd.component.html',
  styleUrls: ['./progessGroupAdd.component.sass']
})
export class ProgessGroupAddComponent implements OnInit {
  @Output() public close:EventEmitter<any> = new EventEmitter<any>()
  constructor(public service: Photo_logService) { }
    title:any = ''
    date:any =  ''
  ngOnInit() {
    laydate.render({ 
      elem: '#selectDate' ,
      type: 'date',
      done: (date:any) => {
        this.date = date
      }
    })
  }
  closeDialog() {
    console.log('close')
    this.close.emit()
  }
  addProgress() {
    this.service.addProgress({title: this.title, date: this.date}).subscribe( res => {
      this.close.emit()
    })

  }
}
