import {OpenProjectModule} from 'core-app/angular4-modules';
import {enableProdMode} from '@angular/core';
import * as jQuery from "jquery";
import {environment} from './environments/environment';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
/** Load sentry integration as soon as possible */
import {SentryReporter} from "core-app/sentry/sentry-reporter";

(window as any).global = window;


window.ErrorReporter = new SentryReporter();

require('./app/init-vendors');
require('./app/init-globals');


if (environment.production) {
  enableProdMode();
}


jQuery(function () {
  // Due to the behaviour of the Edge browser we need to wait for 'DOM ready'
  platformBrowserDynamic()
    .bootstrapModule(OpenProjectModule)
    .then(platformRef => {
      jQuery('body').addClass('__ng2-bootstrap-has-run');
    });
});

// 获取权限
const getUserPermission = () => {
  const projectId:any = document.querySelector('meta[name=current_project]')
  const currentUser:any = document.querySelector('meta[name=current_user]');
  fetch(`/api/v3/projects/${projectId.dataset.projectId}/available_responsibles?role=null`).then(response => response.json())
    .then(data => {
      data._embedded.elements.forEach((resitem:any) => {
        if (resitem.id == currentUser.dataset.id) {
          if (resitem.admin) {
            sessionStorage.setItem('isUserAdmin', 'true')
          } else {
            sessionStorage.setItem('isUserAdmin', 'false')
          }
        }
      })

    })
    .catch(error => console.error(error))
}

const href = location.pathname
if (href.startsWith("/projects/") && href !== "/projects/new") {
  getUserPermission()
}

