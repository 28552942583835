/*
 * @Author: your name
 * @Date: 2020-07-06 15:37:15
 * @LastEditTime: 2020-07-06 16:54:30
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /junhe/frontend/src/app/modules/check_lists/components/check-lists-modulelist/check-lists-modulelist.component.ts
 */ 
import { Component, OnInit, Input,Output, EventEmitter } from '@angular/core';
import { I18nService } from 'core-app/modules/common/i18n/i18n.service';
import { DocumentsService } from '../../services/check.lists.service';


@Component({
  selector: 'check_lists-modulelist',
  templateUrl: './check-lists-modulelist.component.html',
  styleUrls: ['./check-lists-modulelist.component.sass']
})
export class CheckListsModulelistComponent implements OnInit {

@Input()  modulelist:any
@Output()  moduleListid = new EventEmitter();
@Output()  editmoduleListid = new EventEmitter();

  constructor(
    public i18n: I18nService,
    public service: DocumentsService,

  ) { 
    
  }

  ngOnInit() {
    jQuery('#content-wrapper').css({'background':'#ffffff','padding': "15px"});
    console.log(this.modulelist)
  }

  deletemoduleitem(e:any){
    console.log(e.id)
    let r =window.confirm("是否确定删除")
    if (r==true){
      this.moduleListid.emit(e.id)
    }
  }

  editmoduleitem(e:any){
    this.service.getnewcheckliststemplate(e.id).subscribe((res)=>{
      console.log('获取模板',res);
      this.editmoduleListid.emit(res)
      
    })

  }
  copyModuleItem(e:any) {
      this.service.templateCopy.emit({type:'copy', id:e.id})
  }

}
