import { Component, OnInit, ChangeDetectorRef ,Output, Input} from '@angular/core';
import { labour_returnService } from '../../services/labour_return.service';
import { I18nService } from 'core-app/modules/common/i18n/i18n.service';
import { formatDate } from '@angular/common';
import {  EventEmitter } from '@angular/core';




@Component({
  selector:'labour_return-list',
  templateUrl: './labour_return-list.component.html',
  styleUrls: ['./labour_return-list.component.sass']
})
export class labourReturnListComponent implements OnInit {


  @Output () toShowEdit = new EventEmitter;
  @Output () toShowApproval = new EventEmitter;
  @Input () updateList:any
 
  public allList:any = []
  public tableList:any=[]
  public saveNum:any=[]
  public saveIndex:any = 0
  public count:any = 0
  public proId:any
  public action:any
  public getListLoding:any
  public version:any
  constructor(
    public service: labour_returnService,
    public i18n: I18nService,
    ) { 
    }

    ngOnInit() {
      this.version = location.search.split('?')[1].split('&')[0].split('=')[1]
      this.getList('all')
      this.proId = jQuery('meta[name=current_project]').data('projectId')
    }
    getList(action:string){
      this.action = action
      let projectId = jQuery('meta[name=current_project]').data('projectId')
      this.getListLoding = true
      this.service.getAllList(projectId, action,this.version).subscribe((res:any)=>{
        this.allList = res.list
        this.tableList = this.allList.slice(0,10)
        this.count = Math.ceil(res.total_count/10)
        this.getListLoding = false
        this.saveNum = []
        for(let i:any=0;i<this.count;i++){
          this.saveNum.push(i)
        }
      })
    }

    toEdit(e:any){
      if(e.state=='completed'||e.state=='pending_review'){
        return
      }else{
        this.toShowEdit.emit(e) 
      }
    }

    toApproval(e:any){
      this.toShowApproval.emit(e)
    }

    toDelete(e:any){
      let msg = "确定刪除該草稿嗎?"; 
      if (confirm(msg)==true){ 
        let data = {
          project_id:this.proId,
          labour_return_id:e
        }
        this.service.deleteOneList(data).subscribe((res:any)=>{
          this.saveNum = []
          this.getList(this.action)
        })
      }
    }

    //状态名字
    changeStatusName(e:any){
     return (new Map([
        ['new', 'Draft'],
        ['pending_approve', 'Draft'],
        ['pending_review', 'Contractor Signed'],
        ['completed', 'Supervisor Signed'],
        ['rejected', 'Recheck']
      ])).get(e)
    }

    changePage(e:any){
      if(e<0||e>this.saveNum.length-1){
        return
      }
      this.saveIndex =e
      let frontPage = e*9+1*e
      let endPage = (e*9+1*e)+10
      this.tableList = this.allList.slice(frontPage,endPage)
    }

    getHref(e:any){
        return `/projects/${this.proId}/labour_returns/${e.id}/export`
    }

}
