
    <div class="case">
        <input 
        type="text" 
        [attr.aria-required]="required"
        [attr.required]="required"
        [disabled]="inFlight"
        [(ngModel)]="value"
        (keydown)="handler.handleUserKeydown($event)"
        [id]="handler.htmlId" 
        placeholder="yyyy-MM-dd HH:mm:ss"
        id="DateTimeInput"
        #getInput>
        <div  #DateTime class="layui-input Date"  ></div>
        <div  #Time class="layui-input Time" ></div>
    </div>

