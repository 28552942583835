
import { NgModule } from '@angular/core';
import { Ng2StateDeclaration, UIRouterModule } from '@uirouter/angular';
import { E_liftingTableComponent} from './components/e_lifting-table/e_lifting-table.component'
import { E_liftingCreateComponent} from './components/e_lifting-create/e_lifting-create.component'
import { E_liftingViewComponent } from './components/e_lifting-view/e_lifting-view.component';
import {OpenprojectCommonModule} from "core-app/modules/common/openproject-common.module";
import {E_liftingLabourComponent} from './e_lifting-labour/e_lifting-labour.component';
import {E_liftingPlantComponent} from './e_lifting-plant/e_lifting-plant.component';
import {OpenprojectWorkPackagesModule} from 'core-app/modules/work_packages/openproject-work-packages.module';
import {ScanQrcodeComponent} from './components/scanQrcode/scanQrcode.component'


import {E_liftingComponent} from './e_lifting.component'


export const E_lifting_ROUTES: Ng2StateDeclaration[] = [
  {
    name: 'e_lifting',
    url: '/e_lifting',
    parent: 'root',
    component: E_liftingComponent
  },
  {
    name: 'plant',
    url: '/e_lifting/plant',
    parent: 'root',
    component: E_liftingPlantComponent
  },
  {
    name: 'labour',
    url: '/e_lifting/labour',
    parent: 'root',
    component: E_liftingLabourComponent
  },
];

@NgModule({
  imports: [
    UIRouterModule.forChild({ 
      states: E_lifting_ROUTES
    }),
    OpenprojectCommonModule,
    OpenprojectWorkPackagesModule

  ],
  declarations: [
    E_liftingComponent,
    E_liftingTableComponent,
    E_liftingCreateComponent,
    E_liftingViewComponent,
    E_liftingLabourComponent,
    E_liftingPlantComponent,
    ScanQrcodeComponent
  ]
})
export class E_liftingModule { }
