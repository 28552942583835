<div *ngIf="(editAble == false)">
  
  <div >Labour Return Mapping</div>
  <div class="saveLine">
    <div class="update" (click)="updateList()" >
      Update
    </div>
    <div class="save" (click)="saveList()" >
      Save
    </div>
    <div class="cancel" (click)="cancel()" >
      Cancel
    </div>
  </div>
  <div>
    <select [(ngModel)]="version" class="vsersionSelect" (change)="getList($event.target.value)">
      <option value="v17">
        v17
      </option>
      <option value="v03">
        v03
      </option>
    </select>
  </div>
  <table>
    <thead>
      <tr>
        <th class="index">Report Index</th>
        <th class="name">Mapping Trade Name</th>
        <!-- <th class="code">Code</th> -->
        <th class="show">In Show In Form</th>
        <th class="action"></th>
      </tr>
      
    </thead>
    <tbody>
      <tr *ngFor="let item of mapList let  i = index"  [ngStyle]="{backgroundColor: i%2 == 0? '#f8f8f8': '#fff'}">
        <td>
          <span >
            {{item.report_index}}
          </span>
  
        </td>
        <td>
          {{item.mapping_trade_name}}
        </td>
        <!-- <td>
          {{item.code}}
        </td> -->
        <td class="show"> 
          <span >
            {{item.is_show_in_form == 'true' || item.is_show_in_form == true? 'Y': 'N'}}
          </span>
     
        </td>
        <td class="action" >
          <span  (click)="editItem(item,i)" >edit</span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div *ngIf="editAble" class="editCase">
  <h2>
    Labour Return Mapping Setup
  </h2>
  <div class="form">
    <div>
      <span class="title">Report Index</span><span class="edit"><input (input)="inputIndex($event,itemSave)" [(ngModel)]="itemSave.report_index"/></span>
    </div>
    <div>
      <span class="title">Mapping Trade Name</span><span class="edit"><input disabled="true" [(ngModel)]="itemSave.mapping_trade_name"/></span>
    </div>

    <div>
      <span class="title">Is Show In Form</span><span class="edit">
        <select [(ngModel)]="itemSave.is_show_in_form">
          <option [value]="true">
            Y
          </option>
          <option [value]="false">
            N
          </option>
        </select>
      </span>
    </div>
    <div class="Action">
      <div class="Save" (click)="saveItem()">
        Save
      </div>
      <div class="Cancel" (click)="saveToCancel()">
        Cancel
      </div>
    </div>
  </div>
</div>
