<div class="documents-operation-list">
    <div class="title">
        {{ service.isFolder(item.type) ? item.name : item.file_name }}
    </div>
    <div class="detail">
        <span>
            {{ service.formatDate(item.last_modified) }}
        </span>
        <span class="detail-author">
            {{ item.creator }}
        </span>
        <span>
            {{ i18n.t("js.documents.update") }}
            {{ service.isFolder(item.type) ? '__' : service.formatFileSize(item.file_size) }}
        </span>
    </div>
    <div class="operation">
        <div class="operation-item" (click)="handleDownload()" *ngIf="!service.isFolder(item.type)">
            <div class="documents-download-list operation-icon"></div>
            <!-- 下载 -->
            <div>{{ i18n.t("js.documents.download") }}</div>
        </div>
        <div class="operation-item" (click)="handleUpload()" *ngIf="service.isFolder(item.type)">
            <div class="documents-upload-list operation-icon"></div>
            <!-- 上传 -->
            <div>{{ i18n.t("js.documents.upload") }}</div>
        </div>
        <div class="operation-item" (click)="handleMove()">
            <div class="documents-move-list operation-icon"></div>
            <!-- 移动 -->
            <div>{{ i18n.t("js.documents.move") }}</div>
        </div>
        <div class="operation-item" (click)="handleCopy()" *ngIf="service.isFolder(item.type)">
            <div class="documents-move-list operation-icon"></div>
            <!-- 复制 -->
            <div>{{ i18n.t("js.documents.copy") }}</div>
        </div>
        <div class="operation-item" (click)="handleRename()">
            <div class="documents-rename-list operation-icon"></div>
            <!-- 重命名 -->
            <div>{{ i18n.t("js.documents.rename") }}</div>
        </div>
        <div class="operation-item" (click)="handlePreview()" *ngIf="service.isPreview(item.type)">
            <div class="documents-upload-list operation-icon"></div>
            <!-- 预览 -->
            <div>{{ i18n.t("js.documents.preview") }}</div>
        </div>
        <div class="operation-item" (click)="handleShare()" *ngIf="!service.isFolder(item.type)">
            <div class="documents-upload-list operation-icon"></div>
            <!-- 分享 -->
            <div>{{ i18n.t("js.documents.share") }}</div>
        </div>
        <!-- <div class="operation-item">
            <div class="documents-more-list operation-icon"></div>
            更多
            <div>{{ i18n.t("js.documents.more") }}</div>
        </div> -->
    </div>
    <div class="close" (click)="handleClose()">
        <!-- 关闭 -->
        {{ i18n.t("js.documents.close") }}
    </div>
</div>