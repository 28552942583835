import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, OnInit, Output, Query, ViewChild,EventEmitter } from '@angular/core';
import { I18nService } from 'core-app/modules/common/i18n/i18n.service';

declare let laydate: any;
@Component({
  selector: 'check-field-dateTime',
  templateUrl: './check-dateTime.component.html',
  styleUrls: ['./check-dateTime.component.sass']
})
export class checkDateTimeComponent   implements OnInit, AfterViewInit {

  @ViewChild('getInput', { static: false }) input: ElementRef
  @ViewChild('DateTime', {static: false}) Date: ElementRef
  @ViewChild('Time', {static: true}) Time: ElementRef
  @Input() value:any
  @Output() public changeValue = new EventEmitter()
  public laydate:any
  public date: any
  public time: any 
  public dateData: any
  public timeData: any
  public showDateCase: any = false
  public dateValue:any = ''
  constructor(
    public datePipe: DatePipe,
    public I18n:I18nService
  ) {
    
  }
  ngOnInit() {
    console.log(this.value)

    if(this.value){
      this.dateValue = this.value
      let  data = this.value.split(' ')
        this.dateData = data[0]
        this.timeData = data[1]
      } else {
        this.dateData = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
        this.timeData = this.datePipe.transform(new Date(), 'HH:mm') + ':00';
        console.log(this.timeData)
        this.dateValue =  this.dateData + ' ' +  this.timeData
      }
      // this.changeValue.emit(this.dateValue)
  }
  
  Init(){
        
      let lang = 'cn'
      if(this.I18n.locale == 'zh-CN' || this.I18n.locale == 'zh-TW') {
        lang = 'cn'
      } else {
        lang = 'en'
      }
   this.date = laydate.render({
        elem: this.Date.nativeElement,
        position: 'static',
        value: this.dateData,
        zIndex: 99999999,
        btns: [],
        lang,
        change: (value:any) => {
          this.dateData = value
          // this.value =  this.dateData + ' ' + this.timeData
        }
      });
   this.time = laydate.render({
        elem: this.Time.nativeElement,
        type: 'time',
        value: this.timeData,
        position:'static',
        zIndex: 99999999,
        lang,
        btns: ['confirm'],
        change: (value: any)=> {
          this.timeData = value
          this.dateValue =  this.dateData + ' ' + this.timeData
        }
      });
      jQuery(this.Time.nativeElement).find('.laydate-footer-btns').click(() => {
        this.showDateCase = false
        this.changeValue.emit(this.dateValue)
      })
  }
  toShowDateCase() {
    this.showDateCase = true
    console.log(this.showDateCase)
  }
  ngAfterViewInit() {
    this.Init()
    console.log(jQuery('.layui-laydate-main'))
    jQuery('.layui-laydate-main').css('width', '245px')
    jQuery('.layui-laydate-main')
    document.getElementsByClassName('laydate-time-list')
    console.log(this.Time)
    this.Time.nativeElement.children[0].children[0].children[1].children[1].children[2].remove()
    this.Time.nativeElement.children[0].children[0].children[1].children[1].children[0].style.width = '50%'
    this.Time.nativeElement.children[0].children[0].children[1].children[1].children[1].style.width = '50%'
    setTimeout(()=> {
      this.input.nativeElement.focus()
      this.changeValue.emit(this.dateValue)
      console.log(this.Date.nativeElement, this.Time.nativeElement)
    },10) 
  }

}
