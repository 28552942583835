import {Injector} from '@angular/core';
import {tableRowClassName} from '../../builders/rows/single-row-builder';
import {WorkPackageTable} from '../../wp-fast-table';
import {TableEventHandler} from '../table-handler-registry';
import {OPContextMenuService} from "core-components/op-context-menu/op-context-menu.service";
import {WorkPackageTableContextMenu} from "core-components/op-context-menu/wp-context-menu/wp-table-context-menu.directive";
import { HttpClient } from '@angular/common/http';

export abstract class ContextMenuHandler implements TableEventHandler {
  // Injections
  public opContextMenu:OPContextMenuService = this.injector.get(OPContextMenuService);
  public http = this.injector.get(HttpClient)
  public user:any
  constructor(public readonly injector:Injector,
              protected table:WorkPackageTable) {
                console.log(this.http)
                this.user  =  this.http.get(`/api/v3/users/${document.getElementsByName('current_user')[0].getAttribute('data-id')}`).toPromise()
  }

  public get rowSelector() {
    return `.${tableRowClassName}`;
  }

  public abstract get EVENT():string;

  public abstract get SELECTOR():string;

  public eventScope(table:WorkPackageTable) {
    return jQuery(table.tableAndTimelineContainer);
  }

  public abstract handleEvent(table:WorkPackageTable, evt:JQuery.TriggeredEvent):boolean;

  protected async openContextMenu(evt:JQuery.TriggeredEvent, workPackageId:string, positionArgs?:any, type?:any) {
    const handler = new WorkPackageTableContextMenu(this.injector, workPackageId, jQuery(evt.target) as JQuery, positionArgs, this.table);
    console.log(handler)
    this.opContextMenu.user = await this.user
    this.opContextMenu.status =  type
    this.opContextMenu.show(handler, evt);
  }
}
