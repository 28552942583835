//-- copyright
// OpenProject is a project management system.
// Copyright (C) 2012-2015 the OpenProject Foundation (OPF)
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See doc/COPYRIGHT.rdoc for more details.
//++

import {ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit,Injector} from '@angular/core';
import {HalResource} from 'core-app/modules/hal/resources/hal-resource';
import {DynamicBootstrapper} from 'core-app/globals/dynamic-bootstrapper';
import {ElementRef} from '@angular/core';
import {HalResourceService} from 'core-app/modules/hal/services/hal-resource.service';
import {filter, takeUntil, buffer} from "rxjs/operators";
import {componentDestroyed} from "ng2-rx-componentdestroyed";
import {States} from "core-components/states.service";
import {AngularTrackingHelpers} from "core-components/angular/tracking-functions";
// import PSPDFKit from 'pspdfkit';
import { DocumentsService } from '../../documents/services/documents.service';
import {I18nService} from 'core-app/modules/common/i18n/i18n.service';
import {CurrentUserService} from "core-components/user/current-user.service";

@Component({
  selector: 'attachment-list',
  templateUrl: './attachment-list.html',
  styleUrls: ['./attachment-list.sass']
})
export class AttachmentListComponent implements OnInit, OnDestroy {
  @Input() public resource:HalResource;
  @Input() public destroyImmediately:boolean = true;
  //是否系统管理员
  @Input() public is_project_admin:boolean = false;
  private currentUser:CurrentUserService = this.injector.get(CurrentUserService);

  trackByHref = AngularTrackingHelpers.trackByHref;

  attachments:HalResource[] = [];
  deletedAttachments:HalResource[] = [];

  public $element:JQuery;
  public $formElement:JQuery;
  public url: any = '';
  public arrayBuffer:any
  public instance:any
    //显示预览
  previewShow:boolean = false;
  videoShow :boolean = false;
  audioShow :boolean = false;
  pdfShow:boolean = false;
  showLoading:boolean = false;
  toastShow:boolean = false;

  constructor(protected elementRef:ElementRef,
              protected states:States,
              protected injector: Injector,
              protected cdRef:ChangeDetectorRef,
              readonly i18n:I18nService,
              public service: DocumentsService,
              protected halResourceService:HalResourceService) { }

  ngOnInit() {
    this.$element = jQuery(this.elementRef.nativeElement);

    this.updateAttachments();
    this.setupResourceUpdateListener();

    if (!this.destroyImmediately) {
      this.setupAttachmentDeletionCallback();
    }
  }


  public setupResourceUpdateListener() {
    this.states.forResource(this.resource)!
      .values$()
      .pipe(
        takeUntil(componentDestroyed(this)),
        filter(newResource => !!newResource)
      )
      .subscribe((newResource:HalResource) => {
        this.resource = newResource || this.resource;

        this.updateAttachments();
        this.cdRef.detectChanges();
      });
  }

  ngOnDestroy():void {
    if (!this.destroyImmediately) {
      this.$formElement.off('submit.attachment-component');
    }
  }

  colseToast(){
    this.showLoading = false
    this.toastShow = true;
    setTimeout(() => {
      this.toastShow = false;
      this.cdRef.detectChanges();
    }, 2000);
      this.cdRef.detectChanges();
  }

  getpdf(type:any){
    this.showLoading = true
    this.pdfShow = true
    jQuery('#top-menu').css('z-index', 20);
    var xhr = new XMLHttpRequest()
    console.log(type)
    xhr.open('GET', type.url.changingThisBreaksApplicationSecurity)
    xhr.responseType = 'arraybuffer'
    xhr.onload = () => {

      const save:any = {
        type: "custom",
        id: "my-button",
        title: this.i18n.t("js.documents.save"),
        onPress: () => {
          this.showLoading = true
          this.cdRef.detectChanges();
          this.instance.exportPDF().then( (buffer:any)=>{
            const files = new File([buffer],type.name,{ type: "application/pdf" });
            const file = new FormData();
            file.append("file",files)
            this.service.uploadPdf({
              id: type.id,
              file
            }).subscribe((res: any) => {
              if (res.result === 'ok') {
                console.log(res)
                this.closeMask()
                this.colseToast()
                this.cdRef.detectChanges();
              }else{
                this.showLoading = false
                this.cdRef.detectChanges();
              }
            },error =>{
              this.showLoading = false
              this.cdRef.detectChanges();
            })
          })
        }
      };


      const save_btn:any = {
        type: "custom",
        id: "my-button2",
        title: this.i18n.t("js.documents.save_download"),
         onPress: () => {
          this.showLoading = true
          this.cdRef.detectChanges();
          this.instance.exportPDF().then( (buffer:any)=>{
            const files = new File([buffer],type.name,{ type: "application/pdf" });
            const blob = new Blob([buffer], { type: "application/pdf" });

            const file = new FormData();
            file.append("file",files)
            this.service.uploadPdf({
              id: type.id,
              file
            }).subscribe((res: any) => {
              if (res.result === 'ok') {
                this.closeMask()
                this.colseToast()
                this.cdRef.detectChanges();
                console.log(res)
                const objectUrl = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = objectUrl;
                a.target = "_blank";
                a.download = type.name;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);
              }else{
                this.showLoading = false
                this.cdRef.detectChanges();
              }
            },error=>{
              this.showLoading = false
              this.cdRef.detectChanges();
            })
          })
        }
      };
      console.log('xhr.response', xhr.response)
      //  PSPDFKit.load({
      //   baseUrl: location.protocol + "//" + location.host + "/assets/frontend/assets/",
      //   document:  xhr.response,
      //   container: '#app',
      //   disableWebAssemblyStreaming: true,
      //   licenseKey: 'yGsvyVYBPyOEmvBXe6Uje3jGUPoqUvrM-2aKa9iKZivlvy2Vf52NFYMyfqvVTlpDxPir9o34j9gwsnnURAFNCkVZFnhs5R5dvh8yg5TFMYIRBo-030bVlKcASCeDlQojh6-hgKvIynPtelqR1bOhz-o--US0YYyBaCABiGW2kPfjxn0Cv5xBuMxCqCbo63Xzqsa3jS8LCX2_gzZlBH0vjaNYPvJzLOMlRqV2HE7WMKpGdTexs5DLdEnub8lLfzEgEFUpSEKBDoIjXaFMrUmUC-fR7JLdpEbvw1WNrPZ6P-rjdEyCVG_V7EHmPq6P0h27FPEDtuBvycVUUVYG4EnpkIKIRPgztu61fs1LXc7VjAN1YaHs-4p1rL1iFgst5SuK8EfwwormhbgXqkzcpcYbOtMoWciCUKNlqdrivhfen6OI7gGoMxTcGvgnX0hmWWBv',
      // }).then(async instance => {
      //   this.showLoading = false
      //   this.cdRef.detectChanges();
      //   instance.setToolbarItems(items=>{
      //     items.push(save)
      //     items.push(save_btn)
      //     return items
      //   })
      //   this.instance = instance
      // });
      
    }
    xhr.send()
  }
  onclick(obj:any){
    if(obj.status == 'video'){
      this.videoShow = true
    }else if(obj.status == 'audio'){
      this.audioShow = true
    }else{
      this.previewShow = true;
    }
    this.url = obj.url
    jQuery('#top-menu').css('z-index', 20);
  }

    //关闭遮罩层
    closeMask() {
      this.previewShow = false;
      this.videoShow = false;
      this.audioShow = false;
      this.pdfShow = false
      jQuery('#top-menu').css('z-index', 22);
    }

  public removeAttachment(attachment:HalResource) {
    this.deletedAttachments.push(attachment);
    // Keep the same object as we would otherwise loose the connection to the
    // resource's attachments array. That way, attachments added after removing one would not be displayed.
    // This is bad design.
    let newAttachments = this.attachments.filter((el) => el !== attachment);
    this.attachments.length = 0;
    this.attachments.push(...newAttachments);

    this.cdRef.detectChanges();
  }

  private get attachmentsUpdatable() {
    return (this.resource.attachments && this.resource.attachmentsBackend);
  }

  public setupAttachmentDeletionCallback() {
    this.$formElement = this.$element.closest('form');
    this.$formElement.on('submit.attachment-component', () => {
      this.destroyRemovedAttachments();
    });
  }

  private destroyRemovedAttachments() {
    this.deletedAttachments.forEach((attachment) => {
      this
        .resource
        .removeAttachment(attachment);
    });
  }

  private updateAttachments() {
    if (!this.attachmentsUpdatable) {
      this.attachments = this.roleJudgment( this.resource.attachments.elements);
      return;
    }

    this
      .resource
      .attachments
      .updateElements()
      .then(() => {
        this.attachments = this.roleJudgment(this.resource.attachments.elements);
        this.cdRef.detectChanges();
      });
  }
  //权限判断
  private roleJudgment(attachments:any){
   for(let i=0;i< attachments.length;i++){
      attachments[i].isEdit=false;
      if(attachments[i].author.id!=this.currentUser.userId){
        if(this.is_project_admin){
          attachments[i].writable=true;
        }else{
          attachments[i].writable=false;
        }
      }else{
        attachments[i].writable=true;
      }
   }
   return attachments;
  }
}

DynamicBootstrapper.register({
  selector: 'attachment-list',
  cls: AttachmentListComponent
});
