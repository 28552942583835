
  <div class="Dropzone">
    <span class="SectionItem__index">{{indexs+1}}.{{i+1}}</span>
  <ul class="topUl" >
  <li *ngFor="let bigitem of dataItems;let bigindex=index" class="topli">

    <div class="SectionItem__content">
      <div class="TemplateBuilderSectionItem__content-container">
        <div class="TemplateBuilderSectionItem__texts-container">
          <!-- <input style="font-size: 14px;" class="InlineEditTextField-title" [(ngModel)]="bigitem.title" placeholder="Add Title"  (blur)="inputBlur()" (click)="select($event)" /> -->
          <textarea style="font-size: 14px;resize: none;" class="InlineEditTextField-title" [(ngModel)]="bigitem.title" placeholder="Add Title"  (blur)="inputBlur(bigindex)" (click)="select($event)" cols="28" rows="5"></textarea>
          <span *ngIf="!bigitem.necessary" style="color: #84969e;margin-left: 30px;">({{ i18n.t("js.check-lists.template.optional") }})</span>
          <div class="SectionItem__instructions" *ngIf="bigitem.showDescription">
            <div class="SectionItem__instructions-title" >{{ i18n.t("js.check-lists.quest.Description") }}:</div>
            <input style="width: 100%;" class="InlineEditTextField-title"  [(ngModel)]="bigitem.description"/>
          </div>
        </div>
        <div class="TemplateSectionItemDropdown">
          <div class="SectionItem__response-dropdown">
            <select class="Select-control" style="line-height: unset;"  [(ngModel)]="bigitem.type" (ngModelChange)="updatedType($event,bigindex)">
              <option value="text" >{{ i18n.t("js.check-lists.template.text") }}</option>
              <option value="radio" >{{ i18n.t("js.check-lists.template.templateyes") }}/{{ i18n.t("js.check-lists.template.templateno") }}/{{ i18n.t("js.check-lists.template.nothing") }}</option>
              <option value="checkbox" >{{ i18n.t("js.check-lists.template.checkbox") }}</option>
              <option value="date" >{{ i18n.t("js.check-lists.template.date") }}</option>
              <option value="select" >{{ i18n.t("js.check-lists.template.select") }}</option>
              <option value="textarea">{{ i18n.t("js.check-lists.template.textarea") }}</option>
              <option value="unique_date" *ngIf="kind_id == 6">unique Date</option>
              <option value="Int" *ngIf="kind_id == 6">Int</option>
              <option value="Number" *ngIf="kind_id == 6">Number</option>
            </select>
          </div>
        </div>
        <!---*************************************************************************************************--->
        <div class="TemplateBuilderSectionItem__options-button-wrapper">
          <span class="icon-show-more-horizontal buttonicon" (click)="toShowUl(bigindex)"></span>
          <ul class="B360Ul DropdownMenu--float-left" *ngIf="showUl&&bigindex==uiIndex">
          <!-- 添加可选 -->
            <li (click)="bigitem.showDescription = true;showUl = false"><span>{{ i18n.t("js.check-lists.template.Adddescription") }}</span></li>
            <li (click)="changeSelect(bigindex)"><span *ngIf="bigitem.necessary">{{ i18n.t("js.check-lists.template.setoptional") }}</span><span *ngIf="!bigitem.necessary">{{ i18n.t("js.check-lists.template.setrequired") }}</span> </li>
            <li (click)="toDelete(bigindex)"><span>{{ i18n.t("js.check-lists.template.delete") }}</span></li>
          </ul>
        </div>
        <!---*************************************************************************************************--->
      </div>

      <div class="SectionItem__responses-preview-container">
        <input *ngIf="bigitem.type === 'text'" placeholder="Input content"  style="width: 100%;height: 37px;border: none;"  disabled="true" class="SectionItem__response-text-editable " id="input_getinfo"/>
        <input *ngIf="bigitem.type === 'unique_date'" placeholder="Unique Date"  style="width: 100%;height: 37px;border: none;"  disabled="true" class="SectionItem__response-text-editable " />
        <input *ngIf="bigitem.type === 'Int'" placeholder="Int Number"  style="width: 100%;height: 37px;border: none;"  disabled="true" class="SectionItem__response-text-editable " />
        <input *ngIf="bigitem.type === 'Number'" placeholder="Number"  style="width: 100%;height: 37px;border: none;"  disabled="true" class="SectionItem__response-text-editable " />
        <!-- 单选框 -->
        <span *ngIf="bigitem.type === 'radio'">
          <span  style="margin-right: 30px;" *ngFor="let item of bigitem.options">
            <input style="vertical-align: middle;width: 20px; height: 20px;"  name="myname" disabled="true" type="radio"/>
            <span style="margin-left: 10px;">{{item}}</span>
          </span>
        </span>

        <!-- 复选框 -->
        <div class="DraggableSectionItemListResponseAnswers" *ngIf="bigitem.type === 'checkbox'" >
          <ul [sortablejs]="bigitem.options" [sortablejsOptions]="optionsadmin">
            <li class="draggabli" *ngFor="let item of bigitem.options;let i = index; trackBy: trackByFn">
              <div class="DraggableSectionItemListResponseAnswersItem" >
                <div class="DraggableSectionItemListResponseAnswers__answer">
                  <span class="icon-drag-handle itemBimQualityApp"></span>
                  <div class="DraggableSectionItemListResponseAnswers__checkbox">
                    <input style="vertical-align: middle;width: 20px; height: 20px;" type="checkbox" disabled="true"/>
                    <input style="width: 100%;" class="InlineEditTextField-title" [name]="'name'+i" (blur)="updateCheckboxValue($event,i,bigindex)" [(ngModel)]="item"/>
                  </div>
                </div>
                <button class="DraggableSectionItemListResponseAnswers__delete icon-close" (click)="deleteArr(bigitem.options,i)"></button>
              </div>
            </li>
          </ul>
          <div class="DraggableSectionItemListResponseAnswers__add-answer-checkbox">
            <input style="vertical-align: middle;width: 20px; height: 20px;" type="checkbox" disabled="true"/>
            <span (click)="addAnswer(bigindex)" style="cursor: pointer;">Add answer</span>
          </div>
        </div>

        <!-- 时间 -->
        <div *ngIf="bigitem.type=='date'" class="timeStyle">
        <span class="datespan">Date: </span><input type="date" disabled >
        </div>
        <div *ngIf="bigitem.type == 'textarea'" class="textarea">
          <textarea disabled></textarea>
        </div>

          <!-- 下拉框 -->
          <div *ngIf="bigitem.type=='select'"  class="DraggableSectionItemListResponseAnswers">
            <ul [sortablejs]="bigitem.options" [sortablejsOptions]="optionsadmin">
              <li class="draggabli" *ngFor="let item of bigitem.options;let i = index; trackBy: trackByFn">
                <div class="DraggableSectionItemListResponseAnswersItem" >
                  <div class="DraggableSectionItemListResponseAnswers__answer">
                    <span class="icon-drag-handle itemBimQualityApp"></span>
                    <div class="DraggableSectionItemListResponseAnswers__checkbox">
                      <input style="width: 100%;" class="InlineEditTextField-title" [name]="'name'+i" (blur)="updateCheckboxValue($event,i,bigindex)" [(ngModel)]="item"/>
                    </div>
                  </div>
                  <button class="DraggableSectionItemListResponseAnswers__delete icon-close" (click)="deleteArr(bigitem.options,i)"></button>
                </div>
              </li>
            </ul>
            <div class="DraggableSectionItemListResponseAnswers__add-answer-checkbox">
              <span (click)="addAnswer(bigindex)" style="cursor: pointer;">Add option</span>
            </div>
          </div>
      </div>
      
    </div>


  </li>
</ul>

<div class="topThreepot">
  <span class="icon-show-more-horizontal buttonicon" (click)="fathershowUl=!fathershowUl"></span>
  <ul class="toLeft" *ngIf="fathershowUl">
  <!-- 添加可选 -->
    <!-- <li (click)="showDescription = true;showUl = false"><span>{{ i18n.t("js.check-lists.template.Adddescription") }}</span></li> -->
    <li (click)="deleteDate()"><span>{{ i18n.t("js.check-lists.template.delete") }}</span></li>
  </ul>
</div>
  </div>
  
  <div  class="bottomAdd">
    <select style="line-height: unset;" [(ngModel)]="addTypeValue">
      <option value="text" >{{ i18n.t("js.check-lists.template.text") }}</option>
      <option value="radio" >{{ i18n.t("js.check-lists.template.templateyes") }}/{{ i18n.t("js.check-lists.template.templateno") }}/{{ i18n.t("js.check-lists.template.nothing") }}</option>
      <option value="checkbox" >{{ i18n.t("js.check-lists.template.checkbox") }}</option>
      <option value="date" >{{ i18n.t("js.check-lists.template.date") }}</option>
      <option value="select" >{{ i18n.t("js.check-lists.template.select") }}</option>
      <option value="unique_date" *ngIf="kind_id == 6">unique Date</option>
      <option value="Int" *ngIf="kind_id == 6">Int</option>
      <option value="Number" *ngIf="kind_id == 6">Number</option>
    </select>
    <button  class="bottomaddBtn icon-add" (click)="addSonItem()">Add</button>
  </div>
