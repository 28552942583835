import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';

declare var Html5QrcodeScanner: any;
declare var Html5Qrcode: any;

@Component({
  selector: 'scanQrcode',
  templateUrl: './scanQrcode.component.html',
  styleUrls: ['./scanQrcode.component.sass']
})
export class ScanQrcodeComponent implements OnInit {

  @Input() scanQrcodeType:any
  @Output() callback = new EventEmitter();
  private qrcodeDateil = ''
  public showTost:any = null

  constructor(
    private http: HttpClient,
  ) { }

  ngOnInit() {
    this.qrcode()
  }

  qrcode(){
    console.log(this.scanQrcodeType,'sss')
    /**
     * 浏览器平台适配
     * 
     * PC/MAC
     * Firefox	Chrome	Safari  Opera  Edge
     *   ✅       ✅      ✅      ✅     ✅
     * 
     * Android
     * Firefox	Chrome  Opera   Edge   Opera Min   UC
     *   ✅       ✅      ✅      ✅        ❌       ❌
     * 
     * IOS
     * Firefox	Chrome	Safari  Opera   Edge
     *   ❌       ❌      ✅      ❌      ❌ 
     * 
     * 警告：直接访问相机是一项强大的功能。它需要用户的同意，并且您的网站必须位于安全来源（HTTPS）上。
     * 警告：页面加载时要求访问摄像机将导致大多数用户拒绝访问摄像机
     */
    let html5QrcodeScanner = new Html5QrcodeScanner("reader", { fps: 10, qrbox: 250 }, /* verbose= */ true);
    
    html5QrcodeScanner.render((qrMessage:any)=>{
      // qrMessage二维码的内容，里面是一个api地址
      // 扫码发现有时候会扫出一个Á的特殊字符，和全部都是数字组合的哈希值，目前只发现这两种情况的出现，
      if(qrMessage.indexOf('Á') == -1 && qrMessage.indexOf('/api/v3') != -1){
        // 建议在获取到二维码内容，先处理，再关闭扫码插件
        if(!this.qrcodeDateil){
          let qrcodeHtml = jQuery('#reader__scan_region')
          jQuery('#loading').css({'width':`${qrcodeHtml.width()}px`,'height':`${qrcodeHtml.height()}px`,'margin-top':'31px','display':'flex'})
          this.qrcodeDateil = qrMessage
          //判断扫描的二维码是否和要扫描的类型符合
          if(this.judge(qrMessage)) return
          //处理二维码内容，请求api
          this.http.get(qrMessage).subscribe((data:any)=>{
            this.qrcodeDateil = ''
            jQuery('#loading').css({'display':'none'})
            this.qrcode()
            this.callback.emit({data,qrcodeType:this.scanQrcodeType})
            //扫码成功后关闭扫码插件，注意：clear()方法关闭是整个dom删除的('简单点就是说调用这个方法，整个扫码插件都没了')，所以需要重新执行qrcode方法重新渲染使用插件
            // html5QrcodeScanner.clear().then((ignore:any) => {
            //   this.qrcodeDateil = ''
            //   jQuery('#loading').css({'display':'none'})
            //   this.qrcode()
            //   console.log(data,'data出来了');
            //   this.callback.emit({data,qrcodeType:this.scanQrcodeType})
            //   // QR Code scanning is stopped.
            //   console.log("QR Code scanning stopped.");
            // }).catch((err:any) => {
            //   // Stop failed, handle it.
            //   console.log("Unable to stop scanning.");
            // });
          },(error:any)=>{
            //请求失败提示
            this.showTost = 'Unrecognized, re operate'
            setTimeout(()=>{
              this.qrcodeDateil = ''
              jQuery('#loading').css({'display':'none'})
              this.showTost= null
            },2000)
          })
        }
      }
    }, (err:any)=>{
      alert('QR code錯誤，請檢查QR code')
    });
  }
  judge(qrMessage:any){
    if(this.scanQrcodeType.type === 'plants'){
      if(qrMessage.indexOf(this.scanQrcodeType.type) == -1){
        this.showTost = "該QR code類型並非「Plant」，請檢查QR code"
        setTimeout(()=>{
          this.qrcodeDateil = ''
          jQuery('#loading').css({'display':'none'})
          this.showTost= null
        },3000)
        return true
      }else{
        return false
      }
    }else if(this.scanQrcodeType.type === 'labours'){
      if(qrMessage.indexOf(this.scanQrcodeType.type) == -1){
        this.showTost = "該QR code類型並非「Labour」，請檢查QR code"
        setTimeout(()=>{
          this.qrcodeDateil = ''
          jQuery('#loading').css({'display':'none'})
          this.showTost= null
        },3000)
        return true
      }else{
        return false
      }
    }else{
      return false
    }
  }
  clone(){
    this.callback.emit(null)
  }
}
