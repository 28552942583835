import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DocumentsService } from '../../services/documents.service';
import { I18nService } from 'core-app/modules/common/i18n/i18n.service';

@Component({
  selector: 'documents-operation-list',
  templateUrl: './documents-operation-list.component.html',
  styleUrls: ['./documents-operation-list.component.sass']
})
export class DocumentsOperationListComponent implements OnInit {
  @Input() item: any;
  @Output() move = new EventEmitter();
  @Output() download = new EventEmitter();
  @Output() upload = new EventEmitter();
  @Output() copy = new EventEmitter();
  @Output() rename = new EventEmitter();
  @Output() preview = new EventEmitter();
  @Output() share = new EventEmitter();
  @Output() close = new EventEmitter();
  constructor(
    public service: DocumentsService,
    public i18n: I18nService
  ) { }
  //移动
  handleMove() {
    this.move.emit();
  }
  //下载
  handleDownload() {
    this.download.emit();
  }
  //上传
  handleUpload() {
    this.upload.emit();
  }
  //复制
  handleCopy() {
    this.copy.emit();
  }
  //重命名
  handleRename() {
    this.rename.emit();
  }
  //预览
  handlePreview() {
    this.preview.emit();
  }
  //分享
  handleShare(){
    this.share.emit()
  }
  //关闭
  handleClose() {
    this.close.emit();
  }
  ngOnInit() {
    this.service.mask(true);
  }
  ngOnDestroy(): void {
    this.service.mask(false);
  }
}