import { NgModule } from '@angular/core';
import { CunstomFormsComponent } from './cunstom-forms.component';
import {  CunstomFormsItemComponent } from './components/cunstom-forms-item/cunstom-forms-item.component'
import {  CunstomFormsExcelComponent } from './components/cunstom-forms-excel/cunstom-forms-excel.component'
import {  CunstomFormsLoadingComponent } from './components/cunstom-forms-loading/cunstom-forms-loading.component'
import {  CunstomFormsSetupComponent } from './components/cunstom-forms-setup/cunstom-forms-setup.component'
import { OpenprojectCommonModule } from '../common/openproject-common.module';
import { PdfcomponentsComponent } from '../../components/pdfcomponents/pdfcomponents.component'
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { Ng2StateDeclaration, UIRouterModule } from '@uirouter/angular';
import { from } from 'rxjs';

export const SITE_PERMITS_ROUTES: Ng2StateDeclaration[] = [
  {
    name: 'custom_forms',
    url: '/custom_forms',
    parent: 'root',
    component: CunstomFormsComponent
  }
];

@NgModule({
  imports: [
    OpenprojectCommonModule,
    HttpClientModule,
    FormsModule,
    UIRouterModule.forChild({
      states: SITE_PERMITS_ROUTES
    })
  ],
  declarations: [
    CunstomFormsComponent,
    CunstomFormsItemComponent,
    CunstomFormsExcelComponent,
    CunstomFormsLoadingComponent,
    CunstomFormsSetupComponent,
    PdfcomponentsComponent
  ]
})

export class CunstomFormsModule { }