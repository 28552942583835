import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ItemsList } from "@ng-select/ng-select/lib/items-list";
import { I18nService } from "core-app/modules/common/i18n/i18n.service";
import { times } from "lodash";
import { reset } from "mousetrap";
import { E_liftingService } from "../services/e_lifting.service";

declare let laydate: any;

@Component({
  selector: "e_lifting-labour",
  templateUrl: "./e_lifting-labour.component.html",
  styleUrls: ["./e_lifting-labour.component.sass"],
})
export class E_liftingLabourComponent implements OnInit {
  public showLoading: boolean = false;
  public type: any = "lifting_labours";
  public createNew: boolean = true;
  public editNow: boolean = false;
  public employerArr: any = [];
  public licenseArr: any = [];
  public a12Arr: any = [];
  public data: any = {
    role_ids: [],
  };
  public qrId: any;
  public host: any = window.location.origin;
  public tableList: any = [];
  public saveId: any;
  public saveNum: any = [];
  public saveIndex: any = 1;
  public count: any = 0;
  public roleArr: any = [];

  constructor(public i18n: I18nService, public service: E_liftingService) {}

  ngOnInit() {
    //獲取列表
    this.getList(this.type, this.saveIndex);

    this.service.getEmployer().subscribe((res: any) => {
      this.employerArr = res.list;
    });

    //获取角色列表
    this.service.getRole().subscribe((res: any) => {
      this.roleArr = res;
    });
  }
  //编辑的时候，多选的赋值
  checkSelect(e: any) {
    let flag = false;
    for (const item of this.data.role_ids) {
      if (item == e) {
        flag = true;
      }
    }
    return flag;
  }

  //类型的多选
  changeCheckbox(e: any, id: any) {
    if (e.target.value && this.data.role_ids.indexOf(id) == -1) {
      this.data.role_ids.push(id);
    } else if (e.target.checked == false && this.data.role_ids.indexOf(id) >= 0) {
      let i = this.data.role_ids.indexOf(id);
      this.data.role_ids.splice(i, 1);
    }
  }

  //动态绑定证书的日期
  dateFormChange(index: any, type: any) {
    laydate.render({
      elem: `#${type}${index}`,
      show: true,
      lang: "en",
      value:
        type.indexOf("a12") >= 0 ? this.a12Arr[0].cert_valid_date_from : this.licenseArr[index].cert_valid_date_from,
      done: (value: any) => {
        if (type.indexOf("a12") >= 0) {
          this.a12Arr[0].cert_valid_date_from = value;
        } else {
          this.licenseArr[index].cert_valid_date_from = value;
        }
      },
    });
  }
  dateToChange(index: any, type: any) {
    laydate.render({
      elem: `#${type}${index}`,
      show: true,
      lang: "en",
      value: type.indexOf("a12") >= 0 ? this.a12Arr[0].cert_valid_date_to : this.licenseArr[index].cert_valid_date_to,
      done: (value: any) => {
        if (type.indexOf("a12") >= 0) {
          this.a12Arr[0].cert_valid_date_to = value;
        } else {
          this.licenseArr[index].cert_valid_date_to = value;
        }
      },
    });
  }
  dateOfIssueChange(index: any, type: any) {
    laydate.render({
      elem: `#${type}${index}`,
      show: true,
      lang: "en",
      value: type.indexOf("a12") >= 0 ? this.a12Arr[0].date_of_issue : this.licenseArr[index].date_of_issue,
      done: (value: any) => {
        if (type.indexOf("a12") >= 0) {
          this.a12Arr[0].date_of_issue = value;
        } else {
          this.licenseArr[index].date_of_issue = value;
        }
      },
    });
  }
  //列表
  getList(e: any, index: any) {
    this.service.getLabourList(e, index).subscribe((res: any) => {
      this.saveNum = [];
      this.tableList = res.list;
      this.count = Math.ceil(res.count / 10);
      for (let i: any = 0; i < this.count; i++) {
        this.saveNum.push(i);
      }
    });
  }
  //選擇不同的類型
  changeSelect(e: any) {
    if (!e.target.value) {
      return;
    }
    this.type = e.target.value;
    this.getList(this.type, this.saveIndex);
  }
  //創建
  toCreate() {
    this.createNew = false;
  }
  //編輯
  toEdit(e: any) {
    this.saveId = e;
    this.editNow = true;
    this.service.getUserDetail(e).subscribe((res: any) => {
      const { name_cn, name_en, employer_id, site_pass_no, state, remark, qr_code_id, certs, roles,contract_no } = res;
      //编辑赋值
      this.data = { name_cn, name_en, employer_id, site_pass_no, state, remark, role_ids: [],contract_no };
      for (const item of roles) {
        this.data.role_ids.push(item.id);
      }
      this.qrId = `${this.host}/api/v3/logical_docs/doc/${qr_code_id}/stream`;
      //获取证书
      for (const item of certs) {
        const {
          cert_valid_date_from,
          cert_valid_date_to,
          kind,
          reference_no,
          date_of_issue,
          id,
          owner_id,
          owner_type,
        } = item;
        let data = {
          id,
          cert_valid_date_from,
          cert_valid_date_to,
          kind,
          reference_no,
          date_of_issue,
          owner_id,
          owner_type,
        };
        if (item.kind == "a12") {
          this.a12Arr.push(data);
        } else {
          this.licenseArr.push(data);
        }
      }
      this.createNew = false;
    });
  }
  //驼峰转下划线
  // toLine(name: any) {
  //   return (
  //     name
  //       .replace(/([A-Z])/g, "_$1")
  //       .toLowerCase()
  //       .slice(1) + "s"
  //   );
  // }
  //刪除
  toDelete(e: any) {
    let msg = "確定刪除該記錄嗎？";
    if (confirm(msg) == true) {
      this.showLoading = true;
      this.service.deleteLabour(e).subscribe((res: any) => {
        this.showLoading = false;
        this.getList(this.type, this.saveIndex);
      });
    }
  }
  //退出編輯或退出創建
  toCancel() {
    let msg = "確定退出創建或者編輯labour嗎？";
    if (confirm(msg) == true) {
      this.reset();
    }
  }
  //提交創建
  toSubmit() {
    if (this.data.role_ids.length < 1) {
      alert("類型不能爲空，請至少選擇一種");
      return;
    }
    this.showLoading = true;
    this.data.employer_id = Number(this.data.employer_id);
    this.service.createLabour(this.data).subscribe(async (res: any) => {
      let newArr: any = [...this.a12Arr, ...this.licenseArr];
      if (newArr.length == 0) {
        this.reset();
        return;
      }
      await this.getCerts(newArr, res);
      this.reset();
    });
  }
  //完成之后的重置
  reset() {
    this.createNew = true;
    this.data = { role_ids: [] };
    this.a12Arr = [];
    this.licenseArr = [];
    this.showLoading = false;
    this.editNow = false;
    this.saveId = undefined;
    this.getList(this.type, this.saveIndex);
  }

  //更新
  toUpdate() {
    if (this.data.role_ids.length < 1) {
      alert("類型不能爲空，請至少選擇一種");
      return;
    }
    this.showLoading = true;
    this.service.updateLabour(this.saveId, this.data).subscribe(async (res: any) => {
      let newArr: any = [...this.a12Arr, ...this.licenseArr];
      if (newArr.length == 0) {
        this.reset();
        return;
      }
      await this.getCerts(newArr, res);
      this.reset();
    });
  }
  //創建證書關聯
  async getCerts(Arr: any, res: any) {
    return new Promise((resolve, reject) => {
      const { id } = res;
      for (const item of Arr) {
        if (!item.hasOwnProperty("id")) {
          item.owner_id = id;
          this.service.createCert(item).subscribe((result: any) => {});
        } else {
          this.service.updateCert(item.id, item).subscribe((result: any) => {});
        }
      }
      resolve();
    });
  }

  //刪除證書的添加
  remove(e: any, i: any, certid: any) {
    if (this.editNow) {
      console.log(certid, "qqq");
      let msg = "確定刪除關聯的證書嗎";
      if (confirm(msg) == true) {
        this.showLoading = true;
        this.service.deleteCert(certid).subscribe((result: any) => {});
      }
    }
    if (e == "driver") {
      this.licenseArr.splice(i, 1);
    } else {
      this.a12Arr = [];
    }
    this.showLoading = false;
  }
  //添加駕駛證書
  addDriverLicense() {
    this.licenseArr.push({
      reference_no: "",
      date_of_issue: "",
      cert_valid_date_from: "",
      cert_valid_date_to: "",
      kind: "",
      owner_type: "LiftingLabour",
    });
  }
  //添加a12證書
  addA12Lisence() {
    this.a12Arr.push({
      reference_no: "",
      date_of_issue: "",
      cert_valid_date_from: "",
      cert_valid_date_to: "",
      kind: "a12",
      owner_type: "LiftingLabour",
    });
  }

  //分頁
  changePage(i: any) {
    if (i < 1 || i > this.saveNum.length) {
      return;
    }
    (this.saveIndex = i), this.getList(this.type, this.saveIndex);
  }
}
