/*
 * @Author: your name
 * @Date: 2020-06-03 17:45:26
 * @LastEditTime: 2020-06-08 17:49:48
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /junhe/frontend/src/app/modules/check_lists/components/check_lists-autograph/check_lists-autograph.component.ts
 */ 
import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { DocumentsService } from '../../services/check.lists.service';
import { I18nService } from 'core-app/modules/common/i18n/i18n.service';



@Component({
  selector: 'check_lists-autograph',
  templateUrl: './check_lists-autograph.component.html',
  styleUrls: ['./check_lists-autograph.component.sass']
})
export class Check_listsAutographComponent implements OnInit {
  @ViewChild(SignaturePad,{static:true}) signaturePad:SignaturePad; //第二视图
  @Output() closeView = new EventEmitter();


  constructor(
    public service: DocumentsService,
    public i18n: I18nService,
  ) { }

  ngOnInit() {
  }

  signatureImage: string;

  public  signaturePadOptions: Object = {
    minWidth: 0.5,// 比划的粗细,
    canvasWidth: 545, // 可写宽度
    canvasHeight: 200,// 可写高度
    backgroundColor: "#edf0f2"
  };
  canvasResize() {
    const canvas:any = document.querySelector('canvas');
    this.signaturePad.set('minWidth', 0.2);
    this.signaturePad.set('canvasWidth', canvas.offsetWidth);
    this.signaturePad.set('canvasHeight', canvas.offsetHeight); 
  }

  ngAfterViewInit() {

    // this.signaturePad.clear();
    
    // this.canvasResize();
    
    }

    //签名导出图片
  drawComplete(close:boolean) {
    console.log('电机出来了吗？',close);
    
    if(close){
      this.signatureImage = this.signaturePad.toDataURL();
      let blob = this.dataURLtoBlob(this.signatureImage)
      console.log(blob.type);
      
      var files = new File([blob], 'drawComplete.png', { type: blob.type })
      let file = new FormData();
      file.append('file', files);

      this.closeView.emit(file)
    }else{
      this.closeView.emit()
    }
    
    
  }

  dataURLtoBlob(dataurl:any) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
}


}
