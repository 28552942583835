import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DocumentsService } from '../../services/documents.service';
import { I18nService } from 'core-app/modules/common/i18n/i18n.service';

@Component({
  selector: 'documents-operation-modal',
  templateUrl: './documents-operation-modal.component.html',
  styleUrls: ['./documents-operation-modal.component.sass']
})
export class DocumentsOperationModalComponent implements OnInit {
  @Output() uploadFile = new EventEmitter();
  @Output() createFolder = new EventEmitter();
  constructor(
    public service: DocumentsService,
    public i18n: I18nService
  ) { }
  handleUploadFile() {
    this.uploadFile.emit();
  }
  handleCreateFolder() {
    this.createFolder.emit();
  }
  ngOnInit() {
    this.service.mask(true);
  }
  ngOnDestroy(): void {
    this.service.mask(false);
  }
}