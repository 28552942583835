import { Component, OnInit, Input } from '@angular/core';
import { DocumentsService } from '../../services/documents.service';

@Component({
  selector: 'documents-catalog-tree',
  templateUrl: './documents-catalog-tree.component.html',
  styleUrls: ['./documents-catalog-tree.component.sass']
})
export class DocumentsCatalogTreeComponent implements OnInit {
  @Input() value: any;
  @Input() level = 0;
  constructor(
    public service: DocumentsService
  ) {}
  //获取子目录
  getChildrenFolder(item: any) {
    if (!item.openIcon) {
      this.service.showOperationLoading(true);
      this.service.getChildrenFolder(item.id).subscribe((res: any) => {
        if (res.result === 'ok') {
          this.service.showOperationLoading(false);
          item.children = res.data;
          item.openIcon = true;
        } else {
          this.service.showOperationLoading(false);
        }
      })
    } else {
      item.children = [];
      item.openIcon = false;
    }
  }
  //选中子目录
  selectFolder(item: any, event: any) {
    this.service.selectFolderId = item.id;
    this.service.selectFolderItem = item;
    //取消事件冒泡
    this.service.stopPropagation(event);
  }
  ngOnInit() {}
}