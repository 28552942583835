<div class="content">
  <div class="input_info">
    <p>{{ i18n.t("js.check-lists.quest.title") }}*<input type="text" (blur)="blurTitle()"
        [(ngModel)]="objectData.name"></p>

    <p>{{ i18n.t("js.check-lists.quest.Explanation") }}<input type="text" (blur)="blurType()"
        [(ngModel)]="objectData.description"></p>

    <div>{{ i18n.t("js.check-lists.quest.Typesof") }}*
      <div class="divInput">
        <div class="input" (click)="openValue()">
          <input [(ngModel)]="name" type="text" disabled="true" style="background: #fff;" (input)="clearInput()" >
          <div class="list" *ngIf="show">
            <ul>
              <li (click)="getvalue(item);$event.stopPropagation();" *ngFor="let item of tableData">{{ item.name }}</li>
            </ul>
          </div>
        </div>

      </div>
    </div>
    <!-- <div class="TemplateBasicForm__toggle-option"><span
        class="TemplateBasicForm__toggle-option-title">{{ i18n.t("js.check-lists.quest.Needsignature") }}</span>
      <div class="Toggle" [class.Toggle--checked]="showSign" (click)="selectSign()">
        <div>
          <div class="Toggle__track"></div>
          <div class="Toggle__thumb"></div>
        </div><input data-testid="TemplateBasicForm__signatures-toggle" class="BaseOnOff__screenreader-only"
          type="checkbox">
      </div>
    </div> -->
  </div>
</div>