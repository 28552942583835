<!--
 * @Author: your name
 * @Date: 2020-06-12 01:44:10
 * @LastEditTime: 2020-06-16 18:29:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /junhe/frontend/src/app/modules/check_lists/components/check_lists-issue/check_lists-issue.component.html
--> 
<div class="loading"></div>

<div class="IssueNewModal ">
  <div class="SimpleModal">
    <div class="SimpleModal-content">
      <div class="modal-header">
        <div class="SimpleModal__header-container">
          <div>Create New Issue</div>
          <button class="SimpleModal__header-button icon-close" (click)='cleon()'></button>
        </div>
      </div>
      <div class="modal-body">
        <div class="isui-form">
          <div class="formField">
            <div class="formField__label">title</div>
            <div class="InputValidationView">
              <input  class="inputValidationView-subtitle" [(ngModel)]="payload.subject"/>
            </div>
          </div>
          <div class="formField">
            <div class="formField__label">DESCRIPTION</div>
            <div class="InputValidationView">
              <input class="inputValidationView-subtitle" [(ngModel)]="payload.description.raw"/>
            </div>
          </div>
          <div class="IssuesUINamespace"></div>
          <div class="formField">
            <div class="formField__label">Assigned By</div>
            <div class="InputValidationView">
              <select class="Editable_loding_input" [(ngModel)]="payload._links.assignee.href">
                <option [value] =""></option>
                <option [value]="item._links.self.href"  *ngFor="let item of byuserdata">{{item.name}}</option>
              </select>
            </div>
          </div>
          <div class="formField">
            <div class="formField__label">Assigned To</div>
            <div class="InputValidationView">
              <select class="Editable_loding_input" [(ngModel)]="payload._links.responsible.href">
                <option [value] =""></option>
                <option [value]="item._links.self.href" *ngFor="let item of userdata">{{item.name}}</option>
              </select>
            </div>
          </div>
          <div class="formField">
            <div class="formField__label">Estimated time</div>
            <div class="InputValidationView">
              <div class="InputValidationView">
                <input type="number" step="0.01" class="inputValidationView-subtitle" (ngModelChange)="parser($event)" [(ngModel)]="estimatedTime"/>
              </div>
            </div>
          </div>
          <div class="IssuesUINamespace"></div>
          <div class="formField">
            <div class="formField__label">Date</div>
            <div class="InputValidationView formField__label_content">
              <input type="text" id="test1" [value]="payload.startDate">
              <span class="attributes-key-value--value-separator"></span>
              <input type="text" id="test2" [value]="payload.dueDate">
            </div>
          </div>
          <div class="formField">
            <div class="formField__label">Version</div>
            <div class="InputValidationView">
              <select class="Editable_loding_input" [(ngModel)]="payload._links.version.href">
                <option [value] =""></option>
                <option [value]="item._links.self.href" *ngFor="let item of schema.version._embedded.allowedValues">{{item.name}}</option>
              </select>
            </div>
          </div>
          <div class="formField">
            <div class="formField__label">Progress (%)</div>
            <div class="InputValidationView">
              <input type="number" class="inputValidationView-subtitle" [(ngModel)]="payload.percentageDone"/>
            </div>
          </div>
          <div class="formField">
            <div class="formField__label">Priority</div>
            <div class="InputValidationView">
              <select class="Editable_loding_input" [(ngModel)]="text.id" (ngModelChange)="assignedby($event)">
                <option [value]="item.id" *ngFor="let item of schema.priority._embedded.allowedValues">{{item.name}}</option>
              </select>
            </div>
          </div>
          <div class="formField">
            <div class="formField__label">Category</div>
            <div class="InputValidationView">
              <select class="Editable_loding_input" [(ngModel)]="payload._links.category.href">
                <option [value] ="'admin'"></option>
                <option [value]="item._links.self.href" *ngFor="let item of schema.category._embedded.allowedValues">{{item.name}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="IssueNew__button-footer">
          <div class="IssueNew__button-footer-create-another"></div>
          <button class="Button--link-secondary" (click)='cleon()'>
              cancel
          </button>
          <button class="Button--primary" (click)='create()'>
            Create
          </button>
        </div>
      </div>
    </div>
  </div>
</div>