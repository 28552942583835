import { Component, OnInit } from '@angular/core';
import { I18nService } from '../common/i18n/i18n.service';

@Component({
  selector: 'SiteDiary',
  templateUrl: './SiteDiary.component.html',
  styleUrls: ['./SiteDiary.component.sass']
})
export class SiteDiaryComponent implements OnInit {

  public displayTemplate:any
  public currentPage:any = 1
  public action:any = ''

  constructor(   public i18n: I18nService,) { }


  ngOnInit() {
    jQuery('#content').css({'height':'100%'});
    this.handleRoutesData()
  }

  //处理路由携带的参数
  handleRoutesData(){
    let locationData = window.location.search.replace('?','?&',).split('&')
    console.log(locationData.indexOf('route'))
    for (let i = 0; i < locationData.length; i++) {
      let itemdata = locationData[i].split('=')
      if(itemdata[0] === 'route'){
        this.displayTemplate = itemdata[1]
        this.currentPage = this.getQueryString('currentPage')
        this.action = this.getQueryString('action')
        break;
      }else{
        this.displayTemplate = null
      }
    }
    // locationData.forEach((res,index)=>{
    //   if(res === '?') return
    //   let itemdata = res.split('=')
    //   if(itemdata[0] === 'route') this.displayTemplate = itemdata[1]
    // })
  }
  getQueryString(name:any) {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    let url = window.location.href.split('?')[1].match(reg);
    // console.log(url)
    if (url != null) {
      return decodeURI(url[2])//decodeURI() 函数可对 encodeURI() 函数编码过的 URI 进行解码。
    } else {
      return null
    }
  }

  // 点击SiteDiary列表的的按钮返回的事件
  listItemClick(){
    console.log(window.location)
    this.handleRoutesData()
  }

}
