import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { DocumentsService } from '../../services/documents.service';

//声明全局变量
// declare var Viewer: any;

@Component({
  selector: 'documents-preview',
  templateUrl: './documents-preview.component.html',
  styleUrls: ['./documents-preview.component.sass']
})
export class DocumentsPreviewComponent implements OnInit {
  //预览url
  @Input() url: any;
  //img标签
  // @ViewChild('previewImg', { static: false }) previewImg: ElementRef;
  constructor(
    public service: DocumentsService
  ) {}
  ngOnInit() {
    this.service.mask(true);
  }
  ngOnDestroy(): void {
    this.service.mask(false);
  }
  
  // ngAfterViewInit(): void {
  //   new Viewer(this.previewImg.nativeElement, {
  //     navbar: false,
  //     toolbar: false,
  //     title: false
  //   });
  // }
  //打开图片预览
  // openPreview(url: any) {
  //   this.url = url;
  //   this.previewImg && this.previewImg.nativeElement.click();
  // }
}