<div class="documents-share">

    <div class="content">
        <span class="title-name">{{ i18n.t("js.documents.sharedata.name") }}：{{sharename}}</span>
        <span class="title-name" (click)="closeclick()">X</span>
    </div>

    <div class="inputContent">
        <input id="input" class="inputclass" type="text" [value]="shareticket" readonly/>
        <a class="copybutton" (click)="copy()">{{ i18n.t("js.documents.sharedata.copy") }}</a>
    </div>

    <div class="inputContent">
        <div class="qrcode" v-show="_showCode">
            <img style="display: none;"  [src]='imgdata' id="logoImg">
              <div class="qrcodeCanvas">
                <canvas id="qrcodeCanvas"></canvas>
              </div>
        </div>
        <a class="copybutton" (click)="downloadCode()">{{ i18n.t("js.documents.sharedata.save") }}</a>
    </div>

    <p>{{ i18n.t("js.documents.sharedata.beizhu") }}</p>

</div>