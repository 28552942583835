<div class="work-packages-list-view--container">
  <div class="toolbar-container -editable">
    <div class="toolbar">
      <editable-toolbar-title [title]="selectedTitle"
                              [inFlight]="querySaving"
                              [showSaveCondition]="hasQueryProps"
                              (onSave)="saveQueryFromTitle($event)"
                              (onEmptySubmit)="updateQueryName('')"
                              [editable]="titleEditingEnabled">
      </editable-toolbar-title>
      <!-- <ul class="filter-list">
        <li>Survey</li>
        <li>Inspection1</li>
        <li>Inspection2</li>
        <li>All</li>
      </ul> -->
      <ul class="toolbar-items hide-when-print"
          *ngIf="tableInformationLoaded">
        <li class="toolbar-item">
          <wp-create-button
            [allowed]="allowed('work_packages', 'createWorkPackage')"
            stateName="work-packages.list.new">
          </wp-create-button>
        </li>
        <li class="toolbar-item">
          <button class="button -alt-highlight add-work-package" (click)="openDownLoadDialog()">
            <span class="button--text"
                  aria-hidden="true">Download Excel</span>
          </button>
        </li>
        <li class="toolbar-item"
            *ngIf="bcfActivated()">
          <bcf-import-button></bcf-import-button>
        </li>
        <li class="toolbar-item"
            *ngIf="bcfActivated()">
          <bcf-export-button></bcf-export-button>
        </li>
        <li class="toolbar-item">
          <wp-filter-button>
          </wp-filter-button>
        </li>
        <!-- <li class="toolbar-item hidden-for-mobile">
          <wp-view-toggle-button>
          </wp-view-toggle-button>
        </li> -->
        <li class="toolbar-item hidden-for-mobile">
          <wp-details-view-button>
          </wp-details-view-button>
        </li>
        <li class="toolbar-item hidden-for-mobile -no-spacing">
          <wp-timeline-toggle-button>
          </wp-timeline-toggle-button>
        </li>
        <li class="toolbar-item hidden-for-mobile">
          <zen-mode-toggle-button>
          </zen-mode-toggle-button>
        </li>
        <li class="toolbar-item">
          <button id="work-packages-settings-button"
                  title="{{I18n.t('js.toolbar.settings.export') }}"
                  class="button last work-packages-settings-button icon-export"
                  (click)="export()"
                 >

          </button>
        </li>
        <li class="toolbar-item">
          <button id="work-packages-download-button"
   
                  class="button last work-packages-settings-button icon-download"
                  (click)="download()"
                 >

          </button>
        </li>
      </ul>
    </div>
    <!-- <div class="toolbar2">
      <ul>
        <li>My Actions</li>
        <li>News</li>
        <li>Inspecting</li>
        <li>Closed</li>
      </ul>
    </div> -->
  </div>

  <filter-container (filtersCompleted)="updateResultVisibility($event)"></filter-container>

  <accessible-by-keyboard
    linkClass="hidden-for-sighted skip-navigation-link"
    [linkAriaLabel]="text.text_jump_to_pagination"
    (execute)="setAnchorToNextElement()">
    <span [textContent]="text.jump_to_pagination"></span>
  </accessible-by-keyboard>

  <!-- (TABLE + TIMELINE) + DETAILS view -->
  <div class="work-packages-split-view">

    <!-- (TABLE + TIMELINE) and FOOTER vertical split-->
    <div class="work-packages-split-view--tabletimeline-side loading-indicator--location"
         data-indicator-name="table">
      <div class="result-overlay"
           *ngIf="showResultOverlay && showListView"></div>

      <!-- TABLE + TIMELINE horizontal split -->
      <wp-table *ngIf="tableInformationLoaded && showListView"
                [projectIdentifier]="projectIdentifier"
                [configuration]="wpTableConfiguration"
                class="work-packages-split-view--tabletimeline-content"></wp-table>

      <!-- GRID representation of the WP -->
      <div *ngIf="!showListView"
           class="work-packages--card-view-container">
        <wp-grid></wp-grid>
      </div>

      <!-- Footer -->
      <div class="work-packages-split-view--tabletimeline-footer hide-when-print"
           *ngIf="tableInformationLoaded">
        <wp-table-pagination></wp-table-pagination>
      </div>
    </div>

    <!-- Details view -->
    <div class="work-packages-split-view--details-side" ui-view></div>
  </div>
</div>
