<div *ngIf="createNew">
  <!-- <div class="plantContent">
    <ul class="titleUl">
      <li>
        <span class="titlespan">Status</span>
        <div class="subdiv">
          <span style="background-color: #00a74c"
            >20 <br />
            <br />Active
          </span>
          <span style="background-color: #aaa; margin-left: 25px"
            >20 <br />
            <br />Inactive
          </span>
        </div>
      </li>
      <li>
        <span class="titlespan">Expiry alert</span>
        <div class="subdiv">
          <span style="background-color: #e63649"
            >20 <br />
            <br />expired
          </span>
          <span style="background-color: #ffc135; margin-left: 25px"
            >20 <br />
            <br />Expire in 3 months
          </span>
        </div>
      </li>
    </ul>
  </div> -->

  <!-- 下面的表格 -->
  <div class="table">
    <div class="tabletop">
      <!-- 表格上面的下拉框 -->
        <span class="bigSpan">Plant</span>
      <button class="creatbtn icon-add" (click)="toCreate()">Create</button>
    </div>
    <div class="tablecontent">
      <div class="work-packages-split-view--tabletimeline-content mainContent">
        <div class="work-packages-tabletimeline--table-side work-package-table--container __hidden_overflow_container">
          <table class="keyboard-accessible-list generic-table work-package-table modulelistTable">
            <thead class="-sticky">
              <tr style="border-bottom: 1px solid #e7e7e7">
                <th class="wp-table--table-header headcenter">No</th>
                <th class="headcenter">ID</th>
                <th class="headcenter">Type</th>
                <th class="headcenter">Sub Type</th>
                <th class="headcenter">Model No</th>
                <th class="headcenter">SerialNo</th>
                <th class="headcenter">Cert Valid Date From</th>
                <th class="headcenter">Cert Valid Date To</th>
                <th class="headcenter">Created At</th>
                <th class="headcenter">Operation</th>
              </tr>
            </thead>
            <tbody class="results-tbody work-package--results-tbody" *ngFor="let item of tableList; let i = index">
              <tr
                tabindex="0"
                data-work-package-id="303"
                data-class-identifier="wp-row-303"
                class="wp-table--row wp--row wp-row-303 wp-row-303-table issue __hierarchy-root-303"
              >
                <td class="headcenter">{{ item.id }}</td>
                <td class="headcenter">{{ item.plant_no }}</td>
                <td class="headcenter">{{ item.type }}</td>
                <td class="headcenter">{{ item.sub_type }}</td>
                <td class="headcenter">{{ item.model_no }}</td>
                <td class="headcenter">{{ item.serial_no }}</td>
                <td class="headcenter"><span *ngIf="item.cert">{{ item.cert.cert_valid_date_from }}</span></td>
                <td class="headcenter"><span *ngIf="item.cert">{{ item.cert.cert_valid_date_to }}</span></td>
                <td class="headcenter">{{ item.created_at }}</td>
                <td class="headcenter">
                  <span class="viewspan" (click)="toEdit(item.id)">Edit</span
                  ><span class="viewspan" (click)="toDelete(item.id)">Delete</span>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="paging">
            <ul class="pagingUl">
              <li (click)="changePage(saveIndex - 1)"><</li>
              <li
                *ngFor="let item of saveNum; let i = index"
                [ngClass]="{ bluebtn: i == saveIndex - 1 ? true : false }"
                (click)="changePage(i + 1)"
              >
                {{ i + 1 }}
              </li>
              <li (click)="changePage(saveIndex + 1)">></li>
              <span>共{{ count }}頁</span>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!createNew">
  <div class="plantEdit">
    <div class="rightBtn">
      <button class="editbtn cancelbtn" (click)="toCancel()">Cancel</button>
      <button class="editbtn" (click)="toSubmit()" *ngIf="!editNow">Submit</button>
      <button class="editbtn" (click)="toUpdate()" *ngIf="editNow">Update</button>
    </div>
    <div class="orCode" *ngIf="editNow">
      <img [src]="qrId" class="codePic" />
    </div>

    <ul class="toplist">
      <li><a href="#information">1.Basic Information</a></li>
    </ul>
    <hr class="hrstyle" />
    <div class="SectionItem__loading loadingDiv" *ngIf="showLoading">
      <div class="loading-indicator -compact">
        <div class="block-1"></div>
        <div class="block-2"></div>
        <div class="block-3"></div>
        <div class="block-4"></div>
        <div class="block-5"></div>
      </div>
    </div>
    <div class="inmiddle" id="information">
      <p class="title">1.Basic Information</p>
      <p>
        <span class="keyspan">Plant Type:</span>
        <select [(ngModel)]="data.type" *ngIf="!editNow">
          <option value="LiftingGear">Lifting Gears</option>
          <option value="MobileCrane">Mobile Cranes</option>
          <option value="TowerCrane">Tower Cranes</option>
          <option value="Others">Others</option>
        </select>
        <input type="text" disabled [(ngModel)]="data.type" *ngIf="editNow" />
      </p>
      <p *ngIf="data.type=='MobileCrane'">
        <span class="keyspan">Subtype:</span>
        <select [(ngModel)]="data.sub_type">
          <option *ngFor="let item of saveSubType" [value]="item">{{item}}</option>
        </select>
      </p>
      <p *ngIf="data.type=='Others'">
        <span class="keyspan">Subtype:</span>
        <select [(ngModel)]="data.sub_type">
          <option *ngFor="let item of saveOtherSubType" [value]="item">{{item}}</option>
        </select>
      </p>
      <p><span class="keyspan">Contract No:</span><input type="text" disabled [(ngModel)]="data.contract_no"/></p>
      <p>
        <span class="keyspan">Status:</span>
        <select [(ngModel)]="data.state">
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
        </select>
      </p>
      <p *ngIf="data.type=='LiftingGear'"><span class="keyspan">Site No.:</span><input type="text" [(ngModel)]="data.plant_no" /></p>
      <p *ngIf="data.type=='LiftingGear'"><span class="keyspan">ID Mark:</span><input type="text" [(ngModel)]="data.id_mark" /></p>
      <p *ngIf="data.type!='LiftingGear'"><span class="keyspan">Plant No:</span><input type="text" [(ngModel)]="data.plant_no" /></p>
      <p *ngIf="data.type=='LiftingGear'"><span class="keyspan">Safe Working Load:</span><input type="text" [(ngModel)]="data.working_load" /></p>
      <p><span class="keyspan">Description:</span><input type="text" [(ngModel)]="data.description" /></p>
      <p><span class="keyspan">Cert No:</span><input type="text" [(ngModel)]="data.cert_no" /></p>
      <div class="containTime">
        <span class="keyspan">Cert Expire Date From:</span>
        <div class="timeDiv" id="cert_valid_date_from" (click)="dateChange('cert_valid_date_from')">
          {{ data.cert_valid_date_from }}
        </div>
      </div>
      <div class="containTime">
        <span class="keyspan">Cert Expire Date To:</span>
        <div class="timeDiv" id="cert_valid_date_to" (click)="dateChange('cert_valid_date_to')">
          {{ data.cert_valid_date_to }}
        </div>
      </div>
      <p *ngIf="data.type!='LiftingGear'"><span class="keyspan">Model No:</span><input type="text" [(ngModel)]="data.model_no" /></p>
      <p *ngIf="data.type!='LiftingGear'"><span class="keyspan">Serial No:</span><input type="text" [(ngModel)]="data.serial_no" /></p>
      <p *ngIf="data.type!='LiftingGear'">
        <span class="keyspan">Danger Zone Ground:</span><input type="number" [(ngModel)]="data.danger_zone_ground" />
      </p>
      <p *ngIf="data.type!='LiftingGear'"><span class="keyspan">Danger Zone Roof:</span><input type="number" [(ngModel)]="data.danger_zone_roof" /></p>
      <p *ngIf="data.type!='LiftingGear'">
        <span class="keyspan">Danger Zone Others:</span><input type="number" [(ngModel)]="data.danger_zone_others" />
      </p>
      <p *ngIf="data.type!='LiftingGear'">
        <span class="keyspan">Max Safe Working Load Lifting Radius</span
        ><input
          class="range"
          type="number"
          [(ngModel)]="data.working_radius_from"
        />-<input
          class="range"
          type="number"
          [(ngModel)]="data.working_radius_to"
        />
      </p>
      <p *ngIf="data.type!='LiftingGear'">
        <span class="keyspan">Max Safe Working Load Lifting Capacity:</span
        ><input
          class="range"
          type="number"
          [(ngModel)]="data.working_capacity_from"
        />-<input
          class="range"
          type="number"
          [(ngModel)]="data.working_capacity_to"
        />
      </p>
      <p *ngIf="data.type!='LiftingGear'">
        <span class="keyspan">Register Name of Manufacturer:</span
        ><input type="text" [(ngModel)]="data.manufacturer" />
      </p>
      <div class="containTime" *ngIf="data.type!='LiftingGear'">
        <span class="keyspan">Form 2 Expired Date On:</span>
        <div class="timeDiv" id="form_2_expired_date" (click)="dateChange('form_2_expired_date')">
          {{ data.form_2_expired_date }}
        </div>
      </div>
      <div class="containTime" *ngIf="data.type!='LiftingGear'">
        <span class="keyspan">Form 2 Tested Date On:</span>
        <div class="timeDiv" id="form_2_tested_date" (click)="dateChange('form_2_tested_date')">
          {{ data.form_2_tested_date }}
        </div>
      </div>
      <div class="containTime" *ngIf="data.type!='LiftingGear'">
        <span class="keyspan">Form 3 Expired Date On:</span>
        <div class="timeDiv" id="form_3_expired_date" (click)="dateChange('form_3_expired_date')">
          {{ data.form_3_expired_date }}
        </div>
      </div>
      <div class="containTime" *ngIf="data.type!='LiftingGear'">
        <span class="keyspan">Form 4 Tested Date On:</span>
        <div class="timeDiv" id="form_4_tested_date" (click)="dateChange('form_4_tested_date')">
          {{ data.form_4_tested_date }}
        </div>
      </div> 
      <div class="containTime" *ngIf="data.type!='LiftingGear'">
        <span class="keyspan">Form 5 Expired Date On:</span>
        <div class="timeDiv" id="form_5_expired_date" (click)="dateChange('form_5_expired_date')">
          {{ data.form_5_expired_date }}
        </div>
      </div>
      <div class="containTime" *ngIf="data.type!='LiftingGear'">
        <span class="keyspan">Date On Site:</span>
        <div class="timeDiv" id="date_on_site" (click)="dateChange('date_on_site')">{{ data.date_on_site }}</div>
      </div>
      <div class="containTime" *ngIf="data.type!='LiftingGear'">
        <span class="keyspan">Date off Site:</span>
        <div class="timeDiv" id="date_off_site" (click)="dateChange('date_off_site')">{{ data.date_off_site }}</div>
      </div>
      <p *ngIf="editNow"><span class="keyspan">Updated at:</span><input type="text" [(ngModel)]="data.updated_at" disabled /></p>
      <p *ngIf="editNow"><span class="keyspan">Updated by:</span><input type="text" [(ngModel)]="data.updated_by" disabled /></p>
      <p *ngIf="editNow"><span class="keyspan">Created at:</span><input type="text" [(ngModel)]="data.created_at" disabled /></p>
      <p *ngIf="editNow"><span class="keyspan">Created by:</span><input type="text" [(ngModel)]="data.created_by" disabled /></p>

    </div>
  </div>
</div>
