import { Component, OnInit ,Input,Output,EventEmitter} from '@angular/core';
import { DocumentsService } from '../../services/documents.service';
import { I18nService } from 'core-app/modules/common/i18n/i18n.service';

@Component({
  selector: 'documents-share',
  templateUrl: './documents-share.component.html',
  styleUrls: ['./documents-share.component.sass']
})
export class DocumentsShareComponent implements OnInit {

  @Input() shareticket: any;
  @Input() sharename: any;
  @Output() close = new EventEmitter();

  public imgdata = `http://${window.location.host}/images/logo.jpg`


  constructor(
    public service: DocumentsService,
    public i18n: I18nService
  ) {}

  ngOnDestroy(): void {
    this.service.mask(false);
  }

  ngOnInit() {
   
  }

  ngAfterViewInit(){
    console.log(this.shareticket);
    
    this.service.mask(true);
    var QRCode = require('qrcode')

    const options = {
      width: 200,
      height: 200,
    };
    QRCode.toCanvas(this.shareticket, options)
      .then((el:any) => {
        this.mergeCanvas(el);		//生成二维码后，合并二维码
      })
      .catch((err:any) => {
      });
    

  }

  downloadCode(){
    console.log('dianjishijianfasheng le ?');
    
    const  qrcodeCanvas:any = document.getElementById("qrcodeCanvas");
    const url = qrcodeCanvas.toDataURL('image/png');
    console.log(this.dataURLtoBlob(url));
    this.downloadFile(url,"code")
    
  }

  copy(){
    const input:any = document.getElementById('input')
    input.select();
    document.execCommand("copy"); // 执行浏览器复制命令
      alert("复制成功");
  }

  closeclick(){
    console.log("(???????);")
    
    this.close.emit()
  }

  dataURLtoBlob(dataurl:any) { 
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
}

downloadFile(content:any, fileName:any) { //下载base64图片
  var base64ToBlob = function(code:any) {
      let parts = code.split(';base64,');
      let contentType = parts[0].split(':')[1];
      let raw = window.atob(parts[1]);
      let rawLength = raw.length;
      let uInt8Array = new Uint8Array(rawLength);
      for(let i = 0; i < rawLength; ++i) {
          uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], {
          type: contentType
      });
  };
  let aLink = document.createElement('a');
  let blob = base64ToBlob(content); //new Blob([content]);
  let evt = document.createEvent("HTMLEvents");
  evt.initEvent("click", true, true); //initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
  aLink.download = fileName;
  aLink.href = URL.createObjectURL(blob);
  aLink.click();
}

  mergeCanvas(generateCanvas:any) {		//生成的二维码 canvas	
    const  qrcodeCanvas:any = document.getElementById("qrcodeCanvas");
    const logoCavans:any = document.getElementById("logoImg");		//第2步里面的转换后的canvas
    logoCavans.onload=()=>{
      const canvas:any = document.createElement('canvas');
      canvas.width = generateCanvas.width;
      canvas.height = generateCanvas.height;
      canvas.getContext('2d').drawImage(generateCanvas, 0, 0);	//将 generateCanvas 画到 canvas 上，坐标 0，0
      canvas.getContext('2d').drawImage(logoCavans, 80, 80,40,40);	//将 logoCavans 画到 canvas 上，坐标 80，80
      console.log(logoCavans);
      console.log(generateCanvas);
      
      
      qrcodeCanvas.width = canvas.width;
      qrcodeCanvas.height = canvas.height;
      qrcodeCanvas.getContext('2d').drawImage(canvas, 0, 0);
    }
    
  }

}
