
import { Component, OnInit, ViewChild, EventEmitter, Output ,Input} from '@angular/core';
import { Photo_logService } from '../../services/photo_log.service';
import { I18nService } from 'core-app/modules/common/i18n/i18n.service';
import { ThemeService } from 'ng2-charts';


@Component({
    selector: 'photo_log-edit',
    templateUrl: './photo_log-edit.component.html',
    styleUrls: ['./photo_log-edit.component.sass']
  })
  export class Photo_logEditComponent implements OnInit {
    @Input() public saveitemId:any
    @Input() public showRight:any
    @Input() public saveimg:any
    @Input() public getsingledata :any

    @Output() hideEditpage = new EventEmitter();
    @Output() goRefresh = new EventEmitter();


    public saveData:any
    public imgArr:any
    public projectId:any
    public id:any
    public editFormdata:any={
      id:'',
      photo_log: {
        remarks:'',
        photo_log_purpose_id:' ',
        photo_log_state_id:"",
      },
      location:{
      }
    }
    public singdata:any
    public ismulti:any

  
    constructor(
      public service: Photo_logService,
      public i18n: I18nService,
    ) { }
  
    ngOnInit() {
    
      this.projectId = document.querySelector('meta[name=current_project]')
      this.id = this.projectId.dataset.projectId
      this.service.getType(this.id).subscribe((res:any)=>{
        this.saveData=res.allowed_values
      })

      this.ismulti = sessionStorage.getItem('multi')
      console.log(this.showRight)
      if(this.ismulti=='multi'){
        console.log('进来多选')
        this.getsingledetail(this.showRight[0])
      }else if(this.ismulti=='single'){
        console.log('进来单选')
        this.getsingledetail(this.getsingledata)
        this.saveimg=false
      }

    }
//单个的信息
getsingledetail(e:any){
  this.service.getDetailpage(e).subscribe((res:any)=>{
    this.singdata = res
    // console.log(this.singdata,'qq')
    if(!this.saveimg){
      this.editFormdata.location.location1=this.singdata.location.location1
      this.editFormdata.location.location2=this.singdata.location.location2
      this.editFormdata.location.location3=this.singdata.location.location3
      this.editFormdata.location.location4=this.singdata.location.location4
      this.editFormdata.photo_log.photo_log_purpose_id = this.singdata.purpose.purpose_id
      this.editFormdata.photo_log.photo_log_state_id = this.singdata.state.state_id
      this.editFormdata.photo_log.remarks = this.singdata.remarks
    }
  })
}
// 隐藏编辑页面
hideeditpage(){
  this.getsingledata =""
  this.saveimg=[]
  this.showRight=[]
 this.hideEditpage.emit()
}

getKey(obj:any){
  return Object.keys(obj)[0]
}
putName(id:number){
  return  `CustomFiled${id+1}`
}

//编辑
goEditPhotolog(){
  if(this.ismulti=='multi'){
    let data:Array<any> = []
    this.showRight.forEach( (id:any) => {
      let dataItem = Object.assign({}, this.editFormdata)
      dataItem.id = id
      data.push(dataItem)
      this.service.editPhotoLog(data).subscribe((res:any)=>{
        if(res.status=='ok'){
          this.hideeditpage()
          this.goRefresh.emit()
        }
      })
    })

  }else if(this.ismulti=='single'){
    this.editFormdata.id = this.saveitemId
    this.service.editPhotoLog([this.editFormdata]).subscribe((res:any)=>{
      if(res.status=='ok'){
        this.hideeditpage()
        this.goRefresh.emit()
      }
    })
  }

} 

}