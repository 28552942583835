<work-package-comment [workPackage]="workPackage">
  <ng-template>
    <div class="detail-activity">
      <div class="work-package-details-activities-list">
        <div *ngFor="let inf of visibleActivities; trackBy:trackByHref; let first = first"
            class="work-package-details-activities-activity">
          <h3 class="activity-date"
              [ngClass]="{'-with-toggler': first && showToggler}"
              *ngIf="first || inf.isNextDate">
            <span class="activity-date--label" [textContent]="inf.date"></span>
            <accessible-by-keyboard (execute)="toggleComments()"
                                    *ngIf="first && showToggler"
                                    linkClass="activity-comments--toggler button -small -transparent"
                                    [linkAriaLabel]="togglerText">
              <span [textContent]="togglerText"></span>
            </accessible-by-keyboard>
          </h3>

          <activity-entry [workPackage]="workPackage"
                          [activity]="inf.activity"
                          [activityNo]="inf.number(inf.isReversed)"
                          [isInitial]="inf.isInitial()">
          </activity-entry>
        </div>
      </div>

<ng-container *ngIf="showRisclog">
      <h3>Handler  Log</h3>
          <!---risc       log--->
          <div  *ngFor="let item of riscActivtiesLog; trackBy:trackByHref; let first = first;let key =index"  class="work-package-details-activities-activity riscLog"  id="userMess" [attr.data-api]='item._links.user.href'  >
            <ng-container *ngIf="userData">
              <!---usertop--->
          <div class="userTop">
           <user-avatar *ngIf="userData.name"
           [attr.data-user-name]="userData.name"
           [attr.data-user-id]="userData.id"
           data-class-list="avatar">
         </user-avatar>
         <span class="littleTips" *ngIf="showtips">{{userData.name}}</span>
           <a  [attr.href]="userData._links.showUser.href"  class="nameA" (mouseenter)="tips(true)" (mouseleave)='tips(false)'>{{userData.name}}</a>
           <span>
            {{ text.label_updated_on }}
             {{userData.updatedAt|date:'short'}}</span>
           <a href="javascript:;" class="topA">#{{key+riscLength}}</a>
          </div>
          <ul>
            <li>   <p  [innerHtml] ="item.details.html"  style="margin: 10px 0 0 0;"></p></li>
          </ul>
            </ng-container>
           </div>

</ng-container>



    </div>
  </ng-template>
</work-package-comment>
