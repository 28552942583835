import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivityData, SiteDiaryItem, SiteDiaryService} from '../../services/SiteDiary.service';

declare let laydate: any;


@Component({
  selector: 'SiteDiary-Details-addActivity',
  templateUrl: './SiteDiary-Details-addActivity.component.html',
  styleUrls: ['./SiteDiary-Details-addActivity.component.sass']
})
export class SiteDiaryDetailsAddActivityComponent implements OnInit {
  
  @Input() public summaryData: SiteDiaryItem
  @Input() public updataActivty:any
  @Input() public teamIndex:any

  @Output() close = new EventEmitter();

  public sections = []
  public protions = []

  public displayPupop = false
  public pupopType:any

  public activityData:ActivityData = {
    site_diary_id: 1,
    location: "",
    activity: "",
    period_start: "08:00",
    period_end: "18:00",
    section_id: null,
    protion_id: null,
    material: "",
    remark: "",
    labours: [],
    plant: [],
    labur_number:0,
    plant_number:0,
    plant_ldle:0,
    plant_working:0
  }

  constructor(
    public service: SiteDiaryService,
  ) { }

  ngOnInit() {
    console.log(this.updataActivty)
    if(this.updataActivty){
      this.activityData = this.updataActivty
    }
    this.service.getActivitiesSections({}).subscribe((res:any)=>{
      this.sections = res.sort( (pre:any,next:any) => {
       if( pre.name > next.name ) {
         return 1
       } else {
         return -1
       }
      })
    })
    this.service.getActivitiesProtions({}).subscribe((res:any)=>{
      this.protions =  res.sort( (pre:any,next:any) => {
        if( pre.name > next.name ) {
          return 1
        } else {
          return -1
        }
      })
    })
  }

  ngAfterViewInit(){
    if(this.summaryData.state == 'draft'||this.summaryData.state == 'rejected') return
    jQuery('#addActivity')[0].childNodes.forEach((res:any)=>{
      if(res.className == 'summary-key-value' || res.className == 'summary-text-key-value'){
        res.children[1].disabled = true
      }
    })
  }

  //时间事件
  period_startChange() {
    if(this.summaryData.state != 'draft'&&this.summaryData.state != 'rejected') return
    laydate.render({
      elem: '#period_start'
      ,type: 'time'
      ,value:this.activityData.period_start
      ,format: 'HH:mm'
      ,lang: 'en',
      show:true
      ,done:(value:any)=>{
        console.log(value)
        this.activityData.period_start = value
      }
    });
  }
  period_endChange() {
    if(this.summaryData.state != 'draft'&&this.summaryData.state != 'rejected') return
    laydate.render({
      elem: '#period_end'
      ,type: 'time'
      ,value:this.activityData.period_end
      ,format: 'HH:mm'
      ,lang: 'en',
      show:true
      ,done:(value:any)=>{
        console.log(value)
        this.activityData.period_end = value
      }
    });
  }

  //添加列表弹窗
  toAddPlAndLab(value:any){
    this.pupopType = value
    this.displayPupop = true
  }

  pupopClose(data:any){
    this.displayPupop = !this.displayPupop
    // if(data){
    //   Object.assign(this.activityData, ...data)
    //   console.log(this.activityData)
    // }
    
  }

  submitClick(){
    console.log(this.activityData)
    this.activityData.site_diary_id = this.summaryData.id
    if(this.updataActivty){
      let data:any = this.activityData
      data['activity_id'] = this.updataActivty.id
      this.service.updataActivities(data).subscribe((res:any)=>{
        console.log(res)
        this.close.emit()
        jQuery('#top-menu').css('z-index','22')

      })
    }else{
      this.service.addActivities(this.activityData, this.teamIndex).subscribe((res:any)=>{
        console.log(res)
        this.close.emit()
        jQuery('#top-menu').css('z-index','22')
      })
    }
  }

  //关闭弹窗按钮
  closeClick(){
    this.close.emit()
    jQuery('#top-menu').css('z-index','22')
  }

}
