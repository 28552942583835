import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { I18nService } from 'core-app/modules/common/i18n/i18n.service';

@Component({
  selector: 'check_lists-nav',
  templateUrl: './check_lists-nav.component.html',
  styleUrls: ['./check_lists-nav.component.sass']
})
export class Check_listsNavComponent implements OnInit {

  constructor(
    public i18n: I18nService,
  ) { }
  @Input() public nav_pos:any
  @Input() public showNext:any
  @Input() public showFinish:any
  @Input() public changepos:any


  @Output() nextstep = new EventEmitter();
  @Output() getIssue = new EventEmitter();
  @Output() showLists = new EventEmitter();

  ngOnInit() {
    console.log(this.showFinish)
    console.log(this.nav_pos)

  }
  ngDoCheck() {
    if(!this.changepos){
      this.nav_pos = false
    }
  }


  clickOut(){
    if (confirm(this.i18n.t('js.check-lists.cancelCreate'))) {  
        this.showLists.emit()
    } 
  }

  clickIssue(){
    if(this.showFinish) return
    this.getIssue.emit()

  }

  clickNextStep(){
    if (!this.showNext) return
    this.nav_pos = true
    this.changepos = !this.changepos
    this.nextstep.emit()
  }
}
