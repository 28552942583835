import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SEDNComponent } from './SEDN.component';
import { Ng2StateDeclaration, UIRouterModule } from '@uirouter/angular';
import { SednDetailComponent } from './page/sednDetail/sednDetail.component';
import { SEDNImgGroupComponent } from './component/SEDNImgGroup/SEDNImgGroup.component';
import { SEDNIMGComponent } from './component/SEDNIMG/SEDNIMG.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { WpSignatureComponent } from 'core-app/components/work-packages/wp-signature/wp-signature.component';
import { SEDNSignComponent } from './component/SEDNSign/SEDNSign.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImgShowComponent } from './component/ImgShow/ImgShow.component';
export const DOCUMENTS_ROUTES: Ng2StateDeclaration[] = [
  /*这里我暂时用了check_lists的路由，等后端同事配好侧边栏路由后替换，并且记得把check_lists的路由改回来 */
  {
    name: 'sedn',
    url: '/sedn',
    parent: 'root',
    component: SEDNComponent
  }
];

@NgModule({
  
  imports: [
    UIRouterModule.forChild({ 
      states: DOCUMENTS_ROUTES
    }),
    CommonModule,
    SignaturePadModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [SEDNComponent, SednDetailComponent,SEDNImgGroupComponent,SEDNIMGComponent, SEDNSignComponent, ImgShowComponent]
})
export class SEDNModule { }
