<div class="overview">
  <div class="overview-box">
    <div class="overview-title">
      <span>Project Overview</span>
      <select [(ngModel)]="tasksSiteID" (ngModelChange)="tasksDataHandling()">
        <option
          *ngFor="let item of projectList;"
          [value]="item.id"
          >{{ item.name }}</option
        >
      </select>
    </div>
    <div class="overview-con">
      <div class="overview-tasks-list" *ngIf="overviewTasks">
        <div class="tasks-list-item">
          <div class="item-title">
            Total
          </div>
          <div class="item-text">
            {{ overviewTasks.total }}
          </div>
          <div class="item-percentage status__normal">
            <div class="percentage-con"></div>
            <div
              class="percentage-number"
              [ngStyle]="{
                width: (overviewTasks.total / overviewTasks.total) * 100 + '%'
              }"
            ></div>
          </div>
        </div>
        <div class="tasks-list-item">
          <div class="item-title">
            Working
          </div>
          <div class="item-text">
            {{ overviewTasks.working }}
          </div>
          <div class="item-percentage status__success">
            <div class="percentage-con"></div>
            <div
              class="percentage-number"
              [ngStyle]="{
                width: (overviewTasks.working / overviewTasks.total) * 100 + '%'
              }"
            ></div>
          </div>
        </div>
        <div class="tasks-list-item">
          <div class="item-title">
            Expiring
          </div>
          <div class="item-text">
            {{ overviewTasks.expiring }}
          </div>
          <div class="item-percentage status__failure">
            <div class="percentage-con"></div>
            <div
              class="percentage-number"
              [ngStyle]="{
                width:
                  (overviewTasks.expiring / overviewTasks.total) * 100 + '%'
              }"
            ></div>
          </div>
        </div>
        <div class="tasks-list-item">
          <div class="item-title">
            Outdate
          </div>
          <div class="item-text">
            {{ overviewTasks.outdate }}
          </div>
          <div class="item-percentage status__failure">
            <div class="percentage-con"></div>
            <div
              class="percentage-number"
              [ngStyle]="{
                width: (overviewTasks.outdate / overviewTasks.total) * 100 + '%'
              }"
            ></div>
          </div>
        </div>
        <div class="tasks-list-item item-empty">
          <div class="item-title">
            On time
          </div>
          <div class="item-text">
            {{ overviewTasks.on_time }}
          </div>
          <div class="item-percentage status__success">
            <div class="percentage-con"></div>
            <div
              class="percentage-number"
              [ngStyle]="{
                width: (overviewTasks.on_time / overviewTasks.total) * 100 + '%'
              }"
            ></div>
          </div>
        </div>
        <div class="tasks-list-item">
          <div class="item-title">
            Due today
          </div>
          <div class="item-text">
            {{ overviewTasks.due_today }}
          </div>
          <div class="item-percentage status__warning">
            <div class="percentage-con"></div>
            <div
              class="percentage-number"
              [ngStyle]="{
                width:
                  (overviewTasks.due_today / overviewTasks.total) * 100 + '%'
              }"
            ></div>
          </div>
        </div>
        <div class="tasks-list-item">
          <div class="item-title">
            Overdue
          </div>
          <div class="item-text">
            {{ overviewTasks.overdue }}
          </div>
          <div class="item-percentage status__failure">
            <div class="percentage-con"></div>
            <div
              class="percentage-number"
              [ngStyle]="{
                width: (overviewTasks.overdue / overviewTasks.total) * 100 + '%'
              }"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="overview-box">
    <div class="overview-title">
      <span>Project Tasks Overview</span>
      <select [(ngModel)]="itemSiteID" (ngModelChange)="itemDataHandling()">
        <option
          *ngFor="let item of projectList;"
          [value]="item.id"
          >{{ item.name }}</option
        >
      </select>
    </div>
    <div class="overview-con">
      <div
        echarts
        [options]="itemOption"
        [merge]="updateItemOptions"
        [autoResize]="'true'"
        class="overview-chart"
      ></div>
    </div>
  </div>
  <div class="overview-box">
    <div class="overview-title">
      <span> Last 7 day finished </span>
      <select [(ngModel)]="axisSiteID" (ngModelChange)="axisDataHandling()">
        <option
          *ngFor="let item of projectList;"
          [value]="item.id"
          >{{ item.name }}</option
        >
      </select>
    </div>
    <div class="overview-con">
      <div
        echarts
        [options]="axisOption"
        [merge]="updateAxisOptions"
        [autoResize]="'true'"
        class="overview-chart"
      ></div>
    </div>
  </div>
</div>
