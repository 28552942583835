import { Component, EventEmitter, Input, OnInit, Output, Query } from '@angular/core';
import { ThemeService } from 'ng2-charts';
import { SiteDiaryService } from '../../services/SiteDiary.service';

declare let layui: any

@Component({
  selector: 'SiteDiary-List-Approval',
  templateUrl: './SiteDiary-List-Approval.component.html',
  styleUrls: ['./SiteDiary-List-Approval.component.sass']
})
export class SiteDiaryListApprovalComponent implements OnInit {

  @Input() public itemSiteDiary:any

  @Output() close = new EventEmitter();

  public adoptData:any = {
    site_diary_id: 0,
    activitieTeams:[],
  }
  public activities:any = []

  public showloading:boolean = false

  public dipslesignature = false
  public clickIndex = 0
  public activity_teams:any = []
  public clickTeamIndex:any = 0
  public signTime:any = null
  public defaultDoc = document.getElementsByName('current_user')[0].dataset.sign_doc_id
  public actionSign:any //签名图片
  constructor(
    public service: SiteDiaryService,
  ) { }

  ngOnInit() {

    this.service.getNewActivitiesList({site_diary_id:this.itemSiteDiary.id,page:1,per:999}).subscribe((res:any)=>{
      this.activity_teams = res.activity_teams
      this.activities = this.activity_teams[0].list
      this.adoptData.site_diary_id = this.itemSiteDiary.id
      console.log(this.activity_teams[this.clickTeamIndex].list)
      res.activity_teams.forEach((activity:any) => {
        let dataList:any = []
        activity.list.forEach((actres:any) => {
          let itemdataList:any = []
          actres.list.forEach((element:any) => {
            if(element.reason.length !=0)itemdataList.push({"activity_id": element.id, "state": element.reason[0].solve, "reason": element.reason[0].comment})
            if(element.reason.length ==0)itemdataList.push({"activity_id": element.id, "state": true, "reason": ""})
          });
          dataList.push(itemdataList)
        });
        let teamlist= {
          team_id: activity.team_id,
          activities: dataList
        }
        this.adoptData.activitieTeams.push(teamlist)
        console.log(teamlist,'沒眼看');
        this.checkNo(this.clickIndex)
      });
      // res.activities.forEach((element:any) => {
      //   if(element.list.length !=0)this.adoptData.activities.push({"activity_id": element.id, "state": element.list[0].solve, "reason": element.list[0].comment})
      //   if(element.list.length ==0)this.adoptData.activities.push({"activity_id": element.id, "state": true, "reason": ""})
      // });
    })
   
  }
  tabteamClick(e:any) {
    this.clickTeamIndex = e
    this.checkTeamNo(e)
    this.clickIndex = 0
    this.checkNo(0)
  }
  tabClick(e:any){
    this.clickIndex = e
    this.checkNo(e)
  }
  checkTeamNo(index:any) {
    this.activities = this.activity_teams[index].list

  }

  //关闭弹窗
  closeClick(){
    jQuery('#top-menu').css('z-index','22')
    this.close.emit()
  }

  //yes or no按钮
  approvalClick(jindex:any,index:any,boolen:boolean){
    this.adoptData.activitieTeams[this.clickTeamIndex].activities[jindex][index].state = boolen
    this.checkNo(this.clickIndex)
    console.log(this.adoptData)
  }
  //检查是否点击了no
  checkNo(index:any){
    console.log(this.adoptData.activitieTeams)
    if(!this.adoptData.activitieTeams[this.clickTeamIndex].activities[index]) return
   { delete this.adoptData.activitieTeams[this.clickTeamIndex].activities[index].checkFlag
    }
    if(this.adoptData.activitieTeams[this.clickTeamIndex].activities.findIndex( (item:any) => {
     return !item.checkFlag
    }) == -1) {
      delete this.adoptData.activitieTeams[this.clickTeamIndex].checkFlag
    }
    for(let item of this.adoptData.activitieTeams[this.clickTeamIndex].activities[index]){
        if(!item.state){
          this.adoptData.activitieTeams[this.clickTeamIndex].activities[index].checkFlag = true
          this.adoptData.activitieTeams[this.clickTeamIndex].checkFlag = true
        }
    }

  }

  //计算每一个plants统值
  numberData(item:any,name:any){
    let data = 0
    item.forEach((res:any) => {
      data += res[name]
    });
    return data
  }

  //显示那个按钮
  displayButton(){
    let display = true
    let tuichu = false
    for(let index = 0; index<this.adoptData.activitieTeams.length; index ++ ){

      for (let i = 0; i < this.adoptData.activitieTeams[index].activities.length; i++) {
        for (let j = 0; j < this.adoptData.activitieTeams[index].activities[i].length; j++) {
          if(!this.adoptData.activitieTeams[index].activities[i][j].state){
            tuichu = true
            display =  false
            break;
          }else{
            display =  true
          }
        }
        if(tuichu)break;
      }
      if(tuichu)break;
    }
    console.log()
    return display
  }

  //审核接口
  toExamineActivity(){
    let activities:any = []
    let data:any = Object.assign({},this.adoptData)
    console.log(this.adoptData)
    this.adoptData.activitieTeams.forEach((item:any) => {
      console.log(activities)
      item.activities.forEach((index:any) => {
        index.forEach((indexList:any) => {
          activities.push(indexList)
        });
      });
    });
    data.activities = activities
    this.service.toExamineActivity(data).subscribe((res:any)=>{
      this.close.emit()
      this.showloading = false
      jQuery('#top-menu').css('z-index',22) 
    })
  }
  
  //send按钮
  sendClick(){
    this.toExamineActivity()
  }
  //Approve & Signature按钮
  approveClick(){
    // this.dipslesignature = true
    this.openDownLoadDialog()
  }
//打开签名时间弹框
  openDownLoadDialog(){
    let self = this
    layui.use(['jquery','layer','laydate', ], function() {
      var layer = layui.layer,$ = layui.$,
      laydate =  layui.laydate;
      layer.open({
        content:`<div class="layui-form">
                   <div class="layui-form-item">
                     <div class="layui-inline">
                      <label class="layui-form-label">Period</label>
                      <div class="layui-input-inline">
                        <input type="text" class="layui-input" id="test5" placeholder="yyyy-MM-dd HH:mm:ss">
                      </div>
                    </div>
                   </div>
                  </div>
                  <div class="layui-form-item">
                      <img class="signImg" style="max-width: 300px;"/>
                      <div>
                        <input type="checkbox" id="checkSign" /> 默认签名
                      </div>
                    </div>
                  `,
        title:'Time',
        btn:['Confirm'],
        yes: function(index:any, layero:any){
          if(!self.signTime){
            alert('Please select a time')
            return
          }
          // 打开签名弹框
          if(self.actionSign){
            self.showloading = true
            self.toExamineActivity()
          }else{
           self.dipslesignature = true
          }
          layer.close(index); //如果设定了yes回调，需进行手工关闭
        },
        cancel: function(index:any, layero:any){ 
           console.log('djjskkskk');
           self.actionSign= null
           self.adoptData['doc_id'] = null
           self.adoptData['signed_date'] =  null
           layer.close(index)
        }
      });
      $("#checkSign").click(function(){
        let check = $("#checkSign")[0].checked
        console.log(check[0],'状态');
        self.defaultCheck('action',check)
      });
      laydate.render({
        elem: '#test5',
        type: 'datetime'
        ,isInitValue: true
        ,lang: 'en'
        ,done: function(value:any, date:any, endDate:any){
          self.signTime = value
          //添加签名时间
          self.adoptData['signed_date'] =  self.signTime  
        }
      });
  })
  
  }
  //默认签名
  defaultCheck(type:any, e:any){ 
    console.log(e)
    let that = this as any
    if( e == false) {
        that.actionSign = null
        jQuery('.signImg').removeAttr("src");
        that.adoptData['doc_id'] = null
    } else {
      if(this.defaultDoc) {
        that.adoptData['doc_id'] =  this.defaultDoc
        that.actionSign = location.origin + `/api/v3/logical_docs/doc/${this.defaultDoc}/stream`
        jQuery('.signImg').attr("src", that.actionSign);
        console.log(that[type+ 'Sign'], type+ 'Sign')
      } 
    }
  }

  closeView(img:any){
    if(!img) {
      this.dipslesignature = false 
      jQuery('#top-menu').css('z-index',22)
      return
    }
    this.showloading = true
    let blob = this.dataURLtoBlob(img)
    var files = new File([blob], 'drawComplete.png', { type: blob.type })
    let file = new FormData();
    file.append('file', files);
    file.append('prefix', 'site_diary');
    // let projectId:any = document.querySelector('meta[name=current_project]')
    this.service.pushSign(file).subscribe((imgdata:any)=>{
      this.adoptData['doc_id'] = imgdata.doc_id
      this.toExamineActivity()
      this.dipslesignature = false
    })        
  }

  dataURLtoBlob(dataurl:any) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }
  // getActivitiesList

}
