<div class="documents-pagination" [hidden]="total === 0">
    <div class="pagination-btn-wrap"
        [class.disabled]="currentPage === 1"
        (click)="selectPage(currentPage - 1)">
            <div class="overview-left pagination-btn"></div>
    </div>
    <div class="pagination-core">
        <div class="pagination-item"
            [class.current-page]="page === currentPage"
            *ngFor="let page of paginationList"
            (click)="selectPage(page)">
                {{ page }}
        </div>
    </div>
    <div class="pagination-btn-wrap"
        [class.disabled]="currentPage === pageTotal"
        (click)="selectPage(currentPage + 1)">
            <div class="overview-right pagination-btn"></div>
    </div>
    <div class="pagination-total">
        <!-- 总页数文本 -->
        {{ getPageTotalTextLang() }}
    </div>
</div>