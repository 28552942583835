<div class="SEDNImg">
  <div [ngStyle]="{visibility: ((prepared_right && type == 'before') ||  (state == 'Sent' && type == 'after' )  || InputImg.id)? 'visible': 'hidden'}">

    <div  *ngIf="!Imgsrc&&!isWriteIn" #upload class="upload"> 
        <i class="layui-icon-picture layui-icon"></i>
        <span> Add Photo</span>
    </div>
   
  </div>
    
    <div class="img" *ngIf="Imgsrc" >
    
      <img #img  [src]="Imgsrc" (click)="showImg()"/>
      <i class="layui-icon-delete
      layui-icon
      " (click)="deleteImg()" *ngIf="!isWriteIn&&((prepared_right&& type == 'before' && prepared_right) || (state == 'Sent' && type == 'after' && replied_right))"></i>
      <span>{{date}}</span>
  </div>
</div>

