import { Component, OnInit, ChangeDetectorRef ,Output,Input,EventEmitter} from '@angular/core';
import { labour_returnService } from '../../services/labour_return.service';
import { I18nService } from 'core-app/modules/common/i18n/i18n.service';
import * as XLSX from 'xlsx'



@Component({
  selector:'labour_return-table',
  templateUrl: './labour_return-table.component.html',
  styleUrls: ['./labour_return-table.component.sass']
})
export class labourReturnTableComponent implements OnInit {

  @Input() bgcolor:any
  @Input() numArray:any
  @Input() public saveApprovalContent:any
  @Input() public clomnArray:Array<number> = new Array(32)
  public contentEditable:boolean = false
  public version = location.search.split('?')[1].split('&')[0].split('=')[1]
  constructor(
    public service: labour_returnService,
    public i18n: I18nService,
    ) { 
    }


    ngOnInit() {
      jQuery('.tdborder').css('background',this.bgcolor)
      
    }
    ngAfterViewInit(){
      this.changeApprovalContent()

    }

    changeApprovalContent(){
      if(this.saveApprovalContent){
        let {metum} = this.saveApprovalContent
        let Arr = metum.data
        let tableId:any = document.getElementById('mainTable')    
        for(let i:any=0;i<Arr.length;i++){
         for(let j:any=0;j<Arr[i].length;j++){
            tableId.children[i].children[j].innerText =Arr[i][j]
         }
        }
      }
    }
}

