import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Photo_logService } from '../../services/photo_log.service';

@Component({
  selector: 'add-Photo-to-Progress',
  templateUrl: './add-Photo-to-Progress.component.html',
  styleUrls: ['./add-Photo-to-Progress.component.sass']
})
export class AddPhotoToProgressComponent implements OnInit {
  progressList:any = []
  progressSelect:any = ''
  canClose:boolean = true
  @Input() photoLog_id:Array<any>
  @Output() public close:EventEmitter<any> = new EventEmitter<any>()
  constructor(public service: Photo_logService) { }

  ngOnInit() {
    this.getAllProgressList(1)
    console.log(this.photoLog_id)
  }
  getAllProgressList(page:any) {
    this.service.getProgressList(page).subscribe( (res:any) => {
      this.progressList =this.progressList.concat(res.list)
      if(this.progressList.length < res.count ){
        this.getAllProgressList(page + 1)
      } 
    } )
  }
  closeDialog() {
    if(this.canClose) {
      this.close.emit()
    } 
  }
  addToProgress() {
    if(this.progressSelect == '') {
      alert('Progress cannot be empty')
      return
    }
    this.canClose =false
    this.service.addToProgress(this.photoLog_id, this.progressSelect ).subscribe( (res:any) => {
      console.log(res)
      if(res.status == 'ok') {
        alert(res.status)
        this.canClose = true
      }
      this.close.emit(res.status)
    })
  }
}
