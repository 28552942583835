<div id="case" *ngIf="!isProgressDetail">
    <div id="header">
        <h1>
            {{ i18n.t("js.photo-log.PhotoLog") }}
        </h1>
        <div class="screen">
            <div id="timeScreen">
                <input id="timeRangeStart" [placeholder]="i18n.t('js.photo-log.startTime')" /> -
                <input id="timeRanngeEnd" [placeholder]="i18n.t('js.photo-log.endTime')" />
            </div>
            <div class="select">
                <span>Location1</span>
                <select [(ngModel)]="locationsSelect.location1Select" (ngModelChange)="selectLocation()">
                  <option *ngFor="let item of location1" [value]="item.value">{{item.value}}</option>
                  <option value=""></option>
                </select>
                <span *ngIf="locationsSelect.location1Select">
                <span>Location2</span>
                <select [(ngModel)]="locationsSelect.location2Select" *ngIf="locationsSelect.location1Select" (ngModelChange)="selectLocation()">
                  <option *ngFor="let item of location2" [value]="item.value">{{item.value}}</option>
                  <option value=""></option>
                </select>
                </span>
                <span *ngIf="locationsSelect.location2Select">
                <span>Location3</span>
                <select [(ngModel)]="locationsSelect.location3Select" (ngModelChange)="selectLocation()">
                  <option  *ngFor="let item of location3" [value]="item.value">{{item.value}}</option>
                  <option value=""></option>
                </select>
                </span>
                <span>
                    <span>Token By</span>
                  <select [(ngModel)]="token_select_id" (ngModelChange)="selectLocation()">
                      <option  *ngFor="let item of token" [value]="item.user_id">{{item.name}}</option>
                      <option value=""></option>
                    </select>
                  </span>
            </div>
        </div>
        <div id="tag">
            <div class="All" (click)="allInit()" [ngClass]="{select: tagSelect === 'All'}">
                {{ i18n.t("js.photo-log.AllPhotos") }}
            </div>
            <div class="Progress" (click)="ToProgress()" [ngClass]="{select: tagSelect === 'Progress'}">
                {{ i18n.t("js.photo-log.progressPhotos") }}
            </div>
        </div>
    </div>
    <div class="outdiv" *ngIf="tagSelect === 'All'">
        <div class="btn">
            <div class="editbtn">
                <button [ngClass]="{'disablebtn':hasother}" (click)="goEdit()">{{ i18n.t("js.photo-log.edit") }}</button>
                <button [ngStyle]="{'color': isselect?'#ff8645':'#9d9d9d','cursor':isselect?'pointer':'not-allowed','border-color':isselect?'#ff8645':'#ccc'}" (click)="goWorkpackage()">{{ i18n.t("js.photo-log.posttask") }}</button>
                <button [ngClass]="{'disablebtn':hasother}" (click)="toDowenLoad()">{{ i18n.t("js.photo-log.download") }}</button>
                <button [ngClass]="{'disablebtn':hasother}" (click)="goDelete()">{{ i18n.t("js.photo-log.delete") }}</button>
                <button [ngClass]="{'disablebtn':hasother}" (click)="goAddPhotoToProgress()">{{ i18n.t("js.photo-log.addProgressPhotos") }}</button>
            </div>
            <button class="selectbtn" (click)="confirmSelect()" *ngIf="!isselect">{{ i18n.t("js.photo-log.select") }}</button>
            <button class="selectbtn cancelbtn" *ngIf="isselect" (click)="cancelSelect()">{{ i18n.t("js.photo-log.cancel") }}</button>

        </div>
        <div class="content">
            <div *ngFor="let pagedate of items" class="subcontent">
                <span>{{pagedate.page_date}}</span>
                <div class="showpic">
                    <ul class="picul">
                        <li *ngFor="let item of pagedate.data" (click)="toDeatil(item.id,item.user_id,item.image_url)">
                            <!--检测状态是否已经完成-->
                            <div class="spot" [ngStyle]="{'background-color': item.state.name=='已完成'?'#f26269':'#00d277'}" *ngIf="item.purpose.value==1"></div>
                            <!--检测是否点击了选择按钮-->
                            <div class="confirmspot" *ngIf="isselect"></div>
                            <!--检测当前选择了哪些照片-->
                            <ng-container *ngFor="let hasid of showRight">
                                <img [src]="imgOrigin" *ngIf="hasid==item.id" class="selectrightImg">
                            </ng-container>
                            <!--对该照片信息的显示-->
                            <img [src]="item.image_url+'?image_size=thumbnail'" class="img">
                            <span>{{ i18n.t("js.photo-log.photographer") }}:{{item.user_name}}</span>
                            <span>{{item.purpose.name}}</span>
                            <span style="margin-bottom: 5px;" *ngIf="item.purpose.value==1">{{ i18n.t("js.photo-log.status") }}:{{item.state.name}}</span>
                            <span *ngIf="item.state.name=='已完成'">{{ i18n.t("js.photo-log.finishtime") }}:</span>
                            <span *ngIf="item.state.name=='已完成'">{{item.complete_at}}</span>
                        </li>
                    </ul>

                </div>
            </div>
            <photo_log-detail *ngIf="showDetail" class="detailcontent" [hideEditbtn]='hideEditbtn' [saveitemId]='saveitemId' (hideDetailpage)="tohideDetail()" (showEditpage)="toshowEditpage($event)"></photo_log-detail>

            <photo_log-edit [saveitemId]="saveitemId" *ngIf="multitoshowEdit" class="editcontent" [getsingledata]="getsingledata" [showRight]="showRight" [saveimg]="saveimg" (hideEditpage)="tohideEditpage()" (goRefresh)="torefresh()"></photo_log-edit>
            <add-Photo-to-Progress [photoLog_id]="showRight" *ngIf="showAddToProgress" (close)="closeAddToProgress($event)"></add-Photo-to-Progress>
        </div>

        <!-- 分页 -->
    </div>
    <div class="progress" *ngIf="tagSelect === 'Progress'">
        <div class="action">
            <div class="addButton" (click)="progessAdd = true"><span>+</span>&nbsp; <span>Add</span></div>
        </div>
        <div class="table">
            <table>
                <thead>
                    <th>ID</th>
                    <th>Date</th>
                    <th>Title</th>
                    <th>Create By</th>
                    <th>Create Date</th>
                    <th>Shortcut</th>
                </thead>
                <tbody>
                    <tr *ngFor="let item of progressList">
                        <td>{{item.id}}</td>
                        <td>{{item.date}}</td>
                        <td>{{item.title}}</td>
                        <td>{{item.user_id}}</td>
                        <td>{{item.created_at}}</td>
                        <td class="tableButton"> <span (click)="showPrograssDetail(item.id)">View</span> </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="paging" *ngIf="!(showDetail||multitoshowEdit)">
        <ul>
            <li (click)="prepage()">
                <</li>
                    <li class="pagenum" *ngFor="let item of pageSizenum" (click)="toMushpage(item)" [ngStyle]="{'color': item==savenowPage?'#fff':'#000','background-color':item==savenowPage?'#ff8645':'#fff'}">{{item}}</li>
                    <li (click)="nextpage()">></li>
                    <li>{{ i18n.t("js.photo-log.total") }} <span style="margin: 0 5px;">{{pageCount}}</span> {{ i18n.t("js.photo-log.page") }}</li>
        </ul>
    </div>
    <!--loading-->
    <div class="loading-indicator loadingposition" *ngIf="showloading">
        <div class="block-1"></div>
        <div class="block-2"></div>
        <div class="block-3"></div>
        <div class="block-4"></div>
        <div class="block-5"></div>
    </div>
    <progessGroupAdd *ngIf="progessAdd" (close)="refreshProgress()"></progessGroupAdd>
</div>
<photo_log_progress-detail *ngIf="isProgressDetail" (backToTable)="goBackTable()" [ProgressId]="progressId"> </photo_log_progress-detail>