<div class="-columns-2" *ngIf="workPackage.id != 'new'&& ( workPackage.type.name == 'RISC' || workPackage.type.name == 'RISC(inspection)' )">
  <div class="attributes-key-value">
    <div class="attributes-key-value--key">
      <span>Handler</span>
    </div>
    <div class="ng-select-container ng-has-value">
      <div class="ng-value-container">
        <div class="ng-placeholder"></div>
        <select [disabled]="!okselect"  [(ngModel)]="selectCustomFieldGroup" (change)="changesCustomFieldGroup($event)">
          <option [value]="item.id" *ngFor="let item of customFieldGroupPrincipal">{{selectName(item)}}</option>
        </select>
      </div>
    </div>
  </div>
</div>
<div class="-columns-2 flex-columns-2" id="masters">
  <div class="attributes-key-value"
       [ngClass]="{'-span-all-columns': descriptor.spanAll }"
       *ngFor="let descriptor of group.members; trackBy:trackByName ;let i = index">
    <ng-template [ngIf]="!shouldHideField(descriptor)">
      <div
        class="attributes-key-value--key"
        *ngIf="!descriptor.multiple && descriptor.field">
        <wp-replacement-label [fieldName]="descriptor.name">
          {{ descriptor.label }}
          <span class="required"
                *ngIf="descriptor.field!.required && descriptor.field!.writable || mustNotBeBlank.includes(descriptor.label)"> *</span>
        </wp-replacement-label>
        <attribute-help-text [attribute]="descriptor.name" [attributeScope]="'WorkPackage'"></attribute-help-text>
      </div>
      <div *ngIf="!descriptor.multiple && descriptor.field && displaymodel(descriptor.label)"
          class="attributes-key-value--value-container">

        <editable-attribute-field *ngIf="descriptor.field!.isFormattable"
                      class="wp-edit-formattable-field"
                      [resource]="workPackage"
                      [workPackageData]="workPackage"
                      [isDropTarget]="true"
                      [fieldName]="descriptor.name"
                      [fieldLable]="descriptor.label"
                      [is_project_admin]="is_project_admin"
                      [status]="group.status">
        </editable-attribute-field>
        <!-- begin-- 如果不是Inspection detail选项卡页面，按照原有规则渲染 -->
        <ng-container *ngIf="group.name !='Inspection detail'">
          <editable-attribute-field *ngIf="!descriptor.field.isFormattable"
                        [resource]="workPackage"
                        [workPackageData]="workPackage"
                        [fieldName]="descriptor.name"
                        [is_project_admin]="is_project_admin"
                        [status]="group.status">
          </editable-attribute-field>
        </ng-container>
        <!-- 如果是Inspection detail选项卡页面，重新渲染 -->
        <ng-container *ngIf="group.name =='Inspection detail'">
          <!-- 三个选项卡，一个Detail of deficiencies字段文本框 -->
          <ng-container *ngIf="ArrFn(descriptor)">
            <div class="inline-edit--active-field inplace-edit" id="aaa">
                <input *ngIf="descriptor.label != 'Detail of deficiencies'" class="inline-edit--field inline-edit--boolean-field marks deficienciesCheck" type="checkbox" [id]="numerdata(descriptor)"
                (click)='checkTrue($event)'  [(ngModel)]='workPackage[descriptor.name]' [disabled]="userquanx(descriptor.name) || checkdiabale[descriptor.label]">
                <!-- Detail of deficiencies 字段文本输入框 -->
                <input type="text" *ngIf="descriptor.label.trim() == 'Detail of deficiencies'" (blur)="DetailDeficiencies($event,descriptor)" [(ngModel)]='workPackage[descriptor.name]' disabled='disabled' 
                [id]="numerdata(descriptor)"  style="border: none;background-color: #fff;">
            </div>
          </ng-container>
          <!-- 其他文本输入框 -->
          <ng-container  *ngIf="!ArrFn(descriptor)">
            <editable-attribute-field *ngIf="!descriptor.field.isFormattable"
                        [resource]="workPackage"
                        [workPackageData]="workPackage"
                        [status]="group.status"
                        [fieldLable]="descriptor.label"
                        [is_project_admin]="is_project_admin"
                        [fieldName]="descriptor.name" aria-disabled="false">
            </editable-attribute-field>
          </ng-container>
          <!--end--  -->
        </ng-container>
      </div>
      
      <div class='attributes-key-value--value-container' *ngIf="!displaymodel(descriptor.label) " style="display: flex; flex-direction: column;">
        <!-- handler 下拉框下面的文字 -->
        <span style="margin: 10px 0;" >{{dipssignuser(descriptor.name)}}</span>
        <div class="attributes-key-value--value-container-content" 
          [ngStyle]="ValueContainerContentStyle(descriptor)" 
          (click)='displaysign(i)'
          *ngIf="!groupimgdata[descriptor.name] && (!isDefoultSign || !defaultSign )">
          <div class="attributes-key-value--value-container-content-icon"
          [ngStyle]="ValueContainerContentIconStyle(descriptor,check)">
            <span class="icon-add" style="color: #fff;"></span>
          </div>
        </div>
        <div class="attributes-key-value__loading" *ngIf="signloding == i">
          <div class="loading-indicator -compact">
          <div class="block-1"></div>
          <div class="block-2"></div>
          <div class="block-3"></div>
          <div class="block-4"></div>
          <div class="block-5"></div>
          </div>
        </div> 
        
        <img *ngIf="groupimgdata[descriptor.name] && signloding != i || (isDefoultSign && defaultSign )" class="wp-edit-formattable-field-img" (click)='displaysign(i)' [src]='groupimgdata[descriptor.name]?groupimgdata[descriptor.name]:defaultSign'/>
        <!-- <div *ngFor="let item of groupimgdata">
          <img class="wp-edit-formattable-field-img" *ngIf="item.name == descriptor.name" [src]='item.img'/>
        </div> -->
        <span *ngIf="userzhuPlease()">Please assign to a specific User instead of a User Group for signature purpose.</span>
        <span *ngIf="Tip()" >Please complete inspection details before signature.</span>
        <div  *ngIf="!groupimgdata[descriptor.name] && isSign(i) && !!defaultSign" class="signDefaultCheck">
          <input type="checkbox" [(ngModel)]="isDefoultSign"  (change)="checkDefaultSignChange(descriptor.label)"/>
          <span>Apply my default signature</span>
        </div>
      </div>

      <div
        class="attributes-key-value--key"
        *ngIf="descriptor.multiple">
        <wp-replacement-label [fieldName]="descriptor.name">
          {{ descriptor.label }}
        </wp-replacement-label>
        <attribute-help-text [attribute]="descriptor.name" [attributeScope]="'WorkPackage'"></attribute-help-text>
      </div>
      <div
        *ngIf="descriptor.multiple"
        class="attributes-key-value--value-container -minimal">
        <editable-attribute-field [fieldName]="descriptor.fields[0].name"
                       [resource]="workPackage"
                       [workPackageData]="workPackage"
                       [wrapperClasses]="'-small -shrink'"
                       [status]="group.status"
                       [fieldLable]="descriptor.label"
                       [is_project_admin]="is_project_admin"
                       [displayPlaceholder]="text[descriptor.name][descriptor.fields[0].name]">
        </editable-attribute-field>

        <span class="attributes-key-value--value-separator"></span>

        <editable-attribute-field [fieldName]="descriptor.fields[1].name"
                       [resource]="workPackage"
                       [workPackageData]="workPackage"
                       [wrapperClasses]="'-small -shrink'"
                       [status]="group.status"
                       [fieldLable]="descriptor.label"
                       [is_project_admin]="is_project_admin"
                       [displayPlaceholder]="text[descriptor.name][descriptor.fields[1].name]">
        </editable-attribute-field>
      </div>
    </ng-template>
  </div>
</div>


<wp-signature *ngIf="dipslesignature" (closeView)='closeView($event)'></wp-signature>
<span *ngIf="group.name =='Inspection detail'"> *At least one checkbox must be selected</span>