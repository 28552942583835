<div class="mask" (click) = "closeClick()">
  <div class="frame" (click)="$event.stopPropagation()">
    <div class="title">Site Diary Activity</div>
    <div class="close" (click)="closeClick()">X</div>
    <div class="content" id="addActivity">
      <div class="summary-key-value">
        <div class="summary-key">Section：</div>
        <select class="summary-value" [(ngModel)]="activityData.section_id">
          <option selected ></option>
          <option *ngFor="let item of sections" [value]="item.id">{{item.name}}</option>
        </select>
      </div>
      <div class="summary-key-value">
        <div class="summary-key">Portion：</div>
        <select class="summary-value" [(ngModel)]="activityData.protion_id">
          <option selected ></option>
          <option *ngFor="let item of protions" [value]="item.id">{{item.name}}</option>
        </select>
      </div>
      <div class="summary-key-value">
        <div class="summary-key">Location：</div>
        <input class="summary-value" [(ngModel)]="activityData.location"/>
      </div>
      <div class="summary-key-value">
        <div class="summary-key">Activity：</div>
        <textarea type="text" class="summary-value textarea" [(ngModel)]="activityData.activity"></textarea>
      </div>
      <div class="summary-key-value">
        <div class="summary-key">Period Start：</div>
        <div class="summary-value" id="period_start"  (click)="period_startChange()">{{activityData.period_start}}</div>
      </div>
      <div class="summary-key-value">
        <div class="summary-key">Period End：</div>
        <div class="summary-value" id="period_end"  (click)="period_endChange()">{{activityData.period_end}}</div>
      </div>
      <div class="summary-key-value">
        <div class="summary-key">Labour / People：</div>
        <div class="summary-value" (click)="toAddPlAndLab('labour_items')">{{activityData.labur_number}}.0</div>
      </div>
      <div class="summary-key-value">
        <div class="summary-key">Plant / Equipment：</div>
        <div class="summary-value" (click)="toAddPlAndLab('plant_items')"> {{activityData.plant_working}}.0 / {{activityData.plant_ldle}}.0</div>
      </div>
  
    </div>
    <div class="submit">
      <button (click)="submitClick()" *ngIf="summaryData.state == 'draft'||summaryData.state == 'rejected'">Submit</button>
      <button (click)="closeClick()" style="background:#06a1e0">Cancel</button>
    </div>
  </div>
</div>

<SiteDiary-Details-addActivity-Pupop *ngIf="displayPupop" [pupopType]="pupopType" (close)="pupopClose($event)" [summaryData]='summaryData' [activityData]='activityData'></SiteDiary-Details-addActivity-Pupop>