import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { I18nService } from 'core-app/modules/common/i18n/i18n.service';
import { WorkPackageResource } from 'core-app/modules/hal/resources/work-package-resource';
import {LoadingIndicatorService} from "core-app/modules/common/loading-indicator/loading-indicator.service";



@Component({
  selector: 'wp-single-sharePdf',
  templateUrl: './wp-single-sharePdf.component.html',
  styleUrls: ['./wp-single-sharePdf.component.sass']
})
export class WpSingleSharePdfComponent implements OnInit {
  public mailbox:boolean = false;
  public eAlone:any;
  public sReg:any = /^\w+@[a-z0-9]+\.[a-z]{2,4}$/;
  public copyUrl:any;
  public showShare:boolean = false;
  public pdfUrl:any;
  private newHeaders:any = {};
  public eList:any = new Set();
  public loading = false;

  @Input() public workPackage: WorkPackageResource;

  @Output() outputEmitter: EventEmitter<any> = new EventEmitter();

  constructor(
    public i18n: I18nService,
    readonly http: HttpClient,
    readonly loadingIndicator:LoadingIndicatorService,
  ) { }

  ngOnInit() {
    this.http.get(`/api/v3/work_packages/${this.workPackage.id}/share_link`,{withCredentials: true,headers: this.newHeaders}).subscribe((data:any)=>{
      this.pdfUrl = data.link;
    })
  }
  //复制pdf-url
  CopyPdfUrl(){
    //点击按钮复制链接
    this.copyUrl = document.getElementById("copyUrl");
    this.copyUrl.select()
    document.execCommand("copy")
  }
  //添加邮箱号
  onChange(){
    //判断邮箱格式
    if(!this.sReg.test(this.eAlone)){
      alert("Email地址错误！请重新输入。")
      this.eAlone = "";
    }else{
      this.mailbox = true;
      //剔除重复邮箱号
      this.eList.add(this.eAlone);
      this.eAlone = "";      
    }
  }
  //删除当前邮箱
  Delemail(value:string){
    this.eList.delete(value)
  }
  //关闭当前分享PDF页面
  Close(){
    this.outputEmitter.emit();
  }
  //发送email内容
  Transmit(){
    //发送内容
      this.loading = true;

    let arr = Array.from(this.eList)
    this.httpPost(`/api/v3/work_packages/${this.workPackage.id}/share`,{emails: arr}).subscribe((data)=>{
      this.loading = false;
    });
  }
  httpPost(url:string,data:any){
    this.newHeaders['Content-Type'] ='application/json'
    return this.http.post(url,data,{withCredentials: true,headers: this.newHeaders})
  }
  httpGet(url:string){
    this.newHeaders['Content-Type'] ='application/json'
    return this.http.get(url,{withCredentials: true,headers: this.newHeaders})
  }
}
