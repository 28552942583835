import { Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { WorkPackageResource } from 'core-app/modules/hal/resources/work-package-resource';
import { HttpClient } from '@angular/common/http';
import { data } from 'jquery';
import { GroupDescriptor } from '../wp-single-view/wp-single-view.component';




@Component({
  selector: 'wp-single-assignNext',
  templateUrl: './wp-single-assignNext.component.html',
  styleUrls: ['./wp-single-assignNext.component.sass']
})
export class WpSingleAssignNextComponent implements OnInit {
  @Input() public workPackage: WorkPackageResource;
  @Input() public groupedFields: GroupDescriptor;

  @Output() public clone = new EventEmitter();

  constructor(
    readonly http: HttpClient,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  private newHeaders: any = {}
  public watchers: any
  public watcherslist: any
  public selectWatchers: any = null
  public statusdata: any
  public customFieldGroupPrincipal: any = []
  public okbutton: boolean = true
  //INSPECTION DETAIL的三个框
  //approval  name 
  public approvalArr: any = [];
  //diaspproval name
  public diaspprovalTex:any = [];

  //判断跳转
  public approval: any = [
    'There is no objection to you proceeding with the work.',
    'Work reinspected, no objection to you proceeding.',
    'Survey record has been taken'
  ]
  public disapproval = ['The following deficiencies have been noted:', 'Not Ready']
  //状态
  public approvalTip = 'Closed with approval'
  public disapprovalTip = 'Closed with disapproval'

  ngOnInit() {
    console.log('this.groupedFields',this.groupedFields);
    console.log('this.workPackage',this.workPackage);
    
    const csrf_token: string | undefined = jQuery("meta[name=csrf-token]").attr("content");
    this.newHeaders["X-Authentication-Scheme"] = "Session";
    this.newHeaders["X-Requested-With"] = "XMLHttpRequest";

    if (csrf_token) {
      this.newHeaders["X-CSRF-TOKEN"] = csrf_token;
    }
    this.getStatus()
    this.watchershttp()
    this.getWatchersList()

    //approvalArr 三个点击选项的名字
    let groupedData: any = this.groupedFields
    console.log(groupedData)
    groupedData.forEach((data: any) => {
      if (data.name == 'Inspection detail') {
        let groupMembers = data.members;
        groupMembers.forEach((datas: any) => {
          if (this.approval.indexOf(datas.label) != -1) {
            this.approvalArr.push(datas.name)
          }
          if (this.disapproval.indexOf(datas.label) != -1) {
            this.diaspprovalTex.push(datas.name)  //cusfim 44
          }
        })
      }
    })
  }

  getStatus() {
    //所有选项卡中最后一个选项卡的状态
    let endStatus  = this.workPackage._attributeGroups[this.workPackage._attributeGroups.length-1].statuses;
    console.log(endStatus)
    this.httpGet(`/api/v3/work_packages/${this.workPackage.id}`).subscribe((datas: any) => {
      console.log('datas--------', datas)
      this.workPackage.customDefaultSignValues = datas.customDefaultSignValues
      this.httpPost(`/api/v3/work_packages/${this.workPackage.id}/form`, { lockVersion: datas.lockVersion, _links: {} }).subscribe((data: any) => {
        console.log('data====--------', data, this.approvalArr)
        let allowedValues = data._embedded.schema.status._embedded.allowedValues
        let zhoghixunhuan = true
        let statusindex = allowedValues.findIndex((datares: any) => { return datares.id == this.workPackage.status.id })
      //当前状态名workpackgage最后一个分类的状态
      console.log(endStatus.length != 0 &&this.workPackage.status.name == endStatus[0].name)
        if(endStatus.length != 0 &&this.workPackage.status.name == endStatus[0].name){
          console.log('endStatus',endStatus);
          
        //判读当前的状态是否为workpackage里面的最后一个状态，是的话进入下一层判断
        this.approvalArr.forEach((appdata: any) => {  //approvalArr 跳转approval
          //NSPECTION DETAIL 中The following deficiencies have been noted: 被勾选，assigNext跳转disapprovalTip，否则跳转approvalTip
          if(!zhoghixunhuan) return   //跳出foreach循环
          if (datas[appdata]) {   // 
          
            allowedValues.forEach((item: any) => {
              if (this.approvalTip.indexOf(item.name) != -1) { //approvalTip  跳转disApproval
                zhoghixunhuan = false
                this.statusdata = item
              }
            });
            return;
          } else if (this.diaspprovalTex.some( (dis:any) => {
            return datas[dis]
          })) { //disapproval
            allowedValues.forEach((item: any) => {
              if (this.disapprovalTip.indexOf(item.name) != -1) {
                zhoghixunhuan = false
                this.statusdata = item
              }
            });
            return;
          } else {  //跳转下一条操作
            let statusindex = allowedValues.findIndex((datares: any) => { return datares.name == 'Closed' })
            this.statusdata = allowedValues[statusindex]
            // if (statusindex + 1 >= allowedValues.length) {
            //   this.statusdata = allowedValues[statusindex]
            // } else {
            //   this.statusdata = allowedValues[statusindex + 1]
            // }
          }
        });
        }else{ //当前分类不是最后一个
          console.log('当前分类不是最后一个！');
        
          let statusindex = allowedValues.findIndex((datares: any) => { return datares.id == this.workPackage.status.id })
          if (statusindex + 1 >= allowedValues.length) {
            this.statusdata = allowedValues[statusindex]
          } else {
            let groupedData: any = this.groupedFields
            let statuses = groupedData.find( (el:any):boolean => {
              if(el.name === "FOR Supervisor: Countersigned by RE/SIOW/SLS") {
                return true
              } else{
                return false
              }
            })
            groupedData.forEach((element:any) => {
                element.members.forEach( (item:any) => {
                  if(item.field.value ===  false && item.label == 'Countersign required'){
                    for(let status of statuses.status){
                      console.log(status.name, allowedValues[statusindex + 1].name)
                     if(status.name === allowedValues[statusindex + 1].name) {
                       statusindex++
                     }
                    }
                  }
                })
            });
            this.statusdata = allowedValues[statusindex + 1]
          }
        }

        this.watchershttp()
        this.getWatchersList()

        let indexdata = this.workPackage._attributeGroups.filter((data: any) => {
          let dataindex = data.statuses.findIndex((res: any) => { return res.id == this.statusdata.id })
          return dataindex >= 0
        })

        this.getCFavailableList(indexdata, this.statusdata)

        let index = this.workPackage._attributeGroups.filter((data: any) => {
          let dataindex = data.statuses.findIndex((res: any) => { return res.id == this.workPackage.status.id })
          return dataindex >= 0
        })

        index.forEach((element: any) => {
          element.attributes.forEach((data: any) => {
            if (this.workPackage.schema[data].name.indexOf('Signature') >= 0) {
              console.log(data, this.workPackage.customDefaultSignValues[data])
              if (this.workPackage[data] || this.workPackage.customDefaultSignValues[data]) {
              } else {
                this.okbutton = false
              }
            }

          });
        });
      })
    })
  }

  setgroup_principal(data: any, item: any) {

    this.customFieldGroupPrincipal.forEach((element: any) => {
      let selectPrintType = element.principals.find((item:any) => {
        return Number(item.id) == Number(element.select)
      }).type
      if (element.select) {
        this.newHeaders['Content-Type'] = 'application/json'
        let file = { custom_field_group_name: element.custom_field.name, principal_id: element.select, principal_type: selectPrintType }
        this.http.patch(`/api/v3/work_packages/${this.workPackage.id}/change_custom_field_group_principal`, file, { withCredentials: true, headers: this.newHeaders }).subscribe((res: any) => {
        })
      }

    });
  }

  async updateStatus() {
    this.httpPost(`/api/v3/work_packages/${this.workPackage.id}/watchers`, { "user": { "href": this.selectWatchers } }).subscribe((res: any) => {
      // this.watchers = res._embedded.elements
      this.getWatchersList()
      this.watchershttp()
      if (this.statusdata.id != this.workPackage.status.id) {
        this.httpGet(`/api/v3/work_packages/${this.workPackage.id}`).subscribe((data: any) => {
          let httpdata: any = { lockVersion: data.lockVersion, _links: { status: { href: this.statusdata._links.self.href } } }
          this.http.patch(`/api/v3/work_packages/${this.workPackage.id}`, httpdata, {
            withCredentials: true,
            headers: this.newHeaders
          }).subscribe((status: any) => {
            location.reload(true)
          })
        })
  
      }
    },
    () => {
      if (this.statusdata.id != this.workPackage.status.id) {
        this.httpGet(`/api/v3/work_packages/${this.workPackage.id}`).subscribe((data: any) => {
          let httpdata: any = { lockVersion: data.lockVersion, _links: { status: { href: this.statusdata._links.self.href } } }
          this.http.patch(`/api/v3/work_packages/${this.workPackage.id}`, httpdata, {
            withCredentials: true,
            headers: this.newHeaders
          }).subscribe((status: any) => {
            location.reload(true)
          })
        })
  
      }
    }
    );

  }

  //获取可添加watchers
  watchershttp() {
    this.httpGet(`/api/v3/work_packages/${this.workPackage.id}/available_watchers?pageSize=9999`).subscribe((res: any) => {
      this.selectWatchers = null
      this.watchers = res._embedded.elements
      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();
    });
  }


  changeswatchers(item: any) {
  }

  //添加watchers
  addwatchers() {
    this.httpPost(`/api/v3/work_packages/${this.workPackage.id}/watchers`, { "user": { "href": this.selectWatchers } }).subscribe((res: any) => {
      // this.watchers = res._embedded.elements
      this.getWatchersList()
      this.watchershttp()

    });
  }

  //获取添加了watchers列表
  getWatchersList() {
    this.httpGet(`/api/v3/work_packages/${this.workPackage.id}/watchers`,).subscribe((res: any) => {
      this.watcherslist = res._embedded.elements
      // console.log("getFileRefNoInfo", res._embedded.elements);
      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();
    });
  }

  //删除watchers
  deletewatchers(item: any) {
    this.httpDelete(`/api/v3/work_packages/${this.workPackage.id}/watchers/${item.id}`).subscribe((res: any) => {
      this.getWatchersList()
      this.watchershttp()
    })
  }

 async getCFavailableList(customFieled: any, statuses: any) {
    let groupedFieldsdata: any = this.groupedFields
    for(let element of customFieled) {
      let quanxdata = groupedFieldsdata.find((res: any) => {
        return res.name == element.name
      })
      let res:any = await  this.httpGet(`/api/v3/work_packages/${this.workPackage.id}/custom_field_group_available_principal?custom_field_group_name=${element.name}`).toPromise() 
        const principals = res.principals
        let name = ''
        res.principals.forEach((element:any, i:any) => {
          if(i == 0){
            if(element.lastname || element.firstname) {

              name = element.lastname + element.firstname
            } else {
              name += element.name 
            }
          } else {
            if(element.lastname || element.firstname) {

              name = name + ','+ element.lastname + element.firstname
            } else {
              name += ',' + element.name 
            }
          }
        });
        for(let i = principals.length -1 ; i>=0 ; i--) {
          if(principals[i].hasOwnProperty('users')) {
            let users: Array<any> =  principals[i].users
            console.log(users)
            principals.splice(i + 1,0, ...users)
          }
        }
        let panduan = true
        let elementpadnuan = true
        principals.forEach((prinres: any) => {
          if (panduan && elementpadnuan) {
            if (quanxdata.principal_name == `${prinres.lastname} ${prinres.firstname}`) {
              this.customFieldGroupPrincipal.push({ principals: principals, select: prinres.id, custom_field: element })
              panduan = false
            } else {
              if (elementpadnuan) {
                //assignNext里面的选项
                // principals.unshift({ id: null, login: quanxdata.principal_name })
                this.customFieldGroupPrincipal.push({ principals: principals, select: null, custom_field: element })
                elementpadnuan = false
              }

            }
          }
        });
        if(panduan){
          
   
          principals.unshift({
            id:null,
            login: name
          })
        }

        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
    }

  }

  customFielditemlists(itemlists: any) {
    let datatext
    if (itemlists.id) {
      let position = itemlists.position ? '[' + itemlists.position + ']' : ''
      if(itemlists.lastname || itemlists.firstname) {

        datatext = `${position}${itemlists.firstname}${itemlists.lastname}`
      } else {
        datatext = `${position}${itemlists.name} `
      }
    } else {
      datatext = itemlists.login
    }

    return datatext

  }

  httpGet(url: string) {
    this.newHeaders['Content-Type'] = 'application/json'
    return this.http.get(url, { withCredentials: true, headers: this.newHeaders })
  }

  httpPost(url: string, data: any) {
    this.newHeaders['Content-Type'] = 'application/json'
    return this.http.post(url, data, { withCredentials: true, headers: this.newHeaders })
  }

  httpDelete(url: string) {
    let Headers = this.newHeaders
    Headers['Content-Type'] = 'application/hal+json'
    return this.http.delete(url, { withCredentials: true, headers: Headers })
  }

  clonebutton() {
    this.clone.emit()
  }

}
