<div class="documents-preview">
    <!-- <img
        #previewImg
        [src]="url"
    > -->
    <iframe
        class="iframe-preview"
        [src]="url">
    </iframe>
    <div class="close">
        <span>x</span>
    </div>
</div>