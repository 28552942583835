import { Component, OnInit, Input,Output, EventEmitter } from '@angular/core';
import { I18nService } from 'core-app/modules/common/i18n/i18n.service';
import { DocumentsService } from '../../services/check.lists.service';


@Component({
  selector: 'check_lists-template',
  templateUrl: './check_lists-template.component.html',
  styleUrls: ['./check_lists-template.component.sass']
})
export class Check_listsTemplateComponent implements OnInit {

  constructor(
    public i18n: I18nService,
    public service: DocumentsService,
  ) { }
 
  @Input() saveObj:any
  @Input() kind_id:any

  @Input() public changeMoudulelist:any
  
  @Output() dataObject = new EventEmitter();

  public check_sharer:any= false
  public check_sign:any = false
  public tableData:any = []
  public show:any = false
  public value:any = ''
  public showSign:any = false
  public name:any
  public objectData:any = {
    "name":'',
    "itemname":"",
    "check_list_type_id":'',
    "description":'',
    "sign_required":'sign_no_need'
  }

  ngOnInit() {
    this.objectData = {
      "name":'',
    "itemname":"",
      "check_list_type_id":'',
      "description":'',
      "sign_required":'sign_no_need'
    }
    if(this.saveObj.name){
      this.objectData = this.saveObj
      this.name = this.saveObj.itemname
      if(this.saveObj.sign_required=="require_sign"){
        this.showSign = true
      }else{
        this.showSign = false
      }
      this.dataObject.emit(this.saveObj)
    }


          //编辑模板
      if(this.changeMoudulelist){
        console.log(this.changeMoudulelist)
        this.objectData.name = this.changeMoudulelist.name
        this.objectData.check_list_type_id = this.changeMoudulelist.check_list_type_id
        this.objectData.description = this.changeMoudulelist.description
        this.objectData.sign_required = this.changeMoudulelist.sign_required
        if(this.objectData.sign_required=="require_sign"){
          this.showSign = true
        }else{
          this.showSign = false
        }
        this.service.getCheckListTypes(this.kind_id).subscribe((res: any) => {
          for(let i in res){
            if(res[i].id==this.changeMoudulelist.check_list_type_id){
              this.name = res[i].name
            }
            
          }
        })
 
        
        }

    this.dataObject.emit(this.objectData)
    this.getInfoType()
  }

  clearInput(){
    console.log('清空')
    this.objectData.check_list_type_id = ''
    this.dataObject.emit(this.objectData)
  }

  openValue(){
    this.show=!this.show;
  }
  getvalue(item:any){
    console.log(item)
    this.name=item.name;
    //add
    this.objectData.itemname = item.name
    this.objectData.check_list_type_id = item.id
    this.show=false;
    this.dataObject.emit(this.objectData)
  }

  selectSign(){
    this.showSign = !this.showSign
    if(this.showSign){
      this.objectData.sign_required = 'require_sign'
    }else{
      this.objectData.sign_required = 'sign_no_need'
    }
    
  }

  getInfoType(){
    this.service.getCheckListTypes(this.kind_id).subscribe((res: any) => {
     console.log(res)
     this.tableData = res
    })
  }

  blurTitle(){
    this.dataObject.emit(this.objectData)
  }

  blurType(){
    this.dataObject.emit(this.objectData)
  }

  checkSharer(){
    this.check_sharer = !this.check_sharer
  }


}
