<div class="wage" (click)="closeDialog()">
  <div class="dialog" (click)="$event.stopPropagation()">
    <h1>
      ProGress Add
    </h1>
    <p>
      <span>Title:</span>
      <input [(ngModel)]="title" type="text"/>
    </p>
    <p>
      <span>Date:</span>
      <input id="selectDate"  type="text"/>
    </p>
    <button class="Add" (click)="addProgress()">Add</button>
  </div>
</div>
