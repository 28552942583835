
<div class="selectdiv">
  <select (change)="getList(saveIndex,'state',$event.target.value)">
    <option value=" ">Status</option>
    <option value="pending_approve">Pending for approval</option>
    <option value="approved">Approved</option>
    <option value="rejected">Rejected</option>
    <option value="pending_terminate">Pending for Terminated</option>
    <option value="terminated">Terminated</option>
  </select>
  <select *ngIf="applicantFlag&&approverFlag">
    <option value="">Applicant</option>
  </select>
  <select *ngIf="applicantFlag&&approverFlag">
    <option value="">Approver</option>
  </select>
  <select (change)="getList(saveIndex,'crane_type',$event.target.value)">
    <option value=" ">Crane Type</option>
    <option value="MobileCrane">Mobile Crane</option>
    <option value="TowerCrane">Tower Crane</option>
  </select>
  <select (change)="getList(saveIndex,'work_location_id',$event.target.value)">
    <option value="">Work Location</option>
  </select>
</div>

<div class="work-packages-split-view--tabletimeline-content mainContent" >
    <div class="work-packages-tabletimeline--table-side work-package-table--container __hidden_overflow_container">
      <table class="keyboard-accessible-list generic-table work-package-table modulelistTable">
          <thead class="-sticky">
            <tr style="border-bottom: 1px solid #E7E7E7;">
              <th class="wp-table--table-header headcenter">No.</th>
              <th class="headcenter">Status</th>
              <th class="headcenter">Created Date</th>
              <th class="headcenter">Permit No</th>
              <th class="headcenter">Applicant</th>
              <th class="headcenter">Approver</th>
              <th class="headcenter" >Carne Location</th>
              <th class="headcenter">Shortcut</th>
            </tr>
          </thead>
          <tbody class="results-tbody work-package--results-tbody" *ngFor="let item of tableList;let i =index">
            <tr tabindex="0" data-work-package-id="303" data-class-identifier="wp-row-303" class="wp-table--row wp--row wp-row-303 wp-row-303-table issue __hierarchy-root-303">
              <td class="headcenter">{{item.id}}</td>
              <td class="headcenter">{{item.state=="init"?'draft':item.state}} </td>
              <td class="headcenter">{{item.created_at}}</td>
              <td class="headcenter">{{item.permit_no}}</td>
              <td class="headcenter"><span *ngIf="item&&item.applicant">{{item.applicant.firstname}}{{item.applicant.lastname}}</span></td>
              <td class="headcenter"><span *ngIf="item&&item.approver">{{item.approver.firstname}}{{item.approver.lastname}}</span> </td>
              <td class="headcenter" *ngIf="item.hasOwnProperty('work_location')">{{item.work_location.name}}</td>
              <td class="headcenter" *ngIf="!item.hasOwnProperty('work_location')"></td>
              <td class="headcenter" style="text-align: start;"><span class="viewspan" [ngClass]="{completedBtn: (item.state=='init')?true:false}"  (click)="toView(item)">View</span>
                <span class="viewspan" [ngClass]="{completedBtn: (item.state!='init')?true:false}" (click)="toEdit(item)" *ngIf="applicantFlag" [style.visibility]="applicantFlag? 'visible' : 'hidden'">Edit</span>
                <span><a [href]="downloadPdf(item)" class="viewspan">PDF</a></span>
                <span class="viewspan" (click)="copy(item.id)" *ngIf="applicantFlag">Copy</span>
                <span class="viewspan" (click)="toDelete(item.id)" *ngIf="item.state=='init'&&applicantFlag">Delete</span></td>
            </tr>
          </tbody>
        </table>
        <div class="paging">
          <ul class="pagingUl">
            <li (click)="changePage(saveIndex-1)"><</li>
            <li *ngFor="let item of saveNum;let i=index" [ngClass]="{bluebtn:i==saveIndex-1?true:false}" (click)="changePage(i+1)">{{i+1}}</li>
            <li (click)="changePage(saveIndex+1)">></li>
            <span>共{{count}}頁</span>
          </ul>
        </div>
  </div>
  </div>

