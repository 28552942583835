/*
 * @Author: your name
 * @Date: 2020-05-28 15:06:06
 * @LastEditTime: 2020-06-18 17:49:56
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /junhe/frontend/src/app/modules/check_lists/check_lists.module.ts
 */ 
import { NgModule } from '@angular/core';
import { Check_listsComponent } from './check_lists.component';
import { Ng2StateDeclaration, UIRouterModule } from '@uirouter/angular';
import { Check_listsListComponent } from './components/check_lists-list/check_lists-list.component'
import { Check_listsTemplateComponent } from './components/check_lists-template/check_lists-template.component';
import { Check_listsNavComponent } from './components/check_lists-nav/check_lists-nav.component';
import { Check_listsQuestComponent } from './components/check_lists-quest/check_lists-quest.component'
import { Check_listsIssueComponent } from './components/check_lists-issue/check_lists-issue.component'
import { Check_listsNewtemplateComponent } from './components/check_lists-newtemplate/check_lists-newtemplate.component'
import { Check_listsNewtemplateCtitemComponent } from './components/check_lists-newtemplate-ctitem/check_lists-newtemplate-ctitem.component'
import { CheckListsModulelistComponent } from './components/check-lists-modulelist/check-lists-modulelist.component';
import {OpenprojectWorkPackagesModule} from 'core-app/modules/work_packages/openproject-work-packages.module';
import {OpenprojectCommonModule} from "core-app/modules/common/openproject-common.module";
import { SignaturePadModule } from 'angular2-signaturepad';
import {Check_listsAutographComponent} from './components/check_lists-autograph/check_lists-autograph.component'
import {BrowserModule} from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { SortablejsModule } from 'ngx-sortablejs';

import { from } from 'rxjs';
import { CheckListsPdfComponent } from './components/check_lists-pdf/check_lists-pdf/check_lists-pdf.component';
import { Check_listTemplateCopyComponent } from './components/check_list-templateCopy/check_list-templateCopy.component';
import { Check_listCreateComponent } from './components/check_list-create/check_list-create.component';
import {  checkListComponentSign } from './components/check-list-sign/checkListSign.component';
import { Check_listsQuest2Component } from './components/check_list-quest2/check_list-quest2.component';
import { Check_listsQuest3Component } from './components/check-list-quest3/check-list-quest3.component';
import { DashboardsComponent } from './components/Dashboards/Dashboards.component';
import { checkDateTimeComponent } from './components/field-dateTime/check-dateTime.component';
import {Check_listsQuest4Component} from './components/check_lists-quest4/check_lists-quest4.component'
import {PermitDigComponent} from './components/Permit-Dig/Permit-Dig.component'



export const DOCUMENTS_ROUTES: Ng2StateDeclaration[] = [
  {
    name: 'check_lists',
    url: '/check_lists',
    parent: 'root',
    component: Check_listsComponent
  },
  {
    name: 'safety_checklists',
    url: '/safety_checklists',
    parent: 'root',
    component: Check_listsComponent
  }
];

@NgModule({
  imports: [
    UIRouterModule.forChild({ 
      states: DOCUMENTS_ROUTES
    }),
    SortablejsModule,
    OpenprojectWorkPackagesModule,
    OpenprojectCommonModule,
    SignaturePadModule,
    BrowserModule,
    FormsModule
  ],
  declarations: [
    Check_listTemplateCopyComponent,
    Check_listsComponent,
    Check_listsQuest2Component,
    Check_listsListComponent,
    Check_listsQuestComponent,
    checkDateTimeComponent,
    Check_listsAutographComponent,
    Check_listsIssueComponent,
    Check_listsNewtemplateComponent,
    Check_listsNewtemplateCtitemComponent,
    Check_listsTemplateComponent,
    Check_listsNavComponent,
    CheckListsModulelistComponent,
    CheckListsPdfComponent,
    Check_listCreateComponent,
    Check_listsQuest3Component,
    checkListComponentSign,
    Check_listsQuest4Component,
    DashboardsComponent,
    PermitDigComponent
  ]
})
export class Check_listsModule { }
