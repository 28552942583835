<div *ngIf="isFileRefNo" class="fileRefNo" >
  <div class="close" (click)="closeEmit.emit({type:'close'})">X</div>
  <div >
    <hr class="B360Hr">
    <h3 *ngIf="kind_id != 6" style="padding: 5px 10px;" class="attributes-group--header-text">{{i18n.t("js.file-ref-no.title")}}</h3>
    <div *ngIf="kind_id != 6"  class="Field--vertical">
      <label class=" Field__label">{{i18n.t("js.file-ref-no.prefix")}}:</label>
      <div class="ChecklistPageRightPanel__editable-assignee">
        <div class="Editable__text">{{ fileRefNo.prefix }}</div>
      </div>
    </div>
    <div *ngIf="kind_id != 6"  class="Field--vertical">
      <label class=" Field__label">{{i18n.t("js.file-ref-no.id")}}:</label>
      <div class="ChecklistPageRightPanel__editable-assignee">
        <div class="Editable__text">-</div>
      </div>
    </div>
    <div *ngIf="kind_id != 6"  class="Field--vertical">
      <label class=" Field__label">{{i18n.t("js.file-ref-no.type")}}:</label>
      <div class="ChecklistPageRightPanel__editable-assignee">
        <div class="Editable__text">
          <select class="attribute-fileRefNo-select" [(ngModel)]="fileRefNoType" >
            <option  *ngFor="let item of fileRefNo.values;" [value]="getStringValue(item.value)">
            {{item.value.value}}
            </option>
        </select>
        </div>
      </div>
    </div>
    <div *ngIf="kind_id != 6"  class="Field--vertical">
      <label class=" Field__label">{{i18n.t("js.file-ref-no.suffix")}}:</label>
      <div class="ChecklistPageRightPanel__editable-assignee">
        <div class="Editable__text">-</div>
      </div>
    </div>
    <div *ngIf="kind_id == 6" class="Field--vertical">
      <h2>Monthly Safety Pertormance
        </h2>
      <input id="timeDate" [value]="unique_date" (click)="renderDate()"/>
    </div>
    <div  class="Field--vertical">
      <button accesskey="3" class="button -alt-highlight" (click)="saveFileRefNo()" >
        <op-icon class="op-icon--wrapper" icon-classes="button--icon icon-checkmark"><i aria-hidden="true"
            class="button--icon icon-checkmark" title=""></i>
        </op-icon><span class="button--text" >{{i18n.t("js.button_create")}}</span>
      </button>
    </div>
  </div>
</div>
