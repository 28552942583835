import { Component, OnInit } from '@angular/core';
import {SEDNService} from './service/SEDN.service'
import { HttpClient } from "@angular/common/http";
import {ExpressionService} from "../../../../common/expression.service";

declare let layui:any
@Component({
  selector: 'SEDN',
  templateUrl: './SEDN.component.html',
  styleUrls: ['./SEDN.component.sass']
})
export class SEDNComponent implements OnInit {
  public SEDNList:Array<any> = []
  public query = new Map()
  public sednType:any = ''
  public sednId:String = ''
  public sednItem:any
  public createRight: boolean
  public checked:String|any =  'All'
  public downLoadSEDNList:Set<any> = new Set
  public checkItem:any
  public downLoading: boolean
  public count:any = 1
  public projectId:any =  document.getElementsByName('current_project')[0].dataset.projectId
  public sortClass:any ={
    name: '',
    type: 'asc'
  }
  constructor(public service: SEDNService,readonly http: HttpClient,) { }

  ngOnInit() {
      let that = this
      location.search.slice(1).split('&').forEach( (item:String) => {
        this.query.set(  item.split('=')[0], item.split('=')[1])
      })
      if(this.query.get('type') && this.query.get('sednId')) {
        this.sednType = this.query.get('type') 
      } else {

        this.sednType = 'list'
        this.service.getSEDNList('All', 1,that.sortClass.name,that.sortClass.type).subscribe( (res:any) => {
          this.SEDNList = res.list
          this.createRight = res['create_right?']
          layui.use('laypage', function(){
            var laypage = layui.laypage;
            
            //执行一个laypage实例
            laypage.render({
              elem: 'page' //注意，这里的 test1 是 ID，不用加 # 号
              ,count: Number(res.total_count),
              jump: (obj:any) => {
                console.log(obj)
                that.count = obj.curr
                that.service.getSEDNList('All', obj.curr,that.sortClass.name,that.sortClass.type).subscribe( (res:any) => {
                  that.SEDNList = res.list
                  that.createRight = res['create_right?']
                })
              }//数据总数，从服务端得到
            });
          });
        })
      }

  }
  channgeCheckBox(e:any, id:any){
    console.log(e.target.checked)
    if(e.target.checked){

      this.downLoadSEDNList.add(Number(e.target.value))
    } else {
      this.downLoadSEDNList.delete(Number(e.target.value))
    }
  }
  sort(type:any) {
      if(this.sortClass.name !== type) {
        this.sortClass.type = 'asc'
      } else {
        this.sortClass.type == 'desc'?this.sortClass.type = 'asc':this.sortClass.type = 'desc'
      }
    this.sortClass.name = type
    this.service.getSEDNList(this.checked, this.count,this.sortClass.name,this.sortClass.type).subscribe( (res:any) => {
      this.SEDNList = res.list
      this.createRight = res['create_right?']
    })
  }
  filterCheck(type:any){
    this.checked = type
    let that = this
    this.service.getSEDNList(type, 1,that.sortClass.name,that.sortClass.type).subscribe( (res:any) => {
      this.SEDNList = res.list
      this.createRight = res['create_right?']
      layui.use('laypage', function(){
        var laypage = layui.laypage;
        
        //执行一个laypage实例
        laypage.render({
          elem: 'page' //注意，这里的 test1 是 ID，不用加 # 号
          ,count: Number(res.total_count),
          jump: (obj:any) => {
            that.count = obj.curr
            that.service.getSEDNList(type, obj.curr,that.sortClass.name,that.sortClass.type).subscribe( (res:any) => {
              that.SEDNList = res.list
              that.createRight = res['create_right?']
            })
          }//数据总数，从服务端得到
        });
      });
    })
  }
  createSEDN() {
    if(this.createRight)
    this.service.addSEDN().subscribe( (res:any) => {
      this.sednItem = res
      this.sednType = 'create'
      history.replaceState(null, 'edit SEDN', `${location.pathname}?type=${this.sednType}&sednId=${res.id}`)
    })
  }

  stateChange() {
    console.log('list')
    this.sednType = 'list'
    this.checked = 'All'
    this.filterCheck(this.checked)
     history.replaceState(null, 'SEDN', `${location.pathname}`)
  }

  openSEDN(item:any) {
    this.sednItem = item
    this.sednType = 'edit'
    history.replaceState(null, 'edit SEDN', `${location.pathname}?type=${this.sednType}&sednId=${item.id}`)
  }
  downLoadPdf(){
    let downLoadSEDNList = Array.from(this.downLoadSEDNList)
    let list = this.SEDNList.filter((item:any)=>{
     console.log(downLoadSEDNList,item.id, downLoadSEDNList.includes(item.id))
      return downLoadSEDNList.includes(item.id)
    })
    list.forEach( (item:any) => {
      this.getPdfHref(item.id, item.file_id)
    })
  }
  downLoadExcel() {
   
    //打开下载弹框
  let self = this
  layui.use(['jquery','layer','laydate', 'dropdown'], function() {
    let startTime = '',endTime='',state='';
    let date1 = new Date().toLocaleDateString().replace(/\//g,'-');
    let data2 = ExpressionService.lastMonth()
    var layer = layui.layer ,$ = layui.$,
    laydate =  layui.laydate,dropdown = layui.dropdown;
    layer.open({
      content:'<div class="layui-form"><div class="layui-form-item"><div class="layui-inline"><label class="layui-form-label" style="width: 100px;">Period</label><div class="layui-inline" id="test6"><div class="layui-input-inline"><input type="text" autocomplete="off" id="test-startDate-1" class="layui-input" placeholder="Form"></div><div class="layui-form-mid">-</div><div class="layui-input-inline"><input type="text" autocomplete="off" id="test-endDate-1" class="layui-input" placeholder="To"></div> </div></div></div><div class="layui-form-item"><div class="layui-inline"><label class="layui-form-label" style="width: 100px;"></label><button class="layui-btn" id="demo1">Status<i class="layui-icon layui-icon-down layui-font-12"></i></button></div></div></div>',
      // area: ['400px', '400px'],
      title:'Export',
      btn:['Confirm'],
      yes: function(index:any, layero:any){
        //do something
        window.open(`/projects/${self.projectId}/sedn/export_all_to_excel?from=${startTime}&to=${endTime}&state=${state}`)
        layer.close(index); //如果设定了yes回调，需进行手工关闭
      }
    });
    laydate.render({
      elem: '#test6',
      range: '-'
      ,format: 'yyyy-MM-dd',
       value: `${data2} - ${date1}`
      ,isInitValue: true
      ,lang: 'en'
      ,done: function(value:any, date:any, endDate:any){
        //得到日期生成的值，如：2017-08-18
        startTime = date.year+'-'+date.month+'-'+date.date
        endTime = endDate.year+'-'+endDate.month+'-'+endDate.date
        $('#test-startDate-1').val(startTime)
        $('#test-endDate-1').val(endTime)
      }
    });
    self.http.get(`/api/v3/projects/${self.projectId}/sedns/sedn_status`).toPromise().then((res:any) => {
      let arr:any = []
        Object.keys(res).forEach((key) => {
          arr.push({title:key,id:res[key]})
        })
        dropdown.render({
          elem: '#demo1' //可绑定在任意元素中，此处以上述按钮为例
          ,data: arr
          ,id: 'demo1'
          //菜单被点击的事件
          ,click: function(obj:any){
              state =obj.id
              this.elem.html(`${obj.title}<i class="layui-icon layui-icon-down layui-font-12"></i>`)
          }
        });
        })
        })

  }
  getPdfHref(id:any, filename:any){
    this.downLoading = true
 
    this.service.downLoadPDF(id).subscribe ( (res:any) => {
      this.downLoading = false
      var file = new File([res], filename + '.pdf', { type: 'application/force-download' });
      let url = URL.createObjectURL(file)
      let a = document.createElement('a')
      a.download = filename + '.pdf'
      a.href = url
      document.body.appendChild(a)
      console.log(a)
      a.click()
      setTimeout( () => {

        a.remove()
      }, 2000)
 

    })


  }

}
