<div class="assignNext-mask">
    <div class="content">
        <div class="assignNext-content">
            <div class="assignNext-content-item assingNext-Title">
                <span class="assignNext-title">{{workPackage.subject}}</span>
            </div>
            <div class="assignNext-content-item">
                <div class="item-title">Current Status</div>
                <div class="item-select">
                    <select class="Editable_loding_input" disabled='disabled'>
            <option [value]="'null'">{{workPackage.status.name}}</option>

          </select>
                </div>
            </div>
            <div class="assignNext-content-item assingNext-To" style="padding: 0 20px;">
                <div class="item-title"></div>
                <div class="item-select" style="text-align: center;border: none;">
                    <span>To</span>
                </div>
            </div>
            <div class="assignNext-content-item">
                <div class="item-title">Next Status</div>
                <div class="item-select">
                    <select class="Editable_loding_input" disabled='disabled'>
            <option [value]="'null'">{{statusdata? statusdata.name : ''}}</option>

          </select>
                </div>
            </div>
            <div class="assignNext-content-item" style="padding-bottom:0 ;">
                <div class="item-title">Next Handler</div>
                <div class="item-select" style="border: none;">

                </div>
            </div>
            <div class="assignNext-content-item assignNext-customField-item" style="padding: 10px 20px;" *ngFor="let item of customFieldGroupPrincipal">
                <div class="item-title item-title-customField" style="font-size: 14px;">{{item.custom_field.name}}</div>
                <div class="item-select">



                    <select class="Editable_loding_input" [(ngModel)]="item.select" (change)='setgroup_principal($event,item)'>
            <option [value]="itemlists.id" *ngFor="let itemlists of item.principals">{{customFielditemlists(itemlists)}}<option>
          </select>
                </div>
            </div>
            <div class="assignNext-content-item">
                <div class="item-title">cc</div>
                <div>
                    <div style="display: flex;align-items: center;">
                        <div class="item-select assignNext-watchers-select" style="margin-right: 50px;">
                            <select class="Editable_loding_input" [(ngModel)]="selectWatchers" (change)="changeswatchers($event)">
                <option [value]="'null'">Specified User Group
                <option>
                <option [value]="item._links.self.href" *ngFor="let item of watchers">{{item.name}}</option>
              </select>
                        </div>
                        <div class="item-addbutton" (click)='addwatchers()'>
                            <op-icon icon-classes="button--icon icon-add"></op-icon>
                        </div>
                    </div>
                    <div class="assignNext-watchers" style="height: 120px;overflow: auto;">
                        <div class="item-watcherslists" *ngFor="let item of watcherslist">
                            <span>{{item.name}}</span>
                            <span class="icon-close" (click)='deletewatchers(item)'></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="assignNext-button">
            <button [ngStyle]='{"background-color": !okbutton ? "#e3e3e3":"rgb(172 253 111)"}' (click)='updateStatus()' [disabled]='!okbutton'>CONFIRM</button>
            <button style="background-color: rgb(253 216 111);" (click)='clonebutton()'>CANCEL</button>
        </div>
        <div *ngIf="!okbutton" class="tishiwe">"Signature is required before proceeding to the next Handler"</div>
    </div>
</div>