import {Component, OnInit, ChangeDetectorRef, Output, Input} from '@angular/core';
import { labour_returnService } from './services/labour_return.service';
import { I18nService } from 'core-app/modules/common/i18n/i18n.service';
import { formatDate } from '@angular/common';
import { ThemeService } from 'ng2-charts';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

declare let laydate: any;


@Component({

  templateUrl: './labour_return.component.html',
  styleUrls: ['./labour_return.component.sass']
})
export class labourReturnComponent implements OnInit {

  public showEdit:boolean=false
  public title:any
  public showMantle:boolean = false
  public hasTitle:boolean = true
  public showApproval:boolean = false
  public selectConfirm:boolean = true
  public decidetoSign:boolean = false
  public saveEditContent:any
  public saveApprovalContent:any
  public fakeArray:any = []
  public saveNewForm:any
  public sign_doc_id:any
  public showLoading:boolean = false
  public project_id:any
  public userid:any
  public updateList:boolean = false
  public rejectRemark:any
  public setDisabled:boolean = false
  public version:any
  constructor(
    public service: labour_returnService,
    public i18n: I18nService,

    ) { 
    }

    ngOnInit() {
      this.project_id = jQuery('meta[name=current_project]').data('projectId')
      this.userid = jQuery('meta[name=current_user]').data('id')
      this.version = location.search.split('?')[1].split('&')[0].split('=')[1]
    }

    Edit(e:any){
      this.showEdit = true
      this.title = e.month
      this.saveEditContent = e
    }

    Approval(e:any){
      this.saveApprovalContent = e
      this.showApproval = true
      if(e.state=='rejected'){
        this.cancelApprove()
        this.rejectRemark = e.reject_remark
      }else if(e.state=='pending_approve'){
        if(e.reject_remark){
          this.rejectRemark = e.reject_remark
          this.cancelApprove()
        }
        if(this.userid!=e.contractor_signer.id){
          this.setDisabled = true
        }
      }else if(e.state=='pending_review'){
        if(e.reject_remark){
          this.rejectRemark = e.reject_remark
          this.cancelApprove()
        }
        if(this.userid!=e.supervisor_signer.id){
          this.setDisabled = true
        }
      }else{
        this.confirmApprove()
      }


    }


    period_endChange() {
      laydate.render({
        elem: '#period_end'
        ,type: 'month'
        ,lang: 'en'
        ,show:true
        ,value:this.title
        ,done:(value:any)=>{
          this.title = value
        }
      });
    }
    toMap() {
     let toMap = document.getElementsByClassName('menu-item-labour_return_mapping')[0] as any
     toMap.click()
    }
    confirmAdd(){
      if(!this.title){
        this.hasTitle = false
        return
      }else{
        let data = {
          labour_return:{
            month:this.title,
            metum_attributes:{
              data_for_display:[],
              data_for_export:''
            }
          }
        }
        this.service.AddOneList({
          project_id:this.project_id,
          labour_return:data.labour_return,
        version:this.version
      }).subscribe((res:any)=>{
            this.saveNewForm = res
            this.showEdit=true
          }, (err:any) => {
              alert(err.error.errors)
              this.hasTitle = false
          },
         () => {
           
         }
          )
      }
    }
    //创建列表
    addLabour(){
      this.showMantle = true
    }
    toCancel(){
      this.showEdit = false
      console.log(this.showEdit )
      this.showMantle = false
      this.saveEditContent = undefined
      this.saveNewForm = undefined
      this.title = undefined
      this.setDisabled = false
    }
    toSubmit(e:any){
      this.showEdit = false
      this.showMantle = false
      if(e=='update'){
        alert(this.i18n.t('js.labour-return.dialog.update'))
      }else{
        alert('js.labour-return.dialog.create')
      }
    }
    // 更换颜色
    confirmApprove(){
      jQuery('#cancel').removeClass('cancelbtn')
      jQuery('#confirm').addClass('confirmbtn')
      this.selectConfirm =true
    }
    cancelApprove(){
      console.log('进来了吗')
      jQuery('#confirm').removeClass('confirmbtn')
      jQuery('#cancel').addClass('cancelbtn')
      this.selectConfirm =false
      this.sign_doc_id = undefined
    }
    cancelEdit(){
      this.showEdit = false
      this.showApproval = false
      this.sign_doc_id = undefined
      this.rejectRemark = undefined
      this.setDisabled = false
      this.selectConfirm =true

    }
    confirmSign(){
      if(this.setDisabled){
        return
      }
      this.userid = jQuery('meta[name=current_user]').data('id')
      if((this.saveApprovalContent.state=='pending_approve'&&this.saveApprovalContent.contractor_signer.id!=this.userid)
      ||(this.saveApprovalContent.state=='pending_review'&&this.saveApprovalContent.supervisor_signer.id!=this.userid)
      ){
        alert('您沒有簽名的權限')
        return
      }
      this.decidetoSign = true
    }
    //签名图片id
    closeView(img:any){
      if(!img) {
        this.decidetoSign = false 
        return
      }
      this.showLoading = true
      let blob = this.dataURLtoBlob(img)
      var files = new File([blob], 'drawComplete.png', { type: blob.type })
      let file = new FormData();
      file.append('file', files);
      file.append('prefix', 'labour_return');
      this.service.uploadImg({
        file,
        project_id:this.project_id
      }).subscribe((res:any)=>{
          this.sign_doc_id=res.doc_id
          this.decidetoSign = false
          this.showLoading = false
                  //承包商签名
        if(this.saveApprovalContent.state=='pending_approve'){
          this.service.constractorAdopt({
            project_id:this.project_id,
            contractor_sign_doc_id:{sign_doc_id:this.sign_doc_id},
            labour_return_id:this.saveApprovalContent.id
          }).subscribe((res:any)=>{
            this.decidetoSign = false
            this.sign_doc_id = undefined
            this.showEdit = false
            this.showApproval = false
            this.decidetoSign = false 
          })
          //主管签名
        }else if(this.saveApprovalContent.state =='pending_review'){
          this.service.supervisorAdopt({
            project_id:this.project_id,
            supervisor_sign_doc_id:{sign_doc_id:this.sign_doc_id},
            labour_return_id:this.saveApprovalContent.id
          }).subscribe((res:any)=>{
            this.decidetoSign = false
            this.sign_doc_id = undefined
            this.showEdit = false
            this.showApproval = false
            this.decidetoSign = false 
          })
        }
      })    
    }
  
    dataURLtoBlob(dataurl:any) {
      var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    }

    //日期格式显示的改变
    changeTitle(e:any){
      let time = e.split('-')
      return `${time[1]+"/"+ time[0]}`
    }


    //拒绝签名
    rejectSignature(){
      let data = {
        project_id:this.project_id,
        labour_return_id:this.saveApprovalContent.id,
        rejectData:{
          reject_remark:this.rejectRemark,
          rejected_by:''
        }
      }
      if(!this.rejectRemark){
        alert('拒絕簽名的備註信息不能爲空')
      }
      if(this.saveApprovalContent.state=='pending_approve'){
        data.rejectData.rejected_by ='contractor'
      }else if(this.saveApprovalContent.state =='pending_review'){
        data.rejectData.rejected_by ='supervisor'
      }
      this.service.signatureRejected(data).subscribe((res:any)=>{
        this.rejectRemark = undefined
        this.showEdit = false
        this.showApproval = false
      })

    }

}
