import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SEDNService {

  private projectId:any = document.getElementsByName('current_project')[0].dataset.projectId

  //请求头
  requestHeader: any = {
    'X-Authentication-Scheme': 'Session',
    'X-Requested-With': 'XMLHttpRequest',
    // 'Content-Type': 'application/json'
  };
  Header: any = {
    withCredentials: true,
    headers: this.requestHeader
  }
  deleteHeader:any = {
    withCredentials: true,
    headers: {
      'X-Authentication-Scheme': 'Session',
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/json'
    }
  }
  //API前缀
  apiPrefix: any = '/api/v3';
  public eventBus: EventEmitter<any> = new EventEmitter<any>()
  public photoEvent: EventEmitter<any> = new EventEmitter<any>()
  constructor(private http: HttpClient) {

  }

  private HttpPost(url: string, data: any) {
  
    return this.http.post(url, data, this.Header);
  }
  private HttpGet(url: string) {
    return this.http.get(url);
  }
  private HttpDelete(url: string) {


    return this.http.delete(url,this.deleteHeader);
  }
  private HttpPatch(url: string, data: any) {
    return this.http.patch(url, data, this.Header);
  }
  private HttpPUT(url: string, data: any) {
    return this.http.put(url, data, this.Header);
  }
  private HttpDownLoad(url:string) {
    return this.http.get(url, {
      responseType: 'blob'
    })
  }
  public deleteSEDNPhotoGroup(sednId:any, imageGroupId:any) {
    return this.HttpDelete(`/api/v3/projects/${this.projectId}/sedns/${sednId}/image_groups/${imageGroupId}`)
  }
  public handleExecutor(sednId:any, executor:boolean) {
    return this.HttpPUT(`/api/v3/projects/${this.projectId}/sedns/${sednId}/set_executor`,  {
      'executor?': executor
    }
      
    )
  }
  public getdeFicienciesTypesList() {
    return this.HttpGet(`/api/v3/projects/${this.projectId}/sedns/deficiencies_types_list?per_page=999&&page=1`)
  }

  public getGroupLIst(sednId:any) {
    return this.HttpGet(`/api/v3/projects/${this.projectId}/sedns/${sednId}/image_groups`)
  }
  public getSEDNList(filter:any, page?:any,by_name?:any,by_type?:any) {
    let sort = ''
    if(by_name) {
      sort +=`&&by_name=${by_name}`
    }
    if(by_type) {
      sort +=`&&by_type=${by_type}`
    }
   return this.HttpGet(`/api/v3/projects/${this.projectId}/sedns?list_search=${filter}&&page=${page}${sort}`)
  }
  public getPicList(sednId:any, groupId:any) {
    return this.HttpGet(`/api/v3/projects/${this.projectId}/sedns/${sednId}/image_groups/${groupId}`)
  }
  public postSign(file:any){
    return this.HttpPost(`/api/v3/projects/${this.projectId}/sign`, file)
  }
  public getSEDNItem(sednId:any){
    return this.HttpGet(`/api/v3/projects/${this.projectId}/sedns/${sednId}`)
  }
  public addImgGroup(sednId:any) {
    return this.HttpPost(`/api/v3/projects/${this.projectId}/sedns/${sednId}/image_groups`, {
      image_groups: {}
    })
  }
  public saveSEDN(sedn:any, sednId:any) {
    return this.HttpPUT(`/api/v3/projects/${this.projectId}/sedns/${sednId}`,{
      sedn
    })
  }
  public addSEDN(){
    return this.HttpPost(`/api/v3/projects/${this.projectId}/sedns`,{
      sedn: {}
    })
  }
  public contractorUsers(){
   return this.HttpGet(`/api/v3/projects/${this.projectId}/sedns/contractor_users`)
  }
  public deleteImg(sednId:any, imgGroupId:any, photoId:any){
    return  this.HttpDelete(`/api/v3/projects/${this.projectId}/sedns/${sednId}/image_groups/${imgGroupId}/upload/${photoId}`)
  }
  public editPhotoGroup(image_groups:any,sednId:any, imageGroupId:any) {
    return this.HttpPUT(`/api/v3/projects/${this.projectId}/sedns/${sednId}/image_groups/${imageGroupId}`, {image_groups})
  }
  public downLoadPDF(sednId:any){
   return this.HttpDownLoad(`/projects/${this.projectId}/sedn/export_pdf/${sednId}`)
  }
}