// -- copyright
// OpenProject is a project management system.
// Copyright (C) 2012-2015 the OpenProject Foundation (OPF)
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See doc/COPYRIGHT.rdoc for more details.
// ++

import {MultiInputState, State} from 'reactivestates';
import {States} from '../states.service';
import {StateCacheService} from '../states/state-cache.service';
import {SchemaCacheService} from './../schemas/schema-cache.service';
import {WorkPackageCollectionResource} from 'core-app/modules/hal/resources/wp-collection-resource';
import {SchemaResource} from 'core-app/modules/hal/resources/schema-resource';
import {WorkPackageResource} from 'core-app/modules/hal/resources/work-package-resource';
import {Injectable} from '@angular/core';
import {debugLog} from "core-app/helpers/debug_output";
import {WorkPackageDmService} from "core-app/modules/hal/dm-services/work-package-dm.service";

function getWorkPackageId(id:string|null):string {
  return (id || 'new').toString();
}

@Injectable()
export class WorkPackageCacheService extends StateCacheService<WorkPackageResource> {

  /*@ngInject*/
  constructor(private states:States,
              private schemaCacheService:SchemaCacheService,
              private workPackageDmService:WorkPackageDmService) {
    super();
  }

  public updateValue(id:string, val:WorkPackageResource) {
    this.updateWorkPackageList([val], false);
  }

  updateWorkPackage(wp:WorkPackageResource, immediate:boolean = false):Promise<void> {
    const wpId = getWorkPackageId(wp.id!);
    const newWorkPackage=this.handleTable(wp); 

    if (immediate || wp.isNew) {
      this.multiState.get(wpId).putValue(newWorkPackage);
      return Promise.resolve();
    } else {
      return this.schemaCacheService.ensureLoaded(newWorkPackage).then(() => {
        this.multiState.get(wpId).putValue(newWorkPackage);
      });
    }
  }

  updateWorkPackageList(list:WorkPackageResource[], skipOnIdentical = true) {
    for (var i of list) {
      const wp = i;
      const workPackageId = getWorkPackageId(wp.id!);
      const state = this.multiState.get(workPackageId);
      const newWorkPackage=this.handleTable(wp); 
      // If the work package is new, ignore the schema
      if (newWorkPackage.isNew) {
        state.putValue(newWorkPackage);
        continue;
      }

      // Ensure the schema is loaded
      // so that no consumer needs to call schema#$load manually
      this.schemaCacheService.ensureLoaded(newWorkPackage).then(() => {
        // Check if the work package has changed
        if (skipOnIdentical && state.hasValue() && _.isEqual(state.value!.$source, newWorkPackage.$source)) {
          debugLog('Skipping identical work package from updating');
          return;
        }

        this.multiState.get(workPackageId).putValue(newWorkPackage);
      });
    }
  }

  /**
   * Wrapper around `require(id)`.
   *
   * @deprecated
   */
  loadWorkPackage(workPackageId:string, forceUpdate = false):State<WorkPackageResource> {
    const state = this.state(workPackageId);

    // Several services involved in the creation of work packages
    // use this method to resolve the latest created work package,
    // so let them just subscribe.
    if (workPackageId === 'new') {
      return state;
    }

    this.require(workPackageId, forceUpdate);
    return state;
  }

  protected loadAll(ids:string[]) {
    return new Promise<undefined>((resolve, reject) => {
      this.workPackageDmService
        .loadWorkPackagesCollectionsFor(_.uniq(ids))
        .then((pagedResults:WorkPackageCollectionResource[]) => {
          _.each(pagedResults, (results) => {
            if (results.schemas) {
              _.each(results.schemas.elements, (schema:SchemaResource) => {
                this.states.schemas.get(schema.href as string).putValue(schema);
              });
            }

            if (results.elements) {
              this.updateWorkPackageList(results.elements);
            }

            resolve(undefined);
          });
        }, reject);
    });
  }

  protected load(id:string) {
    return new Promise<WorkPackageResource>((resolve, reject) => {

      const errorAndReject = (error:any) => {
        reject(error);
      };

      this.workPackageDmService.loadWorkPackageById(id, true)
        .then((workPackage:WorkPackageResource) => {
            const newWorkPackage=this.handleTable(workPackage); 
          this.schemaCacheService.ensureLoaded(newWorkPackage).then(() => {
            this.multiState.get(id).putValue(newWorkPackage);
            resolve(newWorkPackage);
          }, errorAndReject);
        }, errorAndReject);
    });
  }

  public handleTable(workPackage:WorkPackageResource) {
    let newWorkPackage=workPackage;
    let table= newWorkPackage.table;
    if(table){
        let tablePayload={...table.payload};
        if(tablePayload){
            delete tablePayload.type;
            console.log(tablePayload);
            Object.assign(newWorkPackage,tablePayload);
        }
        console.log(newWorkPackage.type.name,'newWorkPackage.type.name');
        if(newWorkPackage.type.name==='Site diary'){

        if (table.schema) {
            let templateSchema = table.schema;
            if (templateSchema) {
                Object.assign(workPackage.schema, templateSchema);
            }      
            let formSelect = {
              _type: "WorkPackageFormAttributeGroup",
              name: "Select Form",
              attributes: ["template"]
            };
            let formTemplate = [
              {
                _type: "WorkPackageFormAttributeGroup",
                name: "Form",
                attributes: ["form_name", "client_department", "contract_no", "contract_title"]
              },
              {
                _type: "WorkPackageFormAttributeGroup",
                name: "Weather",
                attributes: ["am", "pm", "mm"]
              },
              {
                _type: "WorkPackageFormAttributeGroup",
                name: "Date",
                noTitle: true,
                attributes: ["form_date", "day", "day_counted"]
              },
              {
                _type: "WorkPackageFormAttributeGroup",
                name: "warning Signal",
                noTitle: true,
                attributes: ["thunderstorm", "rainstorm", "tripical_cyclone"]
              },
              {
                _type: "WorkPackageFormAttributeGroup",
                name: "instruclions",
                noTitle: true,
                attributes: ["instruclions"]
              },
              {
                _type: "WorkPackageFormAttributeGroup",
                name: "comments",
                noTitle: true,
                attributes: ["comments"]
              },
              {
                _type: "WorkPackageFormAttributeGroup",
                name: "utilities",
                noTitle: true,
                attributes: ["utilities"]
              },
              {
                _type: "WorkPackageFormAttributeGroup",
                name: "progress",
                noTitle: true,
                attributes: ["progress"]
              },
              {
                _type: "WorkPackageFormAttributeGroup",
                name: "visitor",
                noTitle: true,
                attributes: ["visitor"]
              },
              {
                _type: "WorkPackageFormAttributeGroup",
                name: "accidents",
                noTitle: true,
                attributes: ["accidents"]
              },
              {
                _type: "WorkPackageFormAttributeGroup",
                name: "remarks",
                noTitle: true,
                attributes: ["remarks"]
              }
            ];
      
            if (!workPackage.schema._attributeGroups.some((item: any) => item.key == "Select Form")) {
              workPackage.schema._attributeGroups = [formSelect, ...formTemplate, ...workPackage.schema._attributeGroups];
            }
          }
        }
    }
    return newWorkPackage
  }

  protected get multiState():MultiInputState<WorkPackageResource> {
    return this.states.workPackages;
  }

}
