<div class="documents-catalog-tree"
    (click)="selectFolder(item, $event)"
    [class.background]="item.id === service.selectFolderId"
    *ngFor="let item of value">
    <div class="operation-item" [ngStyle]="{ 'padding-left': level * 30 + 'px' }">
        <div class="documents-{{ !item.openIcon ? 'open' : 'close' }}-pc icon"
            [class.visibility]="!item.has_children"
            (click)="getChildrenFolder(item)">
        </div>
        <div class="operation-row">
            <div class="documents-folder item-icon"></div>
            <span class="operation-title">{{ item.name }}</span>
        </div>
        <div class="border"></div>
    </div>
    <documents-catalog-tree
        [level]="level + 1"
        [value]="item.children" 
        *ngIf="item.children && item.children.length > 0">
    </documents-catalog-tree>
</div>