import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
declare let layui:any
@Component({
  selector: 'app-data_standardization_reports',
  templateUrl: './data_standardization_reports.component.html',
  styleUrls: ['./data_standardization_reports.component.sass']
})
export class Data_standardization_reportsComponent implements OnInit {

  constructor(private http: HttpClient) { }
  public list:any
  public downLoading:any =  false
  public project_id:any
  ngOnInit() {
    this.project_id = document.getElementsByName('current_project')[0].dataset.projectId
    this.http.get(`/api/v3/projects/${this.project_id}/data_standardization_reports?page=1&per_page=10`

    ).subscribe( (res:any) => {
      console.log(res)
      this.list = res.data
      const that =this 
      layui.use('laypage', function(){
        var laypage = layui.laypage;
        
        //执行一个laypage实例
        laypage.render({
          elem: 'page' //注意，这里的 test1 是 ID，不用加 # 号
          ,count: Number(res.count),
          jump: (obj:any) => {
            that.getList(obj.curr)
            
          }//数据总数，从服务端得到
        });
      });
    })
  }
  getZipFileDown(id:string,name:string) {
    return `/api/v3/logical_docs/doc/${id}/stream.zip`
  }
  getTextFileDown(id:string,name:string) {
    return `/api/v3/logical_docs/doc/${id}/stream.txt`
  }
  getList (page:any) {
    this.http.get(`/api/v3/projects/${this.project_id}/data_standardization_reports?page=${page}&per_page=10`

    ).subscribe( (res:any) => {
      this.list = res.data
    })
  }
  showNum(num:any) {
    alert(num)
  }

}
