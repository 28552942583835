import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cunstom-forms-loading',
  templateUrl: './cunstom-forms-loading.component.html',
  styleUrls: ['./cunstom-forms-loading.component.sass']
})
export class CunstomFormsLoadingComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }
}