import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { LoadingIndicatorService } from 'core-app/modules/common/loading-indicator/loading-indicator.service';
import { input } from 'reactivestates';
import { SiteDiaryService } from '../../services/SiteDiary.service';


declare let laydate: any;

@Component({
  selector: 'SiteDiary-List-Popup',
  templateUrl: './SiteDiary-List-Popup.component.html',
  styleUrls: ['./SiteDiary-List-Popup.component.sass']
})

export class SiteDiaryListPopupComponent implements OnInit {
  @Output() close = new EventEmitter();
  @Input() public type:any
  @Input() public id:any
  public dateNow: any ;
  public fileData:any={}
  public flag:boolean
  public savePrefix:any
  public selectValue:any = []
  public saveSelect:any
  public showloading: Boolean = false
  constructor(
    public service: SiteDiaryService,
     readonly loadingIndicator:LoadingIndicatorService,
  ) { }

  ngOnInit() {
    console.log(this.service)
    this.getNowDate()
    this.service.getFileContent().subscribe((res:any)=>{
      const {is_dwss_type,prefix,values} =res.data
      this.flag = is_dwss_type
      this.savePrefix = prefix
      this.selectValue = values
    })
    
  }

  getNowDate(){
    let time =new Date()
    let y =time.getFullYear()
    let m:any = time.getMonth()+1
    let d:any = time.getDate()
    d=d<10?`0${d}`:d
    m=m<10?`0${m}`:m
    this.dateNow =`${y}-${m}-${d}`
    
  }
  getStringValue(value:any) {
    return JSON.stringify(value)
  }
  // 右上角关闭事件
  closeClick(){
    this.close.emit()
    jQuery('#top-menu').css('z-index','22')
  }

  //时间事件
  dateNowChange() {
    laydate.render({
      elem: '#dateNow',
      type:'date',
      value:this.dateNow,
      show:true,
      lang: 'en',
      done:(value:any)=>{
        console.log(value)
        this.dateNow = value
      }
    });
  }

  //添加SiteDiary
  addSiteDiary(){
    if(!this.saveSelect){
      alert('Type cannot be empty')
      return
    }
    this.showloading = true

    let saveSelect = this.selectValue[this.saveSelect]
    if(this.type === 'create') {
         this.service.siteDiaryCreate({daily_date:this.dateNow, type_value: saveSelect.value}).subscribe((res:any)=>{

            this.service.updateFile({obj_id:res.site_diary,obj_type:'SiteDiary',prefix:this.savePrefix,type_value: saveSelect.value}).subscribe((res:any)=>{})
            this.close.emit(res.site_diary)
            jQuery('#top-menu').css('z-index','22')
            this.showloading = false
          }, (err) => {
            alert(err.error.errors)
            this.close.emit()
            jQuery('#top-menu').css('z-index','22')
            this.showloading = false
          })
    } else if(this.type === 'copy') {
          this.service.copySiteDiary(this.service.getCurrentId().projectId, this.id, this.dateNow).subscribe( (res:any) => {
              this.service.updateFile({obj_id:res.id,obj_type:'SiteDiary',prefix:this.savePrefix,type_value: saveSelect.value}).subscribe((res:any)=>{})
              this.close.emit(res.id)
              jQuery('#top-menu').css('z-index','22')
              this.showloading = false
          },(err) => {
            alert(err.error.errors)
            this.close.emit()
            jQuery('#top-menu').css('z-index','22')
            this.showloading = false
          } )
    }
 
  }


}
