import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {
  //loding__DocumentsOperationComponent
  operationLoading = false;
  //遮罩层__DocumentsOperationComponent
  operationMask = false;
  //Subject
  private subject = new Subject();
  //选中的子目录ID
  selectFolderId: any = '';
  //选中的子目录
  selectFolderItem: any;
  //项目文件显示
  projectShow = true;
  //个人文件显示
  userShow = true;
  //当前项目和当前用户ID
  currentId: any;
  //请求头
  requestHeader: any = {
    'X-Authentication-Scheme': 'Session',
    'X-Requested-With': 'XMLHttpRequest'
  };
  //API前缀
  apiPrefix: any = '/api/v3/logical_docs';
  constructor(private http: HttpClient) {
    this.getCurrentId();
    this.getCsrfToken();
  }
  //显示loding__DocumentsOperationComponent
  showOperationLoading(value: any) {
    this.operationLoading = value;
    this.operationMask = value;
  }
  //取消事件冒泡
  stopPropagation(event: any) {
    if (event && event.stopPropagation) {
      event.stopPropagation(); 
    } else {
      window.event && (window.event.cancelBubble = true);
    }
  }
  //Subject发送
  subjectSend() {
    this.subject.next();
  }
  //Subject接收
  subjectGet() {
    return this.subject.asObservable();
  }
  //格式化日期
  formatDate(value: any) {
    let date = new Date(value.replace(/-/g, '/'));
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    let d = date.getDate();
    return `${y}/${m < 10 ? '0' + m : m}/${d < 10 ? '0' + d : d}`;
  }
  //遮罩层处理
  mask(mode: any) {
    jQuery('#top-menu').css('z-index', mode ? 20 : 22);
  }
  //获取文件列表
  getProjectDoc(id: any, params: any) {
    return this.http.get(
      `${ this.apiPrefix }/folder/${ id }/list`,
      { params }
    );
  }
  //获取所有项目
  getAllProjects(id: any) {
    return this.http.get(`${ this.apiPrefix }/projects/${ id }/folders`);
  }
  //获取子目录
  getChildrenFolder(id: any) {
    return this.http.get(`${ this.apiPrefix }/folder/${ id }/folders`);
  }
  //文件下载
  downLoad(id: any) {
    return this.http.get(`${ this.apiPrefix }/doc/${ id }/download`);
  }
  //新建文件夹
  createFolder({ id, folder_name }: any) {
    return this.http.post(
      `${ this.apiPrefix }/folder/${ id }/create`,
      { folder_name },
      {
        withCredentials: true,
        headers: this.requestHeader
      }
    );
  }
  //文件和文件夹重命名
  rename({ id, folder_name, renameType }: any) {
    let type;
    let key;
    if (renameType === '0') {
      type = 'folder';
      key = 'folder_name';
    } else {
      type =  'doc';
      key =  'doc_name';
    }
    return this.http.post(
      `${ this.apiPrefix }/${ type }/${ id }/rename`,
      { [key]: folder_name },
      {
        withCredentials: true,
        headers: this.requestHeader
      }
    );
  }
  //文件移动和复制
  move({ id, type }: any, moveType: any) {
    return this.http.post(
      `${ this.apiPrefix }/${ type === '0' ? 'folder' : 'doc' }/${ id }/${ moveType === 'move' ? 'move' : 'copy' }`,
      { folder_id: this.selectFolderId },
      {
        withCredentials: true,
        headers: this.requestHeader
      }
    );
  }
  //上传文件
  uploadFile({ id, file }: any) {
    return this.http.post(
      `${ this.apiPrefix }/folder/${ id }/upload`,
      file,
      {
        withCredentials: true,
        headers: this.requestHeader
      }
    );
  }

  //上传pdf
  uploadPdf({ id, file }: any){
    return this.http.post(
      `${ this.apiPrefix }/doc/${ id }/upload`,
      file,
      {
        withCredentials: true,
        headers: this.requestHeader
      }
    );
  }
  //表格转pdf，然后编辑保存pdf
  savePdf({ id, file }: any){
    return this.http.post(
      `${ this.apiPrefix }/work_packages/${ id }/save_pdf`,
      file,
      {
        withCredentials: true,
        headers: this.requestHeader
      }
    );
  }

  //格式化文件大小
  formatFileSize(size: any) {
    let sizeFormat = Number(size / 1024 / 1024).toFixed(2);
    return `${ sizeFormat === '0.00' ? '0.01' : sizeFormat }M`;
  }
  //获取请求头
  getCsrfToken() {
    const csrfToken = jQuery('meta[name=csrf-token]').attr('content');
    if (csrfToken) {
      this.requestHeader['X-CSRF-TOKEN'] = csrfToken;
    }
  }
  //是否为文件夹
  isFolder(type: any) {
    return type === '0';
  }
  //是否支持预览
  isPreview(type: any) {
    let preview = [
      'jpg', 'jpeg', 'png', 'pdf', 'xls', 'xlsx', 'doc', 'docx'
    ];
    return preview.indexOf(type) !== -1;
  }
  //获取当前项目和当前用户ID
  getCurrentId() {
    const currentProject: any = document.querySelector('meta[name=current_project]');
    const currentUser: any = document.querySelector('meta[name=current_user]');
    if (currentProject && currentUser) {
      this.currentId = {
        projectFolder: currentProject.dataset.projectFolder,
        userFolder: currentUser.dataset.folder
      }
    }
  }
}