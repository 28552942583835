import { Component, OnInit, ChangeDetectorRef, Output, Input, EventEmitter } from "@angular/core";
import { labour_returnService } from "../../services/labour_return.service";
import { I18nService } from "core-app/modules/common/i18n/i18n.service";
import * as XLSX from "xlsx";
import * as Papa from "papaparse";

@Component({
  selector: "labour_return-edit",
  templateUrl: "./labour_return-edit.component.html",
  styleUrls: ["./labour_return-edit.component.sass"],
})
export class labourReturnEditComponent implements OnInit {
  @Input() public title: any;
  @Input() public saveEditContent: any;
  @Input() public saveNewForm: any;
  @Input() public version: any;
  @Output() public cancel: any = new EventEmitter();
  @Output() public submit: any = new EventEmitter();
  public metum:any
  public labour_return: any = {
    metum_attributes: {},
    department:'',
    contract_no:'',
    contract_title:'',
    contractor:'',
    works_code:''
  };
  public projectId: any;
  public labourId: any
  constructor(public service: labour_returnService, public i18n: I18nService) { }

  public tableArray: any;
  public emptyArray: any = [];
  public numArray: any = [];
  public clomnArray: Array<number> = new Array(32)
  public isGetMapping: boolean = false
  ngOnInit() {
    if (this.saveEditContent) {
      let { department, division, contract_no, contract_title, contractor, works_code, metum, id } = this.saveEditContent;
      this.labourId = id
      this.labour_return = {
        department,
        division,
        contract_no,
        contract_title,
        contractor,
        works_code,
        metum_attributes: {},
      };
    
    }else if(this.saveNewForm) {
      let { department, division, contract_no, contract_title, contractor, works_code, metum, id } = this.saveNewForm;
      this.labourId = id
      this.labour_return = {
        department,
        division,
        contract_no,
        contract_title,
        contractor,
        works_code,
        metum_attributes: {},
      };
    }
  }
  ngAfterViewInit() {
    this.projectId = jQuery("meta[name=current_project]").data("projectId");
    if (this.saveNewForm) {
      let tableId: any = document.getElementById("mainTable");
      let htmlStr = tableId.outerHTML;
      let workbook = XLSX.read(htmlStr, { type: "string" ,raw:true});
      let wb = XLSX.utils.sheet_to_csv(workbook.Sheets.Sheet1);
      this.tableArray = Array.from(tableId.children).map((tr: any, tr_i: any) => {
        return Array.from(tr.children).map((td: any, td_i: any) => td.innerText);
      });
      this.labour_return.metum_attributes = {
        data_for_display: this.tableArray,
        data_for_export: wb,
      };
      let data = {
        labour_return: this.labour_return,
      };
      this.labourId = this.saveNewForm.id;
      this.service
        .EditOneList({
          project_id: this.projectId,
          labour_return: data.labour_return,
          labour_return_id: this.saveNewForm.id,
        })
        .subscribe((res: any) => {
        });
    }
    if (this.saveEditContent) {

      this.changeTableContent(this.saveEditContent.metum.data);
    }
  }

  cancelEdit() {
    let msg = this.i18n.t('js.labour-return.dialog.cancel');
    if (confirm(msg) == true) {
      this.submitLabour("cancel");
      this.cancel.emit();
    }
  }
  updateFSiteDary() {
    this.service.updateLabourReturnFromMapping(this.projectId, this.labourId,this.version).subscribe(() => {
      this.service.getlabour(this.projectId, this.labourId).subscribe((res: any) => {
        console.log(res)
        this.isGetMapping = true
        this.changeTableContent(res.metum.data)
        this.getSum()
      })
    })
  }
  //日期格式显示的改变
  changeTitle(e: any) {
    let time = e.split("-");
    return `${time[1] + "/" + time[0]}`;
  }
  //状态格式
  changeStatus(e: any) {
    // let status = e.split('_')
    // let second =''
    // let first = status[0].slice(0, 1).toUpperCase() + status[0].slice(1)
    // if(status.length>1){
    //   second= status[1].slice(0, 1).toUpperCase() + status[1].slice(1)
    // }
    // return `${first} ${second}`
    //状态名字
    let save: any;
    if (e == "new" || e == "pending_approve") {
      save = "Draft";
    } else if (e == "pending_review") {
      save = "Contractor Signed";
    } else if (e == "completed") {
      save = "Supervisor Signed";
    } else if (e == "rejected") {
      save = "Rejected";
    }
    return save;
  }
  submitLabour(e: any) {
    let tableId: any = document.getElementById("mainTable");
    let htmlStr = tableId.outerHTML;
    let workbook = XLSX.read(htmlStr, { type: "string" ,raw:true});
    let wb = XLSX.utils.sheet_to_csv(workbook.Sheets.Sheet1);
    this.tableArray = Array.from(tableId.children).map((tr: any, tr_i: any) => {
      return Array.from(tr.children).map((td: any, td_i: any) => td.innerText);
    });
    this.emptyArray = Array.from(tableId.children).map((tr: any, tr_i: any) => {
      return Array.from(tr.children).map((td: any, td_i: any) => null);
    });

    this.labour_return.metum_attributes = {
      data_for_display: this.tableArray,
      data_for_export: wb,
    };
    let data
    this.labour_return.month = this.title;
    if (e == "cancel") {
      this.labour_return.metum_attributes = {
        data_for_display: [],
        data_for_export: wb,
      };
      delete this.labour_return.department
      delete this.labour_return.division
      delete this.labour_return.contract_no
      delete this.labour_return.contract_title
      delete this.labour_return.contractor
      delete this.labour_return.works_code
    }
    data = {
      labour_return: this.labour_return,
    };
    let id;
    if (this.saveNewForm) {

      id = this.saveNewForm.id;
    } else if (this.saveEditContent) {
      id = this.saveEditContent.id;
    }
    //编辑状态下的更新
    // if(this.saveEditContent){
    this.service
      .EditOneList({
        project_id: this.projectId,
        labour_return: data.labour_return,
        labour_return_id: id,
        version: this.version
      })
      .subscribe((res: any) => {
        this.submit.emit("update");
      });
    //新增状态
    // }else{
    //   this.service.AddOneList({
    //     project_id:this.projectId,
    //     labour_return:data
    //   }).subscribe((res:any)=>{
    //     this.submit.emit('add')
    //   })
    // }
  }

  getSum() {
    this.numArray = [];
    this.clomnArray = [];
    this.clomnArray.length = 31
    let tableId: any = document.getElementById("mainTable");
    let htmlStr = tableId.outerHTML;
    let workbook = XLSX.read(htmlStr, { type: "string" ,raw:true});
    //转化成csv格式，用于赋值计算
    let wb = XLSX.utils.sheet_to_csv(workbook.Sheets.Sheet1);
    //计算合计
    let results = Papa.parse(wb, {
      header: false,
    });
    let saveArr: any = results.data;
    //新增状态的计算总数
    let startJ = this.version == 'v17'? 5:4
    for (let i = 3; i < saveArr.length; i++) {
      let num = 0;
      for (let j = startJ; j < saveArr[i].length; j++) {
        if (saveArr[i][0] == "") {
          break;
        } else {
          if (typeof this.clomnArray[j - startJ] == 'number') {
            this.clomnArray[j - startJ] += Number(saveArr[i][j])
          } else {
            this.clomnArray[j - startJ] = Number(saveArr[i][j])
          }
          num += Number(saveArr[i][j]);
          if (j == saveArr[i].length - 7) {
            this.numArray.push(num);
            if (i == (saveArr.length - 3)) {
              let columnAdd = 0
              this.clomnArray.forEach((item) => {
                columnAdd += item
              })
              this.clomnArray.push(columnAdd)
            }
            break;
          }
        }
      }
    }
    let saveIndex: any = [];
    //编辑状态的计算总数

    console.log(this.saveEditContent)
    if (this.saveEditContent || this.isGetMapping) {
      for (let i: any = 1; i < saveArr.length; i++) {
        if (saveArr[i][0] != "") {
          saveIndex.push(i);
        }
      }
      console.log(tableId.children, tableId.children[tableId.children.length - 1].length)
      for (let i: any = 0; i < saveIndex.length; i++) {
        tableId.children[saveIndex[i]].children[tableId.children[saveIndex[i]].children.length-6].innerText = this.numArray[i];
      }
      for(let i = 2; i< tableId.children[tableId.children.length - 1].children.length; i++) {
        console.log(tableId.children[tableId.children.length - 1], this.clomnArray[i - 2])
        tableId.children[tableId.children.length - 1].children[i].innerText = this.clomnArray[i - 2]
      }
    }
  }

  changeTableContent(Arr: any) {
    let tableId: any = document.getElementById("mainTable");
    for (let i: any = 0; i < Arr.length; i++) {
      for (let j: any = 0; j < Arr[i].length; j++) {
        if (tableId.children[i].children[j]) {
          tableId.children[i].children[j].innerText = Arr[i][j];
        }
      }
    }
  }
}
