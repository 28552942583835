<li class="form--selected-value--container work-package--attachments--draggable-item"
    focus-within
    style="display: flex;"
    draggable="true"
    (dragstart)="setDragData($event)"
    [title]="text.dragHint"
    focusWithinSelector=".inplace-editing--trigger-icon">
  <!-- <span class="form--selected-value"> -->
    <!-- <op-icon icon-classes="icon-context icon-attachment"></op-icon> -->
    <div *ngIf="type(attachment.fileName)" (click)="handlePreview(attachment.logicalDocId)"  style="width: 100px;height: 100px;float: left; margin: 12px 0;">
      <img [src]="urlImg+attachment.href+'/content'" style="width: 70px;height: 70px;margin-top: 15px;margin-left: 24px;" alt="">
    </div>
    <div  *ngIf="!type(attachment.fileName)" (click)="handlePreview(attachment.logicalDocId)"  style="width: 100px;height: 100px;float: left; margin: 12px 0;">
      <div [class]="fileIcon(attachment.fileName)" style="width: 40px;height: 40px;margin-top: 30px;margin-left: 37px;"></div>
    </div>
    <div class="item-con" style="margin-left:15px;">
      <div class="item-con-title">
          <a class="work-package--attachments--filename form--a" style=" margin-top: 50px;width: auto;"
              target="_blank" rel="noopener" [attr.href]="downloadPath || '#'">
  
              {{ attachment.fileName || attachment.customName || attachment.name }}
  
              <authoring class="work-package--attachments--info" [createdOn]="attachment.createdAt"
                  [author]="attachment.author" [showAuthorAsLink]="false"></authoring>
          </a>
      </div>
      <div class="item-con-edit" *ngIf="attachment.writable" >
          <div class="edit-con" *ngIf="!attachment.isEdit">
              <span *ngIf="!attachment.remark" (click)="edit()">{{description}}</span>
              <span *ngIf="attachment.remark" (click)="edit()">{{attachment.remark}}</span>
          </div>
          <div class="edit-input" *ngIf="attachment.isEdit">
              <input type="text" autoFocus [(ngModel)]="editModel">
  
              <div class="inplace-edit--dashboard">
                  <div class="inplace-edit--controls">
                      <accessible-by-keyboard (execute)="save()" [attr.disabled]="" [linkTitle]="saveTitle"
                          class="inplace-edit--control inplace-edit--control--save">
                          <op-icon icon-classes="icon-checkmark" [icon-title]="saveTitle"></op-icon>
                      </accessible-by-keyboard>
                      <accessible-by-keyboard (execute)="cancel()" [linkTitle]="cancelTitle"
                          class="inplace-edit--control inplace-edit--control--cancel">
                          <op-icon icon-classes="icon-close" [icon-title]="cancelTitle"></op-icon>
                      </accessible-by-keyboard>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <!-- </span> -->
  <!-- <a
      href=""
      class="form--selected-value--remover work-package--atachments--delete-button"
      style="width: 120px !important;right: 120px !important;line-height: 120px;"
      *ngIf="attachment.writable && pdfType(attachment.fileName)"
      (click)="openPdf()"
      >
    <span>{{I18n.t("js.documents.edit")}}</span>
  </a> -->

  <a
      href=""
      class="form--selected-value--remover work-package--atachments--delete-button"
      style="width: 120px !important;"
      *ngIf="!!attachment.$links.delete && attachment.writable"
      (click)="confirmRemoveAttachment($event)">
    <op-icon icon-classes="icon-delete" style="font-size: 26px;position: absolute;top:45px;right:45px;"
             [icon-title]="text.removeFile({fileName: attachment.fileName})"></op-icon>
  </a>

  <input type="hidden" name="attachments[{{index}}][id]" value="{{attachment.id}}" *ngIf="!attachment.container">
</li>
