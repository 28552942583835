<div class="bgMask">
  <div class="content">
    <div class="content-tab">
      <div class="map-name">
        {{address}}
      </div>
      <div class="clone" (click)="clonebutton()">X</div>
    </div>
    <div class="map-content">
      <span>{{latitude}},{{longitude}}</span>
      <span>{{address}}</span>
    </div>
    <div id="map" class="map">
    </div>
  </div>
</div>