import { Component, OnInit } from '@angular/core';
import { DocumentsService } from '../../services/documents.service';

@Component({
  selector: 'documents-loading',
  templateUrl: './documents-loading.component.html',
  styleUrls: ['./documents-loading.component.sass']
})
export class DocumentsLoadingComponent implements OnInit {
  constructor(
    public service: DocumentsService
  ) { }
  ngOnInit() {
    this.service.mask(true);
  }
  ngOnDestroy(): void {
    this.service.mask(false);
  }
}