<div *ngIf="createNew">
  <!-- <div class="plantContent">
    <ul class="titleUl">
      <li>
        <span class="titlespan">Status</span>
        <div class="subdiv">
          <span style="background-color: #00a74c"
            >20 <br />
            <br />Active
          </span>
          <span style="background-color: #aaa; margin-left: 25px"
            >20 <br />
            <br />Inactive
          </span>
        </div>
      </li>
      <li>
        <span class="titlespan">Expiry alert</span>
        <div class="subdiv">
          <span style="background-color: #e63649"
            >20 <br />
            <br />expired
          </span>
          <span style="background-color: #ffc135; margin-left: 25px"
            >20 <br />
            <br />Expire in 3 months
          </span>
        </div>
      </li>
    </ul>
  </div> -->

  <!-- 下面的表格 -->
  <div class="table">
    <div class="tabletop">
      <span class="bigSpan">Labour</span>
      <button class="creatbtn icon-add" (click)="toCreate()">Create</button>
    </div>
    <div class="tablecontent">
      <div class="work-packages-split-view--tabletimeline-content mainContent">
        <div class="work-packages-tabletimeline--table-side work-package-table--container __hidden_overflow_container">
          <table class="keyboard-accessible-list generic-table work-package-table modulelistTable">
            <thead class="-sticky">
              <tr style="border-bottom: 1px solid #e7e7e7">
                <th class="wp-table--table-header headcenter">ID</th>
                <th class="headcenter">Status</th>
                <th class="headcenter">Name_cn</th>
                <th class="headcenter">Created Date</th>
                <th class="headcenter">Type</th>
                <th class="headcenter">Site Pass No</th>
                <th class="headcenter">Remark</th>
                <th class="headcenter">Shortcut</th>
              </tr>
            </thead>
            <tbody class="results-tbody work-package--results-tbody" *ngFor="let item of tableList; let i = index">
              <tr
                tabindex="0"
                data-work-package-id="303"
                data-class-identifier="wp-row-303"
                class="wp-table--row wp--row wp-row-303 wp-row-303-table issue __hierarchy-root-303"
              >
                <td class="headcenter">{{ item.id }}</td>
                <td class="headcenter">{{ item.state }}</td>
                <td class="headcenter">{{ item.name_cn }}</td>
                <td class="headcenter">{{ item.created_at }}</td>
                <td class="headcenter">{{ item.type }}</td>
                <td class="headcenter">{{ item.site_pass_no }}</td>
                <td class="headcenter">{{ item.remark }}</td>
                <td class="headcenter">
                  <span class="viewspan" (click)="toEdit(item.id)">Edit</span
                  ><span class="viewspan" (click)="toDelete(item.id)">Delete</span>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="paging">
            <ul class="pagingUl">
              <li (click)="changePage(saveIndex-1)"><</li>
              <li *ngFor="let item of saveNum;let i=index" [ngClass]="{bluebtn:i==saveIndex-1?true:false}" (click)="changePage(i+1)">{{i+1}}</li>
              <li (click)="changePage(saveIndex+1)">></li>
              <span>共{{count}}頁</span>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!createNew">
  <div class="plantEdit">
    <!-- <h2>ID:312312312</h2> -->
    <div class="rightBtn">
      <button class="editbtn cancelbtn" (click)="toCancel()">Cancel</button>
      <button class="editbtn" (click)="toSubmit()" *ngIf="!editNow">Submit</button>
      <button class="editbtn" (click)="toUpdate()" *ngIf="editNow">Update</button>
    </div>
    <div class="orCode" *ngIf="editNow">
      <img [src]="qrId" class="codePic" />
    </div>

    <ul class="toplist">
      <li><a href="#information">1.Basic Information</a></li>
      <li><a href="#driverLicense">2.Driver's license Information</a></li>
      <li><a href="#LicenseA12">3.Information of A12</a></li>
    </ul>
    <hr class="hrstyle" />

    <div class="inmiddle" id="information">
      <p class="title">1.Basic Information</p>
      <p><span class="keyspan">Contract No:</span><input type="text" disabled [(ngModel)]="data.contract_no"/></p>
      <p> 
        <span class="keyspan">Employer:</span>
        <select [(ngModel)]="data.employer_id" style="line-height: unset">
          <option *ngFor="let item of employerArr" [value]="item.id">{{ item.company }}</option>
        </select>
      </p>
      <p><span class="keyspan">Name(Chinese):</span><input type="text" [(ngModel)]="data.name_cn" /></p>
      <p><span class="keyspan">Name(English):</span><input type="text" [(ngModel)]="data.name_en" /></p>
      <p><span class="keyspan">Site Pass No:</span><input type="text" [(ngModel)]="data.site_pass_no" /></p>
      <p>
        <span class="keyspan">Type:</span>
        <span  *ngFor="let item of roleArr; let i = index" class="checkboxSpan">
          <input style="vertical-align: middle;width: 20px; height: 20px;" *ngIf="!editNow" type="checkbox" (change)="changeCheckbox($event,item.id)"/>
          <input style="vertical-align: middle;width: 20px; height: 20px;" *ngIf="editNow" [checked]="checkSelect(item.id)" type="checkbox" (change)="changeCheckbox($event,item.id)"/>
          <span style="margin-left: 10px;">{{item.name}}</span>
      </span>
      </p>
      <p><span class="keyspan">Status:</span>
        <select [(ngModel)]="data.state">
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
        </select>
      </p>
      <p><span class="keyspan">Remark:</span><input type="text" [(ngModel)]="data.remark" /></p>
    </div>

    <div class="SectionItem__loading loadingDiv" *ngIf="showLoading">
      <div class="loading-indicator -compact">
        <div class="block-1"></div>
        <div class="block-2"></div>
        <div class="block-3"></div>
        <div class="block-4"></div>
        <div class="block-5"></div>
      </div>
    </div>

    <div class="inmiddle" id="driverLicense">
      <p class="title">2.Driver's license Information</p>
      <button class="addBtn icon-add" (click)="addDriverLicense()">Add</button>
      <div class="cranecontent">
        <div class="craneitem" *ngFor="let item of licenseArr; let i = index">
          <p class="topTitle">
            License <span>{{ i + 1 }}</span>
          </p>
          <table class="firsttable">
            <tr>
              <td class="spantd">Type</td>
              <td>
                <select [(ngModel)]="item.kind">
                  <option value="wheeled telescopic">Wheeled Telescopic</option>
                  <option value="crawler">Crawler</option>
                  <option value="tower">Tower</option>
                  <option value="truck">Truck</option>
                  <option value="gantry">Gantry</option>
                  <option value="derrick">Derrick</option>
                </select>
              </td>
            </tr>
            <tr>
              <td class="spantd">Reference No</td>
              <td><input type="text" [(ngModel)]="item.reference_no" /></td>
            </tr>
            <tr>
              <td class="spantd">Date Of Issue</td>
              <td>
                <div
                  class="summary-value"
                  [id]="'dateOfIssue' + i"
                  style="height: 35px; line-height: 35px; border: 1px solid #bbb; background: #fff"
                  (click)="dateOfIssueChange(i, 'dateOfIssue')"
                >{{item.date_of_issue}}</div>
              </td>
            </tr>
            <tr>
              <td class="spantd">Cert Valid Date From</td>
              <td>
                <div
                  class="summary-value"
                  [id]="'dateForm' + i"
                  style="height: 35px; line-height: 35px; border: 1px solid #bbb; background: #fff"
                  (click)="dateFormChange(i, 'dateForm')"
                >{{item.cert_valid_date_from}}</div>
              </td>
            </tr>
            <tr>
              <td class="spantd">Cert Valid Date to</td>
              <td>
                <div
                  class="summary-value"
                  [id]="'dateTo' + i"
                  style="height: 35px; line-height: 35px; border: 1px solid #bbb; background: #fff"
                  (click)="dateToChange(i, 'dateTo')"
                >{{item.cert_valid_date_to}}</div>
              </td>
            </tr>
            <tr>
              <td class="spantd"></td>
              <td>
                <button class="addBtn" style="margin-top: 15px; background: #f56c6c" (click)="remove('driver', i,item.id)">
                  Remove
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div class="inmiddle" id="LicenseA12">
      <p class="title">3.Information of A12</p>
      <button class="addBtn icon-add" (click)="addA12Lisence()">Add</button>
      <div class="cranecontent">
        <div class="craneitem" *ngFor="let item of a12Arr; let i = index">
          <p class="topTitle">A12 Liscence</p>
          <table class="firsttable">
            <tr>
              <td class="spantd">Type</td>
              <td>A12</td>
            </tr>
            <tr>
              <td class="spantd">Reference No</td>
              <td><input type="text" [(ngModel)]="item.reference_no" /></td>
            </tr>
            <tr>
              <td class="spantd">Date Of Issue</td>
              <td>
                <div
                  class="summary-value"
                  [id]="'a12dateOfIssue' + i"
                  style="height: 35px; line-height: 35px; border: 1px solid #bbb; background: #fff"
                  (click)="dateOfIssueChange(i, 'a12dateOfIssue')"
                >{{item.date_of_issue}}
              </div>
              </td>
            </tr>
            <tr>
              <td class="spantd">Cert Valid Date From</td>
              <td>
                <div
                  class="summary-value"
                  [id]="'a12dateForm' + i"
                  style="height: 35px; line-height: 35px; border: 1px solid #bbb; background: #fff"
                  (click)="dateFormChange(i, 'a12dateForm')"
                >{{item.cert_valid_date_from}}</div>
              </td>
            </tr>
            <tr>
              <td class="spantd">Cert Valid Date to</td>
              <td>
                <div
                  class="summary-value"
                  [id]="'a12dateTo' + i"
                  style="height: 35px; line-height: 35px; border: 1px solid #bbb; background: #fff"
                  (click)="dateToChange(i, 'a12dateTo')"
                >{{item.cert_valid_date_to}}</div>
              </td>
            </tr>
            <tr>
              <td class="spantd"></td>
              <td>
                <button class="addBtn" style="margin-top: 15px; background: #f56c6c" (click)="remove('a12', i,item.id)">
                  Remove
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
