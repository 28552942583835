
    <div class="textarea-wrapper">
      <div class="op-ckeditor--wrapper op-ckeditor-element">
      <textarea type="text"
      class="inline-edit--field"
      [attr.aria-required]="required"
      [attr.required]="required"
      [disabled]="inFlight"
      [(ngModel)]="value.raw"
      (keydown)="handler.handleUserKeydown($event)"
      [id]="handler.htmlId" >
      </textarea>
      </div>
      <edit-field-controls *ngIf="!(handler.inEditMode || initializationError)"
                           [fieldController]="field"
                           (onSave)="handleUserSubmit()"
                           (onCancel)="handler.handleUserCancel()"
                           [saveTitle]="text.save"
                           [cancelTitle]="text.cancel">
      </edit-field-controls>
    </div>
