import { Component, OnInit, Input } from '@angular/core';
import { I18nService } from 'core-app/modules/common/i18n/i18n.service';

@Component({
  selector: 'documents-toast',
  templateUrl: './documents-toast.component.html',
  styleUrls: ['./documents-toast.component.sass']
})
export class DocumentsToastComponent implements OnInit {
  @Input() type: any;
  text: any = {
    //移动成功
    move: this.i18n.t("js.documents.move_successed"),
    //复制成功
    copy: this.i18n.t("js.documents.copy_successed"),
    //保存成功
    save:this.i18n.t("js.documents.save_successed")
  }
  constructor(
    public i18n: I18nService
  ) {}
  ngOnInit() {}
}