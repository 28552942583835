<div class="content">
    <div class="labourContent" *ngIf="!showEdit&&!showApproval">
        <div class="labourTitle">
            <p><svg t="1608540015044" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3325" width="16" height="16"><path d="M32 32l0 822.851c0 75.431 61.716 137.147 137.142 137.147l822.856 0 0-959.998-959.998 0zM786.282 923.429l-274.283 0 0-137.147-137.142 0 0 137.147-137.142 0 0-274.284 548.568 0 0 274.284zM854.851 443.426c0 34.289-34.284 68.573-68.569 68.573l-548.568 0c-34.289 0-68.573-34.284-68.573-68.573l0-342.852 685.709 0 0 342.852z" p-id="3326"></path></svg>
            <span class="titlespan">Labour return</span></p>
            <p>
                <button class="icon-add titlebtn" (click)="addLabour()">{{ i18n.t("js.labour-return.add") }}</button>
<!--                <button class="toMapBtn titlebtn" (click)="toMap()"> Config</button>-->
            </p>
        </div>
        <div class="returnlistContent">
           <labour_return-list (toShowEdit)="Edit($event)" (toShowApproval)="Approval($event)"></labour_return-list>
        </div>
    </div>
    <div class="mantleContent" *ngIf="!showEdit&&showMantle">
        <div class="mainContent">
            <span class="closeIcon" (click)="showMantle=false"></span>
          <div class="maindiv">
              <h2>New Labour Return</h2>
              <div class="summary-value" id="period_end"  (click)="period_endChange()">
            </div>
            <span style="color: #F56C6C;" *ngIf="!hasTitle">{{ i18n.t("js.labour-return.selectTime") }}</span>
            <button class="editbtn" (click)="confirmAdd()">ok</button>
          </div>    
        </div>
      </div>
</div>

<div class="editContent" *ngIf="showEdit">
    <labour_return-edit [title]="title" (cancel)="toCancel()" [version]="version" [saveEditContent]="saveEditContent" [saveNewForm]="saveNewForm" (submit)="toSubmit($event)"></labour_return-edit>
</div>

<div class="approvalContent" *ngIf="!showEdit&&showApproval">
    <span class="closeIcon" (click)="cancelEdit()"></span>
    <div class="approvalTitle">
        <span class="titlespan">Labour Return Approval</span>
        <span class="titlespan" *ngIf="saveApprovalContent.state!='pending_review'&&saveApprovalContent.state!='completed'&&saveApprovalContent.state!='new'">Signer (Contractor) : {{saveApprovalContent.contractor_signer.name}}</span>
        <span class="titlespan" *ngIf="saveApprovalContent.state=='pending_review'">Signer (Supervisor) : {{saveApprovalContent.supervisor_signer.name}}</span>
        <span class="titlespan" *ngIf="saveApprovalContent.state=='completed'">所有簽名已完成</span>
        <span class="titlespan" *ngIf="saveApprovalContent.state=='new'">空表，請前往編輯</span>
    </div>
    <div class="tableApproval">
        <div class="titleApproval">
            <p><b>Department/Division:</b><span>{{saveApprovalContent.department}} / {{saveApprovalContent.division}}</span></p>
            <p><b>Month/Year:</b><span>{{changeTitle(saveApprovalContent.month)}}</span></p>
            <p><b>Contract No.:</b><span>{{saveApprovalContent.contract_no}}</span></p>
            <p><b>Contract Title:</b><span>{{saveApprovalContent.contract_title}}</span></p>
            <p><b>Contractor:</b><span>{{saveApprovalContent.contractor}}</span></p>
            <p><b>Works Code:</b><span>{{saveApprovalContent.works_code}}</span></p>
        </div>
       <div class="noPointer">
        <labour_return-table [bgcolor]="'#d8d8d8'" [numArray]="fakeArray" [saveApprovalContent]="saveApprovalContent"></labour_return-table>   
       </div>
    </div>
    <div class="bottomApproval" *ngIf="saveApprovalContent.state!='rejected'&&saveApprovalContent.state!='completed'&&saveApprovalContent.state!='new'">        
        <p class="bottomp"><b>{{ i18n.t("js.labour-return.approve") }}:</b> <button class="bottombtn" (click)="confirmApprove()" [ngClass]="{confirmbtn: !saveApprovalContent.reject_remark?true:false,disableBtn: setDisabled?true:false}" id="confirm" [disabled]="setDisabled?true:false">Yes</button>
             <button class="bottombtn" id="cancel" [ngClass]="{cancelbtn: saveApprovalContent.reject_remark?true:false,disableBtn: setDisabled?true:false}" (click)="cancelApprove()" 
             [disabled]="setDisabled?true:false">No</button>
            </p>
            <!-- <span style="color: #F56C6C;width:250px;" *ngIf="!sign_doc_id&&selectConfirm">尚未签名，请签名</span>
             <span style="color: #67C23A;width:250px;" *ngIf="sign_doc_id&&selectConfirm">签名成功</span> -->
    </div>
    
    <p class="inputwidth" *ngIf="!selectConfirm"><b>{{ i18n.t("js.labour-return.remark") }}:</b>
        <textarea rows="4" cols="50" [(ngModel)]="rejectRemark" *ngIf="saveApprovalContent.state!='rejected'&&saveApprovalContent.state!='completed'&&saveApprovalContent.state!='new'"></textarea>
        <span class="remarkSpan" *ngIf="saveApprovalContent.state=='rejected'||saveApprovalContent.state=='completed'">{{rejectRemark}}</span>
    </p>
    <div class="bottomCenter" *ngIf="saveApprovalContent.state!='rejected'&&saveApprovalContent.state!='completed'&&saveApprovalContent.state!='new'"><button class="approvebtn bluebtn" [disabled]="setDisabled?true:false">
        <span class="approveSpan" *ngIf="selectConfirm" (click)="confirmSign()" [ngClass]="{disableBtn: setDisabled?true:false}">{{ i18n.t("js.labour-return.approvesign") }}</span><span class="approveSpan" *ngIf="!selectConfirm" (click)="rejectSignature()">{{ i18n.t("js.labour-return.send") }}</span></button> <button class="approvebtn" (click)="cancelEdit()">{{ i18n.t("js.labour-return.cancel") }}</button>
    </div>

    
    <div class="SectionItem__loading loadingDiv" *ngIf="showLoading">
        <div class="loading-indicator -compact">
          <div class="block-1"></div>
          <div class="block-2"></div>
          <div class="block-3"></div>
          <div class="block-4"></div>
          <div class="block-5"></div>
        </div>
      </div> 
    <wp-signature *ngIf="decidetoSign" (closeView)='closeView($event)'></wp-signature>
</div>


