import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
//google地图
import { Loader } from "@googlemaps/js-api-loader"

@Component({
  selector: 'wp-gmap',
  templateUrl: './wp-gmap.component.html',
  styleUrls: ['./wp-gmap.component.sass']
})
export class WpGmapComponent implements OnInit {
  @Input() public address: any;
  @Input() public latitude: any;
  @Input() public longitude: any;

  
  @Output() public clone = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    const that = this
    let lat = Number(that.latitude)
    let lng = Number(that.longitude)
    const loader = new Loader({
      apiKey: "AIzaSyAh9_mxlov_dKCrA1QGX3vCrKefxEwTDwU",
      version: "weekly",
    });
    
    let map
    loader.load().then(() => {
      map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
        center: { lat, lng },
        zoom: 18,
        disableDefaultUI: true,
      });
       // The marker, positioned at Uluru
       const marker = new google.maps.Marker({
        position:  { lat, lng },
        map: map,
      });
    });
  }

  clonebutton() {
    console.log('adasdasdadasd')
    this.clone.emit()
  }

}
