import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from "@angular/core";
import { toJson } from "@uirouter/core";
import { I18nService } from "core-app/modules/common/i18n/i18n.service";
import { reject } from "lodash";
import { E_liftingService } from "../../services/e_lifting.service";
import { fromEvent } from 'rxjs';

declare let laydate: any;

@Component({
  selector: "e_lifting-create",
  templateUrl: "./e_lifting-create.component.html",
  styleUrls: ["./e_lifting-create.component.sass"],
})
export class E_liftingCreateComponent implements OnInit {
  @Output() cancelShow = new EventEmitter();
  @Output() saveDraft = new EventEmitter();
  @Input() applicationConfig: any;

  public title: any;
  public applicationId: any;
  public saveIndex: any = "index1";
  public checkboxItem: any = []; //检查项
  public index: any;
  public showMantle: boolean = false;
  public tableList: any = [];
  public locationArr: any = [];
  public approverArr:any = []
  public data: any = {};
  public showLoading: boolean = false;
  //保存选择的值
  public saveItem: any = {};
  public plantArr: any = [
    {
      plant_type: "MobileCrane",
      sub_type: "",
      plant_no: "",
      cert_valid_date_to: "",
      working_radius: "",
    },
  ];
  public labourArr: any = [{ role: "Operator", site_pass_no: "", cert_valid_date_to: "",name_en:'' }];
  public roleFlag: boolean = false;
  public materialFlag: boolean = false;
  public checkFlag: boolean = false;
  public newMaterial: any = {
    remarkable: false,
    weight: 0,
  };
  public host: any = window.location.origin;
  public checkedArr: any = [];
  public newCheck: any = {};
  public decideToSign: boolean = false;
  public signPicAddress: any;
  //判斷編輯
  public editNum: any = 0;
  public editFlag: boolean = false;
  public laboureditFlag: boolean = false;
  public confrimAgreeFlag: boolean = false;
  public laboureditNum: any = 0;
  public savePlantIndex: any;
  public canSign: boolean = true;
  public displayQrcode: boolean = false;
  public scanQrcodeType = {};
  public lang:boolean =false
  constructor(public i18n: I18nService, public service: E_liftingService) {}

  ngOnInit() {
    let lang = document.getElementsByTagName('html')[0].getAttribute('lang')
    if(lang === 'en'){
      this.lang = false
    } else {
      this.lang = true
    }
    this.getNowTime();
    //获取location的列表
    this.service.getLocation().subscribe((res: any) => {
      this.locationArr = res;
    });
    this.service.getApprover().subscribe((res:any)=>{
      this.approverArr = res
    })

  }
  ngOnChanges(changes: SimpleChanges): void {
    this.getAllContent();
    fromEvent(window, 'scroll')
      .subscribe((event) => {
        if(document.documentElement.scrollTop!=0){
        jQuery('#fixdiv').css('top','5px')
        }else{
        jQuery('#fixdiv').css('top','55px')
        }
      })
  }
  //隐藏fix定位
  hiddenFixDiv(){
    jQuery('#fixdiv').css('display','none')
    jQuery('#liftingcontent').css('padding-top','0')
  }
  showFixDiv(){
    jQuery('#liftingcontent').css('padding-top','155px')
    jQuery('#fixdiv').css('display','block')
  }
  //获取当前的时间
  getNowTime() {
    let t = new Date();
    let h: any = t.getHours();
    let min: any = t.getMinutes();
    let s: any = t.getSeconds();
    this.data.permit_valid_time_from = `${h < 10 ? "0" + h : h}:${min < 10 ? "0" + min : min}:${s < 10 ? "0" + s : s}`;
  }
  //创建获取相应的内容
  async getAllContent() {
    this.showLoading = true;
    const { permit_no, permit_valid_date, id } = this.applicationConfig;
    this.applicationId = id;
    this.data = { permit_no, permit_valid_date };

    //获取设备信息
    await this.getLabour("Operator");
    await this.getLabour("Rigger");
    await this.getLabour("Banksman");

    //获取人员信息
    await this.getPlant("LiftingGear");
    await this.getPlant("LiftingCrane");
    //获取已经拥有的基本信息
    await this.getBaseMessage(id);
    //获取材料项目
    await this.getMaterials();
    //获取检查项
    await this.getChecks();
    this.showLoading = false;
  }
  //獲取草稿的基本信息
  getBaseMessage(id: any) {
    return new Promise((resolve, rejected) => {
      this.service.viewApplicationBaseMessage(id).subscribe((res: any) => {
        this.data = res;
        if (res.permit_valid_time_from) {
          this.data.permit_valid_time_from = res.permit_valid_time_from.split(" ")[1];
        }
        if (res.permit_valid_time_to) {
          this.data.permit_valid_time_to = res.permit_valid_time_to.split(" ")[1];
        }
        resolve();
      });
    });
  }
  //獲取草稿的人員和設備信息
  getLabour(e: any) {
    return new Promise((resolve, rejected) => {
      this.service.viewApplicationLabour(this.applicationId, e).subscribe((res: any) => {
        switch (e) {
          case "Operator":
            if (res.list.length>0) {
              this.labourArr[0] = res.list[0];
            }
            break;
          case "Rigger":
            if (res.list.length>0) {
              this.labourArr = this.labourArr.concat(res.list);
            }
            break;
          case "Banksman":
            if (res.list.length>0) {
              this.labourArr = this.labourArr.concat(res.list);
            }
            break;
        } 
        resolve();
      });
    });
  }
  getPlant(e: any) {
    return new Promise((resolve, rejected) => {
      this.service.viewApplicationPlant(this.applicationId, e).subscribe((res: any) => {
        switch (e) {
          case "LiftingCrane":
            if (res.list.length>0) {
              this.plantArr[0] = res.list[0];
              this.plantArr[0].plant_type='MobileCrane'
            }
            break;
          case "LiftingGear":
            if (res.list) {
              this.plantArr = [...this.plantArr, ...res.list];
            }
            break;
        }
        for (const item of this.plantArr) {
          if (item && item.working_capacity) {
            item.working_capacity_from = item.working_capacity.split("-")[0];
            item.working_capacity_to = item.working_capacity.split("-")[1];
          }
          if (item && item.working_radius) {
            item.working_radius_from = item.working_radius.split("-")[0];
            item.working_radius_to = item.working_radius.split("-")[1];
          }
        }
        resolve();
      });
    });
  }

  //data的基本信息的时间的绑定
  dateFormChange(type: any) {
    laydate.render({
      elem: `#${type}`,
      show: true,
      lang: "en",
      type: "time",
      value: this.data[type],
      done: (value: any) => {
        this.data[type] = value;
      },
    });
  }
  //人員使用到的時間的綁定
  labourChange(type: any, index: any) {
    if (index != this.laboureditNum) {
      return;
    }
    laydate.render({
      elem: `#${type}${index}`,
      show: true,
      lang: "en",
      value: this.labourArr[index].cert_valid_date_to,
      done: (value: any) => {
        this.labourArr[index].cert_valid_date_to = value;
      },
    });
  }
  //設備使用到的時間的綁定
  dateChange(type: any, index: any) {
    if (index != this.editNum) {
      return;
    }
    laydate.render({
      elem: `#${type}${index}`,
      show: true,
      lang: "en",
      value: this.plantArr[index].cert_valid_date_to,
      done: (value: any) => {
        this.plantArr[index].cert_valid_date_to = value;
      },
    });
  }
  //確認是否已經點擊第七步的選擇框
  confirmAgree(e: any) {
    if (e.target.checked) {
      this.confrimAgreeFlag = true;
    } else {
      this.confrimAgreeFlag = false;
    }
  }
  //取消給elifting添加內容
  cancel() {
    if (confirm(this.i18n.t("js.attachments.e_lifting.Cancel"))) {
      this.cancelShow.emit();
    }
  }
  //暫時將該內容保存爲草稿
  save() {
    // if (confirm("是否确定要将目前所做的更改保存为草稿")) {
    //   this.saveDraft.emit();
    // }
    if (confirm(this.i18n.t("js.attachments.e_lifting.SaveDraft"))) {
      this.service.editApplication(this.applicationId, this.data).subscribe((res: any) => {
        this.cancelShow.emit();
      });
    }
  }
  //提交整個e-lifting，把狀態改成pending_approve
  submit() {
    // if (!this.confrimAgreeFlag) {
    //   alert(this.i18n.t('js.attachments.e_lifting.ensureBeenProperly'));
    //   return;
    // }
    if (confirm(this.i18n.t('js.attachments.e_lifting.Save'))) {
      this.service.editApplication(this.applicationId, this.data).subscribe((res: any) => {
        this.service.sumbitApplication(this.applicationId, "").subscribe((res: any) => {
          this.cancelShow.emit();
        });
      });
    }
  }
  //添加新的設備或者人員
  addLifting(type: any) {
    this.title =type
    const item =this.resetContent(type)
    if(!item) return
    if (type == "plant") {
      this.plantArr.push({
        plant_type: "LiftingGear",
      });
    } else {
      this.labourArr.push({
        role: type,
      });
    }
  }
  //重置
  resetContent(type:any){
    let flag
    if (this.laboureditFlag || this.editFlag) {
      if (confirm("js.attachments.e_lifting.ReSet")) {
        this.laboureditFlag = false;
        this.editFlag = false;
        //重置plant
        if (type == "plant") {
          if (this.editNum == 0) {
            this.plantArr[this.editNum] = {
              plant_type: "MobileCrane",
              sub_type: "",
              plant_no: "",
              cert_valid_date_to: "",
              working_radius: "",
            };
          } else {
            this.plantArr[this.editNum] = { plant_type: "LiftingGear" };
          }
          //重置labour
        } else {
          if (this.laboureditNum == 0) {
            this.labourArr[this.laboureditNum] = { role: "Operator", site_pass_no: "", cert_valid_date_to: "",name_en:'' };
          } else {
            // this.labourArr[this.laboureditNum] = { role: type };
          }
        }
        flag = true
      } else {
        flag = false
      }
    }else{
      flag = true
    }
    return flag
  }
  //打開select的時候，根據不同的內容，展現不同的可供選擇的人員或者設備
  async addItem(type:any,e: any, index: any) {
    this.hiddenFixDiv()
    this.title = e
    const item =this.resetContent(type)
    if(!item) return
    //用於確認選擇時候點擊的數組的哪一項的判斷
    this.savePlantIndex = index;
    this.showMantle = true;
    if (e == "Crane") {
      //判断是crane还是liftinggear
      if (index == 0) {
        this.getList("plants", "Crane");
      } else {
        this.getList("plants", "LiftingGear");
      }
      this.editNum = index;
    } else {
      await this.getList("labours", "all");
      this.laboureditNum = index;
      this.roleFlag = true;
    }
  }
  //獲取列表，plant或者labour
  async getList(role: any, e: any) {
    return new Promise((resolve, reject) => {
      this.service.getTableList(role, e).subscribe((res: any) => {
        this.tableList = res.list;
        resolve();
      });
    });
  }
  //刪除會導致接口報錯的字段
  deleteObj(item: any) {
    this.showLoading = true;
    delete item.created_by;
    delete item.created_by;
    delete item.updated_at;
    delete item.updated_by;
    delete item.type;
    delete item.working_radius_from;
    delete item.working_radius_to;
    delete item.working_capacity_from;
    delete item.working_capacity_to;
    return item;
  }
  changeEditPlant(index: any) {
    this.editNum = index;
    this.editFlag = true;
  }
  changeEditLabour(index: any) {
    this.laboureditNum = index;
    this.laboureditFlag = true;
  }
  //保存添加的設備
  saveEditPlant(index: any, item: any) {
    let flag: boolean = false;
    //判断是创建还是更新
    if (item.id) {
      flag = true;
    }
    if (item.plant_type != "LiftingGear") {
      item.working_radius = `${item.working_radius_from}-${item.working_radius_to}`;
      item.working_capacity = `${item.working_capacity_from}-${item.working_capacity_to}`;
    }
    item = this.deleteObj(item);
    if (flag) {
      this.service.applicationEditPlant(this.applicationId, { plant: item }).subscribe((res: any) => {
        this.plantArr[index] = res;
        this.commonPlant(index);
      });
    } else {
      this.service.applicationAddPlant(this.applicationId, { plant: item }).subscribe((res: any) => {
        this.plantArr[index] = res;
        this.commonPlant(index);
      });
    }
  }
  //抽取更新或者添加設備時候相同的代碼
  commonPlant(index: any) {
    if (this.plantArr[index].working_radius) {
      this.plantArr[index].working_radius_from = this.plantArr[index].working_radius.split("-")[0];
      this.plantArr[index].working_radius_to = this.plantArr[index].working_radius.split("-")[1];
    }
    if (this.plantArr[index].working_capacity) {
      this.plantArr[index].working_capacity_from = this.plantArr[index].working_capacity.split("-")[0];
      this.plantArr[index].working_capacity_to = this.plantArr[index].working_capacity.split("-")[1];
    }
    this.showLoading = false;
    this.editFlag = false;
  }
  //保存添加的人員
  saveEditLabour(index: any, item: any) {
    let flag: boolean = false;
    item = this.deleteObj(item);
    if (item.id) {
      flag = true;
    }
    delete this.labourArr[index].state;
    if (flag) {
      this.service.applicationEditLabour(this.applicationId, { labour: item }).subscribe((res: any) => {
        this.labourArr[index] = res;
        this.showLoading = false;
        this.laboureditFlag = false;
      });
    } else {
      this.service.applicationAddLabour(this.applicationId, { labour: item }).subscribe((res: any) => {
        this.labourArr[index] = res;
        this.showLoading = false;
        this.laboureditFlag = false;
      });
    }
  }
  //確定添加設備
  confrim() {
    if (JSON.stringify(this.saveItem) == "{}") {
      alert(this.i18n.t('js.attachments.e_lifting.addPOE'));
      return;
    }
    //添加设备或者人員
    this.showLoading = true;
    const { id, type } = this.saveItem;
    if (this.saveItem.hasOwnProperty("type")) {
      //去除掉id的干擾
      this.saveItem.plant_type = type;
      this.saveItem.id = this.plantArr[this.savePlantIndex].id;
      if (type != "LiftingGear") {
        if (this.saveItem.working_radius) {
          this.saveItem.working_radius_from = this.saveItem.working_radius.split("-")[0];
          this.saveItem.working_radius_to = this.saveItem.working_radius.split("-")[1];
        }
        if (this.saveItem.working_capacity) {
          this.saveItem.working_capacity_from = this.saveItem.working_capacity.split("-")[0];
          this.saveItem.working_capacity_to = this.saveItem.working_capacity.split("-")[1];
        }
      }
      //把取好的值賦值給plant的數組
      this.plantArr[this.savePlantIndex] = this.saveItem;
      if (this.saveItem.cert) {
        this.plantArr[this.savePlantIndex].cert_valid_date_to = this.saveItem.cert.cert_valid_date_to;
      }
      this.editFlag = true;
    } else {
      //查看人员的证书
      this.labourArr[this.savePlantIndex].name_en = this.saveItem.name_en;
      this.labourArr[this.savePlantIndex].site_pass_no = this.saveItem.site_pass_no;
      if (this.savePlantIndex == 0) {
        let sub_type: any;
        if (this.plantArr[0].sub_type) {
          sub_type = this.plantArr[0].sub_type;
        }
        this.service.getSignLabour(id, sub_type).subscribe((res: any) => {
          this.labourArr[this.savePlantIndex].name_en = res.name_en;
          if (res.driving_license) {
            this.labourArr[this.savePlantIndex].cert_no = res.driving_license.reference_no;
            this.labourArr[this.savePlantIndex].cert_valid_date_to = res.driving_license.cert_valid_date_to;
          }
        });
      } else {
        if (this.saveItem.cert) {
          this.labourArr[this.savePlantIndex].cert_no = this.saveItem.cert.reference_no;
          this.labourArr[this.savePlantIndex].cert_valid_date_to = this.saveItem.cert.cert_valid_date_to;
        }
      }
      this.laboureditFlag = true;
    }
    this.index = undefined;
    this.showMantle = false;
    this.roleFlag = false;
    this.showLoading = false;
    this.saveItem = {};
    this.showFixDiv()
  }
  //獲取當前角色信息
  getRole(e: any) {
    let arr = [];
    if(e){
      for (const item of e) {
        arr.push(item.name);
      }
    }
    return arr.join();
  }
  selectUse(e: any, data: any) {
    this.index = e;
    this.saveItem = data;
  }
  remove(type: any, id: any, index: any, role: any) {
    this.editFlag = false
    this.laboureditFlag = false
    if (type == "plantArr") {
      if (confirm(this.i18n.t('js.attachments.e_lifting.DLDevice'))) {
        this.showLoading = true;
        if (id) {
          this.service.applicationDeletePlant(this.applicationId, id).subscribe((result: any) => {
            this.plantArr.splice(index, 1);
            this.showLoading = false;
          });
        } else {
          this.plantArr.splice(index, 1);
          this.showLoading = false;
        }
      }
    } else if (type == "labourArr") {
      if (confirm(this.i18n.t('js.attachments.e_lifting.DLPerson'))) {
        this.showLoading = true;
        if (id) {
          this.service.applicationDeleteLabour(this.applicationId, id, role).subscribe((result: any) => {
            this.labourArr.splice(index, 1);
            this.showLoading = false;
          });
        } else {
          this.labourArr.splice(index, 1);
          this.showLoading = false;
        }
      }
    }
  }
  /*************第四步*************************/
  //获取材料列表项
  async getMaterials() {
    return new Promise((resolve, rejected) => {
      this.service.getMaterialsList(this.applicationId).subscribe((res: any) => {
        this.checkboxItem = res.list;
        for (const item of this.checkboxItem) {
          item.weight = item.weight || 0;
        }
        resolve();
      });
    });
  }
  closeBtn() {
    this.showFixDiv()
    this.roleFlag =false
    this.showMantle = false;
    this.materialFlag = false;
  }
  //添加材料
  addMaterial() {
    this.hiddenFixDiv()
    this.showMantle = true;
    this.materialFlag = true;
  }
  //確認添加材料選項
  confirmAddmaterial() {
    if (this.newMaterial.name == "") {
      this.showFixDiv()
      alert(this.i18n.t('js.attachments.e_lifting.EnterName'));
      return;
    }
    this.showLoading = true;
    this.service
      .addMaterialItem({ material_item: this.newMaterial, application_id: this.applicationId })
      .subscribe((res: any) => {
        this.getMaterials();
        this.showMantle = false;
        this.showLoading = false;
        this.newMaterial = {
          remarkable: false,
          weight: 0,
        };
      this.showFixDiv()

      });
  }
  //刪除材料選項
  deleteMaterial(item: any) {
    if (confirm(this.i18n.t('js.attachments.e_lifting.DLItem'))) {
      this.showLoading = true;
      this.service.deleteMaterialItem(this.applicationId, item.id).subscribe((res: any) => {
        this.getMaterials();
        this.showLoading = false;
      });
    }
  }

  changeWeight(e: any) {
    this.service
      .changeMaterialItem(e.id, { application_id: this.applicationId, material_item: e })
      .subscribe((res: any) => {
        this.getMaterials();
        this.showMantle = false;
      });
  }
  changeBox(e: any, item: any) {
    if (e.target.checked) {
      item.checked = true;
    } else {
      item.checked = false;
    }
    this.changeWeight(item);
  }
  //上傳圖片
  uploadPhoto(type: any, index: any) {
    switch (type) {
      case "material":
        jQuery(`#materialPhoto${index}`).click();
        break;
      case "plant":
        jQuery(`#plantPhoto${index}`).click();
        break;
      case "labour":
        jQuery(`#labourPhoto${index}`).click();
        break;
    }
  }
  //選擇上傳圖片
  selectFile(type: any, event: any, item: any, index: any) {
    let files = event.target.files;
    let file = new FormData();
    file.append("file", files[0]);
    this.showLoading = true;
    this.service.update_img(file).subscribe((res: any) => {
      this.showLoading = false;
      if (!item.hasOwnProperty("photo_doc_ids")) {
        item.photo_doc_ids = [];
      }
      item.photo_doc_ids.push(res.doc_id);
      this.showLoading = false;
      switch (type) {
        case "material":
          this.changeWeight(item);
          break;
        case "plant":
          this.service.applicationEditPlant(this.applicationId, { plant: item }).subscribe((res: any) => {
            this.plantArr[index] = item;
          });
          break;
        case "labour":
          this.service.applicationEditLabour(this.applicationId, { labour: item }).subscribe((res: any) => {
            this.labourArr[index] = item;
          });
          break;
      }
    });
  }
  //删除設備图片
  deletePhoto(item: any, doc_id: any, index: any) {
    if (confirm(this.i18n.t('js.attachments.e_lifting.DLPic'))) {
      item.deleted_photo_doc_ids = [doc_id];
      item = this.deleteObj(item);
      this.service.applicationEditPlant(this.applicationId, { plant: item }).subscribe((res: any) => {
        let i = item.photo_doc_ids.indexOf(doc_id);
        item.photo_doc_ids.splice(i, 1);
        this.plantArr[index] = item;
        this.showLoading = false;
      });
    }
  }
  //刪除人員的照片
  deletelabourPhoto(item: any, doc_id: any, index: any) {
    if (confirm(this.i18n.t('js.attachments.e_lifting.DLPic'))) {
      item.deleted_photo_doc_ids = [doc_id];
      this.service.applicationEditLabour(this.applicationId, { labour: item }).subscribe((res: any) => {
        let i = item.photo_doc_ids.indexOf(doc_id);
        item.photo_doc_ids.splice(i, 1);
        this.labourArr[index] = item;
        this.showLoading = false;
      });
    }
  }

  /********************check item******************* */
  //獲取檢查項目
  async getChecks() {
    return new Promise((resolve, reject) => {
      this.service.getChecklistItem(this.applicationId).subscribe((res: any) => {
        this.checkedArr = res.list;
        for (const item of this.checkedArr) {
          item.checked = item.checked ? item.checked : "pass";
        }
        resolve();
      });
    });
  }
  //更新檢查項的內容
  changeCheck(type: any, e: any, id: any) {
    if (!e.target.checked) {
      type = null;
    }
    this.service
      .editCheckItem(id, { application_id: this.applicationId, checklist_item: { checked: type } })
      .subscribe((res: any) => {
        this.getChecks();
      });
  }
  //添加檢查項
  addCheckitem(type: any, e: any) {
    if (e.target.checked) {
      this.newCheck.checked = type;
    } else {
      this.newCheck.checked = null;
    }
  }
  //確認添加檢查項
  addCheck() {
    if (!this.newCheck.content) {
      alert(this.i18n.t('js.attachments.e_lifting.CheckItem'));
      return;
    }
    this.showLoading = true;
    this.checkFlag = false;
    this.service
      .addCheckItem({ application_id: this.applicationId, checklist_item: this.newCheck })
      .subscribe(async (res: any) => {
        await this.getChecks();
        this.newCheck = {};
        this.showLoading = false;
      });
  }
  //刪除檢查項
  deleteCheck(e: any) {
    if (confirm(this.i18n.t('js.attachments.e_lifting.DLItem'))) {
      this.showLoading = true;
      this.service.deleteCheckItem(e.id, this.applicationId).subscribe(async (res: any) => {
        await this.getChecks();
        this.showLoading = false;
      });
    }
  }
  //添加签名
  closeView(img: any) {

    if (!img) {
      this.decideToSign = false;
      this.showFixDiv()
      return;
    }
    this.showLoading = true;
    let blob = this.dataURLtoBlob(img);
    var files = new File([blob], "drawComplete.png", { type: blob.type });
    let file = new FormData();
    file.append("file", files);
    file.append("prefix", "e-lifting");
    this.service.update_img(file).subscribe((res: any) => {
      this.signPicAddress = `${this.host}/api/v3/logical_docs/doc/${res.doc_id}/stream?image_size=thumbnail`;
      this.service
        .updateSign(this.applicationId, { type: "confirmation", signature: { doc_id: res.doc_id } })
        .subscribe((res: any) => {
          this.decideToSign = false;
          this.showLoading = false;
          this.showFixDiv()
        });
    });
  }
  dataURLtoBlob(dataurl: any) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }
  //是否可以签名
  toSign() {
    if (this.canSign) {
      alert(this.i18n.t('js.attachments.e_lifting.signatureNote'));
      return;
    }
    this.hiddenFixDiv()
    this.decideToSign = true;
  }
  useDefault(e: any) {
    this.canSign = e;
  }
  //打开二维码按钮
  pushQrcodeClick(type: string, index: any) {
    this.hiddenFixDiv()
    const item =this.resetContent(type)
    if(!item) return
    this.scanQrcodeType = { type, index };
    this.displayQrcode = !this.displayQrcode;
  }

  /**
   * @returns(dateil) 扫码返回的数据 类型：object
   * @private(data) 扫码请求出来的数据
   * @private(qrcodetype) type:二维码类型，index:以及当前扫码的下标
   *
   * 注意：关闭二维码弹窗是公用的，如果用户没扫码，而是手动关闭二维码弹窗，dateil返回的是null，需要做判断
   */
  scanQrcodeCallback(detail: any) {
    if (detail) {
      //plant
      if(detail.qrcodeType.type=='plants'){
        if(detail.data.type!='LiftingGear'&&detail.qrcodeType.index==0){
          //第一個plant的信息
          if(detail.data.working_capacity){
            this.plantArr[0].working_capacity_from = detail.data.working_capacity.split("-")[0];
            this.plantArr[0].working_capacity_to = detail.data.working_capacity.split("-")[1];
          }
          if(detail.data.working_radius){
            this.plantArr[0].working_radius_from = detail.data.working_radius.split("-")[0];
            this.plantArr[0].working_radius_to = detail.data.working_radius.split("-")[1];
          }
          this.plantArr[0].plant_type =detail.data.type
          this.plantArr[0].sub_type =detail.data.sub_type
          this.plantArr[0].danger_zone_ground =detail.data.danger_zone_ground
          this.plantArr[0].danger_zone_others =detail.data.danger_zone_others
          this.plantArr[0].danger_zone_roof =detail.data.danger_zone_roof
          this.plantArr[0].cert_valid_date_to =detail.data.cert.cert_valid_date_to
          this.plantArr[0].plant_no =detail.data.plant_no
        }else if(detail.data.type=='LiftingGear'&&detail.qrcodeType.index==0){
          //第一個plant只能是crane類型
          alert(this.i18n.t('QRCode', {Type: detail.data.type}))
          return
        }else if(detail.data.type=='LiftingGear'&&detail.qrcodeType.index!=0){
          // 其他的只能是liftinggear
          this.plantArr[detail.qrcodeType.index].working_load = detail.data.working_load
          this.plantArr[detail.qrcodeType.index].plant_no = detail.data.plant_no
          this.plantArr[detail.qrcodeType.index].cert_valid_date_to = detail.data.cert.cert_valid_date_to
        }else if(detail.data.type!='LiftingGear'&&detail.qrcodeType.index!=0){
          alert(this.i18n.t('QRCode', {Type: detail.data.type}))
          return
        }
        this.editFlag = true
        this.editNum = detail.qrcodeType.index
        //labour
      }else{
        //第一个labour的信息
        if(detail.qrcodeType.type=='labours'&&detail.qrcodeType.index==0){
          let sub_type: any;
          if (this.plantArr[0].sub_type) {
            sub_type = this.plantArr[0].sub_type;
          }
          this.service.getSignLabour(detail.data.id, sub_type).subscribe((res: any) => {
            this.labourArr[0].name_en = res.name_en
            this.labourArr[0].site_pass_no = res.site_pass_no
          if (res.driving_license) {
            this.labourArr[0].cert_no = res.driving_license.reference_no;
            this.labourArr[0].cert_valid_date_to = res.driving_license.cert_valid_date_to;
          }
        })
        //剩下的其他labour信息
        }else if(detail.qrcodeType.index!=0){
          this.labourArr[detail.qrcodeType.index].name_en = detail.data.name_en
          this.labourArr[detail.qrcodeType.index].site_pass_no =  detail.data.site_pass_no
          this.labourArr[detail.qrcodeType.index].cert_no =detail.data.cert.reference_no;
          this.labourArr[detail.qrcodeType.index].cert_valid_date_to = detail.data.cert.driving_license.cert_valid_date_to;
        }
        this.laboureditFlag = true
        this.laboureditNum = detail.qrcodeType.index
      }

    }
    this.displayQrcode = !this.displayQrcode;
    this.showFixDiv()
  }
}
