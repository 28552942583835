import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DocumentsService } from '../../services/documents.service';
import { I18nService } from 'core-app/modules/common/i18n/i18n.service';

@Component({
  selector: 'documents-input-name',
  templateUrl: './documents-input-name.component.html',
  styleUrls: ['./documents-input-name.component.sass']
})
export class DocumentsInputNameComponent implements OnInit {
  @Input() oldName: any;
  @Input() type: any = 'createFolder';
  @Output() cancel = new EventEmitter();
  @Output() confirm = new EventEmitter();
  //输入框的值
  name: any = '';
  //标题
  title: any = {
    //重命名
    rename: this.i18n.t("js.documents.rename"),
    //新建文件夹
    createFolder: this.i18n.t("js.documents.new_folder")
  }
  constructor(
    public service: DocumentsService,
    public i18n: I18nService
  ) {}
  handleCancel() {
    this.cancel.emit();
  }
  handleConfirm() {
    if (this.name === '') return;
    this.confirm.emit(this.name);
  }
  ngOnInit() {
    if (this.oldName) {
      this.name = this.oldName;
    }
    this.service.mask(true);
  }
  ngOnDestroy(): void {
    this.service.mask(false);
  }
}