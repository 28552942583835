import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Data_standardization_reportsComponent } from './data_standardization_reports.component';
import { Ng2StateDeclaration, UIRouterModule } from '@uirouter/angular';
export const E_lifting_ROUTES: Ng2StateDeclaration[] = [
  {
    name: 'data_standardization_reports',
    url: '/data_standardization_reports',
    parent: 'root',
    component: Data_standardization_reportsComponent
  }
];
@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild({ 
      states: E_lifting_ROUTES
    }),                           
  ],
  declarations: [Data_standardization_reportsComponent]
})
export class Data_standardization_reportsModule { }
