//-- copyright
// OpenProject is a project management system.
// Copyright (C) 2012-2015 the OpenProject Foundation (OPF)
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See doc/COPYRIGHT.rdoc for more details.
//++

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {I18nService} from 'core-app/modules/common/i18n/i18n.service';
import {PathHelperService} from 'core-app/modules/common/path-helper/path-helper.service';
import {HalResource} from 'core-app/modules/hal/resources/hal-resource';
import {States} from 'core-components/states.service';
import {HalResourceNotificationService} from "core-app/modules/hal/services/hal-resource-notification.service";
//url
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'attachment-list-item',
  templateUrl: './attachment-list-item.html',
  styleUrls: ['./attachment-list-item.sass']
})
export class AttachmentListItemComponent {
  @Input() public resource:HalResource;
  @Input() public attachment:any;
  @Input() public index:any;
  @Input() destroyImmediately:boolean = true;
  @Output() public url:any =  new EventEmitter();
  @Output() public openpdfs:any =  new EventEmitter();


  @Output() public removeAttachment = new EventEmitter<void>();

  static imageFileExtensions:string[] = ['jpeg', 'jpg', 'gif', 'bmp', 'png'];

  //文件图标
  icon: any = {
    0: 'folder',
    zip: 'zip',
    doc: 'doc',
    docx: 'doc',
    pdf: 'pdf',
    jpg: 'jpg',
    jpeg: 'jpg',
    png: 'png'
  }
  urlImg: any = window.location.origin
    
  previewUrl: any = '';
  //文件图标
  fileIcon(type: any) {
    let str = type.split(".")[1]
    return `documents-${ this.icon[str] || 'file' }`;
  }

  type(type:any){
    let t = type.split(".")[1]
    let b =  t == 'png' || t == 'jpg' || t == 'jpeg' ?  true : false
    return b
  }
  pdfType(type:any){
    let t = type.split(".")[1]
    let b =  t == 'pdf'?  true : false
    return b
  }
  // 预览url
  
  public text = {
    dragHint: this.I18n.t('js.attachments.draggable_hint'),
    destroyConfirmation: this.I18n.t('js.text_attachment_destroy_confirmation'),
    removeFile: (arg:any) => this.I18n.t('js.label_remove_file', arg)
  };
   //打开预览
   handlePreview(id: any) {
   let allTyp = ['mp4','avi','rmvb','rm','asf','divx','mpg','mpeg','mpe','wmv','mkv','vob']
   let allAudio = ['mp3','ogg','webm']
   let t = this.attachment.fileName.split(".")[1]
   console.log(this.attachment)
   if(allTyp.indexOf(t) == 0){
    let videoUrl =this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin+this.attachment.href+'/content')
    this.url.emit({
      url:videoUrl,
      status:'video'
    })
   }else if(allAudio.indexOf(t)  == 0){
    let videoUrl =this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin+this.attachment.href+'/content')
    this.url.emit({
      url:videoUrl,
      status:'audio'
    })
   }else{
    this.previewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      `/pdfjs/web/viewer.html?file=${ encodeURIComponent(
        `${ window.location.origin }/api/v3/logical_docs/doc/${ id }/pdf`
      ) }`
    );
    this.url.emit({
      url:this.previewUrl ,
      status:'picture'
    })
   }
  };
  public saveTitle:string='#';

  public cancelTitle:string='#';

  public editModel:string;

  public description:string=this.I18n.t('js.work_packages.placeholders.formattable', { name: this.I18n.t('js.work_packages.properties.description') });



  constructor(protected halNotification:HalResourceNotificationService,
              readonly I18n:I18nService,
              readonly states:States,
              private sanitizer: DomSanitizer,
              readonly http: HttpClient,
              readonly pathHelper:PathHelperService) {
  }

  /**
   * Set the appropriate data for drag & drop of an attachment item.
   * @param evt DragEvent
   */
  public setDragData(evt:DragEvent) {
    const url = this.downloadPath;
    const previewElement = this.draggableHTML(url);

    evt.dataTransfer!.setData("text/plain", url);
    evt.dataTransfer!.setData("text/html", previewElement.outerHTML);
    evt.dataTransfer!.setData("text/uri-list", url);
    evt.dataTransfer!.setDragImage(previewElement, 0, 0);
  }

  public draggableHTML(url:string) {
    let el:HTMLImageElement|HTMLAnchorElement;

    if (this.isImage) {
      el = document.createElement('img') as HTMLImageElement;
      el.src = url;
      el.textContent = this.fileName;
    } else {
      el = document.createElement('a') as HTMLAnchorElement;
      el.href = url;
      el.textContent = this.fileName;
    }

    return el;
  }

  public get downloadPath() {
    return this.pathHelper.attachmentDownloadPath(this.attachment.id, this.fileName);
  }

  public get isImage() {
    const ext = this.fileName.split('.').pop() || '';
    return AttachmentListItemComponent.imageFileExtensions.indexOf(ext.toLowerCase()) > -1;
  }

  public get fileName() {
    const a = this.attachment;
    return a.fileName || a.customName || a.name;
  }

  public openPdf(){
    console.log(this.attachment)
    let videoUrl =this.sanitizer.bypassSecurityTrustResourceUrl(`${ window.location.origin }/api/v3/logical_docs/doc/${ this.attachment.logicalDocId }/pdf`)
    this.openpdfs.emit({
      url:videoUrl,
      type:'true',
      name:this.attachment.fileName,
      id:this.attachment.logicalDocId
    })
  }
  public saveRemark(remark:string) {
    const csrf_token: string | undefined = jQuery("meta[name=csrf-token]").attr("content");
    let newHeaders: any = {};
    newHeaders["X-Authentication-Scheme"] = "Session";
    newHeaders["X-Requested-With"] = "XMLHttpRequest";
    newHeaders["Content-Type"] = "multipart/form-data";

    if (csrf_token) {
      newHeaders["X-CSRF-TOKEN"] = csrf_token;
    }
    this.http
      .post(
        `/api/v3/work_packages/${this.resource.id}/attachments/${this.attachment.id}?remark=${remark}`,
        {},
        {
          withCredentials: true,
          headers: newHeaders
        }
      )
      .subscribe((res:any) => {
        console.log("saveRemark", res);
        if(res.result=="ok"){
          this.attachment.remark=this.editModel;
        }
      });
  }

  public save(){
    this.attachment.isEdit=false;
    this.saveRemark(this.editModel);
  }
  public cancel(){
    this.attachment.isEdit=false;
  }

  public edit(){
    this.attachment.isEdit=true;
    this.editModel=this.attachment.remark;
  }

  public confirmRemoveAttachment($event:JQuery.TriggeredEvent) {
    if (!window.confirm(this.text.destroyConfirmation)) {
      $event.stopImmediatePropagation();
      $event.preventDefault();
      return false;
    }

    this.removeAttachment.emit();

    if (this.destroyImmediately) {
      this
        .resource
        .removeAttachment(this.attachment);
    }

    return false;
  }
}
