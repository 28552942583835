<div #scrollContainer [ngClass]="classes">
  <ul #scrollPane class="tabrow" (scroll)="onScroll($event)">
    <li
        [class.selected]="tabs[0].id === currentTabId"
        [attr.tab-id]="tabs[0].id">
      <a href="#" (click)="clickTab(tabs[0].id)" [textContent]="tabs[0].name"></a>
    </li>
  </ul>
  <div #scrollLeftBtn [hidden]="hideLeftButton"
                      class="scrollable-tabs--button -left"
                      (click)="scrollLeft()">
    <span class="icon-arrow-left2"></span>
  </div>
  <div #scrollRightBtn [hidden]="hideRightButton"
                       class="scrollable-tabs--button -right"
                       (click)="scrollRight()">
    <span class="icon-arrow-right2"></span>
  </div>
</div>
