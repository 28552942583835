import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';

@Component({
  selector: 'checkListSign',
  templateUrl: './checkListSign.component.html',
  styleUrls: ['./checkListSign.component.sass']
})
export class checkListComponentSign implements OnInit {


  @ViewChild(SignaturePad,{static:true}) signaturePad:SignaturePad; //第二视图
  @Output() closeView = new EventEmitter();
  public canvas:any= null
  public canSubmit:boolean = false


  constructor() { }

  ngOnInit() {
    this.signaturePad.onEnd = () => {
    
      this.canSubmit = true 
    }
  }

  signatureImage: string;
  public  signaturePadOptions: Object = {
    minWidth: 0.5,// 比划的粗细,
    canvasWidth: 545, // 可写宽度
    canvasHeight: 200,// 可写高度
  };
  canvasResize() {
    const canvas:any = document.querySelector('canvas');
    this.signaturePad.set('minWidth', 0.2);
    this.signaturePad.set('canvasWidth', canvas.offsetWidth);
    this.signaturePad.set('canvasHeight', canvas.offsetHeight); 
  }

  ngAfterViewInit() {
      this.canvas = document.querySelector('#signatureCanvas')!.querySelector('canvas')

    // this.signaturePad.clear();
    
    // this.canvasResize();
    
    }

  drawComplete() {
      if(  !this.canSubmit )
        return
      this.signatureImage = this.signaturePad.toDataURL();
      
      this.closeView.emit(this.signatureImage)
  }

 
    getSubmitStyle() {
      console.log(this.canSubmit?'background-color: #1aaf54; border-left:1px solid #000':'background-color: #aaa; color:#eee')
      return this.canSubmit?'background-color: #1aaf54; border-left:1px solid #000':'background-color: #aaa; color:#eee'
    }

  clone(){
    this.closeView.emit()
  }
}
