<div [ngClass]="[
      editFieldContainerClass,
      fieldName,
      active && '-active' || '',
      wrapperClasses || '-small'
     ]"
     (dragover)="startDragOverActivation($event)">

  <div #editContainer
       [hidden]="!active">
  </div>

  <div (accessibleClick)="activateIfEditable($event)"
       [accessibleClickStopEvent]="false"
       [hidden]="active"
       tabindex="-1"
       [ngClass]="{
          'customField': this.fieldName.includes('customField')
       }"
       #displayContainer></div>
</div>
