<div class="project-list">
  <div class="project-list-box" *ngFor="let item of projectList">
    <div class="project-list-box-title">
      <div class="title-text">
        <div class="title-text-left">
          {{ item.name }}
        </div>
        <div class="title-text-right" *ngIf="item.attachment_url">
          <a class="icon-one" id="projectIcon" [href]="item.attachment_url" target='_blank'></a>
          <a class="icon-two"  id="projectIcon" [href]="item.attachment_url" target='_blank'></a>
        </div>
      </div>
      <div class="title-date">
        Finish Date: <span>{{ item.finish_date }}</span>
      </div>
      <div class="title-btn">
        <a [href]="projectPath(item.identifier)">
          <span>
            Overview
          </span>
          <i class="iconfont icon-you"></i>
        </a>
      </div>
    </div>
    <div class="project-list-box-mobile">
      <div class="mobile-date">
        Finish Date: <span>{{ item.finish_date }}</span>
      </div>
      <div class="mobile-info">
        <div class="info-item">
            <div class="info-item-text">
              <a [href]="workPackagesPath(item.identifier, 'New')">{{ item.task.new }}</a>
            </div>
            <div class="info-item-title">
              new
            </div>
          </div>
          <div class="info-item">
            <div class="info-item-text">
              <a [href]="workPackagesPath(item.identifier, 'In progress')">{{ item.task.in_progress }}</a>
            </div>
            <div class="info-item-title">
              In progress
            </div>
          </div>
          <div class="info-item">
            <div class="info-item-text">
              <a [href]="workPackagesPath(item.identifier, 'Closed')">{{ item.task.closed }}</a>
            </div>
            <div class="info-item-title">
              Closed
            </div>
          </div>
          <div class="info-item">
            <div class="info-item-text">
              <a [href]="workPackagesPath(item.identifier, 'On hold')">{{ item.task.on_hold }}</a>
            </div>
            <div class="info-item-title">
              On hold
            </div>
          </div>
          <div class="info-item">
            <div class="info-item-text">
              <a [href]="workPackagesPath(item.identifier, 'Rejected')">{{ item.task.rejected }}</a>
            </div>
            <div class="info-item-title">
              Rejected
            </div>
          </div>
      </div>
    </div>
    <div class="project-list-box-info">
      <div class="info-item">
        <div class="info-item-text">
          <a [href]="workPackagesPath(item.identifier, 'New')">{{ item.task.new }}</a>
        </div>
        <div class="info-item-title">
          new
        </div>
      </div>
      <div class="info-item">
        <div class="info-item-text">
          <a [href]="workPackagesPath(item.identifier, 'In progress')">{{ item.task.in_progress }}</a>
        </div>
        <div class="info-item-title">
          In progress
        </div>
      </div>
      <div class="info-item">
        <div class="info-item-text">
          <a [href]="workPackagesPath(item.identifier, 'Closed')">{{ item.task.closed }}</a>
        </div>
        <div class="info-item-title">
          Closed
        </div>
      </div>
      <div class="info-item">
        <div class="info-item-text">
          <a [href]="workPackagesPath(item.identifier, 'On hold')">{{ item.task.on_hold }}</a>
        </div>
        <div class="info-item-title">
          On hold
        </div>
      </div>
      <div class="info-item">
        <div class="info-item-text">
          <a [href]="workPackagesPath(item.identifier, 'Rejected')">{{ item.task.rejected }}</a>
        </div>
        <div class="info-item-title">
          Rejected
        </div>
      </div>
    </div>
    <div class="project-list-box-status">
      <div class="status-title">
        <div class="title-left">
          Tasks Status:
        </div>
        <div class="title-right">{{ item.task.total }} tasks</div>
      </div>
      <div class="status-percentage">
        <div class="status-con"></div>
        <div class="status-number status__new" *ngIf="item.task" [ngStyle]="{
            width: (item.task.new / item.task.total) * 100 + '%'
          }"></div>
        <div class="status-number status__progress" *ngIf="item.task" [ngStyle]="{
            width: (item.task.new / item.task.total) * 100 + (item.task.in_progress / item.task.total) * 100 + '%'
          }"></div>
        <div class="status-number status__closed" *ngIf="item.task" [ngStyle]="{
            width:
              (item.task.new / item.task.total) * 100 +
              (item.task.in_progress / item.task.total) * 100 +
              (item.task.closed / item.task.total) * 100 +
              '%'
          }"></div>
        <div class="status-number status__hold" *ngIf="item.task" [ngStyle]="{
            width:
              (item.task.new / item.task.total) * 100 +
              (item.task.in_progress / item.task.total) * 100 +
              (item.task.closed / item.task.total) * 100 +
              (item.task.on_hold / item.task.total) * 100 +
              '%'
          }"></div>
        <div class="status-number status__rejected" *ngIf="item.task" [ngStyle]="{
            width:
              (item.task.new / item.task.total) * 100 +
              (item.task.in_progress / item.task.total) * 100 +
              (item.task.closed / item.task.total) * 100 +
              (item.task.on_hold / item.task.total) * 100 +
              (item.task.rejected / item.task.total) * 100 +
              '%'
          }"></div>
      </div>
    </div>
  </div>
</div>