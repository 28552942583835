<div class="mask"  (click)="okclick()">
  <div class="frame"  (click)="$event.stopPropagation()">
    <div class="title">{{title}}</div>
    <div class="close" (click)="okclick()">X</div>
    <div class="content">
      <div class="key-value">
        <div class="key">{{title}}：</div>
        <select class="value" style="width: 200px;" [disabled]="summaryData.state != 'draft'&&summaryData.state != 'rejected'" [(ngModel)]="addStaffsData.staff_id">
          <option *ngFor="let item of select" [value]="item.id">{{item.name}}</option>
        </select>
      </div>
      <div class="key-value">
        <div class="key">No：</div>
        <div class="value">
          <input [disabled]="summaryData.state != 'draft'&&summaryData.state != 'rejected'" type="number" [ngClass]="{'input-error': EmptyTip.numberTip}" min="0" class="value-input" [(ngModel)]="addStaffsData.number"/>
          <div class="tip" [ngClass]="{'tipAnimation': EmptyTip.numberTip}"  *ngIf="EmptyTip.numberTip">number cannot be a negative number</div>
        </div>
      </div>
      <div class="key-value">
        <div class="key">Ownership*：</div>
        <div class="value">
          <div class="key-input" (click)="ownershipDialog = !ownershipDialog;$event.stopPropagation()">
            <input class="value-input"   (ngModelChange)="inputChange($event,'ownership')"  [ngClass]="{'input-error': EmptyTip.OwnershipTip}" [(ngModel)]="addStaffsData.ownership" readonly/>
            <div class="key-divloading"  *ngIf="ownershipDialog">
              <input class="key-search-input" (click)="$event.stopPropagation()" (input)="inputChange($event.target.value?$event.target.value.toLowerCase():'','ownership')"/>
              <button class="key-divloading-button" *ngFor="let item of ownershipList" (click)="$event.stopPropagation();addStaffsData.ownership = item.ownership_name; ownershipDialog = false">{{item.ownership_name}}</button>
            </div>
          </div>
          <div class="tip" [ngClass]="{'tipAnimation': EmptyTip.ownershipTip}"  *ngIf="EmptyTip.ownershipTip">ownershipTip can not Empty</div>
        </div>
      </div>
      <button class="add" *ngIf="!updataData && (summaryData.state == 'draft'||summaryData.state == 'rejected')" (click)="add()">Add</button>
      <button class="add" *ngIf="updataData && (summaryData.state == 'draft'||summaryData.state == 'rejected')" (click)="updataClick()">Save</button>
      <button class="addCopy" *ngIf="pupopType == 'site_staffs'&& (summaryData.state == 'draft'||summaryData.state == 'rejected')" (click)="copyDialog = true">copy</button>
      <div class="work-packages-split-view--tabletimeline-content" style="height: 310px; margin: 30px 0;">
        <div class="work-packages-tabletimeline--table-side work-package-table--container __hidden_overflow_container">
          <table style=" margin: 0 auto;" class="keyboard-accessible-list generic-table work-package-table">
            <thead class="-sticky">
              <tr style="border-bottom: 1px solid #E7E7E7;"  class="listTitle">
                <th style="text-align: left;">{{title}}</th>
                <th>No.</th>
                <th>Ownership</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody class="results-tbody work-package--results-tbody" *ngFor="let item of tableList">  
              <tr [ngStyle]="{'color': updataData ? updataData.id != item.id ? '#e3e3e3':'' : '#000'}">
                <td style="text-align: left;">{{item.staff_name}}</td>
                <td>{{item.number}}.0</td>
                <td>{{item.ownership}}</td>
                <td>
                  <div class="tablebutton" *ngIf="summaryData.state == 'draft'||summaryData.state == 'rejected'">
                    <!-- <div (click)="editClick(item)" style="background-color: #777777;" [ngStyle]="{'color': updataData ? updataData.id != item.id ? '#e3e3e3':'' : 'rgba(0, 119, 255, 1)'}">Edit</div>
                    <div (click)="deleteClick(item)" [ngStyle]="{'color': updataData ? updataData.id != item.id ? '#e3e3e3':'' : 'rgba(0, 119, 255, 1)'}">Delete</div> -->
                    <div (click)="editClick(item)" style="background-color: #777777;" [ngStyle]="{'cursor': updataData?'not-allowed':'pointer'}">Edit</div>
                    <div (click)="deleteClick(item)" style="background-color: #e15353;" [ngStyle]="{'cursor': updataData?'not-allowed':'pointer'}">Delete</div>
                  </div>
                </td>
              </tr>
          </table>
        </div>
      </div>
    </div>
    <button class="OK" (click)="okclick()">Done</button>
  </div>
</div>
<!-- <div class="frameCopy" *ngIf="copyDialog">
  <h1>Copy Date</h1>
  <div  class="seachDate-dateItem" id="dateNow"  (click)="dateNowChange()">{{dateNow}}</div>
  <div class="copy">
    <button class="copyButton" (click)="copyOwnership()">
        Copy
    </button>
  </div>
  <div class="close" (click)="copyDialog = false">X</div>
</div> -->
<div class="activeCopy" *ngIf="copyDialog">
  <div class="case">
    <div class="Close" (click)="copyDialog = false">X</div>
    <div class="title">
      <span>Site Diary Date:</span><span id="dateNow" (click)="dateNowChange()" >{{dateNow}}<img [src]="imgdata('date.jpg')"></span>
    </div>
    <div class="CopyAll"><span>Copy All:</span> <input [(ngModel)]="copyCheckAll" type="checkbox" (click)="checkAll()"/></div>
    <div class="table">
      <table style=" margin: 0 auto;" class="keyboard-accessible-list generic-table work-package-table">
        <thead class="-sticky">
          <tr style="border-bottom: 1px solid #E7E7E7;"  class="listTitle">
            <th>Copy</th>
            <th>Site Staffs</th>
            <th>No</th>
            <th>Ownership</th>
          </tr>
        </thead>
        <tbody class="results-tbody work-package--results-tbody" *ngFor="let item of copyList">  
          <tr>
            <td><input [(ngModel)]="item.copy" type="checkbox"/></td>
            <td>{{item.staff_name}}</td>
            <td>{{item.number}}</td>
            <td>{{item.ownership}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="action">
      <div class="submit" (click)="copySubmit()">Submmit</div>
      <div class="Cancel" (click)="copyDialog = false">Cancel</div>
    </div>
  </div>
</div>