import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { I18nService } from 'core-app/modules/common/i18n/i18n.service';

@Component({
  selector: 'documents-pagination',
  templateUrl: './documents-pagination.component.html',
  styleUrls: ['./documents-pagination.component.sass']
})
export class DocumentsPaginationComponent implements OnInit {
  //当前页
  @Input() currentPage = 1;
  //单页条数
  @Input() per = 10;
  //总条数
  @Input() total: any;
  //传值选中页
  @Output() getData = new EventEmitter();
  //总页数
  pageTotal = 0;
  //分页列表
  paginationList: any = [];
  constructor(
    public i18n: I18nService
  ) {}
  ngOnInit() { }
  ngOnChanges(changes: SimpleChanges): void {
    let total = changes.total;
    if (total && total.currentValue !== undefined) {
      this.pageTotal = Math.ceil(total.currentValue / this.per);
      this.paginationList = [];
      for (let i = 1; i <= this.pageTotal; i++) {
        this.paginationList[i - 1] = i;
      }
    }
  }
  //选中页
  selectPage(page: any) {
    if (page >= 1 && page <= this.pageTotal && page !== this.currentPage) {
      this.getData.emit(this.currentPage = page);
    }
  }
  //总页数文本
  getPageTotalTextLang() {
    let pageTotalTextLang: any = {
      en: `Total ${ this.pageTotal }`,
      cn: `共${ this.pageTotal }页`,
      tw: `共${ this.pageTotal }頁`
    }
    return pageTotalTextLang[
      this.i18n.t("js.documents.total")
    ];
  }
}