
    <div class="case" [ngClass]="{notShow: !showDateCase}">

        <input 
        type="text" 
        placeholder="yyyy-MM-dd HH:mm:ss"
        id="DateTimeInput"
        [(ngModel)]="dateValue"
        #getInput>
        <div id="test5" #DateTime ></div>
        <div id="Time" #Time  ></div>
    </div>
    <input type="text" 
    placeholder="yyyy-MM-dd HH:mm:ss"
    [ngClass]="{notShow: showDateCase}" (focus)="toShowDateCase()" [(ngModel)]="dateValue" />

