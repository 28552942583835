import { Component, OnInit, Input } from '@angular/core';

declare var GC: any;

@Component({
  selector: 'cunstom-forms-excel',
  templateUrl: './cunstom-forms-excel.component.html',
  styleUrls: ['./cunstom-forms-excel.component.sass']
})
export class CunstomFormsExcelComponent implements OnInit {
  @Input() exceldatail: any;

  public spread: any
  public excelIo: any
  public sheet: any


  constructor() { }

  public ngOnInit() {
    let LicenseKey
    if(window.location.host == "103.82.213.202"){
      LicenseKey = '103.82.213.202,E331992477277567#B0AnyU3CdJ3SRTVjZzsiQjRWelNmYOFlMmxGVTNnUwUkcyFzLOFnZqdlRBhUWyYlWxMGZ7MjQllnerFnaEBVRGd4cy2SeRhnWHNVYjV5crNlWmp7YXtmdkpVeCJXRxYTYZFlb796aP3CbZxUZGV7bZpXUxd5ZDx6MG3UcthjYvM6UKlmVolDMyZjNzM5bJdzT7MzLStUc8JDWlRHdoVjUaJnVj56TahkY8czQWRXNpRlaRtWZl9WNWdTck3UNTZTYOJUTVRVY9wkSJhUbWdUVHlGSPdnUphWQ4E6LWBDOW3CcYV7cpZ6ZMdXS4EETEVUNuR6UhFkaGJiOiMlIsICNxgDOyQTMiojIIJCL9gTN4kTO8ADO0IicfJye&Qf35VfiU5TzYjI0IyQiwiIzEjL6ByUKBCZhVmcwNlI0IiTis7W0ICZyBlIsICOxAzMxADIyEjNwAjMwIjI0ICdyNkIsIiNxcDMxIDMyIiOiAHeFJCLiIDMy8yMxIjLygjLzATMiojIz5GRiwiIPdlTVh4QiojIh94QiwSZ5JHd0ICb6VkIsIyN6UzN7IzN7QjM9kTMzMjI0ICZJJCL3V6csFmZ0IiczRmI1pjIs9WQisnOiQkIsISP3EkSQRTZ9EldndUS624dPN5ZYFGd6lzVul5QmFVSypWOYdWWFlHMuF7MqhDSHdzNYR7N6x4L9kVS796Rrp4Y4pEcnRmYM36Sq9kNXlzSwY4S4kXboFWRX9WdjZDO0Alb5'
      console.log('103.82.213.202,');
    }else if(window.location.host == "mobility-uat.chunwo.com"){
      console.log('mobility-uat.chunwo.com');
      LicenseKey = '103.82.213.202,E331992477277567#B0AnyU3CdJ3SRTVjZzsiQjRWelNmYOFlMmxGVTNnUwUkcyFzLOFnZqdlRBhUWyYlWxMGZ7MjQllnerFnaEBVRGd4cy2SeRhnWHNVYjV5crNlWmp7YXtmdkpVeCJXRxYTYZFlb796aP3CbZxUZGV7bZpXUxd5ZDx6MG3UcthjYvM6UKlmVolDMyZjNzM5bJdzT7MzLStUc8JDWlRHdoVjUaJnVj56TahkY8czQWRXNpRlaRtWZl9WNWdTck3UNTZTYOJUTVRVY9wkSJhUbWdUVHlGSPdnUphWQ4E6LWBDOW3CcYV7cpZ6ZMdXS4EETEVUNuR6UhFkaGJiOiMlIsICNxgDOyQTMiojIIJCL9gTN4kTO8ADO0IicfJye&Qf35VfiU5TzYjI0IyQiwiIzEjL6ByUKBCZhVmcwNlI0IiTis7W0ICZyBlIsICOxAzMxADIyEjNwAjMwIjI0ICdyNkIsIiNxcDMxIDMyIiOiAHeFJCLiIDMy8yMxIjLygjLzATMiojIz5GRiwiIPdlTVh4QiojIh94QiwSZ5JHd0ICb6VkIsIyN6UzN7IzN7QjM9kTMzMjI0ICZJJCL3V6csFmZ0IiczRmI1pjIs9WQisnOiQkIsISP3EkSQRTZ9EldndUS624dPN5ZYFGd6lzVul5QmFVSypWOYdWWFlHMuF7MqhDSHdzNYR7N6x4L9kVS796Rrp4Y4pEcnRmYM36Sq9kNXlzSwY4S4kXboFWRX9WdjZDO0Alb5'
    }else{
      console.log('Chun Wo Construction & Engineering Co Ltd,mobility.chunwo.com,');
      LicenseKey = 'Chun Wo Construction & Engineering Co Ltd,mobility.chunwo.com,433369283719154#B0s08Q9ETcVhDbnhXQk9WR5hVOLB7cvM7SC3WZalUZ9QETQtCcVVmQ4xEZTZFO7MnYHRTUkh6dthHMM3CN6MlYm9GWyUldxtUUX3SV8oUZtdVZrd5KhljRyMVS8YUVmZTZ4JkbqVGZ8EUbxQ5KH5mYyMjU73UdzgDcCdnbEVzR6FXNmpVWGRnVKBVZvlmRtdVVUNDMWNXOwYESu5WRChXaHJzRPZlZ8oGMFx4ZIpHdplkUvUDbL9kdsFzKWJTN7JnavkWSll6blRUQrMEVk5GWyZ5MC3mN6VTQCNjZilzZxZXVjpUM5gEVY5mapdHevw4UENHdrFzZBRXRyBXZiojITJCLiEzQwUTN8EkI0ICSiwCMxIjMxUDO9QTM0IicfJye#4Xfd5nIV34M6IiOiMkIsIyMx8idgMlSgQWYlJHcTJiOi8kI1tlOiQmcQJCLiQjMxUjMwASMxYDMwIDMyIiOiQncDJCLi46bj9yb79WdoNmL9RXaslmYv5mI0IyctRkIsICZ4xEIvNEIn9WayVWZul6ZuVEImAibvlGdjVnc4NnbvNEIvdFIuVHaDJiOiEmTDJCLiQTNxkTM7MDOykjNzMzM4IiOiQWSiwSflNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPnhVM4Z6YDh7Uo3UVz8UTHpVYEFVYvsSWUFWONdGOz9kS7UUSz9mUtlEOiRVNKRUcotyYUxkc8wUeHF4TYBnRaRUMWtkUQhWetRnQZVTe5kmaPRFd8oVVuNqe'
    }
    GC.Spread.Sheets.LicenseKey = (GC.Spread.Excel.IO as any).LicenseKey = LicenseKey
  }

  public ngAfterViewInit() {
    this.spread = new GC.Spread.Sheets.Workbook(document.getElementById("excel"), { calcOnDemand: true });
    var spreadNS = GC.Spread.Sheets;
    this.sheet = this.spread.getActiveSheet()
    this.configure()
    this.excelIo = new GC.Spread.Excel.IO();
    this.getBlob()
  }

  //去掉表格零值设置
  textprointpo() {
    var oldPaint = GC.Spread.Sheets.CellTypes.Text.prototype.paint;
    GC.Spread.Sheets.CellTypes.Text.prototype.paint = function (ctx: any, value: any, x: any, y: any, w: any, h: any, style: any, context: any) {
      if (value === 0) {
        oldPaint.call(this, ctx, "", x, y, w, h, style, context);
      }
      else {
        oldPaint.apply(this, arguments);
      }
    }
  }

  //插入表格
  public getBlob() {
    console.log(this.exceldatail);

    let excl = new GC.Spread.Excel.IO();
    var xhr = new XMLHttpRequest()
    const that = this
    xhr.open('GET', `${window.location.origin}/api/v3/logical_docs/doc/${that.exceldatail.doc_id}/stream`)
    xhr.responseType = 'blob'
    xhr.onload = () => {
      console.log('xhr.response', xhr.response)
      excl.open(xhr.response, (json: any) => {
        that.textprointpo()
        console.log(json)
        this.spread.fromJSON(json);
        this.spread.setActiveSheetIndex(0)
        that.sheet = this.spread.getActiveSheet()
        that.configure()

      }, (err: any) => {
        console.log(err)
      });
    }
    xhr.send()
  }

  public configure() {
    // this.spread.sheets[0].visible(false)
    this.spread.options.scrollbarMaxAlign = true;//滚动条对齐视图中表单的最后一行或一列
    this.spread.options.scrollbarShowMax = true;//基于表单全部的行列总数显示滚动条
    this.spread.options.newTabVisible = false;//去掉表格下面的sheet按钮，不给添加子表单\
    //给所有表单设置表单保护，
    //已知问题，循环设在表单保护时，如果某个子表单被隐藏了，会报文件错误：解决方法：隐藏表单跳过设置限制行为
    for (var i = 0; i < this.spread.sheets.length; i++) {
      console.log(i);
      
      this.spread.sheets[i].options.isProtected = true;
      //问题：不能直接this.spread.sheets[i].options.protectionOptions={}会出现加载3分之一页面的问题滑动以下才会显示正常
      //解决：设置一个变量把this.spread.sheets[i].options.protectionOptions付给变量修改再重新付值回this.spread.sheets[i].options.protectionOptions
      var option = this.spread.sheets[i].options.protectionOptions
      option.allowSelectLockedCells = false
      this.spread.sheets[i].options.protectionOptions = option
    }
  }

}
