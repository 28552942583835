<!--
 * @Author: your name
 * @Date: 2020-05-28 16:44:44
 * @LastEditTime: 2020-06-09 18:43:59
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /junhe/frontend/src/app/modules/check_lists/components/check_lists-list/check_lists-list.component.html
--> 

<!-- <div class="work-packages-split-view--tabletimeline-content">
  <div class="work-packages-tabletimeline--table-side work-package-table--container __hidden_overflow_container">

    <table class="keyboard-accessible-list generic-table work-package-table">
      <thead class="-sticky">
        <th *ngIf="configuration.dragAndDropEnabled" class="wp-table--manual-sort-th -short hide-when-print">
          <div class="generic-table--empty-header">
            <op-icon *ngIf="manualSortEnabled" icon-classes="icon-sort-by"></op-icon>
          </div>
        </th>
        <th class="wp-table--table-header">ID</th>
        <th class="wp-table--table-header">状态</th>
        <th class="wp-table--table-header">主题</th>
        <th class="wp-table--table-header">类型</th>
        <th class="wp-table--table-header">TYPE</th>
        <th class="wp-table--table-header">LOCATION-HK997</th>
        <th class="wp-table--table-header">负责执行者</th>
        <th class="wp-table--table-header">COMPANY-HK997</th>
        <th class="wp-table--table-header">进度</th>
        
      </thead>
    </table>

  </div>
</div> -->

<div class="work-packages-split-view--tabletimeline-content" style="height: 100%;">
  <div class="work-packages-tabletimeline--table-side work-package-table--container __hidden_overflow_container">

    <table class="keyboard-accessible-list generic-table work-package-table">
      <thead class="-sticky">
        <tr style="border-bottom: 1px solid #E7E7E7;"  class="listTitle">
          <th *ngIf="kind_id == 6" class="wp-table--table-header Date" (click)="changeSort()">month</th>
          <th class="wp-table--table-header">ID</th>
          <th class="wp-table--table-header">File ID</th>
          <th>{{ i18n.t("js.check-lists.list-title.status") }}</th>
          <th>
            <!-- <span aria-hidden="true" class="hierarchy-header--icon" tabindex="-1" title="Hide hierarchy mode">
              <op-icon class="op-icon--wrapper" ng-reflect-icon-classes="icon icon-hierarchy">
                <i aria-hidden="true" class="icon icon-hierarchy" ng-reflect-ng-class="icon icon-hierarchy" title=""></i>
              </op-icon>
            </span> -->
            <span class="icon-hierarchy"></span>
            {{ i18n.t("js.check-lists.list-title.theme") }}
          </th>
          <th>{{ i18n.t("js.check-lists.list-title.Type") }}</th>
          <th *ngIf="kind_id != 4 && kind_id != 6">{{ i18n.t("js.check-lists.list-title.Location") }}</th>
          <th *ngIf="kind_id == 2 || kind_id == 3 || kind_id == 4 || kind_id == 7">Inspection Date</th>
          <th>{{ i18n.t("js.check-lists.list-title.creator") }}</th>
          <th>{{ i18n.t("js.check-lists.list-title.createAt") }}</th>
          <th *ngIf="kind_id != 6">{{ i18n.t("js.check-lists.list-title.handler") }}</th>
          <th *ngIf="kind_id != 3 && kind_id != 2 && kind_id != 4 && kind_id != 7  && kind_id != 6">{{ i18n.t("js.check-lists.list-title.Signatures") }}</th>
          <th *ngIf="kind_id != 3 && kind_id != 2 && kind_id != 4 && kind_id != 7  && kind_id != 6">{{ i18n.t("js.check-lists.list-title.Scheduled") }}</th>
          <th *ngIf="kind_id != 3 && kind_id != 2 && kind_id != 4 && kind_id != 7 && kind_id != 3 && kind_id != 2 && kind_id != 6||isUserAdmin">{{ i18n.t("js.check-lists.list-title.Operation") }}</th>
          <th *ngIf="kind_id != 3 && kind_id != 2 && kind_id != 4 && kind_id != 7 && kind_id != 6">{{ i18n.t("js.check-lists.list-title.Progress") }}</th>
        </tr>
      </thead>
      <tbody class="results-tbody work-package--results-tbody" *ngFor="let item of checklistsindexitem">
        <tr tabindex="0" data-work-package-id="303" data-class-identifier="wp-row-303"
          class="wp-table--row wp--row wp-row-303 wp-row-303-table issue __hierarchy-root-303"
          (click)='clicklistitem(item,$event)' (contextmenu)="onRightClick($event,item.id,item.state)">
          <td *ngIf="kind_id == 6">{{item.unique_date}}</td>
       
          <td>{{item.id}}</td>
          <td class="fileRefNo">{{item.file_ref_no}}</td>
          <td>{{item.state== 'Cancel' ? 'Cancelled' : item.state}}</td>
          <td>{{item.name}}</td>
          <td>{{item.type}}</td>
          <td *ngIf="kind_id != 4 && kind_id != 6">{{(kind_id == 2 || kind_id == 3)?item.template_location:item.location}}</td>
          <td *ngIf="kind_id == 2 || kind_id == 3 || kind_id == 4 || kind_id == 7">{{ kind_id == 4 || kind_id == 7?item.inspection_date:item.template_inspection_date}}</td>
          <td>{{item.creator}}</td>
          <td>{{item.created_at}}</td>
          <td *ngIf="kind_id != 6">{{item.assigner_last == null?(item.executor ==null?item.assigner: (item.executor?.firstname + ' ' + item.executor?.lastname)): (
            item.executor_last ==null?item.assigner_last: (item.executor_last?.firstname + ' ' + item.executor_last?.lastname))}}</td>
          <td *ngIf="kind_id != 3 && kind_id != 2 && kind_id != 4 && kind_id != 7 && kind_id != 6 ">
            {{item.sign_count==item.finished_sign_count ? i18n.t("js.check-lists.quest.Signed") :item.sign_count+i18n.t("js.check-lists.quest.Details.Required")}}
          </td>
          <td *ngIf="kind_id != 3 && kind_id != 2 && kind_id != 4 && kind_id != 7 && kind_id != 6 ">{{item.expected_finish_at}}</td>
          <td *ngIf="kind_id != 3 && kind_id != 2 && kind_id != 4 && kind_id != 7 && kind_id != 6 || isUserAdmin" >
            <div *ngIf="kind_id != 3 && kind_id != 2 && kind_id != 4 && kind_id != 7 && kind_id != 6" class="copyItem" (click)="copyList(item,$event)">
              {{ i18n.t("js.check-lists.list-title.Copy") }}
            </div>
            <div *ngIf="isUserAdmin&&item.state!='New'&&item.state!='Closed'" class="copyItem"  (click)="changeUser(item,$event)">
              Change Handler
            </div>
            <div *ngIf="isUserAdmin" class="copyItem"  (click)="changeStatus(item.id,$event)">
              Change Status
            </div>
          </td>
          <td *ngIf="kind_id != 3 && kind_id != 2 && kind_id != 4 && kind_id != 7  && kind_id != 6">
            <span>
              <span style="width: 80px" class="progress-bar">
                <span style="width: 0%" [ngStyle]="{width: (item.finished_item_count/item.item_count)*100+'%'}"
                  class="inner-progress closed"></span>
              </span>
              <span class="progress-bar-legend">{{((item.finished_item_count/item.item_count)*100).toFixed(2)}}%</span>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="dropdown-relative dropdown -overflow-in-view" id="checkListsMenu" style="position: fixed;" [ngStyle]="{'top': itemY,'left': itemX }" [style.left.px]="itemX" [style.top.px]="itemY" >
  <ul class="dropdown-menu" role="menu">
    <li><a class="menu-item copyViewMenuItem" href="#" *ngIf="rightState !== 'New' && kind_id!=6" (click)="resubmit()">
        <i aria-hidden="true" class="icon-action-menu icon-copy" title=""></i>
        <span>{{i18n.t("js.file-ref-no.resubmit")}}</span>
      </a></li>
      <li><a class="menu-item copyViewMenuItem" href="#" *ngIf="rightState == 'New' && kind_id==6" (click)="Cancel()">
        <i aria-hidden="true" class="icon-action-menu icon-copy" title=""></i>
        <span>Cancelled</span>
      </a></li>

      <li><a class="menu-item copyViewMenuItem" *ngIf="kind_id == 2 && rightState == 'New'" href="#" (click)="Cancel()">
        <i aria-hidden="true" class="icon-action-menu icon-copy" title=""></i>
        <span>Cancel</span>
      </a></li>
  </ul>
</div>