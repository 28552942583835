<div class="check-titleTemplate"  id="loading">
  <div class="toolbar-container">
    <div class="toolbar">
      <div class="title-container" style="display: flex;">
        <h2  class="titleName" >Permit to Lift</h2>
      </div>
    </div>
  </div>
</div>
<div _ngcontent-gja-c2="" class="work-packages-split-view" style="height: 100%;"   >
  <div _ngcontent-gja-c2="" class="work-packages-split-view--tabletimeline-side loading-indicator--location" data-indicator-name="table">
    <!-- 控制显示列表或者模板 -->
    <div class="filter" >
<!--      <a >Valid</a>-->
<!--      <span>|</span>-->

<!--      <a >Work in Progress</a>-->
<!--      <span>|</span>-->

<!--      <a >Invalid</a>-->
<!--      <span>|</span>-->

<!--      <a>All</a>-->
    </div>

    <table class="keyboard-accessible-list generic-table work-package-table">
      <thead class="-sticky">
        <th>ID#</th>
        <th>Permit No.</th>
        <th>Plant Type</th>
        <th>People</th>
        <th>Process Status</th>
        <th>Permit Status</th>
        <th>Created At</th>
        <th>Last Modified At</th>

      </thead>
      <tbody>
      <tr *ngFor="let item of list" style="cursor: pointer;" (click)="openNewWindos(item.permitMasterId)">
        <td>{{item.id}}</td>
        <td>{{item.permitNo}}</td>
        <td>{{item.plantType}}</td>
        <td>{{showPeople(item.participants)}}</td>
        <td >{{showCheck(item.approvalFlow)}}</td>
        <td>{{item.permitStatus}}</td>
        <td>{{item.createdAt}}</td>
        <td>{{item.updatedAt}}</td>
      </tr>
      </tbody>
    </table>
    <div id="pagging"></div>
  </div>
</div>
<div class="loaderBg" *ngIf="loading">
  <div class="loader"></div>
</div>

