import { Component, OnInit } from '@angular/core';
import { I18nService } from 'core-app/modules/common/i18n/i18n.service';

@Component({
  templateUrl: './cunstom-forms.component.html',
  styleUrls: ['./cunstom-forms.component.sass']
})
export class CunstomFormsComponent implements OnInit {
  
  public setuodipsla = false

  constructor(
    public i18n: I18nService
  ) { }

  ngOnInit() {
  }

  setupnotify(e:any){
    this.setuodipsla = e
  }

  setupclick(){    
    this.setuodipsla = true
  }

}
