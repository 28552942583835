import { Component, OnInit } from '@angular/core';
import { labour_returnService } from '../../services/labour_return.service';

interface mapItem {
  report_index: number,
  mapping_trade_name: string,
  code_jsonb: number,
  is_show_in_form: boolean,
  version: string,
  edit: boolean,
  id: number
}
@Component({
  selector: 'app-labour_return-map',
  templateUrl: './labour_return-map.component.html',
  styleUrls: ['./labour_return-map.component.sass']
})
export class Labour_returnMapComponent implements OnInit {
  public mapList: Array<mapItem> = []
  public project_id: any
  public editAble: boolean = false
  public itemEdit: mapItem
  public version: string = 'v17'
  public itemSave: any = {
    report_index: -1,
    mapping_trade_name: '',
    code_jsonb: -1,
    is_show_in_form: false,
    index: -1,
  }
  constructor(
    public service: labour_returnService,
  ) {

  }

  ngOnInit() {

    this.project_id = jQuery('meta[name=current_project]').data('projectId')
    this.getList(this.version)
    
  }

  getList(value:any) {
    console.log(value)
    this.version = value
    this.service.getMapList(this.project_id,this.version).subscribe((res: any) => {
      this.mapList = res.list as Array<mapItem>
      if (this.mapList.length == 0) {
        this.service.getMapFromSiteDiary(this.project_id, this.version).subscribe(() => {
          this.service.getMapList(this.project_id,this.version).subscribe((res: any) => {
            this.mapList = res.list as Array<mapItem>
          })
        })
      }

    })
  }
  editItem(item: mapItem, index: number) {
    this.itemEdit = item
    this.itemSave.report_index = item.report_index
    this.itemSave.mapping_trade_name = item.mapping_trade_name
    this.itemSave.code_jsonb = item.code_jsonb
    this.itemSave.is_show_in_form = item.is_show_in_form
    this.itemSave.index = index
    this.editAble = true
  }
  inputIndex(event: any, item: mapItem) {
    console.log(item.report_index)
    let num = event.target.value
    if (num < 0) {
      num = 0
    }
    console.log(num)
    num = parseInt(num)
    console.log(parseInt(num))
    console.log(num, num == NaN)
    if(typeof num !== 'number' || isNaN(num) ) {
      num = 0 
    }
    item.report_index = num
    event.target.value = num

  }
  saveToCancel() {
    this.editAble = false
  }
  cancel() {
    location.reload();
  }
  updateList() {
    this.service.getMapFromSiteDiary(this.project_id, this.version).subscribe(() => {
      this.service.getMapList(this.project_id,this.version).subscribe((res: any) => {
        this.mapList = res.list as Array<mapItem>
      })
    })
  }
  saveList() {
    let saveItem = {
      version: this.version,
      labour_mapping: this.mapList

    }
    this.service.editMap(this.project_id, saveItem).subscribe( (res) => {
          console.log(res)
          alert('保存成功')
    })
  }
  saveItem() {
    this.itemEdit.report_index = this.itemSave.report_index
    this.itemEdit.mapping_trade_name = this.itemSave.mapping_trade_name
    this.itemEdit.code_jsonb = this.itemSave.code_jsonb
    this.itemEdit.is_show_in_form = this.itemSave.is_show_in_form
    const item = this.itemEdit
    const index = this.itemSave.index
    this.editAble = false
    if (index == 0) {
      let newIndex = this.mapList.findIndex((map: mapItem) => {
        return map.report_index > item.report_index
      })
      console.log(newIndex)
      if (newIndex == -1) {
        newIndex = this.mapList.length
      }
      this.mapList.splice(newIndex, 0, item)
      this.mapList.splice(index, 1)
      return
    }
    if (item.report_index == this.mapList[index - 1].report_index || (this.mapList[index + 1] && item.report_index == this.mapList[index + 1].report_index)) {
      return
    } else {
      let newIndex = this.mapList.findIndex((map: mapItem) => {
        return map.report_index > item.report_index
      })
      if (newIndex == -1) {
        newIndex = this.mapList.length
      }

      if (index > newIndex) {
        this.mapList.splice(index, 1)
        this.mapList.splice(newIndex, 0, item)
      } else {
        this.mapList.splice(newIndex, 0, item)
        this.mapList.splice(index, 1)
      }
    }

  }


}
