import { Component, Input, OnInit ,EventEmitter, Output} from '@angular/core';

import { SEDNService } from '../../service/SEDN.service';

@Component({
  selector: 'SEDNImgGroup',
  templateUrl: './SEDNImgGroup.component.html',
  styleUrls: ['./SEDNImgGroup.component.sass']
})
export class SEDNImgGroupComponent implements OnInit {
  @Input() public GroupId:any
  @Input() public type:any
  @Input() public sednId:any
  @Input() public state:any
  @Input() public prepared_right:any
  @Input() public replied_right:any
  @Input() public groupIndex:any
  @Input() public isWriteIn:any
  @Output() public groupEventEmit = new EventEmitter()
  @Output() public lengthNot0Emit = new EventEmitter()

  constructor(public service: SEDNService) { }
  public before_photos:Array<any>
  public after_photos:Array<any>
  public description:any
  public remark:any
  public beforeNum:any
  public afetNum:any
  ngOnInit() {
    this.service.getPicList(this.sednId, this.GroupId).subscribe ( (res:any) => {
      if(res.before_photos.length > 0) {
        this.lengthNot0Emit.emit()
      }
      if(res.before_photos.length < 4) {
        res.before_photos.push({})
      }
      if(res.after_photos.length < 4) {
        res.after_photos.push({})
      }
      this.beforeNum = res.before_photos.length
      this.afetNum = res.after_photos.length
      this.remark = res.remark
      this.description =  res.description
      this.before_photos = res.before_photos
      this.after_photos = res.after_photos
    })
  }
  ImgafterGroupEvnet(e:any, index:any) {
    if(e.type == 'add'){
      this.after_photos[index].id =  e.id
      if(this.afetNum<4){
        this.after_photos.push({})
        this.afetNum++
      } 
    } else if(e.type == 'delete'){
      if(!(this.after_photos.length == 4 && this.after_photos[3].id)) {
        this.after_photos.splice(index, 1)
        this.afetNum--
      } else if(index < 3) {
        this.after_photos.splice(index, 1)
        this.after_photos.push({})
      } else if(index == 3) {
        this.after_photos[index].id = null
      }
   
    }
  }
  ImgbeforeGroupEvnet(e:any, index:any) {

    if(e.type == 'add'){
      if(index == 0) {
        this.groupEventEmit.emit(e.type)
      }
      this.before_photos[index].id  = e.id
      if(this.beforeNum<4){
      this.before_photos.push({})
      this.beforeNum++
      } 
    } else if(e.type == 'delete'){
      console.log(this.before_photos[3])
      if( !(this.before_photos.length == 4 && this.before_photos[3].id)) {
        this.before_photos.splice(index, 1)
        this.beforeNum--
        console.log(this.beforeNum)
        if(this.beforeNum == 1) {
          this.groupEventEmit.emit(e.type)
        }
      } else if(index < 3) {
        this.before_photos.splice(index, 1)
        this.before_photos.push({})
      } else if(index == 3) {
        this.before_photos[index].id = null
      }
   
    }
  }
  changeGroup(type:any) {
    let data = {} as any
    if(type == 'description'){
        data.description = this.description
    }
    if(type == 'remark') {
      data.remark = this.remark
    } 

    this.service.editPhotoGroup(data,this.sednId, this.GroupId).subscribe( (res:any) => {
      console.log(res)
    })
  }
}
