import { Component, ElementRef, Input, OnInit, Output, ViewChild ,EventEmitter, AfterViewChecked} from '@angular/core';

import { input } from 'reactivestates';
import { SEDNService } from '../../service/SEDN.service';

declare let layui: any
@Component({
  selector: 'SEDNIMG',
  templateUrl: './SEDNIMG.component.html',
  styleUrls: ['./SEDNIMG.component.sass']
})
export class SEDNIMGComponent implements OnInit, AfterViewChecked {
  @ViewChild('img', { static: false }) img:ElementRef;
  @ViewChild('upload', { static: false }) upload:ElementRef;
  @Input() public groupId:any
  @Input() public inputImgId:any
  @Input() public InputImg:any
  @Input() public index:any
  @Input() public type:any
  @Input() public sednId:any
  @Input() public state:any
  @Input() public isWriteIn:any
  @Input() public Inputdate:any
  public replied_right:any
  @Input('replied_right') public set _replied_right(value: string){
    this.replied_right = value
    let that = this
    if((this.type == 'before' && this.prepared_right) || (this.state == 'Sent' && this.type == 'after' && this.replied_right)){
      
      console.log(that.upload)
      if(that.upload) {
      layui.use(['upload', 'element', 'layer'], function(){
        var $ = layui.jquery
        ,upload = layui.upload
        ,element = layui.element
        ,layer = layui.layer;

          var uploadInst = upload.render({
            elem: that.upload.nativeElement
            ,url: `/api/v3/projects/${that.projectId}/sedns/${that.sednId}/image_groups/${that.groupId}/upload`,
            accept: 'Images',
            data:{
              longitude:true,
              latitude:true,
              photo_type: that.type
            } //改成您自己的上传接口
            ,acceptMime: 'image/*'
            ,before: function(obj:any){
              that.service.eventBus.emit(true)
              //预读本地文件示例，不支持ie8
              console.log(obj)
              obj.preview(function(index:any, file:any, result:any){
                that.Imgsrc = result
             
              });
              
        
            }
            ,done: function(res:any){
              that.service.eventBus.emit(false)
              that.imgId = res.id
              this.date = res.updated_at
              that.ImgEventEmit.emit({type:'add', id:   that.imgId})
            }
            // ,error: function(){
            //   //演示失败状态，并实现重传
            //   var demoText = $('#demoText');
            //   demoText.html('<span style="color: #FF5722;">上传失败</span> <a class="layui-btn layui-btn-xs demo-reload">重试</a>');
            //   demoText.find('.demo-reload').on('click', function(){
            //     uploadInst.upload();
            //   });
            // }
            // //进度条
            // ,progress: function(n:any, elem:any, e:any){
            //   element.progress('demo', n + '%'); //可配合 layui 进度条元素使用
            //   if(n == 100){
            //     layer.msg('上传完毕', {icon: 1});
            //   }
            // }
          })
          //常规使用 - 普通图片上传
        })
      }
    } else {
        if(!this.Imgsrc && this.type == 'after' ){
          this.Imgsrc = !this.Imgsrc
          setTimeout(() => {
            if(this.InputImg){
            this.Imgsrc = `/api/v3/logical_docs/doc/${this.InputImg}/stream?image_size=thumbnail`
            } else {
              this.Imgsrc = !this.Imgsrc
            }
          }, 0);
        }
      
    }
  }
  @Input() public prepared_right:any
  @Input() public beforePhotoGroup:any
  @Output() public ImgEventEmit = new EventEmitter()
  public projectId:any = document.getElementsByName('current_project')[0].dataset.projectId
  public date:any
  constructor(public service: SEDNService) { }
  public Imgsrc:any
  public ImgShowSrc:any
  public imgId:any
  ngOnInit() {

    if(this.InputImg){
      console.log('reset')
      this.imgId = this.inputImgId
      this.Imgsrc =location.origin+ `/api/v3/logical_docs/doc/${this.InputImg}/stream.jpg?image_size=thumbnail` 
      this.ImgShowSrc =location.origin+ `/api/v3/logical_docs/doc/${this.InputImg}/stream.jpg?image_size=original` 
      this.date = this.Inputdate

    }
  
  }
  showImg() {
    this.service.photoEvent.emit(this.ImgShowSrc)
  }
  ngAfterViewInit(){
    console.log('aaa')
    let that =  this
    console.log(this.img)
    console.log(this.upload)
    if(!this.upload){
      return
    }
      if(this.inputImgId) {
        return
      }
      if((this.type == 'before' && this.prepared_right) || (this.state == 'Sent' && this.type == 'after' && this.replied_right)){

        layui.use(['upload', 'element', 'layer'], function(){
          var $ = layui.jquery
          ,upload = layui.upload
          ,element = layui.element
          ,layer = layui.layer;
          
          //常规使用 - 普通图片上传
          var uploadInst = upload.render({
            elem: that.upload.nativeElement
            ,url: `/api/v3/projects/${that.projectId}/sedns/${that.sednId}/image_groups/${that.groupId}/upload`,
            accept: 'Images',
            data:{
              longitude:true,
              latitude:true,
              photo_type: that.type
            } //改成您自己的上传接口
            ,acceptMime: 'image/*'
            ,before: function(obj:any){
              that.service.eventBus.emit(true)
              //预读本地文件示例，不支持ie8
              console.log(obj)
              obj.preview(function(index:any, file:any, result:any){
                that.Imgsrc = result
             
              });
              
        
            }
            ,done: function(res:any){
              that.service.eventBus.emit(false)
              that.imgId = res.id
              this.date = res.updated_at
              that.ImgEventEmit.emit({type:'add', id:   that.imgId})
            }
            // ,error: function(){
            //   //演示失败状态，并实现重传
            //   var demoText = $('#demoText');
            //   demoText.html('<span style="color: #FF5722;">上传失败</span> <a class="layui-btn layui-btn-xs demo-reload">重试</a>');
            //   demoText.find('.demo-reload').on('click', function(){
            //     uploadInst.upload();
            //   });
            // }
            // //进度条
            // ,progress: function(n:any, elem:any, e:any){
            //   element.progress('demo', n + '%'); //可配合 layui 进度条元素使用
            //   if(n == 100){
            //     layer.msg('上传完毕', {icon: 1});
            //   }
            // }
          })
        })
      }

  }
  ngAfterViewChecked() {
   
  }
  deleteImg() {
    console.log(this.img)
    this.service.deleteImg(this.sednId, this.groupId ,this.imgId).subscribe( (res) => {
      this.Imgsrc = null
      let that =  this
      that.ImgEventEmit.emit({type: 'delete'})
  
        layui.use(['upload', 'element', 'layer'], function(){
          var $ = layui.jquery
          ,upload = layui.upload
          ,element = layui.element
          ,layer = layui.layer;
         if(!that.upload){
          return
         } 
          //常规使用 - 普通图片上传
          var uploadInst = upload.render({
            elem: that.upload.nativeElement
            ,url: `/api/v3/projects/${that.projectId}/sedns/${that.sednId}/image_groups/${that.groupId}/upload`,
            accept: 'Images',
            acceptMime: 'image/*'
            ,data:{
              longitude:true,
              latitude:true,
              photo_type: that.type
            } //改成您自己的上传接口
            ,before: function(obj:any){
              //预读本地文件示例，不支持ie8
              that.service.eventBus.emit(true)
              console.log(this.img)
              console.log(obj)
              obj.preview(function(index:any, file:any, result:any){
                console.log()
                that.Imgsrc = result
       
              });
              
        
            }
            ,done: function(res:any){
              //如果上传失败
              that.imgId = res.id
              that.ImgEventEmit.emit({type:'add', id:   that.imgId})
              that.service.eventBus.emit(false)
            }
            // ,error: function(){
            //   //演示失败状态，并实现重传
            //   var demoText = $('#demoText');
            //   demoText.html('<span style="color: #FF5722;">上传失败</span> <a class="layui-btn layui-btn-xs demo-reload">重试</a>');
            //   demoText.find('.demo-reload').on('click', function(){
            //     uploadInst.upload();
            //   });
            // }
            // //进度条
            // ,progress: function(n:any, elem:any, e:any){
            //   element.progress('demo', n + '%'); //可配合 layui 进度条元素使用
            //   if(n == 100){
            //     layer.msg('上传完毕', {icon: 1});
            //   }
            // }
          })
        })
    })
  }
}
