import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from "@angular/core";
import { I18nService } from "core-app/modules/common/i18n/i18n.service";
import { E_liftingService } from "../../services/e_lifting.service";
import { fromEvent } from "rxjs";

@Component({
  selector: "e_lifting-view",
  templateUrl: "./e_lifting-view.component.html",
  styleUrls: ["./e_lifting-view.component.sass"],
})
export class E_liftingViewComponent implements OnInit {
  @Output() hideView = new EventEmitter();
  @Input() viewApplicationId: any;
  @Input() roleName: any;
  public showLoading: boolean = false;
  public saveBaseMessage: any = {};
  public labourArr: any = [];
  public materialArr: any = [];
  public checkedArr: any = [];
  public showMantle: boolean = false;
  public operatorArr: any = [];
  public banksmanArr: any = [];
  public riggerArr: any = [];
  public gearArr: any = [];
  public craneArr: any = [];
  public sectionArr: any = [];
  public comment: any;
  public saveIndex: any;
  public host: any = window.location.origin;
  public decideToSign: boolean = false;
  public inspectorAddress: any;
  public cancellationAddress: any;
  public finalAddress: any;
  public saveType: any;
  public rejectRemark: any;
  public cancelRemark: any;
  public finalRemark: any;
  public canSign: boolean = true;
  public applicantFlag: boolean = false;
  public approverFlag: boolean = false;
  public lang = false
  constructor(public i18n: I18nService, public service: E_liftingService) {}

  ngOnInit() {
    let lang = document.getElementsByTagName('html')[0].getAttribute('lang')
    if(lang === 'en'){
      this.lang = false
    } else {
      this.lang = true
    }
    if (this.roleName == "isApplicant") {
      this.applicantFlag = true;
    } else if (this.roleName == "isApprover") {
      this.approverFlag = true;
    } else {
      this.applicantFlag = true;
      this.approverFlag = true;
    }
    //获取审核的列表
    this.getSection();
    this.getAllMessage(this.viewApplicationId);
  }
  ngOnChanges(changes: SimpleChanges): void {
    fromEvent(window, "scroll").subscribe((event) => {
      if (document.documentElement.scrollTop != 0) {
        jQuery("#fixdiv").css("top", "5px");
      } else {
        jQuery("#fixdiv").css("top", "55px");
      }
    });
  }
  hiddenFixDiv() {
    jQuery("#fixdiv").css("display", "none");
    jQuery("#liftingcontent").css("padding-top", "0");
  }
  showFixDiv() {
    jQuery("#liftingcontent").css("padding-top", "155px");
    jQuery("#fixdiv").css("display", "block");
  }

  getAllMessage(e: any) {
    this.showLoading = true;
    //获取基本信息
    this.service.viewApplicationBaseMessage(e).subscribe(async (res: any) => {
      this.saveBaseMessage = res;

      //获取设备信息
      await this.getLabour("Operator");
      await this.getLabour("Rigger");
      await this.getLabour("Banksman");
      //获取人员信息
      await this.getPlant("LiftingGear");
      await this.getPlant("LiftingCrane");

      //获取材料项
      await this.getMaterial();
      //获取检查项
      await this.getChecks();
      this.showLoading = false;
    });
  }
  getSection() {
    this.service.viewSection(this.viewApplicationId).subscribe((res: any) => {
      this.sectionArr = res.sections.sort(this.compare("no"));
      this.getStatus();
    });
  }
  //对数组的no进行排序
  compare(property: any) {
    return function (obj1: any, obj2: any) {
      var value1 = obj1[property];
      var value2 = obj2[property];
      return value1 - value2; // 升序
    };
  }
  getLabour(e: any) {
    return new Promise((resolve, rejected) => {
      this.service.viewApplicationLabour(this.viewApplicationId, e).subscribe((res: any) => {
        switch (e) {
          case "Operator":
            this.operatorArr = res.list;
            break;
          case "Rigger":
            this.riggerArr = res.list;
            break;
          case "Banksman":
            this.banksmanArr = res.list;
            break;
        }
        resolve();
      });
    });
  }
  getPlant(e: any) {
    return new Promise((resolve, rejected) => {
      this.service.viewApplicationPlant(this.viewApplicationId, e).subscribe((res: any) => {
        switch (e) {
          case "LiftingGear":
            this.gearArr = res.list;
            break;
          case "LiftingCrane":
            this.craneArr = res.list;
            break;
        }
        resolve();
      });
    });
  }
  getMaterial() {
    return new Promise((resolve, rejected) => {
      this.service.getMaterialsList(this.viewApplicationId).subscribe((res: any) => {
        this.materialArr = res.list;
        resolve();
      });
    });
  }
  //获取检查项列表
  getChecks() {
    return new Promise((resolve, reject) => {
      this.service.getChecklistItem(this.viewApplicationId).subscribe((res: any) => {
        this.checkedArr = res.list;
        resolve();
      });
    });
  }
  //获取审核状态
  getStatus() {
    this.service.updateApplication(this.viewApplicationId, "").subscribe((res: any) => {
      this.saveBaseMessage = res;
    });
  }
  //同意这个章节
  approveSection(e: any) {
    this.sectionArr[e] = {
      no: Number(e) + 1,
      state: "approved",
      comment: this.comment
    }
    this.comment =undefined
  }

  //添加评论
  addComment(e: any) {
    this.hiddenFixDiv();
    this.showMantle = true;
    this.saveIndex = Number(e);
  }
  //确认添加评论
  confirmComment() {
    this.showLoading = true;
    let data = {
      no: this.sectionArr[this.saveIndex].no,
      state: "rejected",
      comment: this.comment,
    };
    this.sectionArr[this.saveIndex] = data;
    this.showMantle = false;
    this.showLoading = false;
    this.comment = undefined;
    this.showFixDiv();
  }
  changType(e: any) {
    if (this.canSign) {
      alert("已選擇使用默認簽名，無須再籤");
      return;
    }
    this.saveType = e;
    this.decideToSign = true;
    this.hiddenFixDiv();
  }
  closebtn() {
    this.showFixDiv();
    this.showMantle = false;
  }
  //添加签名
  closeView(img: any) {
    if (!img) {
      this.showFixDiv();
      this.decideToSign = false;
      return;
    }
    this.showLoading = true;
    let blob = this.dataURLtoBlob(img);
    var files = new File([blob], "drawComplete.png", { type: blob.type });
    let file = new FormData();
    file.append("file", files);
    file.append("prefix", "e-lifting");
    this.service.update_img(file).subscribe((res: any) => {
      let remarks;
      if (this.saveType == "inspector") {
        remarks = this.rejectRemark ? this.rejectRemark : "用戶沒有填寫備註";
      } else if (this.saveType == "cancellation") {
        remarks = this.cancelRemark ? this.cancelRemark : "用戶沒有填寫備註";
      } else if (this.saveType == "final") {
        remarks = this.finalRemark ? this.finalRemark : "用戶沒有填寫備註";
      }
      this.service
        .updateSign(this.viewApplicationId, { type: this.saveType, signature: { doc_id: res.doc_id, remarks } })
        .subscribe((res: any) => {
          this.service.viewApplicationBaseMessage(this.viewApplicationId).subscribe(async (res: any) => {
            this.saveBaseMessage = res;
            this.decideToSign = false;
            this.showLoading = false;
            this.showFixDiv();
          });
        });
    });
  }
  dataURLtoBlob(dataurl: any) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }
  useDefault(e: any) {
    this.canSign = e;
  }

  cancel() {
    if (confirm("是否确实要离开预览E-lifting？")) {
      this.hideView.emit();
    }
  }
  async agreeApplication() {
    if (this.sectionArr.length) {
      await this.updateRemark(true);
    }
    this.service.approveApplication(this.viewApplicationId, "").subscribe((res: any) => {
      this.reset();
    });
  }
  //更新拒绝备注
  updateRemark(e: any) {
    return new Promise((resolve, reject) => {
      for (const item of this.sectionArr) {
        if (e) {
          if(item.state=='rejected'){
            item.state=='initial'
          }
        }
        this.service.updateSection(this.viewApplicationId, { section: { ...item } }).subscribe(async (res: any) => {});
      }
      resolve();
    });
  }
  async rejectApplication() {
    if (this.sectionArr.length) {
      await this.updateRemark(false);
    }
    this.sectionArr = [];
    this.service.rejectApplication(this.viewApplicationId, "").subscribe((res: any) => {
      this.reset();
    });
  }
  requestTerminated() {
    this.service.requestTerminateApplication(this.viewApplicationId, "").subscribe((res: any) => {
      this.reset();
    });
  }
  terminated() {
    this.service.terminateApplication(this.viewApplicationId, "").subscribe((res: any) => {
      this.reset();
    });
  }
  reset() {
    this.hideView.emit();
  }
}
