<div>
    <div class="header" *ngIf="detail">
        <div>
            <a (click)="back()">
              Progress Photo
            </a> /
            <a>
              {{detail.title}}
            </a>
        </div>
        <div class="title">
            <h2>{{detail.title}}</h2>
            <p>{{detail.id}}</p>
        </div>
        <div class="createDeail">
            <div class="Date">
                <span>
                  Date
                </span>
                <span>{{detail.date}}</span>
            </div>
            <div class="createAt">
                <span>Created Date</span>
                <span>{{detail.created_at}}</span>
            </div>
            <div class="createBy">
                <span>
                  Created By 
                </span>
                <span>
                  {{detail.user_id}}
                </span>
            </div>
        </div>
    </div>
    <div class="case">
        <div class="content">
            <h2>Selected Photos</h2>
            <div *ngFor="let pagedate of items" class="subcontent">
                <span>{{pagedate.page_date}}</span>
                <div class="showpic">
                    <ul class="picul">
                        <li *ngFor="let item of pagedate.data" (click)="toDeatil(item.id,item.user_id,item.image_url)">
                            <!--检测状态是否已经完成-->
                            <div class="spot" [ngStyle]="{'background-color': item.state.name=='已完成'?'#f26269':'#00d277'}" *ngIf="item.purpose.value==1"></div>
                            <!--检测是否点击了选择按钮-->
                            <div class="confirmspot" *ngIf="isselect"></div>
                            <!--检测当前选择了哪些照片-->
                            <!--对该照片信息的显示-->
                            <img [src]="item.image_url+'?image_size=thumbnail'" class="img">
                            <span>{{ i18n.t("js.photo-log.photographer") }}:{{item.user_name}}</span>
                            <span>{{item.purpose.name}}</span>
                            <span style="margin-bottom: 5px;" *ngIf="item.purpose.value==1">{{ i18n.t("js.photo-log.status") }}:{{item.state.name}}</span>
                            <span *ngIf="item.state.name=='已完成'">{{ i18n.t("js.photo-log.finishtime") }}:</span>
                            <span *ngIf="item.state.name=='已完成'">{{item.complete_at}}</span>
                        </li>
                    </ul>

                </div>
                <div class="paging" *ngIf="!(showDetail||multitoshowEdit)">
                    <ul>
                        <li (click)="prepage()">
                            <</li>
                                <li class="pagenum" *ngFor="let item of pageSizenum" (click)="toMushpage(item)" [ngStyle]="{'color': item==savenowPage?'#fff':'#000','background-color':item==savenowPage?'#ff8645':'#fff'}">{{item}}</li>
                                <li (click)="nextpage()">></li>
                                <li>{{ i18n.t("js.photo-log.total") }} <span style="margin: 0 5px;">{{pageCount}}</span> {{ i18n.t("js.photo-log.page") }}</li>
                    </ul>
                </div>
            </div>
            <photo_log-detail *ngIf="showDetail" class="detailcontent" [hideEditbtn]='hideEditbtn' [saveitemId]='saveitemId' (hideDetailpage)="tohideDetail()" (showEditpage)="toshowEditpage($event)"></photo_log-detail>

            <photo_log-edit [saveitemId]="saveitemId" *ngIf="multitoshowEdit" class="editcontent" [getsingledata]="getsingledata" [saveimg]="saveimg" (hideEditpage)="tohideEditpage()" (goRefresh)="torefresh()"></photo_log-edit>
        </div>
    </div>
</div>
<div class="loading-indicator loadingposition" *ngIf="showloading">
    <div class="block-1"></div>
    <div class="block-2"></div>
    <div class="block-3"></div>
    <div class="block-4"></div>
    <div class="block-5"></div>
</div>