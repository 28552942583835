<div class="toolbar-container">

  <div class="dataContent">
    <!-- 日期 -->

    <!-- 选项跳转 -->
    <div class="seachBut">
      <div class="searchButlist">
          <button data-typedata="newRisc" class="SiteDiary buttonMargin"><img [src]="imgdata('RISC.png')"/>
            <div class="title"><span class="titleFont">iCWP</span><span>RISC</span></div>
            </button>
          <button data-typedata="Site Diary" class="SiteDiary buttonMargin"><img [src]="imgdata('RISC.png')"/>
            <div class="title"><span class="titleFont">iCWP</span><span>Site Diary</span></div>
           </button>
          <button data-typedata="Safety Inspection" class="SiteDiary buttonMargin"><img [src]="imgdata('RISC.png')"/>
            <div class="title"><span class="titleFont">iCWP</span><span>Safety Inspection</span></div>
            </button>
          <button data-typedata="newCleansing" class="SiteDiary buttonMargin"><img [src]="imgdata('RISC.png')"/>
            <div class="title"><span class="titleFont">iCWP</span><span>Cleansing</span></div>
            </button>
          <button data-typedata="Labour Return" class="SiteDiary buttonMargin"><img [src]="imgdata('RISC.png')"/>
            <div class="title"><span class="titleFont">iCWP</span><span>Labour Return</span></div>
           </button>
          <div style="display: inline-block;
          width: 100%;"></div>
          <button data-typedata="risc" class="adds buttonMargin"><img [src]="imgdata('RISC.png')"/>RISC（Work）</button>
          <button data-typedata="risc(Survey)" class="SiteDiary buttonMargin"><img [src]="imgdata('RISC.png')"/>RISC（Survey）</button>
          <button data-typedata="site_diary" class="SiteDiary buttonMargin"><img [src]="imgdata('RISC.png')"/>Site Diary</button>
          <button data-typedata="cleansing" class="SiteDiary buttonMargin"><img [src]="imgdata('RISC.png')"/>Daily Cleansing</button>
          <button data-typedata="tyding" class="SiteDiary buttonMargin"><img [src]="imgdata('RISC.png')"/>Weekly Tidying</button>
          <button data-typedata="weekly_env" class="SiteDiary buttonMargin"><img [src]="imgdata('RISC.png')"/>Weekly Env Walk</button>
          <!-- <button data-typedata="weekly_safety" class="SiteDiary"><img [src]="imgdata('RISC.png')"/>Weekly Safety Walk Inspection</button> -->
          <button data-typedata="weekly_safety" class="SiteDiary buttonMargin"><img [src]="imgdata('RISC.png')"/>Weekly Safety Walk </button>
          <button data-typedata="SEDN" class="SiteDiary buttonMargin"><img [src]="imgdata('RISC.png')"/>SEDN</button>
          <button data-typedata="v17" class="SiteDiary buttonMargin"><img [src]="imgdata('RISC.png')"/>Labour Return 2017</button>
          <button data-typedata="v03" class="SiteDiary buttonMargin"><img [src]="imgdata('RISC.png')"/>Labour Return 2003</button></div>
      <!-- <button data-typedata="SiteDiary"><img [src]="imgdata('Site Diary.png')"/>Site Diary</button>
      <!-- <button data-typedata="safety"><img [src]="imgdata('Safety.png')"/>Daily Cleansing</button>
      <button data-typedata="cleansing"><img [src]="imgdata('Cleaning.png')"/>Weekly Tidying</button>
      <button data-typedata=""><img [src]="imgdata('Labour.png')"/>Labour</button> -->
      <div class="dataListWidth">
        <div class="dataName">{{newCheckList.includes(text)?'By Quarter':'By Period'}}</div>
        <div style="display: flex; align-items:center">
        <div class="arrow">
          -->
        </div>
        <div  [ngStyle]="{'display': !newCheckList.includes(text)?'block':'none'}">

          <div class="DateCheck" [ngStyle]="{'display': labour.includes(text)?'none':'block'}">
            <div id="DateCheckBorder" >
              <input id="startTime" style="padding: 0 20px" [(ngModel)]="dateBefore" />
              <input id="endTime" style="padding: 0 20px" [(ngModel)]="dateNow"/>
              <div class="img">
                <img [src]="imgdata('date.jpg')" alt="" />
              </div>
            </div>
          </div>
          <div class="DateCheck" [ngStyle]="{'display': labour.includes(text)?'block':'none'}">
            <div id="MonthCheckBorder" >
              <input id="monthstartTime" [(ngModel)]="monthBefore" />
              <input id="monthendTime" [(ngModel)]="monthNow"/>
              <div class="img">
                <img [src]="imgdata('date.jpg')"/>
              </div>
            </div>
          </div>


        </div>

        <div [ngStyle]="{'display': newCheckList.includes(text)?'block':'none'}"  class="DateCheck" style="width:200px">
          <div id="DateCheckBorder1" >
            <input type="text" class="layui-input" id="quarter" placeholder="yyyy-MM" [(ngModel)]="quarterName" (click)="showDialog()">
             <div class="img">
              <img [src]="imgdata('date.jpg')"/>
            </div>
            <div class="dataSelect" *ngIf="showTimeFalg">
              <div class="layui-laydate-header">
                <i class="layui-icon laydate-icon laydate-prev-y" (click)="changeYear('reduce')"></i>
                <div class="laydate-set-ym">
                  <span lay-ym="2022-12" lay-type="year">{{FullYear}}年</span>
                </div>
                <i class="layui-icon laydate-icon laydate-next-y" (click)="changeYear('add')"></i>
              </div>
              <div class="layui-laydate-content">
                <div class="dataList" *ngFor="let item of quarter;" (click)="checkDate(item,'view')">
                  <div class="dataItemRight" >{{item.quarterName}}</div>
                  <div class="dataItemLeft" [innerHTML]="item.name" [ngClass]="{ checkItem:quarterName.indexOf(item.quarterName)!=-1}">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
    <!-- 数据展示 -->
    <div *ngIf="!showTable">
        <div >
          <div class="data-entry" *ngIf="showRow1.includes(text)">
            <div class="entry-risc">
              <div class="entry-row-1">
                <span class="entry-title">Total RISC Except Cancel & Resubmission </span>
                <span class="entry-type-color"></span>
              </div>

              <span class="entry-num" *ngIf="dateEntry.risc_all">{{dateEntry.risc_all.total}}</span>
              <div class="entry_late">
                <b>
                  with {{dateEntry.risc_late_submission.total}} Late Submission/Urgent
                </b>
              </div>
            </div>
            <div class="entry-risc">
              <div class="entry-box_new">
                <div class="entry-row-1">
                  <span class="entry-title">Passed </span>
                  <span class="entry-type-color passed"></span>
                </div>
              </div>
              <span class="entry-num" *ngIf="dateEntry.risc_passed">{{dateEntry.risc_passed.total}}</span>
            </div>
            <div class="entry-risc">
              <div class="entry-box_new">
                <div class="entry-row-1">
                  <span class="entry-title">Failed </span>
                  <span class="entry-type-color failed"></span>
                </div>
              </div>
                <span class="entry-num" *ngIf="dateEntry.risc_failed">{{dateEntry.risc_failed.total}}</span>
            </div>
            <div class="entry-risc">
              <div class="entry-box_new">
                <div class="entry-row-1">
                  <span class="entry-title">Inspection: In Progress</span>
                  <span class="entry-type-color blank"></span>
                </div>
                <div class="entry-title entry-detail">(Blank excluding "Not Ready")</div>
              </div>
                <span class="entry-num" *ngIf="dateEntry.risc_blank">{{dateEntry.risc_blank.total}}</span>
            </div>
            <div class="entry-risc">
              <div class="entry-box_new">
                <div class="entry-row-1">
                  <span class="entry-title">Inspection: Not Ready</span>
                  <span class="entry-type-color NotReady"></span>
                </div>
                <div class="entry-title entry-detail">(Blank with "Not Ready")</div>
              </div>
                <span class="entry-num" *ngIf="dateEntry.risc_not_ready">{{dateEntry.risc_not_ready.total}}</span>
            </div>
          </div>
          <div class="data-entry checklist" *ngIf="text == 'site_diary' ||checkList.includes(text) ">
            <div class="entry-risc">
              <div class="entry-row-1">
                <span class="entry-title">Total</span>
                <span class="entry-type-color"></span>
              </div>
              <span class="entry-num" *ngIf="dateEntry.total !== null"> {{dateEntry.total}}</span>
            </div>
            <div class="entry-risc">
              <div class="entry-row-1">
                <span class="entry-title">Status: Completed</span>
                <span class="entry-type-color passed"></span>
              </div>
              <div>
                <div>
                  <span class="entry-num" *ngIf="text == 'site_diary' && dateEntry.sd_completed !== null"  >{{dateEntry.sd_completed}}</span>
                  <span class="entry-num" *ngIf="checkList.includes(text)  && dateEntry.total_completed !== null"  >{{dateEntry.total_completed}}</span>
                  <span class="siteDiaryTip" *ngIf="text == 'site_diary'">-supervisor_signed</span>
                </div>
              </div>
            </div>

            <div class="entry-risc">
              <div class="entry-row-1">
                <span class="entry-title">Status: Incompleted</span>
                <span class="entry-type-color failed"></span>
              </div>
              <div>

                <span class="entry-num" *ngIf="text == 'site_diary' && dateEntry.sd_incompleted !== null"  >{{dateEntry.sd_incompleted}}</span>
                <span class="entry-num" *ngIf="checkList.includes(text)  && dateEntry.total_incompleted !== null"  >{{dateEntry.total_incompleted}}</span>
                <div class="siteDiaryTip"  *ngIf="text == 'site_diary'">
                  <div>-contractor_signed</div>
                  <div>SIOW signed</div>
                  <div>-Rejected</div>
                  <div>-Draft</div>
                </div>
              </div>
            </div>
            <div class="entry-risc" *ngIf="text == 'site_diary'">
              <div class="entry-box">
              <div class="entry-row-1">
                <span class="entry-titleNew">Overdue by Contractor</span>
              </div>
            </div>
              <span class="entry-numNew" *ngIf="dateEntry.total_overdue_cr !== null"> {{dateEntry.total_overdue_cr}}</span>
            </div>
            <div class="entry-risc" *ngIf="text == 'site_diary'">
              <div class="entry-box">
              <div class="entry-row-1">
                <span class="entry-titleNew">Overdue by Supervisor</span>
              </div>
            </div>
              <span class="entry-numNew" *ngIf="dateEntry.total_overdue_sup !== null"> {{dateEntry.total_overdue_sup}}</span>
            </div>

            <div class="entry-risc" *ngIf="text == 'cleansing'|| text == 'tyding'">
              <div class="entry-box">
              <div class="entry-row-1">
                <span class="entry-titleNew">Non-Compliance</span>
              </div>
            </div>
              <span class="entry-numNew" *ngIf="dateEntry.non_compliance !== null"> {{dateEntry.non_compliance}}</span>
            </div>
            <div class="entry-risc" *ngIf="text == 'cleansing'|| text == 'tyding'">
              <div class="entry-box">
              <div class="entry-row-1">
                <span class="entry-titleNew">Average Elapsed Time(in days)</span>
              </div>
            </div>
              <span class="entry-numNew" *ngIf="dateEntry.average_elapsed_time !== null"> {{dateEntry.average_elapsed_time}}</span>
            </div>

            <div class="entry-risc" *ngIf="text == 'weekly_env' || text == 'weekly_safety'">
              <div class="entry-box">
              <div class="entry-row-1">
                <span class="entry-titleNew entry2">
                  Non-Compliance
                  <span>
                </span>
              </span>
              </div>
              </div>
              <span class="entry-numNew" >{{dateEntry.non_compliance}}</span>
            </div>
            <div class="entry-risc" *ngIf="text == 'weekly_env' || text == 'weekly_safety'">
              <div class="entry-box">
              <div class="entry-row-1">
                <span class="entry-titleNew entry2">
                  Average Elapsed Time (in days)
                  <span>
                </span>
              </span>
              </div>
            </div>
              <span class="entry-numNew">{{dateEntry.average_elapsed_time}}</span>
            </div>
            <div class="entry-risc" *ngIf="text == 'weekly_env' || text == 'weekly_safety'">
              <div class="entry-box">
              <div class="entry-row-1">
                <span class="entry-titleNew entry2">
                  Late Rectification
                  <span>
                </span>
              </span>
              </div>
            </div>

              <span class="entry-numNew" >{{dateEntry.late_rectification}}</span>
            </div>

          </div>
          <div class="data-entry middle" *ngIf="text == 'SEDN'">
            <div class="entry-risc">
              <div class="entry-row-1">
                <span class="entry-title">Total SEDN</span>
                <span class="entry-type-color"></span>
              </div>
              <span class="entry-num" *ngIf="dateEntry.total !== null"> {{dateEntry.total}}</span>
            </div>
            <div class="entry-risc">
              <div class="entry-row-1">
                <span class="entry-title">Status: Closed</span>
                <span class="entry-type-color NotReady"></span>
              </div>
              <div>
                <div>

                  <span class="entry-num"  >{{dateEntry.total_closed}}</span>
                </div>
              </div>
            </div>
            <div class="entry-risc">
              <div class="entry-row-1">
                <span class="entry-title">Status: Completed</span>
                <span class="entry-type-color passed"></span>
              </div>
              <div>

                <span class="entry-num"  >{{dateEntry.total_completed}}</span>
              </div>
            </div>
            <div class="entry-risc">
              <div class="entry-row-1">
                <span class="entry-title">Status: Sent</span>
                <span class="entry-type-color blank"></span>
              </div>
              <div>

                <span class="entry-num"  >{{dateEntry.total_sent}}</span>
              </div>
            </div>
            <div class="entry-risc">
              <div class="entry-row-1">
                <span class="entry-title">Status: New</span>
                <span class="entry-type-color failed"></span>
              </div>
              <div>

                <span class="entry-num"  >{{dateEntry.total_new}}</span>
              </div>
            </div>
          </div>
          <div class="data-entry checklist" *ngIf="labour.includes(text) ">
            <div class="entry-risc">
              <div class="entry-row-1">
                <span class="entry-title">Total</span>
                <span class="entry-type-color"></span>
              </div>
              <span class="entry-num" *ngIf="dateEntry.total !== null"> {{dateEntry.total}}</span>
            </div>
          </div>
        </div>
        <div class="data-entry" *ngIf="showRow1.includes(text)">
          <ng-container >
            <div class="entry-risc none" style="visibility: hidden;">

            </div>
            <div class="entry-risc">
              <div class="entry-box">
                <div class="entry-row-1">
                <span class="entry-title entry2">
                  Form Status:
                  <span>
                  Submitted
                </span>
              </span>
                </div>
                <div class="entry-title entry-detail">(Submitted, Delegated, Inspecting)</div>
              </div>

              <span class="entry-num" *ngIf="dateEntry.risc_form_submitted">{{dateEntry.risc_form_submitted.total}}</span>
            </div>
            <div class="entry-risc">
              <div class="entry-box">
                <div class="entry-row-1">
                <span class="entry-title entry2">
                  Form Status:
                  <span>
                  Inspected
                </span>
              </span>
                </div>
                <div class="entry-title entry-detail">(Inspected,RE/LOW/LS Signed, RE/SLS Counter Signed)</div>
              </div>
              <span class="entry-num" *ngIf="dateEntry.risc_all">{{dateEntry.risc_form_inspected.total}}</span>
            </div>
            <div class="entry-risc">
              <div class="entry-box">
                <div class="entry-row-1">
                <span class="entry-title entry2">
                  Form Status:
                  <span>
                  Endorsed
                </span>
              </span>
                </div>
                <div class="entry-title entry-detail">(Closed widh approval, Closed with disapproval)</div>
              </div>
              <span class="entry-num" *ngIf="dateEntry.risc_form_endorsed">{{dateEntry.risc_form_endorsed.total}}</span>
            </div>
            <div class="entry-risc">
              <div class="entry-box">
                <div class="entry-row-1">
                <span class="entry-title entry2">
                  Form Status:
                  <span>
                  N/A
                </span>
              </span>
                </div>
                <div class="entry-title entry-detail">(New)</div>
              </div>
              <span class="entry-num" *ngIf="dateEntry.risc_form_n_a">{{dateEntry.risc_form_n_a.total}}</span>
            </div>
          </ng-container>
          </div>
          <div class="data-entry" *ngIf="showRow1.includes(text)">
            <ng-container >
              <div class="entry-risc " >
                <div class="entry-box">
                <div class="entry-row-1">
                  <span class="entry-titleNew entry2">
                    Non-Conformance
                    <span>
                  </span>
                </span>
                </div>
                <div class=" entry-detailNew">Issued by Contractor</div>
                <div class=" entry-detailNew">LATER THAN</div>
                <div class=" entry-detailNew">Actual Inspection/check</div>
                </div>
                <span class="entry-numNew entry_export" *ngIf="dateEntry.risc_non_conformance"><a class="entry_export_a" (click)="exportExcel()">export</a>{{dateEntry.risc_non_conformance.total}}</span>
              </div>
              <div class="entry-risc">
                <div class="entry-box">
                <div class="entry-row-1">
                  <span class="entry-titleNew entry2">
                    Urgent
                    <span>
                  </span>
                </span>
                </div>
                <div class=" entry-detailNew">Issued by the Contractor</div>
                <div class=" entry-detailNew">WITHIN 24hrs from</div>
                <div class=" entry-detailNew">Actual Inspedction/check</div>
                </div>
                <span class="entry-numNew" *ngIf="dateEntry.risc_urgent">{{dateEntry.risc_urgent.total}}</span>
              </div>
              <div class="entry-risc">
                <div class="entry-box">
                <div class="entry-row-1">
                  <span class="entry-titleNew entry2">
                    Average Elapsed Time
                    <span>
                  </span>
                </span>
                </div>
                <div class="entry-detailNew"> Submited to</div>
                <div class="entry-detailNew"> Closed with Approval /</div>
                <div class="entry-detailNew"> Disapproval</div>
                </div>
                <span class="entry-numNew" *ngIf="dateEntry.risc_average_elapsed_time">{{dateEntry.risc_average_elapsed_time.total}}</span>
              </div>
              <div class="entry-risc">
                <div class="entry-box">
                <div class="entry-row-1">
                  <span class="entry-titleNew entry2">
                    Revision
                    <span>
                  </span>
                </span>
                </div>
                <div class="entry-detailNew">No of Resubmission</div>
              </div>
                <span class="entry-numNew" *ngIf="dateEntry.risc_revision">{{dateEntry.risc_revision.total}}</span>
              </div>
              <div class="entry-risc">
                <div class="entry-box">
                <div class="entry-row-1">
                  <span class="entry-titleNew entry2">
                    Overdue
                    <span>
                  </span>
                </span>
                </div>
                <div class="entry-detailNew">Returned / Signed by</div>
                <div class="entry-detailNew">RE/IOW/LS</div>
                <div class="entry-detailNew">LONGER THAN 24hrs from</div>
                <div class="entry-detailNew">Actual Inspection/check</div>
              </div>

                <span class="entry-numNew" *ngIf="dateEntry.risc_overdue">{{dateEntry.risc_overdue.total}}</span>
              </div>
            </ng-container>
          </div>
          <div class="data-entry" *ngIf="showRow1.includes(text)">
            <ng-container >
              <div class="entry-risc none" style="visibility: hidden;" >
              </div>
              <div class="entry-risc">
                <div class="entry-box">
                <div class="entry-row-1">
                  <span class="entry-titleNew entry2">
                    Completed
                    <span>
                  </span>
                </span>
                </div>
                <div class=" entry-detailNew">Close with Approval /</div>
                <div class=" entry-detailNew">Disapproval</div>
                </div>
                <span class="entry-numNew" *ngIf="dateEntry.risc_completed">{{dateEntry.risc_completed.total}}</span>
              </div>
              <div class="entry-risc">
                <div class="entry-box">
                <div class="entry-row-1">
                  <span class="entry-titleNew entry2">
                    Incompleted
                    <span>
                  </span>
                </span>
                </div>
                <div class="entry-detailNew">All except Closed</div>
                </div>
                <span class="entry-numNew" *ngIf="dateEntry.risc_incompleted">{{dateEntry.risc_incompleted.total}}</span>
              </div>
              <div class="entry-risc">
                <div class="entry-box">
                <div class="entry-row-1">
                  <span class="entry-titleNew entry2">
                    Delay Approval
                    <span>
                  </span>
                </span>
                </div>
                <div class="entry-detailNew">Inspected to</div>
                <div class="entry-detailNew">RE/SLS Counter Signed</div>
                <div class="entry-detailNew">LONGER THAN 24hrs</div>
              </div>
                <span class="entry-numNew" *ngIf="dateEntry.risc_delay_approval">{{dateEntry.risc_delay_approval.total}}</span>
              </div>
              <div class="entry-risc">
                <div class="entry-box">
                <div class="entry-row-1">
                  <span class="entry-titleNew entry2">
                    Failure at 1<sup>st</sup> inspection
                    <span>
                  </span>
                </span>
                </div>
              </div>

                <span class="entry-numNew" *ngIf="dateEntry.risc_failure_inspection">{{dateEntry.risc_failure_inspection.total}}</span>
              </div>
            </ng-container>
          </div>
     <!-- <div class="data-entry" *ngIf="text == 'weekly_env' || text == 'weekly_safety'">
        <ng-container >
          <div class="entry-risc">
            <div class="entry-box">
            <div class="entry-row-1">
              <span class="entry-titleNew entry2">
                Non-Compliance
                <span>
              </span>
            </span>
            </div>
            </div>
            <span class="entry-numNew" >{{dateEntry.non_compliance}}</span>
          </div>
          <div class="entry-risc">
            <div class="entry-box">
            <div class="entry-row-1">
              <span class="entry-titleNew entry2">
                Near Miss Reports
                <span>
              </span>
            </span>
            </div>
            </div>
            <span class="entry-numNew" >{{dateEntry.near_miss_reports}}</span>
          </div>
          <div class="entry-risc">
            <div class="entry-box">
            <div class="entry-row-1">
              <span class="entry-titleNew entry2">
                Incident Reports
                <span>
              </span>
            </span>
            </div>
          </div>
            <span class="entry-numNew" >{{dateEntry.incident_reports}}</span>
          </div>
          <div class="entry-risc">
            <div class="entry-box">
            <div class="entry-row-1">
              <span class="entry-titleNew entry2">
                Safety Convictions
                <span>
              </span>
            </span>
            </div>
          </div>

            <span class="entry-numNew" >{{dateEntry.safety_convictions}}</span>
          </div>
          <div class="entry-risc">
            <div class="entry-box">
            <div class="entry-row-1">
              <span class="entry-titleNew entry2">
                Accident Rate
                <span>
              </span>
            </span>
            </div>
            <div class=" entry-detailNewWord">Per 100,000 man-hours</div>
            </div>
            <span class="entry-numNew" >{{dateEntry.accident_rate_hours}}</span>
          </div>
          <div class="entry-risc">
            <div class="entry-box">
            <div class="entry-row-1">
              <span class="entry-titleNew entry2">
                Accident Rate
                <span>
              </span>
            </span>
            </div>
            <div class="entry-detailNewWord">Per 1000 workers</div>
            </div>
            <span class="entry-numNew">{{dateEntry.accident_rate_workers}}</span>
          </div>
          <div class="entry-risc">
            <div class="entry-box">
            <div class="entry-row-1">
              <span class="entry-titleNew entry2">
                Average Elapsed Time (in days)
                <span>
              </span>
            </span>
            </div>
          </div>
            <span class="entry-numNew">{{dateEntry.average_elapsed_time}}</span>
          </div>
          <div class="entry-risc">
            <div class="entry-box">
            <div class="entry-row-1">
              <span class="entry-titleNew entry2">
                Late Rectification
                <span>
              </span>
            </span>
            </div>
          </div>

            <span class="entry-numNew" >{{dateEntry.late_rectification}}</span>
          </div>
        </ng-container>
      </div>
  -->
      <div class="statistics_title">
        Statistics on selected month range
      </div>
    <!-- 图形 -->
      <div class="graphical">
        <div class="site-box"  *ngIf="text == 'site_diary'">
          <div class="table-box">
              <div class="table-title">Site Staff Type</div>
              <div class="table-num">{{dateEntry.staff_type}}</div>
          </div>
          <div class="table-box">
            <div class="table-title">Labour Type</div>
            <div class="table-num">{{dateEntry.labour_type}}</div>
          </div>

          <div class="table-box">
            <div class="table-title">Equipment Type</div>
            <div class="table-num">{{dateEntry.equipment_type
            }}</div>
          </div>
          <div class="table-box">
            <div class="table-title">Site Staff Count</div>
            <div class="table-num">{{dateEntry.staff_number}}</div>
          </div>
          <div class="table-box">
            <div class="table-title">Labour Count </div>
            <div class="table-num">{{dateEntry.labour_number}}</div>
          </div>
          <div class="table-box">
            <div class="table-title">Equipment Count</div>
            <div class="table-num">{{dateEntry.equipment_number}}</div>
          </div>

        </div>
        <div class="graphical-content line" *ngIf="text != 'site_diary'&&!checkList.includes(text)" >
          <div class="title" *ngIf="showRow1.includes(text)">
            RISC Form OverView
          </div>
          <div class="graphical-line" *ngIf="showRow1.includes(text)">
            <div class="overView-line">
              <div
              echarts
              [options]="LineOption"
              [merge]="LineOption"
              [autoResize]="'true'"
              class="risc-line-diagram">
              </div>
            </div>
          </div>
          <div>
            <!-- <table class="graphical-table" *ngIf="text == 'site_diary'">
              <thead>
                <th>Nature</th>
                <th>Type</th>
                <th>Count</th>
              </thead>
              <tbody>
                <tr>

                  <td>Site Staff</td>
                  <td>{{dateEntry.staff_type}}</td>
                  <td>{{dateEntry.staff_number}}</td>
                </tr>
                <tr>
                  <td>Labour</td>
                  <td>{{dateEntry.labour_type}}</td>
                  <td>{{dateEntry.labour_number}}</td>
                </tr>
                <tr>
                  <td>Equipment</td>
                  <td>{{dateEntry.equipment_type}}</td>
                  <td>{{dateEntry.equipment_number}}</td>
                </tr>
              </tbody>
            </table> -->
            <table class="graphical-table" *ngIf="text == 'SEDN'">
              <thead>
                <tr>
                  <th colspan="3">

                    Period dd/mm/yy to dd/mm/yy
                  </th>
                </tr>
                <tr>
                  <th colspan="2">Deficinecies Types</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of ObjectEntries(dateEntry) let i =index">

                  <td>{{i}}</td>
                  <td>{{item[0]}}</td>
                  <td>{{item[1]}}</td>
                </tr>

              </tbody>
            </table>
            <table class="graphical-table" *ngIf="labour.includes(text)">
              <thead>
                <tr>
                  <th>

                  Worker Description
                  </th>
                  <th>Total Mandays</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of labourData let i =index">


                  <td>{{item[0]}}</td>
                  <td>{{item[1]}}</td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
        <div class="graphical-content line" *ngIf="checkList.includes(text)" >
          <div class="title" *ngIf="showRow2.includes(text)">
            Completed Incompleted
          </div>
          <div class="graphical-line" *ngIf="showRow2.includes(text)">
            <div class="overView-line">
              <div
              echarts
              [options]="newLineOption"
              [merge]="newLineOption"
              [autoResize]="'true'"
              class="risc-line-diagram">
              </div>
            </div>
          </div>
        </div>
        <div class="graphical-content pan" *ngIf="!labour.includes(text)">
          <div class="title">{{graphicalTitle}}</div>
          <div class="graphical0">
            <!-- 环形图 -->
            <div class="overview-con0">
              <div
              echarts
              [options]="itemOption"
              [merge]="updateItemOptions"
              [autoResize]="'true'"
              class="ring-diagram">
              </div>
            </div>
            <div class="detail" *ngIf="showRow1.includes(text)">
              <div>
                <div>
                  Accepted: <span class="passed">{{this.dateEntry.risc_all.total?( (this.dateEntry.risc_passed.total / this.dateEntry.risc_all.total * 100).toFixed(2) + '%'): '25%'}}</span>
                </div>
                <div>
                  Rejected: <span class="failed">{{this.dateEntry.risc_all.total?( (dateEntry.risc_failed.total / this.dateEntry.risc_all.total * 100).toFixed(2) + '%'): '25%'}}</span>
                </div>
                <div>
                  Progress: <span class="blank">{{this.dateEntry.risc_all.total?( (dateEntry.risc_blank.total / this.dateEntry.risc_all.total * 100).toFixed(2) + '%'): '25%'}}</span>
                </div>
                <div>
                  Not Ready: <span class="NotReady">{{this.dateEntry.risc_all.total?( (dateEntry.risc_not_ready.total / this.dateEntry.risc_all.total * 100).toFixed(2) + '%'): '25%'}}</span>
                </div>
            </div>
            </div>
            <!-- 折线图 -->

          </div>
        </div>
      </div>
          <!-- 新增图形 -->
        <div class="graphical">

              <div class="graphical-content line" *ngIf="!checkList.includes(text)" >
                <div class="title" *ngIf="showRow2.includes(text)">
                  Completed Incompleted
                </div>
                <div class="graphical-line" *ngIf="showRow2.includes(text)">
                  <div class="overView-line">
                    <div
                    echarts
                    [options]="newLineOption"
                    [merge]="newLineOption"
                    [autoResize]="'true'"
                    class="risc-line-diagram">
                    </div>
                  </div>
                </div>
              </div>

              <div class="graphical-content pan" *ngIf="showPie.includes(text)">
                <div class="title">{{graphicalTitle}}</div>
                <div class="graphical0">
                  <!-- 环形图 -->
                  <div class="overview-con0">
                    <div
                    echarts
                    [options]="pieItemOption"
                    [merge]="updateItemOptionsNew"
                    [autoResize]="'true'"
                    class="ring-diagram">
                    </div>
                </div>
              </div>
            </div>
        </div>
    </div>
    <!-- 新增risc图表  -->
    <div class="newAddDashBoard" *ngIf="showTable">
      <ng-container>
        <div class="newGraphicalList">
          <div class="newGraphical" *ngIf="showTabletwo.includes(text)">
            <div *ngIf="text != 'Safety Inspection'">
              <div class="GraphicalTitle">{{titleName.tableNamOne}}</div>
                <div class="quarterName">{{quarterName}}</div>
              <div  echarts [options]="riscChartPie" class="demo-chart"></div>
            </div>
            <div *ngIf="text == 'Safety Inspection'">
              <div class="GraphicalTitle">{{titleName.tableNamFive}}</div>
              <div echarts [options]="riscChartColumnThree" class="demo-chart"></div>
            </div>
          </div>
          <div class="newGraphical" *ngIf="showTableone.includes(text)">
            <div class="GraphicalTitle">{{titleName.tableNamTwo}}</div>
            <div echarts [options]="riscBrokenLine" class="demo-chart"></div></div>
          <div class="newGraphical">
            <div class="GraphicalTitle">{{titleName.tableNamThree}}</div>
            <div echarts [options]="riscChartColumnOne" class="demo-chart"></div></div>
          <div class="newGraphical">
            <div class="GraphicalTitle">{{titleName.tableNamFour}}</div>
            <div echarts [options]="riscChartColumnTwo" class="demo-chart"></div></div>
        </div>
      </ng-container>
    </div>
  <div class="loading" *ngIf="showloading">
    <div class="loading-indicator -compact">
    <div class="block-1"></div>
    <div class="block-2"></div>
    <div class="block-3"></div>
    <div class="block-4"></div>
    <div class="block-5"></div>
    </div>
  </div>

</div>

