import { Component, OnInit ,Input, Output,EventEmitter} from '@angular/core';
import { I18nService } from 'core-app/modules/common/i18n/i18n.service';
import { Observable, fromEvent }from 'rxjs';



//引入生成pdf的插件
import { jsPDF } from 'jspdf'

import html2canvas from 'html2canvas'
import { DocumentsService } from '../../../services/check.lists.service';


@Component({
  selector: 'check_lists-pdf',
  templateUrl: './check_lists-pdf.component.html',
  styleUrls: ['./check_lists-pdf.component.sass']
})
export class CheckListsPdfComponent implements OnInit {

  public pdfContent:any
  public showdoc:boolean=false
  public host:any=window.location.origin
  public locations:any
  public assginUser:any
  public updatedAt :any
  public divicewidth:any


  @Input() public saveRes:any
  @Input() public jumpUrl:any
  @Output()  backstep =new EventEmitter();


  constructor(   public service: DocumentsService,   public i18n: I18nService,) { }

  ngOnInit() {
// this.downloadPDF()
this.divicewidth = document.documentElement.clientWidth
fromEvent(window,"resize").subscribe((event:any)=>{
  this.divicewidth = document.documentElement.clientWidth

})

this.saveRes.check_list_metum.signs.forEach((e:any )=> {
  if(e.doc_id){
    this.showdoc = true
  }
});

this.service.getAssignUser(this.saveRes.assigned_to_id).subscribe((res:any)=>{
  console.log(res)
  this.assginUser = res.name
  this.updatedAt = res.updatedAt

})

this.service.getDetailLocations(this.saveRes.check_list_location_id).subscribe((res:any)=>{
  this.locations = res.name
})
  }


  back(){
    this.backstep.emit()
  }




  downloadPDF(){
    jQuery("#linka").html("")
    window.scroll(0,0)  //下载前回到顶部防止截图不全
    this.pdfContent = document.getElementById('pdfContent');
    let nocutUnit = document.querySelectorAll(".nocut")//获取所有不可分割的最小单元
    nocutUnit.forEach((element:any,index:any)=>{
      const eleoffset = element.offsetTop -this.pdfContent.offsetTop
      const offsetTop = eleoffset+element.offsetHeight/2 //是否在元素的中间
      if(this.divicewidth<770){  //手机等设备屏幕下
       const pageIndex = Math.ceil(eleoffset/510)
        if(Math.abs(offsetTop/pageIndex-516)<10){
          if(nocutUnit[index].id =="usersign"){   //签名项高度过大特殊处理
            nocutUnit[index].classList.add("setmar")
          }else{
            nocutUnit[index].classList.add("settop")
          }
        }
      }

      else{  //电脑屏幕下
        const pageIndex =Math.ceil(eleoffset/945)   //获取元素具体在第几页
        // console.log(element,eleoffset,offsetTop,offsetTop-(pageIndex*945))
        if(Math.abs(offsetTop-(pageIndex*945))<10){
          console.log(nocutUnit[index])
          if(offsetTop-(pageIndex*945)>0){
            nocutUnit[index].classList.add("setbottom")
          }
        }else if(Math.abs(offsetTop-(pageIndex*945))>850){
          if(nocutUnit[index].id=="usersign"){   //判断签名图片是否在页尾
            nocutUnit[index].classList.add("setmar")
          }
          else{
            nocutUnit[index].classList.add("settop")
          }
        }
      }
    })

//转换成pdf
    html2canvas(this.pdfContent,{
      logging:false,
      allowTaint: true,
      scale: 2,  //提高分辨率
    }).then((canvas)=>{

      let pdf = new jsPDF('p','mm','a4');//A4 纵向
      let ctx:any = canvas.getContext('2d');
      let a4w = 190;
      let a4h = 260;
      let imgHeight = Math.floor(a4h*canvas.width/a4w);
      let renderedHeight = 0;

      // let header = document.getElementById("header")
      while(renderedHeight<canvas.height){

        let page = document.createElement("canvas");
        page.width = canvas.width;
        page.height = Math.min(imgHeight, canvas.height - renderedHeight);//不足一页
        //用getImageData剪裁指定区域，并画到前面创建的canvas对象中
        var a:any = page.getContext('2d');

        a.putImageData(ctx.getImageData(0, renderedHeight, canvas.width, Math.min(imgHeight, canvas.height - renderedHeight)), 0, 0);

        pdf.addImage(page.toDataURL('image/jpeg', 1.0), 'JPEG', 10, 10, a4w, Math.min(a4h, a4w * page.height / page.width));    //添加图像到页面，保留10mm边距
        pdf.setDrawColor(121,132,139)
        pdf.setTextColor(0,143,192)
        if(this.divicewidth<1030&&this.divicewidth>770){
          pdf.line(21,270,191,270)   //每一页的pdf的线
        }else if(this.divicewidth>1030){
          pdf.line(23,270,193,270)
        }else{
          pdf.line(13,270,197,270)

        }
        if(renderedHeight<200){
          if(this.divicewidth<770){
            pdf.setFontSize(25)
            pdf.textWithLink(this.saveRes.name,20,68,{url:this.jumpUrl})
          }else{
            pdf.textWithLink(this.saveRes.name,26,47,{url:this.jumpUrl})
          }
        }else{
          if(this.divicewidth>1030){
            pdf.line(23,10,193,10)
          }else{
            pdf.line(13,10,197,10)
          }
        }

        renderedHeight += imgHeight;
        if(renderedHeight < canvas.height)

            pdf.addPage();//如果后面还有内容，添加一个空页

      }
      pdf.save(`${this.saveRes.name}.pdf`);
    });

jQuery("#linka").html(this.saveRes.name)
  }
}
