import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { I18nService } from "core-app/modules/common/i18n/i18n.service";
import { E_liftingService } from "../../services/e_lifting.service";

@Component({
  selector: "e_lifting-table",
  templateUrl: "./e_lifting-table.component.html",
  styleUrls: ["./e_lifting-table.component.sass"],
})
export class E_liftingTableComponent implements OnInit {
  @Output() viewShow = new EventEmitter();
  @Output() statusNum = new EventEmitter();
  @Output() editShow = new EventEmitter();
  @Output() roleName = new EventEmitter();

  public saveNum: any = [];
  public saveIndex: any = 1;
  public count: any = 0;
  public type: any = "";
  public typeValue: any = "";
  public tableList: any = [];
  public saveRole:any
  public applicantFlag:boolean = false
  public approverFlag:boolean = false

  constructor(public i18n: I18nService, public service: E_liftingService) {}

  ngOnInit() {
    this.getList(this.saveIndex, this.type, this.typeValue);
  }

  // 獲取列表數據
  getList(index: any, type: any, value: any) {
    this.type = type;
    this.typeValue = value;
    this.service.getApplicationList(index, type, value).subscribe((res: any) => {
      this.saveNum = [];
      if(res.current_user_roles.length>0){
        this.saveRole =res.current_user_roles.filter((item:any)=>{
         return item.name.indexOf('Lifting')>=0
        })
      }
      if(this.saveRole.length>1){
        this.applicantFlag = true
        this.approverFlag = true
        this.roleName.emit('isAll')
      }else{
        if(this.saveRole[0].name=='Lifting Applicant'){
          this.applicantFlag = true
          this.roleName.emit('isApplicant')
        }else if (this.saveRole[0].name=='Lifting Approver'){
          this.approverFlag = true
          this.roleName.emit('isApprover')
        }else if (this.saveRole[0].name=='Lifting Manager'){
          this.applicantFlag = true
          this.approverFlag = true
          this.roleName.emit('isAll')
        }
      }
      this.statusNum.emit(res.statistics);
      this.tableList = res.list;
      this.count = Math.ceil(res.total_count / 10);
      for (let i: any = 0; i < this.count; i++) {
        this.saveNum.push(i);
      }

    });
  }
  //分頁
  changePage(i: any) {
    if (i < 1 || i > this.saveNum.length) {
      return;
    }
    this.saveIndex = i;
    this.getList(this.saveIndex, this.type, this.typeValue);
  }
  //刪除
  toDelete(e: any) {
    if (confirm("確定刪除該記錄嗎？") == true) {
      this.service.deleteApplication(e).subscribe((res: any) => {
        this.getList(this.saveIndex, this.type, this.typeValue);
      });
    }
  }
  getHref(e: any) {
    let id = jQuery("meta[name=current_project]").data("projectId");
    return `/projects/${id}/e_lifting/export_pdf/${e.id}`;
  }

  toView(e: any) {
    let role
    if (e.state == "init") {
      return;
    }
    if(this.applicantFlag){
      role='isApplicant'
    }
    if(this.approverFlag){
      role='isApprover'
    } 
    if(this.approverFlag&&this.applicantFlag){
      role = 'isAll'
    }
    this.viewShow.emit({id:e.id,role});
  }

  toEdit(e: any) {
    if (e.state != "init") {
      return;
    }
    this.editShow.emit(e);
  }

  downloadPdf(item: any) {
    let language = jQuery("meta[name=openproject_initializer]").data("locale");
    let id = jQuery("meta[name=current_project]").data("projectId");
    if (language == "en") {
      return `/projects/${id}/e_lifting/export_pdf/${item.id}`;
    } else {
      return `/projects/${id}/e_lifting/export_pdf/${item.id}/zh-TW`;
    }
  }

  copy(e: any) {
    this.service.copyApplication(e).subscribe((res: any) => {
      this.getList(this.saveIndex, this.type, this.typeValue);
    });
  }
}
