import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import {SiteDiaryItem, SiteDiaryService} from '../../services/SiteDiary.service';



@Component({
  selector: 'SiteDiary-Details-table',
  templateUrl: './SiteDiary-Details-table.component.html',
  styleUrls: ['./SiteDiary-Details-table.component.sass']
})
export class SiteDiaryDetailsActivityComponent implements OnChanges {

  @Input() public divClick:any
  @Input() public summaryData: SiteDiaryItem
  @Input() public addActivty:any

  @Output() tableEdit = new EventEmitter();

  public tableList = []

  public modulesize:any = []   //module页码
  public modulepage=1

  public per = 999

  constructor(
    public service: SiteDiaryService,
  ) { }

  ngOnInit() {
    // this.getList()
    console.log('divClick',this.divClick)
  }

  //监听父组件传递过来的值
  ngOnChanges(changes:{[propKey:string]: SimpleChange }){
    console.log(changes)
    if(changes['addActivty']){
      if(!changes['addActivty']['currentValue'])this.getList()
    }
    if(changes['divClick']){
      this.getList()
    }
  }

  //获取labour列表数据
  getList() {
    let data = {site_diary_id:this.summaryData.id ,page:this.modulepage ,per:this.per}
    if(this.divClick === 'labour'){
      this.service.getSiteDiaryLabourList(data).subscribe((res:any)=>{
        console.log(res)
        this.tableList = res.labour_items
        this.setCountList(res.labour_items.length,res.count)
      })
    }else if(this.divClick === 'plant'){
      this.service.getSiteDiaryPlantList(data).subscribe((res:any)=>{
        console.log(res)
        this.tableList = res.plant_items
      })
    }else{
      this.service.getActivitiesList(data).subscribe((res:any)=>{
        console.log(res)
        this.tableList = res.activities
        this.setCountList(res.activities.length,res.count)
      })
    }
  }

  //分页处理
  setCountList(length:any,count:any){
    console.log(Math.ceil(count/this.per))
    this.modulesize = []
    for(let i =0;i<Math.ceil(count/this.per);i++){
      this.modulesize.push("i")
      console.log(this.modulesize)
    }
  }

  modulego(leftright:any){
    if(leftright == 'prev'){
      this.modulepage = this.modulepage>this.modulesize.length ? this.modulepage : this.modulepage-1
    }
  }

  //修改按钮
  editClick(item:any){
    jQuery('#top-menu').css('z-index','unset')
    this.tableEdit.emit(item)
  }

  deleteClick(item:any){
    console.log(item)
    this.service.deleteActivities({site_diary_id:this.summaryData.id,activity_id:item.id}).subscribe((res:any)=>{
      this.getList()
    })
    // this.tableEdit.emit(item)
  }

}
