<div class="work-packages-split-view--tabletimeline-content" style="height: 100%;">
  <div class="work-packages-tabletimeline--table-side work-package-table--container __hidden_overflow_container">
    <table class="keyboard-accessible-list generic-table work-package-table modulelistTable" style="width: 50%;">
        <thead class="-sticky">
          <tr style="border-bottom: 1px solid #E7E7E7;">
            <th class="wp-table--table-header">{{ i18n.t("js.check-lists.template.serialNumber") }}</th>
            <th>ID</th>
            <th >{{ i18n.t("js.modals.label_name") }}</th>
            <th >{{ i18n.t("js.button_edit") }}</th>
            <th >{{ i18n.t("js.button_delete") }}</th>
            <th >{{ i18n.t("js.button_copy")}}</th>
          </tr>
        </thead>
        <tbody class="results-tbody work-package--results-tbody" *ngFor="let item of modulelist;let i =index">
          <tr tabindex="0" data-work-package-id="303" data-class-identifier="wp-row-303" class="wp-table--row wp--row wp-row-303 wp-row-303-table issue __hierarchy-root-303" >
              <td>{{i+1}}</td>
            <td>{{item.id}} </td>
            <td>{{item.name}}</td>
            <td class="deletetd" (click)="editmoduleitem(item)">{{ i18n.t("js.button_edit") }}</td>
            <td class="deletetd" (click)="deletemoduleitem(item)">{{ i18n.t("js.button_delete") }}</td>
            <td class="deletetd" (click)="copyModuleItem(item)">{{i18n.t("js.button_copy")}}</td>
          </tr>
        </tbody>
      </table>
</div>
</div>


