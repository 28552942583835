import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DocumentsService } from '../../services/documents.service';
import { CurrentProjectService } from 'core-app/components/projects/current-project.service';
import { I18nService } from 'core-app/modules/common/i18n/i18n.service';

@Component({
  selector: 'documents-operation',
  templateUrl: './documents-operation.component.html',
  styleUrls: ['./documents-operation.component.sass']
})
export class DocumentsOperationComponent implements OnInit {
  @Input() type: any;
  @Output() cancel = new EventEmitter();
  @Output() confirm = new EventEmitter();
  //输入框显示
  inputNameShow = false;
  //所有项目
  catalogTree: any = [];
  //标题
  title: any = {
    //移动到
    move: this.i18n.t("js.documents.move_to"),
    //复制到
    copy: this.i18n.t("js.documents.copy_to")
  }
  constructor(
    public currentProject: CurrentProjectService,
    public service: DocumentsService,
    public i18n: I18nService
  ) {}
  handleCancel() {
    this.cancel.emit();
  }
  handleConfirm() {
    this.confirm.emit();
  }
  ngOnInit() {
    this.service.mask(true);
    this.getAllProjects();
  }
  //获取所有项目
  getAllProjects() {
    this.service.showOperationLoading(true);
    this.service.getAllProjects(
      this.currentProject.id
    ).subscribe((res: any) => {
      if (res.result === 'ok') {
        this.service.showOperationLoading(false);
        this.catalogTree = res.data;
      } else {
        this.service.showOperationLoading(false);
      }
    })
  }
  //点击新建文件夹
  createFolder() {
    this.inputNameShow = true;
  }
  //输入框确定
  handleInputConfirm(folder_name: any) {
    let selectFolderItem = this.service.selectFolderItem;
    if (!selectFolderItem) return;
    this.service.showOperationLoading(true);
    this.service.createFolder({
      folder_name,
      id: selectFolderItem.id
    }).subscribe((res: any) => {
      if (res.result === 'ok') {
        this.service.showOperationLoading(false);
        if (selectFolderItem.openIcon) {
          selectFolderItem.children && selectFolderItem.children.unshift(res.data);
        } else {
          selectFolderItem.has_children || (selectFolderItem.has_children = true);
        }
        this.inputNameShow = false;
      } else {
        this.service.showOperationLoading(false);
      }
    })
  }
  //关闭遮罩层
  closeMask() {
    if (!this.service.operationLoading) {
      this.service.operationMask = false;
      this.inputNameShow = false;
    }
  }
  //输入框取消
  handleInputCancel() {
    this.inputNameShow = false;
  }
  ngOnDestroy(): void {
    this.service.mask(false);
  }
}