import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
// import PSPDFKit from 'pspdfkit';
import { DocumentsService } from '../../services/documents.service';
import { I18nService } from 'core-app/modules/common/i18n/i18n.service';


@Component({
  selector: 'documents-pdf',
  templateUrl: './documents-pdf.component.html',
  styleUrls: ['./documents-pdf.component.sass']
})
export class DocumentsPdfComponent implements OnInit {

  constructor(
    public service: DocumentsService,
    public i18n: I18nService,
    public changeDetectorRef:ChangeDetectorRef
  ) { }
  @Input() item: any;
  @Output() close = new EventEmitter();
  @Output() openLoad = new EventEmitter();
  @Output() loading = new EventEmitter();
  @Output() colseloading = new EventEmitter();
  public id:any
  ngOnInit() {
    console.log(this.item)
  }
  instance:any;

  ngAfterViewInit(){
    var xhr = new XMLHttpRequest()
    xhr.open('GET', this.item.url)
    xhr.responseType = 'arraybuffer'
    xhr.onload = () => {
      const save:any = {
        type: "custom",
        id: "my-button",
        title: this.i18n.t("js.documents.save"),
        onPress: () => {
          this.openLoad.emit();
          this.changeDetectorRef.detectChanges();
          this.instance.exportPDF().then( (buffer:any)=>{
            const files = new File([buffer],this.item.name,{ type: "application/pdf" });
            const file = new FormData();
            file.append("file",files)
            console.log(file)
            this.service.uploadPdf({
              id: this.item.id,
              file
            }).subscribe((res: any) => {
              this.close.emit()
            },error=>{
              this.colseloading.emit()
            })
          })
        }
      };
      const save_btn:any = {
        type: "custom",
        id: "my-button",
        title:  this.i18n.t("js.documents.save_download"),
          onPress: () => {
            this.openLoad.emit();
            this.changeDetectorRef.detectChanges();
          this.instance.exportPDF().then( (buffer:any)=>{
            console.log(buffer)
            const files = new File([buffer],this.item.name,{ type: "application/pdf" });
            const blob = new Blob([buffer], { type: "application/pdf" });
            console.log(blob)
            const file = new FormData();
            file.append("file",files)
            this.service.uploadPdf({
              id: this.item.id,
              file
            }).subscribe((res: any) => {
              if (res.result === 'ok') {
                this.close.emit()
                console.log(res)
                const objectUrl = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = objectUrl;
                a.target = "_blank";
                a.download = this.item.name;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);
              }
            },error=>{
              this.colseloading.emit()
            })
          })
        }
      };

      // PSPDFKit.load({
      //   baseUrl: location.protocol + "//" + location.host + "/assets/frontend/assets/",
      //   document:  xhr.response,
      //   container: '#app',
      //   disableWebAssemblyStreaming: true,
      //   licenseKey: 'yGsvyVYBPyOEmvBXe6Uje3jGUPoqUvrM-2aKa9iKZivlvy2Vf52NFYMyfqvVTlpDxPir9o34j9gwsnnURAFNCkVZFnhs5R5dvh8yg5TFMYIRBo-030bVlKcASCeDlQojh6-hgKvIynPtelqR1bOhz-o--US0YYyBaCABiGW2kPfjxn0Cv5xBuMxCqCbo63Xzqsa3jS8LCX2_gzZlBH0vjaNYPvJzLOMlRqV2HE7WMKpGdTexs5DLdEnub8lLfzEgEFUpSEKBDoIjXaFMrUmUC-fR7JLdpEbvw1WNrPZ6P-rjdEyCVG_V7EHmPq6P0h27FPEDtuBvycVUUVYG4EnpkIKIRPgztu61fs1LXc7VjAN1YaHs-4p1rL1iFgst5SuK8EfwwormhbgXqkzcpcYbOtMoWciCUKNlqdrivhfen6OI7gGoMxTcGvgnX0hmWWBv',
      // }).then(async instance => {
       
      //   instance.setToolbarItems(items=>{
      //     items.push(save)
      //     items.push(save_btn)
      //     return items
      //   })
      //   this.loading.emit()
      //   this.instance = instance
      // });
    }
    xhr.send()
  }

}
