import { filter} from 'rxjs/operators';
import { Component, OnInit, NgModule, ChangeDetectorRef, Input, Output, EventEmitter, ElementRef, ViewChild,NgZone,SimpleChanges} from '@angular/core';
import {CommonModule} from '@angular/common';
import { DocumentsService } from '../../services/check.lists.service';
import { any, val } from '@uirouter/core';
import { NONE_TYPE } from '@angular/compiler/src/output/output_ast';
import { I18nService } from 'core-app/modules/common/i18n/i18n.service';
import { Observable, fromEvent, from , forkJoin}from 'rxjs';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
declare let laydate: any;
@Component({
  selector: 'check_list-quest3',
  templateUrl: './check-list-quest3.component.html',
  styleUrls: ['./check-list-quest3.component.sass']
})
export class Check_listsQuest3Component implements OnInit {

  @Input() public check_listsID:any
  @Input() public kind_id:any

  @Output() close = new EventEmitter();
  @ViewChild('uploadFileRef', { static: false }) uploadFileRef: ElementRef;

  @ViewChild ('outputPdf',{static:false}) downPdf:ElementRef


  constructor(
    protected cdRef:ChangeDetectorRef,
    public service: DocumentsService,
    private zone: NgZone,
    public i18n: I18nService,
  ) { }

  public data:any={"result":"ok","check_list":{"id":2,"check_list_type_id":null,"check_list_template_id":1,"created_by_id":2,"updated_by_id":3,"assigned_to_id":null,"expected_finish_at":null,"start_at":null,"end_at":null,"description":null,"check_list_location_id":null,"created_at":"06/04/2020/ 16:50 PM","updated_at":"06/04/2020/ 16:50 PM","project_id":null,"label":null,"name":null,"check_list_metum":{"id":2,"parts":[{"items":[{"type":"text","title":"Scheduled Time of Inspection","value":"41545151515","default":"12:00","options":null,"description":"Normally this inspection will be walk through the site and\nwill take at least 1 hour. Please use international time format,\ne.g. 13:30"},{"type":"text","title":"Locations","value":"","default":"Drainage 1 to 13","options":null,"description":""},{"type":"radio","title":"Weather","value":null,"default":0,"options":["Sunny","Cloudy","Rainy"],"description":null},{"type":"text","title":"Temperature","value":null,"default":35,"options":null,"description":"Degree Celsius"},{"type":"text","title":"Humidity","value":"45485151515","default":86,"options":null,"description":"Percentage"},{"type":"text","title":"Person(s) doing inspection","value":null,"default":"Jhon Doe/IOW/AECOM","options":null,"description":"Name / Title / Organisation"}],"title":"Waste Water Inspection","description":"descriptions of waste water inspection"},{"items":[{"type":"radio","title":"Is wastewater discharge License provided?","value":null,"default":null,"options":["是","否","无"],"description":null},{"type":"checkbox","title":"Time","value":[0,1],"default":null,"options":["AM","PM"],"desription":null}],"title":"Water Discharge License","description":"Note: This is a documentary check, photo or document record may be necessary.HS"}],"signs":[{"user_id":3}],"check_list_id":2,"created_at":"06/04/2020/ 16:50 PM","updated_at":"06/04/2020/ 16:50 PM"}}}
  public num:any=0;
  public theCheckbox:any = false
  public indexO:any
  public dipsle:any=true
  public signShow:any=false
  public autographsignitem:any
  public annotationShow:any=false
  public annotationId:any=[]
  public projectId:any
  public userData:any
  public byuserdata:any
  public locationsData:any
  public userSelect:any
  public count:any=0
  public sum:any=0
  public sum_num:any = 0
  public updateModel:any
  public id:any
  public doc_idImg:any
  public assigned_to_id:any
  public check_list_location_id:any
  public start_at:any
  public end_at:any
  public expected_finish_at:any
  public arr_sum:any=[]
  public show_banner:any=false
  public count_doc:any=[]
  public host:any=window.location.origin
  public arr_doc_img:any=[]
  public doc_item_id:any
  public remark_id:any
  public remark_item:any
  public doc_item:any
  public detailsbottomtext:any
  public issueblock = false
  public reSignLoad:any
  public action_required:any
  public Reply:any
  public groupData:any
  public assigntLoading:any
  //创建前数据
  public fileRefNo: any={};
  //创建后数据
  public fileRefNoInfo:any={};

  //是否显示 fileRefNo
  public isFileRefNo:boolean=false;
  // fileRefNo type
  public fileRefNoType:string;
 //是否在提交中
  public isSubmit:boolean=false;

  public payload:any
  public schema:any
  public showPdfdownload:boolean = true
  public saveRes:any
  public jumpUrl:any
  public signBy:any=[]
  public  needNum:any
  public showNum:boolean = false

  public templateid:any=[]
  public showEditDiv:boolean = false
  public showimgEdit:boolean = false
  public imageData:any ={}
  public tempIndex:any
  public remarkFlag:boolean=true
  public hascount:number=0
  public showLoading:boolean = false

  public inputInfoId=""
  public checklistArr = ['','Checklist','Safety','Cleansing']
  public saveFileFlag:boolean = true
  public checkYes:number 
  public allLog: boolean = false
  public replySign:any
  public userId:any = Number(document.getElementsByName('current_user')[0].dataset.id)
  public dipslesignature:any 
  public signloding:any
  public actionSign:any
  public signType:any
  public actiondocId:any
  public replydocId:any
  public ifCheckAssign:any
  public constructorUserId:any
  public contractorUsers:any
  public checkassightselect:any
  public assignType:any
  public assigned_doc_last_id:any
  public assigned_doc_last_loading:any
  public assigned_doc_last_sign:any
  public imageLeftData:any = new Image()
  // public checklistTypeArr = ['','ChecklistType','SafetyType','CleansingType']
  public aImage:any
  public fill_env:any
  public choice_env:any

  clickLi(i:any){
    this.count = 0
    this.num = i
    this.checkYes = 3
    this.allLog = false
    this.hascount =0
    this.dipsle = true
    this.showNum =false
    this.cdRef.detectChanges();
    this.inputInfoId = ''
    jQuery('.CompleteSelectedSection__items-remaining').css({'color':'#819099'});
  }

  clickSign(e:any){
    this.dipsle = false
    this.asideClick(e)
  }
  getStringValue(value:any) {
    return JSON.stringify(value)
  }
  ngOnInit() {
    this.jumpUrl = window.location.href
    if(document.documentElement.clientWidth > 770){
        jQuery(".ChecklistPageRightPanel__close-button").css("display","block")
    }

     fromEvent(window,"resize").subscribe((event:any)=>{
       if(document.documentElement.clientWidth>770){
        jQuery(".ChecklistPageRightPanel__close-button").css("display","block")
        jQuery(".content-detailed").css("display","block")
        jQuery(".content-date-left").css("display","block")
        jQuery(".detailShow").css("display","none")
        jQuery(".asideShow").css("display","none")
        jQuery(".asideHide").css("display","none")
       } else {
        jQuery(".ChecklistPageRightPanel__close-button").css("display","none")
       }
     })

    if(window.location.href.indexOf('check_listsID')<0){
      let url =window.location.href.split('?')[0]
      let newUrl = url+'?kind_id='+this.kind_id+'&check_listsID='+this.check_listsID
      window.history.replaceState(null, '', newUrl)
    }
   
    jQuery('#content-wrapper').css({'background':'#ecf0f3','padding': 0});

    this.projectId = document.querySelector('meta[name=current_project]')
    this.id = this.projectId.dataset.projectId

    this.getInfo()

    // this.service.work_packages(this.id,{}).subscribe((res:any)=>{
    //   console.log(res,'baocuo')
    //   let dateitem = res._embedded.schema.type._links.allowedValues.find((res:any)=>res.title == 'Task')
    //   console.log(dateitem.href);
    //   this.service.work_packages(this.id,{_links:{type:{href:dateitem.href}}}).subscribe((data:any)=>{
    //     console.log()
    //     this.payload = data._embedded.payload
    //     this.schema = data._embedded.schema
    //   })
      
    // })




    this.service.getbyUserList(this.id,).subscribe((res:any)=>{
      this.byuserdata = res._embedded.elements
    })

    this.service.getLocations(this.kind_id).subscribe((res: any) => {
      this.locationsData = res
      this.cdRef.detectChanges();
    })
  }
  dataURLtoBlob(dataurl:any) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }
  handleClick() {
    let Itemuser = null
    if(this.data.check_list.state.includes('Completed')) {
      Itemuser = this.data.check_list.assigned_to_last_group.find( (item:any) => {
        return item.id == Number(this.userId)
      })
    } else {
      Itemuser = this.data.check_list.assigned_to_group.find( (item:any) => {
        return item.id == Number(this.userId)
      })
    }

    console.log(!!Itemuser)
    if(!!Itemuser) {
      console.log(Itemuser, !this.isHandle())
      if(!this.isHandle()) {
        let data = {

        }
        if(this.data.check_list.state.includes('Sent')) {
          data = {
            executor_id: Number(this.userId)
          }
          this.service.saveChecklist(Number(this.check_listsID), data).subscribe( () => {
            this.data.check_list.executor_id = Number(this.userId)
            this.data.check_list.executor = this.data.check_list.assigned_to_group.find( (item:any) => {
              return this.data.check_list.executor_id == item.id
            })
          }, (err:any) => {
            alert('Handler already exists')
          }
          )
        } else {
          data = {
            executor_last_id: Number(this.userId)
          }
          this.service.saveChecklist(Number(this.check_listsID), data).subscribe( () => {
            this.data.check_list.executor_last_id = Number(this.userId)
            this.data.check_list.executor_last = this.data.check_list.assigned_to_last_group.find( (item:any) => {
              return this.data.check_list.executor_last_id == item.id
            })
          },
           (err:any) => {
            alert('Handler already exists')
          })
        }

      } else {
        console.log(this.data.check_list.executor_id== Number(this.userId), this.data.check_list.executor_last_id== Number(this.userId))
        if(this.data.check_list.executor_id== Number(this.userId) ) {
          this.service.saveChecklist(Number(this.check_listsID), {
            executor_id: null
          }).subscribe( () => {
            this.data.check_list.executor_id = null
            this.data.check_list.executor = null
          }, (err:any) => {
            alert('Handler already exists')
          })
        }
        if(this.data.check_list.executor_last_id== Number(this.userId)) {

          this.service.saveChecklist(Number(this.check_listsID), {
            executor_last_id: null
          }).subscribe( () => {
            this.data.check_list.executor_last_id = null
            console.log(this.data.check_list.executor_last_id)
            this.data.check_list.executor_last = null
          }, (err:any) => {
            alert('Handler already exists')
          })
        }


      }
    }
  }
  checkDisable():boolean {
      // if(this.data.check_list.state.includes('Sent') && this.data.check_list.recheck == 'recheck') {
      //   return this.data.check_list.created_by_id != this.userId
      // }
      // if(this.data.check_list.assigned_type == 'Group'  && this.data.check_list.state.includes('Sent')) {
      //   return this.data.check_list.executor_id != this.userId
      // }
      // console.log(((this.data.check_list.assigned_to_last_id != this.userId && this.data.check_list.executor_last_id != this.userId)|| !this.data.check_list.state.includes( 'Complete')))
      
      if(this.data.check_list.state == 'Closed') {
        return true
      }
      if(this.data.check_list.state.includes('Contractor Sent')){
        return true
      }
      if( this.data.check_list.created_by_id == this.userId){
        return false
      } else {
        if(this.data.check_list.state == 'New' || !!this.data.check_list.recheck) {
          return true
        } else {
          if(this.data.check_list.state.includes('Sent')){
             return true
           }
          if(this.data.check_list.state.includes('Sent')&& (this.data.check_list.executor_id !== this.userId && this.data.check_list.assigned_to_id !== this.userId)){
            return true
          }
          if(this.data.check_list.state.includes('Complete')&& (this.data.check_list.executor_last_id !== this.userId && this.data.check_list.assigned_to_last_id !== this.userId)){
            return true
          }
        }
      } 
      return false
      
  }
  isHandle() {

    if(this.data.check_list.state.includes('Sent')) {
      return this.data.check_list.executor_id == this.userId
    } else {
      return this.data.check_list.executor_last_id == this.userId
    }
  }
  canClickHandle() {
    if(this.data.check_list.state.includes('Sent')) {
        if(!this.data.check_list.assigned_to_group) {
          return
        }
      return this.isHandle() || (!!this.data.check_list.assigned_to_group.find( (item:any) => {
        return item.id == Number(this.userId)
      }) && !this.data.check_list.executor_id)
    } else {
      if(!this.data.check_list.assigned_to_last_group) {
        return
      }
      return this.isHandle() || (!!this.data.check_list.assigned_to_last_group.find( (item:any) => {
        return item.id == Number(this.userId)
      }) && !this.data.check_list.executor_last_id)
    }
  }
   AssignClosedSave(){
   let sign = {
      created_doc_id: this.actiondocId,
      assigned_doc_id: this.replydocId,
      action_required: this.data.check_list.action_required,
      reply: this.data.check_list.reply,
      reply_last: this.data.check_list.reply_last,
      assigned_doc_last_id: this.replydocId 
    } as any
    let data= {
      state:'Contractor Completed'
    }
    this.service.update_sign(Number(this.check_listsID), {
      sign
    }).subscribe( () => {
       this.service.saveChecklist(Number(this.check_listsID), data).subscribe( () => {
          this.ifCheckAssign = false
          this.assigntLoading = false
          // this.cleon()
          this.AssignSave('Closed')
        },
        () => {
          this.assigntLoading = false
        })
    })
  }
  AssignSave(state?:any, isSave?: any, closeCompleted?:any) {
    console.log(state,'d')
    // return
    // return
    if(this.canNoClick(state, isSave)){
      return
    }
    let recheck = ''
    let  data  = {} as any
    if(state&& !isSave){
      data.state = state
      console.log(data.state)

      if(data.state == 'Sent') {
        data.state = 'Contractor Sent'
      }
      if(data.state.includes('Completed')){
        // data.state = 'Contractor Completed'
        data.state = 'Contractor Sent'

      }

      if(state == 'Closed') {
        data.state = state
        
      }
    }
    if(isSave) {
      data.state = this.data.check_list.state
    }
    if(this.constructorUserId) {
      if(this.data.check_list.state.includes('Sent') && this.data.check_list.recheck !== 'recheck' ) {
        data.assigned_to_last_id = Number(this.constructorUserId)
      } else {
        data.assigned_to_id= Number(this.constructorUserId)
      }
    } else if( !isSave  && state !== 'Recheck' && state != 'Closed'&&closeCompleted!='Closed'){
      return
    } else if(data.state.includes('Sent')&& (this.data.check_list.assigned_to_id != this.userId && this.data.check_list.executor_id != this.userId)) {
      return
    }
    if(state == 'Recheck') {
      if(this.data.check_list.state.includes('Completed')) {
    
        if(this.data.check_list.assigned_to_last_id != this.userId 
          && this.data.check_list.executor_last_id != this.userId) {
            return
          }
        recheck = 'recheck_two'
      } else {
        if(this.data.check_list.assigned_to_id != this.userId 
          && this.data.check_list.executor_id != this.userId) {
            return
          }
        recheck = 'recheck'
      }
      this.assigntLoading = true
      this.service.setRecheck(this.check_listsID, recheck).subscribe( () => {
        this.assigntLoading = false
      }, () => {
        this.assigntLoading = false
      })
    } else {

      data.recheck = ''
    }
    

    this.checkListSave(data, state, isSave)
  }
  clickassign() {
    console.log(!this.replySign , this.data.check_list.executor_id != this.userId , this.data.check_list.assigned_to_id != this.userId )
  }
  changeAssignTo(type:any) {
    this.assignType = type
    if(type == 'Indlvidual') {
      this.checkassightselect = this.userData
    } else {
      this.checkassightselect = this.groupData
    }
  }
  canNoClick(state:any, isSave?:any) {
    if(state == this.data.check_list.state) {
    if(this.kind_id != 1) {
      if(this.data.check_list.state == 'New' || this.data.check_list.recheck == 'recheck') {
        return this.data.check_list.created_by_id != this.userId 
      }
      if(this.data.check_list.state.includes('Sent') &&  this.data.check_list.recheck !== 'recheck') {
        return this.data.check_list.assigned_to_id != this.userId 
        && this.data.check_list.executor_id != this.userId 
      }
      if(this.data.check_list.state.includes('Completed') ) {
        return this.data.check_list.assigned_to_last_id != this.userId 
            && this.data.check_list.executor_last_id != this.userId
      }
      return true
      // return (this.data.check_list.created_by_id != this.userId 
      //   || (this.data.check_list.state.includes('Sent')  && this.data.check_list.recheck !== 'recheck')) 
      //   && ((this.data.check_list.assigned_to_id != this.userId 
      //   && this.data.check_list.executor_id != this.userId)
      //   || !this.data.check_list.state.includes('Sent')) && (
      //     this.data.check_list.state.includes('Completed')  &&(this.data.check_list.assigned_to_last_id != this.userId 
      //       && this.data.check_list.executor_last_id != this.userId)
      //   )
    } else {

      return (this.data.check_list.created_by_id != this.userId 
         || (this.data.check_list.state !== 'New'
         && !this.data.check_list.state.includes('Completed')))
         && ((this.data.check_list.assigned_to_id != this.userId 
         && this.data.check_list.executor_id != this.userId)
         || !this.data.check_list.state.includes('Sent'))
    }
    } else if(state == 'Closed') {
      return false
      return ( !this.assigned_doc_last_sign  || (this.data.check_list.assigned_to_last_id != this.userId 
        && this.data.check_list.executor_last_id != this.userId)) 
    } else if(state == 'Recheck'){
      return  !(this.data.check_list.assigned_to_id == this.userId || this.data.check_list.executor_id == this.userId || this.data.check_list.assigned_to_last_id == this.userId || this.data.check_list.executor_last_id == this.userId)
    } else {
      return false
    }
    }
  checkListSave(data:any, state:any, isSave?:any) {
    console.log(data)
    let cc_ids = this.userData.filter( (item:any) => {
      return item.checked
    }).map( (item:any) => {
      return item.id
    }).concat(
      this.groupData.filter( (item:any) => {
        return item.checked
      }).map( (item:any) => {
        return item.id
      })
    )
    let   sign ={}
    console.log(state == 'Recheck')
    if(state == 'Recheck') {
      sign = {
        action_required: this.data.check_list.action_required,
        reply: this.data.check_list.reply,
        reply_last: this.data.check_list.reply_last,
      } as any
    } else {

      sign = {
        created_doc_id: this.actiondocId,
        assigned_doc_id: this.replydocId,
        action_required: this.data.check_list.action_required,
        reply: this.data.check_list.reply,
        reply_last: this.data.check_list.reply_last,
        assigned_doc_last_id: state== 'Closed'?  this.replydocId : this.assigned_doc_last_id
      } as any
    }

    if(cc_ids.length > 0) {
    
        if(this.data.check_list.state.includes('New') || !!this.data.check_list.recheck) {
          data.cc_ids = cc_ids
        } else {
          data.cc_last_ids= cc_ids
        }
      
    }
    this.assigntLoading = true

    this.service.update_sign(Number(this.check_listsID), {
      sign
    }).subscribe( () => {
      if(state == 'Recheck' || isSave) {
        this.ifCheckAssign = false
        this.assigntLoading = false
        this.cleon()
      } else {
       this.service.saveChecklist(Number(this.check_listsID), data).subscribe( () => {
          this.ifCheckAssign = false
          this.assigntLoading = false
          this.cleon()
        },
        () => {
          this.assigntLoading = false
        })
      }
    })
  }
  closeView(img:any){
    if(img){
      if(this.signType == 'action'){
        let blob = this.dataURLtoBlob(img)
        var files = new File([blob], 'drawComplete.png', { type: blob.type })
        let file = new FormData();
        file.append('file', files);
        file.append('prefix', 'risc');
        this.signloding = true
        this.service.postSign(file).subscribe( (res:any)=> {
          console.log(res)
          this.actiondocId = res.doc_id
          this.signloding = false
          this.actionSign = img
        })
      } else if(this.signType == 'reply'){
        let blob = this.dataURLtoBlob(img)
        var files = new File([blob], 'drawComplete.png', { type: blob.type })
        let file = new FormData();
        file.append('file', files);
        file.append('prefix', 'risc');
        this.reSignLoad = true
        this.service.postSign(file).subscribe( (res:any)=> {
          console.log(res)
          this.replydocId = res.doc_id
          this.reSignLoad = false
          this.replySign = img
        })
      } else {
        let blob = this.dataURLtoBlob(img)
        var files = new File([blob], 'drawComplete.png', { type: blob.type })
        let file = new FormData();
        file.append('file', files);
        file.append('prefix', 'risc');
        this.assigned_doc_last_loading = true
        this.service.postSign(file).subscribe( (res:any)=> {
          console.log(res)
          this.assigned_doc_last_id = res.doc_id
          this.assigned_doc_last_loading = false
          this.assigned_doc_last_sign = img
        })
      }
    } 
    this.dipslesignature = false 
    console.log(img)
  }

  downloadPdf(){
    this.showPdfdownload = false
  }
  deleteReplySign() {
    this.replySign = null
    this.replydocId = null
  }
  deleteSssignedLast() {
    this.assigned_doc_last_id = null
    this.assigned_doc_last_sign = null
  }
  deleteActionSign() {
    this.actionSign = null
    this.actiondocId = null
  }
  backstep(){
    
    this.showPdfdownload =true
  }

  getCount(e:any){
    let count:number = 0
    for(let item of e){
      if(item.list){
        count +=item.list.length
      }
    }
    return count
  }
  removeDownLoadIframe() {

    if(this.aImage && this.fill_env && this.choice_env){
      document.body.removeChild(this.aImage)
      document.body.removeChild(this.fill_env)
      document.body.removeChild(this.choice_env)
    }
  }
  getHref(){
    this.removeDownLoadIframe()
    this.aImage = document.createElement('iframe')
    this.aImage.src = `/projects/${this.id}/check_lists/export_inspection_pdf/${this.check_listsID}/image_env`
    this.aImage.style.display = 'none'
    document.body.appendChild(this.aImage)
    this.fill_env = document.createElement('iframe')
    this.fill_env.src = `/projects/${this.id}/check_lists/export_inspection_pdf/${this.check_listsID}/fill_env`
    this.fill_env.style.display = 'none'
    document.body.appendChild(this.fill_env)
    this.choice_env = document.createElement('iframe')
    this.choice_env.src = `/projects/${this.id}/check_lists/export_inspection_pdf/${this.check_listsID}/choice_env`
    this.choice_env.style.display = 'none'
    document.body.appendChild(this.choice_env)
  }



  getInfo(){
    this.service.getCheckList(this.id,this.check_listsID).subscribe((res: any) => {
      this.signBy = res.check_list.check_list_metum.signs
      if(res.result == 'ok'){
        this.saveRes =res.check_list
        // this.data = res
        if(!this.inputInfoId){
          this.data = res
          let role = 'Consultant'
        
            // if( this.data.check_list.recheck == 'recheck_two' || this.data.check_list.recheck == 'recheck' ) {
            //   role = 'Contractor'
            // } else {
              // role = 'Consultant'
            // }
        
          this.service.getbyUserList(this.id, role).subscribe((res: any) => {
            let userData = res._embedded.elements

            userData.sort( (pre:any, next:any) => {
                return pre.name.toLowerCase().trim()  < next.name.toLowerCase().trim() ?-1:1
            })
              this.userData = userData.filter( (item:any) => {
               return item._type == 'User'
              })
              this.groupData = userData.filter( (item:any) => {
                return item._type == 'Group'
               })

               this.checkassightselect = this.userData
            this.cdRef.detectChanges();
          })
          this.actiondocId = this.saveRes.created_doc_id
          this.replydocId = this.saveRes.assigned_doc_id
          this.assigned_doc_last_id = this.saveRes.assigned_doc_last_id
          if(this.actiondocId){
            this.actionSign =  this.actionSign = location.origin + `/api/v3/logical_docs/doc/${this.actiondocId}/stream` 
          } 
          if(this.replydocId) {
            this.replySign = location.origin + `/api/v3/logical_docs/doc/${this.replydocId}/stream`
          }
          if(this.assigned_doc_last_id) {
            this.assigned_doc_last_sign = location.origin + `/api/v3/logical_docs/doc/${this.assigned_doc_last_id}/stream`
          }
          if(this.data.check_list.file_ref_no){
            this.saveFileFlag = false
          }
        }
        this.inputInfoId = ''
        this.getFileRefNoInfo();
        this.sum = 0
        this.sum_num = 0
        for(let i of this.data.check_list.check_list_metum.parts){
          let arr:any=[]
          for(let item of i.items){
            for(let subitem of item.list){
              this.sum_num+=1
              if(subitem.value!==''&&subitem.value!==null){
                arr.push(subitem)
              }
            }
          }
          i.count = arr.length
          this.sum+=i.count
          this.cdRef.detectChanges();
        }

        this.arr_sum = this.data.check_list.check_list_metum.parts.filter((j:any)=>{
          return j.state == 'finished'
        })

        this.assigned_to_id = this.data.check_list.created_by_id
        this.check_list_location_id = this.data.check_list.check_list_location_id
        this.expected_finish_at = this.data.check_list.expected_finish_at

        this.count_doc = this.data.check_list.check_list_metum.signs.filter((i:any)=>{
          return i.doc_id !== ''
        })


        if(this.i18n.locale == 'en'){
          this.detailsbottomtext =  `last updated by ${res.check_list.updated_by_user} on ${res.check_list.updated_at}`
        }else if(this.i18n.locale == 'zh-CN' || this.i18n.locale == 'zh-TW'){
          this.detailsbottomtext = `由 ${res.check_list.updated_by_user} 于 ${res.check_list.updated_at} 最后更新`
        }

        
        this.show_banner =   this.arr_sum.length === this.data.check_list.check_list_metum.parts.length && 
        this.data.check_list.state !== 'finished' && 
        this.count_doc.length === this.data.check_list.check_list_metum.signs.length



        // if(!this.inputInfoId) return
        // setTimeout(()=>{
        //   jQuery(`#${this.inputInfoId}`).select()
        //   jQuery(`#${this.inputInfoId}`).hide()
        //   this.inputInfoId = ""
        // },1000)
        }
    })
  }

  showTime(time:any){
    laydate.render({
      elem: '#checklostsdate',
      type:'date',
      value:time,
      show:true,
      done:(value:any)=>{
        if(!value){
          this.expected_finish_at = null
          jQuery('#checklostsdate').text('未指定')
        }else{
          this.expected_finish_at = value
        }
        this.updateUserCheckList({expected_finish_at:this.expected_finish_at})
      }
    });
  }
  check(value:any) {
    if(value == 3){
      return
    }
    let httpArray:any = []
    this.data.check_list.check_list_metum.parts[this.num].items.forEach( (item:any, index:number) => {
      item.list.forEach((el:any, sub:any) => {
        if(el.type === 'radio') {
          el.value = value
          let data={
            value,
            part:this.num,
            item:index,
            sub_item:sub
          }
          let http = this.service.updateItem({
            p_id:this.id,
            c_id:this.check_listsID,
            data:data
          })
          httpArray.push(http)
        }
      });
    })
    this.checkSome(httpArray)
    // forkJoin(httpArray).subscribe ( (res:any) => {
    //     this.getInfo() 
    //   if(this.data.check_list.state == 'pending'){
    //     this.data.check_list.state = 'processing'
    //     this.service.updateCheckListstate({
    //       p_id:this.id,
    //       c_id:this.check_listsID,
    //       data:{state:'processing'}
    //     }).subscribe((res:any)=>{
    //     })
    //   }
    //   jQuery('.CompleteSelectedSection__items-remaining').css({'color':'#819099'});
    // })
  }
  checkSome(httpArray: Array<any>):any {
  
    httpArray.shift().subscribe((res:any):any => {
     if(httpArray.length > 0) {
       this.checkSome(httpArray)
     } else {
      this.getInfo() 
        if(this.data.check_list.state == 'pending'){
          this.data.check_list.state = 'processing'
          this.service.updateCheckListstate({
            p_id:this.id,
            c_id:this.check_listsID,
            data:{state:'processing'}
          }).subscribe((res:any)=>{
          })
        }
     }
    })
    

  }
  updateInfo(datas:any){
    this.showNum = false
    let data=datas
    this.service.updateItem({
      p_id:this.id,
      c_id:this.check_listsID,
      data:data
    }).subscribe((res: any) => {
      this.getInfo()
      if(this.data.check_list.state == 'pending'){
        this.data.check_list.state = 'processing'
        this.service.updateCheckListstate({
          p_id:this.id,
          c_id:this.check_listsID,
          data:{state:'processing'}
        }).subscribe((res:any)=>{
        })
      }
      jQuery('.CompleteSelectedSection__items-remaining').css({'color':'#819099'});
    }, error=>{
      alert(error.error.errors)
      })
  }


  updateUserInfo(event:any,i:any,sub:any){
    let data={
      value:event.target.value,
      part:this.num,
      item:i,
      sub_item:sub
    }
    
      this.updateInfo(data)
      // if(!this.inputInfoId) return
      // setTimeout(()=>{
      //   jQuery(`#${this.inputInfoId}`).select()
      //   this.inputInfoId = ""
      // },1000)
  }
  input_getinfoInputChange(i:any,sub:any){
    this.inputInfoId = `input_getinfo${i}${sub}`
    
  }

  changeSelect(event:any,j:any,i:any,sub:any){
    let data={
      value:j,
      part:this.num,
      item:i,
      sub_item:sub
    }
    this.checkYes = 3
    this.updateInfo(data)
  }
  changeCheckbox(val:any,j:any,i:any,sub:any,subitem:any){
    let arrayIndex = []

    for(let item of val.target.parentNode.parentNode.children ) {
      let targetValue =  Number(item.childNodes[0].value)
      if(item.childNodes[0].checked == true&&arrayIndex.indexOf(targetValue)==-1){
        arrayIndex.push(targetValue)
      }
      // else if (item.childNodes[0].checked == false&&arrayIndex.indexOf(targetValue)>=0){
      //   let i =arrayIndex.indexOf(targetValue)
      //   arrayIndex.splice(i,1)
      // }
    }

    // if(val.target.checked == true&&arrayIndex.indexOf(j)==-1){
    //   arrayIndex.push(Number(j))
    // }else if (val.target.checked == false&&arrayIndex.indexOf(Number(j))>=0){
    //   let i =arrayIndex.indexOf(Number(j))
    //   arrayIndex.splice(i,1)
    // }
    let data={
      value:arrayIndex,
      part:this.num,
      item:i,
      sub_item:sub
    }
    this.updateInfo(data)
  }

  period_endChange(e:any,i:any,sub:any,subitem:any) {
    if(this.data.check_list.state == 'Closed') {
      return
    }
    if( this.data.check_list.created_by_id == this.userId){

    } else {
      if(this.data.check_list.state == 'New' || !!this.data.check_list.recheck) {
        return
      } else {
        if(this.data.check_list.state.includes('Sent')&& (this.data.check_list.executor_id !== this.userId && this.data.check_list.assigned_to_id !== this.userId)){
          return
        }
        if(this.data.check_list.state.includes('Complete')&& (this.data.check_list.executor_last_id !== this.userId && this.data.check_list.assigned_to_last_id !== this.userId)){
          return
        }
      }
    }
      laydate.render({
        elem: `#period_end${i}${sub}`
        ,lang: 'en'
        ,show:true
        ,value:e
        ,done:(value:any)=>{
           let val = value
           let data={
            value:val,
            part:this.num,
            item:i,
            sub_item:sub
          }
          this.updateInfo(data)
        }
      });
    
  }

  newSelect(val:any,i:any,sub:any){
    let data={
      value:val.target.value,
      part:this.num,
      item:i,
      sub_item:sub
    }
    this.updateInfo(data)
  }

  addRemark(event:any,i:any){
    let data={
      remark:event.target.value,
      part:this.num,
      item:i
    }
    this.updateInfo(data)
  }
  showNote(item:any,i:any){
    if(this.data.check_list.state == 'Closed') {
      return
    }
    if( this.data.check_list.created_by_id == this.userId){

    } else {
      if(this.data.check_list.state == 'New' || !!this.data.check_list.recheck) {
        return
      } else {
        if(this.data.check_list.state.includes('Sent')&& (this.data.check_list.executor_id !== this.userId && this.data.check_list.assigned_to_id !== this.userId)){
          return
        }
        if(this.data.check_list.state.includes('Complete')&& (this.data.check_list.executor_last_id !== this.userId && this.data.check_list.assigned_to_last_id !== this.userId)){
          return
        }
      }
    }
    this.remarkFlag = true
    this.remark_item = item
    this.remark_id = i

  }

  uploadImg(item:any,i:any){

    if(this.data.check_list.state == 'Closed') {
      return
    }
    if( this.data.check_list.created_by_id == this.userId){

    } else {
      if(this.data.check_list.state == 'New' || !!this.data.check_list.recheck) {
        return
      } else {
        if(this.data.check_list.state.includes('Sent')&& (this.data.check_list.executor_id !== this.userId && this.data.check_list.assigned_to_id !== this.userId)){
          return
        }
        if(this.data.check_list.state.includes('Complete')&& (this.data.check_list.executor_last_id !== this.userId && this.data.check_list.assigned_to_last_id !== this.userId)){
          return
        }
      }
    }
  
    this.doc_item = item
    //如果不是同一个item
    this.doc_item_id = i

    this.uploadFileRef.nativeElement.click();
  }

  selectFile(event:any,item:any,i:any){
    this.showLoading = true
    let files = event.target.files;
    let file = new FormData();
    file.append('file', files[0]);
    this.service.update_img({
      p_id:this.id,
      c_id:this.check_listsID,
      file
    }).subscribe((res: any) => {
      if(this.doc_item.props.image){
        this.templateid = this.doc_item.props.image.concat ({
          doc_id:res.doc_id,
          status:''
        })
      }else{
        this.templateid.push({
          doc_id:res.doc_id,
          status:''
        })
      }
      let data={
        part:this.num,
        item:this.doc_item_id,
        image:this.templateid
      }
      this.updateInfo(data)
      this.templateid = []
      this.uploadFileRef.nativeElement.value = '';
      this.showLoading = false
    })
  }

  updateUserCheckList(datas:any){
    let data=datas
    this.service.updateCheckList({
      p_id:this.id,
      c_id:this.check_listsID,
      data:data
    }).subscribe((res: any) => {
    })
  }

  changesUser(e:any){
    if(e == 'null'){
      this.assigned_to_id = null
    }else{
      this.assigned_to_id = e
    }
    
    this.updateUserCheckList({assigned_to_id:this.assigned_to_id})
  }

  changeLocations(e:any){
    this.check_list_location_id = e
    if(e == 'null'){
      this.check_list_location_id = null
    }else{
      this.check_list_location_id = e
    }
    this.updateUserCheckList({check_list_location_id:this.check_list_location_id})
  }

  colseSign(e:any){
    if(e){
      this.signShow = false
      const that = this
      this.service.uploadFile({p_id:this.id,c_id:this.check_listsID,file:e}).subscribe((res:any)=>{
        // this.doc_idImg = `http://localhost:3000/api/v3/logical_docs/doc/${res.doc_id}/stream`
        this.doc_idImg = res.doc_id

        that.service.update_sign(that.check_listsID, {sign:that.autographsignitem,doc_id:res.doc_id}).subscribe((res:any)=>{
          that.getInfo()
        })
      })
    }else{
      this.signShow = false
    }
      
  }

  lodingqianming(index:any,item:any){
    // const currentUser: any = document.querySelector('meta[name=current_user]');
    // if(currentUser.dataset.name===this.signBy[index].username){
    //   this.signShow = true
    //   this.autographsignitem = index
    // }else{
    //   alert(`${this.i18n.t("js.check-lists.quest.NotSigner")}`)
    // }
   
      this.signShow = true
      this.autographsignitem = index
   
  }

  okTable(){
    this.updateUserCheckList({state:"finished"})
    this.data.check_list.state = "finished"
    this.show_banner = false
  }

  allLogShow() {
    if(this.data.check_list.state === 'Closed' ||((this.data.check_list.created_by_id !=this.userId || (this.data.check_list.state != 'New' && !this.data.check_list.state.includes('Completed'))) && (this.data.check_list.assigned_to_id != this.userId || !this.data.check_list.state.includes('Sent')))) {
      return
    }
    this.allLog = !this.allLog
  }
  toEditpic(index:any,item:any,subitem:any,bigindex:any){
    this.doc_item_id = bigindex
    this.tempIndex = index
    this.templateid=item
    this.showEditDiv = true
    this.imageData = subitem
    this.imageLeftData.src = this.host+'/api/v3/logical_docs/doc/'+this.imageData.doc_id+'/stream.jpg'
    
  }
  actionRequirBoolean() {
    return ((this.data.check_list.state != 'New' && this.data.check_list.state != 'Completed'
    && !!this.data.check_list.recheck == false ) || this.data.check_list.created_by_id != this.userId)
  }
  goEdit(e:any){
    this.imageData.status = e.target.value
  }
  checkObj(a:any){  
    for (var i in a) {  
      return true
    }
    return false 
  }
  goDelete(){
    var msg = "确定删除图片吗?"; 
    if (confirm(msg)==true){ 
     this.templateid.splice(this.tempIndex,1) 
    }
    let data={
      part:this.num,
      item:this.doc_item_id,
      image:this.templateid
    }
    this.updateInfo(data)
    this.showEditDiv = false
  }
  closeBtn(){
    this.showEditDiv = false
    this.showimgEdit=false
    this.templateid = []

  }
  confirmEdit(){
    this.showLoading = true
    this.showimgEdit=false
    if(this.imageData.status =='before'){
      this.imageData.following_action_token =''
    }else{
      this.imageData.finding = ''
      this.imageData.location = ''
    }
    this.templateid[this.tempIndex] = this.imageData
    let data={
        part:this.num,
        item:this.doc_item_id,
        image:this.templateid
      }
      this.updateInfo(data)

    this.showEditDiv = false
    this.showLoading = false
    this.templateid = []
    
  }

  displaysign(type:any){
    if(type == 'action') {
      if((this.data.check_list.state!='New' && !this.data.check_list.state.includes('Completed') && !!this.data.check_list.recheck == false ) || this.data.check_list.created_by_id != this.userId) {
        return
      }
    } else if(type == 'reply'){
      console.log(this.data.check_list.executor_id != this.userId ,  this.data.check_list.recheck == 'recheck' , this.data.check_list.assigned_to_id != this.userId , !this.data.check_list.state.includes('Sent'))
      if((this.data.check_list.executor_id != this.userId  && this.data.check_list.assigned_to_id != this.userId||  this.data.check_list.recheck == 'recheck' || this.data.check_list.recheck == 'recheck_two' ) || !this.data.check_list.state.includes('Sent')) {
        return
      }
    } else {
      if((this.data.check_list.executor_last_id != this.userId && this.data.check_list.assigned_to_last_id != this.userId||  this.data.check_list.recheck == 'recheck_two' ) || !this.data.check_list.state.includes('Completed')) {
        return
      }
    }
    this.signType = type

    this.dipslesignature = true
  }
  cleon(){
    this.removeDownLoadIframe()
    this.close.emit()
    if(window.location.href.indexOf('check_listsID')>0){
      let newUrl = window.location.href.split('?')[0]+'?kind_id='+this.kind_id
      window.history.replaceState(null, '', newUrl)
    }
  }

  // wanchengpardclick(item:any){
  //   this.showNum = true
  //   let needWrite:any=[]
  //   let state
  //   let count:any=0
  //   let sumcount:any=0
  //   let part = this.num
  //   for(let k in item){
  //     //计算必填项的数
  //     for(let subitem of item[k].list){
  //       sumcount++
  //       if(subitem.necessary){
  //         needWrite.push(subitem.title)
  //       }
  //       if(subitem.necessary&&(subitem.value||typeof(subitem.value)=='number')){
  //         count++
  //       }

  //     }
  //   }
  //   this.needNum=needWrite.length-count
  //   this.hascount = sumcount-count
  //     if(count==needWrite.length){
  //       state = 'finished'
  //       jQuery('.CompleteSelectedSection__items-remaining').css({'color':'#819099'});
  //       this.service.update_part_state({p_id:this.id,c_id:this.check_listsID,data:{part,state}}).subscribe((res:any)=>{
  //         this.getInfo()
  //             this.hascount =0
  //       })
  //     }else{
  //       state = 'pending'
  //       jQuery('.CompleteSelectedSection__items-remaining').css({'color':'red'});
  //     }
  // }
  wanchengpardclick(item:any){
    this.showNum = true
    let needWrite:any=[]
    let needWriteAll:any=[]
    let state
    let count:any=0
    let sumcount:any=0
    let countAll:any=0
    let sumcountAll:any=0
    let part = this.num
    for(let k in item){
      //计算必填项的数
      for(let subitem of item[k].list){
        sumcount++
        if(subitem.necessary){
          needWrite.push(subitem.title)
        }
        if(subitem.necessary&&(subitem.value||typeof(subitem.value)=='number')){
          count++
        }

      }
    }
    this.needNum=needWrite.length-count
    this.hascount = sumcount-count
      if(count==needWrite.length){
        state = 'finished'
        let  complete = true
        this.data.check_list.check_list_metum.parts.forEach( (item:any, num:any) => {
          for(let k in item.items){
            //计算必填项的数
            for(let subitem of item.items[k].list){
              sumcountAll++
              if(subitem.necessary){
                needWriteAll.push(subitem.title)
              }
              if(subitem.necessary&&(subitem.value||typeof(subitem.value)=='number')){
                countAll++
              }
      
            }
          }
          console.log(countAll, needWriteAll)
          if(countAll !== needWriteAll.length) {
            complete = false
          }
          needWriteAll = []
          countAll = 0
        })
        jQuery('.CompleteSelectedSection__items-remaining').css({'color':'#819099'});
        this.service.update_part_state({ p_id:this.id,c_id:this.check_listsID,data:{part,state,'complete?': complete}}).subscribe((res:any)=>{
          this.getInfo()
              this.hascount =0
        })
      }else{
        state = 'pending'
        jQuery('.CompleteSelectedSection__items-remaining').css({'color':'red'});
      }
  }
  autograph(username:any,finished_at:any){
    let text
    if(this.i18n.locale == 'en'){
      text = `Signed by ${username} from Chun Wo on ${finished_at}`
    }else if(this.i18n.locale == 'zh-CN' || this.i18n.locale == 'zh-TW'){
      text = `由${username}于${finished_at}签名`
    }
    return text
  }

  issueclone(){
    this.issueblock=false
  }
  //获取新增task fileRefNo 数据
  public getFileRefNo(project_id: string) {
    this.kind_id = location.search.split('?')[1].split('&')[0].split('=')[1]
    const type = this.checklistArr[this.kind_id];
    this.service.fileRefNo(project_id,type).subscribe((res:any) => {
      this.zone.run(() => {
        if (res.data.is_dwss_type) {
          this.isFileRefNo = true;
          this.fileRefNo = res.data;
          if (res.data.values.length > 0) {
            this.fileRefNoType = this.getStringValue(res.data.values[0].value) || "";
          }
          // this.hook.globalVar.next([{ prefix:this.fileRefNo.prefix,type_value: this.fileRefNoType}]);
        } else {
          this.isFileRefNo = false;
        }
      });
    });
  }

  //获取task fileRefNo 数据
  public getFileRefNoInfo() {
    const obj_id = this.check_listsID;
    this.kind_id = location.search.split('?')[1].split('&')[0].split('=')[1]
    const obj_type =this.checklistArr[this.kind_id] ;
    this.service.fileRefNoInfo(obj_id, obj_type).subscribe((res:any) => {
      this.zone.run(() => {
        if (res.is_dwss_type) {
          this.isFileRefNo = true;
          this.fileRefNoInfo = res.data;
          this.fileRefNo = {};
        } else {
          this.getFileRefNo(this.id);
          this.isFileRefNo = false;
        }
      });
    });
  }

  //提交refno
  public saveFileRefNo() {
    const obj_type: string = 'CheckList' ;
    let fileRefNoType = JSON.parse(this.fileRefNoType)
    this.service
      .saveFileRefNo({
        obj_id: this.check_listsID,
        obj_type,
        prefix: this.fileRefNo.prefix,
        type_value: fileRefNoType,
      })
      .subscribe((res:any) => {
        if (res.result == "ok") {
          this.getFileRefNoInfo();
        } else {
          alert(res.reason);
        }
        this.service.getCheckList(this.id,this.check_listsID).subscribe((res: any) => {
          this.data.check_list.file_ref_no = res.check_list.file_ref_no
          this.saveFileFlag = false
        })    
      });
    
    }

  //适配手机端
  asideClick(e:boolean){
    let diviceWidth = document.documentElement.clientWidth;
    if(diviceWidth>310&&diviceWidth<770){
      if(e){
        jQuery(".content-date-left").css("display","block")
        jQuery(".content-detailed").css("display","none")
        jQuery(".asideHide").css("display","block")
        jQuery(".asideShow").css("display","none")
        jQuery(".detailShow").css("display","block")
      }else{
        jQuery(".content-date-left").css("display","none")
        jQuery(".asideShow").css("display","block")
        jQuery(".asideHide").css("display","none")
      }
    }
  }

  detailClick(e:any){
    let diviceWidth = document.documentElement.clientWidth;
    if(diviceWidth>310&&diviceWidth<770){
      if(e){
        jQuery(".content-detailed").css("display","block")
        jQuery(".content-date-left").css("display","none")
        jQuery(".detailShow").css("display","none")
        jQuery(".asideShow").css("display","block")
        jQuery(".asideHide").css("display","none")

      }else{
        jQuery(".content-detailed").css("display","none")
        jQuery(".detailShow").css("display","block")

      }
    }
  }

  //切换的字体颜色问题
   changeNum(e:any){
     this.num +=e
     jQuery('.CompleteSelectedSection__items-remaining').css({'color':'#819099'});
     this.showNum = false
   }
     //鼠标移入显示
  showAftername(i:any){
    let aftername:any = document.querySelectorAll(".aftername")
    aftername[i].style.display ="inline-block"
  }
  hideAftername(i:any){
    let aftername:any = document.querySelectorAll(".aftername")
    aftername[i].style.display ="none"
  }


}