<div class="createContent" id="liftingcontent">
  <!-- *****创建人员或者设备的时候出现的列表开始***** -->
  <div class="mantlediv" *ngIf="showMantle">
    <div class="mantleTable" *ngIf="!materialFlag">
      <span class="closeIcon" (click)="closeBtn()"></span>
      <h2>Add {{ title }}</h2>
      <!-- <div class="middleInput"><input type="text" /><button>Search</button></div> -->

      <!-- 下面的表格 -->
      <div class="table">
        <div class="tablecontent">
          <div class="work-packages-split-view--tabletimeline-content mainContent">
            <div
              class="work-packages-tabletimeline--table-side work-package-table--container __hidden_overflow_container"
            >
              <table class="keyboard-accessible-list generic-table work-package-table modulelistTable">
                <thead class="-sticky">
                  <tr style="border-bottom: 1px solid #e7e7e7">
                    <th class="wp-table--table-header headcenter">No,</th>
                    <th class="headcenter">State</th>
                    <th class="headcenter" *ngIf="roleFlag">Name Cn</th>
                    <th class="headcenter" *ngIf="roleFlag">Name EN</th>
                    <th class="headcenter" *ngIf="roleFlag">Employer Company</th>
                    <th class="headcenter" *ngIf="roleFlag">Site Pass No</th>
                    <th class="headcenter" *ngIf="roleFlag">Role</th>
                    <th class="headcenter" *ngIf="!roleFlag">Type of Crane</th>
                    <th class="headcenter" *ngIf="!roleFlag">Subtype</th>
                    <th class="headcenter" *ngIf="!roleFlag">Manufacturer</th>
                    <th class="headcenter" *ngIf="!roleFlag">Saft Working Load</th>
                    <th class="headcenter" *ngIf="!roleFlag">Working Radius</th>
                    <th class="headcenter">Shortcut</th>
                  </tr>
                </thead>
                <tbody class="results-tbody work-package--results-tbody" *ngFor="let item of tableList; let i = index">
                  <tr
                    tabindex="0"
                    data-work-package-id="303"
                    data-class-identifier="wp-row-303"
                    class="wp-table--row wp--row wp-row-303 wp-row-303-table issue __hierarchy-root-303"
                    (click)="selectUse(i, item)"
                  >
                    <td class="headcenter">{{ item.id }}</td>
                    <td class="headcenter">{{ item.state }}</td>
                    <td class="headcenter" *ngIf="roleFlag">{{ item.name_en }}</td>
                    <td class="headcenter" *ngIf="roleFlag">{{ item.name_en }}</td>
                    <td class="headcenter" *ngIf="roleFlag">{{ item.employer_company }}</td>
                    <td class="headcenter" *ngIf="roleFlag">{{ item.site_pass_no }}</td>
                    <td class="headcenter" *ngIf="roleFlag">{{ getRole(item.roles) }}</td>
                    <td class="headcenter" *ngIf="!roleFlag">{{ item.type }}</td>
                    <td class="headcenter" *ngIf="!roleFlag">{{ item.sub_type }}</td>
                    <td class="headcenter" *ngIf="!roleFlag">{{ item.manufacturer }}</td>
                    <td class="headcenter" *ngIf="!roleFlag">{{ item.safe_load }}</td>
                    <td class="headcenter" *ngIf="!roleFlag">{{ item.working_radius }}</td>
                    <td class="headcenter">
                      <span class="viewspan" (click)="selectUse(i, item)" *ngIf="i != index">Apply</span
                      ><span class="viewspan" (click)="selectUse(i, item)" *ngIf="i == index">Already used</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <button class="confirmbutton" (click)="confrim()">Confirm</button>
        </div>
      </div>
    </div>
    <div class="materialDiv" *ngIf="materialFlag">
      <h2>Add MaterialItem</h2>
      <span class="closeIcon" (click)="closeBtn()"></span>
      <div class="formDiv">
        <p><span class="titlespan">Name:</span><input type="text" [(ngModel)]="newMaterial.name" /></p>
        <p><span class="titlespan">Weight:</span><input type="number" [(ngModel)]="newMaterial.weight" /></p>
        <p>
          <span class="titlespan">Remarkable:</span
          ><input type="checkbox" (change)="this.newMaterial.remarkable = !this.newMaterial.remarkable" />
        </p>
        <p *ngIf="this.newMaterial.remarkable">
          <span class="titlespan">Remark:</span><input type="text" [(ngModel)]="newMaterial.remark" />
        </p>
        <button (click)="confirmAddmaterial()">Confirm Add</button>
      </div>
    </div>
  </div>
  <!-- *****创建人员或者设备的时候出现的列表护额试过也***** -->

  <div class="topfixed" id="fixdiv">
    <h1>Create e-lifting</h1>
    <!-- *****头部的导航开始***** -->
    <ul class="toplist">
      <div class="submitbtn">
        <button (click)="cancel()" style="background-color: #f56c6c">Cancel</button>
        <button (click)="save()" style="background-color: #e6a23c">Save</button>
        <button style="background-color: #67c23a" (click)="submit()">Submit</button>
      </div>
      <li><a href="#index1">1.Basic Information</a></li>
      <li><a href="#index2">2.Information of Tower Cranes & Lifting Gears</a></li>
      <li><a href="#index3">3.Information of Operator</a></li>
      <li><a href="#index4">4.Information of involved Materials</a></li>
      <li><a href="#index5">5.Consider Potential Danger Area</a></li>
      <li><a href="#index6">6.Checking Items</a></li>
      <li><a href="#index7">7.Confirmation and Issue of Lifting Permit</a></li>
    </ul>
    <hr class="hrstyle" />
  </div>
  <!-- *****头部的导航结束***** -->

  <!-- 主内容 -->
  <div class="bottomdiv">
    <!-- loading动画效果 -->
    <div class="SectionItem__loading loadingDiv" *ngIf="showLoading">
      <div class="loading-indicator -compact">
        <div class="block-1"></div>
        <div class="block-2"></div>
        <div class="block-3"></div>
        <div class="block-4"></div>
        <div class="block-5"></div>
      </div>
    </div>
    <!-- 第一步填写基本信息 -->
    <div class="formcontent" id="index1">
      <p>1.Basic Information</p>
      <table class="firsttable">
        <tr>
          <td class="spantd">Contract No.</td>
          <td class="containTime"><input type="text" [(ngModel)]="data.contract_no" /></td>
        </tr>
        <tr>
          <td class="spantd">Work Location*</td>
          <td class="containTime">
            <select style="font-size: 13px; line-height: unset" [(ngModel)]="data.work_location_id">
              <option *ngFor="let item of locationArr" [value]="item.id">{{ item.name }}</option>
            </select>
          </td>
        </tr>
        <tr>
          <td class="spantd">Permit No.</td>
          <td class="containTime"><input type="text" [(ngModel)]="data.permit_no" disabled /></td>
        </tr>
        <tr>
          <td class="spantd">Permit Valid Date</td>
          <td class="containTime">
            <div class="summary-value noclick" id="permit_valid_date" (click)="dateFormChange('permit_valid_date')">
              {{ data.permit_valid_date.split(" ")[0] }}
            </div>
          </td>
        </tr>
        <tr>
          <td class="spantd">Permit Valid Time*</td>
          <td class="containTime">
            <span>from</span>
            <div class="summary-value" id="permit_valid_time_from" (click)="dateFormChange('permit_valid_time_from')">
              {{ data.permit_valid_time_from }}
            </div>
            <span>to</span>
            <div class="summary-value" id="permit_valid_time_to" (click)="dateFormChange('permit_valid_time_to')">
              {{ data.permit_valid_time_to }}
            </div>
          </td>
        </tr>
      </table>
    </div>
    <!-- 第二步设备的信息 -->
    <div class="setmargin" id="index2">
      <div class="secondContent">
        <p>2. Information of Tower Cranes & Lifting Gears</p>
        <div class="cranecontent">
          <!-- 起重机 -->
          <div class="craneitem" *ngFor="let item of plantArr; let i = index">
            <p *ngIf="i == 0">Crane</p>
            <p *ngIf="i != 0">Lifting Gear {{ i }}</p>
            <div class="editbtn">
              <button class="sonbtn" (click)="addItem('plant','Crane',i)">Select</button>
              <button class="sonbtn" (click)="pushQrcodeClick('plants',i)">Scan QR code</button>
            </div>
            <table class="firsttable">
              <tr *ngIf="item.plant_type != 'LiftingGear'">
                <td class="spantd">Plant Type</td>
                <td>
                  <select [(ngModel)]="item.plant_type" [disabled]="!(editFlag&&i==editNum)">
                    <option value="MobileCrane">Mobile Cranes</option>
                    <option value="TowerCrane">Tower Cranes</option>
                    <option value="Other">Others</option>
                  </select>
                </td>
              </tr>
              <tr *ngIf="item.plant_type == 'MobileCrane'">
                <td class="spantd">Subtype:</td>
                <td>
                  <select [(ngModel)]="item.sub_type" [disabled]="!(editFlag&&i==editNum)">
                    <option value="crawler">Crawler</option>
                    <option value="wheeled telescopic">Wheeled Telescopic</option>
                    <option value="truck">Truck</option>
                    <option value="gantry">Gantry</option>
                    <option value="derrick">Derrick</option>
                    <option value="tower">Tower</option>
                  </select>
                </td>
              </tr>
              <tr *ngIf="item.plant_type == 'Other'">
                <td class="spantd">SubType</td>
                <td><input type="text" [(ngModel)]="item.sub_type" [disabled]="!(editFlag&&i==editNum)"/></td>
              </tr>
              <tr *ngIf="item.plant_type != 'LiftingGear'">
                <td class="spantd">Plant No</td>
                <td><input type="text" [(ngModel)]="item.plant_no" [disabled]="!(editFlag&&i==editNum)"/></td>
              </tr>
              <tr *ngIf="item.plant_type != 'LiftingGear'">
                <td class="spantd">Max Safe Working Load Lifting Radius</td>
                <td class="doubleSpan">
                  <input class="range" type="number" [(ngModel)]="item.working_radius_from" [disabled]="!(editFlag&&i==editNum)"/>-
                  <input class="range" type="number" [(ngModel)]="item.working_radius_to" [disabled]="!(editFlag&&i==editNum)"/>
                </td>
              </tr>
              <tr *ngIf="item.plant_type != 'LiftingGear'">
                <td class="spantd">Max Safe Working Load Lifting Capacity:</td>
                <td class="doubleSpan">
                  <input class="range" type="number" [(ngModel)]="item.working_capacity_from" [disabled]="!(editFlag&&i==editNum)"/>-
                  <input class="range" type="number" [(ngModel)]="item.working_capacity_to" [disabled]="!(editFlag&&i==editNum)"/>
                </td>
              </tr>
              <tr *ngIf="item.plant_type == 'LiftingGear'">
                <td class="spantd">Safe Working Load</td>
                <td><input type="number" [(ngModel)]="item.working_load" [disabled]="!(editFlag&&i==editNum)"/></td>
              </tr>
              <tr *ngIf="item.plant_type == 'LiftingGear'">
                <td class="spantd">ID Mark</td>
                <td><input type="text" [(ngModel)]="item.plant_no" [disabled]="!(editFlag&&i==editNum)"/></td>
              </tr>
              <tr *ngIf="item.plant_type != 'LiftingGear'">
                <td class="spantd">Danger Zone Ground</td>
                <td><input type="number" [(ngModel)]="item.danger_zone_ground" [disabled]="!(editFlag&&i==editNum)"/></td>
              </tr>
              <tr *ngIf="item.plant_type != 'LiftingGear'">
                <td class="spantd">Danger Zone Roof</td>
                <td><input type="number" [(ngModel)]="item.danger_zone_roof" [disabled]="!(editFlag&&i==editNum)"/></td>
              </tr>
              <tr *ngIf="item.plant_type != 'LiftingGear'">
                <td class="spantd">Danger Zone Others</td>
                <td><input type="number" [(ngModel)]="item.danger_zone_others" [disabled]="!(editFlag&&i==editNum)"/></td>
              </tr>
              <tr>
                <td class="spantd">Expire Date of Cert</td>
                <td>
                  <div class="timeDiv" [id]="'cert_valid_date_to' + i" (click)="dateChange('cert_valid_date_to', i)" [ngStyle]="{'background': !(editFlag&&i==editNum)?'#f2f2f2':''}">
                    {{ item.cert_valid_date_to }}
                  </div>
                </td>
              </tr>
              <tr>
                <td class="spantd">Photo</td>
                <td>
                  <button class="bluebtn green" *ngIf="!editFlag" (click)="changeEditPlant(i)">Edit</button>
                  <button class="bluebtn green" *ngIf="editFlag&&i==editNum" (click)="saveEditPlant(i,item)">Save</button>
                </td>
              </tr>
              <tr>
                <td>
                  <button class="bluebtn green" (click)="uploadPhoto('plant', i)">
                    Add photos
                    <input
                      type="file"
                      style="display: none"
                      [id]="'plantPhoto' + i"
                      accept="image/jpg, image/jpeg, image/png"
                      (change)="selectFile('plant', $event, item,i)"
                    />
                  </button>
                </td>
                <td>
                  <button *ngIf="i!=0"
                    class="bluebtn green"
                    style="width: 130px; background: #f56c6c"
                    (click)="remove('plantArr', item.id, i)"
                  >
                    Remove
                  </button>
                </td>
              </tr>
            </table>
            <div *ngIf="item.photo_doc_ids" class="showPic">
              <div
                (click)="deletePhoto(item, subitem, i)"
                *ngFor="let subitem of item.photo_doc_ids"
                class="deletepic"
              >
                <img [src]="host + '/api/v3/logical_docs/doc/' + subitem + '/stream'+'?image_size=thumbnail'" />
              </div>
            </div>
          </div>
        </div>
        <div class="addbtn">
          <button class="bluebtn icon-add longbtn" (click)="addLifting('plant')">Add Lifting Gear</button>
        </div>
      </div>
    </div>
    <!-- 第三步添加人员的信息 -->
    <div class="setmargin" id="index3">
      <div class="secondContent">
        <p>3.Information of Operator</p>
        <div class="cranecontent">
          <!-- 操作人员 -->
          <div class="craneitem" *ngFor="let item of labourArr; let i = index">
            <div class="editbtn">
              <button class="sonbtn" (click)="addItem('labour','Operator',i)">Select</button>
              <button class="sonbtn" (click)="pushQrcodeClick('labours',i)">Scan QR code</button>
            </div>
            <p>{{ item.role }}</p>
            <table class="firsttable">
              <tr>
                <td class="spantd">Name of {{item.role}}</td>
                <td>
                  <input type="text" [(ngModel)]="item.name_en" [disabled]="!(laboureditFlag&&i==laboureditNum)"/>
                </td>
              </tr>
              <tr>
                <td class="spantd">Site Pass No.</td>
                <td>
                  <input type="text" [(ngModel)]="item.site_pass_no" [disabled]="!(laboureditFlag&&i==laboureditNum)"/>
                </td>
              </tr>
              <tr>
                <td class="spantd">Certificate No.</td>
                <td>
                  <input type="text" [(ngModel)]="item.cert_no" [disabled]="!(laboureditFlag&&i==laboureditNum)"/>
                </td>
              </tr>
              <tr>
                <td class="spantd" *ngIf="i==0">Valid Date of Certificate.</td>
                <td class="spantd" *ngIf="i!=0">Valid Date of A12 Cert. No.</td>
                <td>
                  <div class="timeDiv" [id]="'no'+i" (click)="labourChange('no',i)" [ngStyle]="{'background': !(laboureditFlag&&i==laboureditNum)?'#f2f2f2':''}">
                    {{ item.cert_valid_date_to }}
                  </div>
                </td>
              </tr>
              <tr>
                <td class="spantd">Edit</td><td>
                    <button class="bluebtn green" *ngIf="!laboureditFlag" (click)="changeEditLabour(i)">Edit</button>
                    <button class="bluebtn green" *ngIf="laboureditFlag&&i==laboureditNum" (click)="saveEditLabour(i,item)">Save</button>
                </td>
              </tr>
              <tr>
                <td class="spantd">Photo</td>
                <td>
                  <button class="bluebtn green" (click)="uploadPhoto('labour', i)">
                    Add photos
                    <input
                      type="file"
                      style="display: none"
                      [id]="'labourPhoto' + i"
                      accept="image/jpg, image/jpeg, image/png"
                      (change)="selectFile('labour', $event, item,i)"
                    />
                  </button>
                  <button *ngIf="i!=0"
                    class="bluebtn"
                    style="width: 130px; margin-top: 15px; background: #f56c6c"
                    (click)="remove('labourArr', item.id, i, item.role)"
                  >
                    Remove
                  </button>
                </td>
              </tr>
            </table>
            <div *ngIf="item.photo_doc_ids" class="showPic">
              <div
                (click)="deletelabourPhoto(item, subitem, i)"
                *ngFor="let subitem of item.photo_doc_ids"
                class="deletepic"
              >
                <img [src]="host + '/api/v3/logical_docs/doc/' + subitem + '/stream'+'?image_size=thumbnail'" />
              </div>
            </div>
          </div>
        </div>
        <div class="addbtn">
          <!-- <button class="bluebtn icon-add" (click)="addItem('Operator')">Add Operator</button> -->
          <button class="bluebtn icon-add longbtn" (click)="addLifting('Banksman')">Add Banksman</button>
          <button class="bluebtn icon-add longbtn" (click)="addLifting('Rigger')">Add Rigger</button>
        </div>
      </div>
    </div>
    <!-- 第四步显示材料项目的选项 -->
    <div class="setmargin" id="index4">
      <div class="fourContent">
        <p>4.Information of involved Materials</p>
        <div class="cranecontent">
          <div class="title"><span>Involved Materials</span> <span class="numspan">Max.Weight(Ton))</span></div>
          <ul class="checkboxul">
            <li *ngFor="let item of checkboxItem; let i = index">
              <div class="inputdiv">
                <label [for]="'gogo' + i">
                  <input
                    type="checkbox"
                    [id]="'gogo' + i"
                    [checked]="item.checked"
                    (change)="changeBox($event, item)"
                  />{{ lang?item.name_cn:item.name }}</label
                >
                <input type="text" style="width: 100px" *ngIf="item.remarkable" [(ngModel)]="item.remark" disabled />
                <input
                  type="number"
                  min="0"
                  style="width: 200px"
                  [(ngModel)]="item.weight"
                  (blur)="changeWeight(item)"
                />
              </div>
              <button class="bluebtn addphoto" *ngIf="item.checked" (click)="uploadPhoto('material', i)">
                Add photo
                <input
                  type="file"
                  style="display: none"
                  [id]="'materialPhoto' + i"
                  accept="image/jpg, image/jpeg, image/png"
                  (change)="selectFile('material', $event, item, i)"
                />
              </button>
              <div *ngIf="item.photo_doc_ids.length > 0" class="showPic">
                <img
                  [src]="host + '/api/v3/logical_docs/doc/' + subitem + '/stream'+'?image_size=thumbnail'"
                  *ngFor="let subitem of item.photo_doc_ids"
                />
              </div>
              <span class="deleteMa" (click)="deleteMaterial(item)">x</span>
            </li>
          </ul>
        </div>
        <div class="addbtn">
          <button class="bluebtn icon-add longbtn" (click)="addMaterial()">Add Involved Material</button>
        </div>
      </div>
    </div>
    <!-- 第五步填写危险区工作的备注 -->
    <div class="fiveForm setmargin" id="index5">
      <p>5.Consider Potential Danger Area</p>
      <div class="fiveContent">
        <span class="textspan">Consider Potential Danger Area</span>
        <textarea cols="30" rows="10" [(ngModel)]="data.dangerous_zone_remarks"></textarea>
      </div>
    </div>
    <!-- 第六步添加检查项 -->
    <div class="sixForm setmargin" id="index6">
      <p>6.Checking Item</p>

      <div class="sixContent">
        <span class="bigspan">Checking items</span>
        <div class="select">
          <span>Pass</span>
          <span>Fail</span>
          <span>NA</span>
        </div>
      </div>
      <div class="sixContent" *ngFor="let item of checkedArr; let i = index">
        <span class="contentSpan">{{ i + 1 }}.{{ lang?item.content_cn:item.content }}</span>
        <div class="select">
          <input type="checkbox" [checked]="item.checked == 'pass'" (change)="changeCheck('pass', $event, item.id)" />
          <input type="checkbox" [checked]="item.checked == 'fail'" (change)="changeCheck('fail', $event, item.id)" />
          <input
            type="checkbox"
            [checked]="item.checked == 'not_applicable'"
            (change)="changeCheck('not_applicable', $event, item.id)"
          />
        </div>
        <span class="deleteMa" (click)="deleteCheck(item)">x</span>
      </div>
      <div class="sixContent" *ngIf="checkFlag">
        <input
          type="text"
          [placeholder]="i18n.t('js.attachments.e_lifting.plEnCT')"
          [(ngModel)]="newCheck.content"
          class="contentSpan"
          style="width: 500px"
        />
        <div class="select">
          <label for="pass">
            Pass
            <input type="checkbox" id="pass" (change)="addCheckitem('pass', $event)" />
          </label>
          <label for="fail">
            fail
            <input type="checkbox" id="fail" (change)="addCheckitem('fail', $event)" />
          </label>
          <label for="na">
            NA
            <input type="checkbox" id="na" (change)="addCheckitem('not_applicable', $event)" />
          </label>
        </div>
        <button (click)="addCheck()" *ngIf="checkFlag" class="addBtn">Confirm Add</button>
      </div>
      <div class="addbtn">
        <button class="bluebtn icon-add longbtn" (click)="checkFlag = true">Add CheckItem</button>
      </div>
    </div>

    <!-- 第七步完成授权 -->
    <div class="sevenForm setmargin" id="index7">
      <div class="sevenContent">
        <p>7. Confirmation and Issue of Lifting Permit</p>
        <div class="inputbox">
          <!-- <label for="dasheng">
            <input type="checkbox" id="dasheng" (change)="confirmAgree($event)"/><span class="lisen"
              >I understand that as a lifting permit applicant shall ensure the above mentioned checking items have been
              properly implemented and adopted all necessary safety precautions control measures.</span
            ></label
          > -->
          <table class="firsttable">
       
            <tr>
              <td class="spantd">Applicant Name</td>
              <td>{{ applicationConfig.applicant.firstname }} {{ applicationConfig.applicant.lastname }}</td>
            </tr>
            <tr>
              <td class="spantd">Date</td>
              <td>{{ applicationConfig.created_at.split(" ")[0] }}</td>
            </tr>
            <tr>
              <td class="spantd">Time</td>
              <td>{{ applicationConfig.created_at.split(" ")[1] }}</td>
            </tr>
            <tr>
              <td class="spantd">Approver</td>
              <td class="radiotd">
                <!-- <select style="font-size: 13px" [(ngModel)]="data.approver_id">
                  <option *ngFor="let item of approverArr" [value]="item.id">{{item.firstname}}</option>
                </select> -->
                <span class="approveRaido"><input type="radio" name="test" checked>ALL approver</span>
                <span class="approveRaido" *ngFor="let item of approverArr"><input type="radio" name="test" (click)="data.approver_id=item.id">{{item.firstname}}</span>
              </td>
            </tr>
          </table>
        </div>
        <wp-signature *ngIf="decideToSign" (closeView)="closeView($event)"></wp-signature>
      </div>
    </div>
  </div>

</div>

<!-- 扫码插件 -->
<scanQrcode *ngIf="displayQrcode" [scanQrcodeType]="scanQrcodeType" (callback)="scanQrcodeCallback($event)"></scanQrcode>