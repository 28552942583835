import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HandlerCheckObserveService {

constructor() { }
  public eventBus:EventEmitter<any> = new EventEmitter<any>()
}
