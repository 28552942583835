<table cellspacing="0" border="0" id="mainTable" *ngIf="version == 'v17'">
    <tr>
        <td class="tdborder noborderbottom" rowspan=3 height="90" align="center" valign=middle><b>
                <font size=2>Item<br>No.</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=3 align="left" valign=middle><b>
                <font size=2>Trade </font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=3 align="left" valign=middle><b>
                <font size=2>Trade Division</font>
            </b></td>
        <td class="tdborder" colspan=2 align="center" valign=middle><b>
                <font size=2>Trade List 1</font>
            </b></td>
        <td class="tdborder" colspan=31 align="center" valign=middle><b>
                <font size=2>Number of workers engaged on site on each calendar day b,c</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=3 align="center" valign=middle><b>
                <font size=2>Total <br>Man-days</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=3 align="center" valign=middle><b>
                <font size=2>Overtime d<br>(hours)</font>
            </b></td>
        <td class="tdborder" colspan=3 rowspan=2 align="center" valign=middle><b>
                <font size=2> Daily Wage Rate ($) e</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=3 align="center" valign=middle><b>
                <font size=2>Item<br>No.</font>
            </b></td>
        <td align="left" valign=bottom>
            <font size=2><br></font>
        </td>
        <td align="left" valign=bottom>
            <font size=2><br></font>
        </td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="left" valign=bottom>
            <font size=2><br></font>
        </td>
    </tr>
    <tr>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>Orig.</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>Rev.</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="1"><b>
                <font size=2>1</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="2"><b>
                <font size=2>2</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="3"><b>
                <font size=2>3</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="4"><b>
                <font size=2>4</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="5"><b>
                <font size=2>5</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="6"><b>
                <font size=2>6</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="7"><b>
                <font size=2>7</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="8"><b>
                <font size=2>8</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="9"><b>
                <font size=2>9</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="10"><b>
                <font size=2>10</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="11"><b>
                <font size=2>11</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="12"><b>
                <font size=2>12</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="13"><b>
                <font size=2>13</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="14"><b>
                <font size=2>14</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="15"><b>
                <font size=2>15</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="16"><b>
                <font size=2>16</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="17"><b>
                <font size=2>17</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="18"><b>
                <font size=2>18</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="19"><b>
                <font size=2>19</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="20"><b>
                <font size=2>20</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="21"><b>
                <font size=2>21</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="22"><b>
                <font size=2>22</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="23"><b>
                <font size=2>23</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="24"><b>
                <font size=2>24</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="25"><b>
                <font size=2>25</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="26"><b>
                <font size=2>26</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="27"><b>
                <font size=2>27</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="28"><b>
                <font size=2>28</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="29"><b>
                <font size=2>29</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="30"><b>
                <font size=2>30</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="31"><b>
                <font size=2>31</font>
            </b></td>
        <td align="left" valign=bottom>
            <font size=2><br></font>
        </td>
        <td align="left" valign=bottom>
            <font size=2><br></font>
        </td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="left" valign=bottom>
            <font size=2><br></font>
        </td>
    </tr>
    <tr>
        <td class="tdborder noborderbottom" align="center" valign=middle><b>
                <font size=2><br></font>
            </b></td>
        <td class="tdborder noborderbottom" align="center" valign=middle><b>
                <font size=2>(Code 2)</font>
            </b></td>
        <td class="tdborder noborderbottom" align="center" valign=middle><b>
                <font size=2>Av. </font>
            </b></td>
        <td class="tdborder noborderbottom" align="center" valign=middle><b>
                <font size=2>High </font>
            </b></td>
        <td class="tdborder noborderbottom" align="center" valign=middle><b>
                <font size=2>Low </font>
            </b></td>
        <td align="left" valign=bottom>
            <font size=2><br></font>
        </td>
        <td align="left" valign=bottom>
            <font size=2><br></font>
        </td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="left" valign=bottom>
            <font size=2><br></font>
        </td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="1"><b>
                <font face="Times New Roman" size=2>1</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Bar Bender &amp; Fixer </font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Bar Bender &amp; Fixer</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C304 / C404</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C304 / C404</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[0]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle sdval="1"><b>
                <font face="Times New Roman" size=2>1</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" rowspan=5 height="160" align="center" valign=middle sdval="2"><b>
                <font face="Times New Roman" size=2>2</font>
            </b></td>
        <td class="tdborder" rowspan=5 align="left" valign=middle><b>
                <font size=2>Concretor 3</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Concrete and Grouting Worker (Master)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C3016</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[1]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=5 align="center" valign=middle sdval="2"><b>
                <font face="Times New Roman" size=2>2</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Concretor</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C309 / C409</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C309 / C409</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Concrete Repairer (Spalling Concrete)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C308</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Shotcretor</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C342</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Grouting Worker</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C321</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" rowspan=3 height="168" align="center" valign=middle sdval="3"><b>
                <font face="Times New Roman" size=2>3</font>
            </b></td>
        <td class="tdborder" rowspan=3 align="left" valign=middle><b>
                <font size=2>Drainlayer 4</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Drain and Pipe Layer (Master)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C3006</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[2]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=3 align="center" valign=middle sdval="3"><b>
                <font face="Times New Roman" size=2>3</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Drainlayer</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C314</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C314 / C414</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Pipelayer</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C331</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="4"><b>
                <font face="Times New Roman" size=2>4</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Plumber 5</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Plumber</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C338 / C438</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C338 / C438</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[3]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="4"><b>
                <font face="Times New Roman" size=2>4</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="5"><b>
                <font face="Times New Roman" size=2>5</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Leveller </font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Leveller</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C323 / C423</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C323 / C423</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[4]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="5"><b>
                <font face="Times New Roman" size=2>5</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" rowspan=3 height="168" align="center" valign=middle sdval="6"><b>
                <font face="Times New Roman" size=2>6</font>
            </b></td>
        <td class="tdborder" rowspan=3 align="left" valign=middle><b>
                <font size=2>Scaffolder 6</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Scaffolder (Master)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C3019 / C4019</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[5]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=3 align="center" valign=middle sdval="6"><b>
                <font face="Times New Roman" size=2>6</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Bamboo Scaffolder</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C303 / C403</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C303 / C403</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Metal Scaffolder</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C327 / C427</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="7"><b>
                <font face="Times New Roman" size=2>7</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>General Workers 7</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C406</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[6]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="7"><b>
                <font face="Times New Roman" size=2>7</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" rowspan=6 height="336" align="center" valign=middle sdval="8"><b>
                <font face="Times New Roman" size=2>8</font>
            </b></td>
        <td class="tdborder" rowspan=6 align="left" valign=middle><b>
                <font size=2>Carpenter (Formwork) 8</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Carpenter (Fender)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C306</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[7]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=6 align="center" valign=middle sdval="8"><b>
                <font face="Times New Roman" size=2>8</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Carpenter (Formwork) (Master)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C307 / C407</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C307 / C407</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Carpenter (Formwork - Civil Construction)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C307b / C407b</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Carpenter (Formwork - Civil Construction) (Striking)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C307d</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Carpenter (Formwork - Building Construction)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C307a / C407a</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Carpenter (Formwork - Building Construction) (Striking)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C307c</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" rowspan=2 height="112" align="center" valign=middle sdval="9"><b>
                <font face="Times New Roman" size=2>9</font>
            </b></td>
        <td class="tdborder" rowspan=2 align="left" valign=middle><b>
                <font size=2>Joiner 9</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Joiner</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C322 / C422</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C322 / C422</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[8]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=2 align="center" valign=middle sdval="9"><b>
                <font face="Times New Roman" size=2>9</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Joiner (Assembling)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C322a</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" rowspan=16 height="896" align="center" valign=middle sdval="10"><b>
                <font face="Times New Roman" size=2>10</font>
            </b></td>
        <td class="tdborder" rowspan=16 align="left" valign=middle><b>
                <font size=2>Plant &amp; Equipment Operator (General) 10 </font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Ground Investigation Operator/Driller/Borer</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C320 / C420</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=16 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=16 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=16 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=16 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=16 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=16 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=16 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=16 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=16 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=16 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=16 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=16 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=16 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=16 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=16 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=16 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=16 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=16 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=16 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=16 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=16 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=16 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=16 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=16 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=16 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=16 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=16 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=16 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=16 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=16 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=16 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=16 align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[9]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=16 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=16 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=16 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=16 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=16 align="center" valign=middle sdval="10"><b>
                <font face="Times New Roman" size=2>10</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Plant and Equipment Operator (Fork-lift Truck)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C333f</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Plant and Equipment Operator (Mini-loader)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C333d</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Plant and Equipment Operator (Mini-loader (with Attachments))</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C333e</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Plant and Equipment Operator (Grader)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C333g</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Plant and Equipment Operator (Suspended Working Platform)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C357</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Plant and Equipment Operator (Demolition) - Excavator</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C356</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Plant and Equipment Operator (Excavator)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C333b</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Builder's Lift Operator</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C332</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Plant and Equipment Operator (Bulldozer)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C333a</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Plant and Equipment Operator (Truck-mounted Crane)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C334d</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Plant and Equipment Operator (Dumper)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C333h</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Plant and Equipment Operator (Loader)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C333c</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Plant and Equipment Operator (Locomotive)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C333i</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Plant and Equipment Operator (Compactor)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C333j</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Plant and Equipment Operator (Scraper)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C333k</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" rowspan=5 height="160" align="center" valign=middle sdval="11"><b>
                <font face="Times New Roman" size=2>11</font>
            </b></td>
        <td class="tdborder" rowspan=5 align="left" valign=middle><b>
                <font size=2>Truck Driver 11</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Construction Goods Vehicle Driver (Master)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C349</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C349</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[10]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=5 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=5 align="center" valign=middle sdval="11"><b>
                <font face="Times New Roman" size=2>11</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Truck Driver (Medium Goods Vehicles)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C349c</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Truck Driver (Heavy Goods Vehicles)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C349a</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Truck Driver (Special Purpose Vehicles)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C349b</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Truck Driver (Articulated Vehicles)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C349d</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" rowspan=3 height="168" align="center" valign=middle sdval="12"><b>
                <font face="Times New Roman" size=2>12</font>
            </b></td>
        <td class="tdborder" rowspan=3 align="left" valign=middle><b>
                <font size=2>Flooring Worker 12</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Floor Layer (Master)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C316 / C416</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[11]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=3 align="center" valign=middle sdval="12"><b>
                <font face="Times New Roman" size=2>12</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Floor Layer (Timber Flooring)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C316b / C416b</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Floor Layer (PVC Flooring)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C316a / C416a</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" rowspan=10 height="560" align="center" valign=middle sdval="13"><b>
                <font face="Times New Roman" size=2>13</font>
            </b></td>
        <td class="tdborder" rowspan=10 align="left" valign=middle><b>
                <font size=2>Plant &amp; Equipment Operator (Heavy) 13 </font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Plant and Equipment Operator (Piling)(Master)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C335 / C435</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=10 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=10 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=10 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=10 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=10 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=10 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=10 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=10 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=10 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=10 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=10 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=10 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=10 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=10 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=10 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=10 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=10 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=10 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=10 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=10 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=10 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=10 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=10 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=10 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=10 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=10 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=10 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=10 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=10 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=10 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=10 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=10 align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[12]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=10 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=10 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=10 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=10 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=10 align="center" valign=middle sdval="13"><b>
                <font face="Times New Roman" size=2>13</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Plant and Equipment Operator (Percussive Pile)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C335b / C435b</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Plant and Equipment Operator (Bored Pile)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C335a / C435a</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Plant and Equipment Operator (Tower Crane)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C334c</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Plant and Equipment Operator (Crawler-mounted Mobile Crane)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C334a</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Plant and Equipment Operator (Wheeled Telescopic Mobile Crane)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C334e</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Plant and Equipment Operator (Gantry Crane)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C334b</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Plant and Equipment Operator (Tunneling) - Jumbo Drilling</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C336a / C436a</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Plant and Equipment Operator (Tunneling) - Segment Erection</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C336c / C436c</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Plant and Equipment Operator (Tunneling) - Tunnel Boring Machine</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C336d / C436d</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="14"><b>
                <font face="Times New Roman" size=2>14</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>General Welder </font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>General Welder</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C318 / C418</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C318 / C418</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[13]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="14"><b>
                <font face="Times New Roman" size=2>14</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" rowspan=2 height="112" align="center" valign=middle sdval="15"><b>
                <font face="Times New Roman" size=2>15</font>
            </b></td>
        <td class="tdborder" rowspan=2 align="left" valign=middle><b>
                <font size=2>Metal Worker 14</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Metal-steel Worker (Master)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C3010 / C4010</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[14]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=2 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=2 align="center" valign=middle sdval="15"><b>
                <font face="Times New Roman" size=2>15</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Metal Worker</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C328 / C428</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C328 / C428</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" rowspan=4 height="224" align="center" valign=middle sdval="16"><b>
                <font face="Times New Roman" size=2>16</font>
            </b></td>
        <td class="tdborder" rowspan=4 align="left" valign=middle><b>
                <font size=2>Equipment and System Mechanic 15</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Electronic Equipment Mechanic (Construction Work) (Master)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>E3036 / E4036</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[15]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=4 align="center" valign=middle sdval="16"><b>
                <font face="Times New Roman" size=2>16</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Building Security System Mechanic</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>E323 / E423</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Communication System Mechanic</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>E324 / E424</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Plant and Equipment Mechanic (Construction Work) (Master)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>CE310 / CE410</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" rowspan=3 height="168" align="center" valign=middle sdval="17"><b>
                <font face="Times New Roman" size=2>17</font>
            </b></td>
        <td class="tdborder" rowspan=3 align="left" valign=middle><b>
                <font size=2>Piling Worker 16</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Piling Operative (Master)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C330 / C430</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[16]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=3 align="center" valign=middle sdval="17"><b>
                <font face="Times New Roman" size=2>17</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Piling Operative (Percussive Pile)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C330b / C430b</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Piling Operative (Bored Pile)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C330a / C430a</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" rowspan=4 height="224" align="center" valign=middle sdval="18"><b>
                <font face="Times New Roman" size=2>18</font>
            </b></td>
        <td class="tdborder" rowspan=4 align="left" valign=middle><b>
                <font size=2>Waterproofing Worker 17</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Waterproofing Worker (Master)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C301</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[17]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=4 align="center" valign=middle sdval="18"><b>
                <font face="Times New Roman" size=2>18</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Waterproofing Worker (Liquid Membrane)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C301c</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Waterproofing Worker (Burntype Felt)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C301b / C401b</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Waterproofing Worker (Adhesive-type Felt)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C301a</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="19"><b>
                <font face="Times New Roman" size=2>19</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Paving Block Layer</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Paving Block Layer</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C355</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[19]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="19"><b>
                <font face="Times New Roman" size=2>19</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" rowspan=3 height="168" align="center" valign=middle sdval="20"><b>
                <font face="Times New Roman" size=2>20</font>
            </b></td>
        <td class="tdborder" rowspan=3 align="left" valign=middle><b>
                <font size=2>Tiler 18</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Tiler</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C347 / C447</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[19]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=3 align="center" valign=middle sdval="20"><b>
                <font face="Times New Roman" size=2>20</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Tiler (Mosaic)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C347a / C447a</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Tiler (Tile)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C347b / C447b</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" rowspan=3 height="168" align="center" valign=middle sdval="21"><b>
                <font face="Times New Roman" size=2>21</font>
            </b></td>
        <td class="tdborder" rowspan=3 align="left" valign=middle><b>
                <font size=2>Demolition Worker 19</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Demolition Worker (Master)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C312</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[20]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=3 align="center" valign=middle sdval="21"><b>
                <font face="Times New Roman" size=2>21</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Demolition Worker (Building)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C312a</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Demolition Worker (Unauthorised Building Works)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C312b</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" rowspan=4 height="224" align="center" valign=middle sdval="22"><b>
                <font face="Times New Roman" size=2>22</font>
            </b></td>
        <td class="tdborder" rowspan=4 align="left" valign=middle><b>
                <font size=2>Marine Construction Plant Operator 20</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Marine Construction Plant Operator (Lifting) (Master)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C325 / C425</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[21]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=4 align="center" valign=middle sdval="22"><b>
                <font face="Times New Roman" size=2>22</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Marine Construction Plant Operator (Derrick)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C325c / C425c</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Marine Construction Plant Operator (Boom-grab Bucket)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C325a / C425a</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Marine Construction Plant Operator (Boom-hook)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C325b / C425b</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="23"><b>
                <font face="Times New Roman" size=2>23</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Aluminum Window Installer</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Window Frame Installer</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C350 / C450</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[22]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="23"><b>
                <font face="Times New Roman" size=2>23</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" rowspan=3 height="168" align="center" valign=middle sdval="24"><b>
                <font face="Times New Roman" size=2>24</font>
            </b></td>
        <td class="tdborder" rowspan=3 align="left" valign=middle><b>
                <font size=2>Curtain Wall and Glass Installer 21</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Curtain Wall and Glass Panes Installer (Master)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C3021 / C4021</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[23]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=3 align="center" valign=middle sdval="24"><b>
                <font face="Times New Roman" size=2>24</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Glazier</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C319</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C319 / C419</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Curtain Wall Installer</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C311 / C411</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" rowspan=11 height="616" align="center" valign=middle sdval="25"><b>
                <font face="Times New Roman" size=2>25</font>
            </b></td>
        <td class="tdborder" rowspan=11 align="left" valign=middle><b>
                <font size=2>Painter &amp; Decorator 22</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Painter and Decorator (Master)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C329 / C429</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C329 / C429</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=11 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=11 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=11 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=11 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=11 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=11 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=11 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=11 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=11 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=11 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=11 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=11 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=11 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=11 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=11 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=11 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=11 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=11 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=11 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=11 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=11 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=11 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=11 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=11 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=11 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=11 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=11 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=11 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=11 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=11 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=11 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=11 align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[24]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=11 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=11 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=11 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=11 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=11 align="center" valign=middle sdval="25"><b>
                <font face="Times New Roman" size=2>25</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Painter and Decorator (Roller Painting)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C329a</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Painter and Decorator (Surface Filling)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C329b</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Painter and Decorator (Emulsion Painting)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C329c</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Painter and Decorator (Brushing Lacquering)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C329d</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Painter and Decorator (Synthetic Painting)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C329e</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Painter and Decorator (Clear Lacquering)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C329f</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Painter and Decorator (Texture-spray)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C329g</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Painter and Decorator (Metal Paint Spray)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C329h</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Painter and Decorator (Paperhanging)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C329i</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Painter and Decorator (Sign Writing)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C329j</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" rowspan=3 height="168" align="center" valign=middle sdval="26"><b>
                <font face="Times New Roman" size=2>26</font>
            </b></td>
        <td class="tdborder" rowspan=3 align="left" valign=middle><b>
                <font size=2>Plasterer 23</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Cement Sand Mortar Worker (Master)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C3008 / C4008</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[25]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=3 align="center" valign=middle sdval="26"><b>
                <font face="Times New Roman" size=2>26</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Plasterer</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C337 / C437</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C337 / C437</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Plasterer (Floor)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C337a / C437a</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="27"><b>
                <font face="Times New Roman" size=2>27</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Trackworker</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Trackworker</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C348</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[26]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="27"><b>
                <font face="Times New Roman" size=2>27</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="28"><b>
                <font face="Times New Roman" size=2>28</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>False Ceiling Installer</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>False Ceiling Installer</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C358 / C458</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[27]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="28"><b>
                <font face="Times New Roman" size=2>28</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="29"><b>
                <font face="Times New Roman" size=2>29</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Gas Installer</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Gas Installer</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>E375</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[28]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="29"><b>
                <font face="Times New Roman" size=2>29</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="30"><b>
                <font face="Times New Roman" size=2>30</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Bricklayer </font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Bricklayer</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C305 / C405</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C305 / C405</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[29]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="30"><b>
                <font face="Times New Roman" size=2>30</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="31"><b>
                <font face="Times New Roman" size=2>31</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Structural Steel Welder </font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Structural Steel Welder</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C346</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C346</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[30]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="31"><b>
                <font face="Times New Roman" size=2>31</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="32"><b>
                <font face="Times New Roman" size=2>32</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Rigger/Metal Formwork Erector</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Rigger / Metal Formwork Erector</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C341</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C341 / C441</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[31]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="32"><b>
                <font face="Times New Roman" size=2>32</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="33"><b>
                <font face="Times New Roman" size=2>33</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Asphalter (Road Construction)</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Asphalter (Road Construction)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C302</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C302 / C402</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[32]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="33"><b>
                <font face="Times New Roman" size=2>33</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="34"><b>
                <font face="Times New Roman" size=2>34</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Construction Plant Mechanic</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Construction Plant Mechanic</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C310 / C410</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C310 / C410</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[33]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="34"><b>
                <font face="Times New Roman" size=2>34</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" rowspan=3 height="168" align="center" valign=middle sdval="35"><b>
                <font face="Times New Roman" size=2>35</font>
            </b></td>
        <td class="tdborder" rowspan=3 align="left" valign=middle><b>
                <font size=2>Electrical Fitter (incl. Electrician) 24</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Control Panel Assembler</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>E305a / E405a</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[34]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=3 align="center" valign=middle sdval="35"><b>
                <font face="Times New Roman" size=2>35</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Electrical Wireman</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>E305b / E405b</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Electrical Fitter</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>E305</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>E305</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="36"><b>
                <font face="Times New Roman" size=2>36</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Mechanical Fitter </font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Mechanical Fitter</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>E310 / E410</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>E310 / E410</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[35]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="36"><b>
                <font face="Times New Roman" size=2>36</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" rowspan=6 height="336" align="center" valign=middle sdval="37"><b>
                <font face="Times New Roman" size=2>37</font>
            </b></td>
        <td class="tdborder" rowspan=6 align="left" valign=middle><b>
                <font size=2>Refrigeration/AC/Ventilation Mechanic 25</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Refrigeration/AC/Ventilation Mechanic (Master)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>E314 / E414</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>E314</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[36]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=6 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=6 align="center" valign=middle sdval="37"><b>
                <font face="Times New Roman" size=2>37</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Refrigeration/AC/Ventilation Mechanic (Water System)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>E314e / E414e</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Refrigeration/AC/Ventilation Mechanic (Air System)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>E314a / E414a</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Refrigeration/AC/Ventilation Mechanic (Thermal Insulation)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>E314c / E414c</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Refrigeration/AC/Ventilation Mechanic (Electrical Control)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>E314b / E414b</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Refrigeration/AC/Ventilation Mechanic (Unitary System)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>E314d / E414d</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" rowspan=4 height="224" align="center" valign=middle sdval="38"><b>
                <font face="Times New Roman" size=2>38</font>
            </b></td>
        <td class="tdborder" rowspan=4 align="left" valign=middle><b>
                <font size=2>Fire Service Mechanic 26</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Fire Service Mechanic (Master)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>E306</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>E306</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[37]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=4 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=4 align="center" valign=middle sdval="38"><b>
                <font face="Times New Roman" size=2>38</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Fire Service Portable Equipment Fitter</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>E306c</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Fire Service Electrical Fitter</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>E306a / E406a</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Fire Service Mechanical Fitter</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>E306b / E406b</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" rowspan=3 height="168" align="center" valign=middle sdval="39"><b>
                <font face="Times New Roman" size=2>39</font>
            </b></td>
        <td class="tdborder" rowspan=3 align="left" valign=middle><b>
                <font size=2>Lift and Escalator Mechanic 27</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Lift and Escalator Mechanic (Master)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>E309</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>E309</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[38]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" rowspan=3 align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" rowspan=3 align="center" valign=middle sdval="39"><b>
                <font face="Times New Roman" size=2>39</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Lift Mechanic</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>E309a</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Escalator Mechanic</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>E309b</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="40"><b>
                <font face="Times New Roman" size=2>40</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Building Services Maintenance Mechanic 28</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>E302 / E402</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[39]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="40"><b>
                <font face="Times New Roman" size=2>40</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="53" align="center" valign=middle sdval="41"><b>
                <font face="Times New Roman" size=2>41</font>
            </b></td>
        <td class="tdborder nobordertop" align="left" colspan=4 valign=middle><b>
                <font size=2>Others: trade name &nbsp;&nbsp;&nbsp;&nbsp; - code -</font>
            </b></td>

        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[40]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=bottom sdval="41"><b>
                <font face="Times New Roman" size=2>41</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="53" align="center" valign=middle sdval="42"><b>
                <font face="Times New Roman" size=2>42</font>
            </b></td>
        <td class="tdborder nobordertop" align="left" colspan=4 valign=middle><b>
                <font size=2>Others: trade name &nbsp;&nbsp;&nbsp;&nbsp; - code -</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[41]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=bottom sdval="42"><b>
                <font face="Times New Roman" size=2>42</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="53" align="center" valign=middle sdval="43"><b>
                <font face="Times New Roman" size=2>43</font>
            </b></td>
        <td class="tdborder nobordertop" align="left" colspan=4 valign=middle><b>
                <font size=2>Others: trade name &nbsp;&nbsp;&nbsp;&nbsp; - code -</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[42]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=bottom sdval="43"><b>
                <font face="Times New Roman" size=2>43</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="53" align="center" valign=middle sdval="44"><b>
                <font face="Times New Roman" size=2>44</font>
            </b></td>
        <td class="tdborder nobordertop" align="left" colspan=4 valign=middle><b>
                <font size=2>Others: trade name &nbsp;&nbsp;&nbsp;&nbsp; - code -</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[43]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=bottom sdval="44"><b>
                <font face="Times New Roman" size=2>44</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="53" align="center" valign=middle sdval="45"><b>
                <font face="Times New Roman" size=2>45</font>
            </b></td>
        <td class="tdborder nobordertop" align="left" colspan=4 valign=middle><b>
                <font size=2>Others: trade name &nbsp;&nbsp;&nbsp;&nbsp; - code -</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[44]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=bottom sdval="45"><b>
                <font face="Times New Roman" size=2>45</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="53" align="center" valign=middle sdval="46"><b>
                <font face="Times New Roman" size=2>46</font>
            </b></td>
        <td class="tdborder nobordertop" align="left" colspan=4 valign=middle><b>
                <font size=2>Others: trade name &nbsp;&nbsp;&nbsp;&nbsp; - code -</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[44]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=bottom sdval="46"><b>
                <font face="Times New Roman" size=2>46</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="53" align="center" valign=middle sdval="47"><b>
                <font face="Times New Roman" size=2>47</font>
            </b></td>
        <td class="tdborder nobordertop" align="left" colspan=4 valign=middle><b>
                <font size=2>Others: trade name &nbsp;&nbsp;&nbsp;&nbsp; - code -</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[44]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=bottom sdval="47"><b>
                <font face="Times New Roman" size=2>47</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="53" align="center" valign=middle sdval="48"><b>
                <font face="Times New Roman" size=2>48</font>
            </b></td>
        <td class="tdborder nobordertop" align="left" colspan=4 valign=middle><b>
                <font size=2>Others: trade name &nbsp;&nbsp;&nbsp;&nbsp; - code -</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[44]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=bottom sdval="48"><b>
                <font face="Times New Roman" size=2>48</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="53" align="center" valign=middle sdval="49"><b>
                <font face="Times New Roman" size=2>49</font>
            </b></td>
        <td class="tdborder nobordertop" align="left" colspan=4 valign=middle><b>
                <font size=2>Others: trade name &nbsp;&nbsp;&nbsp;&nbsp; - code -</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[44]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=bottom sdval="49"><b>
                <font face="Times New Roman" size=2>49</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="53" align="center" valign=middle sdval="50"><b>
                <font face="Times New Roman" size=2>50</font>
            </b></td>
        <td class="tdborder nobordertop" align="left" colspan=4 valign=middle><b>
                <font size=2>Others: trade name &nbsp;&nbsp;&nbsp;&nbsp; - code -</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[44]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=bottom sdval="50"><b>
                <font face="Times New Roman" size=2>50</font>
            </b></td>
    </tr>

    <tr>
        <td class="tdborder" height="53" align="center" valign=middle sdval="51"><b>
                <font face="Times New Roman" size=2>51</font>
            </b></td>
        <td class="tdborder nobordertop" align="left" colspan=4 valign=middle><b>
                <font size=2>Others: trade name &nbsp;&nbsp;&nbsp;&nbsp; - code -</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[44]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=bottom sdval="51"><b>
                <font face="Times New Roman" size=2>51</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="53" align="center" valign=middle sdval="52"><b>
                <font face="Times New Roman" size=2>52</font>
            </b></td>
        <td class="tdborder nobordertop" align="left" colspan=4 valign=middle><b>
                <font size=2>Others: trade name &nbsp;&nbsp;&nbsp;&nbsp; - code -</font>
            </b></td>
      
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[44]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=bottom sdval="52"><b>
                <font face="Times New Roman" size=2>52</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="53" align="center" valign=middle sdval="53"><b>
                <font face="Times New Roman" size=2>53</font>
            </b></td>
        <td class="tdborder nobordertop" align="left" colspan=4 valign=middle><b>
                <font size=2>Others: trade name &nbsp;&nbsp;&nbsp;&nbsp; - code -</font>
            </b></td>
     
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[44]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=bottom sdval="53"><b>
                <font face="Times New Roman" size=2>53</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="53" align="center" valign=middle sdval="54"><b>
                <font face="Times New Roman" size=2>54</font>
            </b></td>
        <td class="tdborder nobordertop" align="left" colspan=4 valign=middle><b>
                <font size=2>Others: trade name &nbsp;&nbsp;&nbsp;&nbsp; - code -</font>
            </b></td>
     
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[44]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=bottom sdval="54"><b>
                <font face="Times New Roman" size=2>54</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="53" align="center" valign=middle sdval="55"><b>
                <font face="Times New Roman" size=2>55</font>
            </b></td>
        <td class="tdborder nobordertop" align="left" colspan=4 valign=middle><b>
                <font size=2>Others: trade name &nbsp;&nbsp;&nbsp;&nbsp; - code -</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[44]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=bottom sdval="55"><b>
                <font face="Times New Roman" size=2>55</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="53" align="center" valign=middle sdval="56"><b>
                <font face="Times New Roman" size=2>56</font>
            </b></td>
        <td class="tdborder nobordertop" align="left" colspan=4 valign=middle><b>
                <font size=2>Others: trade name &nbsp;&nbsp;&nbsp;&nbsp; - code -</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[44]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=bottom sdval="56"><b>
                <font face="Times New Roman" size=2>56</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="53" align="center" valign=middle sdval="57"><b>
                <font face="Times New Roman" size=2>57</font>
            </b></td>
        <td class="tdborder nobordertop" align="left" colspan=4 valign=middle><b>
                <font size=2>Others: trade name &nbsp;&nbsp;&nbsp;&nbsp; - code -</font>
            </b></td>
     
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[44]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=bottom sdval="57"><b>
                <font face="Times New Roman" size=2>57</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="53" align="center" valign=middle sdval="58"><b>
                <font face="Times New Roman" size=2>58</font>
            </b></td>
        <td class="tdborder nobordertop" align="left" colspan=4 valign=middle><b>
                <font size=2>Others: trade name &nbsp;&nbsp;&nbsp;&nbsp; - code -</font>
            </b></td>
      
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[44]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=bottom sdval="58"><b>
                <font face="Times New Roman" size=2>58</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="53" align="center" valign=middle sdval="59"><b>
                <font face="Times New Roman" size=2>59</font>
            </b></td>
        <td class="tdborder nobordertop" align="left" colspan=4 valign=middle><b>
                <font size=2>Others: trade name &nbsp;&nbsp;&nbsp;&nbsp; - code -</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[44]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=bottom sdval="59"><b>
                <font face="Times New Roman" size=2>59</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="53" align="center" valign=middle sdval="60"><b>
                <font face="Times New Roman" size=2>60</font>
            </b></td>
        <td class="tdborder nobordertop" align="left" colspan=4 valign=middle><b>
                <font size=2>Others: trade name &nbsp;&nbsp;&nbsp;&nbsp; - code -</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[44]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=bottom sdval="60"><b>
                <font face="Times New Roman" size=2>60</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="53" align="center" valign=middle sdval="45"><b>
                <font face="Times New Roman" size=2></font>
            </b></td>
        <td class="tdborder" align="left" colspan="4" valign=middle><b>
                <font size=2>Daily total</font>
            </b></td>
        <!-- <td class="tdborder noborderleft" align="center" valign=middle [contentEditable]="contentEditable"><b><font size=2><br>
                {{clomnArray[0]}}
            </font></b></td> -->
        <td class="tdborder" *ngFor="let item of clomnArray" [contentEditable]="contentEditable" align="center"
            valign=middle><b>
                <font face="Times New Roman" size=2>
                    {{item}}
                    <br>
                </font>
            </b></td>
        <!-- <td class="tdborder" align="center" valign=middle><b><font face="Times New Roman" size=2>111{{numArray[45]}}</font></b></td> -->
        <!-- <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td> -->


    </tr>
</table>
<table cellspacing="0" border="0" id="mainTable" *ngIf="version == 'v03'">
    <tr>
        <td class="tdborder noborderbottom" rowspan=3 height="90" align="center" valign=middle><b>
                <font size=2>Item<br>No.</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=3 align="left" valign=middle><b>
                <font size=2>Trade </font>
            </b></td>
        <td class="tdborder" colspan=2 align="center" valign=middle><b>
                <font size=2>Trade List 1</font>
            </b></td>
        <td class="tdborder" colspan=31 align="center" valign=middle><b>
                <font size=2>Number of workers engaged on site on each calendar day b,c</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=3 align="center" valign=middle><b>
                <font size=2>Total <br>Man-days</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=3 align="center" valign=middle><b>
                <font size=2>Overtime d<br>(hours)</font>
            </b></td>
        <td class="tdborder" colspan=3 rowspan=2 align="center" valign=middle><b>
                <font size=2> Daily Wage Rate ($) e</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=3 align="center" valign=middle><b>
                <font size=2>Item<br>No.</font>
            </b></td>
        <td align="left" valign=bottom>
            <font size=2><br></font>
        </td>
        <td align="left" valign=bottom>
            <font size=2><br></font>
        </td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="left" valign=bottom>
            <font size=2><br></font>
        </td>
    </tr>
    <tr>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>Orig.</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>Rev.</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="1"><b>
                <font size=2>1</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="2"><b>
                <font size=2>2</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="3"><b>
                <font size=2>3</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="4"><b>
                <font size=2>4</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="5"><b>
                <font size=2>5</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="6"><b>
                <font size=2>6</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="7"><b>
                <font size=2>7</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="8"><b>
                <font size=2>8</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="9"><b>
                <font size=2>9</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="10"><b>
                <font size=2>10</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="11"><b>
                <font size=2>11</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="12"><b>
                <font size=2>12</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="13"><b>
                <font size=2>13</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="14"><b>
                <font size=2>14</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="15"><b>
                <font size=2>15</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="16"><b>
                <font size=2>16</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="17"><b>
                <font size=2>17</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="18"><b>
                <font size=2>18</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="19"><b>
                <font size=2>19</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="20"><b>
                <font size=2>20</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="21"><b>
                <font size=2>21</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="22"><b>
                <font size=2>22</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="23"><b>
                <font size=2>23</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="24"><b>
                <font size=2>24</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="25"><b>
                <font size=2>25</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="26"><b>
                <font size=2>26</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="27"><b>
                <font size=2>27</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="28"><b>
                <font size=2>28</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="29"><b>
                <font size=2>29</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="30"><b>
                <font size=2>30</font>
            </b></td>
        <td class="tdborder noborderbottom" rowspan=2 align="center" valign=middle sdval="31"><b>
                <font size=2>31</font>
            </b></td>
        <td align="left" valign=bottom>
            <font size=2><br></font>
        </td>
        <td align="left" valign=bottom>
            <font size=2><br></font>
        </td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="left" valign=bottom>
            <font size=2><br></font>
        </td>
    </tr>
    <tr>
        <td class="tdborder noborderbottom" align="center" valign=middle><b>
                <font size=2><br></font>
            </b></td>
        <td class="tdborder noborderbottom" align="center" valign=middle><b>
                <font size=2>(Code 2)</font>
            </b></td>
        <td class="tdborder noborderbottom" align="center" valign=middle><b>
                <font size=2>Av. </font>
            </b></td>
        <td class="tdborder noborderbottom" align="center" valign=middle><b>
                <font size=2>High </font>
            </b></td>
        <td class="tdborder noborderbottom" align="center" valign=middle><b>
                <font size=2>Low </font>
            </b></td>
        <td align="left" valign=bottom>
            <font size=2><br></font>
        </td>
        <td align="left" valign=bottom>
            <font size=2><br></font>
        </td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="justify" valign=middle><span class="fontstyle"><br></span></td>
        <td align="left" valign=bottom>
            <font size=2><br></font>
        </td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="1"><b>
                <font face="Times New Roman" size=2>1</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Bar Bender & Fixer [or Steelbender] 6 </font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>✔</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C304</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[0]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle sdval="1"><b>
                <font face="Times New Roman" size=2>1</font>
            </b></td>

    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="2"><b>
                <font face="Times New Roman" size=2>2</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Concretor</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>✔</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C309</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[1]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="2"><b>
                <font face="Times New Roman" size=2>2</font>
            </b></td>
    </tr>

    <tr>
        <td class="tdborder" height="64"  align="center" valign=middle sdval="3"><b>
                <font face="Times New Roman" size=2>3</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Drainlayer
                </font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>✔</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C314</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[2]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="3"><b>
                <font face="Times New Roman" size=2>3</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="4"><b>
                <font face="Times New Roman" size=2>4</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Plumber</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>✔</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C338</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[3]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="4"><b>
                <font face="Times New Roman" size=2>4</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="5"><b>
                <font face="Times New Roman" size=2>5</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Leveller </font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C323</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[4]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="5"><b>
                <font face="Times New Roman" size=2>5</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="6"><b>
                <font face="Times New Roman" size=2>6</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Bamboo Scaffolder
                </font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>✔</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C303</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[5]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="6"><b>
                <font face="Times New Roman" size=2>6</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="7"><b>
                <font face="Times New Roman" size=2>7</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Carpenter & Joiner 3
                </font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>✔</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[6]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="7"><b>
                <font face="Times New Roman" size=2>7</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="8"><b>
                <font face="Times New Roman" size=2>8</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Carpenter (Formwork)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C307</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[7]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="8"><b>
                <font face="Times New Roman" size=2>8</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="9"><b>
                <font face="Times New Roman" size=2>9</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Joiner</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C322</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[8]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="9"><b>
                <font face="Times New Roman" size=2>9</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="168" align="center" valign=middle sdval="10"><b>
                <font face="Times New Roman" size=2>10</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Plant & Equipment Operator (Load Shifting) [or Plant Operator (exc. driver, bulldozer
                    driver, etc.)] 6
                </font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>✔</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C333</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[9]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="10"><b>
                <font face="Times New Roman" size=2>10</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="11"><b>
                <font face="Times New Roman" size=2>11</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Truck Driver</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>✔</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C349</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[10]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="11"><b>
                <font face="Times New Roman" size=2>11</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="12"><b>
                <font face="Times New Roman" size=2>12</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Rock-Breaking Driller [or Pneumatic Driller ] 6
                </font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>✔</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C339
                </font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[11]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="12"><b>
                <font face="Times New Roman" size=2>12</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="13"><b>
                <font face="Times New Roman" size=2>13</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Blacksmith 3
                </font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>✔</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[12]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="13"><b>
                <font face="Times New Roman" size=2>13</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="14"><b>
                <font face="Times New Roman" size=2>14</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>General Welder
                </font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C318</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[13]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="14"><b>
                <font face="Times New Roman" size=2>14</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="15"><b>
                <font face="Times New Roman" size=2>15</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Metal Worker
                </font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C328</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[14]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="15"><b>
                <font face="Times New Roman" size=2>15</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="16"><b>
                <font face="Times New Roman" size=2>16</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Glazier
                </font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>✔</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C319</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[15]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle sdval="16"><b>
                <font face="Times New Roman" size=2>16</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="17"><b>
                <font face="Times New Roman" size=2>17</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Excavator (male)
                </font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>✔</font>
            </b></td>
        <td class="tdborder" rowspan=8 align="center" valign=middle><b>
                <font size=2>C406</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[16]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="17"><b>
                <font face="Times New Roman" size=2>17</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="18"><b>
                <font face="Times New Roman" size=2>18</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Excavator (female)
                </font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>✔</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[17]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="18"><b>
                <font face="Times New Roman" size=2>18</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="19"><b>
                <font face="Times New Roman" size=2>19</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Labourer (male)
                </font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>✔</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[19]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="19"><b>
                <font face="Times New Roman" size=2>19</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="20"><b>
                <font face="Times New Roman" size=2>20</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Labourer (female)
                </font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>✔</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[19]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="20"><b>
                <font face="Times New Roman" size=2>20</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="96" align="center" valign=middle sdval="21"><b>
                <font face="Times New Roman" size=2>21</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Concretor's Labourer (male)
                </font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>✔</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[20]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="21"><b>
                <font face="Times New Roman" size=2>21</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="96" align="center" valign=middle sdval="22"><b>
                <font face="Times New Roman" size=2>22</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Concretor's Labourer (female)
                </font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>✔</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[21]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="22"><b>
                <font face="Times New Roman" size=2>22</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="96" align="center" valign=middle sdval="23"><b>
                <font face="Times New Roman" size=2>23</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Heavy Load Labourer [or Heavy Load Coolie] 6
                </font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>✔</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[22]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="23"><b>
                <font face="Times New Roman" size=2>23</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="24"><b>
                <font face="Times New Roman" size=2>24</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Diver's Linesman</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>✔</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[23]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="24"><b>
                <font face="Times New Roman" size=2>24</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="25"><b>
                <font face="Times New Roman" size=2>25</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Painter & Decorator
                </font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>✔</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C329</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[24]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="25"><b>
                <font face="Times New Roman" size=2>25</font>
            </b></td>
    </tr>

    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="26"><b>
                <font face="Times New Roman" size=2>26</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Plasterer</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>✔</font>
            </b></td>
        <td class="tdborder" align="center" rowspan=2 valign=middle><b>
                <font size=2>C336</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[25]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="26"><b>
                <font face="Times New Roman" size=2>26</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="96" align="center" valign=middle sdval="27"><b>
                <font face="Times New Roman" size=2>27</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Terrazzo & Granolithic Worker </font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>✔</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[26]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="27"><b>
                <font face="Times New Roman" size=2>27</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="28"><b>
                <font face="Times New Roman" size=2>28</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Plasterer's Labourer (male)
                </font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>✔</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[27]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="28"><b>
                <font face="Times New Roman" size=2>28</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="96" align="center" valign=middle sdval="29"><b>
                <font face="Times New Roman" size=2>29</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Plasterer's Labourer (female)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>✔</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[28]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="29"><b>
                <font face="Times New Roman" size=2>29</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="30"><b>
                <font face="Times New Roman" size=2>30</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Bricklayer </font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>✔</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C305</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[29]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="30"><b>
                <font face="Times New Roman" size=2>30</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="96" align="center" valign=middle sdval="31"><b>
                <font face="Times New Roman" size=2>31</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Bricklayer's Labourer (male)</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>✔</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[30]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="31"><b>
                <font face="Times New Roman" size=2>31</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="96" align="center" valign=middle sdval="32"><b>
                <font face="Times New Roman" size=2>32</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Bricklayer's Labourer (female)
                </font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>✔</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[31]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="32"><b>
                <font face="Times New Roman" size=2>32</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="33"><b>
                <font face="Times New Roman" size=2>33</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Marble Worker
                </font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C324</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[32]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="33"><b>
                <font face="Times New Roman" size=2>33</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="96" align="center" valign=middle sdval="34"><b>
                <font face="Times New Roman" size=2>34</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Mason (incl. rubble mason, splitting mason and ashlar mason)
                </font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>✔</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C326</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[33]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="34"><b>
                <font face="Times New Roman" size=2>34</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="35"><b>
                <font face="Times New Roman" size=2>35</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Structural Steel Welder</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C346</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[34]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="35"><b>
                <font face="Times New Roman" size=2>35</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="36"><b>
                <font face="Times New Roman" size=2>36</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Structural Steel Erector
                </font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>✔</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C345</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[35]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="36"><b>
                <font face="Times New Roman" size=2>36</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="37"><b>
                <font face="Times New Roman" size=2>37</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Rigger/Metal Formwork Erector</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C341</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[36]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="37"><b>
                <font face="Times New Roman" size=2>37</font>
            </b></td>
    </tr>

    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="38"><b>
                <font face="Times New Roman" size=2>38</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Asphalter (Road Construction)
                </font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>---</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C302</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[37]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="38"><b>
                <font face="Times New Roman" size=2>38</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="96" align="center" valign=middle sdval="39"><b>
                <font face="Times New Roman" size=2>39</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Construction Plant Mechanic [or Fitter ] 6
                </font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>✔</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C310</font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[38]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="39"><b>
                <font face="Times New Roman" size=2>39</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="64" align="center" valign=middle sdval="40"><b>
                <font face="Times New Roman" size=2>40</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Diver
                </font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>✔</font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font size=2>C313
                </font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[39]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle sdval="40"><b>
                <font face="Times New Roman" size=2>40</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="96" align="center" valign=middle sdval="41"><b>
                <font face="Times New Roman" size=2>41</font>
            </b></td>
        <td class="tdborder nobordertop" align="left" valign=middle><b>
                <font size=2>Electrical Fitter (incl. Electrician)
                </font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>✔</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>E305
                </font>
            </b></td>
        <td class="tdborder noborderleft" align="center" valign=middle [contentEditable]="contentEditable"><b>
                <font size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[40]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=bottom sdval="41"><b>
                <font face="Times New Roman" size=2>41</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="53" align="center" valign=middle sdval="42"><b>
                <font face="Times New Roman" size=2>42</font>
            </b></td>
        <td class="tdborder nobordertop" align="left" valign=middle><b>
                <font size=2>Mechanical Fitter
                </font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2><br>---</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>E310</font>
            </b></td>
        <td class="tdborder noborderleft" align="center" valign=middle [contentEditable]="contentEditable"><b>
                <font size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[41]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=bottom sdval="42"><b>
                <font face="Times New Roman" size=2>42</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="96" align="center" valign=middle sdval="43"><b>
                <font face="Times New Roman" size=2>43</font>
            </b></td>
        <td class="tdborder nobordertop" align="left" valign=middle><b>
                <font size=2>Refrigeration/AC/Ventilation Mechanic </font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>---<br></font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>E314</font>
            </b></td>
        <td class="tdborder noborderleft" align="center" valign=middle [contentEditable]="contentEditable"><b>
                <font size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[42]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=bottom sdval="43"><b>
                <font face="Times New Roman" size=2>43</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="53" align="center" valign=middle sdval="44"><b>
                <font face="Times New Roman" size=2>44</font>
            </b></td>
        <td class="tdborder nobordertop" align="left" valign=middle><b>
                <font size=2>Fire Service Mechanic
                </font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>---<br></font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>E306</font>
            </b></td>
        <td class="tdborder noborderleft" align="center" valign=middle [contentEditable]="contentEditable"><b>
                <font size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[43]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=bottom sdval="44"><b>
                <font face="Times New Roman" size=2>44</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="53" align="center" valign=middle sdval="45"><b>
                <font face="Times New Roman" size=2>45</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Lift and Escalator Mechanic
                </font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>---<br></font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>E309</font>
            </b></td>
        <td class="tdborder noborderleft" align="center" valign=middle [contentEditable]="contentEditable"><b>
                <font size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[44]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=bottom sdval="45"><b>
                <font face="Times New Roman" size=2>45</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="53" align="center" valign=middle sdval="46"><b>
                <font face="Times New Roman" size=2>46</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Building Services Maintenance Mechanic

                </font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>---<br></font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>E302</font>
            </b></td>
        <td class="tdborder noborderleft" align="center" valign=middle [contentEditable]="contentEditable"><b>
                <font size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[44]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=bottom sdval="46"><b>
                <font face="Times New Roman" size=2>46</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="53" align="center" valign=middle sdval="47"><b>
                <font face="Times New Roman" size=2>47</font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>Cable Jointer (Power)
                </font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>---<br></font>
            </b></td>
        <td class="tdborder" align="left" valign=middle><b>
                <font size=2>E303</font>
            </b></td>
        <td class="tdborder noborderleft" align="center" valign=middle [contentEditable]="contentEditable"><b>
                <font size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[44]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=bottom sdval="47"><b>
                <font face="Times New Roman" size=2>47</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="53" align="center" valign=middle sdval="48"><b>
                <font face="Times New Roman" size=2>48</font>
            </b></td>
        <td class="tdborder" align="left" colspan=3 valign=middle><b>
                <font size=2>Others: trade name&nbsp;&nbsp;&nbsp;&nbsp; - code -

                </font>
            </b></td>

        <td class="tdborder noborderleft" align="center" valign=middle [contentEditable]="contentEditable"><b>
                <font size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[44]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=bottom sdval="48"><b>
                <font face="Times New Roman" size=2>48</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="53" align="center" valign=middle sdval="49"><b>
                <font face="Times New Roman" size=2>49</font>
            </b></td>
        <td class="tdborder" align="left" colspan=3 valign=middle><b>
                <font size=2>Others: trade name&nbsp;&nbsp;&nbsp;&nbsp; - code -

                </font>
            </b></td>
        <td class="tdborder noborderleft" align="center" valign=middle [contentEditable]="contentEditable"><b>
                <font size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[44]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=bottom sdval="49"><b>
                <font face="Times New Roman" size=2>49</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="53" align="center" valign=middle sdval="50"><b>
                <font face="Times New Roman" size=2>50</font>
            </b></td>
        <td class="tdborder" align="left" colspan=3 valign=middle><b>
                <font size=2>Others: trade name&nbsp;&nbsp;&nbsp;&nbsp; - code -

                </font>
            </b></td>
        <td class="tdborder noborderleft" align="center" valign=middle [contentEditable]="contentEditable"><b>
                <font size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[44]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=bottom sdval="50"><b>
                <font face="Times New Roman" size=2>50</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="53" align="center" valign=middle sdval="51"><b>
                <font face="Times New Roman" size=2>51</font>
            </b></td>
        <td class="tdborder" align="left" colspan=3 valign=middle><b>
                <font size=2>Others: trade name&nbsp;&nbsp;&nbsp;&nbsp; - code -

                </font>
            </b></td>
        <td class="tdborder noborderleft" align="center" valign=middle [contentEditable]="contentEditable"><b>
                <font size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[44]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=bottom sdval="51"><b>
                <font face="Times New Roman" size=2>51</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="53" align="center" valign=middle sdval="52"><b>
                <font face="Times New Roman" size=2>52</font>
            </b></td>
        <td class="tdborder" align="left" colspan=3 valign=middle><b>
                <font size=2>Others: trade name&nbsp;&nbsp;&nbsp;&nbsp; - code -

                </font>
            </b></td>
        <td class="tdborder noborderleft" align="center" valign=middle [contentEditable]="contentEditable"><b>
                <font size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[44]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=bottom sdval="52"><b>
                <font face="Times New Roman" size=2>52</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="53" align="center" valign=middle sdval="53"><b>
                <font face="Times New Roman" size=2>53</font>
            </b></td>
        <td class="tdborder" align="left" colspan=3 valign=middle><b>
                <font size=2>Others: trade name&nbsp;&nbsp;&nbsp;&nbsp; - code -

                </font>
            </b></td>
        <td class="tdborder noborderleft" align="center" valign=middle [contentEditable]="contentEditable"><b>
                <font size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[44]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=bottom sdval="53"><b>
                <font face="Times New Roman" size=2>53</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="53" align="center" valign=middle sdval="54"><b>
                <font face="Times New Roman" size=2>54</font>
            </b></td>
        <td class="tdborder" align="left" colspan=3 valign=middle><b>
                <font size=2>Others: trade name&nbsp;&nbsp;&nbsp;&nbsp; - code -

                </font>
            </b></td>
        <td class="tdborder noborderleft" align="center" valign=middle [contentEditable]="contentEditable"><b>
                <font size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[44]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=bottom sdval="54"><b>
                <font face="Times New Roman" size=2>54</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="53" align="center" valign=middle sdval="55"><b>
                <font face="Times New Roman" size=2>55</font>
            </b></td>
        <td class="tdborder" align="left" colspan=3 valign=middle><b>
                <font size=2>Others: trade name&nbsp;&nbsp;&nbsp;&nbsp; - code -

                </font>
            </b></td>
        <td class="tdborder noborderleft" align="center" valign=middle [contentEditable]="contentEditable"><b>
                <font size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[44]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=bottom sdval="54"><b>
                <font face="Times New Roman" size=2>55</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="53" align="center" valign=middle sdval="56"><b>
                <font face="Times New Roman" size=2>56</font>
            </b></td>
        <td class="tdborder" align="left" colspan=3 valign=middle><b>
                <font size=2>Others: trade name&nbsp;&nbsp;&nbsp;&nbsp; - code -

                </font>
            </b></td>
        <td class="tdborder noborderleft" align="center" valign=middle [contentEditable]="contentEditable"><b>
                <font size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[44]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=bottom sdval="56"><b>
                <font face="Times New Roman" size=2>56</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="53" align="center" valign=middle sdval="57"><b>
                <font face="Times New Roman" size=2>57</font>
            </b></td>
        <td class="tdborder" align="left" colspan=3 valign=middle><b>
                <font size=2>Others: trade name&nbsp;&nbsp;&nbsp;&nbsp; - code -

                </font>
            </b></td>
        <td class="tdborder noborderleft" align="center" valign=middle [contentEditable]="contentEditable"><b>
                <font size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[44]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=bottom sdval="57"><b>
                <font face="Times New Roman" size=2>57</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="53" align="center" valign=middle sdval="58"><b>
                <font face="Times New Roman" size=2>58</font>
            </b></td>
        <td class="tdborder" align="left" colspan=3 valign=middle><b>
                <font size=2>Others: trade name&nbsp;&nbsp;&nbsp;&nbsp; - code -

                </font>
            </b></td>
        <td class="tdborder noborderleft" align="center" valign=middle [contentEditable]="contentEditable"><b>
                <font size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[44]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=bottom sdval="58"><b>
                <font face="Times New Roman" size=2>58</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="53" align="center" valign=middle sdval="59"><b>
                <font face="Times New Roman" size=2>59</font>
            </b></td>
        <td class="tdborder" align="left" colspan=3 valign=middle><b>
                <font size=2>Others: trade name&nbsp;&nbsp;&nbsp;&nbsp; - code -

                </font>
            </b></td>
        <td class="tdborder noborderleft" align="center" valign=middle [contentEditable]="contentEditable"><b>
                <font size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[44]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=bottom sdval="59"><b>
                <font face="Times New Roman" size=2>59</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="53" align="center" valign=middle sdval="60"><b>
                <font face="Times New Roman" size=2>60</font>
            </b></td>
        <td class="tdborder" align="left" colspan=3 valign=middle><b>
                <font size=2>Others: trade name&nbsp;&nbsp;&nbsp;&nbsp; - code -

                </font>
            </b></td>
        <td class="tdborder noborderleft" align="center" valign=middle [contentEditable]="contentEditable"><b>
                <font size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=middle><b>
                <font face="Times New Roman" size=2>{{numArray[44]}}</font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" [contentEditable]="true" align="center" valign=middle><b>
                <font face="Times New Roman" size=2><br></font>
            </b></td>
        <td class="tdborder" align="center" valign=bottom sdval="60"><b>
                <font face="Times New Roman" size=2>60</font>
            </b></td>
    </tr>
    <tr>
        <td class="tdborder" height="53" align="center" valign=middle sdval="53"><b>
                <font face="Times New Roman" size=2></font>
            </b></td>
        <td class="tdborder" align="left" colspan="3" valign=middle><b>
                <font size=2>Daily total</font>
            </b></td>
        <!-- <td class="tdborder noborderleft" align="center" valign=middle [contentEditable]="contentEditable"><b><font size=2><br>
                {{clomnArray[0]}}
            </font></b></td> -->
        <td class="tdborder" *ngFor="let item of clomnArray" [contentEditable]="contentEditable" align="center"
            valign=middle><b>
                <font face="Times New Roman" size=2>
                    {{item}}
                    <br>
                </font>
            </b></td>
        <!-- <td class="tdborder" align="center" valign=middle><b><font face="Times New Roman" size=2>111{{numArray[45]}}</font></b></td> -->
        <!-- <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td>
            <td class="tdborder" [contentEditable]="contentEditable" align="center" valign=middle ><b><font face="Times New Roman" size=2><br></font></b></td> -->


    </tr>
</table>