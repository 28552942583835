import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SiteDiaryComponent } from './SiteDiary.component';
import { SiteDiaryListComponent } from './SiteDiary-List/SiteDiary-List.component';
import { SiteDiaryListPopupComponent } from './SiteDiary-List/SiteDiary-List-Popup/SiteDiary-List-Popup.component';
import { SiteDiaryDetailsComponent } from './SiteDiary-Details/SiteDiary-Details.component';
import { SiteDiaryDetailsSummaryComponent } from './SiteDiary-Details/SiteDiary-Details-Summary/SiteDiary-Details-Summary.component';
import { SiteDiaryDetailsActivityComponent } from './SiteDiary-Details/SiteDiary-Details-table/SiteDiary-Details-table.component';
import { SiteDiaryDetailsPupopComponent } from './SiteDiary-Details/SiteDiary-Details-Summary/SiteDiary-Details-Pupop/SiteDiary-Details-Pupop.component';
import { SiteDiaryDetailsAddActivityComponent } from './SiteDiary-Details/SiteDiary-Details-addActivity/SiteDiary-Details-addActivity.component';
import { SiteDiaryDetailsAddActivityPupopComponent } from './SiteDiary-Details/SiteDiary-Details-addActivity/SiteDiary-Details-addActivity-Pupop/SiteDiary-Details-addActivity-Pupop.component';
import { SiteDiaryListApprovalComponent } from './SiteDiary-List/SiteDiary-List-Approval/SiteDiary-List-Approval.component'
import { Ng2StateDeclaration, UIRouterModule } from '@uirouter/angular';
import {OpenprojectWorkPackagesModule} from 'core-app/modules/work_packages/openproject-work-packages.module';
import {OpenprojectCommonModule} from "core-app/modules/common/openproject-common.module";
import { SignaturePadModule } from 'angular2-signaturepad';
import {BrowserModule} from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { SiteDiatyActivityNewComponent } from './SiteDiary-Details/SiteDiaty-Activity-New/SiteDiaty-Activity-New.component';



export const DOCUMENTS_ROUTES: Ng2StateDeclaration[] = [
  /*这里我暂时用了check_lists的路由，等后端同事配好侧边栏路由后替换，并且记得把check_lists的路由改回来 */
  {
    name: 'site_diary',
    url: '/site_diary',
    parent: 'root',
    component: SiteDiaryComponent
  }
];

@NgModule({
  imports: [
    UIRouterModule.forChild({ 
      states: DOCUMENTS_ROUTES
    }),
    CommonModule,
    OpenprojectWorkPackagesModule,
    OpenprojectCommonModule,
    SignaturePadModule,
    BrowserModule,
    FormsModule
  ],
  declarations: [
    SiteDiaryComponent,

    //列表
    SiteDiatyActivityNewComponent,
    SiteDiaryListComponent,
    SiteDiaryListPopupComponent,
    SiteDiaryListApprovalComponent,

    SiteDiaryDetailsComponent,
    SiteDiaryDetailsSummaryComponent,
    SiteDiaryDetailsActivityComponent,
    SiteDiaryDetailsPupopComponent,
    SiteDiaryDetailsAddActivityComponent,
    SiteDiaryDetailsAddActivityPupopComponent
    
  ]
})
export class SiteDiaryModule { }
