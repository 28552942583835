import { Component, OnInit, ChangeDetectorRef ,Output} from '@angular/core';

@Component({
  selector: 'DashboardsComponent',
  templateUrl: './Dashboards.component.html',
  styleUrls: ['./Dashboards.component.sass']
})
export class DashboardsComponent implements OnInit {
  public link:string = '';
  public loading = true
  constructor(
  ) {
  }


  ngOnInit() {
    let that = this
    // @ts-ignore
    document.getElementById("MainIframe").addEventListener('load', function() {
      // document.getElementById('loading').style.display = 'none';
      that.loading = false
    });
  }
}
