import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { SEDNService } from '../../service/SEDN.service';

@Component({
  selector: 'ImgShow',
  templateUrl: './ImgShow.component.html',
  styleUrls: ['./ImgShow.component.sass']
})
export class ImgShowComponent implements OnInit {
  @Input() showImg:any
  public sizetype:any = 'width'
  public size = 60
  @ViewChild('imgShow', { static: true }) imgShow: ElementRef;
  @ViewChild('Img', { static: true }) Img: ElementRef;
  constructor(public service:SEDNService) { }
  public maxSize:any 
  public minSize:any
  ngOnInit() {
    let img = new Image()
    img.src = this.showImg
    img.onload = () => {
      
      console.log(img.width,img.height)
      if(img.width > img.height) {
        this.sizetype = 'width'
        this.Img.nativeElement.style.height = 'auto'
        this.Img.nativeElement.style.width = '100%'
        this.maxSize = 80
        this.minSize = 34
      } else {
        this.sizetype = 'height'
        console.log(img)
        let size = window.innerHeight/ window.innerWidth
        this.size = 60 * size
        this.maxSize = 80* size
        this.minSize = 34* size
      }
      this.imgShow.nativeElement.style.width= this.size + '%'
      document.body.style.overflow = 'hidden'

    }
  }
  resizeImg(e:any){
    console.log(e)
    if(e.deltaY < 0 && this.size < this.maxSize) {
      this.size += 2
    } else if(this.size > this.minSize){
      this.size -= 2
    }
    this.imgShow.nativeElement.style.width = this.size + '%'
    e.stopPropagation()

    return false
  }
  closeShow() {
    document.body.style.overflow = 'auto'
    this.service.photoEvent.emit(null)
  }
}
