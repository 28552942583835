// -- copyright
// OpenProject is a project management system.
// Copyright (C) 2012-2015 the OpenProject Foundation (OPF)
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See doc/COPYRIGHT.rdoc for more details.
// ++

import {WorkPackageResource} from 'core-app/modules/hal/resources/work-package-resource';
import {combine, deriveRaw, InputState, multiInput, MultiInputState, State, StatesGroup} from 'reactivestates';
import {map} from 'rxjs/operators';
import {Injectable, Injector} from '@angular/core';
import {WorkPackageChangeset} from "core-components/wp-edit/work-package-changeset";
import {SchemaCacheService} from "core-components/schemas/schema-cache.service";
import {Subject} from "rxjs";
import {FormResource} from "core-app/modules/hal/resources/form-resource";
import {ChangeMap} from "core-app/modules/fields/changeset/changeset";
import {ResourceChangeset} from "core-app/modules/fields/changeset/resource-changeset";
import {HalResource} from "core-app/modules/hal/resources/hal-resource";
import {StateCacheService} from "core-components/states/state-cache.service";
import {HookService} from "core-app/modules/plugins/hook-service";
import { HttpClient } from "@angular/common/http";

class ChangesetStates extends StatesGroup {
  name = 'Changesets';

  changesets = multiInput<ResourceChangeset>();

  constructor() {
    super();
    this.initializeMembers();
  }
}

/**
 * Wrapper class for the saved change of a work package,
 * used to access the previous save and or previous state
 * of the work package (e.g., whether it was new).
 */
export class ResourceChangesetCommit<T extends HalResource = HalResource> {
  /**
   * The work package id of the change
   * (This is the new work package ID if +wasNew+ is true.
   */
  public readonly id:string;

  /**
   * The resulting, saved work package.
   */
  public readonly resource:T;

  /** Whether the commit saved an initial work package */
  public readonly wasNew:boolean = false;

  /** The previous changes */
  public readonly changes:ChangeMap;

  /**
   * Create a change commit from the change object
   * @param change The change object that resulted in the save
   * @param saved The returned work package
   */
  constructor(change:ResourceChangeset<T>, saved:T) {
    this.id = saved.id!.toString();
    this.wasNew = change.pristineResource.isNew;
    this.resource = saved;
    this.changes = change.changes;
  }
}

export interface ResourceChangesetClass {
  new(...args:any[]):ResourceChangeset;
}

@Injectable()
export class HalResourceEditingService extends StateCacheService<ResourceChangeset> {

  /** Committed / saved changes to work packages observable */
  public comittedChanges = new Subject<ResourceChangesetCommit>();

  /** State group of changes to wrap */
  private stateGroup = new ChangesetStates();

  constructor(protected readonly injector:Injector,protected readonly http: HttpClient,
              protected readonly hook:HookService) {
    super();
  }

  public async save<V extends HalResource, T extends ResourceChangeset<V>>(change:T):Promise<ResourceChangesetCommit<V>> {
    change.inFlight = true;
    let tablePayload=change.buildPayloadFromChanges();


    let SiteTable={...tablePayload};
    delete SiteTable.lockVersion;
    delete SiteTable._links;

    let tablePayloadData:any={};
    let tableKey = [
        "form_name",
        "client_department",
        "contract_no",
        "contract_title",
        "am",
        "pm",
        "mm",
        "form_date",
        "day",
        "day_counted",
        "thunderstorm",
        "rainstorm",
        "tripical_cyclone",
        "instruclions",
        "comments",
        "utilities",
        "progress",
        "visitor",
        "accidents",
        "remarks",
        "site_agent",
        "project_manager",
        "project_director",
        "construction_manager"
    ];
    tableKey.forEach(item => {
        for(const key of Object.keys(SiteTable)) {
            if (key === item) {
              tablePayloadData[key] = SiteTable[key];
            }
        }
    });
    // Form the payload we're going to save
    let [form, payload] = await change.buildRequestPayload();
    let obj:any={...payload};
    let imageid = localStorage.getItem("imageid")
    if(imageid){
      let imageArr =imageid.split(',')
      obj.photo_log_ids = imageArr
    }
    if(Object.keys(tablePayloadData).length!==0){
        obj.table={
            "template_id": 1,
            "payload": tablePayloadData,
            "schema": {
                "am": {
                    "name": "AM",
                    "type": "String",
                    "required": false,
                    "writable": true,
                    "maxLength": 255,
                    "minLength": 1,
                    "hasDefault": false
                },
                "mm": {
                    "name": "Rainfall(mm)",
                    "type": "String",
                    "required": false,
                    "writable": true,
                    "maxLength": 255,
                    "minLength": 1,
                    "hasDefault": false
                },
                "pm": {
                    "name": "PM",
                    "type": "String",
                    "required": false,
                    "writable": true,
                    "maxLength": 255,
                    "minLength": 1,
                    "hasDefault": false
                },
                "day": {
                    "name": "Day",
                    "type": "Date",
                    "required": false,
                    "writable": true,
                    "hasDefault": false
                },
                "form_date": {
                    "name": "Date",
                    "type": "Date",
                    "required": false,
                    "writable": true,
                    "hasDefault": false
                },
                "remarks": {
                    "name": "Remarks",
                    "type": "String",
                    "required": false,
                    "writable": true,
                    "maxLength": 255,
                    "minLength": 1,
                    "hasDefault": false
                },
                "visitor": {
                    "name": "Visitor(Record names of visitors and time of visit)",
                    "type": "String",
                    "required": false,
                    "writable": true,
                    "maxLength": 255,
                    "minLength": 1,
                    "hasDefault": false
                },
                "comments": {
                    "name": "Instruclions to Contractor(Recod verbal instructiong given)",
                    "type": "String",
                    "required": false,
                    "writable": true,
                    "maxLength": 255,
                    "minLength": 1,
                    "hasDefault": false
                },
                "progress": {
                    "name": "Progress (Mention briefly any matter delaying or obstructing progress)",
                    "type": "String",
                    "required": false,
                    "writable": true,
                    "maxLength": 255,
                    "minLength": 1,
                    "hasDefault": false
                },
                "accidents": {
                    "name": "Accidents(Describe any occurrence of accident)",
                    "type": "String",
                    "required": false,
                    "writable": true,
                    "maxLength": 255,
                    "minLength": 1,
                    "hasDefault": false
                },
                "rainstorm": {
                    "name": "Rainstorm Warning Signal",
                    "type": "String",
                    "required": false,
                    "writable": true,
                    "maxLength": 255,
                    "minLength": 1,
                    "hasDefault": false
                },
                "utilities": {
                    "name": "Untilities(Record Location & uature of Works)",
                    "type": "String",
                    "required": false,
                    "writable": true,
                    "maxLength": 255,
                    "minLength": 1,
                    "hasDefault": false
                },
                "form_name": {
                    "name": "Form Name",
                    "type": "String",
                    "required": true,
                    "writable": true,
                    "maxLength": 255,
                    "minLength": 1,
                    "hasDefault": true
                },
                "contract_no": {
                    "name": "Contract No",
                    "type": "String",
                    "required": true,
                    "writable": true,
                    "maxLength": 255,
                    "minLength": 1,
                    "hasDefault": true
                },
                "day_counted": {
                    "name": "Day counted from date of contract commenceet",
                    "type": "String",
                    "required": false,
                    "writable": true,
                    "maxLength": 255,
                    "minLength": 1,
                    "hasDefault": false
                },
                "instruclions": {
                    "name": "Comments by Supervisor's / Contractor's Represcutative",
                    "type": "String",
                    "required": false,
                    "writable": true,
                    "maxLength": 255,
                    "minLength": 1,
                    "hasDefault": false
                },
                "thunderstorm": {
                    "name": "Thunderstorm Warning Signal",
                    "type": "String",
                    "required": false,
                    "writable": true,
                    "maxLength": 255,
                    "minLength": 1,
                    "hasDefault": false
                },
                "contract_title": {
                    "name": "Contract Title",
                    "type": "String",
                    "required": true,
                    "writable": true,
                    "maxLength": 255,
                    "minLength": 1,
                    "hasDefault": true
                },
                "tripical_cyclone": {
                    "name": "Tripical Warning Signal",
                    "type": "String",
                    "required": false,
                    "writable": true,
                    "maxLength": 255,
                    "minLength": 1,
                    "hasDefault": false
                },
                "client_department": {
                    "name": "Client Department",
                    "type": "String",
                    "required": true,
                    "writable": true,
                    "maxLength": 255,
                    "minLength": 1,
                    "hasDefault": true
                }
            }
        };
    }
    // Reject errors when occurring in form validation
    const errors = form.getErrors();
    if (errors !== null) {
      change.inFlight = false;
      throw(errors);
    }
    let prefix :string='';
    let type_value:string='';
    this.hook.globalVar.subscribe(d=>{
      console.log(d)
      if(d.length > 0) {
        prefix=d[0].prefix;
        type_value=d[0].type_value;
      }
      if(type_value=='-'){
        type_value='';
      }
    });
    const savedResource = await change.pristineResource.$links.updateImmediately(obj);
    if(imageid){
      localStorage.removeItem('imageid')
    }
    this.saveFileRefNo(savedResource.id,prefix,type_value);
    // Initialize any potentially new HAL values
    let changeTable= change.pristineResource.table;
    if(changeTable){
        let changeTablePayload=changeTable.payload;
        if(changeTablePayload){
            Object.assign(change.pristineResource,changeTablePayload);
        }
    }
    let savedResourceTable= savedResource.table;
    if(savedResourceTable){
        let savedResourceTablePayload=savedResourceTable.payload;
        if(savedResourceTablePayload){
            Object.assign(savedResource,savedResourceTablePayload);
        }
    }
    savedResource.retainFrom(change.pristineResource);
    this.onSaved(savedResource);

    change.inFlight = false;

    // Complete the change
    return this.complete(change, savedResource);
  }


  //提交refno
  public saveFileRefNo(obj_id: string,prefix:string,type_value:string) {
    const obj_type:string='WorkPackage';
    if(prefix){
      const csrf_token: string | undefined = jQuery("meta[name=csrf-token]").attr("content");
      let newHeaders: any = {};
      newHeaders["X-Authentication-Scheme"] = "Session";
      newHeaders["X-Requested-With"] = "XMLHttpRequest";
  
      if (csrf_token) {
        newHeaders["X-CSRF-TOKEN"] = csrf_token;
      }
      this.http
        .post(
          `/api/v3/file_ref_no/update_file_ref_no`,
          {
            obj_id,
            obj_type,
            prefix,
            type_value
          },
          {
            withCredentials: true,
            headers: newHeaders
          }
        )
        .subscribe(res => {
          // console.log("activitiesData", res);
          let obj: any = res;  
          console.log(obj);
        });
    }
  }

  /**
   * Mark the given change as completed, notify changes
   * and reset it.
   */
  private complete<V extends HalResource, T extends ResourceChangeset<V>>(change:T, saved:V):ResourceChangesetCommit<V> {
    const commit = new ResourceChangesetCommit<V>(change, saved);
    this.comittedChanges.next(commit);
    this.reset(change);

    return commit;
  }

  /**
   * Reset the given change, either due to cancelling or successful submission.
   * @param change
   */
  public reset<V extends HalResource, T extends ResourceChangeset<V>>(change:T) {
    change.clear();
    this.clearSome(change.href);
  }

  /**
   * Returns the typed state value. Use this to get a changeset
   * for a subtype of ResourceChangeset<HalResource>.
   * @param resource
   */
  public typedState<V extends HalResource, T extends ResourceChangeset<V>>(resource:V):State<T> {
    return this.multiState.get(resource.href!) as InputState<T>;
  }

  /**
   * Create a new changeset for the given work package, discarding any previous changeset that might exist.
   *
   * @param resource
   * @param form
   *
   * @return The state for the created changeset
   */
  public edit<V extends HalResource, T extends ResourceChangeset<V>>(resource:V, form?:FormResource):T {
    const state = this.multiState.get(resource.href!) as InputState<T>;
    const changeset = this.newChangeset(resource, state, form);

    state.putValue(changeset);

    return changeset;
  }

  protected newChangeset<V extends HalResource, T extends ResourceChangeset<V>>(resource:V, state:InputState<T>, form?:FormResource):T {
    // we take the last registered group component which means that
    // plugins will have their say if they register for it.
    const cls = this.hook.call('halResourceChangesetClass', resource).pop() || ResourceChangeset;
    return new cls(resource, state, form) as T;
  }

  /**
   * Start or continue editing the work package with a given edit context
   * @param {resource} Hal resource to edit
   * @param {form:FormResource} Initialize with an existing form
   * @return {ResourceChangeset} Change object to work on
   */
  public changeFor<V extends HalResource, T extends ResourceChangeset<V>>(fallback:V):T {
    const state = this.multiState.get(fallback.href!) as InputState<T>;
    let resource = fallback;
    if (fallback.state) {
      resource = fallback.state.getValueOr(fallback);
    }
    let changeset = state.value;

    // If there is no changeset, or
    // If there is an empty one for a older work package reference
    // build a new changeset
    if (changeset && !changeset.isEmpty()) {
      return changeset;
    }
    if (!changeset || resource.hasOwnProperty('lockVersion') && changeset.pristineResource.lockVersion < resource.lockVersion) {
      return this.edit<V, T>(resource);
    }    

    return changeset;
  }

  /**
   * Get a temporary view on the resource being edited.
   * IF there is a changeset:
   *   - Merge the changeset, including its form, into the work package resource
   * IF there is no changeset:
   *   - The work package itself is returned.
   *
   *  This resource has a read only index signature to make it clear it is NOT
   *  meant for editing.
   *
   * @return {State<HalResource>}
   */
  public temporaryEditResource<V extends HalResource, T extends ResourceChangeset<V>>(resource:V):State<V> {
    const combined = combine(resource.state! as State<V>, this.typedState<V, T>(resource) as State<T>);

    return deriveRaw(combined,
      ($) => $
        .pipe(
          map(([resource, change]) => {
            if (resource && change && !change.isEmpty()) {
              return change.projectedResource as V;
            } else {
              return resource;
            }
          })
        )
    );
  }

  public stopEditing(resource:HalResource|{ href:string }) {
    this.multiState.get(resource.href!).clear();
  }

  protected load(href:string):Promise<ResourceChangeset> {
    return Promise.reject('Loading not applicable for changesets.') as any;
  }

  protected onSaved(saved:HalResource) {
    if (saved.state) {
      saved.push(saved);
    }
  }

  protected loadAll(hrefs:string[]) {
    return Promise.all(hrefs.map(href => this.load(href))) as any;
  }

  protected get multiState():MultiInputState<ResourceChangeset> {
    return this.stateGroup.changesets;
  }
}

