<div class="toolbar-container -with-dropdown">
  <div class="toolbar">
    <ul class="toolbar-items">
      <li class="toolbar-item">
        <button type="button -alt-highlight"
                class="form-configuration--reset button"
                (click)="resetToDefault($event)">
          <op-icon icon-classes="button--icon icon-undo"></op-icon>
          <span class="button--text" [textContent]="text.reset"></span>
        </button>
      </li>
      <li class="toolbar-item drop-down">
        <a ng-click="showGroupsContextMenu($event)"
           class="form-configuration--add-group button -alt-highlight" aria-haspopup="true">
          <op-icon icon-classes="button--icon icon-add"></op-icon>
          <span class="button--text" [textContent]="text.label_group"></span>
          <op-icon icon-classes="button--dropdown-indicator"></op-icon>
        </a>
        <ul class="menu-drop-down-container">
          <li>
            <a class="form-configuration--add-group"
               [textContent]="text.add_group"
               (accessibleClick)="createGroup('attribute', '')">
            </a>
          </li>
          <li>
            <a class="form-configuration--add-group"
               [textContent]="text.add_table"
               (accessibleClick)="addGroupAndOpenQuery()">
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>

<div class="grid-block wrap">
  <div class="grid-content small-12 medium-6">
    <div id="draggable-groups" dragula="groups" [(dragulaModel)]="groups">
      <ng-container *ngFor="let group of groups">
        <type-form-attribute-group *ngIf="group.type === 'attribute'"
                                   (removeAttribute)="deactivateAttribute($event)"
                                   (editAttribute)="editAttribute(group)"
                                   (deleteGroup)="deleteGroup(group)"
                                   [group]="group">
        </type-form-attribute-group>
        <type-form-query-group *ngIf="group.type === 'query'"
                               (editQuery)="editQuery(group)"
                               (deleteGroup)="deleteGroup(group)"
                               [group]="group">
        </type-form-query-group>
      </ng-container>
    </div>
  </div>
  <div class="grid-content small-12 medium-6">
    <div id="type-form-conf-inactive-group">
      <div class="group-head">
        <span class="group-name" [textContent]="text.label_inactive"></span>
        &ngsp;
        <span class="advice" [textContent]="text.drag_to_activate"></span>
      </div>
      <div class="attributes" dragula="attributes" [(dragulaModel)]="inactives">
        <div *ngFor="let inactive_attribute of inactives"
             class="type-form-conf-attribute"
             [attr.data-key]="inactive_attribute.key">
          <span class="attribute-handle icon-drag-handle"></span>
          <span class="attribute-name">
              {{ inactive_attribute.translation }}
            <span *ngIf="inactive_attribute.is_cf"
                  class="attribute-cf-label"
                  [textContent]="text.custom_field">
              </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</div><!-- END type form configurator -->
<div class="op-modals--overlay" *ngIf="isModals">
  <div class="op-modal--portal ngdialog-theme-openproject">
      <div class="op-modal--modal-container confirm-dialog--modal loading-indicator--location">
          <div class="op-modal--modal-header">
              <a class="op-modal--modal-close-button" (click)="close()">
                  <i class="icon-close" title="Close"></i>
              </a>
              <h3 class="icon-context icon-attention">{{groupName}}</h3>
          </div>
          <div class="ngdialog-body op-modal--modal-body" [ngStyle]="{alignItem:  'flex-end'}">
              <div class="form--field">
                      <label class="form--label">
                          {{I18n.t("js.admin.type_form.set_principal")}}
                      </label>
                  <div class="form--field--value-container" >
                    <ng-container >
                      <div class="principalList">
                        <div *ngFor="let item of principalArray let i = index">
                          <span class="name">
                            {{item.name}}
                          </span>
                          <span class="delete" (click)="deleteItem(i)">X</span>
                        </div>
                      </div>
                    </ng-container>
                    <div [ngStyle]="{display: 'flex'} ">

                      <select class="attribute-fileRefNo-select" [(ngModel)]="principal" [ngStyle]="{
                        width:'70%',
                        display:  'inline-block'
                      }">
                      <optgroup [label]="items.label" *ngFor="let items of membersData; trackBy:trackByName">
                        <option *ngFor="let item of items.list; trackBy:trackByName" [value]="items.label+'+'+item.id + '+' + item.name">
                          {{item.name}}
                        </option>
                      </optgroup>
                    </select>
                    <div class="principalAdd"  [ngStyle]="{
                      backgroundColor: isPrincipalIncludes()? '#eee': ''
                    }" (click)="addprincipal()">
                      +
                    </div>
                  </div>
                  </div>
              </div>
              <div class="form--field">
                    <label class="form--label">
                        {{I18n.t("js.admin.type_form.set_valid_statuses")}}
                      </label>
                  <div class="form--field--value-container">
                      <div class="form--field"  *ngFor='let item of validStatuses'>
                          <label class="form--label">
                            {{item.name}}
                          </label>
                          <span class="form--field-container">
                              <label class="form--label-with-check-box">
                                  <span class="form--check-box-container">
                                      <input type="checkbox" [value]="item.id" [(ngModel)]='item.isChecked'
                                          class="form--check-box" />
                                  </span>
                              </label>
                          </span>
                      </div>
                  </div>
              </div>
              <button type="button" class="-highlight -with-icon icon-checkmark button" (click)="save()">Save</button>
          </div>
      </div>
  </div>
</div>