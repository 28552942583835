import { NgModule } from '@angular/core';
import { OpenprojectCommonModule } from '../common/openproject-common.module';
import { Ng2StateDeclaration, UIRouterModule } from '@uirouter/angular';
import { DocumentsComponent } from './documents.component';
import { DocumentsItemComponent } from './components/documents-item/documents-item.component';
import { DocumentsOperationComponent } from './components/documents-operation/documents-operation.component';
import { DocumentsToastComponent } from './components/documents-toast/documents-toast.component';
import { DocumentsLoadingComponent } from './components/documents-loading/documents-loading.component';
import { DocumentsOperationModalComponent } from './components/documents-operation-modal/documents-operation-modal.component';
import { DocumentsOperationListComponent } from './components/documents-operation-list/documents-operation-list.component';
import { DocumentsInputNameComponent } from './components/documents-input-name/documents-input-name.component';
import { DocumentsCatalogTreeComponent } from './components/documents-catalog-tree/documents-catalog-tree.component';
import { DocumentsShareComponent } from './components/documents-share/documents-share.component'
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { DocumentsPaginationComponent } from './components/documents-pagination/documents-pagination.component';
import { DocumentsPreviewComponent } from './components/documents-preview/documents-preview.component';
import { DocumentsPdfComponent } from './components/documents-pdf/documents-pdf.component'
import { from } from 'rxjs';

export const DOCUMENTS_ROUTES: Ng2StateDeclaration[] = [
  {
    name: 'documents',
    url: '/documents?path',
    parent: 'root',
    component: DocumentsComponent
  }
];

@NgModule({
  imports: [
    OpenprojectCommonModule,
    HttpClientModule,
    FormsModule,
    UIRouterModule.forChild({ 
      states: DOCUMENTS_ROUTES
    })
  ],
  declarations: [
    DocumentsComponent,
    DocumentsItemComponent,
    DocumentsOperationComponent,
    DocumentsToastComponent,
    DocumentsLoadingComponent,
    DocumentsOperationModalComponent,
    DocumentsOperationListComponent,
    DocumentsInputNameComponent,
    DocumentsCatalogTreeComponent,
    DocumentsPaginationComponent,
    DocumentsPreviewComponent,
    DocumentsShareComponent,
    DocumentsPdfComponent
  ]
})

export class DocumentsModule { }