<div class="documents-operation">
    <div class="operation-header">
        {{ title[type] }}
    </div>
    <ng-container>
        <div class="select-m">
            <span class="select-title">
                <!-- 选择文件夹 -->
                {{ i18n.t("js.documents.select_folder") }}
            </span>
            <span class="select-cancel" (click)="handleCancel()">
                <!-- 取消 -->
                {{ i18n.t("js.documents.cancel") }}
            </span>
        </div>
        <div class="create-m">
            <div class="create-button-m" (click)="createFolder()">
                <!-- 新建文件夹 -->
                {{ i18n.t("js.documents.new_folder") }}
            </div>
        </div>
    </ng-container>
    <div class="operation-body">
        <documents-catalog-tree [value]="catalogTree"></documents-catalog-tree>
    </div>
    <div class="operation-footer">
        <div class="operation-create" (click)="createFolder()">
            <!-- 新建文件夹 -->
            {{ i18n.t("js.documents.new_folder") }}
        </div>
        <div class="operation-confirm">
            <div class="operation-yes" (click)="handleConfirm()">
                <!-- 确定 -->
                {{ i18n.t("js.documents.ok") }}
            </div>
            <div class="operation-no" (click)="handleCancel()">
                <!-- 取消 -->
                {{ i18n.t("js.documents.cancel") }}
            </div>
        </div>
    </div>
    <div class="footer-m">
        <div class="confirm" (click)="handleConfirm()">
            <!-- 确定 -->
            {{ i18n.t("js.documents.ok") }}
        </div>
    </div>
    <div class="create-folder">
        <documents-input-name
            (cancel)="handleInputCancel()"
            (confirm)="handleInputConfirm($event)"
            *ngIf="inputNameShow">
        </documents-input-name>
    </div>
    <div id="operation-mask"
        (click)="closeMask()"
        *ngIf="service.operationMask || inputNameShow">
    </div>
    <documents-loading *ngIf="service.operationLoading"></documents-loading>
</div>