<div class="editcontent">
    <div class="putImg">
        <ng-container  *ngIf="saveimg">
            <img [src]="item" *ngFor="let item of saveimg">
        </ng-container>
        <img [src]="singdata.image_url+'?image_size=thumbnail'"  *ngIf="!saveimg&&singdata">
        
    </div>
    <b>{{ i18n.t("js.photo-log.project") }}:</b>
    <span *ngIf="singdata">{{singdata.project.name}}</span>
    <b>{{ i18n.t("js.photo-log.photographer") }}:</b>
    <span *ngIf="singdata">{{singdata.user.name}}</span>
    <div>
<b style="margin-right: 300px;">{{ i18n.t("js.photo-log.use") }}</b> <b *ngIf="editFormdata.photo_log_purpose_id==1">{{ i18n.t("js.photo-log.status") }}</b>
</div>
<div>
<select [(ngModel)]="editFormdata.photo_log.photo_log_purpose_id"><option value="1">须跟进</option>
    <option value="2">记录用途</option>
</select>
<select [(ngModel)]="editFormdata.photo_log.photo_log_state_id" *ngIf="editFormdata.photo_log.photo_log_purpose_id==1"><option value="1">跟进中</option>
    <option value="2">已完成</option>
</select></div>
    <b>{{ i18n.t("js.photo-log.detail.location") }}</b>
    <div>
        <p *ngFor="let item of saveData;let i =index">
        <ng-container *ngIf="editFormdata.location['location' + i ] || i === 0">
            <strong class="setmargin">{{getKey(item)}}:</strong>
            <select [(ngModel)]="editFormdata.location['location' + (i + 1)]">
                <option [value]="subitem.value" *ngFor="let subitem of item[getKey(item)]" >{{subitem.value}}</option>
            </select>
        </ng-container>      
        </p>
    </div>
    <b>GPS</b>
    <span>当前位置</span>
    <b>{{ i18n.t("js.photo-log.remark") }}</b>
    <input type="text" [(ngModel)]="editFormdata.photo_log.remarks" *ngIf="saveimg">
    <input type="text" [(ngModel)]="editFormdata.photo_log.remarks" *ngIf="!saveimg">
    <div>
        <button class="btn savebtn" (click)="goEditPhotolog()">{{ i18n.t("js.photo-log.detail.save") }}</button><button class="btn cancelbtn" (click)="hideeditpage()">{{ i18n.t("js.photo-log.cancel") }}</button>
    </div>

</div>