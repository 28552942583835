<div class="documents-operation-modal">
    <div class="modal-item" (click)="handleCreateFolder()">
        <div class="documents-create-m create-m-icon"></div>
        <span class="operation-modal-text">
            <!-- 新建文件夹 -->
            {{ i18n.t("js.documents.new_folder") }}
        </span>
    </div>
    <div class="border"></div>
    <div class="modal-item" (click)="handleUploadFile()">
        <div class="documents-upload-m create-m-icon"></div>
        <span class="operation-modal-text">
            <!-- 上传文件 -->
            {{ i18n.t("js.documents.upload_file") }}
        </span>
    </div>
</div>