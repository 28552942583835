import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SiteDiaryService,SiteDiaryItem} from '../services/SiteDiary.service';


@Component({
  selector: 'SiteDiary-Details',
  templateUrl: './SiteDiary-Details.component.html',
  styleUrls: ['./SiteDiary-Details.component.sass']
})
export class SiteDiaryDetailsComponent implements OnInit {
  
  @Output() submitButton = new EventEmitter();


  public divClick = 'summary'
  public addActivty = false
  public updataActivty = null
  public summaryData:SiteDiaryItem 
  public showLoading: boolean = false

  public objectKeys = Object.keys;

  constructor(
    public service: SiteDiaryService,
  ) { }

  ngOnInit() {
    let locationData = window.location.search.replace('?','?&',).split('&')
    locationData.forEach((res,index)=>{
      if(res === '?') return
      let itemdata = res.split('=')
      if(itemdata[0] === 'siteDiary'){
        this.service.siteDiaryShow({site_diary_id:itemdata[1]}).subscribe((res:SiteDiaryItem)=>{
          console.log(res)
          this.summaryData = res
          console.log(this.summaryData)
        })
      }
    })
  }

  //分类按钮
  classificationClick(value:any){
    this.divClick = value
  }

  //添加Activty
  addActivtyClick(){
    this.updataActivty=null
    this.addActivty = !this.addActivty
    jQuery('#top-menu').css('z-index','unset')

  }

  //table修改按钮
  tableEdit(data:any){
    console.log(data)
    this.updataActivty = data
    this.addActivty = !this.addActivty
  }

  //提交按钮
  submitClick(){
    console.log(this.summaryData)
    let data:any = this.summaryData
    data['site_diary_id'] = this.summaryData.id
    this.showLoading = true
    this.service.updataSiteDiaryShow(data).subscribe((res)=>{
      console.log(res)
      window.history.replaceState(null, '', `${window.location.origin}${window.location.pathname}`)
      this.submitButton.emit()
      this.showLoading = false
    },(error:any)=>{
      alert(error.error.error)
      this.showLoading = false
    })

  }

  //取消按钮
  closeClick(){
    window.history.replaceState(null, '', `${window.location.origin}${window.location.pathname}`)
    this.submitButton.emit()
  }

}
