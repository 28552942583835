<div class="tableList">
  <table>
    <thead>
      <tr>
        <th>Data Date</th>
        <th >Created at</th>
        <th >File generated </th>
        <th >Sent at</th>
        <th >Status</th>
        <th >Transmission Log</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of list"  >
        <td>{{item.data_date}}</td>
        <td>{{item.created_at}}</td>
        <td><a class="underLine" [href]="getZipFileDown(item.doc_id,item.file_name)">{{item.file_name}}</a></td>
        <td>{{item.sent_at}}</td>
        <td>{{item.status}}</td>
        <td class="underLine" ><a class="underLine" [href]="getTextFileDown(item.log_doc_id,item.file_name)">{{item.file_name}}.txt</a></td>

      </tr>
    </tbody>
  </table>
  <div id="page"></div>
</div>


<div class="attributes-key-value__loading uploadLoading" *ngIf="downLoading">
<div class="loading-indicator -compact">
<div class="block-1"></div>
<div class="block-2"></div>
<div class="block-3"></div>
<div class="block-4"></div>
<div class="block-5"></div>
</div>
</div> 
