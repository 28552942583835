import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { any, compose } from '@uirouter/core';
import { SiteDiaryService ,SiteDiaryItem} from '../../services/SiteDiary.service';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'SiteDiary-Details-Summary',
  templateUrl: './SiteDiary-Details-Summary.component.html',
  styleUrls: ['./SiteDiary-Details-Summary.component.sass']
})
export class SiteDiaryDetailsSummaryComponent implements OnInit {

  @Input() public summaryData:SiteDiaryItem

  public selectDate = [
    {name:'Cloudy',value:'Cloudy'},
    {name:'Drizzle',value:'Drizzle'},
    {name:'Fine',value:'Fine'},
    {name:'Rain',value:'Rain'},
    {name:'Shower',value:'Shower'}
  ]
  public day=['Sunday','Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  public fileData:any

  public displayPupop = false
  public pupopType:any

  public inputNuble = 0
  public pmUserData:any
  public contractorUserData:any
  public supervisorUserData:any
  public id:any
  public dataList=['Consultant','Contractor']
  constructor(
    public service: SiteDiaryService,
    protected cdRef:ChangeDetectorRef,
    public http: HttpClient
  ) { }

  ngOnInit() {
    this.inputNuble = this.summaryData.rainfall
    this.service.getFileAddress(this.summaryData.id).subscribe((res:any)=>{
      this.fileData = res.data.file_ref_no
    })
    const currentProject: any = document.querySelector('meta[name=current_project]');
    this.id = currentProject.dataset.projectId
    this.getUserData()
  }
  
  rainfallChange(event: any){
    var reg1 = /^[+-]?(0|([1-9]\d*))(\.\d*)?$/;
    if(!event) return
    if (!reg1.test(event)) {
      let dada:any = jQuery('#rainfallInput')[0]
      dada.value = 0
      this.summaryData.rainfall = 0
      this.cdRef.detectChanges();
    }
  }

  ngAfterViewInit(){
    
    if(this.summaryData.state == 'draft'||this.summaryData.state=='rejected') return
    jQuery('#siteDiary_details')[0].childNodes.forEach((res:any)=>{
      if(res.className == 'summary-key-value' || res.className == 'summary-text-key-value'){
        res.children[1].disabled = true
      }
    })
    
  }

  //添加Assistance 和 Site Staff按钮
  toAddSfAndAc(value:any){
    this.pupopType = value
    this.displayPupop = !this.displayPupop
    jQuery('#top-menu').css('z-index','unset')

  }

  setHight(el:any) {
    jQuery(el.target).css('height', 'auto')
    jQuery(el.target).css('height', el.target.scrollHeight)
  }
  //添加Assistance 和 Site Staff弹窗返回事件
  Pupopclose(){
    this.displayPupop = !this.displayPupop
    jQuery('#top-menu').css('z-index','22')
  }
  //获取用户列表数据
  getUserData(){
    this.dataList.forEach(item=>{
     this.http.get(`api/v3/projects/${ this.id }/available_assignees?role=${item}`).subscribe((res: any) => {
        console.log(res,'数据');
        if(item == 'Consultant' ){
          this.pmUserData = res._embedded.elements.filter( (item:any) => {
            return item._type == 'User'
           })
          this.supervisorUserData = this.pmUserData
        }else{
          this.contractorUserData = res._embedded.elements.filter( (item:any) => {
            return item._type == 'User'
           })
        }
       
     })
    })
  }
}
