<!--
 * @Author: your name
 * @Date: 2020-06-03 17:45:26
 * @LastEditTime: 2020-06-10 15:49:39
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /junhe/frontend/src/app/modules/check_lists/components/check_lists-autograph/check_lists-autograph.component.html
--> 

<div class="canvascss">
<signature-pad id="signatureCanvas" [options]="signaturePadOptions"></signature-pad>
<button (click)='drawComplete(true)'>{{ i18n.t("js.check-lists.quest.done") }}</button>
</div>


<div class="close">
  <span>x</span>
</div>

<div class="loding"  (click)='drawComplete(false)'></div>
