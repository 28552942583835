import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { I18nService } from 'core-app/modules/common/i18n/i18n.service';

@Component({
  selector: 'cunstom-forms-setup',
  templateUrl: './cunstom-forms-setup.component.html',
  styleUrls: ['./cunstom-forms-setup.component.sass']
})
export class CunstomFormsSetupComponent implements OnInit {
  @Output() notify = new EventEmitter();

  public displeloading = false
  public checkoutdata: any
  public checkoutiscustom: []
  public projectId: any
  public csrf_token: any
  public newHeaders: any

  constructor(
    readonly http: HttpClient,
    public i18n: I18nService
  ) { }

  ngOnInit() {
    const currentProject: any = document.querySelector('meta[name=current_project]');
    this.projectId = currentProject.dataset.projectId
    this.csrf_token = jQuery("meta[name=csrf-token]").attr("content");
    let newHeaders: any = {};
    newHeaders["X-Authentication-Scheme"] = "Session";
    newHeaders["X-Requested-With"] = "XMLHttpRequest"
    if (this.csrf_token) {
      newHeaders["X-CSRF-TOKEN"] = this.csrf_token;
    }
    this.newHeaders = newHeaders
    this.getTableTitle()
  }

  getTableTitle() {
    const that = this
    console.log(that.projectId);

    that.http.get(
      `api/v3/projects/${that.projectId}/custom_forms/types`,
      {
        withCredentials: true,
        headers: that.newHeaders
      }
    ).subscribe((res: any) => {
      console.log(res);
      that.checkoutdata = res.data
      that.checkoutiscustom = res.data.filter((item: any) => { return item.is_custom_form }).map((index: any) => { return index.id })

    });
  }

  toggleVisibility(e: any) {
    this.checkoutiscustom = this.checkoutdata.filter((item: any) => { return item.is_custom_form }).map((index: any) => { return index.id })
  }

  pushclick() {
    this.displeloading = true
    const that = this
    console.log(that.checkoutiscustom);

    that.http.post(
      `api/v3/projects/${that.projectId}/custom_forms/types`,
      {
        ids: that.checkoutiscustom,
      },
      {
        withCredentials: true,
        headers: that.newHeaders
      }
    ).subscribe((res: any) => {
      console.log(res);
      that.displeloading = false
      that.notify.emit(false)
    });
  }

}
