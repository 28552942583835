<div class="documents-input-name">
  <div class="title">{{ title[type] }}</div>
  <div class="input">
    <input type="text" [(ngModel)]="name">
  </div>
  <div class="operation">
    <div class="button-cancel" (click)="handleCancel()">
      <!-- 取消 -->
      {{ i18n.t("js.documents.cancel") }}
    </div>
    <div class="border"></div>
    <div class="button-confirm" (click)="handleConfirm()">
      <!-- 确定 -->
      {{ i18n.t("js.documents.ok") }}
    </div>
  </div>
</div>