import { Component, Input, OnInit, Output, EventEmitter} from '@angular/core';

import { SEDNService } from '../../service/SEDN.service';

@Component({
  selector: 'sednDetail',
  templateUrl: './sednDetail.component.html',
  styleUrls: ['./sednDetail.component.sass']
})
export class SednDetailComponent implements OnInit {
  @Input() public SEDNItem:any
  @Input() public dipslesignature:any
  @Output() public changeNow = new EventEmitter()
  public  signaturePadOptions: Object = {
    minWidth: 0.5,// 比划的粗细,
    canvasWidth: 545, // 可写宽度
    canvasHeight: 200,// 可写高度
  };
  public Item:any 
  public actionSign:any
  public actiondocId:any
  public replySign:any
  public replydocId:any
  public signType:String
  public signloding:boolean = false
  public actionRequire:any
  public query:Map<any, any> = new Map
  public editTYpe:any|String
  public ImgGroup:Array<any>
  public ifCheckAssign: boolean
  public contractorUsers: Array<any>
  public constructorGroup: Array<any>
  public constructorSelect: any
  public constructorUserId: any
  public assignId:string
  public state:any 
  public reSignLoad: any
  public uploadLoading:any = false
  public showImg:any
  public replied_right:any
  public assignType:any = 'Indlvidual'
  public deFicienciesList:any = []
  public deficiency_id:any
  public imgBoolean: boolean = false
  public isWriteIn:boolean = false //completed状态不可以更改内容
  constructor(
    public service: SEDNService
  ) { }
  
  ngOnInit() {
    if(this.SEDNItem.state == 'Completed'){
      this.isWriteIn = true
    }
    this.service.getdeFicienciesTypesList().subscribe( (res:any) => {
      console.log(res)
      this.deFicienciesList = res.data
    })
    this.service.contractorUsers().subscribe( (res:any) => {
      this.contractorUsers = res.filter( (item:any) => {
        return item.type == 'User'
      })
      this.constructorSelect = this.contractorUsers
      this.constructorGroup = res.filter( (item:any) => {
        return item.type == 'Group'
      })
    })
    this.service.eventBus.subscribe( (res:any) => {
      console.log(res)
      this.uploadLoading = res
    })
    this.service.photoEvent.subscribe( (res:any) => {
      this.showImg = res
    })
    if(this.SEDNItem) {
      this.Item = this.SEDNItem
      this.ImgGroup = this.SEDNItem.image_groups
      if(this.Item.replied_by) {

        if(this.Item.replied_by.type == 'Group') {
          this.replied_right = this.Item.replied_right
         this.Item.replied_right = this.Item.replied_right && !!this.Item.executor_id
        }
      }
      if(this.Item.prepared_sign_id) {
        this.actiondocId = this.Item.prepared_sign_id
        this.actionSign = location.origin + `/api/v3/logical_docs/doc/${this.Item.prepared_sign_id}/stream` 
      }
      if(this.Item.replied_sign_id){
        this.replydocId = this.Item.replied_sign_id
        this.replySign = location.origin + `/api/v3/logical_docs/doc/${this.Item.replied_sign_id}/stream` 
      }
    } else {
      location.search.slice(1).split('&').forEach( (item:String) => {
        this.query.set(  item.split('=')[0], item.split('=')[1])
      })
      if(this.query.get('type')) {
        this.editTYpe = this.query.get('type')
  
          this.service.getSEDNItem(this.query.get('sednId')).subscribe( (res:any) => {
              
            if(res.replied_by && res.replied_by.type == 'Group') {
              this.replied_right = res.replied_right
              res.replied_right = res.replied_right && !!res.executor_id
             }
            this.saveData(res)
          })
         
  
      }
    }
    
   
  }
  handleExecutor() {
    console.log()
    console.log(this.Item)
    if(this.replied_right) {

      this.service.handleExecutor(this.Item.id, !this.Item.executor_id).subscribe( (res:any) => {
        this.replied_right = res.replied_right
        this.Item.replied_right = res.replied_right && res.executor_id
        this.Item.executor_id = res.executor_id
        this.Item.executor = res.executor
      })
    } 
  }
  changeAssignTo(tip:any) {
    this.assignType = tip 
    if(tip == 'Indlvidual'){
      this.constructorSelect = this.contractorUsers
    } else {
      this.constructorSelect = this.constructorGroup
    }

  }

  photoGroupEvent(type:any, index:any) {
    console.log(type, index, this.ImgGroup.length, type == 'delete' && index== 0)
    if(type == 'add' && index + 1 == this.ImgGroup.length){
      this.addNewphotoGroup()
      this.imgBoolean = true
    } else if(type == 'delete' && index + 1== this.ImgGroup.length - 1) {
      this.deletePhotoGroup(index)
      this.imgBoolean = false
    } else if(type == 'delete' && index !== 0) {
      this.deletePhotoGroup(index)
    } else if(type == 'delete' && index== 0){
      this.imgBoolean = false
      console.log(this.imgBoolean)
    }
  }
  addNewphotoGroup() {
    this.service.addImgGroup(this.Item.id).subscribe( (res:any) => {
      this.ImgGroup.push(res)
    })
  }
  deletePhotoGroup(index:any) {
    this.service.deleteSEDNPhotoGroup(this.Item.id,this.ImgGroup[index].id).subscribe( () => {
 
        this.ImgGroup.splice(index, 1)
      
    }) 
  }
  displaysign(type:any){
    if((!this.Item.prepared_right&&type == 'action'  ) || (!this.Item.replied_right && type == 'reply') ){
      return
    }
    this.signType = type
    this.dipslesignature = true
  }
  deleteReplySign() {
    this.replySign = null
    this.replydocId = null
  }
  deleteActionSign() {
    this.actionSign = null
    this.actiondocId = null
  }
  saveData(res:any) {
    this.Item = res
    if(res.prepared_sign_id){
      this.actiondocId = res.prepared_sign_id
      this.actionSign = location.origin + `/api/v3/logical_docs/doc/${this.Item.prepared_sign_id}/stream` 
    }
    if(res.replied_sign_id) {
      this.replydocId = res.replied_sign_id
      this.replySign = location.origin + `/api/v3/logical_docs/doc/${this.Item.replied_sign_id}/stream` 
    } else {
      this.replySign = null
    }
    this.ImgGroup = res.image_groups
    console.log(res)
    if(this.Item.state == 'Completed'){
      this.isWriteIn = true
    }
  }
  dataURLtoBlob(dataurl:any) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }
  Assign() {
    if(this.imgBoolean && this.Item.deficiency_id&&this.Item.location && this.Item.site_activity) {
      this.ifCheckAssign = true
    }
  }
  AssignSave() {
    if(!this.constructorUserId) {
      return
    }
    
    let cc_ids = this.contractorUsers.filter( (item:any) => {
      return item.checked
    }).map( (item:any) => {
      return item.id
    }).concat(
      this.constructorGroup.filter( (item:any) => {
        return item.checked
      }).map( (item:any) => {
        return item.id
      })
    )

    let data = {
      location: this.Item.location,
      site_activity: this.Item.site_activity,
      action_required: this.Item.action_required,
      deficiency_id: this.Item.deficiency_id,
      cc_ids
    } as any
    data.replied_by_id
    if(this.actiondocId){
      data.prepared_sign_id = this.actiondocId
    }
    data.replied_by_id = this.constructorUserId
    console.log(this.Item.state, this.Item.state == 'Completed')
    if(this.Item.state == 'Completed') {
      data.replied_sign_id = ''
    }
    data.state = 'Sent'
    if(this.ImgGroup.length !==1 && this.Item.state == 'New'){

      this.deletePhotoGroup(this.ImgGroup.length - 1)
    }
    this.service.saveSEDN(data, this.Item.id).subscribe( (res:any) => {
      this.saveData(res)
      this.changeNow.emit()
      this.ifCheckAssign = false
      
    })
  }
  CompleteSave() {
    let data = {
      location: this.Item.location,
      site_activity: this.Item.site_activity,
      action_required: this.Item.action_required,
      deficiency_id: this.Item.deficiency_id,
      replied_sign_id: this.replydocId,
      remark: this.Item.remark,
      replied_by_id: document.getElementsByName('current_user')[0].dataset.id,
      state: 'Completed'
    } as any
    if(this.Item.replied_right && !this.replySign) {
      return
    }
    if(this.actiondocId){
      data.prepared_sign_id = this.actiondocId
    }


    this.service.saveSEDN(data, this.Item.id).subscribe( (res:any) => {
      this.saveData(res)
      this.changeNow.emit()
    })
  }
  closeSedn() {
    let data = {
      state: 'Closed',
      action_required: this.Item.action_required,
    }
    this.service.saveSEDN(data, this.Item.id).subscribe( (res:any) => {
      this.saveData(res)
      this.changeNow.emit()
    })
  }
  closeView(img:any){
    if(img){
      if(this.signType == 'action'){
        if(!this.Item.prepared_right) {
          return
        }
        let blob = this.dataURLtoBlob(img)
        var files = new File([blob], 'drawComplete.png', { type: blob.type })
        let file = new FormData();
        file.append('file', files);
        file.append('prefix', 'risc');
        this.signloding = true
        this.service.postSign(file).subscribe( (res:any)=> {
          console.log(res)
          this.actiondocId = res.doc_id
          this.signloding = false
          this.actionSign = img
        })
      } else {
        if(!this.Item.replied_right) {
          return
        }
        let blob = this.dataURLtoBlob(img)
        var files = new File([blob], 'drawComplete.png', { type: blob.type })
        let file = new FormData();
        file.append('file', files);
        file.append('prefix', 'risc');
        this.reSignLoad = true
        this.service.postSign(file).subscribe( (res:any)=> {
          console.log(res)
          this.replydocId = res.doc_id
          this.reSignLoad = false
          this.replySign = img
        })
     
      }
    } 
    this.dipslesignature = false 
    console.log(img)
  }
  saveSEDN(){
    let data = {
      location: this.Item.location,
      site_activity: this.Item.site_activity,
      action_required: this.Item.action_required,
      deficiency_id: this.Item.deficiency_id,
    } as any
    if(this.actiondocId){
      data.prepared_sign_id = this.actiondocId
    }
    if(this.Item.replied_right){
      data.remark = this.Item.remark
      data.replied_by_id = document.getElementsByName('current_user')[0].dataset.id
    }
    if(this.replySign && this.Item.replied_right) {
      data.replied_sign_id = this.replydocId
    }
    this.service.saveSEDN(data, this.Item.id).subscribe( (res:any) => {
      this.changeNow.emit()
      this.saveData(res)
    })
  }

}
