/*
 * @Author: your name
 * @Date: 2020-06-17 14:24:02
 * @LastEditTime: 2020-07-17 18:39:53
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /junhe/frontend/src/app/modules/check_lists/components/check_lists-newtemplate/check_lists-newtemplate.component.ts
 */ 
import { Component, OnInit, Input, ChangeDetectorRef, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { I18nService } from 'core-app/modules/common/i18n/i18n.service';
import { SortablejsOptions } from 'ngx-sortablejs';
import { DocumentsService } from '../../services/check.lists.service';
import { input } from 'reactivestates';
import { fromEvent } from 'rxjs';


@Component({
  selector: 'check_lists-newtemplate', 
  templateUrl: './check_lists-newtemplate.component.html',
  styleUrls: ['./check_lists-newtemplate.component.sass']
})
export class Check_listsNewtemplateComponent implements OnInit {

  constructor(

    
    public i18n: I18nService,
    protected cdRef:ChangeDetectorRef,
    public service: DocumentsService
  ) { }
  @Input() public dataObjectInfo:any
  @Input() public yesIssue:any
  @Input() public updatetemplate:any
  @Input() public dataitem:any
  @Input() public kind_id:any

  // @Input() public nav_pos:any

  @Output() finish = new EventEmitter();
  @Output() closeTep = new EventEmitter();
  @Output() backStep = new EventEmitter();


  options: SortablejsOptions = {
    group: 'name',
    handle:'.shuliangicon',
    onUpdate: () => {
      console.log('updated');
      
    }
  };

  signArrData: SortablejsOptions = {
    group: 'name',
    handle:'.BimQualityApp',
    onUpdate: () => {
      console.log('updated');
      
    }
  };

  
  dataoptions: SortablejsOptions = {
    group: 'name',
    handle:'.BimQualityApp',
    draggable:'.content-li',
    scrollSensitivity: 30,
    onUpdate: () => {
      console.log('updated');
      
    }
  };

  public data:any

  public signs:any = [{"finished_at":"","user_id":'',"doc_id":""}]

  public index:any=0
  public addClass1:any= false
  public addClass2:any = false
  public showUl:any = false
  public showUl2:any= false
  public typeValue:any = 'text'
  public showList:any = true
  public showSign:any = false
  public showSignLi:any = false
  public signIndex:any
  public byuserdata:any
  public projectId:any
  public id:any
  public templateData:any={}
  public showFinish:any=false





  ngOnChanges(changes: SimpleChanges) {
    console.log(this.yesIssue)



      
    if(this.yesIssue){
      this.templateData = this.dataObjectInfo
      this.templateData.parts = this.data
      if(this.dataObjectInfo.sign_required == 'sign_no_need'){
        this.templateData.signs = []
      }else{
        this.templateData.signs = this.signs
      }

      console.log('????????',this.updatetemplate);
      
      delete this.templateData.itemname 
      if(this.updatetemplate){
        console.log('有东西');
        
        
        this.service.updatecheckListsTemplate(this.id,this.updatetemplate,this.templateData).subscribe((res:any)=>{
          this.closeTep.emit()
        })

      }else{
        console.log('没东西');
        this.templateData.check_list_kind_id =  Number(this.kind_id)
        this.service.checkListsTemplate(this.id,this.templateData).subscribe((res:any)=>{
          this.closeTep.emit()
        })
      }
    }
   }

   changVerify(){
     setTimeout(() => {
       this.verify()
     }, 100);
   }

   verify(){
    console.log('這裡')
    // 判断多选是否有未填
    let arr = []
     for(let i of this.data){
      var d =  i.items.filter((n:any) => n.type === "checkbox")
      if(d.length > 0){
        for(let j of d){
          let n = j.options.includes('')
          arr.push(n)
        }
      }
     }
     
     //判断签名是否未选
     if(this.dataObjectInfo.sign_required === 'require_sign'){
      let signArr =  this.signs.filter((f:any) => f.user_id === '')
      signArr.length > 0 ? arr.push(true) : arr.push(false)
      console.log(signArr)
     }
     console.log(arr)
     this.showFinish = arr.includes(true)
     this.finish.emit(this.showFinish)
   }

   // 是否有空checkbox
   update(){
     this.verify()
   }

   addNewItem(){
    console.log(this.data)
    let arr
    let value = '' as any
    if(this.typeValue === 'text'){
      arr = null
    }else if(this.typeValue === 'radio'){
      arr = [this.i18n.t("js.check-lists.template.templateyes"),this.i18n.t("js.check-lists.template.templateno"),this.i18n.t("js.check-lists.template.nothing")]
    }else if(this.typeValue === 'checkbox'){
      arr = ['']
    }else if (this.typeValue=='date'){
      arr = null
    }else if(this.typeValue=='select'){
      arr = ['']
    } else if(this.typeValue == 'Number' || this.typeValue == 'Int' ) {
      value = 0
    } else{
      arr = null
    }
    this.data[this.index].items.push(
     {
       props:{},
       list: [{
        "type":this.typeValue,
        "title":this.i18n.t("js.check-lists.template.newentry"),
        "value":value,
        "remark":null,
        "default":null,
        "options":arr,
        "issue_id":null,
        "description":"",
        "image":null
      }]
     }
    )
    this.verify()
  }

  ngOnInit() {
    if(document.documentElement.clientWidth<770){
      jQuery(".baseoutline").css("display","none")
        jQuery(".outlineShow").css("display","block")
    }
    fromEvent(window,"resize").subscribe((event:any)=>{
      console.log(this.data,'dsss')
      if(document.documentElement.clientWidth>770){
        jQuery(".baseoutline").css("display","flex")
        jQuery(".outlineShow").css("display","none")
      }else{
        jQuery(".baseoutline").css("display","none")
        jQuery(".outlineShow").css("display","block")
      }
      
    })
    console.log('这个第一？',this.dataitem);
    if(this.dataitem){
      this.data = this.dataitem.parts
      console.log('肯定过了的')
      if(this.dataitem.signs) {
        this.signs = this.dataitem.signs
      }
    }else{
      console.log('mei dongx de ')
      this.data =[{
        items:[
          { props:{},
            list:[{
              "type":"text",
              "title":this.i18n.t("js.check-lists.template.newentry"),
              "value":"",
              "remark":null,
              "default":null,
              "options":null,
              "issue_id":null,
              "description":"",
              "image":null
            }]
          }
        ],
        "state":"pending",
        "title":this.i18n.t("js.check-lists.template.newsection"),
        "description":""
      }]
    }
    
    this.verify()
    this.projectId = document.querySelector('meta[name=current_project]')
    this.id = this.projectId.dataset.projectId
    jQuery('#content-wrapper').css({'background':'#ecf0f3','padding': 0});
    if(this.dataObjectInfo.sign_required === 'require_sign'){
      this.service.getbyUserList(this.id).subscribe((res:any)=>{
        this.byuserdata = res._embedded.elements
        console.log(this.byuserdata)
      })
    }
  }

  clickBackstep(){
    this.backStep.emit()
  }

  clickLi(index:any){
    this.index = index
    this.showList = true
  }
  clickSignDelete(i:any){
    this.signIndex = i
    this.showSignLi = !this.showSignLi
  }

  inputBlur(index:any){
    this.addClass1 = false
    if(!this.data[index].title){
      this.data[index].title = this.i18n.t("js.check-lists.template.notitlesection")
    }
  }
  
  select(event:any){
    event.target.select();
   }
  addSignOne(){
    this.signs.push({"finished_at":"","user_id":'',"doc_id":""})
    this.verify()
  }
  deleteSignOne(i:any){
    this.signs.splice(i,1)
  }


  deleteOne(e:any){
    this.data[e.indexs].items.splice(e.i,1)
  }
  deleteItems(){
    if(this.data.length <= 1) return
    this.data.splice(this.index,1)
    this.index = 0
    this.showUl = false
    this.showUl2 = false
  }
  addDataOne(){
    this.data.push({
      "state":"pending",
      "title":this.i18n.t("js.check-lists.template.newsection"),
      "description":"",
      items:[ { 
        props:{},
        list:[{
          "type":"text",
          "title":this.i18n.t("js.check-lists.template.newentry"),
          "value":"",
          "remark":null,
          "default":null,
          "options":null,
          "issue_id":null,
          "description":"",
          "image":null
        }]
      }]
      })
    
  }

  //适配手机端
  containPhone(e:any){
    let diviceWidth = document.documentElement.clientWidth;
    if(diviceWidth>310&&diviceWidth<800){
      if(e){
        jQuery(".baseoutline").css("display","block")
      }else{
        jQuery(".baseoutline").css("display","none")
      }
    }
  }

  //鼠标移入显示
  showAftername(i:any){
    let aftername:any = document.querySelectorAll(".aftername")
    aftername[i].style.display ="inline-block"
  }
  hideAftername(i:any){
    let aftername:any = document.querySelectorAll(".aftername")
    aftername[i].style.display ="none"
  }


  public ngAfterViewInit() {

  }

}
