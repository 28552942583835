import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SiteDiaryService {

  private currentId = {
    projectIdentifier: '',
    projectId:'',
    userId: ''
  }

  //请求头
  requestHeader: any = {
    'X-Authentication-Scheme': 'Session',
    'X-Requested-With': 'XMLHttpRequest',
    // 'Content-Type': 'application/json'
  };
  Header: any = {
    withCredentials: true,
    headers: this.requestHeader
  }
  //API前缀
  apiPrefix: any = '/api/v3';

  constructor(private http: HttpClient) {
    this.getCurrentId()
  }

  private HttpPost(url: string, data: any) {
    return this.http.post(url, data, this.Header);
  }
  private HttpGet(url: string) {
    return this.http.get(url);
  }
  private HttpDelete(url: string) {
    let header:any = this.Header
    header.headers['Content-Type'] = 'application/json'
    return this.http.delete(url,header);
  }
  private HttpPatch(url: string, data: any) {
    return this.http.patch(url, data, this.Header);
  }


  getOwnerList(type:any) {
    return this.HttpGet(`${this.apiPrefix}/projects/${ this.currentId.projectId}/ownership/list?ownership_type=${type}&per_page=999
    `)
  }
  getUserList(id: any, role:any) {
    return this.http.get(`${ this.apiPrefix }/projects/${ id }/available_responsibles?role=${role}`);
  }
  copyOwner(site_id:any,site_staff_ids:any) {
    return this.HttpPost(`${this.apiPrefix}/projects/${ this.currentId.projectId}/site_diary/staff_items/copy_staffs
    `,{
      site_diary_id:site_id,
      site_staff_ids:site_staff_ids
    })
  }
  getOwnerCopyList(date:any, site_id:any) {
    return this.HttpGet(`${this.apiPrefix}/projects/${ this.currentId.projectId}//site_diary/copy_staff?daily_date=${date}&per_page=999&site_diary_id=${site_id}
    `)
  }
  getTeamList(id:any) {
   return this.HttpGet(`${this.apiPrefix}/projects/${this.currentId.projectId}/site_diary/activities/team_list?site_diary_id=${id}`)
  }

  //获取当前项目和当前用户ID
  getCurrentId() {
    const currentProject: any = document.querySelector('meta[name=current_project]');
    const currentUser: any = document.querySelector('meta[name=current_user]');
    console.log(currentUser.dataset)
    this.currentId = {
      projectIdentifier: currentProject.dataset.projectIdentifier,
      projectId: currentProject.dataset.projectId,
      userId: currentUser.dataset.id
    }
    return this.currentId
  }

  //更新文件id
  updateFile(data:any){
    return  this.HttpPost(`${this.apiPrefix}/file_ref_no/update_file_ref_no`,data)
  }

  //获取文件内容
  getFileContent(){
    return this.HttpGet(`${this.apiPrefix}/file_ref_no/create_form_data?project_id=${this.currentId.projectId}&target_type=SiteDiary`)
  }
  //获取路径地址
  getFileAddress(id:any){
    return this.HttpGet(`${this.apiPrefix}/file_ref_no/view_form_data?obj_type=SiteDiary&obj_id=${id}`)
  }

  //添加SiteDiary
  siteDiaryCreate(data: any) {
    return this.HttpPost(`${this.apiPrefix}/projects/${this.currentId.projectIdentifier}/site_diary/create`, data)
  }

  //获取SiteDiary列表
  getSiteDiaryList(page:any,page_size:any, action:any) {
    return this.HttpGet(`${this.apiPrefix}/projects/${this.currentId.projectIdentifier}/site_diary/index?page=${page}&per_page=${page_size}&action=${action}`,)
  }

  copySiteDiary (projectId: any, id: Number, date: string) {
    return this.HttpPost(`${this.apiPrefix}/projects/${projectId}/site_diary/copy`, {
      project_id: projectId,
      site_diary_id: id,
      daily_date: date
    })
  }

  copyActivity(siteDiaryId:any, teamId:any, actIdArr:any) {
    console.log(siteDiaryId)
   return this.HttpPost(`/api/v3/projects/${this.currentId.projectIdentifier}/site_diary/activities/copy_activities_form`, {
      project_id: this.currentId.projectId,
      site_diary_id: siteDiaryId,
      activity_team: teamId,
      act_id_arr: actIdArr
    })
  }

  getPlantList(){
    return this.HttpGet(`${this.apiPrefix}/projects/${this.currentId.projectIdentifier}/lifting/plants?page=1&per_page=999`)
  }
  getCopyFormList(date:any, activity_team:any,site_diary_id:any) {
   return this.HttpGet( `${this.apiPrefix}/projects/${this.currentId.projectIdentifier}/site_diary/copy_list?daily_date=${date}&activity_team=${activity_team}&site_diary_id=${site_diary_id}`)
  }
  //删除SiteDiary列表
  deleteSiteDiaryList(data: any) {
    return this.HttpDelete(`${this.apiPrefix}/projects/${this.currentId.projectIdentifier}/site_diary/delete?site_diary_id=${data.site_diary_id}`)
  }

  //获取SiteDiary信息
  siteDiaryShow(data: any) {
    return this.HttpGet(`${this.apiPrefix}/projects/${this.currentId.projectIdentifier}/site_diary/show?site_diary_id=${data.site_diary_id}`)
  }
  

  //修改SiteDiary信息
  updataSiteDiaryShow(data: any) {
    return this.HttpPatch(`${this.apiPrefix}/projects/${this.currentId.projectIdentifier}/site_diary/update`,data)
  }

  //获取SiteDiary->Summary->Site Staffs选项信息
  getSummaryStaffs(data: any) {
    return this.HttpGet(`${this.apiPrefix}/projects/${this.currentId.projectIdentifier}/staffs?page=1&per_page=999&staff_type=${data.staff_type}`)
  }

  //获取SiteDiary->Summary->添加Site Staffs/Assistance
  addStaffsAndAssistance(data: any) {
    return this.HttpPost(`${this.apiPrefix}/projects/${this.currentId.projectIdentifier}/site_diary/staff_items/staffs_item_create`,data)
  }

  //获取SiteDiary->Summary->获取Site Staffs/Assistance列表
  getStaffsAndAssistanceList(data: any) {
    return this.HttpGet(`${this.apiPrefix}/projects/${this.currentId.projectIdentifier}/site_diary/staff_items/staffs_item_index?site_diary_id=${data.site_diary_id}&staff_item_type=${data.staff_item_type}&page=${data.page}&per_page=${data.per_page}`)
  }

  //获取SiteDiary->Summary->删除Site Staffs/Assistance列表
  deleteStaffsAndAssistanceList(data: any) {
    return this.HttpDelete(`${this.apiPrefix}/projects/${this.currentId.projectIdentifier}/site_diary/staff_items/staffs_item_delete?site_diary_id=${data.site_diary_id}&staff_item_id=${data.staff_item_id}`)
  }

  //获取SiteDiary->Summary->删除Site Staffs/Assistance列表
  updateStaffsAndAssistanceList(data: any) {
    return this.HttpPatch(`${this.apiPrefix}/projects/${this.currentId.projectIdentifier}/site_diary/staff_items/staffs_item_update`,data)
  }

  //获取SiteDiary->activities->Labour
  getActivitiesSections(data: any) {
    return this.HttpGet(`${this.apiPrefix}/projects/${this.currentId.projectIdentifier}/sections?page=1&per_page=999`)
  }

  //获取SiteDiary->activities->Labour
  getActivitiesProtions(data: any) {
    return this.HttpGet(`${this.apiPrefix}/projects/${this.currentId.projectIdentifier}/protions?page=1&per_page=999`)
  }

  //获取SiteDiary->添加activities
  addActivities(data: any, teamIndex:any) {
    let newData = new Object(data) as any
    newData.activity_team = teamIndex
    console.log(newData)
    return this.HttpPost(`${this.apiPrefix}/projects/${this.currentId.projectIdentifier}/site_diary/activities`,newData)
  }

  //获取SiteDiary->修改activities
  updataActivities(data: any) {
    console.log(data)
    return this.HttpPatch(`${this.apiPrefix}/projects/${this.currentId.projectIdentifier}/site_diary/activities`,data)
  }

   //获取SiteDiary->删除activities
   deleteActivities(data: any) {
    return this.HttpDelete(`${this.apiPrefix}/projects/${this.currentId.projectIdentifier}/site_diary/activities?site_diary_id=${data.site_diary_id}&activity_id=${data.activity_id}`)
  }

  //获取SiteDiary->获取activities列表
  getActivitiesList(data: any) {
    return this.HttpGet(`${this.apiPrefix}/projects/${this.currentId.projectIdentifier}/site_diary/activities?site_diary_id=${data.site_diary_id}&page=${data.page}&per_page=${data.per}&activity_team=${data.activity_team}`)
  }

   //获取SiteDiary->获取activities列表
   getNewActivitiesList(data: any) {
    return this.HttpGet(`${this.apiPrefix}/projects/${this.currentId.projectIdentifier}/site_diary/activities/list?site_diary_id=${data.site_diary_id}&page=${data.page}&per_page=${data.per}&project_id=${this.currentId.projectId}`)
  }

  //获取SiteDiary->获取activities->Labour列表
  getActivitiesLabourList(data: any) {
    return this.HttpGet(`${this.apiPrefix}/projects/${this.currentId.projectIdentifier}/site_diary/activities/labour_items?site_diary_id=${data.site_diary_id}&activity_id=${data.activity_id}&page=1&per_page=999`)
  }

  //获取SiteDiary->获取activities->plant列表
  getActivitiesPlantList(data: any) {
    return this.HttpGet(`${this.apiPrefix}/projects/${this.currentId.projectIdentifier}/site_diary/activities/plant_items?site_diary_id=${data.site_diary_id}&activity_id=${data.activity_id}&page=1&per_page=999`)
  }

  //获取SiteDiary->添加activities->Labour列表
  addActivitiesLabourItem(data: any) {
    return this.HttpPost(`${this.apiPrefix}/projects/${this.currentId.projectIdentifier}/site_diary/activities/labour_items`,data)
  }

  //获取SiteDiary->添加activities->plant
  addActivitiesPlantItem(data: any) {
    return this.HttpPost(`${this.apiPrefix}/projects/${this.currentId.projectIdentifier}/site_diary/activities/plant_items`,data)
  }

  //获取SiteDiary->添加activities->Labour列表
  updataActivitiesLabourItem(data: any) {
    return this.HttpPatch(`${this.apiPrefix}/projects/${this.currentId.projectIdentifier}/site_diary/activities/labour_items`,data)
  }
  
  //获取SiteDiary->添加activities->plant列表
  updataActivitiesPlantItem(data: any) {
    return this.HttpPatch(`${this.apiPrefix}/projects/${this.currentId.projectIdentifier}/site_diary/activities/plant_items`,data)
  }

  //获取SiteDiary->Summary->删除activities->Labour列表
  deleteActivitiesLabourItem(data: any) {
    return this.HttpDelete(`${this.apiPrefix}/projects/${this.currentId.projectIdentifier}/site_diary/activities/labour_items?activity_id=${data.activity_id}&site_diary_id=${data.site_diary_id}&labour_item_id=${data.labour_item_id}`)
  }

  //获取SiteDiary->Summary->删除activities->plant列表
  deleteActivitiesPlantItem(data: any) {
    return this.HttpDelete(`${this.apiPrefix}/projects/${this.currentId.projectIdentifier}/site_diary/activities/plant_items?activity_id=${data.activity_id}&site_diary_id=${data.site_diary_id}&plant_item_id=${data.plant_item_id}`)
  }

  //获取SiteDiary->plant列表
  getSiteDiaryPlantList(data: any) {
    return this.HttpGet(`${this.apiPrefix}/projects/${this.currentId.projectIdentifier}/site_diary/plant_item_list?site_diary_id=${data.site_diary_id}&page=${data.page}&per_page=${data.per}`)
  }
  //获取SiteDiary->plant列表
  getSiteDiaryLabourList(data: any) {
    return this.HttpGet(`${this.apiPrefix}/projects/${this.currentId.projectIdentifier}/site_diary/labour_item_list?site_diary_id=${data.site_diary_id}&page=${data.page}&per_page=${data.per}`)
  }

  pushSign(data:any){
    return this.HttpPost(`${this.apiPrefix}/projects/${this.currentId.projectId}/sign`,data)
  }

  toExamineActivity(data:any){
    return this.HttpPost(`${this.apiPrefix}/projects/${this.currentId.projectIdentifier}/site_diary/activity_verify/site_diary_adopt`,data)
  }
  //获取SiteDiary->plant列表
  getLiftingLabours() {
    return this.HttpGet(`${this.apiPrefix}/projects/${this.currentId.projectIdentifier}/staffs?staff_type=site_labours&page=1&per_page=999`)
  }
}

export interface SiteDiaryItem {
  accidents: any
  afternoon_weather: any
  assistance: any
  comments: any
  created_at: any
  daily_date: any
  id: any
  instructions: any
  labour_items: any
  morning_weather: any
  plant_items: any
  progress: any
  project_name: any
  rain_storm_warning: any
  rainfall: any
  remarks: any
  site_staff: any
  state: any
  thrnder_storm_waming: any
  tropicai_cyclone_warning: any
  updated_at: any
  user_name: any
  utilities: any
  visitor: any
  weather_warning: any
  week: any
  project_effective_days:any
}

export interface ActivityData {
  site_diary_id: any,
  location: any,
  activity: any,
  period_start: any,
  period_end: any,
  section_id: any,
  protion_id: any,
  material: any,
  remark: any,
  labours:any,
  plant:any,
  labur_number:any,
  plant_number:any,  
  plant_ldle:any,
  plant_working:any
}