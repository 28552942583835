<div class="loding"></div>

<div class="content">
  <signature-pad style="border: 1px solid rgba(0,0,0,0.5);" id="signatureCanvas" [options]="signaturePadOptions"></signature-pad>

  <!-- <button (click)='drawComplete()'>ann </button> -->
    <div class="confirm-cancel" >
    <div class="confirm-cancel-item" style="background-color: #fed86f;" (click)='clone()'>
      <span>
        Cancel
        <!-- <i class="icon-checkmark"></i> -->
      </span>
    </div>
    <div class="confirm-cancel-item" 
    [ngClass]="canSubmit?'canSubmit': 'disSubmit'"
    (click)="drawComplete()">
      <span >
        Sign & Submit
        <!-- <i class="icon-close"></i> -->
      </span>
    </div>
  </div>
</div>


