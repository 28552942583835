import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import {SiteDiaryItem, SiteDiaryService} from '../../services/SiteDiary.service';

declare var laydate: any;

@Component({
  selector: 'SiteDiaty-Activity-New',
  templateUrl: './SiteDiaty-Activity-New.component.html',
  styleUrls: ['./SiteDiaty-Activity-New.component.sass']
  
})
export class SiteDiatyActivityNewComponent implements OnChanges {

  @Input() public divClick:any
  @Input() public summaryData: SiteDiaryItem
  public addActivty:any

  @Output() tableEdit = new EventEmitter();

  public tableList1 = []
  public tableList2 = []
  public tableList3 = []
  public tableList4 = []
  public modulesize:any = []   //module页码
  public modulepage=1

  public per = 999
  public oneShow:any
  public twoShow:any
  public threeShow:any
  public fourShow:any
  public teamList:any
  public updataActivty:any = null
  public editIndex:any
  public copyIndex:any
  public copyShow:any = false
  public copyList = []
  public copyCheckAll:any = false

  constructor(
    public service: SiteDiaryService,
  ) { }

  ngOnInit() {
    // this.getList()
    this.service.getTeamList(this.summaryData.id).subscribe( (res) => {
      console.log(res)
      this.teamList = res
    })
    console.log(this.summaryData.daily_date)
    console.log('divClick',this.divClick)
  }
  checkAll() {
    this.copyList.forEach( (item:any) => {
        item.copy = !this.copyCheckAll
    })
  }
  copySubmit() {
    let copyarr = this.copyList.filter( (item:any) => {
      return item.copy
    }).map( (item:any) => {
      return item.id
    })
    console.log(copyarr)

    this.service.copyActivity(this.summaryData.id,this.copyIndex, copyarr).subscribe( (res:any) => {
        console.log(res)
        this.getList(this.copyIndex)
        this.closeCopy()
    })
  }
  addActivtyClick(index:any){
    if(index) {
      this.editIndex = index
    } else {
      this.getList(this.editIndex)
    }
    this.updataActivty = null
    this.addActivty = !this.addActivty
    jQuery('#top-menu').css('z-index','unset')

  }
  dropDown(index:any) {
    switch (index) {
      case 1: {
        this.oneShow = !this.oneShow 
        this.getList(index)
      };break;
      case 2: {
        this.twoShow = !this.twoShow
        this.getList(index)
      }break;
      case 3: {
        this.threeShow = !this.threeShow 
        this.getList(index)
      }break;
      case 4: {
        this.fourShow = !this.fourShow
        this.getList(index)
      }break;
    }
  }
  //监听父组件传递过来的值
  ngOnChanges(changes:{[propKey:string]: SimpleChange }){
    console.log(changes)
    // if(changes['addActivty']){
    //   if(!changes['addActivty']['currentValue'])this.getList()
    // }
    // if(changes['divClick']){
    //   this.getList()
    // }
  }

  //获取labour列表数据
  getList(index:any) {
    let data = {site_diary_id:this.summaryData.id ,page:this.modulepage ,per:this.per,activity_team: index}

      this.service.getActivitiesList(data).subscribe((res:any)=>{
        console.log(res)
        let gettableList = ('tableList' + index) as any
        let that = this as any
        that[gettableList]= res.activities
        this.service.getTeamList(this.summaryData.id).subscribe( (res) => {
          console.log(res)
          this.teamList = res
        })
        // this.setCountList(res.activities.length,res.count)
      })
    
  }

  //分页处理
  setCountList(length:any,count:any){
    console.log(Math.ceil(count/this.per))
    this.modulesize = []
    for(let i =0;i<Math.ceil(count/this.per);i++){
      this.modulesize.push("i")
      console.log(this.modulesize)
    }
  }

  modulego(leftright:any){
    if(leftright == 'prev'){
      this.modulepage = this.modulepage>this.modulesize.length ? this.modulepage : this.modulepage-1
    }
  }
  closeCopy() {
    this.copyCheckAll = false
    this.copyShow = false
  }
  copyClick(index:any) {
    this.copyIndex = index
    this.service.getCopyFormList(this.summaryData.daily_date, index,this.summaryData.id).subscribe( (res:any) => {

      this.copyList = res.activities
    })
    
    this.copyShow  = true
      setTimeout(() => {
        laydate.render({ 
          elem: '#activetyCopyDate'
          ,type: 'date',
          value: this.summaryData.daily_date,
          done: (value:any) => {
            this.service.getCopyFormList(value, index,this.summaryData.id).subscribe( (res:any) => {
              this.copyCheckAll = false
              this.copyList = res.activities
            })
          }
        });
      }, 50)
  }
  //修改按钮
  editClick(item:any, index:any){
    this.editIndex = index
    jQuery('#top-menu').css('z-index','unset')
    this.updataActivty = item
    this.addActivty = !this.addActivty
  }

  deleteClick(item:any, index:any){
    console.log(item)
    this.service.deleteActivities({site_diary_id:this.summaryData.id,activity_id:item.id}).subscribe((res:any)=>{
      this.getList(index)
    })
    // this.tableEdit.emit(item)
  }

}

