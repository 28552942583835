<div style="margin-left: 10px;">

  <div class="title">
    {{ i18n.t("js.cunstom-forms.table.title") }}
  </div>

  <div class="constent">
    <table>
      <tr style="text-align: left;">
        <th>{{ i18n.t("js.cunstom-forms.table.th.thcheckbox") }}</th>
        <th>{{ i18n.t("js.cunstom-forms.table.th.thname") }}</th>
      </tr>
      <tr *ngFor="let item of checkoutdata">
        <td>
          <input type="checkbox" [(ngModel)]="item.is_custom_form" data-md-icheck (change)="toggleVisibility(item)" />
        </td>
        <td>{{item.name}}</td>
      </tr>
    </table>
  </div>
  <button class="-highlight -with-icon icon-checkmark button"  (click)="pushclick()"> {{ i18n.t("js.cunstom-forms.savebutton") }} </button>


</div>

<cunstom-forms-loading *ngIf="displeloading"></cunstom-forms-loading>
