
import { Component, OnInit, ViewChild, EventEmitter, Output ,Input} from '@angular/core';
import { Photo_logService } from '../../services/photo_log.service';
import { I18nService } from 'core-app/modules/common/i18n/i18n.service';
import { input } from 'reactivestates';


@Component({
    selector: 'photo_log-detail',
    templateUrl: './photo_log-detail.component.html',
    styleUrls: ['./photo_log-detail.component.sass']
  })
  export class Photo_logDetailComponent implements OnInit {

  @Output() hideDetailpage = new EventEmitter();
  @Output() showEditpage = new EventEmitter();


    @Input() public saveitemId:any
    @Input() public hideEditbtn:any


    public imgdetailData:any
    public image_url:any
    public showEdit:boolean=false
    public Object = Object
    constructor(
      public service: Photo_logService,
      public i18n: I18nService,
    ) { }
  
    ngOnInit() {
      // 获取详情页的数据
      this.service.getDetailpage(this.saveitemId).subscribe((res:any)=>{
        this.imgdetailData = res
        this.image_url = this.imgdetailData.image_url
      })

    }

    //隐藏详情页
    hidedetail(){
      this.hideDetailpage.emit()
    }
    
    //显示编辑页
    toshowEdit(){
      sessionStorage.setItem('multi','single')
      this.hideDetailpage.emit()
      this.showEditpage.emit( this.saveitemId)
    }

}