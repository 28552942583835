import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { any } from '@uirouter/core';
import { SiteDiaryItem, SiteDiaryService ,ActivityData} from '../../../services/SiteDiary.service';


@Component({
  selector: 'SiteDiary-Details-addActivity-Pupop',
  templateUrl: './SiteDiary-Details-addActivity-Pupop.component.html',
  styleUrls: ['./SiteDiary-Details-addActivity-Pupop.component.sass']
})
export class SiteDiaryDetailsAddActivityPupopComponent implements OnInit {
  @Input() public pupopType: any
  @Input() public summaryData: SiteDiaryItem
  @Input() public activityData: ActivityData

  @Output() close = new EventEmitter();

  public updataActivity:any={}
  public serviceData:any = {}

  public inputdilog = false
  public title: any = ''
  public labourData:any = {labour_name:'',number:0,ownership:'',labour_code:''}
  public PlantData:any = {equipment: "", plant_mark: "", working: 0, ldle: 0, ldle_code: "", ownership: ""}

  public tableList:any = []
  public addStaffsData: any = {}
  public updataData:any = null

  public returnData:any = {}

  public labour_List:any=[]

  public labourName_List:any = []
  public plantName_List:any = []
  public plant_List:any = []
  public ownershipList= []
  // public noInput:boolean =false
  public plantDialog:boolean = false
  public codedialog:boolean = false
  public idDialog:boolean =false
  public ownershipDialog:boolean = false

  public isownershipDCKJV = false
  public ownerList:any = []
  public EmptyTip:any = {
    NumberTip:false,
    OwnershipTip:false,
    IDTip: false,
    WorkingTip: false,
    IdleTip: false,
    Ownership1Tip: false


  }

  constructor(
    public service: SiteDiaryService,
    protected cdRef:ChangeDetectorRef,
  ) { }

  ngOnInit() {
    console.log(this.pupopType)
    this.updataActivity = this.activityData
    this.title = this.pupopType === 'labour_items' ? 'Labour / People' : 'Plant / Equipment'
    this.addStaffsData = this.pupopType === 'labour_items' ? this.labourData : this.PlantData
    let ownerType = this.pupopType == 'labour_items'? 'SiteLabour': 'SitePlant'
    this.service.getOwnerList(ownerType).subscribe( (res:any) => {
      console.log(res)
      this.ownershipList = res.data
      this.ownerList = res.data
      this.ownershipList.forEach( (item:any) => {
        if(item.ownership_name == 'DCKJV') {
          this.isownershipDCKJV = true
          this.addStaffsData.ownership = 'DCKJV'
        }
      })
    })
    if(this.updataActivity.id){
      this.serviceData = {
        activity_id:this.updataActivity.id,
        site_diary_id:this.summaryData.id,
      }
      this.getItems()
    }else{
      this.tableList = this.activityData[this.pupopType === 'labour_items' ? 'labours' : 'plant']
    }
    this.service.getLiftingLabours().subscribe((res:any)=>{
      this.labour_List =  res.sort( (pre:any,next:any) => {
        if( pre.name > next.name ) {
          return 1
        } else {
          return -1
        }
       })
    })

    this.service.getPlantList().subscribe((res:any)=>{
      this.plant_List =  res.list.sort( (pre:any,next:any) => {
        if( pre.sub_type > next.sub_type ) {
          return 1
        } else {
          return -1
        }
       })
    })  
  }

  ngAfterViewInit(){
    if(this.summaryData.state == 'draft'||this.summaryData.state == 'rejected') return
    jQuery('#addActivity_Pupop')[0].childNodes.forEach((res:any)=>{
      if(res.className == 'key-value'){
        if(res.children[1].className == 'key-input'){
          res.children[1].children[0].disabled = true
        }else{
          res.children[1].disabled = true
        }
      }
    })
  }
  numberChange(event: any){
    var reg1 = /^[+-]?(0|([1-9]\d*))(\.\d*)?$/;
    if(!event) return
    if (!reg1.test(event)) {
      let dada:any = jQuery('#numberInput')[0]
      dada.value = 0
      this.addStaffsData.number = 0
      this.cdRef.detectChanges();
    }
  }
  workingChange(event: any){
    var reg1 = /^[+-]?(0|([1-9]\d*))(\.\d*)?$/;
    if(!event) return
    if (!reg1.test(event)) {
      let dada:any = jQuery('#workingInput')[0]
      dada.value = 0
      this.addStaffsData.working = 0
      this.cdRef.detectChanges();
    }
  }
  idleChange(event: any){
    var reg1 = /^[+-]?(0|([1-9]\d*))(\.\d*)?$/;
    if(!event) return
    if (!reg1.test(event)) {
      let dada:any = jQuery('#idleInput')[0]
      dada.value = 0
      this.addStaffsData.idle = 0
      this.cdRef.detectChanges();
    }
  }

  //获取input焦点
  inputfocus(type:any){
    if(type=='input'){ 
      this.codedialog = false     
      this.labourName_List = this.labour_List
      this.inputdilog = !this.inputdilog
    }else if(type=='code'){
      this.inputdilog = false
      this.labourName_List = this.labour_List
      this.codedialog = !this.codedialog
    }else if(type=='id'){
      this.plantDialog = false
      this.plantName_List = this.plant_List
      this.idDialog = !this.idDialog
    } else if(type == 'ownership') {
      this.plantDialog = false
      this.codedialog = false  
      this.ownershipDialog = !this.ownershipDialog
    }
    else{
      this.idDialog = false
      this.plantName_List = this.plant_List
      this.plantDialog = !this.plantDialog
    }
  }

  filterLabourNameInput() {
    return
  }
  //获取input失去焦点
  // inputblur(){
  //   setTimeout(()=>{
  //     this.inputdilog = !this.inputdilog
  //   },100)
  // }
  //根据输入的code自动填充人员
  // containLabour(e:any){
  //   let list = this.labour_List.filter((res:any)=>{
  //     let saveData 
  //     if(res.code){
  //       saveData = res.code.indexOf(e) != -1
  //     }
  //     return saveData
  //   })
  //   this.addStaffsData.labour_name = list[0].name
  // }
  inputChange(event:any,type:any,e:any){


  console.log(event,type)
    if(type=='input'){
      if(!event) {
        this.labourName_List = this.labour_List
        return 
      }
      let list = this.labour_List.filter((res:any)=>{return res.name.toLowerCase().indexOf(event) == 0})
      this.labourName_List = list.length == 0 ? event ? list : this.labour_List : list
    }else if(type=='code'){
      if(!event) {
        this.labourName_List = this.labour_List
        return 
      }
      let list = this.labour_List.filter((res:any)=>{
        if(!res.code) return
        return res.code.toLowerCase().indexOf(event) == 0})
      this.labourName_List = list.length == 0 ? event ? list : this.labour_List : list
    }else if(type=='plant'){
      if(!event) {
        this.plantName_List = this.plant_List
        return 
      }
      let list = this.plant_List.filter((res:any)=>{return res.sub_type.toLowerCase().indexOf(event) == 0})
      this.plantName_List = list.length == 0 ? event ? list : this.plant_List : list
    } else if(type=='ownership') {
      if(!event) {
        this.ownershipList = this.ownerList
        return
      }
      let list = this.ownerList.filter((res:any)=>{return res.ownership_name.toLowerCase().indexOf(event) == 0})
      this.ownershipList = list.length == 0 ? event ? list : this.ownerList : list
    }
    else{
      if(!event) {
        this.plantName_List = this.plant_List
        return 
      }
      let list = this.plant_List.filter((res:any)=>{
        return String(res.id).toLowerCase().indexOf(event) == 0})
      this.plantName_List = list.length == 0 ? event ? list : this.plant_List : list
    }
  }
  labourNameListClick(item:any,type:any){
    this.addStaffsData.labour_name = item.name
    this.addStaffsData.labour_code = item.code
    if(type=='labour'){
      this.inputdilog = !this.inputdilog
    }else{
      this.codedialog = !this.codedialog
    }
    // this.noInput = true
  }
  plantListClick(item:any,type:any){
    
    if(type=='plant'){
    this.plantDialog = !this.plantDialog
    this.addStaffsData.equipment = item.sub_type
    } else if(type == 'ownership'){
      this.addStaffsData[type] = item.ownership_name
      this.ownershipDialog = false
    }else{
    this.idDialog = !this.idDialog
    this.addStaffsData.plant_mark = item.id
    }
    // this.noInput = true
  }
  //获取labour列表数据
  getItems() {
    if(this.pupopType === 'labour_items'){
      this.service.getActivitiesLabourList(this.serviceData).subscribe((res:any)=>{
        this.tableList = res.labour_items
      })
    }else{
      this.service.getActivitiesPlantList(this.serviceData).subscribe((res:any)=>{
        this.tableList = res.plant_items
      })
    }
  }
  //添加列表数据
  add() {
    for(let key in this.EmptyTip){
      this.EmptyTip[key] = false
    }
    if(this.pupopType === 'labour_items'){
      let returnBoolean = false
      if(!this.addStaffsData.ownership){
        this.EmptyTip.OwnershipTip = true
        // alert("ownership cannot be empty")
        returnBoolean = true
      }     
       if(this.addStaffsData.number<0){
         this.EmptyTip.NumberTip = true
        // alert("number cannot be a negative number")
        returnBoolean = true
      }
      if(returnBoolean) {
        return
      }
      if(this.updataActivity.id){
        Object.assign(this.serviceData,this.addStaffsData)
        this.service.addActivitiesLabourItem(this.serviceData).subscribe((res:any)=>{
          this.getItems()
        })
      }else{
        this.tableList.push(this.addStaffsData)
      }
      this.addStaffsData = {labour_name:'',number:0,ownership:this.isownershipDCKJV?'DCKJV':'',labour_code:''}
    }else{
      let returnBoolean = false
      if(!this.addStaffsData.plant_mark){
        // alert("ID cannot be empty")
        this.EmptyTip.IDTip = true
        returnBoolean = true
      }
      if(!this.addStaffsData.ownership){
        this.EmptyTip.Ownership1Tip = true
        // alert("ownership cannot be empty")
        returnBoolean = true
      }
      if(Number(this.addStaffsData.ldle)<0){
        this.EmptyTip.IdleTip = true
        returnBoolean = true
      }
      if(Number(this.addStaffsData.working)<0){
        this.EmptyTip.WorkingTip = true
        // alert("ldle or working cannot be a negative number")
        returnBoolean = true
      }
      if(returnBoolean) {
        return
      }
      if(this.updataActivity.id){
        Object.assign(this.serviceData,this.addStaffsData)
        this.service.addActivitiesPlantItem(this.serviceData).subscribe((res:any)=>{
          this.getItems()
        })
      }else{
        this.tableList.push(this.addStaffsData)
      }
      this.addStaffsData = {equipment: "", plant_mark: "", working: 0, ldle: 0, ldle_code: "", ownership:this.isownershipDCKJV?'DCKJV':'' }
    }
    // this.noInput = false
  }

  //删除列表数据
  deleteClick(item: any,index:any) {
    if(this.updataData) return
    if(!this.updataActivity.id) this.tableList.splice(index,1)
    if(this.pupopType === 'labour_items'){
      this.serviceData['labour_item_id'] = item.id
      this.service.deleteActivitiesLabourItem(this.serviceData).subscribe((res:any)=>{
        this.getItems()
      })
    }else{
      this.serviceData['plant_item_id'] = item.id
      this.service.deleteActivitiesPlantItem(this.serviceData).subscribe((res:any)=>{
        this.getItems()
      })
    }
    
  }

  //修改列表按钮
  editClick(item: any) {
    if(this.updataData) return
    this.updataData = item
    this.addStaffsData = item
  }

  //修改确定按钮
  updataClick() {
    if(this.updataActivity.id){
      if(this.pupopType === 'labour_items'){
        if(!this.addStaffsData.ownership){
          alert("ownership cannot be empty")
          return
        }
        this.serviceData['labour_item_id'] = this.updataData.id
        Object.assign(this.serviceData,this.addStaffsData)
        this.service.updataActivitiesLabourItem(this.serviceData).subscribe((res:any)=>{
          this.getItems()
          this.updataData = null
          this.addStaffsData = {labour_name:'',number:0,ownership:this.isownershipDCKJV?'DCKJV':'',labour_code:''}
        })
      }else{
        if(!this.addStaffsData.plant_mark){
          alert("ID cannot be empty")
          return
        }else if(!this.addStaffsData.ownership){
          alert("ownership cannot be empty")
          return
        }
        this.serviceData['plant_item_id'] = this.updataData.id
        Object.assign(this.serviceData,this.addStaffsData)
        this.service.updataActivitiesPlantItem(this.serviceData).subscribe((res:any)=>{
          this.getItems()
          this.updataData = null
          this.addStaffsData = {equipment: "", plant_mark: "", working: 0, ldle: 0, ldle_code: "", ownership:this.isownershipDCKJV?'DCKJV':''}
        })
      }
    }else{
      this.updataData = null
      this.addStaffsData = this.pupopType === 'labour_items' ? {labour_name:'',number:0,ownership:this.isownershipDCKJV?'DCKJV':'',labour_code:''} : {equipment: "", plant_mark: "", working: 0, ldle: 0, ldle_code: "", ownership:this.isownershipDCKJV?'DCKJV':''}
    }
  }




  //ok按钮
  okclick() {
    // this.noInput = false
    if(this.pupopType === 'labour_items'){
      this.activityData.labours = this.tableList
      let numberData = 0
      this.tableList.forEach((res:any,index:any) => {
        numberData+=res.number
        if(this.tableList.length-1 == index){
          this.activityData.labur_number = numberData
        }
      });
    }else{
      this.activityData.plant = this.tableList
      let numberData = 0
      let plant_ldle = 0
      let plant_working = 0
      this.tableList.forEach((res:any,index:any) => {
        numberData+=res.working + res.ldle
        plant_ldle+=res.ldle
        plant_working+=res.working
        if(this.tableList.length-1 == index){
          this.activityData.plant_number = numberData
          this.activityData.plant_ldle = plant_ldle
          this.activityData.plant_working = plant_working
        }
      });
    }
    this.close.emit()
  }




}
