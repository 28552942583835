<div class="border">
  <div class="before">

    <div class="title">
      Before
    </div>
    <div class="picList">
      <ng-container *ngFor="let item of before_photos let i = index">

        <SEDNIMG class="picItem" [isWriteIn]="isWriteIn" [groupId]="GroupId" [inputImgId]="item.id" [sednId]="sednId" type="before"  [index]="i" [InputImg]="item.doc_id?item.doc_id: false" [state]="state"  [Inputdate]="item.updated_at" [replied_right]="replied_right" [prepared_right]="prepared_right" (ImgEventEmit)="ImgbeforeGroupEvnet($event, i)" ></SEDNIMG>
      </ng-container>
    </div>
    <textarea class="description" [(ngModel)]="description" (change)="changeGroup('description')" [disabled]="isWriteIn?isWriteIn:(!prepared_right || !prepared_right)?'disabled': false"></textarea>
  </div>
  <div class="after" *ngIf="state !== 'New'">
    <div class="title">
      After
    </div>
    <div class="picList">
      <SEDNIMG  class="picItem" [isWriteIn]="isWriteIn" [groupId]="GroupId" [inputImgId]="item.id" type="after" [sednId]="sednId" *ngFor="let item of after_photos let i = index" [index]="i" [InputImg]="item.doc_id?item.doc_id: false" [state]="state" [Inputdate]="item.updated_at" [replied_right]="replied_right" [prepared_right]="prepared_right" [beforePhotoGroup]="before_photos" (ImgEventEmit)="ImgafterGroupEvnet($event, i)"></SEDNIMG>
    </div>
    <textarea class="description" [(ngModel)]="remark" (change)="changeGroup('remark')" [disabled]="isWriteIn?isWriteIn:(state!=='Sent' || !replied_right)?'disabled': false"></textarea>
  </div>
</div>
