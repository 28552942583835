<div class="work-packages-split-view--tabletimeline-content" style="padding:20px;height: 100%;">
    <div class="work-packages-tabletimeline--table-side work-package-table--container __hidden_overflow_container">
      <div class="filter">
        <!--<a (click)="getList('my_action')">My Actions</a>
        <span>|</span>-->
        <a [ngClass]="{'filterSelect': action == 'draft'}" (click)="getList('draft')">Draft</a>
        <span>|</span>
        <a [ngClass]="{'filterSelect': action == 'in_progress'}" (click)="getList('in_progress')">Contractor_signed</a>
        <span>|</span>
        <a [ngClass]="{'filterSelect': action == 'complete'}" (click)="getList('complete')">Supervisor_signed</a>
        <span>|</span>
        <a [ngClass]="{'filterSelect': action == 'recheck'}" (click)="getList('recheck')">Recheck</a>
        <span>|</span>
        <a [ngClass]="{'filterSelect': action == 'all'}" (click)="getList('all')">All</a>
      </div>
      <table class="keyboard-accessible-list generic-table work-package-table ">
          <thead class="-sticky">
            <tr style="border-bottom: 1px solid #E7E7E7;">
              <th class="headercenter">{{ i18n.t("js.labour-return.theme") }}</th>
              <th class="headercenter">{{ i18n.t("js.labour-return.status") }}</th>
              <th>Created by</th>
              <th>Created at</th>
              <th>Handler</th>
              <th class="headercenter">{{ i18n.t("js.labour-return.operation") }}</th>
            </tr>
          </thead>
          <tbody class="results-tbody work-package--results-tbody" *ngFor="let item of tableList;let i =index">
            <tr tabindex="0" data-work-package-id="303" data-class-identifier="wp-row-303" class="wp-table--row wp--row wp-row-303 wp-row-303-table issue __hierarchy-root-303">
                <td class="headercenter">{{item.month}}</td>
                <td class="headercenter">{{changeStatusName(item.state)}} </td>
                <td>{{item.created_by?item.created_by.firstname + ' ' + item.created_by.lastname: ''}}</td>
                <td>{{item.created_at}}</td>
                <td>{{item.contractor_signer.name + ',' + item.supervisor_signer.name}}</td>
              <td class="headercenter"><span class="viewspan" [ngClass]="{completedBtn: (item.state=='completed'||item.state=='pending_review')?true:false}" (click)="toEdit(item)">{{ i18n.t("js.labour-return.edit") }}</span><span class="viewspan" (click)="toApproval(item)">{{ i18n.t("js.labour-return.approval") }}</span><span>
                <a [href]="getHref(item)" class="viewspan">PDF</a></span>
                <span class="viewspan" (click)="toDelete(item.id)">{{ i18n.t("js.labour-return.delete") }}</span>
              </td>
            </tr>
          </tbody>
        </table>


        <div class="paging">
          <ul class="pagingUl">
            <li (click)="changePage(saveIndex-1)"><</li>
            <li *ngFor="let item of saveNum;let i=index" [ngClass]="{bluebtn:i==saveIndex?true:false}" (click)="changePage(i)">{{i+1}}</li>
            <li (click)="changePage(saveIndex+1)">></li>
            <span>{{ i18n.t("js.labour-return.total") }} {{count}} {{ i18n.t("js.labour-return.page") }}</span>
          </ul>
        </div>
  </div>
  </div>
  <div class="attributes-key-value__loading" *ngIf="getListLoding">
    <div class="loading-indicator -compact">
    <div class="block-1"></div>
    <div class="block-2"></div>
    <div class="block-3"></div>
    <div class="block-4"></div>
    <div class="block-5"></div>
    </div>
  </div>
