<div class="documents-item-container">

    <!--- <iframe name="iframeLogidoc"
        src="/assets/iframe_logidoc/index.html">
    </iframe> -->

    <div class="back" *ngIf="projectId !== currentFolderId" (click)="back()">
        <div class="documents-arrow  arrow-icon"></div>
        <span class="back-text">
            <!-- 返回上一页 -->
            {{ i18n.t("js.documents.back") }}
        </span>
    </div>
    <div class="documents-item">
        <div class="operation">
            <div class="project-name">
                <div class="project-name-container" *ngFor="let item of path; let i=index">
                    <div *ngIf="i" class="documents-navigation-arrow  navigation-arrow-icon"></div>
                    <span class="project-name-title"  (click)="handlePath(item.id)">
                        <!-- 标题 -->
                        {{ item.name }}
                    </span>
                </div>
            </div>
            <div class="operation-right">
                <div class="operation-right-create" (click)="handleInputName({ id: currentFolderId }, 'createFolder')">
                    <div class="operation-container">
                        <div class="documents-create operation-icon"></div>
                        <span class="operation-create-text">
                            <!-- 新建文件夹 -->
                            {{ i18n.t("js.documents.new_folder") }}
                        </span>
                    </div>
                </div>
                <div class="operation-right-upload" (click)="uploadFile(currentFolderId)">
                    <div class="operation-container">
                        <div class="documents-upload operation-icon upload-margin"></div>
                        <span class="operation-upload-text">
                            <!-- 上传 -->
                            {{ i18n.t("js.documents.upload") }}
                        </span>
                        <div (click)="service.stopPropagation($event)" id="upload-file">
                            <input #uploadFileRef type="file" (change)="selectFile($event)">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="operation-m-display">
            <div *ngIf="projectId === currentFolderId" class="operation-m">
                <div class="operation-m-title" *ngFor="let item of path" (click)="handlePath(item.id)">
                     <!-- 标题 -->
                    {{ item.name }}
                </div>
                <div class="more-horizontal-m-icon-padding" (click)="handleOperationModalShow()">
                    <div class="documents-more-horizontal-m more-horizontal-m-icon">
                        <div class="operation--modal" *ngIf="operationModalShow">
                            <documents-operation-modal
                                (createFolder)="handleInputName({ id: currentFolderId }, 'createFolder')"
                                (uploadFile)="uploadFile(currentFolderId)">
                            </documents-operation-modal>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="projectId !== currentFolderId" class="current-folder">
                <div class="current-folder-item" *ngFor="let item of path; let i=index; let last=last">
                    <div *ngIf="i" class="documents-arrow-m arrow-m-icon"></div>
                    <span (click)="handlePath(item.id)"
                        [class.current-folder-color]="last">
                            {{ item.name }}
                    </span>
                </div>
            </div>
        </div>
        <div class="scroll">
            <div class="max-content">
                <div class="title">
                    <div class="title-item title-name">
                        <!-- 名称 -->
                        {{ i18n.t("js.documents.file_name") }}
                    </div>
                    <div class="title-item menu-display">
                    </div>
                    <div class="title-item title-size">
                        <!-- 大小 -->
                        {{ i18n.t("js.documents.size") }}
                    </div>
                    <div class="title-item title-author">
                        <!-- 创建者 -->
                        {{ i18n.t("js.documents.creator") }}
                    </div>
                    <div class="title-item title-time">
                        <!-- 更新时间 -->
                        {{ i18n.t("js.documents.update_time") }}
                    </div>
                </div>
                <!-- 文件列表 -->
                <div *ngFor="let item of data">
                    <!-- pc -->
                    <div class="content-display">
                        <div class="content">
                            <div class="content-item title-name content-name">
                                <div [class]="fileIcon(item.type)"></div>
                                <span
                                    (click)="enterFolder(item)"
                                    class="content-name-size">
                                        {{ service.isFolder(item.type) ? item.name : item.file_name }}
                                </span>
                            </div>

                            <div class=" title-menu menu-display">
                                <span class="title-menu-item" (click)="downLoad(item.id)" *ngIf="!service.isFolder(item.type)">
                                    <!-- 下载 -->
                                    {{ i18n.t("js.documents.download") }}
                                </span>
                                <span class="title-menu-item" (click)="uploadFile(item.id)" *ngIf="service.isFolder(item.type)">
                                    <!-- 上传 -->
                                    {{ i18n.t("js.documents.upload") }}
                                </span>
                                <span class="title-menu-item" (click)="listItemMove(item, 'move')">
                                    <!-- 移动 -->
                                    {{ i18n.t("js.documents.move") }}
                                </span>
                                <span class="title-menu-item" (click)="listItemMove(item, 'copy')" *ngIf="service.isFolder(item.type)">
                                    <!-- 复制 -->
                                    {{ i18n.t("js.documents.copy") }}
                                </span>
                                <span class="title-menu-item" (click)="handleInputName(item, 'rename')">
                                    <!-- 重命名 -->
                                    {{ i18n.t("js.documents.rename") }}
                                </span>
                                <span class="title-menu-item" (click)="handlePreview(item.id)" *ngIf="service.isPreview(item.type)">
                                    <!-- 预览 -->
                                    {{ i18n.t("js.documents.preview") }}
                                </span>
                                <span class="title-menu-item" (click)="shareLoding(item.id,item.file_name)" *ngIf="!service.isFolder(item.type)">
                                    <!-- 分享 -->
                                    {{ i18n.t("js.documents.share") }}
                                </span>
                                <span class="title-menu-item" (click)="editLoding(item.id,item.file_name)" *ngIf="item.type == 'pdf'">
                                    <!-- 编辑 -->
                                    {{ i18n.t("js.documents.edit") }}
                                </span>
                                <!-- 暂时隐藏三个点 -->
                                <!-- <span class="title-menu-item">
                                    <span class="title-menu-item-point"></span>
                                    <span class="title-menu-item-point"></span>
                                    <span class="title-menu-item-point"></span>
                                </span> -->
                            </div>

                            <div class="content-item title-size">
                                {{ service.isFolder(item.type) ? '__' : service.formatFileSize(item.file_size) }}
                            </div>
                            <div class="content-item title-author content-author">
                                <!-- 弃用头像 -->
                                <!-- <div class="text-pic">Y</div> -->
                                <span class="content-author-size">
                                    {{ item.creator }}
                                </span>
                            </div>
                            <div class="content-item title-time">
                                {{ service.formatDate(item.last_modified) }}
                            </div>
                            <div class="content-item title-menu">
                                <span class="title-menu-item" (click)="downLoad(item.id)" *ngIf="!service.isFolder(item.type)">
                                    <!-- 下载 -->
                                    {{ i18n.t("js.documents.download") }}
                                </span>
                                <span class="title-menu-item" (click)="uploadFile(item.id)" *ngIf="service.isFolder(item.type)">
                                    <!-- 上传 -->
                                    {{ i18n.t("js.documents.upload") }}
                                </span>
                                <span class="title-menu-item" (click)="listItemMove(item, 'move')">
                                    <!-- 移动 -->
                                    {{ i18n.t("js.documents.move") }}
                                </span>
                                <span class="title-menu-item" (click)="listItemMove(item, 'copy')" *ngIf="service.isFolder(item.type)">
                                    <!-- 复制 -->
                                    {{ i18n.t("js.documents.copy") }}
                                </span>
                                <span class="title-menu-item" (click)="handleInputName(item, 'rename')">
                                    <!-- 重命名 -->
                                    {{ i18n.t("js.documents.rename") }}
                                </span>
                                <span class="title-menu-item" (click)="handlePreview(item.id)" *ngIf="service.isPreview(item.type)">
                                    <!-- 预览 -->
                                    {{ i18n.t("js.documents.preview") }}
                                </span>
                                <span class="title-menu-item" (click)="shareLoding(item.id,item.file_name)" *ngIf="!service.isFolder(item.type)">
                                    <!-- 分享 -->
                                    {{ i18n.t("js.documents.share") }}
                                </span>
                                <!-- <span class="title-menu-item" (click)="editLoding(item.id,item.file_name)" *ngIf="item.type== 'pdf'"> -->
                                    <!-- 编辑 -->
                                    <!-- {{ i18n.t("js.documents.edit") }}
                                </span> -->
                                <!-- 暂时隐藏三个点 -->
                                <!-- <span class="title-menu-item">
                                    <span class="title-menu-item-point"></span>
                                    <span class="title-menu-item-point"></span>
                                    <span class="title-menu-item-point"></span>
                                </span> -->
                            </div>
                        </div>
                    </div>
                    <!-- mobile -->
                    <div class="content-m-display">
                        <div class="content-m">
                            <div class="content-m-left" (click)="enterFolder(item)">
                                <div [class]="fileIcon(item.type)"></div>
                                <div class="flex-1">
                                    <div class="content-m-title">
                                        {{ service.isFolder(item.type) ? item.name : item.file_name }}
                                    </div>
                                    <div class="content-m-content">
                                        <span>
                                            {{ service.formatDate(item.last_modified) }}
                                        </span>
                                        <span class="content-m-content-author">
                                            {{ item.creator }}
                                        </span>
                                        <span class="content-m-content-size">
                                            {{ i18n.t("js.documents.update") }}
                                            {{ service.isFolder(item.type) ? '__' : service.formatFileSize(item.file_size) }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="more-vertical-m-icon-padding" (click)="handleOperationListShow(item)">
                                <div class="documents-more-vertical-m more-vertical-m-icon"></div>
                            </div>
                        </div>
                    </div>
                    <div class="border"></div>
                </div>
            </div>
        </div>
        <!-- 分页 -->
        <documents-pagination
            [per]="params.per"
            [currentPage]="params.page"
            [total]="total"
            (getData)="paginationChange($event)">
        </documents-pagination>
    </div>
    <div class="documents-input-name">
        <documents-input-name
            [oldName]="oldName"
            [type]="inputNameType"
            (cancel)="handleCancel()"
            (confirm)="handleConfirm($event)"
            *ngIf="inputNameShow">
        </documents-input-name>
    </div>
    <div class="documents-operation-list">
        <documents-operation-list
            [item]="operationListItem"
            (download)="downLoad(operationListItem.id)"
            (upload)="uploadFile(operationListItem.id)"
            (move)="listItemMove(operationListItem, 'move')"
            (copy)="listItemMove(operationListItem, 'copy')"
            (rename)="handleInputName(operationListItem, 'rename')"
            (preview)="handlePreview(operationListItem.id)"
            (share)="shareLoding(operationListItem.id,operationListItem.file_name)"
            (close)="handleClose()"
            *ngIf="operationListShow">
        </documents-operation-list>
    </div>
    <div class="documents-operation">
        <documents-operation
            *ngIf="operationShow"
            [type]="moveType"
            (confirm)="handleOperationConfirm()"
            (cancel)="handleOperationCancel()">
        </documents-operation>
    </div>
    <div class="documents-toast">
        <documents-toast
            *ngIf="toastShow"
            [type]="toastType">
        </documents-toast>
    </div>
    <documents-loading *ngIf="loadingShow"></documents-loading>
    <!-- 预览 -->
    <div class="documents-preview" *ngIf="previewShow">
        <!-- #preview -->
        <documents-preview [url]="previewUrl"></documents-preview>
    </div>

    <div class="documents-share" *ngIf="shareShow">
        <!-- #preview -->
        <documents-share (close)="shareclose()" [shareticket]="shareticket" [sharename]="sharename"></documents-share>
    </div>
    <div class="d_pdf" *ngIf="pdfShow">
        <documents-pdf   (close)="pdfclose()" (colseloading)="colseloading()" (openLoad)="openloading()"  (loading)="showLoad()"  [item]="items"></documents-pdf>
    </div>
    <!-- 遮罩层 -->
    <div id="documents-mask"
        (click)="closeMask()"
        *ngIf="loadingShow
            || inputNameShow
            || operationListShow
            || operationShow
            || operationModalShow
            || previewShow
            || shareShow
            || pdfShow">
    </div>
</div>