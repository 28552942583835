import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class labour_returnService {

  //请求头
  requestHeader:any = {
    withCredentials: true,
    headers: {
      'X-Authentication-Scheme': 'Session',
      'X-Requested-With': 'XMLHttpRequest'
    }
  }

  requestJsonHeader:any = {
    withCredentials: true,
    headers: {
      'X-Authentication-Scheme': 'Session',
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type':'application/json'
    }
  }
  //API前缀
  apiPrefix: any = '/api/v3';
  constructor(private http: HttpClient) {
    
  }

  //查找所有列表
  getAllList(projectid:any, action:any,version:any){
    return this.http.get(`${this.apiPrefix}/projects/${projectid}/labour_returns?project_id=${projectid}&action=${action}&version=${version}&order_way=month`)
  }
  getMapList(projectid:any, version:any) {
    return this.http.get(`${this.apiPrefix}/projects/${projectid}/labour_return_mapping?project_id=${projectid}&version=${version}`)
  }
  addMapList(projectid:any,{report_index,mapping_trade_name,code,is_show_in_form,version}:any) {
    return this.http.post(`${this.apiPrefix}/projects/${projectid}/labour_return_mapping`, {
      project_id: projectid,
      report_index,
      mapping_trade_name,
      code,
      is_show_in_form,
      version
    }, this.requestJsonHeader)
  }
  getMapFromSiteDiary(projectid:string, version: string) {
    return this.http.get(`${this.apiPrefix}/projects/${projectid}/labour_return_mapping/update_labour_list_data`, {
      params: {
        project_id:projectid,
        version
      }
    })
  }
  updateLabourReturnFromMapping(project_id:any,labour_return_id:any, version:string) {
    return this.http.post(`${this.apiPrefix}/projects/${project_id}/labour_returns/${labour_return_id}/update_from_mapping`,{
      project_id,
      labour_return_id,
      version
    },this.requestHeader)
  }
  editMap(projectid: string, item:any) {
    return this.http.post(`${this.apiPrefix}/projects/${projectid}/labour_return_mapping/mapping_update`, item, this.requestHeader)
  }
  getlabour(projectid:any,labour_return_id:any) {
    return this.http.get(`${this.apiPrefix}/projects/${projectid}/labour_returns/${labour_return_id}`)
  }
  //创建单个列表
  AddOneList(data:any){
    return this.http.post(`${this.apiPrefix}/projects/${data.project_id}/labour_returns`,
    data,
    this.requestHeader
    )
  }
  //刪除單個列表
  deleteOneList(data:any){
    return this.http.delete(`${this.apiPrefix}/projects/${data.project_id}/labour_returns/${data.labour_return_id}`,
    this.requestJsonHeader
    )
  }
  EditOneList(data:any){
    return this.http.patch(`${this.apiPrefix}/projects/${data.project_id}/labour_returns/${data.labour_return_id}`,
    data,
    this.requestHeader
    )
  }
  uploadImg(data:any){
    return this.http.post(`${this.apiPrefix}/projects/${data.project_id}/sign`,data.file,this.requestHeader)
  }
  //constractor签名
  constractorAdopt(data:any){
    return this.http.post(`${this.apiPrefix}/projects/${data.project_id}/labour_returns/${data.labour_return_id}/approve`,
    data.contractor_sign_doc_id,
    this.requestJsonHeader
    )
  }
  //supervisor签名
  supervisorAdopt(data:any){
    return this.http.post(`${this.apiPrefix}/projects/${data.project_id}/labour_returns/${data.labour_return_id}/review`,
    data.supervisor_sign_doc_id,
    this.requestJsonHeader
    )
  }
  //签名拒绝
  signatureRejected(data:any){
    return this.http.post(`${this.apiPrefix}/projects/${data.project_id}/labour_returns/${data.labour_return_id}/reject`,
    data.rejectData,
    this.requestHeader
    )
  }
}
