<div id="riscformpdf" (click)="refresh()"></div>
<div edit-form [resource]="workPackage" *ngIf="workPackage&&phone_demo_pdf" class="work-packages--show-view">
  <ng-container *ngIf="phone_demo">
    <wp-breadcrumb [workPackage]="workPackage"></wp-breadcrumb>

    <div class="toolbar-container">
      <div id="toolbar">
        <div class="wp-show--header-container">
          <div class="wp-show--back-button hide-when-print">
            <accessible-by-keyboard (execute)="goBack()" linkClass="work-packages-back-button button"
              [linkAriaLabel]="text.goBack" [linkTitle]="text.goBack">
              <op-icon icon-classes="button--icon icon-back-up"></op-icon>
            </accessible-by-keyboard>
          </div>
          <ul class="subject-header">
            <li class="subject-header-inner">
              <wp-subject></wp-subject>
            </li>
          </ul>
        </div>
        <ul id="toolbar-items" class="toolbar-items hide-when-print">
          <li class="toolbar-item hidden-for-mobile">
            <wp-create-button [allowed]="!!(workPackage.addChild || workPackage.$links.copy)"
              stateName="work-packages.new">
            </wp-create-button>
          </li>
          <li class="toolbar-item" *ngIf="displayWatchButton">
            <wp-watcher-button [workPackage]="workPackage" [showText]="false">
            </wp-watcher-button>
          </li>
          <li class="toolbar-item hidden-for-mobile">
            <zen-mode-toggle-button>
            </zen-mode-toggle-button>
          </li>
        </ul>
      </div>
    </div>
  </ng-container>

  <div class="work-packages-full-view--split-container">
    <ng-container *ngIf="phone_demo">
      <div class="work-packages-full-view--split-left">
        <div class="work-packages--panel-inner">
          <ng-container *ngIf="isRiscForm">
            <wp-single-view [workPackage]="workPackage"></wp-single-view>
          </ng-container>
          <ng-container *ngIf="!isRiscForm">
            <div class="wp-info-wrapper">
              <wp-status-button *ngIf="!workPackage.isNew" [workPackage]="workPackage">
              </wp-status-button>
              <attribute-help-text [attribute]="'status'" [attributeScope]="'WorkPackage'" *ngIf="!workPackage.isNew">
              </attribute-help-text>

              <div class="work-packages--info-row" *ngIf="!workPackage.isNew">
                <span [textContent]="idLabel"></span>:
                <span [textContent]="wptext.infoRow.createdBy"></span>
                <span>&nbsp;</span>
                <user-link class="user-link" [user]="workPackage.author"></user-link>.
                <span [textContent]="wptext.infoRow.lastUpdatedOn"></span>
                <span>&nbsp;</span>
                <op-date-time [dateTimeValue]="workPackage.updatedAt"></op-date-time>.
              </div>
            </div>
            <div class="work-packages-full-view--split-right work-packages-tab-view--overflow">
              <div class="work-packages--panel-inner">
                <span class="hidden-for-sighted" tabindex="-1" focus [textContent]="focusAnchorLabel"></span>
                <div id="tabs">
                  <ul class="tabrow">
                    <!-- The hrefs with empty URLs are necessary for IE10 to focus these links
                      properly. Thus, don't remove the hrefs or the empty URLs! -->
                    <li *ngIf="!isRiscForm" uiSref="work-packages.show.form" [uiParams]="{workPackageId: workPackage.id}"
                      uiSrefActive="selected">
                      <a href="">form</a>
                    </li>
                    <li uiSref="work-packages.show.activity" [uiParams]="{workPackageId: workPackage.id}"
                      uiSrefActive="selected">
                      <a href="" [textContent]="text.tabs.activity"></a>
                    </li>
                    <li *ngIf="canRelevance() && isRiscForm" uiSref="work-packages.show.relations"
                      [uiParams]="{workPackageId: workPackage.id}" uiSrefActive="selected">
                      <a href="" [textContent]="text.tabs.relations"></a>
                      <wp-relations-count [wpId]="workPackage.id"></wp-relations-count>
                    </li>
                    <li *ngIf="canViewWorkPackageWatchers() && isRiscForm" uiSref="work-packages.show.watchers"
                      [uiParams]="{workPackageId: workPackage.id}" uiSrefActive="selected">
                      <a href="" [textContent]="text.tabs.watchers"></a>
                      <wp-watchers-count [wpId]="workPackage.id"></wp-watchers-count>
                    </li>
                  </ul>
                </div>
                <div class="tabcontent" ui-view>
                </div>
              </div>
        
              <div class="work-packages-full-view--resizer hidden-for-mobile hide-when-print">
                <wp-resizer [elementClass]="'work-packages-full-view--split-right'"
                  [localStorageKey]="'openProject-fullViewFlexBasis'"></wp-resizer>
              </div>
            </div>
            <!-- <div
              style="display: block;width: 100%;height: 1135px;border: 1px solid rgba(0,0,0,0.3); position: relative;">
              <div id="riscloding" class="attributes-key-value__loading" *ngIf="signloding == i" style="position: absolute;
                        top: 50%;
                        left: 50%;
                        height: 160px;
                        transform: translate(-50%,-50%);">
                <div class="loading-indicator -compact" style="width: 155px;height: 120px;">
                  <div class="block-1" style="width: 15px;"></div>
                  <div class="block-2" style="width: 15px;"></div>
                  <div class="block-3" style="width: 15px;"></div>
                  <div class="block-4" style="width: 15px;"></div>
                  <div class="block-5" style="width: 15px;"></div>
                </div>
              </div>
              <pdf-viewer id="riscpdfview" [src]="pdfSrc" [render-text]="true" [autoresize]="true"
                [original-size]="false" style="display: block;width: 100%;height: 100%;" 
                (after-load-complete)="callBackFn($event)" (on-progress)="onProgress($event)"></pdf-viewer>
            </div> -->
            <div class="work-packages--attachments attributes-group"
              *ngIf="workPackage.canAddAttachments || workPackage.hasAttachments">
              <div class="work-packages--attachments-container">
                <div class="attributes-group--header">
                  <div class="attributes-group--header-container">
                    <h3 class="attributes-group--header-text" [textContent]="wptext.attachments.label"></h3>
                  </div>
                </div>

                <ndc-dynamic [ndcDynamicComponent]="attachmentListComponent()"
                  [ndcDynamicInputs]="{ resource: workPackage,is_project_admin:is_project_admin }">
                </ndc-dynamic>

                <ndc-dynamic [ndcDynamicComponent]="attachmentUploadComponent()"
                  [ndcDynamicInputs]="{ resource: workPackage,is_project_admin:is_project_admin }">
                </ndc-dynamic>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  
  </div>
</div>
<div
  *ngIf="!phone_demo_pdf" 
  style="display: block;width: 100%;height:80vh;border: 1px solid rgba(0,0,0,0.3); position: relative;">
  <div id="riscloding" class="attributes-key-value__loading" *ngIf="signloding == i" style="position: absolute;
            top: 50%;
            left: 50%;
            height: 160px;
            transform: translate(-50%,-50%);">
    <div class="loading-indicator -compact" style="width: 155px;height: 120px;">
      <div class="block-1" style="width: 15px;"></div>
      <div class="block-2" style="width: 15px;"></div>
      <div class="block-3" style="width: 15px;"></div>
      <div class="block-4" style="width: 15px;"></div>
      <div class="block-5" style="width: 15px;"></div>
    </div>
  </div>
  <pdf-viewer id="riscpdfview" [src]="pdfSrc" [render-text]="true" [autoresize]="true"
    [original-size]="false" style="display: block;width: 100%;height: 100%;" 
    (after-load-complete)="callBackFn($event)" (on-progress)="onProgress($event)"></pdf-viewer>
</div>
