<div class="tabs">
  <ul style="padding: 0px" > 
    <li *ngFor="let item of itemdata">
      <a class="tab-show "  [ngClass]="itemdatavalue == item.id?'selected':''" (click)='titleclick(item.id)'>{{item.name}}</a>
    </li>
  </ul>
</div>

<div class="xianshiload">
  <cunstom-forms-loading ></cunstom-forms-loading>
</div>

<div style="position: relative;">
    <a href="javascript:;" class="Uploadtemplate" (click)="inputfile()" id="ainout"> {{ i18n.t("js.cunstom-forms.uploadbutton") }}
      <input class="change"  id="file" type="file" accept=".xlc, .xlm, .xls  .xlt, .xlw, .xml, .xlsx" />
    </a>
  <ng-container *ngIf="titlereturn">
    <a class="Uploadtemplate" (click)='clickreturn()' style="position: absolute;top: 0;left: 0;" [ngStyle]="{'width': widthbock}">{{ i18n.t("js.cunstom-forms.returnbutton") }}</a>
  </ng-container>
</div>

<div class="item-content">

  <ng-container *ngIf="excelcont">
    <cunstom-forms-excel [exceldatail]="exceldatail"></cunstom-forms-excel>
  </ng-container>

  <ng-container *ngIf="!excelcont">
    <table>
      <tr>
        <th style="text-align: left;">{{ i18n.t("js.cunstom-forms.title") }}</th>
        <th>{{ i18n.t("js.cunstom-forms.createdDate") }}</th>
        <th>{{ i18n.t("js.cunstom-forms.creator") }}</th>
        <th>{{ i18n.t("js.cunstom-forms.action") }}</th>
      </tr>
      <tr *ngFor="let item of itemdateil">
        <td><a (click)="clickexcel(item)">{{item.name}}</a></td>
        <td>{{item.created_at}}</td>
        <td>{{item.operator}}</td>
        <td (click)="delexcel(item)"><a style="color: black;">{{ i18n.t("js.cunstom-forms.deletebutton") }}</a></td>
      </tr>
    </table>
  </ng-container>
</div>