import { Component, OnInit } from '@angular/core';
import { DocumentsService } from './services/documents.service';
import { CurrentProjectService } from 'core-app/components/projects/current-project.service';
// import { StateService } from '@uirouter/core';

@Component({
  templateUrl: './documents.component.html',
  styleUrls: [ './documents.component.sass' ]
})
export class DocumentsComponent implements OnInit {
  constructor(
    public service: DocumentsService,
    public currentProject: CurrentProjectService,
    // private $state:StateService
  ) { }
  ngOnInit() {
    jQuery('#content-wrapper').css('padding', 0);
    // jQuery('#content-wrapper').addClass('background');
  }
  ngOnDestroy(): void {
    // jQuery('#content-wrapper').removeClass('background');
  }
}
//路由方法
// this.$state.go('.', {path: 'xxx'});