<div class="content">
  <div class="scanqrcode">
    <div id="reader" style="width:100%;border-bottom:1px solid #000000"></div>
    <div id="loading" class="loading">
      <div >
        <ng-container  *ngIf="!showTost">
          <div class="documents-loading icon rotate"></div>
          <span class="loading-text">Loading</span>
        </ng-container>
        <span *ngIf="showTost" class="showTost">{{showTost}}</span>
      </div>
    </div>
  </div>
  <div class="tips">
    <span>*Safari browser is recommended for IOS devices</span>
    <span>*Chrome browser is recommended for other devices</span>
    <span>*If there is no response, please refresh the page to try</span>
    <span>*If "notallowederror: permission denied" appears, please set the browser's permission to activate the camera</span>
  </div>
  <button class="clone" (click)="clone()">return</button>
</div>
<div class="bg"></div>