<div class="can-hide-navigation">
    <div class="toolbar-container">
        <div class="toolbar">
            <div class="title-container" style="display: flex;">
                <h2>Custom Forms</h2>
                <a class="Uploadtemplate" (click)='setupclick()'> {{ i18n.t("js.cunstom-forms.setupbuttom") }}</a>
            </div>
        </div>
    </div>

    <ng-container *ngIf="setuodipsla">
        <cunstom-forms-setup (notify)="setupnotify($event)"></cunstom-forms-setup>
    </ng-container>
    
    <ng-container *ngIf="!setuodipsla">
        <cunstom-forms-item></cunstom-forms-item>
    </ng-container>


</div>