import { Component, OnInit } from '@angular/core';
import { I18nService } from 'core-app/modules/common/i18n/i18n.service';
import { Photo_logService } from './services/photo_log.service';
import { CurrentProjectService } from 'core-app/components/projects/current-project.service';
import { flatMap } from 'lodash';
// import { StateService } from '@uirouter/core';

declare let laydate:any
@Component({
  templateUrl: './all_photo.component.html',
  styleUrls: [ './all_photo.component.sass' ]
})
export class All_photoComponent implements OnInit {
  public tagSelect:string = 'All'
  public type_id:any
  public showloading:boolean=true
  public userid:any
  public projectId:any
  public items:any
  public isselect:boolean=false
  public imgOrigin = `${location.origin}/images/isselect.png`
  public showRight:any=[]
  public showDetail:boolean=false
  public saveitemId:any
  public multitoshowEdit:boolean=false
  public comfirmDelete:boolean = false
  public saveimg:any=[]
  public getsingledata:any
  public pageCount:Number
  public pageSizenum:any=[]
  public savenowPage:any
  public hasother:any
  public projectName:any
  public hideEditbtn:boolean
  public saveUserid:any=[]
  public startTime = ''
  public endTime = ''
  public locationsSelect = {

    location1Select: '',
    location2Select: '',
    location3Select: ''
  }
  public location1: Array<any>
  public location2: Array<any>
  public location3: Array<any>
  public progressList: Array<any>
  public progessAdd = false
  public isProgressDetail:boolean = false
  public progressId:any
  public showAddToProgress:boolean = false
  public token: any
  public token_select_id:any
  constructor(
    public service: Photo_logService,
    public i18n: I18nService,
    public currentProject: CurrentProjectService,
    // private $state:StateService
  ) { }
  ngOnInit() {
    this.InitDate()
    this.userid = jQuery('meta[name=current_user]').data('id')
    this.projectId = jQuery('meta[name=current_project]').data('projectId')
    this.projectName =  jQuery('meta[name=current_project]').data('projectIdentifier')
    this.allInit()
  }

  InitDate() {
    setTimeout( () => {
      laydate.render({ 
        elem: '#timeScreen' ,
        type: 'date',
        range: ['#timeRangeStart', '#timeRanngeEnd'],
        done:(value:any) => {
  
          if(value.length > 0) {
            let date = value.split(' - ')
            console.log(date)
            this.startTime = date[0].replace(/\s*/g,"");
            this.endTime = date[1].replace(/\s*/g,"");
          } else {
            this.startTime = ''
            this.endTime = ''
          }
  
          let location = {}
          if(this.locationsSelect.location1Select) {
            location  = {
              location1: this.locationsSelect.location1Select? this.locationsSelect.location1Select: undefined,
              location2: this.locationsSelect.location2Select? this.locationsSelect.location2Select:undefined,
              location3: this.locationsSelect.location3Select? this.locationsSelect.location3Select:undefined 
            } as any
          }
  
          this.service.getAlbumbyday({whether_all:false,project_id:this.projectId,start_time: this.startTime, end_time: this.endTime, page: this.savenowPage, location, progress_id: this.token_select_id}).subscribe( (res:any) => {
            this.pageCount = Math.ceil(res.count/3)
            this.pageSizenum = []
            for(let i=1;i<=this.pageCount;i++){
              this.pageSizenum.push(i)
            }
            this.savenowPage=1
            this.items = res.position
            setTimeout(() => {
              this.showloading=false
            }, 1000);
          })
        }
      });
    }, 1000)
  }
  allInit() {
    this.tagSelect = 'All'
    this.refreshProgress(1);
    let location = {}
    this.service.getToken().subscribe( (res:any) => {
      this.token = res.position
    })
    if(this.locationsSelect.location1Select) {
      location  = {
        location1: this.locationsSelect.location1Select? this.locationsSelect.location1Select: undefined,
        location2: this.locationsSelect.location2Select? this.locationsSelect.location2Select:undefined,
        location3: this.locationsSelect.location3Select? this.locationsSelect.location3Select:undefined 
      } as any
    }
    this.service.getAlbumbyday({whether_all:false,project_id:this.projectId,page: 1,start_time: this.startTime, end_time: this.endTime, location, progress_id: this.token_select_id }).subscribe((res:any)=>{
      this.pageCount = Math.ceil(res.count/3)
      this.pageSizenum = []
      for(let i=1;i<=this.pageCount;i++){
        this.pageSizenum.push(i)
      }
      this.savenowPage=1
      this.items = res.position
      setTimeout(() => {
        this.showloading=false
      }, 1000);
    })

    this.service.getType(this.projectId).subscribe((res:any)=>{
      this.type_id = res.type_id
      this.location1 = Object.values(res.allowed_values[0])[0] as Array<any>
      this.location2 = Object.values(res.allowed_values[1])[0] as Array<any>
      this.location3 = Object.values(res.allowed_values[2])[0] as Array<any>
    })
  }

  ngOnDestroy(): void {

  }
  ToProgress() {
    this.refreshProgress(1)
    this.tagSelect = 'Progress'
  }
  refreshProgress(page:any) {
    console.log('close1')
    this.progessAdd = false
    this.service.getProgressList(page).subscribe( (res:any) => {
      this.progressList = res.list
      this.pageCount = Math.ceil(res.count/10)
      this.pageSizenum = []
      for(let i=1;i<=this.pageCount;i++){
        this.pageSizenum.push(i)
      }
      this.savenowPage=page
    } )
  }

  selectLocation() {
    let location = {
      
    }
    if(this.locationsSelect.location1Select == '') {
      this.locationsSelect.location2Select = ''
      this.locationsSelect.location3Select = ''
      console.log(this.locationsSelect.location2Select)
    }
    if(this.locationsSelect.location2Select == '') {
      this.locationsSelect.location3Select = ''
    }
    if(this.locationsSelect.location1Select) {
      location  = {
        location1: this.locationsSelect.location1Select? this.locationsSelect.location1Select: undefined,
        location2: this.locationsSelect.location2Select? this.locationsSelect.location2Select:undefined,
        location3: this.locationsSelect.location3Select? this.locationsSelect.location3Select:undefined 
      } as any
    }
    this.service.getAlbumbyday({whether_all:false,project_id:this.projectId, page: this.savenowPage, location, start_time: this.startTime, end_time: this.endTime, progress_id: this.token_select_id}).subscribe( (res:any) => {
      this.pageCount = Math.ceil(res.count/3)
      this.pageSizenum = []
      for(let i=1;i<=this.pageCount;i++){
        this.pageSizenum.push(i)
      }
      this.savenowPage=1
      this.items = res.position
      setTimeout(() => {
        this.showloading=false
      }, 1000);
    }, (err) => {
      console.log(err)
      alert(JSON.parse(err.error.error).message)

    })
  }
  //显示loading
  Loading(){
    this.showloading=true
    setTimeout(() => {
      this.showloading=false
    }, 1000);
  }
  //获取照片数据接口
  getPicture(e:any){
    let location = {}
    if(this.locationsSelect.location1Select) {
      location  = {
        location1: this.locationsSelect.location1Select? this.locationsSelect.location1Select: undefined,
        location2: this.locationsSelect.location2Select? this.locationsSelect.location2Select:undefined,
        location3: this.locationsSelect.location3Select? this.locationsSelect.location3Select:undefined 
      } as any
    }
    this.service.getAlbumbyday({whether_all:false,project_id:this.projectId,page:e,start_time: this.startTime, end_time: this.endTime, location, progress_id: this.token_select_id}).subscribe((res:any)=>{
      this.items = res.position
    })
  }
  //删除图片
  goDelete(){
    if(this.showRight.length>0&&this.hasother){
      let r =window.confirm("是否确定删除")
      if (r==true){
        console.log(this.showRight)
        let photostring = this.showRight.toString() 
        this.service.deletePhtotLog(photostring).subscribe((res:any)=>{
          if(res.status=='ok'){
            this.getPicture(this.savenowPage)
            this.showRight=[]
          }
        })
      }
    }
  }
  // 下载图片
  toDowenLoad() {
    this.saveimg.forEach((url: string) => {
      const iframe = document.createElement('iframe')
      iframe.style.height = '0'
      iframe.style.display = 'none'
      iframe.src = url
      document.body.appendChild(iframe)
      console.log(iframe)
      setTimeout( ()=> {iframe.remove()}, 5000)
    });
  }
  //前往发布页面
  goWorkpackage(){
      if(this.isselect&&this.showRight.length>0){
        localStorage.setItem('imageid',this.showRight)
        const origin = window.location.origin
        window.location.href =`${origin}/projects/${this.projectName}/work_packages/new?type=${this.type_id}`
      }
  }
  //点击选择按钮
  confirmSelect(){
    this.isselect  =true
  }
  //点击取消按钮
  cancelSelect(){
    this.showRight = []
    this.isselect  =false
    this.hasother = false
    this.saveimg=[]
    this.saveUserid = []
  }
  //点击选择图片
  selectImg(e:any,user:any,url:any){
    this.saveUserid.push(user)
    this.showRight.push(e) 
    this.saveimg.push(url)
    for(let item of this.saveUserid){
      if(this.userid!=item){
        this.hasother=false
        break;
      }else{
        this.hasother=true
      }
    }

  }
  //取消选择图片
  cancelSelectimg(e:any,user:any,url:any){
    　let  index = this.showRight.indexOf(e);
    　let  urlindex = this.saveimg.indexOf(url);
    let userindex = this.saveUserid.indexOf(user)
        this.showRight.splice(index, 1)
        this.saveimg.splice(urlindex, 1)
        this.saveUserid.splice(userindex,1)
    console.log(this.saveUserid,'ssdf')
    console.log(this.userid)
        for(let item of this.saveUserid){
          if(this.userid!=item){
            this.hasother=false
            break;
          }else{
            this.hasother=true
          }
        }
        if(this.showRight.length==0){
          this.hasother=false
        }

  } 
  //进入详情页
  toDeatil(e:any,user:any,url:any){
  if(!this.isselect&&this.userid==user){
  console.log(this.userid,user,'这是true')
    this.Loading()
  this.showDetail = true
  this.saveitemId = e
  console.log(this.saveitemId)
  this.hideEditbtn = true
}else if(this.isselect&&this.showRight.indexOf(e)<0){
  this.selectImg(e,user,url)
}else if(this.isselect&&this.showRight.indexOf(e)>=0){
  this.cancelSelectimg(e,user,url)
}else if(!this.isselect&&this.userid!=user){
  this.Loading()
  this.saveitemId = e
  this.showDetail = true
  this.hideEditbtn=false
}
}
  //隐藏详情页
  tohideDetail(){
    this.showDetail = false
    this.hasother = false
    this.saveimg=[]
  }
  //多选详情页
  goEdit(){
   if(this.isselect&&this.showRight.length>0&&this.hasother){
    this.Loading()  
     sessionStorage.setItem('multi','multi')
      this.multitoshowEdit=true
   }
  }
  //显示编辑页面
  toshowEditpage(data:any){
    this.Loading()
    //单选
    this.getsingledata = data
      this.multitoshowEdit=true

  }
  //隐藏编辑耶米阿
  tohideEditpage(){
    this.multitoshowEdit=false
    this.isselect=false
    this.showRight=[]
    this.hasother = false
    this.saveimg=[]
  }

  goAddPhotoToProgress() {
    if(    this.showRight.length > 0 && this.isselect == true) {
      this.showAddToProgress = true
    }
  }
  closeAddToProgress(e:any) {
    this.showAddToProgress = false
    console.log(e == 'ok')
    if(e == 'ok') {
      this.showRight = []
      this.isselect  =false
      this.hasother = false
      this.saveimg=[]
      this.saveUserid = []

    }
  }
  //分页
  toMushpage(e:any){
    this.savenowPage = e
    if(this.tagSelect !== 'Progress') {
      this.getPicture(e)
    } else {
      this.refreshProgress(e) 
    }
  }
  //上一页
  prepage(){
    if(this.savenowPage==1){
      if(this.tagSelect !== 'Progress') {
      this.getPicture(1)
      } {
        this.refreshProgress(1) 
      }
    }else{
      if(this.tagSelect !== 'Progress') {
      this.getPicture(  this.savenowPage-1)
      } else {
        this.refreshProgress(this.savenowPage-1 ) 
      }
      this.savenowPage--
    }
  }
//下一页
  nextpage(){
    if(this.savenowPage == this.pageCount) {
      return
    }
    if(this.tagSelect !== 'Progress') {
      this.getPicture(  this.savenowPage+1)
    } else {
      this.refreshProgress(this.savenowPage+1) 
    }
    this.savenowPage++
  }
  getProgress(page:any) {

  }
  // 更新页面
  torefresh(){
    this.getPicture(this.savenowPage)
    this.Loading()
    this.isselect = false
    this.hasother = false
    let info = I18n.t('js.photo-log.savesuccess')
    alert(info)
  }
  showPrograssDetail(id:any) {
    this.progressId = id
    this.isProgressDetail = true
  }
  goBackTable() {
    this.isProgressDetail = false
    this.tagSelect = 'Progress'
    this.InitDate()
  }

}
