<div *ngIf="sednType === 'list'">
  <h2> Safety / Environmental Deficiency Notification</h2>
  <div class="filter" >
    <div class="left">
      <div [ngClass]="{'checked': checked == 'MyAction'}" (click)="filterCheck('MyAction')">
        <a>
          My Action
        </a>
      </div>
      <div>|</div>
      <div [ngClass]="{'checked': checked == 'New'}" (click)="filterCheck('New')">
        <a>
        New
        </a>
    </div>
    <div>|</div>
      <div [ngClass]="{'checked': checked == 'Sent'}" (click)="filterCheck('Sent')">
        <a>
          Sent
        </a>  
      </div>
      <div>|</div>
      <div [ngClass]="{'checked': checked == 'Completed'}" (click)="filterCheck('Completed')">
        <a>
          Completed
        </a>
      </div>
      <div>|</div>
      <div [ngClass]="{'checked': checked == 'Closed'}" (click)="filterCheck('Closed')">
        <a>
          Closed
        </a>
      </div>
      <div>|</div>
      <div [ngClass]="{'checked': checked == 'All'}" (click)="filterCheck('All')">
      <a>
        All
      </a>  
      </div>
    </div>
    <div class="right">
      <!-- <div class="search">
  
        <input placeholder="Search"/>
        <i class="layui-icon  layui-icon-search"></i>
      </div> -->
      <div class="Create" (click)="createSEDN()">
        Create
      </div>
        <div class="dowloadPdf" (click)="downLoadExcel()">
          Download Excel
        </div>
      
      <div class="dowloadPdf" (click)="downLoadPdf()">
        Download PDF
      </div>
    </div>
  </div>
  <div class="tableList">
    <table>
      <thead>
        <tr>
          <th></th>
          <th (click)="sort('file_id')">Subject<span *ngIf="sortClass.name == 'file_id'" class="sort" [ngClass]="{'desc': sortClass.type == 'desc'}" ></span></th>
          <th (click)="sort('state')">Status <span class="sort" *ngIf="sortClass.name == 'state'" [ngClass]="{'desc': sortClass.type == 'desc'}"></span></th>
          <th (click)="sort('deficiency')">Deficiencies Type<span class="sort" *ngIf="sortClass.name == 'deficiency'" [ngClass]="{'desc': sortClass.type == 'desc'}"></span></th>
          <th (click)="sort('location')">Location<span class="sort" *ngIf="sortClass.name == 'location'" [ngClass]="{'desc': sortClass.type == 'desc'}"></span></th>
          <th (click)="sort('site_activity')">Site Activity<span class="sort" *ngIf="sortClass.name == 'site_activity'" [ngClass]="{'desc': sortClass.type == 'desc'}"></span></th>
          <th (click)="sort('prepared_by')">
            Prepared by
            <span class="sort" *ngIf="sortClass.name == 'prepared_by'" [ngClass]="{'desc': sortClass.type == 'desc'}"></span>
          </th>
          <th (click)="sort('prepared_sign_at')">
            issue date time
            <span class="sort" *ngIf="sortClass.name == 'prepared_sign_at'" [ngClass]="{'desc': sortClass.type == 'desc'}"></span>
          </th> 
          <th (click)="sort('replied_by')">
            Replied by
            <span class="sort" *ngIf="sortClass.name == 'replied_by'" [ngClass]="{'desc': sortClass.type == 'desc'}"></span>
          </th>
          <th (click)="sort('replied_state_at')">
            Reply Date time
            <span class="sort" *ngIf="sortClass.name == 'replied_state_at'" [ngClass]="{'desc': sortClass.type == 'desc'}"></span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of SEDNList" (click)="openSEDN(item)" (click)="checkItem = item.file_id" [ngClass]="{
          checked: checkItem == item.file_id
        }">
          <td><input type="checkbox" [value]="item.id" (change)="channgeCheckBox($event, item.id)" (click)="$event.stopPropagation()"></td>
          <td>{{item.file_id}}</td>
          <td>{{item.state}}</td>
          <td>{{item.deficiencies_name}}</td>
          <td>{{item.location}}</td>
          <td>{{item.site_activity}}</td>
          <td>{{item.prepared_by.firstname + item.prepared_by.lastname}}</td>
          <td>{{item.prepared_sign_at}}</td>
          <td>{{item.replied_by?item.replied_by.firstname + item.replied_by.lastname: ''}}</td>
          <td>{{item.replied_state_at}}</td>
        </tr>
      </tbody>
    </table>
    <div id="page"></div>
  </div>

</div>
<div class="attributes-key-value__loading uploadLoading" *ngIf="downLoading">
  <div class="loading-indicator -compact">
  <div class="block-1"></div>
  <div class="block-2"></div>
  <div class="block-3"></div>
  <div class="block-4"></div>
  <div class="block-5"></div>
  </div>
</div> 
<sednDetail *ngIf="sednType !== 'list'" [SEDNItem]="sednItem" (changeNow)="stateChange()"></sednDetail>
