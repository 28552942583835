<!--
 * @Author: your name
 * @Date: 2020-06-17 14:24:02
 * @LastEditTime: 2020-07-10 15:44:58
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /junhe/frontend/src/app/modules/check_lists/components/check_lists-newtemplate/check_lists-newtemplate.component.html
--> 
<div class="body-contnet">
  <div class="title">
    <div class="bockicon" (click)="clickBackstep()"></div>
    <div class="title-input">
      <input #editableTitleInput
          type="p"
          [value]="dataObjectInfo.name"
          [(ngModel)]="dataObjectInfo.name"
          class="editable-toolbar-title--input toolbar--editable-toolbar ellipsis" style="width: 100%;"/>
    </div>

  </div>
  <div class="content">
    <i (click)="containPhone(true)" class="outlineShow">>></i>
    <div class="baseoutline">
      <i (click)="containPhone(false)" class="icon-close phoneTogglebtn"></i>
      <div class="content-date-left-title"  >
        <svg t="1590741643954" class="icon" viewBox="0 0 1152 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5392" width="16" height="16" >
          <path d="M0 0h1152v128H0V0z m0 448h768v128H0V448z m0 448h1152v128H0v-128z" p-id="5393" fill="#848c91"></path>
        </svg>
        <span>{{ i18n.t("js.check-lists.quest.outtline") }}</span>
      </div>
      <!-- 列表 -->
      <ul id="item" [sortablejs]="data" [sortablejsOptions]="options" >
        <li  (click)="clickLi(i)" *ngFor="let item of data ; let i = index" (mouseover)="showAftername(i)" (mouseout)="hideAftername(i)">
          <div class="content-date-left-item " [class.content-date-left-item-click]="i==index && showList" >
            <span class="icon-drag-handle shuliangicon"></span>
            <span class="shuliang"  >{{i+1}}</span>
            <span class="name"  (click)="containPhone(false)" >{{item.title}}</span>
            <span class="aftername">{{item.title}}</span>
            <span class="shuliang" style="padding: 0;">{{item.items.length}}</span>
            <span class="icon-show-more-horizontal buttonicon" (click)="showUl2 = !showUl2"></span>
            <ul class="B360Ul DropdownMenu--float-left" *ngIf="showUl2 && i==index">
              <li (click)="deleteItems()" style="width: 70px !important;" [ngStyle]="{'background' : data.length > 1 ? '' : '#edf0f2'}"><span>{{ i18n.t("js.check-lists.template.delete") }}</span></li>
            </ul>
          </div>
        </li>
      </ul>
      <!-- 添加按钮 -->
      <div class="addleftitem" (click)="addDataOne()">
        <div class="addcontent" style="cursor: pointer;">
          <div class="addicon">
            <span class="icon-add"></span>
          </div>
          <span>Add section</span>
        </div>
      </div>
      <!-- 签名 -->
      <ul *ngIf="dataObjectInfo.sign_required === 'require_sign'"  class="needSignul" (click)="containPhone(false)">
        <hr />
        <li (click)="showList = false">
          <div class="content-date-left-item " [class.content-date-left-item-click]="!showList" >
            <span class="name ">{{ i18n.t("js.check-lists.quest.Required") }}</span>
            <span class="shuliang">{{signs.length}}</span>
            <span class="icon-show-more-horizontal buttonicon"></span>
          </div>
        </li>
      </ul>
    </div>
    <div class="basemain" *ngIf="!showList">
      <div class="content-date-conter">
        <div class="content-data-conter-title">
          <h2 style="margin-right: 20px;">{{ i18n.t("js.check-lists.quest.Required") }}</h2>
        </div>
          <ul  [sortablejs]="signs" [sortablejsOptions]="signArrData">
            <li class="content-li" *ngFor="let item of signs; let i = index">
              <div class="content-date-conter-item">
                <span class="icon-drag-handle BimQualityApp"></span>
                <div class="sign_box">
                  <span>{{ i18n.t("js.check-lists.quest.Signature") }}{{i+1}}</span>
                  <span *ngIf="i === 0" style="font-weight: 700;margin-left: 55px;">{{ i18n.t("js.check-lists.template.Whoneedstosignthis") }}?</span>
                  <div class="delect_icon" style="float: right;">
                    <span class="icon-show-more-horizontal buttonicon" (click)="clickSignDelete(i)"></span>
                    <ul class="B360Ul DropdownMenu--float-left" *ngIf="showSignLi && i === signIndex">
                        <li (click)="deleteSignOne(i)"><span>{{ i18n.t("js.check-lists.template.delete") }}</span></li>
                    </ul>
                  </div>
                  <div style="clear:both;"></div>
                  <div class="sign_input">
                    <select class="Select-control" style="line-height: 26px;"  (ngModelChange)="changVerify()" [(ngModel)]="item.user_id">
                      <option [value]="item.id"  *ngFor="let item of byuserdata; let j = index">{{item.name}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div class="addSign"   style="cursor: pointer;"  >  
            <button  (click)="addSignOne()">{{ i18n.t("js.check-lists.template.Addsigna") }}</button>
          </div>
      </div>
    </div>
    <div class="basemain" *ngIf="showList">
      <div class="content-date-conter">
        <div class="content-data-conter-title">
          <div class="content-data-cinter-title-text">
            <h2 style="margin-right: 20px;">{{index+1}}</h2>
            <input type="text" style="border: none;background-color: #f7f9fa;" [class.addClass]="addClass1" (focus)="addClass1 = true" (blur)="inputBlur(index)" [(ngModel)]="data[index].title" placeholder="New section"  (click)="select($event)">
          </div>
          <div class="jingdutiao">
            <span class="jiangdutiao-text">{{data[index].items.length}} Items</span>
            <span class="icon-show-more-horizontal" (click)="showUl = !showUl"></span>
            <ul class="B360Ul DropdownMenu--float-left" *ngIf="showUl">
              <li (click)="deleteItems()" [ngStyle]="{'background' : data.length > 1 ? '' : '#edf0f2'}"><span>{{ i18n.t("js.check-lists.template.delete") }}</span></li>
            </ul>
          </div>
        </div>  
        <div class="content-date-conter-describe">
          <!-- <input type="text" style="border: none;background-color: #f7f9fa;" placeholder="Add description" [class.addClass]="addClass2" (focus)="addClass2 = true" (blur)="addClass2 = false" [(ngModel)]="data[index].description"> -->
          <textarea  style="border: none;background-color: #f7f9fa;resize: none;" placeholder="Add description" [class.addClass]="addClass2" (focus)="addClass2 = true" (blur)="addClass2 = false" [(ngModel)]="data[index].description" cols="30" rows="7"></textarea>
        </div>
        <ul [sortablejs]="data[index].items" [sortablejsOptions]="dataoptions">
          <li class="content-li" *ngFor="let item of data[index].items; let i = index">
            <div class="content-date-conter-item">
              <span class="icon-drag-handle BimQualityApp"></span>
              <check_lists-newtemplate-ctitem [dataItems]="item.list" [kind_id]="kind_id" [indexs]="index" [i]="i" (deleteOne)="deleteOne($event)" (update)="update()"></check_lists-newtemplate-ctitem>
            </div>
          </li>
        </ul>

        <div class="TemplateBuilderSectionPanel__split-button-wrapper">
          <div class="TemplateBuilderSectionPanel__split-button">
            <div class="Select-control">
              <!-- <div class="Select-control-icon">
                <span></span>
              </div> -->
              <select class="Select-control" style="line-height: 26px;"  [(ngModel)]="typeValue">
                <option value="text" >{{ i18n.t("js.check-lists.template.text") }}</option>
                <option value="radio" >{{ i18n.t("js.check-lists.template.templateyes") }}/{{ i18n.t("js.check-lists.template.templateno") }}/{{ i18n.t("js.check-lists.template.nothing") }}</option>
                <option value="checkbox" >{{ i18n.t("js.check-lists.template.checkbox") }}</option>
                <option value="date" >{{ i18n.t("js.check-lists.template.date") }}</option>
                <option value="select" >{{ i18n.t("js.check-lists.template.select") }}</option>
                <option value="unique_date" *ngIf="kind_id == 6">unique Date</option>
                <option value="Int" *ngIf="kind_id == 6">Int</option>
                <option value="Number" *ngIf="kind_id == 6">Number</option>
              </select>
              <!-- <div class="Select-control-sanjiao" style="transform:rotate(180deg)"></div> -->
            </div>
            <button class="SplitButton__action-button" (click)="addNewItem()">Add new item</button>
            <div class="Select-control-loding"></div>
          </div>
        </div>
      </div>
      <div class="TemplateBuilderAddNewSectionButton"   style="cursor: pointer;" (click)="addDataOne()">  
        <span class="icon-add"></span>
        <span>Add section</span>
      </div>
    </div>
  </div>
</div>