<div class="mask" (click)="okclick()">
  <div class="frame" (click)="inputdilog = false; codedialog = false; plantDialog = false; idDialog = false;$event.stopPropagation()">
    <div class="title">{{title}}</div>
    <div class="close" (click)="okclick()">X</div>
    <div class="content" id="addActivity_Pupop">
      <ng-container *ngIf="pupopType == 'labour_items'">
        <div class="key-value">
          <div class="key">{{title}}：</div>
          <div class="key-input" (click)="inputfocus('input');$event.stopPropagation()">
<!--            <input disabled     [(ngModel)]="addStaffsData.labour_name"/>-->
            <input readonly     [(ngModel)]="addStaffsData.labour_name"/>
            <div class="key-divloading" *ngIf="inputdilog" >
              <input class="key-search-input" (click)="$event.stopPropagation()" (input)="inputChange($event.target.value?$event.target.value.toLowerCase():'','input', $event)"/>
              <button class="key-divloading-button" *ngFor="let item of labourName_List" (click)="$event.stopPropagation();labourNameListClick(item,'labour')">{{item.name}}</button>
            </div>
          </div>
        </div>
        <div class="key-value">
          <div class="key">Code:</div>
          <div class="key-input" (click)="inputfocus('code');$event.stopPropagation()">
            <input disabled  class="value" [(ngModel)]="addStaffsData.labour_code" />
            <!-- <input (focus)="inputfocus('code')"  [disabled]="noInput" type="text" class="value" [(ngModel)]="addStaffsData.labour_code" /> -->
            <div class="key-divloading" *ngIf="codedialog">
              <input class="key-search-input" (click)="$event.stopPropagation()" (input)="inputChange($event.target.value?$event.target.value.toLowerCase():'','code')"/>
              <button class="key-divloading-button" *ngFor="let item of labourName_List" (click)="$event.stopPropagation();labourNameListClick(item,'code')">{{item.code}}</button>
            </div>
          </div>
        </div>
        <div class="key-value">
          <div class="key">Number*：</div>
          <div class="value">
            <input type="number" min="0" class="value-input" 
            [ngClass]="{'input-error': EmptyTip.NumberTip}" [(ngModel)]="addStaffsData.number"/>
            <span class="tip" [ngClass]="{'tipAnimation': EmptyTip.NumberTip}" *ngIf="EmptyTip.NumberTip">Number can not be Empty</span>
          </div>
        </div>
        <div class="key-value">
          <div class="key">Ownership*：</div>
          <div class="value">
            <div class="key-input" (click)="inputfocus('ownership');$event.stopPropagation()">
              <input class="value-input"   (ngModelChange)="inputChange($event,'ownership')"  [ngClass]="{'input-error': EmptyTip.OwnershipTip}" [(ngModel)]="addStaffsData.ownership" readonly/>
              <div class="key-divloading"  *ngIf="ownershipDialog">
                <input class="key-search-input" (click)="$event.stopPropagation()" (input)="inputChange($event.target.value?$event.target.value.toLowerCase():'','ownership')"/>
                <button class="key-divloading-button" *ngFor="let item of ownershipList" (click)="$event.stopPropagation();plantListClick(item,'ownership')">{{item.ownership_name}}</button>
              </div>
            </div>
            <span class="tip" [ngClass]="{'tipAnimation': EmptyTip.OwnershipTip}" *ngIf="EmptyTip.OwnershipTip">Ownership can not be Empty</span>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="pupopType == 'plant_items'">
        <div class="key-value">
          <div class="key">{{title}}：</div>
          <div class="key-input" (click)="inputfocus('plant');$event.stopPropagation()">
<!--            <input disabled (ngModelChange)="inputChange($event,'plant')"   [(ngModel)]="addStaffsData.equipment"/>-->
            <input readonly (ngModelChange)="inputChange($event,'plant')"   [(ngModel)]="addStaffsData.equipment"/>
            <div class="key-divloading"  *ngIf="plantDialog">
              <input class="key-search-input" (click)="$event.stopPropagation()" (input)="inputChange($event.target.value?$event.target.value.toLowerCase():'','plant')"/>
              <button class="key-divloading-button" *ngFor="let item of plantName_List" (click)="$event.stopPropagation();plantListClick(item,'plant')">{{item.sub_type}}</button>
            </div>
          </div>
        </div>
        <div class="key-value">
          <div class="key">ID*：</div>
          <!-- <input class="value" [disabled]="noInput" [(ngModel)]="addStaffsData.plant_mark"/> -->
          <!-- <input class="value" [(ngModel)]="addStaffsData.plant_mark"/> -->
          <div class="key-input">
            <input (focus)="inputfocus('id')" [ngClass]="{'input-error': EmptyTip.IDTip}" (click)="$event.stopPropagation()"  (ngModelChange)="inputChange($event,'id')" [(ngModel)]="addStaffsData.plant_mark"/>
            <span class="tip" [ngClass]="{'tipAnimation': EmptyTip.IDTip}" *ngIf="EmptyTip.IDTip">ID can not be Empty</span>
          </div>
        </div>
        <div class="key-value">
          <div class="key">Working*：</div>
          <div class="value">
            <input type="number" min="0" class="value-input" [ngClass]="{'input-error': EmptyTip.WorkingTip}" [(ngModel)]="addStaffsData.working"/>
            <span class="tip" [ngClass]="{'tipAnimation': EmptyTip.WorkingTip}" *ngIf="EmptyTip.WorkingTip">Working cannot be a negative number</span>
          </div>
        </div>
        <div class="key-value">
          <div class="key">Idle*：</div>
          <div class="value">
            <input type="number" min="0" class="value-input" [ngClass]="{'input-error': EmptyTip.IdleTip}" [(ngModel)]="addStaffsData.ldle"/>
            <span class="tip" [ngClass]="{'tipAnimation': EmptyTip.IdleTip}" *ngIf="EmptyTip.IdleTip">Idle cannot be a negative number</span>
          </div>
        </div>
        <div class="key-value">
          <div class="key">Idle Code：</div>
          <!-- <input class="value" [(ngModel)]="addStaffsData.ldle_code"/> -->
          <select class="value" style="width: 400px; background-color:#e3e3e3;" [disabled]="summaryData.state != 'draft'&&summaryData.state != 'rejected'" [(ngModel)]="addStaffsData.ldle_code">
            <option value=""></option>
            <option value="a">a Breakdown</option>
            <option value="b">b Surplus</option>
            <option value="c">c Awaiting Instruction</option>
            <option value="d">d Assemble/Disassemble</option>
            <option value="e">e Bad Weather</option>
            <option value="f">f Task Completed</option>
            <option value="g">g No Operator</option>
            <option value="h">h Not Required</option>
          </select>
        </div>
        <div class="key-value">
          <div class="key">Ownership*：</div>
          <div class="value">
            <div class="key-input" (click)="inputfocus('ownership');$event.stopPropagation()">
              <input class="value-input"   (ngModelChange)="inputChange($event,'ownership')"  [ngClass]="{'input-error': EmptyTip.Ownership1Tip}" [(ngModel)]="addStaffsData.ownership" readonly/>
              <div class="key-divloading"  *ngIf="ownershipDialog">
                <input class="key-search-input" (click)="$event.stopPropagation()" (input)="inputChange($event.target.value?$event.target.value.toLowerCase():'','ownership')"/>
                <button class="key-divloading-button" *ngFor="let item of ownershipList" (click)="$event.stopPropagation();plantListClick(item,'ownership')">{{item.ownership_name}}</button>
              </div>
            </div>
            <span class="tip" [ngClass]="{'tipAnimation': EmptyTip.Ownership1Tip}"  *ngIf="EmptyTip.Ownership1Tip">Ownership can not be Empty</span>
          </div>
        </div>
      </ng-container>
  
      
      <button class="add" *ngIf="!updataData && (summaryData.state == 'draft'||summaryData.state == 'rejected')" (click)="add()">Add</button>
      <button class="add" *ngIf="updataData && (summaryData.state == 'draft'||summaryData.state == 'rejected')" (click)="updataClick()">Save</button>
      <div class="work-packages-split-view--tabletimeline-content" style="height: 420px; margin: 30px 0;">
        <div class="work-packages-tabletimeline--table-side work-package-table--container __hidden_overflow_container">
          <ng-container *ngIf="pupopType == 'labour_items'">
            <table style=" margin: 0 auto;" class="keyboard-accessible-list generic-table work-package-table">
              <thead class="-sticky">
                <tr style="border-bottom: 1px solid #E7E7E7;"  class="listTitle">
                  <th style="text-align: left;">item</th>
                  <th>Code</th>
                  <th>No.</th>
                  <th>Ownership</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody class="results-tbody work-package--results-tbody" *ngFor="let item of tableList; let i = index">
                <tr [ngStyle]="{'color': updataData ? updataData.id != item.id ? '#e3e3e3':'' : '#000'}">
                  <td style="text-align: left;">{{item.labour_name}}</td>
                  <td>{{item.labour_code}}</td>
                  <td>{{item.number}}.0</td>
                  <td>{{item.ownership}}</td>
                  <td>
                    <div class="tablebutton" *ngIf="summaryData.state == 'draft'||summaryData.state == 'rejected'">
                      <div (click)="editClick(item)" style="background-color: #777777;" [ngStyle]="{'cursor': updataData?'not-allowed':'pointer'}">Edit</div>
                      <div (click)="deleteClick(item,i)" style="background-color: #e15353;" [ngStyle]="{'cursor': updataData?'not-allowed':'pointer'}">Delete</div>
                    </div>
                  </td>
                </tr>
            </table>
          </ng-container>

          <ng-container *ngIf="pupopType == 'plant_items'">
            <table style=" margin: 0 auto;" class="keyboard-accessible-list generic-table work-package-table">
              <thead class="-sticky">
                <tr style="border-bottom: 1px solid #E7E7E7;"  class="listTitle">
                  <th style="text-align: left;">{{title}}</th>
                  <th>ID.</th>
                  <th>Working</th>
                  <th>Idle</th>
                  <th>Idle Code</th>
                  <th>Ownership</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody class="results-tbody work-package--results-tbody" *ngFor="let item of tableList; let i = index">
                <tr [ngStyle]="{'color': updataData ? updataData.id != item.id ? '#e3e3e3':'' : '#000'}">
                  <td style="text-align: left;">{{item.equipment}}</td>
                  <td>{{item.plant_mark}}</td>
                  <td>{{item.working}}</td>
                  <td>{{item.ldle}}</td>
                  <td>{{item.ldle_code}}</td>
                  <td>{{item.ownership}}</td>
                  <td>
                    <div class="tablebutton">
                      <div (click)="editClick(item)" style="background-color: #777777;" [ngStyle]="{'cursor': updataData?'not-allowed':'pointer'}">Edit</div>
                      <div (click)="deleteClick(item,i)" style="background-color: #e15353;" [ngStyle]="{'cursor': updataData?'not-allowed':'pointer'}">Delete</div>
                    </div>
                  </td>
                </tr>
            </table>
          </ng-container>
        </div>
      </div>
    </div>
    <button class="OK" (click)="okclick()">Done</button>
  </div>
</div>
