<div class="documents-container">
    <div [hidden]="!service.projectShow">
        <documents-item
            [projectId]="service.currentId.projectFolder"
            [projectTitle]="currentProject.name">
        </documents-item>
    </div>
    <div [hidden]="!service.userShow">
        <documents-item [projectId]="service.currentId.userFolder"></documents-item>
    </div>
</div>