import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter,ChangeDetectorRef } from '@angular/core';
import { DocumentsService } from '../../services/documents.service';
import { I18nService } from 'core-app/modules/common/i18n/i18n.service';
//url
import { DomSanitizer } from '@angular/platform-browser';


//- declare let iframeLogidoc: any;

@Component({
  selector: 'documents-item',
  templateUrl: './documents-item.component.html',
  styleUrls: ['./documents-item.component.sass']
})
export class DocumentsItemComponent implements OnInit {
  //分页参数
  params: any = {
    page: 1,
    per: 10
  }
  //总条数
  total: any;
  //项目ID
  @Input() projectId: any;
  //项目标题
  @Input() projectTitle: any = 'Private Folders';
  @Input() public url:any = true;
  @ViewChild('uploadFileRef', { static: false }) uploadFileRef: ElementRef;
  @ViewChild('preview', { static: false }) preview: any;
  //toast type
  toastType: any = 'move';
  //mobile item
  operationListItem: any = {};

  items:any={};
  //移动项
  moveItem: any;
  //文件路径
  path: any;
  //移动类型
  moveType: any;
  //重命名的类型
  renameType: any;
  //上传文件的ID
  uploadId: any;
  //当前文件夹ID
  currentFolderId: any;
  //项目数据
    data: any;
  //输入弹框
  inputNameShow = false;
  //输入弹框类型
  inputNameType: any;
  //输入弹框ID
  inputNameId: any;
  //mobile新建上传弹框
  operationModalShow = false;
  //mobile操作文件弹框
  operationListShow = false;
  //移动文件弹框
  operationShow = false;
  //显示loding
  loadingShow = false;
  //显示toast
  toastShow = false;
  //显示预览
  previewShow = false;
  // 预览url
  previewUrl: any = '';
  //旧文件名
  oldName = '';
  //文件图标
  icon: any = {
    0: 'folder',
    zip: 'zip',
    doc: 'doc',
    docx: 'doc',
    pdf: 'pdf',
    jpg: 'jpg',
    jpeg: 'jpg',
    png: 'png'
  }
  //分享弹窗
  shareShow = false;
  shareticket:any;
  sharename:any;
  pdfShow:any=false;
  

  constructor(
    public service: DocumentsService,
    public i18n: I18nService,
    public changeDetectorRef:ChangeDetectorRef,
    private sanitizer: DomSanitizer
  ) {}
  //其他项目的显示
  otherProjectShow() {
    const firstPath = this.projectId === this.currentFolderId;
    if (this.projectTitle === 'Private Folders') {
      this.service.projectShow = firstPath;
    } else {
      this.service.userShow = firstPath;
    }
  }
  //返回上一页
  back() {
    this.getData(
      this.path[ this.path.length - 2 ].id
    );
  }
  //点击路径跳转
  handlePath(id: any) {
    this.getData(id);
  }
  //文件图标
  fileIcon(type: any) {
    return `item-icon documents-${ this.icon[type] || 'file' }`;
  }
  //点击上传按钮
  uploadFile(id: any) {
    this.operationListShow && (this.operationListShow = false);
    this.uploadId = id;
    this.uploadFileRef.nativeElement.click();
  }
  //选择文件
  selectFile(event: any) {
    let files = event.target.files;
    console.log(files)
    let file = new FormData();
    file.append('file', files[0]);
    this.loadingShow = true;
    this.service.uploadFile({
      id: this.uploadId,
      file
    }).subscribe((res: any) => {
      this.uploadFileRef.nativeElement.value = '';
      if (res.result === 'ok') {
        this.loadingShow = false;
        this.getData();
      } else {
        this.loadingShow = false;
      }
    })
  }
  //文件下载
  downLoad(id: any) {
    this.operationListShow && (this.operationListShow = false);
    this.service.downLoad(id).subscribe((res: any) => {
      if (res.result === 'ok') {
        window.open(res.ticket);
      }
    })
  }
  //显示toast
  handleToastShow(type: any) {
    this.toastType = type;
    this.toastShow = true;
    setTimeout(() => {
      this.toastShow = false;
    }, 2000);
  }
  //移动文件
  listItemMove(item: any, moveType: any) {
    this.operationListShow && (this.operationListShow = false);
    this.moveType = moveType;
    this.moveItem = item;
    console.log(item)
    this.operationShow = true;
  }
  //close operation-list
  handleClose() {
    this.operationListShow = false;
  }
  //open operation-modal
  handleOperationModalShow() {
    this.operationModalShow = !this.operationModalShow;
  }
  //open operation-list
  handleOperationListShow(item: any) {
    this.operationListShow = true;
    this.operationListItem = item;
  }
  //打开预览
  handlePreview(id: any) {
    this.operationListShow && (this.operationListShow = false);
    // this.service.downLoad(id).subscribe((res: any) => {
    //   if (res.result === 'ok') {
    //     this.preview.openPreview(res.ticket);
    //   }
    // })
    this.previewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      `/pdfjs/web/viewer.html?file=${ encodeURIComponent(
        `${ window.location.origin }/api/v3/logical_docs/doc/${ id }/pdf`
      ) }`
    );
    this.previewShow = true;
  }
  //打开分享弹窗
  shareLoding(id:any,name:any){
    console.log(name);
    
    this.operationListShow && (this.operationListShow = false);
    this.service.downLoad(id).subscribe((res: any) => {
      if (res.result === 'ok') {
        this.shareticket = res.ticket
        this.sharename = name;
        this.shareShow = true;
      }
    })
  }

  //编辑
  editLoding(id:any,filename:any){
    this.pdfShow = true
    this.loadingShow = true
    let url:any = this.sanitizer.bypassSecurityTrustResourceUrl(`${ window.location.origin }/api/v3/logical_docs/doc/${ id }/pdf`);
    this.items = {
      url:url.changingThisBreaksApplicationSecurity,
      id:id,
      name:filename
    }
  }
  showLoad(){
    this.loadingShow = false;
    this.changeDetectorRef.detectChanges();
    jQuery('#top-menu').css('z-index', 20);
  }
  shareclose(){
    this.shareShow = false;
    this.changeDetectorRef.detectChanges();
  }
  //打开loading
  openloading(){
    this.loadingShow = true;
    this.changeDetectorRef.detectChanges();
  }
  //关闭loading
  colseloading(){
    this.loadingShow = false;
    this.changeDetectorRef.detectChanges();
    jQuery('#top-menu').css('z-index', 20);
  }
  pdfclose(){
    this.pdfShow = false;
    this.loadingShow = false;
    this.handleToastShow('save');
    this.changeDetectorRef.detectChanges();
  }
  //close input-name
  handleCancel() {
    this.oldName = '';
    this.inputNameShow = false;
  }
   //输入弹框点击确认处理
  handleConfirm(folder_name: any) {
    if (this.inputNameType === 'createFolder') {
      //新建文件夹
      this.service.createFolder({
        folder_name,
        id: this.inputNameId
      }).subscribe((res: any) => {
        if (res.result === 'ok') {
          this.inputNameShow = false;
          this.getData();
        }
      })
    } else if (this.inputNameType === 'rename') {
      //重命名
      this.service.rename({
        folder_name,
        id: this.inputNameId,
        renameType: this.renameType
      }).subscribe((res: any) => {
        if (res.result === 'ok') {
          this.inputNameShow = false;
          this.getData();
        }
      })
    }
  }
  //关闭遮罩层
  closeMask() {
    if (!this.loadingShow) {
      this.inputNameShow = false;
      this.operationListShow = false;
      this.operationShow = false;
      this.operationModalShow = false;
      this.previewShow = false;
      this.shareShow = false;
      this.pdfShow = false
    }
  }
  //移动文件取消
  handleOperationCancel() {
    this.service.selectFolderId = '';
    this.operationShow = false;
  }
  //移动文件确认
  handleOperationConfirm() {
    this.service.showOperationLoading(true);
    this.service.move(
      this.moveItem,
      this.moveType
    ).subscribe((res: any) => {
      if (res.result === 'ok') {
        this.service.showOperationLoading(false);
        this.service.selectFolderId = '';
        this.operationShow = false;
        this.handleToastShow(this.moveType);
        //刷新所有项目
        this.service.subjectSend();
      }
    },
    () => {
      //错误处理
      this.service.showOperationLoading(false);
    })
  }
  //打开输入弹框
  handleInputName({ id, type, name, file_name }: any, inputType: any) {
    this.operationListShow && (this.operationListShow = false);
    this.oldName = this.service.isFolder(type) ? name : file_name;
    this.renameType = type;
    this.inputNameId = id;
    this.inputNameType = inputType;
    this.inputNameShow = true;
  }
  //切换分页
  paginationChange(page: any) {
    this.params.page = page;
    this.getData();
  }
  //获取列表数据
  getData(id?: any) {
    id && (this.params.page = 1);
    if (this.projectId) {
      this.loadingShow = true;
      this.service.getProjectDoc(
        id || this.currentFolderId || this.projectId,
        this.params
      ).subscribe((res: any) => {
        this.loadingShow = false;
        if (res.result === 'ok') {
          this.data = res.data;
          this.path = res.path;
          this.total = res.total;
          //当前文件夹ID
          if (id) {
            this.currentFolderId = id;
          }
          this.otherProjectShow();
        }
      })
    }
  }
  //进入文件夹
  enterFolder({ id, type }: any) {
    this.service.isFolder(type) && this.getData(id);
  }
  ngOnInit() {
    this.currentFolderId = this.projectId;
    this.getData();
    //订阅
    this.service.subjectGet().subscribe(() => {
      this.getData();
    })
  }

  //- ngAfterViewInit(): void {
  //   iframeLogidoc.onload = function() {
  //     //这里获取数据

  //     //成功回调如下
  //     iframeLogidoc.window.autoLogin();
  //   }
  // }
}