import {Component, ElementRef, OnDestroy, OnInit,NgZone} from '@angular/core';
import {TypeBannerService} from 'core-app/modules/admin/types/type-banner.service';
import {I18nService} from 'core-app/modules/common/i18n/i18n.service';
import {NotificationsService} from 'core-app/modules/common/notifications/notifications.service';
import {ExternalRelationQueryConfigurationService} from 'core-components/wp-table/external-configuration/external-relation-query-configuration.service';
import {DomAutoscrollService} from 'core-app/modules/common/drag-and-drop/dom-autoscroll.service';
import {DragulaService} from 'ng2-dragula';
import {ConfirmDialogService} from 'core-components/modals/confirm-dialog/confirm-dialog.service';
import {Drake} from 'dragula';

import {randomString} from 'core-app/helpers/random-string';
import {GonService} from "core-app/modules/common/gon/gon.service";
import {DynamicBootstrapper} from "core-app/globals/dynamic-bootstrapper";
import {takeUntil} from "rxjs/operators";
import {componentDestroyed} from "ng2-rx-componentdestroyed";

export type TypeGroupType = 'attribute'|'query';

export interface TypeFormAttribute {
  key:string;
  translation:string;
  is_cf:boolean;
}

export interface TypeGroup {
  /** original internal key, if any */
  key:string|null|undefined;
  /** Localized / given name */
  name:string;
  attributes:TypeFormAttribute[];
  query?:any;
  type:TypeGroupType;
  readonly_status_ids:any;
  principal:any;
  statuses:any;
}

export interface ValidStatus {
  id:number;
  name:string;
  isChecked:boolean;
}

export interface MembersData {
  id:number;
  name:string;
}

export interface SettingData {
  key:string;
  principal:any;
  statuses:ValidStatus[];
}

@Component({
  selector: 'admin-type-form-configuration',
  templateUrl: './type-form-configuration.html',
  styleUrls: ['./type-form-configuration.sass'],
  providers: [
    TypeBannerService,
  ]
})
export class TypeFormConfigurationComponent implements OnInit, OnDestroy {

  public text = {
    drag_to_activate: this.I18n.t('js.admin.type_form.drag_to_activate'),
    reset: this.I18n.t('js.admin.type_form.reset_to_defaults'),
    label_group: this.I18n.t('js.label_group'),
    new_group: this.I18n.t('js.admin.type_form.new_group'),
    label_inactive: this.I18n.t('js.admin.type_form.inactive'),
    custom_field: this.I18n.t('js.admin.type_form.custom_field'),
    add_group: this.I18n.t('js.admin.type_form.add_group'),
    add_table: this.I18n.t('js.admin.type_form.add_table'),
  };

  private autoscroll:any;
  private element:HTMLElement;
  private form:JQuery;

  public groups:TypeGroup[] = [];
  public inactives:TypeFormAttribute[] = [];

  //状态选择数组
  public validStatuses:ValidStatus[] = [];
  //用户数组
  public membersData:MembersData[] = [];
  //设置过的权限数组
  public settingData:SettingData[] = [];

  private attributeDrake:Drake;
  private groupsDrake:Drake;

  private no_filter_query:string;

  public principal: any;


  public oldPrincipal: any = {
    id: "",
    type: "",
  };

  public principalArray: Array<any> = []

  public isModals: boolean = false;

  public groupName: string;

  constructor(private elementRef:ElementRef,
              private I18n:I18nService,
              private Gon:GonService,
              private zone: NgZone,
              private dragula:DragulaService,
              private confirmDialog:ConfirmDialogService,
              private notificationsService:NotificationsService,
              private externalRelationQuery:ExternalRelationQueryConfigurationService) {
  }
  ngOnInit():void {
    // Hook on form submit
    this.element = this.elementRef.nativeElement;
    this.no_filter_query = this.element.dataset.noFilterQuery!;
    this.form = jQuery(this.element).closest('form');
    this.form.on('submit.typeformupdater', () => {
      this.updateHiddenFields();
      return true;
    });

    // Setup groups
    this.dragula.createGroup('groups', {
      moves: (el, source, handle:HTMLElement) => handle.classList.contains('group-handle')
    });

    // Setup attributes
    this.dragula.createGroup('attributes', {
      moves: (el, source, handle:HTMLElement) => handle.classList.contains('attribute-handle')
    });

    this.dragula.dropModel("attributes")
      .pipe(
        takeUntil(componentDestroyed(this))
      )
      .subscribe((event) => {
        //console.log(event);
      });

    // Get attribute id
    this.groups = JSON.parse(this.element.dataset.activeGroups!);
    this.inactives = JSON.parse(this.element.dataset.inactiveAttributes!);
    this.settingData = JSON.parse(this.element.dataset.settingData!);

    for(let i=0;i<this.groups.length;i++){
      this.groups[i].principal={};
      this.groups[i].statuses=[];
      for(let j=0;j<this.settingData.length;j++){
        if(this.groups[i].key==this.settingData[j].key||this.groups[i].name==this.settingData[j].key){
          this.groups[i].principal=this.settingData[j].principal;
          this.groups[i].statuses=this.settingData[j].statuses;
          let readonly_status_ids:any=[];
          this.settingData[j].statuses.forEach((item:any)=>{
            　　readonly_status_ids.push(item.id);
          })
          this.groups[i].readonly_status_ids=readonly_status_ids;
        }
      }
    }

    //console.log('this.groups: ', this.groups);


    let validStatuses: any = JSON.parse(this.element.dataset.validStatuses!);
    validStatuses.forEach((item: any) => {
      item.isChecked = false;
    });
    this.validStatuses = [...validStatuses];
    let membersData: any = JSON.parse(this.element.dataset.membersData!);
    Object.keys(membersData).forEach((key) => {
      if (membersData[key].length > 0) {
        let obj: any = {
          label: "",
          list: [],
        };
        if(key==='users'){
          obj.label='User';
        }else  if(key==='groups'){
          obj.label='Group';
        }else  if(key==='roles'){
          obj.label='Role';
        }
        membersData[key].forEach((item: any) => {
          if (!this.oldPrincipal.id) {
            this.oldPrincipal = {
              id: item.id,
              type: obj.label,
            };
          }
          obj.list.push(item);
        });
        this.membersData.push(obj);
      }
    });

    // Setup autoscroll
    const that = this;
    this.autoscroll = new DomAutoscrollService(
      [
        document.getElementById('content-wrapper')!
      ],
      {
        margin: 25,
        maxSpeed: 10,
        scrollWhenOutside: true,
        autoScroll: function (this:any) {
          const groups = that.groupsDrake && that.groupsDrake.dragging;
          const attributes = that.attributeDrake && that.attributeDrake.dragging;
          return this.down && (groups || attributes);
        }
      });
  }

  ngOnDestroy() {
    // Nothing to do
  }

  public deactivateAttribute(attribute:TypeFormAttribute) {
    this.updateInactives(this.inactives.concat(attribute));
  }

  public addGroupAndOpenQuery():void {
    let newGroup = this.createGroup('query');
    this.editQuery(newGroup);
  }

  public editQuery(group:TypeGroup) {
    // Disable display mode and timeline for now since we don't want users to enable it
    const disabledTabs = {
      'display-settings': I18n.t('js.work_packages.table_configuration.embedded_tab_disabled'),
      'timelines': I18n.t('js.work_packages.table_configuration.embedded_tab_disabled')
    };

    this.externalRelationQuery.show(
      JSON.parse(group.query),
      (queryProps:any) => group.query = JSON.stringify(queryProps),
      disabledTabs
    );
  }

  public deleteGroup(group:TypeGroup) {
    if (group.type === 'attribute') {
      this.updateInactives(this.inactives.concat(group.attributes));
    }

    this.groups = this.groups.filter(el => el !== group);

    return group;
  }

  public createGroup(type:TypeGroupType, groupName:string = '') {
    let group:TypeGroup = {
      type: type,
      name: '',
      key: null,
      query: this.no_filter_query,
      attributes: [],
      readonly_status_ids:[],
      principal:{},
      statuses:[]
    };

    this.groups.unshift(group);
    return group;
  }

  public resetToDefault($event:Event):boolean {
    this.confirmDialog
      .confirm({
        text: {
          title: this.I18n.t('js.types.attribute_groups.reset_title'),
          text: this.I18n.t('js.types.attribute_groups.confirm_reset'),
          button_continue: this.I18n.t('js.label_reset')
        }
      })
      .then(() => {
      this.form.find('input#type_attribute_groups').val(JSON.stringify([]));

      // Disable our form handler that updates the attribute groups
      this.form.off('submit.typeformupdater');
      this.form.trigger('submit');
    });

    $event.preventDefault();
    return false;
  }

  private updateInactives(newValue:TypeFormAttribute[]) {
    this.inactives = [...newValue].sort((a, b) => a.translation.localeCompare(b.translation));
  }

  private updateHiddenFields() {
    //console.log('this.groups',this.groups);
    const hiddenField = this.form.find('.admin-type-form--hidden-field');
    hiddenField.val(JSON.stringify(this.groups));
  }
  //保存权限修改
  public save() {
    this.dataJudgment();
    this.close();
  }
  //权限数据处理
  public dataJudgment() {
    let arr: any = this.principal.split("+");
    let principal = null

      principal = this.principalArray
  

    let readonlyStatusIds: any = [];
    let statusesArr:any=[];

    for (let i = 0; i < this.validStatuses.length; i++) {
      if (this.validStatuses[i].isChecked) {
        readonlyStatusIds.push(this.validStatuses[i].id);
        statusesArr.push(this.validStatuses[i]);
      }
    }
    console.log(principal)

    for (let i = 0; i < this.groups.length; i++) {
      if (this.groups[i].name==this.groupName) {
          this.groups[i].principal = principal;
          this.groups[i].readonly_status_ids = readonlyStatusIds;
          this.groups[i].statuses=statusesArr;
      }
    }
  }
  //关闭弹窗
  public close() {
    this.isModals = false;
    let validStatuses: any = JSON.parse(this.element.dataset.validStatuses!);
    validStatuses.forEach((item: any) => {
      item.isChecked = false;
    });
    this.zone.run(() => {
      this.validStatuses = [...validStatuses];
      //console.log("this.validStatuses", this.validStatuses);
      //console.log("this.groups", this.groups);
     });
     console.log('this.groups: ', this.groups);
  }
  //加载权限修改数据
  public isPrincipalIncludes() {
    if(!this.principal){
      return false
    }
    let arr: any = this.principal.split("+");
    let principal = {
      type: arr[0],
      id: arr[1],
      name: arr[2]
    };
    for(let item of this.principalArray) {
      if(item.name === principal.name) {
        return true
      }
    }
    return false
  }
  public addprincipal() {
    let arr: any = this.principal.split("+");
    let principal = {
      type: arr[0],
      id: arr[1],
      name: arr[2]
    };
    for(let item of this.principalArray) {
      if(item.name === principal.name) {
        return
      }
    }
    this.principalArray.push(principal)
  }
  deleteItem(index:any) {
    this.principalArray.splice(index, 1)
  }
  public editAttribute(group: any) {
    console.log(group)

    
    if(group.principal.length) {
      this.principal = ''
      this.principalArray =  group.principal
    } else if(group.principal.hasOwnProperty('id')) {
      console.log(Object.entries(group.principal))
      if(Object.entries(group.principal).length == 0){
        this.principalArray = []
      } else {

        this.principal = group.principal.type + "+" + group.principal.id + '+' + group.principal.name;
        this.principalArray = [group.principal]
      }
    }else {

        this.principalArray = []
   
    }
    if (group.statuses.length>0) {
      let oldValidStatuses: any = [...this.validStatuses];
      for (let i = 0; i < oldValidStatuses.length; i++) {
        group.statuses.forEach((item: any) => {
          if (oldValidStatuses[i].id == item.id) {
            oldValidStatuses[i].isChecked = true;
          }
        });
      }
      this.validStatuses = oldValidStatuses;
    }
    this.groupName = group.name;
    this.isModals = true;
    //console.log("principal", this.principal);
    //console.log("validStatuses", this.validStatuses);
  }
}

DynamicBootstrapper.register({ cls: TypeFormConfigurationComponent, selector: "admin-type-form-configuration" });
