import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { I18nService } from 'core-app/modules/common/i18n/i18n.service';
import { Photo_logService } from '../../services/photo_log.service';
@Component({
  selector: 'photo_log_progress-detail',
  templateUrl: './photo_log_progress-detail.component.html',
  styleUrls: ['./photo_log_progress-detail.component.sass']
})
export class Photo_log_progressDetailComponent implements OnInit {
  showDetail: boolean = false
  hasother: boolean = false
  saveimg:any =[]
  showloading: boolean = false
  public getsingledata: any
  public multitoshowEdit:boolean
  public hideEditbtn:boolean
  public saveitemId:any
  public isselect:boolean=false
  public items:any
  public userid:any
  public projectId:any
  @Input() ProgressId:any
  public pageCount:Number
  public detail:any
  public savenowPage:any
  public pageSizenum:any
  @Output() backToTable:EventEmitter<any> = new EventEmitter()
  constructor(
    public service: Photo_logService,
    public i18n: I18nService
  ) { }

  ngOnInit() {
    this.userid = jQuery('meta[name=current_user]').data('id')
    this.projectId = jQuery('meta[name=current_project]').data('projectId')
    this.service.getProgressDetail(3).subscribe ((res:any) => {
        this.detail = res
    })
    this.getPicture(1)
  }
  tohideDetail(){
    this.showDetail = false
    this.hasother = false
    this.saveimg=[]
  }
  toshowEditpage(data:any){
    this.Loading()
    //单选
    this.getsingledata = data
      this.multitoshowEdit=true

  }
  toMushpage(e:any){
    this.savenowPage = e
    this.getPicture(e)
  }
  prepage(){
    if(this.savenowPage==1){
      this.getPicture(1)
    }else{
      this.getPicture(  this.savenowPage-1)
      this.savenowPage--
    }
  }
  nextpage(){
    if(this.savenowPage == this.pageCount) {
      return
    }
    this.getPicture(  this.savenowPage+1)
    this.savenowPage++
  }
  Loading(){
    this.showloading=true
    setTimeout(() => {
      this.showloading=false
    }, 1000);
  }
    //进入详情页
    toDeatil(e:any,user:any,url:any){
      if(!this.isselect&&this.userid==user){
      console.log(this.userid,user,'这是true')
        this.Loading()
      this.showDetail = true
      this.saveitemId = e
      console.log(this.saveitemId)
      this.hideEditbtn = true
    } else if(!this.isselect&&this.userid!=user){
      this.Loading()
      this.saveitemId = e
      this.showDetail = true
      this.hideEditbtn=false
    }
    }
    tohideEditpage(){
      this.multitoshowEdit=false
      this.isselect=false
      this.hasother = false
      this.saveimg=[]
    }
    torefresh(){
      this.getPicture(1)
      this.Loading()
      this.isselect = false
      this.hasother = false
      let info = I18n.t('js.photo-log.savesuccess')
      alert(info)
    }
    getPicture(e:any){
      this.service.getAlbumbyday({whether_all:false,project_id:this.projectId,page:e, progress_id: this.ProgressId}).subscribe((res:any)=>{
        this.items = res.position
        this.pageCount = Math.ceil(res.count/3)
        this.pageSizenum = []
        for(let i=1;i<=this.pageCount;i++){
          this.pageSizenum.push(i)
        }
        this.savenowPage=e
        this.items = res.position
        setTimeout(() => {
          this.showloading=false
        }, 1000);
      })
    }
    back() {
      this.backToTable.emit()

    }
}
