import { Component, OnInit ,Input, Output, EventEmitter} from '@angular/core';
import { DocumentsService } from '../../services/check.lists.service';
import { input } from 'reactivestates';
import * as moment from 'moment';


declare var laydate: any;

@Component({
  selector: 'check_lists-issue',
  templateUrl: './check_lists-issue.component.html',
  styleUrls: ['./check_lists-issue.component.sass']
})
export class Check_listsIssueComponent implements OnInit {

  @Input() public userdata:any
  @Input() public payload:any
  @Input() public schema:any
  @Input() public byuserdata:any
  @Output() close = new EventEmitter();


  public text:any
  public estimatedTime = 0

  constructor(
    public service: DocumentsService,
  ) { }

  ngOnInit() {
    console.log(this.payload);
    console.log(this.schema);
    console.log(this.userdata);
    let ada = this.schema.priority._embedded.allowedValues.find((res:any)=>{
      return res.isDefault && res
    })
    this.text = ada
    console.log(ada);
    

  }

  ngAfterViewInit(){
    laydate.render({
      elem: '#test1',
      done:(value:any)=>{
        console.log(value);
        if(value)this.payload.startDate = value
      }
    });
    laydate.render({
      elem: '#test2',
      done:(value:any)=>{
        if(value)this.payload.dueDate = value
      }
    });
  }

  assignedby(e:any){
    console.log(e);
    let inputdata = this.schema.priority._embedded.allowedValues.find((res:any)=>{return res.id == e})
    this.payload._links.priority.href = inputdata._links.self.href
    this.payload._links.priority.title = inputdata._links.self.title
  }


  create(){
    console.log(this.payload);
    this.service.newTaskeissus(this.payload).subscribe((res:any)=>{
      console.log(res);
      this.cleon()
    })
  }

  public parser(value:any) {
    console.log(moment.duration(value, 'hours').toISOString());
    this.payload.estimatedTime = moment.duration(value, 'hours').toISOString()
    // if (!isNaN(value)) {
    //   let floatValue = parseFloat(value);
    //   return moment.duration(floatValue, 'hours');
    // }

    // return value;
  }

  cleon(){
    this.close.emit()
  }

}
