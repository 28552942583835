import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class Photo_logService {
    //请求头
    requestHeader: any = {
      'X-Authentication-Scheme': 'Session',
      'X-Requested-With': 'XMLHttpRequest'
    };
    //API前缀
    apiPrefix: any = '/api/v3';
    constructor(private http: HttpClient) {
      
    }
    public project_id = Number(document.getElementsByName('current_project')[0].getAttribute('data-project-id'))
    // photo_log按照日排序
  getAlbumbyday(data:any){
    return this.http.post(
      `${this.apiPrefix}/photo_log_data/computer_my_album_day`,{...data,
        project_id: this.project_id
      } ,
      {
        withCredentials:true,
        headers:this.requestHeader
      }
    )
  }

  //photo_log详情页面数据
  getDetailpage(data:any){
    return this.http.get(`/api/v3/photo_log_data/${data}?project_id=${this.project_id}`)
  }

    //删除图片接口
    deletePhtotLog(e:any){
      console.log(e,'hahas')
      let reqheader = this.requestHeader
      reqheader[ 'Content-Type'] = 'application/json'
      return this.http.delete(`${this.apiPrefix}/photo_log_data/delete_photo?photo_log_id=${e}&project_id=${this.project_id}`,{ withCredentials:true,headers:this.requestHeader})
    }

    //编辑接口
    editPhotoLog(data:Array<any>){
      return this.http.patch(
        `${this.apiPrefix}/photo_log_data/photo_edit`,{data: data, project_id: this.project_id},
        {
          withCredentials:true,
          headers:this.requestHeader
        }
      )
    }

    //获取楼层接口
    getLocation(id:any,data:any){
      return this.http.post(`${ this.apiPrefix }/projects/${id}/work_packages/form`,{...data,
        project_id: this.project_id
      },{withCredentials: true,headers: this.requestHeader});}

    getType(data:any){
      return this.http.get(`/api/v3/photo_log_data/type?project_id=${data}`)
    }

    relationTask(data:any){
      return this.http.post(`${this.apiPrefix}/photo_log_data/release_work_package`,{...data, project_id: this.project_id},
        {withCredentials: true,headers: this.requestHeader}
      )
    }

    getProgressList(page:any) {
      return this.http.get(`${this.apiPrefix}/projects/1/photo_log_progress?page=${page}`)

    }

    addProgress(data: any){
      return this.http.post(`${this.apiPrefix}/projects/1/photo_log_progress`, {data: data} , {
       headers: {
        'Content-Type': 'application/json',
        ...this.requestHeader
       } 
      })
    }
    
    addToProgress(photo_logs:any, progress_id:any) {
      return this.http.post(`${this.apiPrefix}/projects/1/photo_log_progress/${progress_id}/relation_photo_logs`, {
        "data":{
          photo_logs
        }
    },  {withCredentials: true,headers: this.requestHeader})
    }

    getProgressDetail(progress_id:any) {
      return this.http.get(`${this.apiPrefix}/projects/1/photo_log_progress/${progress_id}`)
    }
    getToken() {
      return this.http.post(`${this.apiPrefix}/photo_log_data/photographers`, {
        project_id: this.project_id
      },  {withCredentials: true,headers: this.requestHeader})
    }
}
