import { NgModule } from '@angular/core';
import { Ng2StateDeclaration, UIRouterModule } from '@uirouter/angular';
import {labourReturnComponent} from './labour_return.component'
import {labourReturnListComponent} from './component/labour_return-list/labour_return-list.component'
import {labourReturnEditComponent} from './component/labour_return-edit/labour_return-edit.component'
import {OpenprojectCommonModule} from "core-app/modules/common/openproject-common.module";
import {OpenprojectWorkPackagesModule} from 'core-app/modules/work_packages/openproject-work-packages.module';

import {labourReturnTableComponent} from './component/labour_return-table/labour_return-table'
import {Labour_returnMapComponent} from './component/labour_return-map/labour_return-map.component'

export const labour_return_ROUTES: Ng2StateDeclaration[] = [
    {
        name: 'labour_returns',
        url: '/labour_returns',
        parent: 'root',
        component: labourReturnComponent
      },
      {
        name: 'labour_return_mapping',
        url: '/labour_return_mapping',
        parent: 'root',
        component: Labour_returnMapComponent
      }
];

@NgModule({
  imports: [
    UIRouterModule.forChild({ 
      states: labour_return_ROUTES
    }),
    OpenprojectWorkPackagesModule,
    OpenprojectCommonModule,

  ],
  declarations: [
    labourReturnComponent,
    labourReturnListComponent,
    labourReturnEditComponent,
    Labour_returnMapComponent,
    labourReturnTableComponent
  ]
})
export class labourReturnModule { }
