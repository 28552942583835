<div class="work-packages-split-view--tabletimeline-content" style="height: 500px; margin-top: 30px;">
  <div class="work-packages-tabletimeline--table-side work-package-table--container __hidden_overflow_container">

    <table style=" margin: 0 auto;" class="keyboard-accessible-list generic-table work-package-table">
      <thead class="-sticky">
        <tr style="border-bottom: 1px solid #E7E7E7;"  class="listTitle">
          <th>Section</th>
          <th>Portion</th>
          <th>Location</th>
          <ng-container *ngIf="divClick == 'activity'">
            <th>Activity</th>
            <th>Labour/People</th>
            <th>Plant/Equipment</th>
          </ng-container>
          <ng-container *ngIf="divClick == 'labour'">
            <th>Item</th>
            <th>No.</th>
            <th>Ownership</th>
          </ng-container>
          <ng-container *ngIf="divClick == 'plant'">
            <th>Plant / Equipment</th>
            <th>ID.</th>
            <th>Working</th>
            <th>Idle</th>
            <th>Idle Code</th>
            <th>Ownership</th>
          </ng-container>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody class="results-tbody work-package--results-tbody" *ngFor="let item of tableList">  
        <tr>
          <td>{{item.section_name}}</td>
          <td>{{item.protion_name}}</td>
          <td>{{item.location}}</td>
          <ng-container *ngIf="divClick == 'activity'">
            <td class="activity">{{item.activity}}</td>
            <td>
              <div class="labur_plant">
                {{item.labur_number}}.0
                <div class="labur_plant-item">
                  <span *ngFor="let laburItem of item.labours">({{laburItem.number}})&nbsp;&nbsp;{{laburItem.labour_name}}&nbsp;&nbsp;{{laburItem.ownership}}</span>
                </div>
              </div>
            </td>
            <td>
              <div class="labur_plant">
                {{item.plant_working}}.0/{{item.plant_ldle}}.0
                <div class="labur_plant-item">
                  <span *ngFor="let laburItem of item.plants">({{laburItem.working}}/{{laburItem.ldle}})&nbsp;&nbsp;{{laburItem.equipment}}&nbsp;&nbsp;{{laburItem.plant_mark}}&nbsp;&nbsp;{{laburItem.ldle_code[0]}}&nbsp;&nbsp;{{laburItem.ownership}}</span>
                </div>
              </div>
            </td>
            <td>
              <div class="tablebutton" *ngIf="summaryData.state == 'draft'||summaryData.state == 'rejected'">
                <div (click)="editClick(item)" style="background-color: #777777;">Edit</div>
                <div (click)="deleteClick(item)" style="background-color: #e15353;">Delete</div>
              </div>
              <div class="tablebutton" *ngIf="summaryData.state != 'draft'&&summaryData.state != 'rejected'">
                <div (click)="editClick(item)" style="background-color: #00a7ec">View</div>
              </div>
            </td>
          </ng-container>
          <ng-container *ngIf="divClick == 'labour'">
            <td>{{item.labour_name}}</td>
            <td>{{item.number}}.0</td>
            <td>{{item.ownership}}</td>
          </ng-container>
          <ng-container *ngIf="divClick == 'plant'">
            <td>{{item.equipment}}</td>
            <td>{{item.plant_mark}}</td>
            <td>{{item.working}}.0</td>
            <td>{{item.ldle}}.0</td>
            <td>{{item.ldle_code}}</td>
            <td>{{item.ownership}}</td>
          </ng-container>
        </tr>
      </tbody>
    </table>
    
  </div>
</div>

<!-- modulelist 分页 -->
<!-- <ng-container >
  <div class="pagination" style="position: absolute; bottom: 10px;">
    <ul class="pagination--items">
      <li class="pagination--item" *ngIf="modulepage-1 != 0"><a href="" (click)='modulego("prev")' id="pagination--prev-link" rel="prev start" role="link" aria-label="Back to the previous page">&lt;</a></li>
      <li *ngFor="let item of modulesize;let i = index" class="pagination--item pagination-number" ng-reflect-klass="pagination--item pagination-nu" ng-reflect-ng-class="[object Object]">
        <a [ngClass]="{peracss:modulepage==i+1}" (click)='modulelistpage(i+1)' href="" rel="next" role="link">{{i+1}}</a></li>
      <li class="pagination--item" *ngIf="modulepage != modulesize.length"><a (click)='modulego("next")' class="pagination--next-link" href="" id="pagination--next-link" rel="next" role="link" aria-label="Forward to the next page">&gt;</a></li>
    </ul>
  </div>
</ng-container> -->
