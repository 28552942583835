import { AfterViewInit, Component, ElementRef, OnInit, Query, ViewChild } from '@angular/core';
import { extend } from 'jquery';
import { EditFieldComponent } from '../../edit-field.component';
declare let laydate: any;
@Component({
  selector: 'app-field-dateTime',
  templateUrl: './field-dateTime.component.html',
  styleUrls: ['./field-dateTime.component.sass']
})
export class FieldDateTimeComponent extends EditFieldComponent  implements OnInit, AfterViewInit {

  @ViewChild('getInput', { static: true }) input: ElementRef
  @ViewChild('DateTime', {static: true}) Date: ElementRef
  @ViewChild('Time', {static: true}) Time: ElementRef
  public laydate:any
  public date: any
  public time: any 
  public dateData: any
  public timeData: any
  ngOnInit() {
    console.log(this.value)
    const focusOutEvnet = (e:any) => {
      console.log(e.target, this.input.nativeElement )
        if(e.target !== this.input.nativeElement){
          this.handler.onFocusOut()
        }
        focusOutEvent
        document.removeEventListener('click',focusOutEvnet)
    }
    if(this.value){
    let  data = this.value.split(' ')
      this.dateData = data[0]
      this.timeData = data[1]
    } else {
      this.dateData = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      this.timeData = '00:00:00'
    }
    let lang = 'cn'
    if(this.I18n.locale == 'zh-CN' || this.I18n.locale == 'zh-TW') {
      lang = 'cn'
    } else {
      lang = 'en'
    }
 this.date = laydate.render({
      elem: this.Date.nativeElement,
      position: 'static',
      value: this.dateData,
      zIndex: 99999999,
      btns: [],
      lang,
      change: (value:any) => {
        this.dateData = value
        this.value =  this.dateData + ' ' + this.timeData
      }
    });
 this.time = laydate.render({
      elem: this.Time.nativeElement,
      type: 'time',
      value: this.timeData,
      position:'static',
      zIndex: 99999999,
      lang,
      btns: ['confirm'],
      change: (value: any)=> {
        this.timeData = value
        this.value =  this.dateData + ' ' + this.timeData
        document.removeEventListener('click',focusOutEvnet)
      }
    });
    
    let focusOutEvent = document.addEventListener('click', focusOutEvnet)
   jQuery('.laydate-footer-btns').click(() => {
    this.handler.onFocusOut()
    document.removeEventListener('click',focusOutEvnet)
   })
  }
  

  ngAfterViewInit() {
    console.log(jQuery('.layui-laydate-main'))
    jQuery('.layui-laydate-main').css('width', '245px')
    jQuery('.layui-laydate-main')
    document.getElementsByClassName('laydate-time-list')
    console.log(this.Time)
    this.Time.nativeElement.children[0].children[0].children[1].children[1].children[2].remove()
    this.Time.nativeElement.children[0].children[0].children[1].children[1].children[0].style.width = '50%'
    this.Time.nativeElement.children[0].children[0].children[1].children[1].children[1].style.width = '50%'
    setTimeout(()=> {
      this.input.nativeElement.focus()
      console.log(this.Date.nativeElement, this.Time.nativeElement)
    },10) 
  }

}
