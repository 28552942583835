<div class="content">
    <div (click)="downloadPDF()" class="download" >{{i18n.t("js.check-lists.pdf.downloadPDF")}}</div>
    <div  class="back" (click)="back()">{{i18n.t("js.check-lists.pdf.exitpreview")}}</div>
    <div class="pdfcontent"  id="pdfContent">
        <!-- 页眉 -->
        <div class="header" id="header">
            {{saveRes.no}}
            <b>Chunwo</b>
            <hr>
        </div>
        <div class="title">
            <h3 >CHECKLIST DETAIL</h3>
            <b>Chunwo</b>
        </div>
        <a [href]="jumpUrl" style="color:#1d8fc3;margin-left: 10px;font-size: 23px;" id="linka">{{saveRes.name}}</a>

        <!-- 时间 -->
        <div class="time">
            <ul>
                <li class="completed">COMPLETED</li>
                <li>CREATED <br>{{saveRes.created_at|date}}</li>
                <!-- <li>SCHEDULED <br>jiashuju</li> -->
                <li>COMPLETED <br>{{saveRes.expected_finish_at|date}} </li>
            </ul>
        </div>
        
        <!-- 详细内容 -->
        <div class="detailcontent">
            <div class="detailtitler">
           <table >
               <tr><td class="left">Type</td><td class="right"><b>{{saveRes.check_list_type.name}}</b></td></tr>
               <tr><td class="left">Description</td><td class="right">{{saveRes.description}}</td></tr>
               <tr><td class="left">Location</td><td class="right">{{locations}}</td></tr>
               <tr><td class="left">Creator</td><td class="right">{{saveRes.created_by_user}}</td></tr>
               <tr><td class="left">Assigned to</td><td class="right">{{assginUser}}</td></tr>
               <!-- <tr><td class="left">Section Assignees</td><td class="right">jiashujuu</td></tr> -->
           </table>
           <!-- <ul >
               <li><b>SETIONS</b>  <br><i>fake</i></li>
               <li><b>ITEMS</b>   <br><i>fake</i></li>
               <li><b>ISSUES</b> <br><i>fake</i></li>
               <li><b>CONFORMING</b> <br><i>fake</i></li>
               <li><b>NON-CONFORMING</b> <br><i>fake</i></li>
               <li><b>N/A</b> <br><i>fake</i></li>
               <li><b>TO BE ANSWERED</b> <br><i>fake</i></li> 
           </ul> -->
            </div>
        </div>

        <!-- 表单内容 -->
        <div class="formcontent">
            <ul>
                <li *ngFor="let item of saveRes.check_list_metum.parts;let i = index">
                 <div class="formtitle"> 
                   <div class="titlecontent nocut">  <b >  {{i+1}}.{{item.title}}</b>  <span> 
                    <svg t="1595829816504"   class="icon iconcompele" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2937" width="64" height="64"><path d="M512.186242 6.139837c-278.100856 0-504.362043 226.261187-504.362043 504.362043S234.085386 1014.863922 512.186242 1014.863922s504.362043-226.261187 504.362043-504.362043S790.287097 6.139837 512.186242 6.139837zM794.409998 413.799444 466.418872 750.040465c-7.838525 8.023744-18.590404 12.579503-29.816073 12.642948l-0.227174 0c-11.141758 0-21.834284-4.433986-29.715789-12.314467L230.289943 574.038962c-16.420995-16.396435-16.420995-43.013653 0-59.430554 16.416901-16.420995 43.013653-16.420995 59.430554 0l146.32767 146.281621 298.275337-305.866223c16.253172-16.58063 42.891879-16.891715 59.430554-0.655939C810.356179 370.600573 810.646797 397.21779 794.409998 413.799444z" p-id="2938" fill="#1296db"></path></svg>   
                    Done</span></div>
                     <span class="formdescribe nocut">{{saveRes.check_list_metum.parts[i].description}} . ({{saveRes.check_list_metum.parts[i].count}}/{{saveRes.check_list_metum.parts[i].items.length}})</span>
                    </div>
                    <!-- 表单项 -->
                 <div class="formitem">
                     <div *ngFor="let item of saveRes.check_list_metum.parts[i].items;let key = index " class="formitemcontent">
                        <b class="itemindex"><b class="indexb nocut">{{i+1}}.{{key+1}}</b></b>
                         <div class="itemtitle">
                             <p [ngClass]="{'toRed': item.value===1}" class="nocut">{{item.title}}</p>
                         <input  disabled  *ngIf="item.type == 'text'"  [(ngModel)]="item.value" [type]="item.type" class="textinput nocut"/>
                         <span  *ngIf="item.type == 'radio'">
                           <div  *ngFor="let t of item.options;let j = index" class="radioitem nocut">
                            <!-- radio -->
                            <div class="radio checkbox" *ngIf="item.value!==j"></div>
                                <svg t="1595836483444" class="icon" *ngIf="item.value===0&&j==0"  viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5423" width="16" height="16"><path d="M512 76.8c-236.8 0-435.2 192-435.2 435.2s192 435.2 435.2 435.2 435.2-192 435.2-435.2S748.8 76.8 512 76.8z m249.6 320L480 704c-12.8 12.8-38.4 12.8-51.2 0L288 556.8c-12.8-12.8-12.8-38.4 0-51.2 12.8-12.8 38.4-12.8 51.2 0l115.2 115.2L704 339.2c12.8-12.8 38.4-12.8 51.2 0 25.6 12.8 25.6 38.4 6.4 57.6z" fill="#68D279" p-id="5424"></path><path d="M710.4 339.2l-256 281.6-115.2-115.2c-12.8-12.8-38.4-12.8-51.2 0-12.8 12.8-12.8 38.4 0 51.2l147.2 140.8c12.8 12.8 38.4 12.8 51.2 0L768 390.4c12.8-12.8 12.8-38.4 0-51.2-19.2-12.8-44.8-12.8-57.6 0z" fill="#FFFFFF" p-id="5425"></path></svg>

                                <svg t="1595836722622" class="icon" *ngIf="item.value===1&&j==1" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6227" width="16" height="16"><path d="M512 512m-435.2 0a435.2 435.2 0 1 0 870.4 0 435.2 435.2 0 1 0-870.4 0Z" fill="#FE6D68" p-id="6228"></path><path d="M563.2 512l108.8-108.8c12.8-12.8 12.8-38.4 0-51.2-12.8-12.8-38.4-12.8-51.2 0L512 460.8 403.2 352c-12.8-12.8-38.4-12.8-51.2 0-12.8 12.8-12.8 38.4 0 51.2L460.8 512 352 620.8c-12.8 12.8-12.8 38.4 0 51.2 12.8 12.8 38.4 12.8 51.2 0L512 563.2l108.8 108.8c12.8 12.8 38.4 12.8 51.2 0 12.8-12.8 12.8-38.4 0-51.2L563.2 512z" fill="#FFFFFF" p-id="6229"></path></svg>

                                <svg t="1595836815332" class="icon" *ngIf="item.value===2&&j==2" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6656" width="16" height="16"><path d="M512 65.98144c-245.91872 0-446.01856 200.09472-446.01856 446.01856 0 245.95456 200.064 446.01856 446.01856 446.01856s446.01856-200.064 446.01856-446.01856c0-245.91872-200.064-446.01856-446.01856-446.01856z" p-id="6657" fill="#c2cdd5"></path></svg>
                             <span style="margin-left: 10px;" class="radioBefore ">{{t}}</span>
                        </div>
                         </span>
                         <span  *ngIf="item.type == 'checkbox'">
                           <span  *ngFor="let t of item.options; let j = index" class="checkboxitem nocut" >
                               <!-- checkbox -->
                               <div class="checkbox" [ngStyle]="{'backgroundColor': item.value&&item.value.includes(j)?'#1d8fc3':''}"></div>
                               <span style="margin-left: 10px;" >{{t}}</span><br>
                           </span>
                         </span>

                         <div  class="itemdescript"  *ngIf="item.description"><i>Description:</i><span>{{item.description}}</span></div>
                              <!-- 图片，注释 -->
                        <div class="formdetailContent">
                            <div class="contentImg nocut" *ngIf="item.image_doc_id ">
                                <i>images:</i>
                                    <img [src]="host+'/api/v3/logical_docs/doc/'+item.image_doc_id+'/stream'">
                            </div>
                            <div class="contentDesc nocut" *ngIf="item.remark">
                                <i>Note:</i>
                                <span>{{item.remark}}</span>
                            </div>
                        </div>
                         </div>

               

                     
                     </div>


                 </div>
                  
             
                </li>
                <!-- 签名项 -->
                <li>
                  <div class="signtitle nocut"><b>Signatures</b> <span >{{saveRes.check_list_metum.signs.length}}/{{saveRes.check_list_metum.signs.length}}signed</span> </div>
                  <div class="signcontent">
                      <span>Signature 1</span> <b>Required by</b>  <span>Not specified</span>
                      <div class="sign nocut"  id="usersign" *ngFor="let item of saveRes.check_list_metum.signs" > 
                        <div class="forpic">    <img id="SectionItemid" class="nocut"   *ngIf="showdoc"  [src]="host+'/api/v3/logical_docs/doc/'+item.doc_id+'/stream'"/></div>
                            <span class="singby nocut">
                                <svg t="1595829816504"   class="icon iconcompele" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2937" width="64" height="64"><path d="M512.186242 6.139837c-278.100856 0-504.362043 226.261187-504.362043 504.362043S234.085386 1014.863922 512.186242 1014.863922s504.362043-226.261187 504.362043-504.362043S790.287097 6.139837 512.186242 6.139837zM794.409998 413.799444 466.418872 750.040465c-7.838525 8.023744-18.590404 12.579503-29.816073 12.642948l-0.227174 0c-11.141758 0-21.834284-4.433986-29.715789-12.314467L230.289943 574.038962c-16.420995-16.396435-16.420995-43.013653 0-59.430554 16.416901-16.420995 43.013653-16.420995 59.430554 0l146.32767 146.281621 298.275337-305.866223c16.253172-16.58063 42.891879-16.891715 59.430554-0.655939C810.356179 370.600573 810.646797 397.21779 794.409998 413.799444z" p-id="2938" fill="#1296db"></path></svg>
                                Signed by <b>{{assginUser}}</b>from Chunwo on<b>{{updatedAt|date:'medium' }}</b> </span>
                        </div>
                  </div>
                </li>
            </ul>
        </div>
    </div>
</div>