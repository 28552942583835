<div class="work-packages-split-view--tabletimeline-content" style=" margin-top: 30px;" *ngIf="teamList">
  
  <div class="work-packages-tabletimeline--table-side work-package-table--container __hidden_overflow_container">
<div class="activity_one activity" style="box-shadow: 0px 2px 4px rgb( 0 0 0 / 8%)" [ngClass]="{'droDown': oneShow}"> 
  <div class="header" (click)="dropDown(1)">
    <div class="title">
      {{teamList.activity_one.team_name}}
    </div>
    <div class="Statistics">
        <span>Activities: {{teamList.activity_one.count}};</span>
        <span>Labour/People: {{teamList.activity_one.labour_number}};</span>
        <span>Plant/Equipment:{{teamList.activity_one.plant_working + '/' +teamList.activity_one.plant_ldle}};</span>
    </div>
    <div class="dropDown" >
      >
    </div>
  </div>
  <div class="addButton" *ngIf="oneShow">
    <button class="addActivty icon-add" *ngIf="divClick=='activity' && (summaryData.state == 'draft'||summaryData.state == 'rejected')" (click)="copyClick(1)">Copy From</button>
    <button class="addActivty icon-add" *ngIf="divClick=='activity' && (summaryData.state == 'draft'||summaryData.state == 'rejected')" (click)="addActivtyClick(1)">Add</button>
  </div>  <table style=" margin: 0 auto;" class="keyboard-accessible-list generic-table work-package-table" *ngIf="oneShow">
    <thead class="-sticky">
      <tr style="border-bottom: 1px solid #E7E7E7;"  class="listTitle">
        <th>Section</th>
        <th>Portion</th>
        <th>Location</th>
          <th>Activity</th>
          <th>Labour/People</th>
          <th>Plant/Equipment</th>
     
        <th>Actions</th>
      </tr>
    </thead>
    <tbody class="results-tbody work-package--results-tbody" *ngFor="let item of tableList1">  
      <tr>
        <td>{{item.section_name}}</td>
        <td>{{item.protion_name}}</td>
        <td>{{item.location}}</td>
          <td class="activity">{{item.activity}}</td>
          <td>
            <div class="labur_plant">
              {{item.labur_number}}.0
              <div class="labur_plant-item">
                <span *ngFor="let laburItem of item.labours">({{laburItem.number}})&nbsp;&nbsp;{{laburItem.labour_name}}&nbsp;&nbsp;{{laburItem.ownership}}</span>
              </div>
            </div>
          </td>
          <td>
            <div class="labur_plant">
              {{item.plant_working}}.0/{{item.plant_ldle}}.0
              <div class="labur_plant-item">
                <span *ngFor="let laburItem of item.plants">({{laburItem.working}}/{{laburItem.ldle}})&nbsp;&nbsp;{{laburItem.equipment}}&nbsp;&nbsp;{{laburItem.plant_mark}}&nbsp;&nbsp;{{laburItem.ldle_code[0]}}&nbsp;&nbsp;{{laburItem.ownership}}</span>
              </div>
            </div>
          </td>
          <td>
            <div class="tablebutton" *ngIf="summaryData.state == 'draft'||summaryData.state == 'rejected'">
              <div (click)="editClick(item,1)" style="background-color: #777777;">Edit</div>
              <div (click)="deleteClick(item, 1)" style="background-color: #e15353;">Delete</div>
            </div>
            <div class="tablebutton" *ngIf="summaryData.state != 'draft'&&summaryData.state != 'rejected'">
              <div (click)="editClick(item,1)" style="background-color: #00a7ec">View</div>
            </div>
          </td>

  
      </tr>
    </tbody>
  </table>

</div>
<div class="activity_two activity" style="box-shadow: 0px 2px 4px rgb( 0 0 0 / 8%)" [ngClass]="{'droDown': twoShow}">
  <div class="header" (click)="dropDown(2)">
    <div class="title">
      {{teamList.activity_two.team_name}}
    </div>
    <div class="Statistics">
        <span>Activities: {{teamList.activity_two.count}};</span>
        <span>Labour/People: {{teamList.activity_two.labour_number}};</span>
        <span>Plant/Equipment:{{teamList.activity_two.plant_working + '/' +teamList.activity_two.plant_ldle}};</span>
    </div>
    <div class="dropDown" >
      >
    </div>
  </div>
  <div class="addButton" *ngIf="twoShow">
    <button class="addActivty icon-add" *ngIf="divClick=='activity' && (summaryData.state == 'draft'||summaryData.state == 'rejected')" (click)="copyClick(2)">Copy From</button>
    <button class="addActivty icon-add" *ngIf="divClick=='activity' && (summaryData.state == 'draft'||summaryData.state == 'rejected')" (click)="addActivtyClick(2)">Add</button>
  </div>
  <table style=" margin: 0 auto;" class="keyboard-accessible-list generic-table work-package-table" *ngIf="twoShow">
    <thead class="-sticky">
      <tr style="border-bottom: 1px solid #E7E7E7;"  class="listTitle">
        <th>Section</th>
        <th>Portion</th>
        <th>Location</th>
          <th>Activity</th>
          <th>Labour/People</th>
          <th>Plant/Equipment</th>
   
        <th>Actions</th>
      </tr>
    </thead>
    <tbody class="results-tbody work-package--results-tbody" *ngFor="let item of tableList2">  
      <tr>
        <td>{{item.section_name}}</td>
        <td>{{item.protion_name}}</td>
        <td>{{item.location}}</td>
        <ng-container *ngIf="divClick == 'activity'">
          <td class="activity">{{item.activity}}</td>
          <td>
            <div class="labur_plant">
              {{item.labur_number}}.0
              <div class="labur_plant-item">
                <span *ngFor="let laburItem of item.labours">({{laburItem.number}})&nbsp;&nbsp;{{laburItem.labour_name}}&nbsp;&nbsp;{{laburItem.ownership}}</span>
              </div>
            </div>
          </td>
          <td>
            <div class="labur_plant">
              {{item.plant_working}}.0/{{item.plant_ldle}}.0
              <div class="labur_plant-item">
                <span *ngFor="let laburItem of item.plants">({{laburItem.working}}/{{laburItem.ldle}})&nbsp;&nbsp;{{laburItem.equipment}}&nbsp;&nbsp;{{laburItem.plant_mark}}&nbsp;&nbsp;{{laburItem.ldle_code[0]}}&nbsp;&nbsp;{{laburItem.ownership}}</span>
              </div>
            </div>
          </td>
          <td>
            <div class="tablebutton" *ngIf="summaryData.state == 'draft'||summaryData.state == 'rejected'">
              <div (click)="editClick(item,2)" style="background-color: #777777;">Edit</div>
              <div (click)="deleteClick(item,2)" style="background-color: #e15353;">Delete</div>
            </div>
            <div class="tablebutton" *ngIf="summaryData.state != 'draft'&&summaryData.state != 'rejected'">
              <div (click)="editClick(item,2)" style="background-color: #00a7ec">View</div>
            </div>
          </td>
        </ng-container>
  
      </tr>
    </tbody>
  </table>

</div>
<div class="activity_three activity" style="box-shadow: 0px 2px 4px rgb( 0 0 0 / 8%)" [ngClass]="{'droDown': threeShow}">
  <div class="header" (click)="dropDown(3)">
    <div class="title">
      {{teamList.activity_three.team_name}}
    </div>
    <div class="Statistics">
        <span>Activities: {{teamList.activity_three.count}};</span>
        <span>Labour/People: {{teamList.activity_three.labour_number}};</span>
        <span>Plant/Equipment:{{teamList.activity_three.plant_working + '/' +teamList.activity_three.plant_ldle}}</span>
    </div>
    <div class="dropDown" >
      >
    </div>
  </div>
  <div class="addButton" *ngIf="threeShow">
    <button class="addActivty icon-add" *ngIf="divClick=='activity' && (summaryData.state == 'draft'||summaryData.state == 'rejected')" (click)="copyClick(3)">Copy From</button>
    <button class="addActivty icon-add" *ngIf="divClick=='activity' && (summaryData.state == 'draft'||summaryData.state == 'rejected')" (click)="addActivtyClick(3)">Add</button>
  </div>
  <table style=" margin: 0 auto;" class="keyboard-accessible-list generic-table work-package-table" *ngIf="threeShow">
    <thead class="-sticky">
      <tr style="border-bottom: 1px solid #E7E7E7;"  class="listTitle">
        <th>Section</th>
        <th>Portion</th>
        <th>Location</th>
          <th>Activity</th>
          <th>Labour/People</th>
          <th>Plant/Equipment</th>
   
        <th>Actions</th>
      </tr>
    </thead>
    <tbody class="results-tbody work-package--results-tbody" *ngFor="let item of tableList3">  
      <tr>
        <td>{{item.section_name}}</td>
        <td>{{item.protion_name}}</td>
        <td>{{item.location}}</td>
        <ng-container *ngIf="divClick == 'activity'">
          <td class="activity">{{item.activity}}</td>
          <td>
            <div class="labur_plant">
              {{item.labur_number}}.0
              <div class="labur_plant-item">
                <span *ngFor="let laburItem of item.labours">({{laburItem.number}})&nbsp;&nbsp;{{laburItem.labour_name}}&nbsp;&nbsp;{{laburItem.ownership}}</span>
              </div>
            </div>
          </td>
          <td>
            <div class="labur_plant">
              {{item.plant_working}}.0/{{item.plant_ldle}}.0
              <div class="labur_plant-item">
                <span *ngFor="let laburItem of item.plants">({{laburItem.working}}/{{laburItem.ldle}})&nbsp;&nbsp;{{laburItem.equipment}}&nbsp;&nbsp;{{laburItem.plant_mark}}&nbsp;&nbsp;{{laburItem.ldle_code[0]}}&nbsp;&nbsp;{{laburItem.ownership}}</span>
              </div>
            </div>
          </td>
          <td>
            <div class="tablebutton" *ngIf="summaryData.state == 'draft'||summaryData.state == 'rejected'">
              <div (click)="editClick(item,3)" style="background-color: #777777;">Edit</div>
              <div (click)="deleteClick(item,3)" style="background-color: #e15353;">Delete</div>
            </div>
            <div class="tablebutton" *ngIf="summaryData.state != 'draft'&&summaryData.state != 'rejected'">
              <div (click)="editClick(item,3)" style="background-color: #00a7ec">View</div>
            </div>
          </td>
        </ng-container>
  
      </tr>
    </tbody>
  </table>
</div> 
<div class="activity_four activity" style="box-shadow: 0px 2px 4px rgb( 0 0 0 / 8%)" [ngClass]="{'droDown': fourShow}">
  <div class="header" (click)="dropDown(4)">
    <div class="title">
      {{teamList.activity_four.team_name}}
    </div>
    <div class="Statistics">
        <span>Activities: {{teamList.activity_four.count}};</span>
        <span>Labour/People: {{teamList.activity_four.labour_number}};</span>
        <span>Plant/Equipment:{{teamList.activity_four.plant_working + '/' +teamList.activity_four.plant_ldle}};</span>
    </div>
    <div class="dropDown" >
      >
    </div>
  </div>
  <div class="addButton" *ngIf="fourShow">
    <button class="addActivty icon-add" *ngIf="divClick=='activity' && (summaryData.state == 'draft'||summaryData.state == 'rejected')" (click)="copyClick(4)">Copy From</button>
    <button class="addActivty icon-add" *ngIf="divClick=='activity' && (summaryData.state == 'draft'||summaryData.state == 'rejected')" (click)="addActivtyClick(4)">Add</button>
  </div>


  <table style=" margin: 0 auto;" class="keyboard-accessible-list generic-table work-package-table" *ngIf="fourShow">
    <thead class="-sticky">
      <tr style="border-bottom: 1px solid #E7E7E7;"  class="listTitle">
        <th>Section</th>
        <th>Portion</th>
        <th>Location</th>
          <th>Activity</th>
          <th>Labour/People</th>
          <th>Plant/Equipment</th>
   
        <th>Actions</th>
      </tr>
    </thead>
    <tbody class="results-tbody work-package--results-tbody" *ngFor="let item of tableList4">  
      <tr>
        <td>{{item.section_name}}</td>
        <td>{{item.protion_name}}</td>
        <td>{{item.location}}</td>
        <ng-container *ngIf="divClick == 'activity'">
          <td class="activity">{{item.activity}}</td>
          <td>
            <div class="labur_plant">
              {{item.labur_number}}.0
              <div class="labur_plant-item">
                <span *ngFor="let laburItem of item.labours">({{laburItem.number}})&nbsp;&nbsp;{{laburItem.labour_name}}&nbsp;&nbsp;{{laburItem.ownership}}</span>
              </div>
            </div>
          </td>
          <td>
            <div class="labur_plant">
              {{item.plant_working}}.0/{{item.plant_ldle}}.0
              <div class="labur_plant-item">
                <span *ngFor="let laburItem of item.plants">({{laburItem.working}}/{{laburItem.ldle}})&nbsp;&nbsp;{{laburItem.equipment}}&nbsp;&nbsp;{{laburItem.plant_mark}}&nbsp;&nbsp;{{laburItem.ldle_code[0]}}&nbsp;&nbsp;{{laburItem.ownership}}</span>
              </div>
            </div>
          </td>
          <td>
            <div class="tablebutton" *ngIf="summaryData.state == 'draft'||summaryData.state == 'rejected'">
              <div (click)="editClick(item,4)" style="background-color: #777777;">Edit</div>
              <div (click)="deleteClick(item,4)" style="background-color: #e15353;">Delete</div>
            </div>
            <div class="tablebutton" *ngIf="summaryData.state != 'draft'&&summaryData.state != 'rejected'">
              <div (click)="editClick(item,4)" style="background-color: #00a7ec">View</div>
            </div>
          </td>
        </ng-container>
  
      </tr>
    </tbody>
  </table>
</div>
  </div>
</div>

<!-- modulelist 分页 -->
<!-- <ng-container >
  <div class="pagination" style="position: absolute; bottom: 10px;">
    <ul class="pagination--items">
      <li class="pagination--item" *ngIf="modulepage-1 != 0"><a href="" (click)='modulego("prev")' id="pagination--prev-link" rel="prev start" role="link" aria-label="Back to the previous page">&lt;</a></li>
      <li *ngFor="let item of modulesize;let i = index" class="pagination--item pagination-number" ng-reflect-klass="pagination--item pagination-nu" ng-reflect-ng-class="[object Object]">
        <a [ngClass]="{peracss:modulepage==i+1}" (click)='modulelistpage(i+1)' href="" rel="next" role="link">{{i+1}}</a></li>
      <li class="pagination--item" *ngIf="modulepage != modulesize.length"><a (click)='modulego("next")' class="pagination--next-link" href="" id="pagination--next-link" rel="next" role="link" aria-label="Forward to the next page">&gt;</a></li>
    </ul>
  </div>
</ng-container> -->
<SiteDiary-Details-addActivity [teamIndex]="editIndex" [summaryData]="summaryData" [updataActivty]="updataActivty" (close)="addActivtyClick()" *ngIf="addActivty"></SiteDiary-Details-addActivity>
<div class="wage" *ngIf="copyShow"></div>
<div class="activeCopy" *ngIf="copyShow">
  <div class="case">
    <div class="Close" (click)="closeCopy()">X</div>
    <div class="title">
      <span>Site Diary Date:</span><span id="activetyCopyDate">dataTimeText</span>
    </div>
    <div class="CopyAll"><span>Copy All:</span> <input [(ngModel)]="copyCheckAll" type="checkbox" (click)="checkAll()"/></div>
    <div class="table">
      <table style=" margin: 0 auto;" class="keyboard-accessible-list generic-table work-package-table">
        <thead class="-sticky">
          <tr style="border-bottom: 1px solid #E7E7E7;"  class="listTitle">
            <th>Copy</th>
            <th>Section</th>
            <th>Portion</th>
            <th>Location</th>
            <th>Activity</th>
            <th>Labour/People</th>
            <th>Plant/Equipment</th>
          </tr>
        </thead>
        <tbody class="results-tbody work-package--results-tbody" *ngFor="let item of copyList">  
          <tr>
            <td><input [(ngModel)]="item.copy" type="checkbox"/></td>
            <td>{{item.section_name}}</td>
            <td>{{item.protion_name}}</td>
            <td>{{item.location}}</td>
              <td class="activity">{{item.activity}}</td>
              <td>
                <div class="labur_plant">
                  {{item.labur_number}}.0
                  <div class="labur_plant-item">
                    <span *ngFor="let laburItem of item.labours">({{laburItem.number}})&nbsp;&nbsp;{{laburItem.labour_name}}&nbsp;&nbsp;{{laburItem.ownership}}</span>
                  </div>
                </div>
              </td>
              <td>
                <div class="labur_plant">
                  {{item.plant_working}}.0/{{item.plant_ldle}}.0
                  <div class="labur_plant-item">
                    <span *ngFor="let laburItem of item.plants">({{laburItem.working}}/{{laburItem.ldle}})&nbsp;&nbsp;{{laburItem.equipment}}&nbsp;&nbsp;{{laburItem.plant_mark}}&nbsp;&nbsp;{{laburItem.ldle_code[0]}}&nbsp;&nbsp;{{laburItem.ownership}}</span>
                  </div>
                </div>
              </td>
     
  
          </tr>
        </tbody>
      </table>
    </div>
    <div class="action">
      <div class="submit" (click)="copySubmit()">Submmit</div>
      <div class="Cancel" (click)="closeCopy()">Cancel</div>
    </div>
  </div>
</div>