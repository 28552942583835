<div class="content">
  <div class="content_left">
    <div class="basic_info" [class.basic_info_ok]="!nav_pos">
      <span>1.{{ i18n.t("js.check-lists.quest.BasicInfo") }}</span>
    </div>
    <div class="basic_icon">
      <i class="icon6 icon-toggler icon-arrow-right3" aria-hidden="true"></i>
    </div>
    
    <div class="template_info" [class.basic_info_ok]="nav_pos">
      <span>2.{{ i18n.t("js.check-lists.quest.TemplateInfo") }}</span>
    </div>
  </div>
  
  <div class="btn_info">
    <span class="btn_no" (click)="clickOut()">{{ i18n.t("js.check-lists.quest.cancel") }}</span>
    <div class="btn_back" *ngIf="nav_pos" [ngStyle]="{'background' : !showFinish ? '#0696d7' : '#f7f9fa' , 'color' :  !showFinish ? '#fff' : ''}" (click)="clickIssue()"><span>{{ i18n.t("js.check-lists.quest.issue") }} </span></div>
    <div class="btn_back" *ngIf="!nav_pos" [ngStyle]="{'background' : showNext ? '#0696d7' : '#f7f9fa' , 'color' : showNext ? '#fff' : ''}" (click)
    ="clickNextStep()"><span>{{ i18n.t("js.check-lists.quest.NextStep") }}  </span></div>
  </div>

    <!-- 方便媒体查询 -->
    <div class="copybtn_info" style="display: none;">
      <span class="cancel" (click)="clickOut()">{{ i18n.t("js.check-lists.quest.cancel") }}</span>
      <div class="cancel decided" *ngIf="nav_pos" [ngStyle]="{'background' : !showFinish ? '#0696d7' : '#f7f9fa' , 'color' :  !showFinish ? '#fff' : ''}" (click)="clickIssue()"><span>{{ i18n.t("js.check-lists.quest.issue") }} </span></div>
      <div class="cancel decided" *ngIf="!nav_pos" [ngStyle]="{'background' : showNext ? '#0696d7' : '#f7f9fa' , 'color' : showNext ? '#fff' : ''}" (click)
      ="clickNextStep()"><span>{{ i18n.t("js.check-lists.quest.NextStep") }}  </span></div>
    </div>

</div>